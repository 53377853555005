<!-- 禁入规则 -->
<template>
  <div class="percent-100">
    <AppTable
      ref="AppTable"
      :tableColumns="tableColumns"
      controller="rule"
      :requestParams="requestParams"
      :searchArr="searchArr"
    >
      <template #operator-left-box>
        <div class="r-f" style="align-items: center">
          <el-button size="small" class="button_addTo" @click="insert">
            <div class="centerBox">
              <svg-icon svg-name="addV2" />
              <span class="ml-[6px]">添加禁入规则</span>
            </div>
          </el-button>
          <span
            style="color: var(--fontSize-auxiliary-color)"
            class="fs_14 ml_20"
            >规则使用说明：1.添加规则 2.设置自定义的规则条件
            3.并把需要执行此规则的人员授权给它111</span
          >
        </div>
      </template>

      <template #startDtm1="{ row }">
        {{ row.startDtm1 }} - {{ row.endDtm1 }}
      </template>

      <template #operator="{ row }">
        <iconBox
          imgName="fenPei"
          :altText="$t('rules.allotRule')"
          @click="assingRules(row)"
        ></iconBox>
        <iconBox
          imgName="edit"
          :altText="$t('general.edit')"
          @click="editRow(row)"
        ></iconBox>
        <iconBox
          classNmae="shadow_delete"
          imgName="delete"
          altText="删 除"
          @click="deleteRow(row)"
        ></iconBox>
      </template>
    </AppTable>
    <inOutRuleDialog
      @on-submit="onSubmit"
      ref="inOutRuleDialog"
    ></inOutRuleDialog>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
import inOutRuleDialog from '@components/ruleSetting/inOutRuleDialog'
export default {
  components: {
    inOutRuleDialog
  },
  data () {
    return {
      requestParams: {
        type: 1
      },
      tableColumns: [
        {
          label: this.$t('rules.ruleName'),
          prop: 'ruleName',
          isSearch: true
        },
        {
          label: '区域名称',
          prop: 'railName',
        },
        {
          label: '时间范围',
          prop: 'startDtm1',
          customSlot: 'startDtm1',
          type: 'customSlot'
        },
        {
          label: '操作',
          customSlot: 'operator',
          type: 'customSlot',
          width: '250px'
        }
      ],
      searchArr: [
        {
          label: '用户姓名',
        },
        {
          label: '设备IMEI',
        }
      ],
    }
  },
  methods: {
    editRow (row) {
      this.$refs.inOutRuleDialog.show(row)
    },
    insert () {
      this.$refs.inOutRuleDialog.show({}, this.requestParams.type)
    },
    deleteRow (row) {
      this.$deleteConfirmationBox({
        deleteTitle: '删除规则',
        deleteContent: '<div><p>确认删除当前规则？</p></div>',
        submitText: '确 定'
      }, () => {
        this.$api.deleteBatch('rule', row.id).then(() => {
          this.$message.success('删除成功')
          this.$refs.AppTable.getData()
        })
      })
    },
    onSubmit () {
      this.$refs.AppTable.getData()
    },
    assingRules (row) {
      this.$emit('on-ruleDeviceDialogShow', row)
    }
  }
}
</script>
