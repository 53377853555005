<!-- 违反处理 -->
<template>
  <el-dialog
    :destroy-on-close="true"
    :visible.sync="dialogVisible"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    class="dialog_clsss filter-dialog"
    custom-class="w-[640px]"
  >
    <div class="deleteBox c_fff">
      <div class="pos_r dialog_header">
        <span v-if="dealState">查看处理</span>
        <span v-else>处理内容</span>
        <div class="imgPictureChange clickBox closeBox" @click="onClose">
          <svg-icon
            :svg-name="'delete_hover'"
            class-name="!w-[26px] !h-[26px]"
          />
          <svg-icon :svg-name="'close'" class-name="!w-[26px] !h-[26px]" />
        </div>
      </div>
      <!-- 已经处理 -->
      <div class="dialog_body" v-if="dealState">
        <div class="r-f lh_20 mb_10 fs_14">
          <div class="c_9DA7B2 mr_10 titleBox">处理内容：</div>
          <div class="c_fff">
            {{ form.dealContent }}
          </div>
        </div>
        <div class="r-f lh_20 mb_10 fs_14">
          <div class="c_9DA7B2 mr_10 titleBox tracking-[1px]">处 理 人：</div>
          <div class="c_fff">
            {{ form.dealUser }}
          </div>
        </div>
        <div class="r-f lh_20 mb_10 fs_14">
          <div class="c_9DA7B2 mr_10 titleBox">处理时间：</div>
          <div class="c_fff">
            {{ form.dealDtm }}
          </div>
        </div>
        <div class="operator-button centerBox mt_20">
          <el-button
            size="small"
            class="button_default buttonBox"
            @click="onClose"
            >关 闭</el-button
          >
        </div>
      </div>
      <!-- 未处理 -->
      <div class="dialog_body" v-else>
        <el-form
          label-position="top"
          :model="form"
          :rules="rules"
          ref="form"
          label-width="0px"
        >
          <el-form-item prop="dealContent">
            <el-input
              type="textarea"
              placeholder="请填写处理内容"
              v-model="form.dealContent"
              resize="none"
              :autosize="{ minRows: 6, maxRows: 8 }"
            >
            </el-input>
          </el-form-item>
        </el-form>
        <div class="operator-button centerBox mt_20">
          <el-button
            size="small"
            class="button_default buttonBox"
            @click="onClose"
            >关 闭</el-button
          >
          <el-button
            size="small"
            class="button_function buttonBox"
            @click="onSubmit"
            >确 认</el-button
          >
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      submitCallbackFn: null, // 成功回调
      ruleViolateId: null,
      dealState: false, // true 已处理
      form: {
        dealContent: ''
      },
      rules: {
        dealContent: [
          { required: true, message: '请填写处理内容', trigger: 'blur' }
        ]
      },
      dialogVisible: false
    }
  },
  methods: {
    /* 数据详情 */
    selectFn(id) {
      this.$api.select('report/ruleViolate', id).then((res) => {
        if (res.code === 200) {
          const details = res.data
          this.ruleViolateId = details.id
          if (this.$refs.form) {
            this.$refs.form.resetFields()
          }
          this.dealState = details.dealState === 1
          this.form = { ...details }
          this.$nextTick(() => {
            this.dialogVisible = true
          })
        }
      })
    },
    show(id, submitCallbackFn) {
      this.submitCallbackFn = submitCallbackFn || null
      this.selectFn(id)
    },
    onClose() {
      this.dialogVisible = false
      this.$emit('on-close')
      this.form = {
        dealContent: ''
      }
    },
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$api.report
            .ruleViolateDeal({
              id: this.form.id,
              dealContent: this.form.dealContent
            })
            .then((res) => {
              if (res.code === 200) {
                if (this.submitCallbackFn) {
                  this.submitCallbackFn(this.form)
                }
                this.$emit('on-submit')
                this.$message.success('操作成功')
                this.dialogVisible = false
              }
            })
        }
      })
    }
  }
}
</script>
<style scoped lang="less" rel="stylesheet/less">
.titleBox {
  width: 70px;
  flex-shrink: 0;
}
</style>
