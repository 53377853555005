var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.globalLoading || _vm.disconnectionType),expression:"globalLoading || disconnectionType"}],ref:"app",staticClass:"flex flex-col",attrs:{"id":"app","element-loading-text":_vm.elementLoadingText,"element-loading-spinner":"el-icon-loading","element-loading-background":"rgba(0, 0, 0, 0.8)"}},[(false)?_c('div',{staticClass:"w-[40px] h-[60px]"}):_vm._e(),_c('div',{staticClass:"hidden text-main text-xs fixed top-0 left-0 z-[3001] rounded bg-backgroundv2 border border-border px-2 py-1",attrs:{"id":"mouseTextPrompts"}}),(_vm.loginPageFn())?_c('div',[(
        (_vm.separatePageName &&
          _vm.separatePageName !== 'null' &&
          _vm.separatePageName !== 'hideNavigation') ||
        _vm.isDangerMode
      )?_c('newTabOpenHeader',{ref:"newTabOpenHeader"}):_c('headerBox',{ref:"headerBox",attrs:{"headerHeight":_vm.headerHeight}})],1):_vm._e(),_c('div',{staticClass:"h-0 flex-grow",style:({
      'flex-grow': _vm.loginPageFn() && _vm.isLogin ? 1 : 0
    }),attrs:{"id":"appContainer"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"text-[.8333vw]"}),_c('router-view')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }