import Element from 'element-ui'
import store from '@store'
export default {
  delConfirm(delObj) {
    if (store.getters.language === 'English') {
      Element.MessageBox.confirm(
        'This operation will permanently delete the data. Do you want to continue?',
        'Tips',
        {
          confirmButtonText: 'submit',
          cancelButtonText: 'cancel',
          type: 'warning'
        }
      ).then(() => {
        delObj.onOk()
      })
    } else {
      Element.MessageBox.confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delObj.onOk()
      })
    }
  }
}
