import common from '@/api/modules/common.js'
import { getRouter } from '@/router'
const state = {
  userGuidance: window.sessionStorage.getItem('userGuidance')
    ? JSON.parse(sessionStorage.getItem('userGuidance'))
    : {},
  guidanceSectionName: null, // 用户指引板块名称（在关闭指引的时候，根据名称去禁止下次在弹出指引）
  dragLevel: 100, // 拖动层级
  firstLevel: 200, // 拖动弹窗浮动层级 （第一级）
  secondLevel: 100, // 拖动弹窗浮动层级 （第二级）
  methodLogoutFn: null, // 退出登录
  commandLogSave: null, // 用户对设备发起指令操作日志保存
  globalLoading: false, // 全局 loading
  mqConnectionType: false,
  administrationList: [],
  userInfo: window.sessionStorage.getItem('userInfo')
    ? JSON.parse(sessionStorage.getItem('userInfo'))
    : {}, // 用户信息
  commonOptions: window.sessionStorage.getItem('commonOptions')
    ? JSON.parse(sessionStorage.getItem('commonOptions'))
    : {},
  AEPLogin: window.sessionStorage.getItem('AEPLogin')
    ? JSON.parse(sessionStorage.getItem('AEPLogin'))
    : null,
  /* 对方用户操作回调 */
  userOperationCallbackList: [],
  /* 地图通用配置 */
  mapConfiguration: {},
  /* 屏幕宽度变化回调数组 */
  onresizeCallbackList: [],
  /* 自定义点击回调列表 */
  customClickCallbackList: [],
  /* 系统设置 */
  businessSystemSetting: {},
  /* sos 相关配置 */
  sosObj: {
    triggerSOS: false, // 是否触发sos校验
    viewSOS: false, // 是否需要查看sos
    emergencyOpening: false,
    progressKey: null // progressKey
  },
  /* 地图打点 */
  mapPunctuation: {
    key: null
  },
  /* 颜色表 */
  colorObject: {
    theme: null,
    theme02: null,
    theme05: null,
    success: null,
    success05: null,
    info: null,
    info05: null,
    warning: null,
    warning05: null,
    dangerv2: null,
    dangerv205: null,
    main: null,
    auxiliary: null,
    backgroundv3: null
  },
  /* 文件上传数量 */
  fileUploadNum: 0,
  /* 新标签页的名称 */
  separatePageName: window.sessionStorage.getItem('separatePageName')
    ? sessionStorage.getItem('separatePageName')
    : null,
  /* 报警人进入第一现场的信息 */
  alarmPerson: {},
  arcgisMapConfig: {},
  /* poi 地图服务 */
  poiServer: {},
  /* 地图层级服务 */
  layersServer: window.sessionStorage.getItem('layersServer')
    ? JSON.parse(sessionStorage.getItem('layersServer'))
    : {},
  /* 地图层级配置列表 */
  mapLayerConfigList: [],
  /* 报警信息 （用于跳转到报警页面，自动到对应位置展示） */
  warningAlarm: {},
  /* 板块权限 */
  platePermission: {
    // dispatching
  },
  /* 监控状态 */
  monitorState: false,
  /* 监控页面状态 */
  monitorPageState: true,
  // 地图设置位置
  mapSetPosition: {
    moveType: false,
    deviceId: null,
    lng: null,
    lat: null
  },
  // 警单接警员信息
  alarmRecordOperatorInfo: {
    // data.alarmOperator [是否是接警人员]{1:是;}
    // data.operatorState 接警员当前状态（1空闲、2接警中）
    alarmOperator: 0,
    operatorState: 1
  },
  // 初始化 地图服务，数据在 entryList.json 获取
  initMapServer: null
}
const getters = {
  initMapServer(state) {
    return state.initMapServer
  },
  alarmRecordOperatorInfo(state) {
    return state.alarmRecordOperatorInfo
  },
  // 判断接警员是否在接警中
  alarmRecordOperatorType(state) {
    // this.$message.error('当前处于接警中，无法进行此操作')
    const alarmRecordOperatorInfo = state.alarmRecordOperatorInfo
    return (
      alarmRecordOperatorInfo.alarmOperator === 1 &&
      alarmRecordOperatorInfo.operatorState === 2
    )
  },
  monitorStyle() {
    return state.userInfo.monitorStyle
  },
  monitorPageState() {
    return state.monitorPageState
  },
  monitorState() {
    return state.monitorState
  },
  platePermission() {
    return state.platePermission
  },
  warningAlarm(state) {
    return state.warningAlarm
  },
  /* 报警人第一现场信息 */
  alarmPerson(state) {
    return state.alarmPerson
  },
  /* 单独页面的名称 */
  separatePageName(state) {
    return state.separatePageName
  },
  /* 文件上传数量 */
  fileUploadNum(state) {
    return state.fileUploadNum
  },
  /* sos 相关配置 */
  sosObj(state) {
    return state.sosObj
  },
  /* 地图打点 */
  mapPunctuation(state) {
    return state.mapPunctuation
  },
  /* 颜色表 */
  colorObject(state) {
    return state.colorObject
  },
  windowFn() {
    return window
  },
  /* 用户指南 */
  userGuidance(state) {
    return state.userGuidance
  },
  /* 系统设置 */
  businessSystemSetting(state) {
    return state.businessSystemSetting || {}
  },
  layersServer(state) {
    return state.layersServer
  },
  poiServer(state) {
    return state.poiServer
  },
  arcgisMapConfig(state) {
    return state.arcgisMapConfig
  },
  // 是否有地图权限
  noPermissionsMapType(state) {
    const arcgisMapConfig = state.arcgisMapConfig
    return !arcgisMapConfig.mapKey && !arcgisMapConfig.mapType
  },
  /* 离线地图配置 */
  commonOptions(state) {
    return state.commonOptions
    // return {
    //   bmHttpsUrl: 'https://192.168.0.125:3000', /* 内网部署的地址 */
    //   bmUiName: 'bigemap.d8su44rd', /* bmUiName 内网部署的地址里面的离线地图名称 */
    //   bmCorrect: '1' /* bmCorrect 是否需要矫正： 1需要 0不需要 */
    //   poiCoordinate: '1' /* poi 坐标位置： 1需要 0不需要 */
    // }
  },
  mapConfiguration(state) {
    return state.mapConfiguration
  },
  mapLayerConfigList(state) {
    return state.mapLayerConfigList
  },
  mqConnectionType(state) {
    return state.mqConnectionType
  },
  dragLevel(state) {
    return state.dragLevel
  },
  firstLevel(state) {
    return state.firstLevel
  },
  secondLevel(state) {
    return state.secondLevel
  },
  administrationList(state) {
    return [...state.administrationList]
  },
  methodLogoutFn(state) {
    return state.methodLogoutFn
  },
  commandLogSave(state) {
    return state.commandLogSave
  },
  globalLoading(state) {
    return state.globalLoading
  },
  userInfo(state) {
    return state.userInfo
  },
  isLogin(state) {
    return state.userInfo.id
  },
  userToken(state) {
    return state.userInfo.token || ''
  },
  AEPLogin(state) {
    return state.AEPLogin
  }
}
const mutations = {
  // 地图服务
  setInitMapServer(state, mapServer) {
    state.initMapServer = mapServer
  },
  // 设置拖拽等级
  setDragLevel(state) {
    state.dragLevel++
  },
  // 地图设置位置
  setMapSetPosition(state, mapSetPosition) {
    const router = getRouter()
    router.push('/dispatching')
    state.mapSetPosition = mapSetPosition
  },
  // 警单接警员信息
  setAlarmRecordOperatorInfo(state, res) {
    state.alarmRecordOperatorInfo = res
  },
  /* 修改监控页面状态 */
  setMonitorPageState(state, monitorPageState) {
    if (state.monitorPageState !== monitorPageState) {
      state.monitorPageState = monitorPageState
    }
  },
  /* 修改监控状态 */
  setMonitorState(state, monitorState) {
    state.monitorState = monitorState
  },
  /* 获取版块权限 */
  setPlatePermission(state, platePermission) {
    state.platePermission = platePermission
  },
  /* 修改报警信息 */
  getWarningAlarm(state, warningAlarm) {
    const router = getRouter()
    if (warningAlarm.id) {
      router.push('/alarmCenter')
    }
    state.warningAlarm = warningAlarm
  },
  alarmPerson(state, alarmPerson) {
    state.alarmPerson = alarmPerson
  },
  separatePageNameFn(state, separatePageName) {
    state.separatePageName = separatePageName
    window.sessionStorage.setItem('separatePageName', separatePageName)
  },
  colorObjectFn(state, colorObject) {
    state.colorObject = colorObject
  },
  sosObjFn(state, sosObj) {
    state.sosObj = { ...sosObj }
  },
  fileUploadNumFn(state, fileUploadNum) {
    state.fileUploadNum = fileUploadNum
  },
  mapPunctuationFn(state, mapPunctuation) {
    state.mapPunctuation = mapPunctuation
  },
  /* 系统设置 */
  businessSystemSetting(state, businessSystemSetting) {
    state.businessSystemSetting = { ...businessSystemSetting }
  },
  userGuidanceFn(state, userGuidance) {
    state.userGuidance = { ...userGuidance }
    state.guidanceSectionName = null
    window.sessionStorage.setItem('userGuidance', JSON.stringify(userGuidance))
  },
  /* 用户指引板块正在引导的名称 */
  guidanceSectionName(state, guidanceSectionName) {
    state.guidanceSectionName = guidanceSectionName
  },
  /* 执行 自定义点击 回调 */
  implementCustomClick(state, obj) {
    const customClickCallbackList = state.customClickCallbackList
    if (customClickCallbackList.length) {
      customClickCallbackList.forEach((v) => {
        if (v.callbackFn) {
          v.callbackFn(obj)
        }
      })
    }
  },
  /* 操作 自定义点击回调 （自定义点击） */
  operationCustomClick(state, obj) {
    /* type 1添加 2删除 */
    const customClickCallbackList = state.customClickCallbackList
    const index = customClickCallbackList.findIndex((v) => v.name === obj.name)
    if (obj.type === 1) {
      /* 判断之前有没有如果有就替换回调没有就新增 */
      if (index === -1) {
        state.customClickCallbackList.push({
          name: obj.name,
          callbackFn: obj.callbackFn
        })
      } else {
        state.customClickCallbackList[index].callbackFn = obj.callbackFn
      }
    } else {
      /* 删除 */
      if (index !== -1) {
        state.customClickCallbackList.splice(index, 1)
      }
    }
  },
  /* 执行 onresize 回调 */
  implementOnresize(state) {
    const onresizeCallbackList = state.onresizeCallbackList
    if (onresizeCallbackList.length) {
      onresizeCallbackList.forEach((v) => {
        if (v.callbackFn) {
          v.callbackFn()
        }
      })
    }
  },
  /* 操作 onresize （屏幕宽度变化） */
  operationOnresize(state, obj) {
    /* type 1添加 2删除 */
    const onresizeCallbackList = state.onresizeCallbackList
    const index = onresizeCallbackList.findIndex((v) => v.name === obj.name)
    if (obj.type === 1) {
      /* 判断之前有没有如果有就替换回调没有就新增 */
      if (index === -1) {
        state.onresizeCallbackList.push({
          name: obj.name,
          callbackFn: obj.callbackFn
        })
      } else {
        state.onresizeCallbackList[index].callbackFn = obj.callbackFn
      }
    } else {
      /* 删除 */
      if (index !== -1) {
        state.onresizeCallbackList.splice(index, 1)
      }
    }
  },
  setMapConfigurationFn(state, obj) {
    const { label, value } = obj
    const mapConfiguration = { ...state.mapConfiguration }
    mapConfiguration[`${label}`] = value
    state.mapConfiguration = mapConfiguration
  },
  /* 执行子项目方法 */
  userOperationCallback(state, obj) {
    state.userOperationCallbackList.forEach((v) => {
      if (v.operationName === obj.operationName && v.callback) {
        v.callback(obj.data)
      }
    })
  },
  /* 删除子项目点击方法 */
  deleteUserOperationCallback(state, id) {
    const index = state.userOperationCallbackList.findIndex((v) => v.id === id)
    if (index !== -1) {
      state.userOperationCallbackList.splice(index, 1)
    }
  },
  /* 添加子项目回调 */
  addUserOperationCallback(state, obj) {
    // const obj = {
    //   id: 唯一标识符用于删除
    //   operationName: 方法名字
    //   callback: 回调
    // }
    const index = state.userOperationCallbackList.findIndex(
      (v) => v.id === obj.id
    )
    if (index === -1) {
      state.userOperationCallbackList.push(obj)
    } else {
      state.userOperationCallbackList[index] = obj
    }
  },
  mqConnectionTypeFn(state, mqConnectionType) {
    state.mqConnectionType = mqConnectionType
  },
  logoutFn(state, methodLogoutFn) {
    state.methodLogoutFn = methodLogoutFn
  },
  // 用户对设备发起指令操作日志保存
  setCommandLogSave(state, commandLogSave) {
    state.commandLogSave = commandLogSave
  },
  globalLoadingFn(state, type) {
    state.globalLoading = type
  },
  administrationListFn(state, administrationList) {
    state.administrationList = administrationList
  },
  /* 登录用户信息 */
  setUserInfo(state, userInfo) {
    if (!userInfo.lat && !userInfo.lng) {
      userInfo.lat = 39.906381
      userInfo.lng = 116.4067
    }
    state.userInfo = userInfo
    window.sessionStorage.setItem('userInfo', JSON.stringify(userInfo))
  },
  /* 设置地图层级基础配置 */
  setMapLayerConfigList(state, mapLayerConfigList) {
    state.mapLayerConfigList = mapLayerConfigList
    // window.sessionStorage.setItem(
    //   'mapLayerConfigList',
    //   JSON.stringify(mapLayerConfigList)
    // )
  },
  setCommonOptions(state, commonOptions) {
    state.commonOptions = commonOptions
    window.sessionStorage.setItem(
      'commonOptions',
      JSON.stringify(commonOptions)
    )
  },
  setLayersServer(state, layersServer) {
    state.layersServer = { ...layersServer }
    console.log('setLayersServer', layersServer)
    window.sessionStorage.setItem('layersServer', JSON.stringify(layersServer))
  },
  // 地图poi 配置
  setPoiServer(state, poiServer) {
    state.poiServer = poiServer
  },
  // 地图配置
  setArcgisMapConfig(state, arcgisMapConfig) {
    state.arcgisMapConfig = arcgisMapConfig
  },
  /* 退出登录 */
  logout() {
    state.userInfo = {}
    window.sessionStorage.setItem('userInfo', '')
    window.sessionStorage.setItem('permissionList', '')
    window.sessionStorage.setItem('directiveList', '[]')
    window.sessionStorage.clear('firstLogin')
  },
  setAEPLogin(state, payload) {
    state.AEPLogin = payload
    window.sessionStorage.setItem('AEPLogin', JSON.stringify(payload))
  }
}

const actions = {
  userGuidanceFn({ commit, state }, parameter) {
    const userGuidance = { ...state.userGuidance }
    if (parameter && parameter.name) {
      userGuidance[parameter.name] = parameter.content
    } else if (state.guidanceSectionName) {
      userGuidance[state.guidanceSectionName] = 1
    }
    common.userGuidanceDataSaveFn({ settingJson: JSON.stringify(userGuidance) })
    commit('userGuidanceFn', userGuidance)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
