import Axios from '@/http'
export default {
  assignCancel(params) {
    return new Promise((resolve, reject) => {
      Axios.post('rule/device/rule/assignCancel', params).then((res) => {
        resolve(res)
      })
    })
  },
  assigned(params) {
    return new Promise((resolve, reject) => {
      Axios.post('rule/assign', params, { isJSON: true }).then((res) => {
        resolve(res)
      })
    })
  }
}
