<!-- 电子围栏 -->
<template>
  <div class="w_100vh h_100vh">
    <AppTable
      ref="AppTable"
      :tableColumns="tableColumns"
      controller="mapRail"
      :requestParams="requestParams"
    >
      <template #operator-left-box>
        <div>
          <el-button
            size="small"
            class="button_addTo"
            @click="addElectronicFenceShow()"
          >
            <div class="centerBox">
              <svg-icon :svg-name="'addV2'" />
              <span class="ml-[6px]">添加电子围栏</span>
            </div>
          </el-button>
        </div>
      </template>
      <template #mapDisplay="{ row }">
        <div>
          <el-switch
            class="switch_custom switch_width_60"
            @change="changeFn(row.id, $event)"
            :value="mapDisplayFn(row.id)"
            active-text="显示"
            inactive-text="隐藏"
            active-color="#5AB95F"
            inactive-color="var(--background)"
          >
          </el-switch>
        </div>
      </template>
      <!-- 自定义内容 -->
      <template #operator="{ row }">
        <iconBox
          imgName="edit"
          altText="编 辑"
          @click="addElectronicFenceShow(row.id)"
        ></iconBox>
        <iconBox
          classNmae="shadow_delete"
          imgName="delete"
          altText="删 除"
          @click="deleteShow(row.id)"
        ></iconBox>
      </template>
    </AppTable>
    <!--operationShow-->
    <addElectronicFence
      @on-submit="getData"
      ref="addElectronicFence"
    ></addElectronicFence>
  </div>
</template>

<script>
import addElectronicFence from '@components/electronicFence/addElectronicFence'
// const addElectronicFence = () => import('@components/electronicFence/addElectronicFence')
export default {
  props: {
    mapDisplayList: {
      type: Array,
      default: () => {
        return []
      }
    },
    tableColumns: {
      type: Array,
      default: () => {
        return [
          {
            label: '电子围栏名称',
            isSearch: true,
            prop: 'railName'
          },
          {
            label: '操作',
            customSlot: 'operator',
            type: 'customSlot',
            width: '200px'
          }
        ]
      }
    }
  },
  data() {
    return {
      value1: true,
      requestParams: {
        groupId: null,
        waterPointTypeId: null
      },
      waterPointTypeList: []
    }
  },
  components: {
    addElectronicFence
  },
  mounted() {},
  methods: {
    changeFn(id, type) {
      this.$emit('on-mapDisplayFn', {
        id,
        type
      })
    },
    /* 地图显示 */
    mapDisplayFn(id) {
      if (this.mapDisplayList.length) {
        const index = this.mapDisplayList.findIndex((v) => v === id)
        return index !== -1
      } else {
        return false
      }
    },
    addElectronicFenceShow(id) {
      this.$refs.addElectronicFence.show(id)
    },
    visibleChange(e, row, name) {
      this.$set(row, name, e)
    },
    getData() {
      this.$refs.AppTable.getData()
      this.$emit('on-getData')
    },
    // 删除水源点
    deleteShow(id) {
      this.$deleteConfirmationBox(
        {
          deleteTitle: '删除电子围栏',
          deleteContent: '<div><p>确认删除当前删除电子围栏？</p></div>',
          submitText: '确 定'
        },
        () => {
          this.$api.deleteBatch('mapRail', id).then((res) => {
            if (res.code === 200) {
              this.$emit('on-delete', id)
              this.$message.success('删除成功')
              this.getData()
            }
          })
        }
      )
    }
  }
}
</script>
<style scoped lang="less" rel="stylesheet/less"></style>
