<!-- 设备上下线汇总 -->
<template>
  <AppTable
    ref="AppTable"
    :tableColumns="tableColumns"
    controller="case/casesUserNotice"
    :requestParams="requestParams"
  >
    <template #operator-left-box>
      <div class="">
        <slot name="unreadType"></slot>
      </div>
    </template>
    <template #operator-right-box>
      <div>
        <el-button
          @click="appTableGetData()"
          class="button_default"
          size="mini"
        >
          <svg-icon
            svg-name="icon_refres"
            className="svgBox !w-[12px] !h-[12px]"
          />
        </el-button>
      </div>
    </template>
    <template #unread="{ row }">
      <div v-if="!row.hadRead" class="unread"></div>
    </template>
    <template #content="{ row }">
      <div class="text-xs text-main">
        <span class="text-theme mr-1">案件：{{ row.casesNo || '/' }}; </span>
        <span>{{ row.content }}</span>
      </div>
    </template>
    <template #operator="{ row }">
      <div>
        <iconBox
          v-if="row.casesType === 3 || row.type === 1"
          @click="viewCaseCasesUserNotice(row)"
          imgName="see"
          altText="前往查看案件"
        ></iconBox>
        <iconBox
          v-else
          @click="viewCaseCasesUserNotice(row)"
          imgName="edit"
          altText="前往审核"
        ></iconBox>
        <slot name="operator" v-bind:row="row"></slot>
      </div>
    </template>
  </AppTable>
</template>

<script>
export default {
  props: {
    unreadType: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  data() {
    return {
      tableColumns: [
        {
          label: '内容',
          customSlot: 'content',
          type: 'customSlot'
        },
        {
          label: '通知时间',
          prop: 'createDtm',
          width: '180px'
        },
        {
          label: '操作',
          customSlot: 'operator',
          type: 'customSlot',
          width: '80px'
        }
      ],
      requestParams: {
        queryStartDtm: null,
        queryEndDtm: null,
        orgId: null,
        sosState: null,
        deviceType: null
      }
    }
  },
  components: {},
  mounted() {
    if (this.unreadType) {
      this.tableColumns.push({
        label: null,
        width: '30px',
        customSlot: 'unread',
        type: 'customSlot'
      })
    }
  },
  methods: {
    // 获取案件详情
    getCaseCasesUserNoticeSelect(id) {
      return new Promise((resolve, reject) => {
        this.$api.select('case/casesUserNotice', id).then(resolve)
      })
    },
    /* 查看详情 */
    viewCaseCasesUserNotice(row) {
      const { casesId, casesAuditId, casesType, type } = row
      let path = null
      if (type === 1) {
        if (casesType === 3) {
          path = '/caseCenter#/compul-measures'
        } else {
          path = '/caseCenter#/case-manage'
        }
      } else {
        path = '/caseCenter#/review-approval'
      }
      this.$nextTick(() => {
        this.$emit('on-readFn', row)
        this.$emit('on-close')
        const time = this.createUniqueId(1)
        this.$router.push(
          `${path}?casesId=${casesId}&casesAuditId=${casesAuditId}&time=${time}`
        )
      })
    },
    appTableGetData() {
      this.$refs.AppTable.getData()
    }
  }
}
</script>
<style scoped lang="less" rel="stylesheet/less">
.el-row::after,
.el-row::before {
  display: none;
}
.unread {
  content: '';
  width: 10px;
  height: 10px;
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 50%;
  background: var(--dangerv2);
  box-shadow: 0px 0px 6px 1px var(--dangerv2);
}
</style>
