import { mapGetters } from 'vuex'
import headerNavigation from '@components/header'
import multipleChecksJs from './multipleChecks.js'
import cases from './cases.js'
import alarmRecord from './alarmRecord.js'
import spotCheck from './spotCheck.js'
import { Map } from 'hy-common'
import { DangerTypes } from '@/types/dangerType.js'

// /* 单人对讲（只能在基座，因为也换子应用功能要保留） */
const singleTalkback = () => import('@components/device/singleTalkback')
// /* 多人对讲（只能在基座，因为也换子应用功能要保留） */
const multiPersonIntercom = () =>
  import('@components/device/multiPersonIntercom/multiPersonIntercom')
// /* 多人监控（只能在基座，因为也换子应用功能要保留） */
const multipleChecks = () => import('@components/device/videoCheck/monitor')
const departmentMultipleChecks = () =>
  import('@components/device/videoCheck/multipleChecks')
// /* 单人视频（只能在基座，因为也换子应用功能要保留） */
const singleInspection = () =>
  import('@components/device/videoCheck/singleInspection.vue')
// /* 录像 （只能在基座，因为也换子应用功能要保留） */
const recording = () => import('@components/device/recording/recording.vue')
// /* 录音 （只能在基座，因为也换子应用功能要保留） */
const soundRecording = () =>
  import('@components/device/soundRecording/soundRecording.vue')
// /* 广播（只能在基座，因为也换子应用功能要保留） */
const broadcast = () => import('@components/device/broadcast')
/* 检查记录 */
const personnelCheckAdd = () => import('@components/inspect/personnelCheckAdd')
/* 下发任务 */
const assignTasks = () => import('@components/task_allocation/assignTasks.vue')
/* 查看任务 */
const independentMode = () =>
  import('@components/task_allocation/independent_mode.vue')
/* 文件回放 */
const filePlayback = () => import('@components/mediaCenter/filePlayback.vue')
/* 文件上传 */
const fileUpload = () => import('@components/file')
/* 新消息提醒 */
const newMessageReminder = () => import('@components/layout/newMessageReminder')
/* 下发消息 */
const issueMessage = () => import('@components/issueMessage/issueMessage')
const {
  menuList,
  userGuidanceList,
  mapConfiguration,
  sdkParams
} = require(`@/version/${process.env.VUE_APP_BUILD_VERION}.js`)
// import headerBox from './js/index.js'
const mixinsList = [multipleChecksJs, cases, alarmRecord, spotCheck]
export default {
  props: {
    headerHeight: {
      type: Number,
      default: () => {
        return null
      }
    }
  },
  mixins: mixinsList,
  data() {
    return {
      mapConfiguration: mapConfiguration,
      userGuidanceList: userGuidanceList,
      beingInvitedType: false, // 是否正在被邀请
      alarmPersonInCaseType: false,
      mqObj: {
        mapConfigurationType: false,
        sdkType: false,
        businessType: false
      },
      componentMethodList: [
        'recording',
        'soundRecording',
        'singleTalkback',
        'multiPersonIntercom',
        'multipleChecks',
        'singleInspection',
        'filePlayback',
        'departmentMultipleChecks',
        'assignTasks',
        'independentMode',
        'broadcast',
        'fileUpload',
        'issueMessage',
        'editSpotCheck',
        'spotCheckDetails'
      ],
      menuList: menuList,
      /* 隐藏图层列表 */
      hideLayerList: [
        // {
        //   title: '单人对讲',
        //   componentsName: 'singleTalkback'
        // },
        // {
        //   title: '多人对讲',
        //   componentsName: 'multiPersonIntercom'
        // },
        // {
        //   title: '监控设备',
        //   componentsName: 'multipleChecks'
        // },
        // {
        //   title: '监控检查',
        //   componentsName: 'departmentMultipleChecks'
        // },
        // {
        //   title: '视频检查',
        //   componentsName: 'singleInspection'
        // }
      ],
      /* 隐藏列表导航 top */
      navigationTop: true,
      /* 隐藏列表是否打开 */
      openType: true,
      valid: true,
      /* 是否有新的sos */
      newSosEvents: false, // sos提示弹=窗
      sosTime: null, // 弹窗显示定时器
      sosId: null, // 设备id
      sceneName: null, // 监听房间名称
      audioShow: 'return false;',
      permissionIdList: [],
      listeningToMqttTime: null
    }
  },
  destroyed() {
    if (this.listeningToMqttTime) {
      clearInterval(this.listeningToMqttTime)
      this.listeningToMqttTime = null
    }
    if (this.sceneName) {
      this.$business.deleteSceneDevice(this.sceneName)
    }
  },
  components: {
    headerNavigation,
    personnelCheckAdd,
    singleTalkback,
    multiPersonIntercom,
    multipleChecks,
    departmentMultipleChecks,
    singleInspection,
    filePlayback,
    assignTasks,
    independentMode,
    recording,
    soundRecording,
    broadcast,
    fileUpload,
    newMessageReminder,
    issueMessage
  },
  watch: {
    broadcastDeviceList: {
      immediate: true,
      handler: function () {
        if (this.$refs.multipleChecks) {
          this.$refs.multipleChecks.broadcastDeviceIdListFn()
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'platePermission',
      'checkParameters',
      'userInfo',
      'userGuidance',
      'separatePageName',
      'alarmRecordOperatorType',
      'initMapServer'
    ]),
    // 广播设备列表
    broadcastDeviceList() {
      if (this.$store) {
        return this.$store.state.sdk.broadcastDeviceList
      } else {
        return []
      }
    }
  },
  methods: {
    // 监听mq链接
    setListeningToMqtt() {
      if (this.listeningToMqttTime) {
        clearInterval(this.listeningToMqttTime)
        this.listeningToMqttTime = null
      }
      this.listeningToMqttTime = setInterval(() => {
        const business = this.$business.connectionStatus()
        const businessNumber = this.$business.getTimeout()
        const sdk = this.$sdk.connectionStatus()
        const sdkNumber = this.$sdk.getTimeout()
        if (!business || !sdk) {
          console.log('mqtt监听', businessNumber, sdkNumber)
          if (businessNumber > 3 || sdkNumber > 3) {
            this.methodLogout()
          }
        }
      }, 60000)
    },

    issueMessageFn(item) {
      // this.$refs.issueMessage.show([{ deviceId: item.deviceId }])
      this.$store.commit('callComponent', {
        name: 'issueMessage',
        data: {
          methodName: 'show',
          params: [item]
        }
      })
    },
    /* 用户对设备发起指令操作日志保存 */
    async commandLogSave(params = {}) {
      // const command = {
      // 业务MQ
      //   录像: 'videoRecord',
      //   拍照: 'takePicture',
      //   录音: 'audioRecord',
      // 中颗粒度MQ
      //   直播: 'monitor',
      //   语音通话: 'intercomCall',
      //   视频通话: 'videoCall',
      //   广播: 'broadcast',
      // }
      const { command, deviceIdList = [] } = params || {}
      await this.$api.device.commandLogSave({
        command,
        deviceIdList
      })
    },
    /* 组件挂载到vuex，方便子项目使用 */
    componentMethodListFn() {
      this.componentMethodList.forEach((v) => {
        this.$store.commit('componentMethodFn', {
          name: v,
          callback: (res) => {
            if (res.methodName && this.$refs[`${v}`][`${res.methodName}`]) {
              this.$refs[`${v}`][`${res.methodName}`](
                res.params,
                res.paramsV2,
                res.otherParams
              )
            }
          }
        })
      })
    },
    /* 主题颜色放到vuex，方便子项目使用 */
    colorObjectFn() {
      const root = document.querySelector(':root')
      this.$store.commit('colorObjectFn', {
        theme: this.getPropertyValue('--theme', root),
        theme02: this.getPropertyValue('--theme-02', root),
        theme05: this.getPropertyValue('--theme-05', root),
        success: this.getPropertyValue('--success', root),
        success05: this.getPropertyValue('--success-05', root),
        info: this.getPropertyValue('--info', root),
        info05: this.getPropertyValue('--info-05', root),
        warning: this.getPropertyValue('--warning', root),
        warning05: this.getPropertyValue('--warning-05', root),
        dangerv2: this.getPropertyValue('--dangerv2', root),
        dangerv205: this.getPropertyValue('--dangerv2-05', root),
        main: this.getPropertyValue('--main', root),
        auxiliary: this.getPropertyValue('--auxiliary', root),
        backgroundv3: this.getPropertyValue('--backgroundv3', root)
      })
    },
    /* 获取颜色 */
    getPropertyValue(propertyName, root) {
      if (!propertyName) return
      if (!root) {
        root = document.querySelector(':root')
      }
      return getComputedStyle(root).getPropertyValue(propertyName)
    },
    /* 设备类型 */
    deviceTypeFindAll() {
      this.$api.findAll('device/deviceType').then((res) => {
        if (res.code === 200) {
          window.sessionStorage.setItem(
            'deviceTypeList',
            JSON.stringify(res.data)
          )
        }
      })
    },
    /* 获取sos中分页数据 */
    reportSosPageFn() {
      // this.$api
      //   .page('report/sos', {
      //     sosing: 1, // sos 状态 1：进行中
      //     page: 0,
      //     size: 1
      //   })
      //   .then((res) => {
      //     const data = res.data
      //     if (!data.totalElements) {
      this.$refs.newMessageReminder.onClose('sos')
      // }
      // })
    },
    // 停止警报
    async warningClose(closeop) {
      await this.$api.warningRecord.warningClose(closeop)
    },
    /* 新的sos弹窗提示 */
    newSosEventsFn() {
      const json = {
        triggerSOS: true, // 是否触发sos校验
        viewSOS: false, // 是否需要查看sos
        emergencyOpening: false,
        progressKey: null // progressKey
      }
      const close = () => {
        this.warningClose('sos')
        this.$store.commit('sosObjFn', json)
        this.$refs.newMessageReminder.onClose('sos')
      }
      const submit = () => {
        this.warningClose('sos')
        /* sos进行中 和 地图都有监听 */
        json.progressKey = this.sosId
        /* emergencyOpening 是否需要倒计时打开监控 */
        json.emergencyOpening = true
        json.viewSOS = true
        if (this.platePermission.dispatching) {
          this.routerPush('/dispatching')
          /* sos 消息标记已读 */
          this.$api.msgRead
            .read({ type: 6, progressKey: this.sosId })
            .then((res) => {
              if (res.code === 200 && res.data) {
                this.$store.commit('userOperationCallback', {
                  operationName: 'msgNotRead',
                  data: res.data
                })
              }
            })
        } else {
          this.$message.error('当前账号无指挥管理权限，无法立即查看')
        }
        close()
      }
      const checkLater = () => {
        json.viewSOS = false
        close()
      }
      this.$refs.newMessageReminder
        .show(
          {
            type: 'sos',
            imgUrl: 'newCase.png',
            title: 'S O S',
            content: '有新的SOS发生',
            contentSvgName: 'sos-ring',
            headerHeight: this.headerHeight
          },
          {
            sosId: this.sosId
          }
        )
        .then(submit)
        .catch(checkLater)
    },
    /* 有新险情通知 */
    newCaseNotice() {
      // 强提醒
      const strongReminder = (gasCaseId) => {
        const onClose = () => {
          this.$refs.newMessageReminder.onClose('gasCase')
        }
        const onSubmit = () => {
          const token = this.userInfo.token
          const newOpenWindow = window.open('about:blank')
          const url = `/login/?patterns=gasCase&token=${token}&gasCaseId=${gasCaseId}&dangerType=${DangerTypes.DANGER_MODE}`
          if (newOpenWindow) {
            newOpenWindow.location = url
          } else {
            this.$deleteConfirmationBox(
              {
                deleteTitleImg: 'tips',
                deleteTitle: '新窗口提示',
                deleteContent:
                  '<div><p>当前浏览器拦截新窗口打开</p><p>请前往浏览器设置后 或 复制链接到浏览器打开</p></div>',
                submitClass: 'button_function',
                submitText: '复制链接'
              },
              () => {
                this.copy(`${window.location.origin}${url}`)
              }
            )
          }
          onClose()
        }
        this.$refs.newMessageReminder
          .show({
            type: 'gasCase',
            imgUrl: 'newCase.png',
            title: '险情报警',
            content: '有新的险情',
            submitText: '立即加入',
            contentSvgName: 'device-ring',
            headerHeight: this.headerHeight
          })
          .then(onSubmit)
          .catch(onClose)
      }
      // 轻提醒
      const lightReminder = () => {
        this.$message.warning('接收到新险情，请前往消息中心或警情模式查看')
      }
      /**
       * caseSourceType
       * 1设备上报、2话机热线、3客服转单
       * */
      const callback = async ({ gasCaseId, caseSourceType }) => {
        if (caseSourceType !== 1) return
        const { data } = await this.$api.gasCase.openGasCaseModeStatus()
        if (data === 1) {
          lightReminder()
        } else {
          strongReminder(gasCaseId)
        }
      }
      this.$store.commit('addUserOperationCallback', {
        id: 'headerBox_newCaseNotice',
        operationName: 'newCaseNotice',
        callback
      })
    },
    /* 险情电话报警 */
    gasCasePhoneAlarm() {
      const newMessageReminderOnClose = () => {
        this.$refs.newMessageReminder.onClose('gasCasePhoneAlarm')
      }
      // 未接起
      const onSLCNotAnswer = () => {
        this.$message.warning('电话已挂断')
        newMessageReminderOnClose()
      }
      /* 电话响铃 */
      const onCallIn = async () => {
        const { data } = await this.$api.gasCase.openGasCaseModeStatus()
        switch (data) {
          case 1:
            this.$message.warning('有险情来电，请前往警情模式查看')
            break
          default:
            this.$message.warning('有险情来电，请接听')
            this.$refs.newMessageReminder
              .show({
                type: 'gasCasePhoneAlarm',
                imgUrl: 'newCase.png',
                title: '险情来电',
                content: '有险情来电',
                auxiliaryContent: '拿起话筒接听或挂断',
                contentSvgName: 'phoneAlarm-ring',
                headerHeight: this.headerHeight,
                closeShow: true,
                closeText: '稍后处理',
                submitShow: false
              })
              .catch(newMessageReminderOnClose)
            break
        }
      }

      /* 电话接起 */
      const onSLCAnswer = async ({ phone, callFlowNo }) => {
        const { data } = await this.$api.gasCase.openGasCaseModeStatus()
        if (!phone || data === 1) return
        const token = this.userInfo.token
        const newOpenWindow = window.open('about:blank')
        const url = `/login/?patterns=gasCase&token=${token}&gasCasePhone=${phone}&callFlowNo=${callFlowNo}`
        if (newOpenWindow) {
          newOpenWindow.location = url
        } else {
          this.$deleteConfirmationBox(
            {
              deleteTitleImg: 'tips',
              deleteTitle: '新窗口提示',
              deleteContent:
                '<div><p>当前浏览器拦截新窗口打开</p><p>请前往浏览器设置后 或 复制链接到浏览器打开</p></div>',
              submitClass: 'button_function',
              submitText: '复制链接'
            },
            () => {
              this.copy(`${window.location.origin}${url}`)
            }
          )
        }
        newMessageReminderOnClose()
      }
      const callback = async (params) => {
        const { opType, exten, phone, callFlowNo } = params || {}
        const { data = null } = await this.$api.gasCase.userExten()
        if (!data || data !== exten) return
        // data：phone（打进来的手机号）, opType（“CallIn”打进来，“SLCAnswer”接起）
        switch (opType) {
          case 'CallIn': // 打进来
            await onCallIn()
            break
          case 'SLCAnswer': // 接起
            onSLCAnswer({ phone, callFlowNo })
            break
          case 'SLCNotAnswer': // 接起
            onSLCNotAnswer({ phone })
            break
          default:
            break
        }
      }
      this.$store.commit('addUserOperationCallback', {
        id: 'headerBox_gasCasePhoneAlarm',
        operationName: 'gasCasePhoneAlarm',
        callback
      })
    },
    /* 设备监听 */
    deviceMonitoring() {
      this.$business.addSceneDevice({
        sceneName: this.sceneName,
        sceneNameCallback: (sceneName) => {
          this.sceneName = sceneName
        },
        onlySos: 1,
        callback: (res) => {
          if (res.isSos === 1) {
            if (this.alarmRecordOperatorType) {
              return
            }
            if (res.sos === 1) {
              /* sos开始 */
              this.sosId = res.progressKey
              this.newSosEventsFn()
            } else {
              this.reportSosPageFn()
              this.$store.commit('sosObjFn', {
                triggerSOS: true, // 是否触发sos校验
                viewSOS: false, // 是否需要查看sos
                emergencyOpening: false,
                progressKey: null // progressKey
              })
            }
          }
        }
      })
    },
    singleInspectionFn(deviceId) {
      this.$refs.singleInspection.show(deviceId)
    },
    /* 用户引导 (子应用的 userGuidanceFn 有差别 不能整个复制) */
    userGuidanceFn() {
      if (this.userGuidanceList && this.userGuidance) {
        if (this.userGuidanceList.length && !this.userGuidance.header) {
          this.$deleteConfirmationBox(
            {
              deleteTitleImg: 'tips',
              deleteTitle: '功能介绍',
              submitClass: 'button_function',
              deleteContent:
                '<div class="c_9DA7B2 tracking-[2px]">是否需要新手指引<span class="c_fff">导航说明</span>？</div>',
              closeText: '跳 过',
              submitText: '确 定'
            },
            () => {
              const userGuidance = window.userGuidance
              const defineStepsList = []
              /* 判断是否有权限 */
              const userGuidanceList = this.userGuidanceList.filter((v) => {
                if (v.permission) {
                  return (
                    this.permissionIdList.findIndex(
                      (permission) => permission === v.permission
                    ) !== -1
                  )
                } else {
                  return true
                }
              })
              const num = userGuidanceList.length
              /* 整理引导数据 */
              userGuidanceList.forEach((row, index) => {
                const item = {
                  element: row.element,
                  popover: { ...row.popover }
                }
                let li = ''
                for (let i = 0; i < num; i++) {
                  li += i === index ? '<li class="select"></li>' : '<li></li>'
                }
                if (index === 0) {
                  item.popover.className = 'first-step-class'
                  item.onPrevious = () => {
                    userGuidance.preventMove()
                  }
                }
                item.popover.description = `<div class="content">${item.popover.description}</div><ul class="centerBox">${li}</ul>`
                item.onHighlightStarted = () => {
                  if (row.query && row.path) {
                    this.$router.push({ path: row.path, query: row.query })
                  } else {
                    if (row.path) {
                      this.$router.push({ path: row.path })
                    }
                  }
                }
                item.onNext = () => {}
                defineStepsList.push(item)
              })
              /* 标记当前引导的板块 */
              this.$store.commit('guidanceSectionName', 'header')
              this.$nextTick(() => {
                this.defineStepsList = defineStepsList
                userGuidance.defineSteps(defineStepsList)
                userGuidance.start()
              })
            },
            () => {
              this.$store.dispatch('userGuidanceFn', {
                name: 'header',
                content: 1
              })
            }
          )
        }
      }
    },
    onunloadFn() {
      this.$store.commit('onunloadOperation')
    },
    /* 节流 */
    throttle(fn, delay) {
      return (params) => {
        if (!this.valid) {
          return false
        }
        this.valid = false
        setTimeout(() => {
          fn(params)
          this.valid = true
        }, delay)
      }
    },
    openTypeFn(type) {
      if (type) {
        this.navigationTop = type
        setTimeout(() => {
          this.openType = type
        }, 500)
      } else {
        this.openType = type
        setTimeout(() => {
          this.navigationTop = type
        }, 100)
      }
    },
    onComponentShowLayer(componentsName) {
      const index = this.hideLayerList.findIndex(
        (v) => v.componentsName === componentsName
      )
      if (index !== -1) {
        this.hideLayerList.splice(index, 1)
      }
    },
    hideLayerFn(componentsName, index) {
      const ref = this.$refs[`${componentsName}`]
      if (ref) {
        ref.showLayerFn()
        this.$nextTick(() => {
          this.hideLayerList.splice(index, 1)
        })
      }
    },
    /* 广播中的设备改变 */
    broadcasRoomDeviceChange(deviceList = []) {
      this.$store.commit(
        'broadcastDeviceIdListFn',
        JSON.parse(JSON.stringify(deviceList))
      )
    },
    /* 加入广播 */
    broadcast_addNewRoomFn(deviceList) {
      this.$store.commit('callComponent', {
        name: 'broadcast',
        data: {
          methodName: 'show',
          params: deviceList,
          paramsV2: true
        }
      })
    },
    /* 设备移除房间 */
    broadcast_closeTheRoom(deviceList) {
      this.$store.commit('callComponent', {
        name: 'broadcast',
        data: {
          methodName: 'closeTheRoom',
          params: deviceList,
          paramsV2: true
        }
      })
    },
    /* 关闭房间 */
    broadcast_closeAllRoomsFn() {
      this.$store.commit('callComponent', {
        name: 'broadcast',
        data: {
          methodName: 'closeAllRoomsFn'
        }
      })
    },
    hideLayerListFn(obj) {
      this.openTypeFn(true)
      const index = this.hideLayerList.findIndex(
        (v) => v.componentsName === obj.componentsName
      )
      if (index === -1) {
        this.hideLayerList.push({
          title: obj.title,
          componentsName: obj.componentsName
        })
      }
    },
    mqConnectionFn() {
      this.$sdk.initSocket(
        {
          ...this.userInfo.mediumMqConnectInfo,
          sdkParams: sdkParams || {}
        },
        () => {
          this.$set(this.mqObj, 'sdkType', true)
          this.$store.commit('mainThisFn', this.$sdk)
          this.$nextTick(() => {
            this.connectionVerification()
            // this.$sdk.callCallbackObject('audioProducerBroken', (res) => {
            //   this.$message.error('话语权被抢断')
            //   this.$store.commit('audioContextTypeFn', false)
            //   this.$store.commit('sdkCallCallbackObject', {
            //     name: 'examine_audioContextType',
            //     res: false
            //   })
            // })
          })
        }
      )
      const businessParams = {
        ...this.userInfo.businessMqConnectInfo,
        userId: this.userInfo.id
      }
      if (this.separatePageName) {
        businessParams.heartbeatData = {
          from: this.separatePageName
        }
      }
      this.$business.initSocket(businessParams, () => {
        this.$set(this.mqObj, 'businessType', true)
        this.$store.commit('businessSdkFn', this.$business)
        this.$nextTick(() => {
          this.connectionVerification()
          this.$business.callCallbackObject('equipmentStatus', (res) => {
            this.$store.commit('businessCallCallbackObject', {
              name: 'equipmentStatus',
              res
            })
          })
        })
      })
    },
    /* 直接新标签页会议 */
    validCodeCreate(uniqueId) {
      this.$api.videoMeeting.validCodeCreate(uniqueId).then((res) => {
        if (res.code === 200) {
          const validCode = res.data
          const newOpenWindow = window.open('about:blank')
          const url = `/login/?validCode=${validCode}&uniqueId=${uniqueId}&patterns=videoMeeting`
          if (newOpenWindow) {
            newOpenWindow.location = url
          }
          if (newOpenWindow) {
            this.$message.success('操作成功')
          } else {
            this.$deleteConfirmationBox(
              {
                deleteTitleImg: 'tips',
                deleteTitle: '加入会议提示',
                deleteContent:
                  '<div><p>当前浏览器拦截新窗口打开</p><p>请前往设置后在进入会议 或 复制链接到浏览器打开</p></div>',
                submitClass: 'button_function',
                submitText: '复制链接'
              },
              () => {
                this.copy(`${window.location.origin}${url}`)
              }
            )
          }
        }
      })
    },
    copy(random) {
      var input = document.createElement('input') // 创建input对象
      input.value = random // 设置复制内容
      document.body.appendChild(input) // 添加临时实例
      input.select() // 选择实例内容
      document.execCommand('Copy') // 执行复制
      document.body.removeChild(input) // 删除临时实例
      this.$message.success('复制成功')
    },
    /* 报警人进入警情的mq通知  */
    alarmPersonInCase() {
      this.$store.commit('addUserOperationCallback', {
        id: 'newCase_headerBox',
        operationName: 'newCase',
        callback: (res) => {
          this.$message.success('新警情提醒，请前往指挥中心查看')
        }
      })
      this.$store.commit('addUserOperationCallback', {
        id: 'alarmPersonInCase_headerBox',
        operationName: 'alarmPersonInCase',
        callback: (res) => {
          if (this.alarmPersonInCaseType) {
            this.$message.success('报警人员，已进入第一现场')
            return
          }
          this.alarmPersonInCaseType = true
          this.$deleteConfirmationBox(
            {
              deleteTitleImg: 'tips',
              deleteTitle: '警情提醒',
              submitClass: 'button_function',
              deleteContent: `<div>
                  <p>报警人员，已进入第一现场</p>
                  <p class="c_auxiliary">${res.address}</p>
                </div>`,
              closeText: '稍后处理',
              submitText: '立即查看'
            },
            () => {
              if (this.platePermission.dispatching) {
                this.routerPush('/dispatching')
                window.sessionStorage.setItem(
                  'openDispatch',
                  JSON.stringify({
                    id: res.caseId,
                    lng: res.lng,
                    lat: res.lat
                  })
                )
                this.$store.commit('alarmPerson', { ...res })
              } else {
                this.$message.error('当前账号无指挥管理权限，无法立即查看')
              }
              this.alarmPersonInCaseType = false
            },
            () => {
              this.alarmPersonInCaseType = false
            }
          )
        }
      })
    },
    /* 收到视频会议邀请通知 */
    videoMeetingInviteFn() {
      this.$store.commit('addUserOperationCallback', {
        id: 'videoMeetingInvite_headerBox',
        operationName: 'videoMeetingInvite',
        callback: (res) => {
          if (res.uniqueId) {
            if (this.beingInvitedType) {
              this.$message.success('收到新的邀请通知')
            } else {
              this.beingInvitedType = true
              this.$deleteConfirmationBox(
                {
                  deleteTitleImg: 'tips',
                  deleteTitle: '协同提示',
                  submitClass: 'button_function',
                  deleteContent: `<div>
                                  <p class="fs_14">${res.inviteUserName}邀请你进入协同房间</p>
                                  <p class="fs_12 c_auxiliary">${res.meetingName}</p>
                                </div>`,
                  closeText: '关 闭',
                  submitText: '进 入'
                },
                () => {
                  this.beingInvitedType = false
                  this.validCodeCreate(res.uniqueId)
                },
                () => {
                  this.beingInvitedType = false
                }
              )
            }
          }
        }
      })
    },
    /* MQ连接验证 */
    connectionVerification() {
      console.log('connectionVerification', JSON.stringify(this.mqObj))
      if (
        this.mqObj.sdkType &&
        this.mqObj.businessType &&
        this.mqObj.mapConfigurationType
      ) {
        this.videoMeetingInviteFn()
        this.alarmPersonInCase()
        this.colorObjectFn()
        /* 设备监听 */
        this.deviceMonitoring()
        /* 有新险情通知 */
        this.newCaseNotice()
        /* 险情电话报警 */
        this.gasCasePhoneAlarm()
        // 案件通知
        this.casesNotice()
        // 警单通知
        this.alarmRecord()
        // 取消警报
        this.warningUserClose()
        /* 当mq 都连接完成后在发起用户引导 */
        this.userGuidanceFn()
        // 批量订阅设备状态()
        this.bulkSubscriptionDevice()
        this.$store.commit('sdkCallCallbackObject', {
          name: 'mqConnectionType',
          res: true
        })
        this.$store.commit('mqObjFn', {
          name: 'sdkType',
          type: true
        })
        this.$store.commit('mqObjFn', {
          name: 'mapConfigurationType',
          type: true
        })
      } else {
        this.$store.commit('sdkCallCallbackObject', {
          name: 'mqConnectionType',
          res: false
        })
        this.$store.commit('mqObjFn', {
          name: 'businessType',
          type: true
        })
      }
    },
    // 请求用户能访问的设备，获取设备后订阅设备 用于后续的监听设备状态
    bulkSubscriptionDevice() {
      this.$api.findAll('device').then((res) => {
        if (res.code === 200) {
          this.$business.bulkSubscriptionDevice(res.data || [])
        }
      })
    },
    multiPersonIntercomClose(obj) {
      if (
        this.checkParameters &&
        this.checkParameters.checkStyle !== 3 &&
        this.$store.getters.departmentMultipleChecks.length === 0
      ) {
        this.personnelCheckAddShow(obj)
      }
    },
    personnelCheckAddShow(obj) {
      if (this.checkParameters) {
        const checkParameters = { ...this.checkParameters }
        checkParameters.linkStatus = obj.type ? 1 : 0
        if (obj.list) {
          checkParameters.list = obj.list.map((device) => {
            return {
              orgName: device.orgName,
              name: device.name,
              devName: device.devName
            }
          })
        }
        this.$refs.personnelCheckAdd.establishFn(checkParameters, (res) => {
          this.$refs.personnelCheckAdd.showV2(res)
        })
        this.$store.commit('checkParametersFn', null)
      }
    },
    pathFn(path) {
      if (this.$route.path) {
        const pathList = this.$route.path.split('/')
        return `/${pathList[1]}`.indexOf(path) !== -1
      }
      return false
    },
    routerPush(name) {
      if (name) {
        this.$router.push(name)
      } else {
        this.$message.error('功能未开发')
      }
    },
    /* 退出登录 */
    methodLogout() {
      this.$api.login.logout().then((res) => {
        if (res.code === 200) {
          if (this.$sdk.connectionStatus()) {
            /* 断开mq */
            this.$sdk.disconnectCompletely()
          }
          if (this.$business.connectionStatus()) {
            /* 批量取消订阅设备 */
            this.$business.resetDeviceIdList([])
            /* 断开mq */
            this.$business.disconnectCompletely()
          }
          if (res.data) {
            window.location.href = res.data
            this.$store.commit('logout')
          } else {
            this.$router.push({ path: '/login' })
            this.$store.commit('logout')
            location.reload()
          }
        }
      })
    },
    loadScript(url) {
      return new Promise((resolve) => {
        let scriptType = false
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.setAttribute('data-callType', 'callScript')
        if (script.readyState) {
          // IE浏览器
          script.onreadystatechange = function () {
            if (
              script.readyState === 'loaded' ||
              script.readyState === 'complete'
            ) {
              script.onreadystatechange = null // 删除事件，避免重复触发
              resolve()
              scriptType = true
            }
          }
        } else {
          script.onload = () => {
            resolve()
            scriptType = true
          }
        }
        script.src = url
        document.querySelector('head').appendChild(script)
        setTimeout(() => {
          if (!scriptType) {
            this.$message.warning('请求资源失败')
            resolve()
          }
        }, 10000)
      })
    },
    /* 地图配置 */
    mapSettingInfoFn() {
      this.$api.common
        .mapSettingInfo({
          jgsj: 1
        })
        .then((res) => {
          if (res.code === 200) {
            const mapSettingInfo = res.data
            if (mapSettingInfo && mapSettingInfo.settingJson) {
              const settingJson = JSON.parse(mapSettingInfo.settingJson)
              if (!settingJson.satelliteMap) {
                settingJson.satelliteMap = mapSettingInfo.satelliteMap
              }
              this.$store.commit('mapSettingInfoFn', settingJson)
            }
          }
        })
    },
    /* 获取 地图层级配置信息 */
    mapLayerConfigFn(layersServer) {
      const { serverUrl, showMinZoom, showMaxZoom } = layersServer || {}
      this.$api.common.mapLayerConfig().then((res) => {
        if (res.code === 200) {
          const mapLayerConfigList = res.data || []
          const boxSearchList = []
          const polyIdList = [] // { layerId: ID, index: 1}
          const polylineIdList = [] // { layerId: ID, index: 1}
          const defaultSelectIdList = [] // { layerId: ID, index: 1}
          mapLayerConfigList.forEach((item, itemIndex) => {
            const layerId = item.layerId
            const layerType = item.layerType
            /* 默认展示 */
            if (item.defaultDisplayType) {
              defaultSelectIdList.push({
                layerId,
                itemIndex: itemIndex
              })
            }
            /* 地图类型才生效 */
            if (layerType === 1) {
              /* 可拉框查找 */
              if (item.boxSearchType) {
                const row = boxSearchList.find((v) => v.id === layerType)
                if (row) {
                  row.layerIdList.push(layerId)
                } else {
                  boxSearchList.push({
                    id: 1,
                    label: '管道',
                    layerIdList: [layerId]
                  })
                }
              }
              /* marker 打点 */
              if (item.markerType) {
                polylineIdList.push({
                  layerId,
                  itemIndex: itemIndex
                })
              }
            } else if (layerType === 2) {
              if (item.boxSearchType) {
                const row = boxSearchList.find((v) => v.id === layerType)
                if (row) {
                  row.layerIdList.push(layerId)
                } else {
                  boxSearchList.push({
                    id: 2,
                    label: '阀门',
                    layerIdList: [layerId]
                  })
                }
              }
              /* marker 打点 */
              if (item.markerType) {
                polyIdList.push({
                  layerId,
                  itemIndex: itemIndex
                })
              }
            }
          })
          this.$nextTick(() => {
            const defaultState = defaultSelectIdList.length !== 0
            this.$store.commit('setMapLayerConfigList', mapLayerConfigList)
            this.$store.commit('setLayersServer', {
              serverUrl,
              showMinZoom,
              showMaxZoom,
              defaultState, // 默认是否开启
              defaultSelectIdList, // 默认展示图层
              boxSearchList, // 拉框查找
              rangeSearchList: [], // 范围搜索 （定点搜索）
              polyIdList, // 点
              polylineIdList // 线
            })
          })
        }
      })
    },
    setMapServer(data) {
      return new Promise((resolve, reject) => {
        // const data = {
        //   layersServer: {
        //     id: 8,
        //     createDtm: '2023-06-30 17:50:03',
        //     updateDtm: '2023-12-19 14:54:24',
        //     tenantId: '4cdd37aa2bc047b4b76f96d564b53d93',
        //     configType: 1,
        //     serverUrl:
        //       'http://192.168.11.206:6080/arcgis/rest/services/zhgw84/MapServer',
        //     defaultState: 'false',
        //     showMinZoom: '16',
        //     showMaxZoom: '18',
        //     polyIdList: '1,2',
        //     polylineIdList: '9,10,11,12,13,14,15',
        //     mapKey: '3e743104485221ac8d573da521a7617b',
        //     mapType: 'gaode',
        //     defaultMapStyle:
        //       'amap://styles/eeefd028855fff4361276ff7210b0961',
        //     bmCorrect: 1,
        //     maxZoom: '18',
        //     layersUrl:
        //       'http://t7.tianditu.gov.cn/vec_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=vec&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk={mapKey},http://t7.tianditu.gov.cn/cva_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cva&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILECOL={x}&TILEROW={y}&TILEMATRIX={z}&tk={mapKey}',
        //     mapPoiType: 'gaode',
        //     satelliteMap: 2
        //   },
        //   fourCornersList: [],
        //   fixedPointList: [],
        //   poiServer: {
        //     id: 71,
        //     createDtm: '2023-07-26 16:34:31',
        //     updateDtm: '2023-09-25 15:06:28',
        //     tenantId: '4cdd37aa2bc047b4b76f96d564b53d93',
        //     configType: 4,
        //     serverUrl: 'https://restapi.amap.com',
        //     mapKey: '3e743104485221ac8d573da521a7617b',
        //     mapPoiType: 'gaode',
        //     poiCoordinate: 1,
        //     city: '广东省-深圳市',
        //     citylimit: 1
        //   },
        //   bmCorrect: 1,
        //   maxZoom: '18',
        //   layersUrl:
        //     'http://t7.tianditu.gov.cn/vec_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=vec&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk={mapKey},http://t7.tianditu.gov.cn/cva_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cva&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILECOL={x}&TILEROW={y}&TILEMATRIX={z}&tk={mapKey}',
        //   mapKey: '3e743104485221ac8d573da521a7617b',
        //   defaultMapStyle: 'amap://styles/eeefd028855fff4361276ff7210b0961',
        //   mapSk: null,
        //   bmHttpsUrl: null,
        //   bmUiName: null,
        //   mapType: 'gaode',
        //   mapPoiType: 'gaode',
        //   satelliteMap: 2
        // }
        // const data = {
        //   bmCorrect: 0,
        //   bmHttpsUrl: 'https://192.168.0.125:3000',
        //   bmUiName: 'bigemap.d8su44rd',
        //   mapType: 'bigmap',
        //   mapPoiType: 'bigmap',
        //   poiCoordinate: 1,
        //   satelliteMap: 2
        // }
        // const data = {
        //   layersServer: {
        //     id: 1,
        //     createDtm: '2023-06-19 12:19:31',
        //     updateDtm: '2024-01-12 18:20:03',
        //     tenantId: 'default',
        //     configType: 1,
        //     serverUrl:
        //       'http://121.36.72.25:6080/arcgis/rest/services/zhgw84/MapServer',
        //     defaultState: 'false',
        //     defaultSelectIdList: null,
        //     showMinZoom: '16',
        //     showMaxZoom: '18',
        //     polyIdList: '13',
        //     mapKey: 'db848f59ddd50debfb774811bbdfb327',
        //     mapType: 'leafletjs',
        //     defaultMapStyle:
        //       'amap://styles/eeefd028855fff4361276ff7210b0961',
        //     bmHttpsUrl: 'https://192.168.0.125:3000',
        //     bmUiName: 'bigemap.d8su44rd',
        //     bmCorrect: 0,
        //     maxZoom: '18',
        //     layersUrl:
        //       'http://t7.tianditu.gov.cn/vec_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=vec&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk={mapKey},http://t7.tianditu.gov.cn/cva_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cva&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILECOL={x}&TILEROW={y}&TILEMATRIX={z}&tk={mapKey}',
        //     mapPoiType: 'gaode',
        //     satelliteMap: 1,
        //   },
        //   fourCornersList: [],
        //   fixedPointList: [],
        //   poiServer: {},
        //   bmCorrect: 0,
        //   maxZoom: '18',
        //   layersUrl:
        //     'http://t7.tianditu.gov.cn/vec_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=vec&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk={mapKey},http://t7.tianditu.gov.cn/cva_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cva&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILECOL={x}&TILEROW={y}&TILEMATRIX={z}&tk={mapKey}',
        //   mapKey: 'db848f59ddd50debfb774811bbdfb327',
        //   defaultMapStyle: 'amap://styles/eeefd028855fff4361276ff7210b0961',
        //   mapSk: null,
        //   bmHttpsUrl: 'https://192.168.0.125:3000',
        //   bmUiName: 'bigemap.d8su44rd',
        //   mapType: 'leafletjs',s
        //   mapPoiType: 'gaode',
        //   satelliteMap: 1
        // }
        data.mapCoordinate =
          typeof data.bmCorrect === 'number'
            ? data.bmCorrect
            : data.mapCoordinate
        data.serverCoordinate = 1
        data.poiServer = data.poiServer || {}
        window.sessionStorage.setItem('mapConfig', JSON.stringify(data))
        /* 地图poi 搜索 */
        // mapPoiType = {
        //   gaode: '高德',
        //   tianditu: '天地图',
        //   local: '本地',
        //   google: '谷歌',
        // }
        let city = data.poiServer.city || ''
        city = city.split('-')
        const poiServer = {
          mapPoiType: 'gaode',
          ...data.poiServer,
          city: city[city.length - 1],
          // city: '珠海市',
          // citylimit: true,
          mapCoordinate: data.mapCoordinate,
          mapSk: data.mapSk
        }
        this.$store.commit('setPoiServer', poiServer)
        this.$store.commit('setArcgisMapConfig', data)
        window.hyMap = new Map({
          poiServer
        })
        // 地图判断
        const mapType = data.mapType || 'gaode'
        if (mapType === 'leafletjs') {
          const { layersServer, layersUrl, mapKey } = data
          const commonOptions = {
            bmCorrect: data.bmCorrect || 0,
            poiCoordinate: data.poiCoordinate,
            maxZoom: data.maxZoom,
            layersUrlList: [],
            mapKey
          }
          if (layersUrl) {
            const layersUrlList = layersUrl.split(',')
            commonOptions.layersUrlList = layersUrlList.map((url) => {
              const index = url.indexOf('{mapKey}')
              if (index !== -1) {
                return url.replace('{mapKey}', mapKey)
              }
              return url
            })
          }
          this.$nextTick(() => {
            this.$store.commit('setCommonOptions', commonOptions)
            this.mapLayerConfigFn(layersServer || {})
            this.$nextTick(() => {
              resolve({ mapType })
            })
          })
        } else if (mapType === 'bigmap') {
          this.$store.commit('setCommonOptions', {
            bmHttpsUrl: data.bmHttpsUrl,
            bmCorrect: data.bmCorrect || 0,
            poiCoordinate: data.poiCoordinate,
            bmUiName: data.bmUiName
          })
          this.$nextTick(() => {
            resolve({ mapType })
          })
        } else if (mapType === 'gaode') {
          const { mapSk, mapKey, defaultMapStyle } = data
          if (mapSk) {
            window._AMapSecurityConfig = {
              securityJsCode: mapSk
            }
          }
          const url = `//webapi.amap.com/maps?v=1.4.15&key=${
            mapKey || 'mapKey3e743104485221ac8d573da521a7617b'
          }&plugin=AMap.MouseTool,AMap.Geolocation,AMap.ControlBar,Map3D,AMap.DistrictSearch,AMap.RangingTool,AMap.PolyEditor,AMap,Autocomplete,AMap.PlaceSearch`
          if (url) {
            this.loadScript(url).then(() => {
              setTimeout(() => {
                this.$nextTick(() => {
                  resolve({ mapType })
                })
              }, 500)
            })
          }
          this.$store.commit('setCommonOptions', {
            bmCorrect: data.bmCorrect || 0
          })
          this.$store.commit('setMapConfigurationFn', {
            label: 'currencyMapStyle',
            value: this.mapConfiguration.currencyMapStyle
          })
          this.$store.commit('setMapConfigurationFn', {
            label: 'defaultMapStyle',
            value: defaultMapStyle || this.mapConfiguration.defaultMapStyle
          })
        }
      })
    },
    /* 获取arcgis地图配置信息 */
    arcgisMapConfigFn() {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        if (this.initMapServer) {
          await this.setMapServer(this.initMapServer)
          resolve()
        } else {
          const res = await this.$api.common.arcgisMapConfig()
          if (res.code === 200) {
            const data = res.data || {}
            await this.setMapServer(data)
            resolve()
          }
        }
      })
    },

    /* =========== 取消报警 =========== */
    warningUserClose() {
      const callback = ({ closeop = '' }) => {
        this.$refs.newMessageReminder.onClose(closeop)
      }
      this.$store.commit('addUserOperationCallback', {
        id: 'headerBox_warningUserClose',
        operationName: 'warningUserClose',
        callback
      })
    },
    // 一键退出所有媒体房间
    closeByClientId() {
      this.$api.media.closeByClientId({ token: this.userInfo.token })
      // const formData = new FormData()
      // formData.append('token', this.userInfo.token)
      // const url = 'http://192.168.0.222:20000/api/admin/media/closeByClientId'
      // navigator.sendBeacon(url, formData)
      // 阻塞进程保证请求发送成功
      for (let i = 1; i < 10000; i++) {
        for (let m = 1; m < 10000; m++) {
          continue
        }
      }
    }
  },
  created() {
    this.setListeningToMqtt()
  },
  mounted() {
    /* 判断当前账号是否有 指挥中心权限 */
    const permissionIdList = window.sessionStorage.getItem('permissionIdList')
    this.permissionIdList =
      permissionIdList && permissionIdList.length
        ? JSON.parse(permissionIdList)
        : []
    if (this.permissionIdList.length) {
      const platePermission = {}
      const index = this.permissionIdList.findIndex((v) => v === 'commands')
      this.$set(platePermission, 'dispatching', index !== -1)
      this.$store.commit('setPlatePermission', platePermission)
    }
    /* 监听页面刷新 */
    window.onunload = () => {
      this.onunloadFn()
      return ''
    }
    /* 自定义点击回调 */
    window.customClick = (obj) => {
      this.$store.commit('implementCustomClick', obj)
    }
    /* 屏幕改变触发回调 */
    window.onresize = () => {
      if (window.innerWidth >= 1280) {
        this.throttle(() => {
          /* 执行 onresize 回调 */
          this.$store.commit('implementOnresize')
        }, 1000)()
      }
    }
    this.$store.commit('mqObjFn', {
      name: 'sdkType',
      type: false
    })
    this.$store.commit('mqObjFn', {
      name: 'businessType',
      type: false
    })
    this.$store.commit('mqObjFn', {
      name: 'mapConfigurationType',
      type: false
    })
    const userInfo = this.userInfo
    this.mapSettingInfoFn()
    this.arcgisMapConfigFn().then(() => {
      this.$set(this.mqObj, 'mapConfigurationType', true)
      this.connectionVerification()
    })
    if (userInfo) {
      this.$store.commit('setUserInfo', userInfo)
      this.mqConnectionFn()
    }
    this.$nextTick(() => {
      this.$store.commit('logoutFn', this.methodLogout)
      this.$store.commit('setCommandLogSave', this.commandLogSave)
    })
    /* 组件挂载到vuex */
    this.componentMethodListFn()
    /* 设备类型 */
    this.deviceTypeFindAll()
    // 关闭时发送异步请求
    window.addEventListener('beforeunload', () => {
      this.closeByClientId()
    })
  }
}
