export default {
  bind(el, binding, vnode) {
    const change = binding.value?.change
    const dialogHeaderEl = el.querySelector('.el-dialog__header')
    // const dragDom = el.querySelector('.el-dialog')
    const dragDom = el
    dialogHeaderEl.style.cssText += ';cursor:move;'
    // dragDom.style.cssText += ';top:50%;'

    // 获取原有属性 ie dom元素.currentStyle 火狐谷歌 window.getComputedStyle(dom元素, null);
    const getStyle = (function () {
      if (window.document.currentStyle) {
        return (dom, attr) => dom.currentStyle[attr]
      } else {
        return (dom, attr) => getComputedStyle(dom, false)[attr]
      }
    })()
    const startFn = (e) => {
      // 鼠标按下，计算当前元素距离可视区的距离
      // const disX = e.clientX - dialogHeaderEl.offsetLeft
      const disX = e.clientX - dialogHeaderEl.offsetLeft
      const disY = e.clientY - dialogHeaderEl.offsetTop
      const dragDomWidth = dragDom.offsetWidth
      const dragDomHeight = dragDom.offsetHeight
      const screenWidth = document.body.clientWidth
      const screenHeight = document.body.clientHeight
      const minDragDomLeft = dragDom.offsetLeft
      const maxDragDomLeft = screenWidth - dragDom.offsetLeft - dragDomWidth
      const minDragDomTop = dragDom.offsetTop
      const maxDragDomTop = screenHeight - dragDom.offsetTop - dragDomHeight
      // 获取到的值带px 正则匹配替换
      let styL = getStyle(dragDom, 'left')
      let styT = getStyle(dragDom, 'top')
      let leftType = null
      let finalLeft = null
      let topType = null
      let finalTop = null
      if (styL.includes('%')) {
        styL = +document.body.clientWidth * (+styL.replace(/%/g, '') / 100)
        styT = +document.body.clientHeight * (+styT.replace(/%/g, '') / 100)
      } else {
        styL = +styL.replace(/\px/g, '')
        styT = +styT.replace(/\px/g, '')
      }
      const moveFn = function (e) {
        // 通过事件委托，计算移动的距离
        let left = e.clientX - disX
        let top = e.clientY - disY

        // 边界处理
        if (-left > minDragDomLeft) {
          left = -minDragDomLeft
          leftType = 'left'
        } else if (left > maxDragDomLeft) {
          left = maxDragDomLeft
          leftType = 'right'
        } else {
          leftType = null
        }

        if (-top > minDragDomTop) {
          top = -minDragDomTop
          topType = 'top'
        } else if (top > maxDragDomTop) {
          top = maxDragDomTop
          topType = 'bottom'
        } else {
          topType = null
        }
        finalLeft = left + styL
        finalTop = top + styT
        // 移动当前元素
        dragDom.style.cssText += `;left:${finalLeft}px;top:${finalTop}px;`
        // emit onDrag event
        // vnode.child.$emit('dragDialog')
      }
      const endFn = function (e) {
        if (change) {
          change({
            left: finalLeft,
            leftType,
            top: finalTop,
            topType
          })
        }
        document.onmousemove = null
        document.onmouseup = null
      }
      document.onmousemove = moveFn
      document.touchmove = moveFn
      document.onmouseup = endFn
      document.touchend = endFn
    }
    dialogHeaderEl.onmousedown = startFn
    dialogHeaderEl.touchstart = startFn
  }
}
