// 解决浏览器切换其它窗口最小化定时器失效方案
function create(f) {
  var blob = new Blob(['(' + f +')()']);
  var url = window.URL.createObjectURL(blob);
  var worker = new Worker(url);
  return worker;
}

export const createWorker = (callback, time) => {
  var pollingWorker = create(`function (e) {
    setInterval(function () {
      this.postMessage(null)
    }, ${time})
  }`);
  pollingWorker.onmessage = callback
  return pollingWorker
}

export const stopWorker = (vm) => {
  try {
    vm&&vm.terminate()
  } catch (err) {
    console.log(err)
  }
}
