import checkPasswordStrength from '@utils/checkPasswordStrength'
const {
  headConfigure
} = require(`@/version/${process.env.VUE_APP_BUILD_VERION}.js`)
export default {
  data() {
    return {
      headConfigure: headConfigure,
      componentMethodList: ['instructionProgress'],
      haveInHandNum: null, // 进行中数量
      msgNotReadTotal: null, // 新消息统计
      visible: false,
      fullscreen: false,
      form: {
        oldPsw: null,
        newPsw: null
      },
      menuListLeft: [],
      menuListRight: []
    }
  },
  methods: {
    // 手册
    handbookShow() {
      this.$refs.handbookRef.show()
    },
    /* 打开工单 */
    workOrderShow() {
      this.$refs.workOrder.show()
    },
    reportPopupShow() {
      this.$refs.reportPopup.show()
    },
    /* 获取平台设置数据 */
    businessSystemSettingFn() {
      this.$api.setting.businessSystemSetting().then((res) => {
        if (res.code === 200) {
          const data = res.data || {}
          this.$store.commit('businessSystemSetting', {
            systemName: data.systemName || this.headConfigure.title,
            systemNameLabelSize: data.systemNameLabelSize,
            labelIcon: data.labelIcon,
            systemLogo: data.systemLogo
          })
        }
      })
    },
    /* 新消息数据统计 */
    msgNotReadTotalFn(total) {
      this.msgNotReadTotal = total
    },
    /* 指令数据统计 */
    directiveListFn() {
      this.haveInHandNum = this.directiveList.filter(
        (v) => v.state === 1 || v.state === 0
      ).length
      if (this.directiveList.length && this.haveInHandNum) {
        this.instructionProgressShow()
      }
    },
    /* 指令进度 */
    instructionProgressShow() {
      if (this.mainStore) {
        this.mainStore.commit('callComponent', {
          name: 'instructionProgress',
          data: {
            methodName: 'show',
            params: {}
          }
        })
      } else {
        this.$store.commit('callComponent', {
          name: 'instructionProgress',
          data: {
            methodName: 'show',
            params: {}
          }
        })
      }
    },
    /* 文件进度 */
    fileUploadShow() {
      if (this.mainStore) {
        this.mainStore.commit('callComponent', {
          name: 'fileUpload',
          data: {
            methodName: 'show',
            params: {}
          }
        })
      } else {
        this.$store.commit('callComponent', {
          name: 'fileUpload',
          data: {
            methodName: 'show',
            params: {}
          }
        })
      }
    },
    handleFullScreen() {
      const isFullScreen = document.fullscreenElement
      const element = document.documentElement
      if (isFullScreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        }
        this.fullscreen = false
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen()
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen()
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen()
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen()
        }
        this.fullscreen = true
      }
    },
    close() {
      this.visible = false
      this.form = {}
    },
    updatePsw() {
      if (!this.form.oldPsw) {
        this.$message.error(this.$t('tips.tips15'))
        return false
      }
      if (!this.form.newPsw) {
        this.$message.error(this.$t('tips.tips16'))
        return false
      }
      if (
        !checkPasswordStrength.checkPassword(
          this.form.newPsw,
          this.$store.getters.userInfo.account
        )
      ) {
        return false
      }
      const json = {
        ...this.form
      }
      json.oldPsw = this.$rsa(this.form.oldPsw)
      json.newPsw = this.$rsa(this.form.newPsw)
      this.form.oldPsw = null
      this.form.newPsw = null
      this.$api.login.updatePsw(json).then((res) => {
        this.$message.success('操作成功')
        this.$emit('on-methodLogout')
      })
    },
    updatePasswordShow() {
      this.visible = true
      this.form = {
        oldPsw: null,
        newPsw: null
      }
    },
    routerPush(name) {
      const mouseTextPrompts = document.getElementById('mouseTextPrompts')
      if (mouseTextPrompts) {
        mouseTextPrompts.style.display = 'none'
      }
      /* 当也换到监控中心的时候需要把直播弹窗关闭 */
      if (name === '/monitor') {
        this.$store.commit('callComponent', {
          name: 'multipleChecks',
          data: {
            methodName: 'switchCloseFn'
          }
        })
      }
      this.$emit('on-routerPush', name)
    },
    methodLogout() {
      this.$emit('on-methodLogout')
    },
    pathFn(path) {
      if (this.$route.path) {
        const pathList = this.$route.path.split('/')
        return `/${pathList[1]}`.indexOf(path) !== -1
      }
      return false
    }
  }
}
