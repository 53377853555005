import Axios from '@/http'
export default {
  /* 警单（查询） */
  select(params) {
    return new Promise((resolve, reject) => {
      Axios.post('alarmRecord/select', params).then((res) => {
        resolve(res)
      })
    })
  },
  /* 联动范围 */
  linkScopeConfig() {
    return new Promise((resolve, reject) => {
      Axios.post('alarmRecord/linkScopeConfig').then((res) => {
        resolve(res)
      })
    })
  },
  /* 接警员信息（是否是接警人员、当前接警状态） */
  operatorInfo() {
    return new Promise((resolve, reject) => {
      Axios.post('alarmRecord/operatorInfo').then((res) => {
        resolve(res)
      })
    })
  },
  /* 录制房间（开始录制or结束录制） */
  recordRoomOperate(params) {
    return new Promise((resolve, reject) => {
      Axios.post('alarmRecord/recordRoomOperate', params).then((res) => {
        resolve(res)
      })
    })
  },
  /* 录制房间（创建） */
  recordRoomCreate(params) {
    return new Promise((resolve, reject) => {
      Axios.post('alarmRecord/recordRoomCreate', params).then((res) => {
        resolve(res)
      })
    })
  },
  /* 警单（接警） */
  dealStart(params) {
    return new Promise((resolve, reject) => {
      Axios.post('alarmRecord/dealStart', params).then((res) => {
        resolve(res)
      })
    })
  },
  /* 警单（结束） */
  dealEnd(params) {
    return new Promise((resolve, reject) => {
      Axios.post('alarmRecord/dealEnd', params).then((res) => {
        resolve(res)
      })
    })
  }
}
