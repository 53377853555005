// import store from './store/'
import httpConfig from './http/config'
// import Vue from 'vue'
const {
  entry,
  messages
} = require(`@/version/${process.env.VUE_APP_BUILD_VERION}.js`)

const microApps = [
  // {
  //   // 应用名称
  //   name: 'command',
  //   /*
  //    * 默认会加载这个html，解析里面的js 动态的执行 （子应用必须支持跨域）
  //    * 内容使用的是 fetch
  //    * 在.env中VUE_APP_COMMAND 与父应用的配置一致
  //    */
  //   entry: process.env.VUE_APP_COMMAND,
  //   // 挂载子应用，到主的那个元素上
  //   container: '#appContainer',
  //   /*
  //   * 激活的规则 也就是子应用路径
  //   * 也就是把 container 挂载到 子应用里面去
  //   * */
  //   activeRule: '/command'
  // },
  {
    name: 'violation',
    titleName: '违章管理',
    entry: entry.violation,
    container: '#appContainer',
    activeRule: '/violationSystem',
    props: {}
  },
  {
    name: 'operator',
    titleName: '运营中心',
    entry: entry.operator,
    externals: true,
    container: '#appContainer',
    activeRule: '/operator',
    props: {}
  },
  {
    name: 'dispatching',
    titleName: '指挥中心',
    entry: entry.dispatching,
    container: '#appContainer',
    activeRule: '/dispatching',
    nginxUrl: '/web-dispatching',
    props: {}
  },
  {
    name: 'mediaCenter',
    titleName: '回放中心',
    entry: entry.mediaCenter,
    container: '#appContainer',
    activeRule: '/mediaCenter',
    nginxUrl: '/web-mediaCenter/mediaCenter',
    props: {}
  },
  {
    name: 'mission',
    entry: entry.mission,
    titleName: '任务中心',
    container: '#appContainer',
    activeRule: '/mission',
    props: {}
  },
  {
    name: 'alert',
    entry: entry.alert,
    titleName: '警情中心',
    container: '#appContainer',
    activeRule: '/alert',
    props: {}
  },
  {
    name: 'setting',
    entry: entry.setting,
    titleName: '设置中心',
    container: '#appContainer',
    activeRule: '/setting',
    props: {}
  },
  {
    name: 'monitor',
    titleName: '监控中心',
    entry: entry.monitor,
    container: '#appContainer',
    activeRule: '/monitor',
    props: {}
  },
  {
    name: 'dailyCheck',
    titleName: '考勤中心',
    entry: entry.dailyCheck,
    container: '#appContainer',
    activeRule: '/dailyCheck',
    props: {}
  },
  {
    name: 'risk',
    titleName: '风险管控',
    entry: entry.risk,
    container: '#appContainer',
    activeRule: '/risk',
    props: {}
  },
  {
    name: 'examine',
    titleName: '检查中心',
    entry: entry.examine,
    container: '#appContainer',
    activeRule: '/examine',
    props: {}
  },
  {
    name: 'alarmCenter',
    titleName: '报警中心',
    entry: entry.alarmCenter,
    container: '#appContainer',
    activeRule: '/alarmCenter',
    props: {}
  },
  {
    name: 'dataCenter',
    titleName: '数据首页',
    entry: entry.dataCenter,
    container: '#appContainer',
    activeRule: '/dataCenter',
    props: {}
  },
  {
    name: 'report',
    titleName: '报表中心',
    entry: entry.report,
    container: '#appContainer',
    activeRule: '/report',
    props: {}
  },
  {
    name: 'videoConference',
    titleName: '视频会议',
    entry: entry.videoConference,
    container: '#appContainer',
    activeRule: '/videoConference',
    props: {}
  },
  {
    name: 'viidControl',
    titleName: '缉查布控',
    entry: entry.viidControl,
    container: '#appContainer',
    activeRule: '/viidControl',
    props: {}
  },
  {
    name: 'caseCenter',
    titleName: '案件中心',
    entry: entry.caseCenter,
    container: '#appContainer',
    activeRule: '/caseCenter',
    props: {}
  },
  {
    name: 'danger',
    titleName: '险情中心',
    entry: entry.danger,
    container: '#appContainer',
    activeRule: '/danger',
    props: {}
  },
  {
    name: 'gonganInspection',
    titleName: '流媒体',
    entry: entry.gonganInspection,
    container: '#appContainer',
    activeRule: '/gonganInspection',
    props: {}
  },
  {
    name: 'policeOrder',
    titleName: '警单',
    entry: entry.policeOrder,
    container: '#appContainer',
    activeRule: '/policeOrder',
    props: {}
  },
  {
    name: 'spotCheck',
    titleName: '抽查',
    entry: entry.spotCheck,
    container: '#appContainer',
    activeRule: '/spotCheck',
    props: {}
  }
  // {
  //   name: 'userCenter',
  //   titleName: '用户中心',
  //   entry: entry.userCenter,
  //   container: '#appContainer',
  //   activeRule: '/userCenter',
  //   props: {}
  // },
  // {
  //   name: 'preplanCenter',
  //   titleName: '预案中心',
  //   entry: entry.preplanCenter,
  //   container: '#appContainer',
  //   activeRule: '/preplanCenter',
  //   props: {}
  // }
]

// 子应用公共方法
const fns = [
  {
    name: 'login',
    login() {
      window.alert('login')
    }
  }
]
const apps = microApps.map((item) => {
  return {
    ...item,
    props: {
      messages,
      props: item.props,
      routerBase: item.activeRule, // 下发基础路由
      // store: Vue.observable(store),
      activeRule: item.activeRule,
      components: {
        // 公共组件
      },
      httpConfig,
      fns,
      styles: {
        // themeStyle
      }
    }
  }
})

export default apps
