/* 监控数据管理 */
export default {
  data() {
    return {
      multipleChecksDataList: []
    }
  },
  mounted() {
    /* 设备发起直播 */
    this.$store.commit('addUserOperationCallback', {
      id: 'multipleChecks_monitorFn',
      operationName: 'multipleChecks_monitorFn',
      callback: (device) => {
        this.monitorFn(device)
      }
    })
    /* 设备推送正在直播的设备列表 */
    this.$store.commit('addUserOperationCallback', {
      id: 'mulxtipleChecks_dataListFn',
      operationName: 'multipleChecks_dataListFn',
      callback: ({ type, clientIdList }) => {
        this.multipleChecksDataList = clientIdList
        this.$store.commit('multipleChecksDataListFn', clientIdList)
        if (type === 'multipleChecks') {
          /* 需要打开弹窗 */
          if (clientIdList.length) {
            this.$store.commit('callComponent', {
              name: 'multipleChecks',
              data: {
                methodName: 'multipleChecksDataListFn'
              }
            })
          }
        } else {
          const index = this.hideLayerList.findIndex(
            (v) => v.componentsName === 'multipleChecks'
          )
          if (index !== -1) {
            this.hideLayerList.splice(index, 1)
          }
        }
      }
    })
  },
  methods: {
    /* 设备发起直播 */
    monitorFn(device) {
      let json = {}
      if (device.deviceId) {
        const muted = device.muted
        this.$sdk.monitor(
          {
            containerName: `videoBox_${device.deviceId}`,
            muted: typeof muted === 'boolean' ? muted : false,
            // 有通道用通道，没有通道才使用设备id
            clientId: device.channelId || device.deviceId
          },
          (res) => {
            json = {
              callbackType: 'failCallback',
              res: res,
              device: device,
              deviceId: device.deviceId
            }
            this.$store.commit('userOperationCallback', {
              operationName: 'monitor_joinCallback',
              data: json
            })
          },
          (res) => {
            json = {
              callbackType: 'successCallback',
              res: res,
              device: device,
              deviceId: device.deviceId
            }
            this.$store.commit('userOperationCallback', {
              operationName: 'monitor_joinCallback',
              data: json
            })
          }
        )
      }
    }
  }
}
