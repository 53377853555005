import http from '@/http'
import { pxTorem } from '@utils/adaptive'
export default {
  data() {
    return {
      loading: false,
      internalContentType: true,
      searchText: null,
      tableData: [],
      myColumns: [],
      selectedList: [], // 已选中列表
      currentPage: 1, // 当前分页数
      pageSize: 15, // 每页数量
      totalElements: 0, // 数据总条数
      searchPlaceholder: '',
      selectable: true,
      minInputWidth: 220 // 设置最小宽度，防止输入框过于收缩
    }
  },

  props: {
    pagerCount: {
      // 页码按钮的数量，当总页数超过该值时会折叠
      type: Number,
      default: () => {
        return 7
      }
    },
    contentType: {
      // 内容状态 // true表格展示 false自定义展示
      type: Boolean,
      default: () => {
        return true
      }
    },
    /* 加载的时候 */
    loadingEmptyText: {
      type: String,
      default: () => {
        return '正 在 加 载'
      }
    },
    /* 空数据时显示的文本内容 */
    emptyText: {
      type: String,
      default: () => {
        return '暂 无 数 据'
      }
    },
    requestParams: Object, // 请求时的额外参数
    tableColumns: Array, // 表格的列
    controller: String, // 表格的controller名
    fontSize: {
      // 字体大小
      type: Number,
      default: () => {
        return 14
      }
    },
    rowClassName: {
      // 自定义某一行需要展示的颜色
      type: Function,
      default: () => {
        return null
      }
    },
    expandType: {
      // 是否需要展开
      type: Boolean,
      default: () => {
        return false
      }
    },
    defaultGetData: {
      // 是否自动获取表格数据
      type: Boolean,
      default: () => {
        return true
      }
    },
    isJsonData: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    /* 选中的参数 */
    selectParameter: {
      type: String,
      default: () => {
        return ''
      }
    },
    layout: {
      type: String,
      default: () => {
        return 'total, sizes, prev, pager, next, jumper'
      }
    },
    url: {
      // 自定义请求地址
      type: String
    },
    searchArr: {
      // 除了配置了isSearch以外的搜索项
      type: Array,
      default: () => {
        return []
      }
    },

    // 全选禁用
    isShowAllSelect: {
      type: Boolean,
      default: () => false
    }
  },

  computed: {
    matchInputWidth() {
      const placeholderLength = this.searchPlaceholder.length + 5 || 10 // 默认长度为10，避免placeholder为空时输入框收缩过小
      const calculatedWidth = placeholderLength * 13 // 调整因子，可以根据实际情况调整
      return Math.max(calculatedWidth, this.minInputWidth)
    }
  },

  methods: {
    pxTorem,
    parseRem(res) {
      let px = res
      if (px) {
        px = parseInt(px, 10)
        if (isNaN(px)) {
          return ''
        }
        return this.pxTorem(px)
      }
      return ''
    },
    getRowKeys(row) {
      if (this.selectParameter && this.selectParameter.length) {
        return row[`${this.selectParameter}`]
      } else {
        return row.id
      }
    },
    contentTypeFn(type) {
      this.internalContentType = type
    },
    tableRowClassName(obj) {
      const row = obj.row
      let className = ''
      if (this.rowClassName) {
        className = this.rowClassName(obj) || ''
      }
      if (this.selectedList.length) {
        if (this.selectParameter && this.selectParameter.length) {
          const rowId = row[`${this.selectParameter}`]
          if (rowId) {
            return this.selectedList.findIndex(
              (v) => v[`${this.selectParameter}`] === rowId
            ) !== -1
              ? 'selected'
              : className
          }
        } else {
          if (row.id) {
            return this.selectedList.findIndex((v) => v.id === row.id) !== -1
              ? 'selected'
              : className
          } else if (row.deviceUserId) {
            return this.selectedList.findIndex(
              (v) => v.deviceUserId === row.deviceUserId
            ) !== -1
              ? 'selected'
              : className
          } else if (row.orgId) {
            return this.selectedList.findIndex((v) => v.orgId === row.orgId) !==
              -1
              ? 'selected'
              : className
          }
        }
      }
      return className
    },
    /* 获取当前请求参数 */
    getParametersFn() {
      return Object.assign(
        {},
        { isJSON: this.isJsonData },
        this.requestParams,
        {
          size: this.pageSize,
          page: this.currentPage - 1,
          query: this.searchText,
          sort: this.sort,
          canceled: this.canceled,
          handleDate: this.handleDate
        }
      )
    },
    // 删除判断是否需要刷新页面
    deleteStayCurrentPage(id) {
      let key = 'id'
      if (this.selectParameter && this.selectParameter.length) {
        key = this.selectParameter
      }
      const dataList = [...this.tableData]
      const index = dataList.findIndex((v) => v[`${key}`] === id)
      if (index !== -1) {
        this.totalElements -= 1
        this.tableData.splice(index, 1)
      }
      return dataList.length
    },
    exportTableDataFn() {
      return this.tableData
    },
    loadingFn(loading) {
      this.loading = loading
    },
    customDataSuccessFn(customData) {
      this.loading = false
      this.tableData = customData
    },
    totalElementsFn() {
      return this.totalElements
    },
    getData(searchText, page) {
      this.currentPage = page || 1
      this.loading = true
      const successFunc = (res) => {
        this.loading = false
        this.tableData = res.data.content ? res.data.content : res.data
        this.totalElements = res.data.totalElements
        this.$emit('reqSuccess')
      }
      const params = Object.assign(
        {
          isJSON: this.isJsonData,
          query: this.searchText
        },
        this.requestParams,
        {
          size: this.pageSize,
          page: this.currentPage - 1,
          sort: this.sort,
          canceled: this.canceled,
          handleDate: this.handleDate
        }
      )
      if (this.controller) {
        this.$api.page(this.controller, params).then(successFunc)
      } else if (this.url) {
        http.post(this.url, params).then(successFunc)
      } else {
        this.loading = false
      }
    },
    /**
     * 处理列的展示
     */
    handlerColumns() {
      const searchColumns = []
      const columnsArr = []
      // 判断是否有多选框
      if (this.selection) {
        columnsArr.push({
          width: 50,
          type: 'selection'
        })
      }
      // 判断是否显示索引值
      if (this.showIndex) {
        columnsArr.push({
          label: this.$t('operation.No'),
          width: 50,
          type: 'index'
        })
      }
      this.tableColumns.forEach((val) => {
        if (!val.type) {
          val.type = 'default'
        }
        columnsArr.push(val)
        // 处理搜索的placeholder
        if (val.isSearch) {
          searchColumns.push(val.label)
        }
      })
      if (this.searchArr.length > 0) {
        this.searchArr.forEach((item) => {
          searchColumns.push(item.label)
        })
      }
      this.searchPlaceholder = searchColumns.join('、')
      this.myColumns = columnsArr
    },
    getSelectedList() {
      return this.selectedList
    },
    handleRowClick(row) {
      if (!this.selectable) return

      this.$emit('on-handleRowClick', row)
      if (this.expandType) {
        this.$refs.elTable.toggleRowExpansion(row)
      }
      if (this.tableColumns.find((v) => v.type === 'selection')) {
        this.$refs.elTable.toggleRowSelection(row)
        let index = null
        if (this.selectParameter && this.selectParameter.length) {
          const rowId = row[`${this.selectParameter}`]
          if (rowId) {
            index = this.selectedList.findIndex(
              (v) => v[`${this.selectParameter}`] === rowId
            )
          }
        } else {
          if (row.id) {
            index = this.selectedList.findIndex((v) => v.id === row.id)
          } else if (row.deviceUserId) {
            index = this.selectedList.findIndex(
              (v) => v.deviceUserId === row.deviceUserId
            )
          } else if (row.orgId) {
            index = this.selectedList.findIndex((v) => v.orgId === row.orgId)
          }
        }
        this.$nextTick(() => {
          if (index === -1) {
            this.selectedList.push(row)
          } else {
            this.selectedList.splice(index, 1)
          }
          this.$emit('on-select-checkbox', this.selectedList, row)
        })
      }
    },
    /**
     * 选择时触发的事件
     * @param selectList
     */
    selectEvent(selectList, row) {
      this.selectedList = [...selectList]
      this.$emit('on-select-checkbox', [...selectList], row)
    },
    clearSelectedList(list = []) {
      this.selectedList = list || []
      if (this.$refs.elTable) {
        this.$refs.elTable.clearSelection()
      }
      list.forEach((row) => {
        let item = null
        if (this.selectParameter && this.selectParameter.length) {
          const rowId = row[`${this.selectParameter}`]
          if (rowId) {
            item = this.tableData.find(
              (v) => v[`${this.selectParameter}`] === rowId
            )
          }
        } else {
          item = this.tableData.find((v) => v.id === row.id)
        }
        if (item) {
          this.$refs.elTable.toggleRowSelection(item, true)
        } else {
          this.$refs.elTable.toggleRowSelection(row, true)
        }
      })
    },
    /**
     * 更改表格每页展示数目
     * @param val 每页条数
     */
    handleSizeChange(val) {
      this.currentPage = 1
      this.pageSize = val
      this.getData()
    },
    resetFn() {
      this.searchText = null
      this.$nextTick(() => {
        this.handleCurrentChange(1)
      })
    },
    /**
     * 更改当前页码
     * @param val 页码
     */
    handleCurrentChange(val) {
      this.currentPage = val
      this.getData(this.searchText, val)
    },
    /**
     * 清空列表数据
     * 不重新获取
     * */
    initData() {
      this.currentPage = 1
      this.tableData = []
      this.totalElements = 0
    }
  }
}
