<!-- 点名抽查 -->
<template>
  <div
    class="el-dialog sideFootBox"
    style="width: 830px"
    v-el-drag-dialog
    :style="{ 'z-index': dragLevel }"
    @click="setDragLevel"
    v-if="dialogVisible"
  >
    <div class="el-dialog__header !p-0">
      <!--<div @click="show(91)">show(91)</div>-->
      <div
        class="el-dialog__body !p-[8px_0px_8px] sideFoot_content h-[700px] flex flex-col text-main text-xs"
      >
        <!-- 标题 -->
        <div class="h-[40px] flex items-center px-4 mb-1">
          <div
            class="font-bold bg-theme02 border border-theme05 rounded px-2 flex items-center justify-center h-[24px] flex-shrink-0"
          >
            发起{{ form.checkStyle === 1 ? '消息' : '呼叫' }}抽查
          </div>
          <div v-if="form.checkStyle === 1" class="ml-3">
            <span class="text-auxiliary">应答方式：</span>
            <span>{{ form.actionType === 1 ? '打卡' : '答复' }}</span>
          </div>
          <div v-if="form.checkStyle === 1" class="ml-3">
            <span class="text-auxiliary">应答时间：</span>
            <span
              ><span class="font-bold">{{ form.actionTime }}</span> 秒</span
            >
          </div>
        </div>
        <!-- 消息内容 -->
        <div
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          class="flex-grow h-0"
        >
          <div
            v-if="form.checkStyle === 1"
            class="w-full h-full flex flex-col box-border"
          >
            <!-- 内容统计 -->
            <div class="flex-shrink-0 mb-3 px-4">
              <el-row :gutter="8">
                <el-col :span="8">
                  <el-popover
                    :open-delay="500"
                    placement="top"
                    popper-class="popover_echarts w-[130px]"
                    trigger="hover"
                  >
                    <div>
                      <ul
                        class="text-xs p-2 leading-[22px] border-b border-border"
                      >
                        <li class="flex w-full">
                          <span class="flex-shrink-0">抽查人数：</span>
                          <div class="text-right font-bold flex-grow w-0">
                            {{ form.checkNum || '- -' }}
                          </div>
                        </li>
                        <li class="flex w-full text-auxiliary">
                          <span class="flex-shrink-0">离线状态：</span>
                          <div class="text-right flex-grow w-0">
                            {{ form.offLineNum || '- -' }}
                          </div>
                        </li>
                        <li class="flex w-full text-auxiliary">
                          <span class="flex-shrink-0">临时群组：</span>
                          <div class="text-right flex-grow w-0">
                            {{ form.intercomGroupTemNum || '- -' }}
                          </div>
                        </li>
                      </ul>
                      <ul class="text-xs p-2 leading-[22px]">
                        <li class="flex w-full">
                          <span class="flex-shrink-0 tracking-[4px]"
                            >待接收：</span
                          >
                          <div class="text-right font-bold flex-grow w-0">
                            {{ form.beReceiveNum || '- -' }}
                          </div>
                        </li>
                        <li class="flex w-full">
                          <span class="flex-shrink-0 tracking-[4px]"
                            >接收中：</span
                          >
                          <div class="text-right font-bold flex-grow w-0">
                            {{ form.receivingNum || '- -' }}
                          </div>
                        </li>
                        <li class="flex w-full">
                          <span class="flex-shrink-0 tracking-[4px]"
                            >已完成：</span
                          >
                          <div
                            class="text-right font-bold flex-grow w-0 text-success"
                          >
                            {{ form.finishNum || '- -' }}
                          </div>
                        </li>
                        <li class="flex w-full">
                          <span class="flex-shrink-0 tracking-[4px]"
                            >未完成：</span
                          >
                          <div
                            class="text-right font-bold flex-grow w-0 text-dangerv2"
                          >
                            {{ form.notFinishNum || '- -' }}
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div
                      slot="reference"
                      class="h-[28px] box-border leading-[28px] w-full bg-backgroundv2 border border-border rounded flex font-bold"
                    >
                      <div class="flex-1 flex">
                        <div
                          class="bg-backgroundv3 h-full w-[32px] flex items-center justify-center flex-shrink-0"
                        >
                          <svg-icon
                            svg-name="everyDay_manyPeople"
                            className="svgBox"
                          />
                        </div>
                        <div class="flex-grow w-0 text-center over_one_line">
                          {{ form.checkNum || '- -' }}
                        </div>
                      </div>
                      <div class="flex-1 flex">
                        <div
                          class="bg-backgroundv3 h-full w-[32px] flex items-center justify-center flex-shrink-0"
                        >
                          <svg-icon
                            svg-name="successState"
                            className="svgBox"
                          />
                        </div>
                        <div
                          class="flex-grow w-0 text-center text-success over_one_line"
                        >
                          {{ form.finishNum || '- -' }}
                        </div>
                      </div>
                      <div class="flex-1 flex">
                        <div
                          class="bg-backgroundv3 h-full w-[32px] flex items-center justify-center flex-shrink-0"
                        >
                          <svg-icon svg-name="dangerState" className="svgBox" />
                        </div>
                        <div
                          class="flex-grow w-0 text-center text-dangerv2 over_one_line"
                        >
                          {{ form.notFinishNum || '- -' }}
                        </div>
                      </div>
                    </div>
                  </el-popover>
                </el-col>
                <el-col :span="16">
                  <div
                    class="w-full px-2 leading-[22px] box-border rounded bg-backgroundv3 h-[28px] flex items-center justify-between"
                  >
                    <div class="flex">
                      消息内容
                      <div
                        v-if="form.playMsg === 1"
                        class="ml-2 bg-background h-[22px] px-2 rounded"
                      >
                        TTS文字转语音
                      </div>
                    </div>
                    <div
                      class="css-text-no-select clickBox"
                      @click="setMsgContentType"
                    >
                      <el-tooltip
                        class="item"
                        placement="bottom-end"
                        v-model="msgContentType"
                        trigger="manual"
                      >
                        <div slot="content" class="w-[400px]">
                          {{ form.msgContent }}
                        </div>
                        <span class="">
                          查看内容
                          <i
                            :class="{ '-rotate-90': msgContentType }"
                            class="el-icon-caret-left text-sm duration-500"
                          ></i>
                        </span>
                      </el-tooltip>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
            <!-- 设备展示 -->
            <div
              v-if="form.checkNum !== 0"
              class="box-border flex-grow h-0 flex px-2"
            >
              <div
                class="flex-grow w-0 px-2 overflow-x-hidden overflow-y-auto h-full"
              >
                <spotCheckTaskDevice
                  v-if="form.id"
                  :paramsSpan="form.actionType === 1 ? 8 : 24"
                  :form="form"
                  @change="spotCheckTaskDeviceChange"
                  @on-countStatus="spotCheckTaskDeviceCountStatus"
                  ref="spotCheckTaskDevice"
                ></spotCheckTaskDevice>
              </div>
              <!-- 答复 -->
              <div v-if="form.actionType === 2" class="w-[66%] box-border pr-2">
                <div
                  class="w-full h-full rounded border border-border bg-backgroundv2 overflow-hidden"
                >
                  <talkbackReply
                    v-if="form.id"
                    :taskId="form.id"
                    ref="talkbackReply"
                  ></talkbackReply>
                </div>
              </div>
            </div>
            <!-- 无设备内容 -->
            <div v-else class="flex-grow h-0 px-4">
              <div
                class="w-full h-full flex items-center justify-center text-auxiliary bg-backgroundv2 box-border border border-border rounded"
              >
                <div class="r-f tac block">
                  <svg-icon
                    svg-name="noData"
                    className="svgBox !w-[46px] !h-[46px]"
                  />

                  <div class="mt-3">您所抽查的设备处于离线状态</div>
                  <div class="mt-1">无法支持本次抽查</div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="w-full h-full">
            <div
              v-if="form.checkNum !== 0"
              class="w-full h-full flex px-4 box-border"
            >
              <div class="flex flex-col w-[33%]">
                <div class="pr-2">
                  <el-popover
                    :open-delay="500"
                    placement="top"
                    popper-class="popover_echarts w-[130px]"
                    trigger="hover"
                  >
                    <ul
                      class="text-xs p-2 leading-[22px] border-b border-border"
                    >
                      <li class="flex w-full">
                        <span class="flex-shrink-0">抽查人数：</span>
                        <div class="text-right font-bold flex-grow w-0">
                          {{ form.checkNum || '- -' }}
                        </div>
                      </li>
                      <li class="flex w-full text-auxiliary">
                        <span class="flex-shrink-0">离线状态：</span>
                        <div class="text-right flex-grow w-0">
                          {{ form.offLineNum || '- -' }}
                        </div>
                      </li>
                      <li class="flex w-full text-auxiliary">
                        <span class="flex-shrink-0">临时群组：</span>
                        <div class="text-right flex-grow w-0">
                          {{ form.intercomGroupTemNum || '- -' }}
                        </div>
                      </li>
                    </ul>
                    <ul class="text-xs p-2 leading-[22px]">
                      <li v-if="form.checkStyle === 1" class="flex w-full">
                        <span class="flex-shrink-0 tracking-[4px]"
                          >待接收：</span
                        >
                        <div class="text-right font-bold flex-grow w-0">
                          {{ form.beReceiveNum || '- -' }}
                        </div>
                      </li>
                      <li v-if="form.checkStyle === 1" class="flex w-full">
                        <span class="flex-shrink-0 tracking-[4px]"
                          >接收中：</span
                        >
                        <div class="text-right font-bold flex-grow w-0">
                          {{ form.receivingNum || '- -' }}
                        </div>
                      </li>
                      <li class="flex w-full">
                        <span class="flex-shrink-0 tracking-[4px]"
                          >已完成：</span
                        >
                        <div
                          class="text-right font-bold flex-grow w-0 text-success"
                        >
                          {{ form.finishNum || '- -' }}
                        </div>
                      </li>
                      <li class="flex w-full">
                        <span class="flex-shrink-0 tracking-[4px]"
                          >未完成：</span
                        >
                        <div
                          class="text-right font-bold flex-grow w-0 text-dangerv2"
                        >
                          {{ form.notFinishNum || '- -' }}
                        </div>
                      </li>
                    </ul>
                    <div
                      slot="reference"
                      class="h-[28px] box-border leading-[28px] w-full bg-backgroundv2 border border-border rounded flex font-bold"
                    >
                      <div class="flex-1 flex">
                        <div
                          class="bg-backgroundv3 h-full w-[32px] flex items-center justify-center flex-shrink-0"
                        >
                          <svg-icon
                            svg-name="everyDay_manyPeople"
                            className="svgBox"
                          />
                        </div>
                        <div class="flex-grow w-0 text-center over_one_line">
                          {{ form.checkNum || '- -' }}
                        </div>
                      </div>
                      <div class="flex-1 flex">
                        <div
                          class="bg-backgroundv3 h-full w-[32px] flex items-center justify-center flex-shrink-0"
                        >
                          <svg-icon
                            svg-name="successState"
                            className="svgBox"
                          />
                        </div>
                        <div
                          class="flex-grow w-0 text-center text-success over_one_line"
                        >
                          {{ form.finishNum || '- -' }}
                        </div>
                      </div>
                      <div class="flex-1 flex">
                        <div
                          class="bg-backgroundv3 h-full w-[32px] flex items-center justify-center flex-shrink-0"
                        >
                          <svg-icon svg-name="dangerState" className="svgBox" />
                        </div>
                        <div
                          class="flex-grow w-0 text-center text-dangerv2 over_one_line"
                        >
                          {{ form.notFinishNum || '- -' }}
                        </div>
                      </div>
                    </div>
                  </el-popover>
                </div>
                <div
                  class="flex-grow h-0 mt-2 overflow-x-hidden overflow-y-auto pr-2 box-border"
                >
                  <spotCheckTaskDevice
                    v-if="form.id"
                    :paramsSpan="24"
                    :form="form"
                    @change="spotCheckTaskDeviceChange"
                    @on-countStatus="spotCheckTaskDeviceCountStatus"
                    ref="spotCheckTaskDevice"
                  ></spotCheckTaskDevice>
                </div>
              </div>
              <div class="flex-grow w-0 flex flex-col">
                <!-- -->
                <div class="flex-grow h-0 overflow-hidden">
                  <multiPersonIntercomSpotCheck
                    ref="multiPersonIntercomSpotCheck"
                  ></multiPersonIntercomSpotCheck>
                </div>
                <div
                  v-if="groupType"
                  class="w-full h-full flex items-center justify-center text-auxiliary bg-backgroundv2 box-border border border-border rounded"
                >
                  <div class="r-f tac block">
                    <svg-icon
                      svg-name="no_intercom_record"
                      className="svgBox !w-[160px] !h-[160px]"
                    />
                    <div class="mt-5">组内成员状态异常，无法进行抽查</div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 无设备内容 -->
            <div v-else class="flex-grow h-full px-4">
              <div
                class="w-full h-full flex items-center justify-center text-auxiliary bg-backgroundv2 box-border border border-border rounded"
              >
                <div class="r-f tac block">
                  <svg-icon
                    svg-name="noData"
                    className="svgBox !w-[46px] !h-[46px]"
                  />

                  <div class="mt-3">您所抽查的设备处于离线状态</div>
                  <div class="mt-1">无法支持本次抽查</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 操作区 -->
        <div
          class="h-[50px] flex items-center justify-between flex-shrink-0 px-4 mt-1"
        >
          <div class="text-auxiliary text-xs">
            <div>
              <i class="el-icon-warning-outline mr-1 text-sm"></i>
              <span v-if="form.checkType === 2">随机抽查</span>
              <span v-else>
                <span v-if="form.checkScope === 1">区域抽查</span>
                <span v-else-if="form.checkScope === 2">部门抽查</span>
                <span v-else-if="form.checkScope === 3">设备抽查</span>
              </span>
            </div>
          </div>
          <div>
            <el-button
              size="mini"
              class="button_default buttonBox"
              @click="onClose"
              >取 消</el-button
            >

            <el-button
              size="mini"
              class="button_function buttonBox"
              @click="onSubmit"
              >完 成</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import elDragDialog from '@components/el-drag-dialog'
import talkbackReply from '@components/spotCheck/viewSpotCheck/talkbackReply'
import spotCheckTaskDevice from '@components/spotCheck/viewSpotCheck/spotCheckTaskDevice'
import multiPersonIntercomSpotCheck from '@components/device/multiPersonIntercom/multiPersonIntercomSpotCheck'
export default {
  data() {
    return {
      form: {},
      groupType: false,
      groupId: null,
      dragLevel: 0,
      loading: false,
      taskDeviceList: [],
      msgContentType: false,
      dialogVisible: false
    }
  },
  directives: { elDragDialog },
  components: {
    talkbackReply,
    spotCheckTaskDevice,
    multiPersonIntercomSpotCheck
  },
  mounted() {
    // this.show(37)
  },
  methods: {
    // 抽查任务绑定群组id
    async taskGroupBind() {
      // const { code, data = {} } =
      await this.$api.spotCheck.taskGroupBind({
        groupId: this.groupId,
        taskId: this.form.id
      })
    },
    // 发起临时群组
    orgGroupInsertTemp() {
      if (this.taskDeviceList.length === 0) return
      this.$refs.multiPersonIntercomSpotCheck
        ?.orgGroupInsertTempFn([...this.taskDeviceList], { openRecording: 1 })
        .then((groupId) => {
          console.warn('001')
          this.groupId = groupId
          this.$nextTick(() => {
            this.taskGroupBind()
          })
          this.setLoading(false)
        })
        .catch((error) => {
          console.warn('002')
          this.groupId = null
          this.groupType = true
          this.setLoading(false)
        })
    },
    // 抽查设备获取设备
    spotCheckTaskDeviceChange(taskDeviceList = []) {
      this.taskDeviceList = taskDeviceList
      this.setLoading(false)
      if (this.form.checkStyle === 2) {
        this.orgGroupInsertTemp()
      }
    },
    // 抽查设备 返回状态数量统计
    spotCheckTaskDeviceCountStatus(params = {}) {
      const {
        beReceiveNum = 0,
        receivingNum = 0,
        finishNum = 0,
        notFinishNum = 0,
        offLineNum = 0,
        intercomGroupTemNum = 0
      } = params
      this.$set(this.form, 'beReceiveNum', beReceiveNum)
      this.$set(this.form, 'receivingNum', receivingNum)
      this.$set(this.form, 'finishNum', finishNum)
      this.$set(this.form, 'notFinishNum', notFinishNum)
      this.$set(this.form, 'offLineNum', offLineNum)
      this.$set(this.form, 'intercomGroupTemNum', intercomGroupTemNum)
    },
    // 设置加载状态
    setLoading(loading) {
      this.loading = loading
    },
    // 设置消息内容展示状态
    setMsgContentType() {
      this.msgContentType = !this.msgContentType
    },
    async detail(id) {
      const { code, data = {} } = await this.$api.spotCheck.taskDetail({ id })
      if (code === 200) {
        this.form = { ...data }
        console.log('this.form.checkNum', this.form.checkNum)
        if (this.form.checkNum === 0) {
          this.spotCheckTaskDeviceChange([])
        }
        if (this.form.checkStyle === 2) {
          this.$set(this.form, 'actionType', null)
        }
        this.setDialogVisible(true)
      }
    },
    // 设置弹窗展示
    setDialogVisible(type) {
      //  if (this.dialogVisible === type) return
      if (type) {
        this.setDragLevel()
      }
      this.dialogVisible = type
    },
    // 设置弹窗层级
    setDragLevel() {
      if (this.mainStore) {
        this.dragLevel = this.mainStore.getters.dragLevel
        this.mainStore.commit('setDragLevel')
      } else {
        this.dragLevel = this.$store.getters.dragLevel
        this.$store.commit('setDragLevel')
      }
    },
    show(id) {
      if (this.dialogVisible) {
        this.$message.warning('当前正在抽查，请稍后处理')
        return
      }
      this.groupId = null
      this.groupType = false
      this.form = {}
      this.setLoading(true)
      this.detail(id)
    },
    close() {
      this.dialogVisible = false
    },
    onClose() {
      this.$deleteConfirmationBox(
        {
          deleteTitleImg: 'tips',
          deleteTitle: '取消抽查',
          deleteContent: `
              <div><p>确认取消本次点名抽查？</p><p>中途取消将无法检索或查看本次抽查的任何信息</p></div>
            `,
          closeText: '返回抽查',
          submitClass: 'button_delete',
          submitText: '取消抽查'
        },
        () => {
          this.taskEnd(3)
        }
      )
    },
    onSubmit() {
      let title = '结束后可在抽查模块中查看本次抽查信息。'
      if (this.form.beReceiveNum !== 0 || this.form.receivingNum !== 0) {
        // 部分设备存在 接收中/待接收(未被拉入抽查群组) 的状态
        title = '还有设备处于接收中，系统将标记这些设备为未完成状态'
      }
      this.$deleteConfirmationBox(
        {
          deleteTitleImg: 'tips',
          deleteTitle: '结束抽查',
          deleteContent: `
              <div><p>确认结束本次点名抽查？</p><p>${title}</p></div>
            `,
          closeText: '返回抽查',
          submitClass: 'button_function',
          submitText: '结束抽查'
        },
        () => {
          this.taskEnd(2)
        }
      )
    },
    // 抽查任务（结束or取消）
    async taskEnd(checkStatus) {
      // checkStatus 抽查状态（2已结束、3已取消）
      const { code } = await this.$api.spotCheck.taskEnd({
        taskId: this.form.id,
        checkStatus
      })
      if (code === 200) {
        if (checkStatus === 2) {
          this.$message.success(
            '点名抽查已完成，可在抽查模块中查看详细信息记录。'
          )
          // 通知指挥页面，今日抽查获取新数据
          this.$store.commit('userOperationCallback', {
            operationName: 'spotCheck_spotCheckToday',
            data: {}
          })
          // 通知抽查页面获取新数据
          this.$store.commit('userOperationCallback', {
            operationName: 'spotCheck_rollCallCheck',
            data: {}
          })
          // 关闭临时群组
          if (this.form.checkStyle === 2 && this.groupId) {
            this.$refs.multiPersonIntercomSpotCheck?.onClose()
          }
        }
        this.close()
      }
    }
  }
}
</script>
<style scoped lang="less" rel="stylesheet/less">
.el-dialog {
  position: fixed;
  top: 8%;
  left: 2%;
}
</style>
