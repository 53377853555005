export default {
  general: {
    accountNumber: '账号',
    noData: '暂无数据',
    pleaseEnter: '请输入',
    pleaseChoose: '请选择',
    createTime: '创建时间',
    noBinding: '暂不绑定',
    bind: '绑定',
    change: '修改',
    phone: '手机号',
    code: '验证码',
    screenshot: '截图',
    currentPosition: '当前位置',
    PositionOfThatMoment: '当时位置',
    construction: '结构',
    uploadSuccessful: '上传成功',
    selectAll: '全选',
    systemParameter: '系统参数',
    updateRecord: '更新记录',
    updateInfo: '更新说明',
    allVersion: '全部版本',
    online: '在线设备',
    offline: '离线设备',
    longTermOffline: '长期离线',
    cameraStatus: '设备状态',
    Entrytime: '进入时间',
    Entryposition: '进入位置',
    HistoryTerminal: '历史终端',
    deviceCode: '设备激活二维码',
    assignedBatch: '批量分配',
    cancelAssignedBatch: '批量取消分配',
    complete: '完成',
    notCompleted: '未完成',
    times: '次',
    event: '事件',
    clear: '清空',
    detail: '详情',
    edit: '编辑',
    delete: '删除',
    add: '新增',
    save: '保存',
    unSave: '不保存',
    close: '关闭',
    back: '返回',
    send: '发送',
    submit: '确定',
    cancel: '取消',
    view: '查看',
    list: '列表',
    type: '类型',
    name: '名称',
    time: '时间',
    status: '状态',
    startDtm: '开始时间',
    endDtm: '结束时间',
    selectTimeRange: '选择时间范围',
    to: '至',
    startDate: '开始日期',
    endDate: '结束日期',
    deviceID: '设备ID',
    department: '所属部门',
    operation: '操作区',
    OperationContent: '操作',
    position: '位置',
    search: '搜索',
    device: '设备',
    export: '导出',
    download: '下载',
    allot: '分配',
    allotBatch: '批量分配',
    play: '播放',
    pause: '暂停',
    choose: '选择',
    chooseUser: '选择用户',
    chooseDevice: '选择设备',
    deviceUser: '设备使用者',
    operator: '操作人',
    startPosition: '开始位置',
    faultCause: '故障原因',
    msgContent: '消息内容',
    violateType: '违反类型',
    imagePreview: '图片预览',
    videoPreview: '视频预览',
    video: '视频',
    audio: '音频',
    image: '图片',
    document: '文档',
    startUsing: '启用',
    stopUsing: '禁用',
    normal: '正常',
    deleteSuccess: '删除成功',
    updatePassword: '修改密码',
    startPrint: '开始绘制',
    clearCurrent: '清空当前围栏',
    all: '所有',
    normally: '常规',
    SOSAlarm: 'SOS报警',
    accountConfirm: '长度在6-20位，由字母、数字组成',
    passwordConfirm: '长度在8-16位，由大写字母、小写字母、数字、特殊符号组成',
    oldPassword: '旧密码',
    newPassword: '新密码',
    pleaseEnterOldPassword: '请输入旧密码',
    none: '无'
  },
  appTable: {
    selectTheScreeningDate: '请选择筛选日期',
    custom: '自定义',
    today: '今天',
    yesterday: '昨天',
    recentTwoDays: '最近两天',
    recentSevenDays: '最近七天',
    recentOneMonth: '最近一个月',
    recentThreeMonths: '最近三个月',
    searchBy: '搜索'
  },
  common: {
    confirm: '确定',
    cancel: '取消',
    home: '首页',
    pleaseChooseMissionType: '请选择任务类型',
    pleaseChooseMissionStatus: '请选择任务状态',
    pleaseEnterMissionDesc: '请输入任务说明',
    pleaseChooseMissionArea: '请选择任务范围',
    pleaseChooseMissionOperation: '请选择任务操作',
    pleaseChooseDepartment: '请选择部门',
    everyDay: '每日',
    Monday: '星期一',
    Tuesday: '星期二',
    Wednesday: '星期三',
    Thursday: '星期四',
    Friday: '星期五',
    Saturday: '星期六',
    Sunday: '星期日',
    appointedDay: '指定日期',
    missionPhoto: '拍摄',
    missionVideo: '录像',
    viewMissionPhoto: '查看任务照片',
    viewMissionVideo: '查看任务录像',
    allocated: '已分配',
    undistributed: '未分配',
    allotInfomation: '分配信息',
    ResetOptions: '重置选项'
  },
  login: {
    login: '登 录',
    logout: '退出登录',
    theManagementSystemOfTheLawEnforcementRecorder: '可视化指挥调度平台',
    clientLogin: '用户端登录',
    pleaseInputUserName: '请输入用户名称',
    pleaseInputPassword: '请输入密码',
    pleaseInputVerificationCode: '请输入验证码',
    PlatformLogin: '平台端登录',
    pleaseEnterTheMobileNumberOfTheAdministrator: '请输入管理员手机号',
    mobileNumberOfAdministrator: '管理员手机号',
    mobilePhoneVerificationCode: '手机验证码',
    getVerificationCode: '获取验证码',
    getTheVerificationCodeAgain: '重新获取验证码',
    mobileDynamicLogin: '手机动态登录',
    accountPasswordLogin: '账号密码登录',
    pleaseInputTheCorrectMobileNumber: '请输入正确手机号码',
    pleaseEnterTheMobilePhoneVerificationCode: '请输入手机验证码'
  },
  header: {
    index: '首页',
    command: '指挥调度',
    contact: '集群对讲',
    mission: '任务中心',
    storage: '回放中心',
    report: '数据中心',
    operation: '运营中心',
    setting: '设置中心'
  },
  device: {
    refreshDeviceList: '点击刷新列表',
    department: '部门',
    group: '群组',
    groupName: '群组名称',
    attention: '关注',
    filterDevice: '可输入设备号、部门名进行筛选',
    filterDeviceV2: '可输入部门名进行筛选',
    history: '历史事件',
    hardwareVersion: '固件版本',
    productFactor: '产品厂家',
    OptionalInformation: '选填信息',
    accredit: '授权',
    ActivateDevice: '激活设备',
    ClickQrCodeToPreview: '点击二维码可放大显示',
    SaveQrCodeLocally: '保存本地'
  },
  index: {
    sos: 'sos紧情',
    noHandlerRule: '未处理',
    equipmentOnlineCondition: '设备上线情况',
    equipmentOnlineTrendChart: '设备上线趋势图',
    storeTheWarningTrendChart: '存储预警趋势图',
    storageUsageReport: '存储情况',
    platformData: '平台数据',
    recentOperation: '最近操作',
    systemMsg: '系统消息',
    SOSEmergency: 'SOS紧情',
    breakRule: '违反规则',
    FaultLog: '故障日志',
    mediaFile: '媒体文件',
    totalStoragePlace: '总存储空间',
    usedStoragePlace: '已用空间',
    freeStoragePlace: '剩余空间'
  },
  footer: {
    message: '这是公共的底部组件'
  },
  monitorIndex: {
    findTheCurrentTerminal: '找当前的终端',
    findTheTerminalOfHistory: '找历史的终端',
    displayDevice: '显示设备',
    showAllDevices: '显示所有设备',
    showOnlineDevices: '显示在线设备',
    showSelectedDevices: '显示选中设备',
    currentlySelectedDevice: '当前选中设备',
    state: '状态',
    close: '关闭',
    organization: '组织',
    time: '时间',
    position: '位置',
    attention: '关注',
    unfollow: '取消关注',
    viewRemoteVideo: '远程视频',
    monitoringRemoteDevice: '监听设备',
    informTakePhoto: '通知拍照',
    informTakeVideo: '通知录像',
    devicePlayback: '设备回放',
    imageTextSMS: '图文短信',
    sendFile: '下发文件',
    viewDeviceNativeMedia: '本机媒体',
    terminalRules: '终端规则',
    mapTools: '地图工具',
    measuringDistance: '测量距离',
    electronicFence: '电子围栏',
    rulesSetting: '规则设置',
    dropDownBoxToFind: '拉框查找',
    currentAccount: '当前账号',
    inputKeyWordToSearchPressEnterForSearch:
      '输入关键词进行搜索,按回车键进行搜索。'
  },
  imageTextSMS: {
    fillInTheMessage: '填写短信',
    messageContent: '短信内容',
    pleaseFillInTheTextMessageLessThan240WordsAndNoSpecialCharacters:
      '请填写文字信息,不超过240字且不能包括特殊字符',
    UploadPicturesUpTo9Pictures: '上传图片(最多九张)',
    SaveAsInformationTemplate: '是否保存为固定图文信息',
    informationTemplate: '固定模板',
    messageRecord: '短信记录',
    receivingTerminal: '接收终端',
    sendTime: '发送时间',
    sender: '发送人',
    content: '内容',
    images: '图片',
    text: '文字',
    addAsTemplate: '添加固定图文'
  },
  sendFile: {
    sendFile: '下发文件',
    fileRecord: '文件记录',
    fileName: '文件名称',
    fileType: '文件类型',
    chooseFile: '选择文件',
    fileSendTime: '文件下发时间',
    pleaseInsertFileName: '请填写文件名称'
  },
  rules: {
    addCheckInSettings: '添加签到设置',
    viewCheckInSettings: '查看签到设置',
    creator: '创建人',
    rule: '规则',
    deviceRule: '终端规则',
    allotRule: '分配规则',
    ruleName: '规则名称',
    cancelAllot: '取消分配',
    forbidInto: '禁入',
    forbidOut: '禁出',
    timmingShoot: '定时拍摄',
    timmingVideo: '定时录像',
    timmingPassBack: '定时回传',
    signIn: '签到',
    sedentariness: '久坐',
    addRule: '新增规则',
    attendanceTime: '考勤时间',
    areaName: '区域名称',
    addMap: '添加地图',
    timeRange: '时间范围',
    videoOrPhoto: '拍照或录像',
    basicInfo: '基本信息',
    videoAndPhoto: '拍照和录像',
    Email: '邮件',
    EmailAddress: '邮箱',
    photo: '拍照',
    video: '录像',
    timeRange1: '时间范围1',
    timeRange2: '时间范围2',
    captureMode: '抓拍模式',
    captureInterval: '抓拍间隔(秒)',
    standingTime: '停留时间(分钟)',
    signInSetting: '签到设置',
    SitToLongWarning: '久坐报警',
    pleaseSeletePhotoOrVideo: '请选择拍照或录像',
    deallocate: '解除分配',
    distribution: '分配情况',
    CycleCaptured: '循环抓拍',
    IndependentCapture: '独立抓拍',
    SingInQuery: '签到查询',
    SedentaryAlarmRecord: '久坐报警记录',
    AlarmRecord: '报警记录',
    AlarmTime: '报警时间',
    StayTime: '停留时间'
  },
  mission: {
    missionManagement: '任务管理',
    missionRecord: '任务记录',
    missionName: '任务名称',
    missionType: '任务类型',
    missionTime: '任务时间',
    missionTimeQuantum: '任务时间段',
    missionStatus: '任务状态',
    missionExplain: '任务说明',
    missionStatusExecute: '执行状态说明',
    executeDevice: '执行设备',
    executeStatus: '执行状态',
    missionOperation: '任务操作',
    missionDevice: '任务设备',
    missionRange: '任务范围',
    addMission: '添加任务',
    taskDetails: '任务详情',
    editMission: '编辑任务'
  },
  callIndex: {
    groupManagement: '群组管理',
    talkBackRecord: '对讲记录',
    stopBroadcast: '停止广播',
    sendBroadcast: '发起广播',
    stopTalkBack: '结束对讲',
    sendTalkBack: '发起对讲',
    hangUp: '挂断',
    speaking: '正在说话',
    talkBacking: '正在对讲',
    ByListening: '发起监听',
    viewRemoteVideo: '视频直播',
    monitoRemoteDevice: '监听设备',
    noticeVideo: '通知录像',
    noticeToTakePhotos: '通知拍照'
  },
  playBack: {
    preview: '预览',
    folder: '文件夹',
    automaticallyUpgradeToTheLatestVersion: '自动升级为最新版本',
    ascendingOrder: '升序',
    descendingOrder: '降序',
    videoDuration: '视频时长',
    callThePolice: '报警',
    executionRules: '执行规则',
    return: '回传',
    userActiveUpload: '用户主动上传',
    expirationTime: '过期时间',
    remarkInformation: '备注信息',
    pleaseEnterComments: '请输入备注信息',
    trackPlayBack: '轨迹回放',
    videoPlayBack: '录像回放',
    audioPlayBack: '音频回放',
    photoPlayBack: '照片查找',
    searchByPerson: '一人一档',
    trackPoint: '轨迹点',
    recordTime: '记录时间',
    position: '位置',
    GPSPoint: 'GPS坐标点',
    checkInMap: '在地图中查看',
    screenShot: '截屏',
    mute: '静音',
    cancelMute: '取消静音',
    fullScreen: '全屏',
    cancelFullScreen: '取消全屏',
    remark: '备注',
    videoList: '视频列表',
    videoPlay: '视频播放',
    fileInfo: '文件信息',
    videoTime: '视频时间',
    deviceId: '终端编码',
    user: '使用者',
    videoType: '视频类型',
    shootPosition: '拍摄位置',
    searchPhoto: '照片查找',
    allPhotos: '整页相册',
    cancelChoosed: '取消选择',
    choosed: '已选择',
    devices: '个设备',
    photo: '张图片',
    photoAlbum: '相册',
    audioPlay: '音频播放',
    audioList: '音频列表',
    person: '人员',
    date: '日期',
    type: '类型',
    noContent: '暂无内容',
    video: '视频',
    audio: '音频',
    videoSize: '视频大小',
    pictureSize: '照片大小',
    audioSize: '音频大小',
    videoformat: '视频格式',
    pictureformat: '照片格式',
    audioformat: '音频格式',
    audioTime: '音频时间',
    photoTime: '拍照时间',
    audioDuration: '音频时长',
    audioType: '音频类型',
    pictureType: '图片类型',
    picture: '照片'
  },
  report: {
    editTheSedentarySettings: '编辑静默设置',
    addTheSedentarySettings: '添加静默设置',
    viewTheSedentarySettings: '查看静默设置',
    unprocessedRuleViolation: '未处理违反规则',
    reportOnLineState: '上下线报表',
    reportOnLineStateTotal: '设备上下线汇总报表',
    reportOnLineStateDetails: '设备上下线明细报表',
    breakRule: '违反规则',
    breakRuleTotal: '违反统计',
    breakRuleQuery: '违反查询',
    SOSEmergency: 'SOS紧情',
    SOSTotal: 'SOS紧情统计',
    SOSQuery: 'SOS紧情查询',
    errorLog: '故障日志报表',
    errorLogTotal: '故障日志统计',
    errorLogQuery: '故障日志查询',
    talkback: '对讲记录',
    talkbackTotal: '对讲统计',
    talkbackQuery: '对讲查询',
    userBehavior: '用户行为',
    onlineTimeTotal: '在线时长统计',
    userLoginDetail: '用户登录明细',
    userLog: '用户操作日志',
    chartStatistics: '图表统计',
    onlineSum: '上线总数',
    offlineSum: '下线总数',
    onlineTime: '上线时间',
    onlinePosition: '上线位置',
    offlineTime: '下线时间',
    offlinePosition: '下线位置',
    signInAbnormal: '签到不正常',
    breakSitTooLong: '违反久坐',
    executeTheTaskDeviceOffLine: '执行任务--设备不在线',
    takePhotoTimingDeviceOffLine: '定时拍摄--设备不在线',
    takeVideoTimingDeviceOffLine: '定时录像--设备不在线',
    passBackTimingDeviceOffLine: '定时回传--设备不在线',
    tipschooseDeviceBatchThenDisposeBatch: '提示: 批量选择后进行批量处理',
    alarmContent: '报警内容',
    handlingInformation: '处理情况',
    handlingUser: '处理用户',
    handlingContent: '处理内容',
    handlingTime: '处理时间',
    SOSTimes: 'SOS次数',
    talkBack: '对讲',
    talkBackDevice: '对讲设备',
    durationTime: '时长',
    loginAccount: '登录账号',
    name: '姓名',
    totalTimes: '总次数',
    totalDurationTime: '总时长',
    loginWay: '登录方式',
    loginDurationTime: '登录时长',
    user: '用户',
    operationDescription: '操作描述',
    alarmType: '报警类型',
    faultType: '故障类型',
    trendChartOfSOSAlarmFrequency: 'SOS报警次数趋势图',
    SOSTimeProportion: 'SOS时间段占比',
    usePeakIntervalTimes: '使用峰值区间时间',
    flowIntervalUsage: '流量区间使用量'
  },
  operation: {
    adminInfo: '管理员信息',
    deviceInfo: '设备信息',
    SIMCardManagement: 'SIM卡管理',
    SIMCard: 'SIM卡',
    departmentManagement: '部门管理',
    groupManagement: '群组管理',
    deviceUpgradeManagement: '终端升级管理',
    roleManagement: '角色管理',
    No: '序号',
    updateTime: '更新时间',
    role: '角色',
    deviceNum: '设备总数',
    onlineNum: '在线数目',
    offlineNum: '离线数目',
    onlineDevice: '在线设备',
    exportEXCEL: '导出EXCEL',
    SIMCardNumber: 'SIM卡号',
    deviceSerialNumber: '设备序列号',
    firmwareVersion: '固件版本',
    softwareVersion: '软件版本',
    hardwareVersion: '硬件版本',
    productBrand: '产品品牌',
    productModel: '产品型号',
    manufacturers: '厂家',
    belongsToGroup: '所属群组',
    jobNum: '工号',
    packageName: '套餐名称',
    used: '已使用量',
    surplus: '剩余量',
    total: '总量',
    totalToday: '流量合计--当日',
    totalThisMonth: '流量合计--当月',
    cardStatusDesc: '卡状态描述',
    accessToTheNo: '接入号',
    Warning: '流量预警',
    pleaseSelectWarning: '请选择流量预警',
    SIMCardCount: 'SIM卡数目',
    inputDepartmentNameThenClickQueryButton: '输入部门名称后点击查询进行筛选',
    Query: '查询',
    addSubordinate: '添加下级',
    allotDevice: '分配设备',
    viewDeviceList: '查看设备列表',
    groupList: '群组列表',
    allotedDevice: '已分配设备',
    moveInto: '移入',
    moveOut: '移出',
    unallotedDevice: '未分配设备',
    addGroup: '添加群组',
    editGroup: '修改群组',
    deleteGroup: '删除群组',
    versionNumber: '版本号',
    uploadTime: '上传时间',
    versionId: '版本号标识',
    versionType: '版本类型',
    updatedInstructions: '更新说明',
    sender: '下发人',
    upgradeRecord: '升级记录',
    clickGroupToRefresh: '点击群组，可以刷新已分配人员;',
    chooseAGroupBeforeViewAllotedDevice: '查看已分配设备需先选择群组;',
    beforeMoveIntoOrMoveOutNeedToChooseDevice: '移入移出需要先选择设备;'
  },
  set: {
    operationLog: '操作日志',
    time: '时间',
    chooseUser: '选择用户',
    UserName: '用户名称',
    logContent: '日志内容',
    role: '角色',
    No: '序号',
    AllUser: '所有用户',
    applicant: '申请人',
    systemSetting: '系统设置',
    dataManagement: '数据管理',
    functionManagement: '功能管理',
    opinionFeedback: '意见反馈',
    keyManagement: '激活码列表',
    streamingMediaAPI: '流媒体API',
    companyName: '企业名称',
    companyLOGO: '企业LOGO',
    pathNavigation: '路径导航',
    fileName: '文件名',
    fileType: '文件类型',
    fileSize: '文件大小',
    updateDate: '修改日期',
    operationDate: '操作日期',
    feedback: '反馈',
    feedbackDetail: '反馈详情',
    feedbackImages: '反馈图片',
    dealState: '处理状态',
    waitingForDeal: '等待处理中',
    Dealed: '已处理',
    notDeal: '不予处理',
    feedbackDate: '反馈时间',
    feedbackPerson: '反馈人',
    contactInformation: '联系方式',
    feedbackContent: '反馈问题',
    key: '激活码',
    keyStatus: '激活码状态',
    periodOfValidity: '有效期',
    UseDate: '使用时间',
    expireDate: '到期时间',
    restDays: '剩余时长',
    YouHaveNotAppliedForTheSecretKey: '您还没有申请秘钥',
    apply: '申请',
    applyType: '申请状态',
    enterAPIDocument: '进入API文档',
    applyDate: '申请时间',
    keyTotal: '激活码总数',
    used: '已激活',
    noUsed: '未激活',
    tips: '温馨提示：未勾选的功能表与业务组件，平台隐藏不显示。'
  },
  user: {
    loginAccount: '登录账户',
    loginPassword: '登录密码',
    confirmPassword: '确认密码',
    userName: '用户姓名',
    forbiddenChangePassword: '禁用修改密码',
    yes: '是',
    no: '否',
    department: '所属部门',
    pleaseChooseDepartment: '请选择部门',
    pleaseChooseRole: '请选择角色',
    periodOfValidity: '有效期限',
    chooseDate: '选择日期',
    chooseTime: '选择时间',
    userStatus: '用户状态',
    pleaseChooseUserStatus: '请选择用户状态',
    roleName: '角色名称',
    permission: '权限分配',
    requiredFields: '必填项',
    pleaseEnterRemark: '请输入备注',
    feedBack: '反馈',
    dealStatus: '处理状况',
    undeal: '未处理',
    dealed: '已处理',
    deal: '处理',
    feedbackContent: '反馈内容'
  },
  deviceFiles: {
    deviceStorageFile: '设备存储文件',
    clickNotificationToUploadTheVideo: '点击通知上传视频',
    clickNotificationToUploadTheAudio: '点击通知上传音频',
    clickNotificationToUpload: '点击通知上传',
    NoInstructionReceived: '未收到指令',
    deviceHasReceivedInstructions: '设备已收到指令',
    deviceIsExecuting: '设备正在执行',
    commandExecutedReadyToUpload: '指令执行完毕 准备上传...',
    uploading: '上传中...',
    uploadSuccess: '上传成功',
    uploadFail: '上传失败',
    unknowFail: '未知失败',
    InstructionExecutionSchedule: '指令执行进度',
    tipsContent: '提示: 上传成功后可在回放中心查看。',
    ReinitiateTheInstructionAndEnforceIt: '重新发起指令,并强制执行'
  },
  upgrade: {
    UpgradeDeviceVersionnNmber: '升级设备版本号',
    FirmwareVersion: '固件版本号',
    Firmwaretype: '固件类型',
    OTATerminalUpgradeManagemen: 'OTA升级管理',
    AddOTAUpgrade: '新增OTA升级',
    TerminalUpgradeManagemen: 'APK升级管理',
    UploadVersion: '上传版本',
    TestTerminalManagement: '测试终端管理',
    versionNumber: '版本号',
    uploadDate: '上传时间',
    VersionID: '版本号标识',
    VersionType: '版本类型',
    upgradeIntro: '更新说明',
    sender: '下发人',
    upgradeRecord: '升级记录',
    viewRecord: '查看记录',
    AddTestTerminal: '添加测试终端',
    DeviceID: '设备ID',
    DeviceNumber: '设备号',
    TerminalType: '终端类型',
    addTerminal: '添加终端',
    TerminalModel: '终端型号',
    testTerminal: '测试终端',
    PublicBetaTerminal: '公测终端',
    AddAPKUpgrade: '新增APK升级',
    selectTerminalModel: '请选择终端型号',
    enterVersionNumber: '请输入版本号',
    selectVersionType: '请选择版本类型',
    UploadFirmware: '上传固件',
    clickToUpload: '点击上传'
  },
  videoItem: {
    reload: '重新加载',
    stopPlaying: '停止播放',
    thePictureFrameRate: '画面帧率',
    loading: '正在加载',
    frames: '的画面',
    hasBeenSentNotificationTo: '已向 ',
    device: '设备发送通知',
    clientBeginsToRequestData: '客户端开始请求数据',
    clientIsRequestingData: '客户端正在请求数据',
    AnErrorWasEncounteredWhileRequestingData: '请求数据时遇到错误',
    currentPlaybackDevice: '当前播放设备: ',
    unplayedState: '未播放状态',
    stoppedPlaying: '已停止播放',
    ReloadingDevice: '正在重新加载设备',
    LiveInterface: '直播窗口',
    SelectedDeviceList: '已选设备列表',
    Live: '直播',
    Monitor: '监听',
    InitiateGroupTalkback: '发起群组对讲',
    SelectedDeviceListItem: '台'
  },
  talkDialog: {
    groupTalkback: '群组对讲',
    speaking: '正在说话',
    talkbacking: '正在对讲',
    Disconnected: '未连接',
    Connected: '已连接',
    Disconnecting: '正在连接',
    fractured: '已断开'
  },
  deviceBroadcast: {
    RadioWindow: '广播窗口',
    DeviceID: '设备号',
    connectionStatus: '连接状态',
    tips: '提示',
    tipsContent: '已经开启了一个对讲窗口, 是否断开当前连接'
  },
  deviceTalk: {
    IntercomWindow: '对讲窗口',
    InTheIntercom: '对讲中'
  },
  drawer: {
    notificationCenter: '通知中心',
    clearAllNotification: '清除所有通知'
  },
  deviceUser: {
    DeviceUser: '设备使用者',
    DeviceUserName: '使用者名称',
    EnterDeviceUserName: '请输入使用者名称',
    EnterDeviceUserJobNum: '请输入工号',
    duty: '职务',
    EnterDeviceUserDuty: '请输入职务',
    departmentName: '部门名称',
    deviceAllot: '设备分配',
    deviceADistributionStatellot: '分配状态',
    setDeviceUser: '指定为设备使用者'
  },
  deviceUpgrade: {
    deviceUpgrade: '设备升级',
    versionName: '升级版本名称',
    versionNumber: '升级版本号',
    upgradeFile: '升级文件',
    uploadTime: '文件上传时间',
    userInformation: '用户信息',
    pleaseSelectType: '请选择类型',
    pleaseEnterKeyword: '请输入关键词',
    attendanceRecord: '考勤记录',
    ViewRecord: '查看记录',
    AddAPKUpgrade: '新增APK升级',
    DeviceType: '终端型号',
    SelectDeviceType: '请选择终端型号',
    SelectVersionNumber: '请选择版本号',
    Alpha: 'Alpha版',
    Beta: 'Beta版',
    official: '正式版',
    UploadTheFirmware: '上传固件',
    clickToUpload: '点击上传'
  },
  card: {
    stop: '报停',
    restart: '复机',
    subordinate: '下级',
    EnterNameOrNumber: '请输入名称或编号',
    EnterDealContent: '请输入处理内容',
    WarningInfo: '报警信息'
  },
  tips: {
    tips1: '请单击选中设备',
    tips2: '请输入反馈人',
    tips3: '请输入联系方式',
    tips4: '请输入反馈问题',
    tips5: '请输入反馈内容',
    tips6: '保存成功',
    tips7: '备注成功',
    tips8: '发送成功',
    tips9: '创建成功',
    tips10: '请选择音频',
    tips11: '请选择视频',
    tips12: '请选择照片',
    tips13: '正在下载，请稍等',
    tips14: '请选择下载照片',
    tips15: '请填写旧密码',
    tips16: '请填写新密码',
    tips17: '已选设备均不在线',
    tips18: '已过滤离线设备',
    tips19: '修改成功, 请重新登录',
    tips20: '无法开启麦克风，请检查电脑麦克风是否正常。',
    tips21: '请输入要创建的激活码数量',
    tips22: '请输入激活码的有效期',
    tips23: '该文件类型暂无内容',
    tips24: '请输入规则名称',
    tips25: '请设置时间范围',
    tips26: '请输入停留时间',
    tips27: '请选择抓拍模式',
    tips28: '请选择区域',
    tips29: '请选择行政区域',
    tips30: '请至少选择一台设备',
    tips31: '图片最多上传9张',
    tips32: '请输入使用者名称',
    tips33: '请输入工号',
    tips34: '请输入职务',
    tips35: '请选择流量警报',
    tips36: '请选择任务名称',
    tips37: '请选择任务时间',
    tips38: '请选择时间范围',
    tips39: '请选择任务类型',
    tips40: '请选择设备',
    tips41: '请输入文件名称',
    tips42: '请选择上传文件',
    tips43: '请选择上传文件类型',
    tips44: '请输入角色姓名',
    tips45: '上传头像图片只能是 JPG 格式!',
    tips46: '上传头像图片大小不能超过 2MB!',
    tips47: '默认新增客户为启用状态, 修改用户状态请先保存用户信息',
    tips48: '最多添加10个',
    tips49: '请输入版本名称',
    tips50: '请输入版本号',
    tips51: '请上传升级文件',
    tips52: '请选择文件上传时间',
    tips53: '请输入群组名称',
    tips54: '请输入登录账户',
    tips55: '请输入登录密码',
    tips56: '两次密码输入不一致,请重新输入',
    tips57: '请输入用户姓名',
    tips58: '请选择是否禁用修改密码',
    tips59: '请选择所属部门',
    tips60: '请选择角色',
    tips61: '请选择有效期限',
    tips62: '请选择用户状态',
    tips63: '地图中未找到设备',
    tips64: '进入拉框查找模式',
    tips65: '请选择设备',
    tips66: '请选择2天内的时间范围'
  },
  storage: {
    storageWarningTotal: '存储预警统计',
    storageWarningQuery: '存储预警查询',
    storageAlertTrendChart: '存储预警趋势图',
    storageWarning: '存储预警'
  },
  indexOperation: {
    NoticeLive: '通知直播',
    NoticeVideo: '通知录像',
    NoticePhoto: '通知拍照',
    SendBroadcast: '发起广播',
    AddTask: '新增任务',
    Login: '登录',
    logout: '退出登录',
    InitiateIntercom: '发起对讲',
    saveNameAndLogo: '保存名称和logo',
    addCameraUser: '新增设备使用者',
    allotRule: '规则分配设备',
    DeleteRule: '删除规则',
    remoteMonitoring: '远程监听',
    SendImageTextSMS: '发送图文短信',
    AddDepartment: '添加部门',
    UpdateDepartment: '更新部门',
    addAdministrator: '新增管理员',
    editAdministrator: '编辑管理员',
    deleteAdministrator: '删除管理员',
    deleteDepartment: '删除部门',
    changePassword: '修改密码',
    changeAdministratorPassword: '更新管理员密码',
    NewElectronicFence: '新增电子围栏',
    editTask: '编辑任务',
    deleteTask: '删除任务',
    IssueFileToDevice: '下发文件给设备',
    addGroup: '添加群组',
    movedDevicesIntoGroups: '设备移入群组',
    EditingDeviceUser: '编辑设备使用者',
    PayAttentionToEquipment: '关注设备',
    DeleteGroup: '删除群组'
  },
  deviceImport: {
    downloadTem: '下载模板',
    importBatch: '批量导入',
    importDeviceBatch: '批量导入设备',
    ActivationCodeValidity: '激活码有效期(月)',
    tips1: '请填写激活码有效期'
  },
  helpCenter: {
    helpCenter: '帮助中心'
  },
  blacklist: {
    blacklistManager: '黑名单库',
    blacklistRecorder: '黑名单匹配记录'
  },
  noticeManage: {
    tenantAnnouncementManagement: '租户公告管理',
    announcementTitle: '公告标题',
    pleaseEnterTheTitleContent: '请输入标题内容',
    startTime: '起始时间',
    announcementContent: '公告内容',
    lastPublished: '上次发布时间',
    notPublished: '未发布过',
    release: '发布',
    pleaseEnterATitle: '请输入标题',
    pleaseSelectThePublishingTime: '请选择发布时间',
    publishedSuccessfully: '发布成功'
  },
  tasksOfTheDay: {
    task: '任务',
    taskOfTheDay: '当日任务',
    thereAreNoTasksToday: '当日无任务',
    executing: '正在执行',
    taskType: '任务类型',
    routineTasks: '例行任务',
    temporaryTasks: '临时任务',
    emergencyMission: '紧急任务',
    taskExecutionTime: '任务执行时间',
    everyday: '每日',
    Everyday: '每一天',
    unperformedTask: '未执行的任务',
    theListShowsTasksThatWereNotYetPerformedThatDay:
      '该列表显示当天尚未执行的任务',
    taskAlreadyPerformed: '已执行的任务',
    noDataWasObtained: '未获取数据',
    theListShowsTheTasksBeingPerformedInTheCurrentTimePeriod:
      '该列表显示当前时间段正在执行的任务',
    theListShowsTheTasksThatHaveBeenPerformedOnTheDay:
      '该列表显示当天已执行的任务',
    monday: '星期一',
    tuesday: '星期二',
    wednesday: '星期三',
    thursday: '星期四',
    friday: '星期五',
    saturday: '星期六',
    sunday: '星期日',
    pleaseSelectAttendanceTime: '请选择考勤时间',
    pleaseSelectAttendanceStartAndEndTime: '请选择考勤开始结束时间',
    assignTasks: '分配任务',
    associatedTask: '关联任务',
    selectAssociatedTask: '选择关联任务',
    executionDate: '执行日期',
    specifyWeek: '指定星期',
    specifyDate: '指定日期',
    specifyTimePeriod: '指定时间段',
    selectOneOrMoreDates: '选择一个或多个日期',
    executionTime: '执行时间',
    independentExecution: '独立执行',
    collaborativeExecution: '协同执行',
    pleaseSelectExecutionMode: '请选择执行模式',
    executive: '执行人员',
    selectExecutive: '选择执行人员',
    pleaseSelectAnAssociatedTask: '请选择关联任务',
    pleaseSelectTheExecutionDate: '请选择执行日期',
    pleaseSelectTheExecutionTime: '请选择执行时间',
    pleaseSelectTaskType: '请选择任务类型',
    pleaseSelectAnExecutive: '请选择执行人员',
    pleaseSelectASpecifiedWeek: '请选择指定星期',
    pleaseSelectADate: '请选择日期',
    pleaseSelectATimePeriod: '请选择时间段',
    executionMode: '执行模式',
    deactivate: '停用',
    enable: '启用',
    refresh: '刷新',
    TaskDescription: '任务描述',
    viewOrEditTasks: '查看或编辑任务',
    taskName: '任务名称',
    taskDescription: '任务说明',
    taskOperation: '任务操作',
    pleaseSelectPersonnelFilter: '请选择人员筛选',
    switchToTaskView: '切换到任务视图',
    equipmentNumber: '设备编号',
    noAccessAddress: '暂无获取地址',
    waitingToUploadPhotos: '等待上传拍照',
    waitingToUploadVideo: '等待上传录像',
    waitingToUploadNFC: '等待上传NFC',
    yourBrowserDoesNotSupportVideoTags: '您的浏览器不支持 video 标签。',
    messageStopped: '留言已停止',
    messagePlayingCompleted: '留言播放完成',
    playingTheRecordingWillStopTheMessagesInOtherFiles:
      '播放录音，会停止其他文件播放中的留言',
    playbackCanceled: '已取消播放',
    switchToPeopleView: '切换到人员视图',
    application: '应用',
    basicEdition: '基础版',
    professionalEdition: '专业版',
    taskList: '任务列表',
    newTask: '新建任务',
    commonActionManagement: '常用动作管理',
    usageTimes: '使用次数',
    lastModificationTime: '最后修改时间',
    addAction: '新增动作',
    pleaseEnterActionName: '请输入动作名称',
    operationTask: '操作任务',
    selectAction: '选择动作',
    pleaseEnterTheContent: '请输入内容',
    addTaskAction: '添加任务操作',
    enterAtLeastOneTaskOperation: '最少输入一个任务操作',
    enforceSequentialExecution: '强制按顺序执行',
    taskAllocation: '任务分配',
    taskTime: '任务时间',
    taskTimePeriod: '任务时间段',
    lastExecutionProgress: '上次执行进度',
    selectDepartment: '选择部门',
    subordinate: '下级',
    reselectDepartment: '重选部门',
    subordinateDepartment: '下级部门',
    workRecord: '工作记录',
    exportAllVideoFiles: '导出所有视频文件',
    restoreFile: '恢复文件',
    discardFile: '丢弃文件',
    barcode: '条码号',
    pleaseEnterUserNameToFind: '请输入使用者名称查找',
    departmentSelector: '部门选择器',
    personSelector: '人员选择器',
    myAttention: '我的关注',
    unassignedDevice: '未分配设备',
    findByDepartment: '按部门查找',
    allocationSucceeded: '分配成功',
    unassignedSuccessfully: '取消分配成功',
    tips1: '搜索输入框(输入人员名称或工号)',
    pleaseSelectCheckInStatus: '请选择签到情况',
    whole: '全部',
    normal: '正常',
    late: '迟到',
    absenceFromDuty: '缺勤',
    goOffWork: '下班',
    selectTerminal: '选择终端',
    attendanceTime: '考勤时间',
    checkInTime: '签到时间',
    equipment: '设备',
    checkInLocation: '签到位置',
    checkIn: '签到情况',
    choice: '选择',
    clickToStartTalking: '点击开始说话',
    clickCloseToTalk: '点击关闭说话',
    muteAll: '全部静音',
    unmuteAll: '全部取消静音',
    turnOffAllVideos: '关闭所有视频',
    upgradeComplete: '升级完成',
    upgrading: '升级中',
    removeAll: '全部移除',
    allCardStatus: '全部卡状态',
    allStates: '全部状态',
    alreadyActivated: '已经激活',
    notActive: '未激活',
    allAreas: '全部区域',
    northChinaRegion: '华北区域',
    southChinaRegion: '华南区域',
    southwestRegion: '西南区域',
    inOperation: '运行中',
    stopOperation: '停止运行',
    tips2: '考勤时间(开始-结束)',
    upgradeEquipment: '升级设备',
    downloadTime: '下载时间',
    downloadProgress: '下载进度',
    upgradeTime: '升级时间',
    upgradeSituation: '升级情况',
    currentVersion: '当前版本',
    isItTheCurrentVersion: '是否当前版本',
    upgradeRecord: '升级记录',
    installationIsComplete: '安装完成',
    notUpgraded: '未升级',
    departmentPersonnel: '部门人员',
    name: '名字',
    pleaseEnterDepartmentPersonnelName: '请输入部门人员名字',
    ToDigitsNumbersOrLetters: '4到8位数，数字或字母',
    modifyDevicePassword: '修改设备密码',
    modifyTheOperatingTerminalPassword: '修改操作端密码',
    departmentEquipment: '部门设备',
    deviceSearch: '设备搜索',
    assignorName: '分配者名称',
    jobNumber: '工号',
    assignPersonnel: '分配人员',
    moveToAnotherDepartment: '移至其他部门',
    violationOfRules: '违反规则',
    batchProcessing: '批量处理',
    noEntry: '禁入',
    noGoingOut: '禁出',
    lateForCheckIn: '签到迟到',
    checkInAbsence: '签到缺勤',
    taskNotPerformed: '未执行任务',
    batchProcessingAfterSelectingEquipmentInBatch: '批量选择设备后进行批量处理',
    pleaseEnterTheProcessingContent: '请输入处理内容',
    processingSucceeded: '处理成功',
    violationOfTheNoEntryRule: '违反禁入规则',
    violationOfTheNoGoRule: '违反禁出规则',
    violationOfSedentaryRules: '违反久坐规则',
    performTasks: '执行任务',
    theEquipmentIsNotWithinTheScopeOfTheTask: '设备不在任务范围内',
    unassign: '取消成功',
    departmentPersonnelManagement: '部门人员管理',
    pleaseEnterTheJobNumberOfDepartmentPersonnel: '请输入部门人员工号',
    selectMap: '选择地图',
    selectedAreaMapName: '选择的区域地图名字',
    selectTheDepartmentGroupOrConcernedPerson: '选择所属部门、群组或关注人员',
    productDepartment: '产品部',
    pleaseEnter4To8DigitsOfTheJobNumberLength: '请输入工号长度4至8位数',
    numberOfEquipment: '设备数量',
    equipmentOnline: '设备在线',
    deviceOffline: '设备离线',
    longTermOfflineOfEquipment: '设备长期离线',
    modifiedSuccessfully: '修改成功',
    batchExportImportModifyPersonnel: '批量导出/导入/修改人员',
    batchImportPersonnel: '批量导入人员',
    batchExport: '批量导出',
    modifier: '修改人员',
    downloadTemplate: '下载模版',
    exportPersonnelInformationByDepartment: '按部门导出人员信息',
    fileGenerationInProgress: '文件生成中',
    personnelListExportedSuccessfully: '导出人员名单成功',
    uploadTheCompletedFile: '上传填写好的文件',
    uploadFile: '上传文件',
    importSucceeded: '导入成功',
    importFailed: '导入失败',
    downloadFailureRecord: '下载失败记录',
    reUpload: '重新上传',
    mattersNeedingAttention: '注意事项',
    personnelSearch: '人员搜索',
    distribution: '分配',
    cancellationSucceeded: '取消成功',
    tips3: '校验失败时，停止导入数据',
    tips4: '如部门不存在，自动创建部门',
    tips5: '密码由8-20位大小写字母、数字、特殊符号组成',
    tips6: '下载导入人员模版，填写人员信息',
    pleaseEnterDepartmentName: '请输入部门名称',
    youCanEnterARuleNameToFilter: '可输入规则名进行筛选',
    equipmentOfflineUpgradeRecord: '设备离线升级记录表',
    versionName: '版本名称',
    completionStatus: '完成情况',
    unexecuted: '未执行',
    missionAccomplished: '任务完成',
    batchImportBlacklist: '批量导入黑名单',
    instructions: '使用说明',
    pleaseDownloadTheExcelTemplateFileFirst: '请先下载Excel模板文件',
    clickDownloadTemplate: '点击下载模板',
    editBlacklist: '编辑黑名单',
    uploadToPlatformBlacklistLibrary: '上传至平台黑名单库',
    clickUploadExcelFile: '点击上传Excel文件',
    pleaseUploadAgain: '请重新上传',
    pleaseSelectAFile: '请选择文件',
    pleaseSelectTaskDevice: '请选择任务设备',
    equipmentIntercomRequestSetting: '设备对讲请求设置',
    promptTime: '提示时间',
    meansNoAutomaticShutdown: '0表示不自动关闭',
    playSoundAtTheSameTime: '同时播放声音',
    soundFile: '声音文件',
    pleaseSelectASoundFile: '请选择声音文件',
    audition: '试听',
    setUp: '设置',
    receive: '接收',
    refuse: '拒绝',
    turnOnAllVideos: '开启所有视频',
    terminalID: '终端ID',
    frequency: '次数',
    time: '时间',
    state: '状态',
    confirmClose: '确认关闭',
    processingEquipmentIntercomRequest: '处理设备对讲请求',
    addPeople: '添加人员',
    moveSuccessful: '移至成功',
    movingTo: '正在移至到',
    tips7: '是否删除该部门？删除后将不可恢复',
    tips8: '是否确认删除？删除后将不可恢复',
    tips9: '请输入部门名字'
  },
  stationmail: {
    agencyStationLetter: '代理站内信',
    newStationMessage: '新增站内信',
    title: '标题',
    receivingObject: '接收对象',
    messageType: '消息类型',
    sendingTime: '发送时间',
    messageContent: '站内信内容',
    tenantCustomers: '租户客户',
    importBlacklist: '导入黑名单',
    batchDelete: '批量删除',
    iDNumber: '身份证号',
    region: '地区',
    address: '地址',
    gender: '性别',
    headPortrait: '头像',
    addTime: '添加时间',
    label: '标签',
    binding: '绑定',
    newLabel: '新建标签',
    loginAccountAndName: '登录账号、姓名',
    pleaseEnterAValue: '请输入值',
    reportingAddress: '上报地址',
    reliability: '可信度',
    routineMaintenance: '日常维护',
    productMessage: '产品消息',
    securityMessage: '安全消息',
    serviceMessage: '服务消息',
    agent: '代理商',
    tenant: '租户',
    pleaseSelectMessageType: '请选择消息类型',
    newStationLetter: '新增站内信',
    pleaseSelectAtLeastOneCustomer: '请至少选择一个客户',
    pleaseSelectAtLeastOneAgent: '请至少选择一个代理商',
    pleaseSelectTheReceivingObject: '请选择接收对象',
    pleaseEnterTheActivityName: '请输入活动名称',
    forwardingSucceeded: '转发成功',
    tips: '上传视频大小不能超过 500MB!',
    inApplication: '申请中',
    applicationPassed: '申请通过',
    applicationFailed: '申请未通过',
    platformStationLetter: '平台站内信',
    mail: '站内信',
    stationLetterList: '站内信列表',
    sendStationLetter: '发送站内信'
  },
  equipment: {
    terminalEquipment: '终端设备',
    passageway: '通道',
    file: '文件',
    mediaDownload: '媒体下载',
    downloadSpeed: '下载速度',
    diskManagement: '磁盘管理',
    insufficientSpace: '空间不足',
    pleaseSelectAnActiveArea: '请选择活动区域',
    overwriteOldestFile: '覆盖最早文件',
    stopOperation: '停止操作',
    reservedSpace: '保留空间',
    earlyWarningSpace: '预警空间',
    retentionDays: '保留天数',
    fileManagement: '文件管理',
    snapPictures: '抓拍图片',
    browse: '浏览',
    open: '打开',
    fileDownload: '文件下载',
    sOSAlarmReminder: 'SOS报警提醒',
    equipmentInitiatesSOSEmergencyAlarm: '设备发起SOS紧急报警',
    alarmDuration: '报警时长',
    startPosition: '开始位置',
    endPosition: '结束位置',
    alarmInformation: '报警信息',
    alarmSource: '报警来源',
    platform: '平台',
    queryAfterSelectingEquipment: '选择设备后进行查询',
    clickToView: '点击查看',
    voice: '声音',
    audioTime: '音频时间',
    audioDuration: '音频时长',
    audioType: '音频类型',
    pictureInformation: '图片信息',
    noDataHasBeenObtained: '暂未获取数据',
    noPositioningData: '暂无定位数据'
  },
  serverCollector: {
    serverResourceView: '服务器资源查看',
    serverResources: '服务器资源',
    masterHost: 'Master主机',
    numberOfCores: '核心数量',
    memory: '内存',
    hardDisk: '硬盘',
    objectStorageResources: '对象存储资源',
    mediaStorage: '媒体存储',
    notSet: '未设置',
    pleaseEnterTheStorageResourceSize: '请输入存储资源大小',
    cPULoad: 'CPU负载',
    configurationInformation: '配置信息',
    hardDiskCapacity: '硬盘容量',
    operatingSystem: '操作系统',
    intranetIP: '内网IP',
    dockorVersion: 'Dockor版本',
    monitoringInformation: '监控信息',
    networkCard: '网卡',
    usage: '使用量',
    receiveDifference: '接收差值',
    sendDifference: '发送差值'
  },
  errorLogTotal: {
    gnssFault: 'GNSS故障',
    gpssignalLossFault: 'GPS信号丢失故障',
    ttsFault: 'TTS故障',
    cameraFailure: '摄像头故障'
  }
}
