import Axios from '@/http'

export default {
  /* SOS结案 */
  sosDeal(params) {
    return new Promise((resolve, reject) => {
      Axios.post('report/sos/deal', params, { isJSON: true }).then((res) => {
        return resolve(res)
      })
    })
  },
  /* 违规处理 */
  ruleViolateDeal(params) {
    return new Promise((resolve, reject) => {
      Axios.post('report/ruleViolate/deal', params, { isJSON: true }).then(
        (res) => {
          return resolve(res)
        }
      )
    })
  },
  /* 故障类型归类占比-扇形图 */
  summaryDeviceFault(params) {
    return new Promise((resolve, reject) => {
      Axios.post('report/summary/deviceFault', params).then((res) => {
        return resolve(res)
      })
    })
  },
  /* SOS报警次数趋势图 */
  summarySosTrend(params) {
    return new Promise((resolve, reject) => {
      Axios.post('report/summary/sosTrend', params).then((res) => {
        return resolve(res)
      })
    })
  },
  /* SOS时间段占比 */
  summarySosHourSpace(params) {
    return new Promise((resolve, reject) => {
      Axios.post('report/summary/sosHourSpace', params).then((res) => {
        return resolve(res)
      })
    })
  },
  /* 流量区间使用量 */
  summaryFlowSpace(params) {
    return new Promise((resolve, reject) => {
      Axios.post('report/summary/flowSpace', params).then((res) => {
        return resolve(res)
      })
    })
  },
  /* 使用峰值区间时间-流量小时内使用情况 */
  summaryFlowHour(params) {
    return new Promise((resolve, reject) => {
      Axios.post('report/summary/flowHour', params).then((res) => {
        return resolve(res)
      })
    })
  }
}
