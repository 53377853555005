import Axios from '@/http'
export default {
  /* 图注信息 */
  mapLayerConfig() {
    return new Promise((resolve, reject) => {
      Axios.post('common/mapLayerConfig').then((res) => {
        return resolve(res)
      })
    })
  },
  businessSystemNameLogo() {
    return new Promise((resolve, reject) => {
      Axios.post('common/businessSystemNameLogo').then((res) => {
        return resolve(res)
      })
    })
  },
  /* 录制最长时长-查询 || 编辑 */
  recDuration(params) {
    let url = null
    let isJSON = false
    if (params) {
      url = 'common/recDuration/update'
      isJSON = true
    } else {
      url = 'common/recDuration'
    }
    return new Promise((resolve, reject) => {
      Axios.post(url, params, { isJSON }).then((res) => {
        resolve(res)
      })
    })
  },
  /* 修改用户引导 */
  userGuidanceDataSaveFn(params) {
    let url = ''
    if (params) {
      url = 'common/navigationSetting/update'
    } else {
      url = 'common/navigationSetting'
    }
    return new Promise((resolve, reject) => {
      Axios.post(url, params, { isJSON: true }).then((res) => {
        return resolve(res)
      })
    })
  },
  /* 获取离线地图信息 */
  bigMapConfig() {
    return new Promise((resolve, reject) => {
      Axios.post('common/bigMapConfig').then((res) => {
        resolve(res)
      })
    })
  },
  /* 根据名称获取poi */
  getPoiByNameFn(params) {
    return new Promise((resolve, reject) => {
      Axios.post('common/getPoiByName', params).then((res) => {
        resolve(res)
      })
    })
  },
  /* 地图设置 */
  mapSettingInfo(params) {
    return new Promise((resolve, reject) => {
      Axios.post('common/mapSettingInfo', params).then((res) => {
        resolve(res)
      })
    })
  },
  /* 获取arcgis地图配置信息 */
  arcgisMapConfig(params) {
    return new Promise((resolve, reject) => {
      Axios.post('common/arcgisMapConfig', params).then((res) => {
        resolve(res)
      })
    })
  },
  /* 报警类型 */
  commonWarningType(params) {
    return new Promise((resolve, reject) => {
      Axios.post('/common/warningType', params).then((res) => {
        resolve(res)
      })
    })
  },
  /* 设备故障类型 */
  commonDeviceFaultCode(params) {
    return new Promise((resolve, reject) => {
      Axios.post('/common/deviceFaultCode', params).then((res) => {
        resolve(res)
      })
    })
  },
  /* 获取系统配置信息 */
  platformLoginCover(params) {
    return new Promise((resolve, reject) => {
      Axios.post('common/platformLoginCover', params).then((res) => {
        resolve(res)
      })
    })
  }
}
