<!-- 警单长时间未处理通知 -->
<template>
  <el-dialog
    :destroy-on-close="true"
    :visible.sync="dialogVisible"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    class="dialog_clsss filter-dialog"
    width="600px"
  >
    <div class="deleteBox c_fff">
      <div class="dialog_header !p-[0px_0_0]">
        <div class="iconCloseBox clickBox closeBox">
          <svg-icon
            @click.native="onClose()"
            svg-name="icon_close"
            className="svgBox !w-[26px] !h-[26px]"
          />
        </div>
      </div>
      <div class="dialog_body !p-[0_0_0px] h-[40vh] min-h-[300px]">
        <div class="w-full h-full flex">
          <!-- 案件列表 -->
          <div
            :class="{ '!w-0 overflow-hidden': dataList.length <= 1 }"
            class="h-full w-[40%] duration-500"
          >
            <div
              class="min-w-[200px] h-full flex flex-col text-main border-r-[2px] border-border p-[10px_0] box_border"
            >
              <div
                class="flex justify-between items-center h-[30px] w-full box-border px-[10px]"
              >
                <div class="text-sm font-bold">多个警情无人响应</div>
                <!--<div>-->
                <!--  <el-button-->
                <!--    size="mini"-->
                <!--    class="button_default !p-[4px_10px]"-->
                <!--    @click="onClose"-->
                <!--  >-->
                <!--    <span class="text-xs font-[400]">暂不处理</span>-->
                <!--  </el-button>-->
                <!--</div>-->
              </div>
              <ul
                class="flex-grow h-0 mt-[4px] box-border p-[4px_10px] text-xs overflow-y-auto overflow-x-hidden"
              >
                <li
                  v-for="(item, index) in dataList"
                  :key="index"
                  :class="{
                    'border-theme bg-backgroundv3': item.id === form.id
                  }"
                  @click="setForm(item)"
                  class="h-[36px] bg-backgroundv2 border-backgroundv2 flex items-center rounded px-[10px] border-[2px] hover:bg-backgroundv3 hover:border-theme clickBox mb-[8px] relative"
                >
                  <div
                    v-show="item.id === form.id"
                    class="absolute top-1/2 -translate-y-1/2 -right-[12px]"
                  >
                    <svg-icon
                      svg-name="mediaCenter_navigationList_select"
                      class-name="svgBox !w-[12px] !h-[12px]"
                    />
                  </div>
                  <div class="flex-grow w-0 flex items-center">
                    <div class="over_one_line">警情：{{ item.incidentId }}</div>
                  </div>
                  <!--<div-->
                  <!--  v-show="item.id !== form.id"-->
                  <!--  @click.stop="deleteDataList(index)"-->
                  <!--  class="flex-shrink-0 ml-2 text-theme hover:text-dangerv2 clickBox flex items-center"-->
                  <!--&gt;-->
                  <!--  <svg-icon svg-name="icon_close" className="svgBox" />-->
                  <!--</div>-->
                </li>
              </ul>
            </div>
          </div>
          <!-- 详情展示 -->
          <div
            class="flex-grow w-0 text-main text-xs flex flex-col items-center text-center py-[20px] duration-500 relative"
          >
            <div class="text-lg font-bold">
              <svg-icon
                svg-name="tips"
                className="svgBox !w-[18px] !h-[18px]"
              />
              <p>报警器警情提醒</p>
            </div>
            <div class="flex-grow h-0 flex flex-col justify-center w-full">
              <div
                class="bg-backgroundv2 w-full mb-[10px] leading-[34px] font-bold"
              >
                警情：{{ form.incidentId }}
              </div>
              <div class="leading-[22px]">
                <p>
                  <span class="text-dangerv2 font-bold">{{
                    form.alarmDtm
                  }}</span>
                  发生报警未处理，
                </p>
                <p>请尽快处理</p>
              </div>
            </div>
            <div class="operator-button centerBox mt_20">
              <div>
                <el-button
                  size="small"
                  class="button_default buttonBox"
                  @click="setHadRead(form)"
                  >稍后处理</el-button
                >
                <el-button
                  size="small"
                  class="button_function buttonBox"
                  @click="handle()"
                  >前往处理</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      form: {},
      dataList: [],
      dialogVisible: false
    }
  },
  mounted() {},
  methods: {
    // 待处理警单数量变动通知（收到此通知，web端刷新待处理警单列表）：
    alarmNumChange(id) {
      if (!this.dialogVisible) return
      if (this.form && this.form.id === id) {
        this.getData(id)
      } else {
        this.getData()
      }
    },
    // 前往处理
    handle() {
      this.setHadRead(this.form)
      this.onClose()
      this.$emit('on-viewAlarmRecord', this.form)
    },
    // 获取数据
    async getData(id) {
      const { code, data } = await this.$api.findAll('alarmRecord/waitDeal')
      if (code === 200) {
        let index = 0
        const content = data || []
        if (content.length !== 0) {
          this.dataList = content
          if (id) {
            index = this.dataList.findIndex((v) => v.id === id)
          }
          this.setForm(this.dataList[index])
          this.dialogVisible = true
        }
      }
    },
    // 设置已读
    setHadRead(row) {
      const index = this.dataList.findIndex((v) => v.id === row.id)
      if (index !== -1) {
        this.deleteDataList(index)
        this.$nextTick(() => {
          if (this.dataList.length === 0) {
            this.onClose()
          } else {
            if (row.id === this.form.id) {
              this.setForm(this.dataList[0])
            }
          }
        })
      }
    },
    // 删除内容
    deleteDataList(index) {
      this.dataList.splice(index, 1)
    },
    setForm(row) {
      this.form = { ...row }
    },
    show(id) {
      this.getData(id)
    },
    onClose() {
      this.dialogVisible = false
    }
  }
}
</script>
<style scoped lang="less" rel="stylesheet/less"></style>
