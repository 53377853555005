import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import store from './store/'
import api from './api'
import apiHttp from '@/http'
import microApps from './micro-app'
import BaseUrlConfig from '@/http/config.js'
import VueI18n from 'vue-i18n'
import ElementUI, { Message } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import inputCheck from '@utils/inputCheck.js'
import rsa from '@utils/rsa'
import axios from 'axios'
import {
  registerMicroApps,
  start,
  addGlobalUncaughtErrorHandler
} from 'qiankun'
import sdk from '@utils/hyRtcSdk'
import business from '@utils/hyRtcBusiness'
import VirtualList from 'vue-virtual-scroll-list'
import Driver from '@/utils/driver.min.js'
import './styles/main.less'
import './styles/driver.less'
import './styles/tailwindcss.css'
import { Common as hyCommon } from 'hy-common'
import map from '@utils/map.js'
import { newVueRouter } from './router'
let router = null

const {
  svgUrl,
  messages,
  stylesName
} = require(`@/version/${process.env.VUE_APP_BUILD_VERION}.js`)
if (stylesName === 'basics_dispatch') {
  // eslint-disable-next-line no-unused-expressions
  import('@/styles/basics_dispatch.less')
} else if (stylesName === 'basics_tiantong') {
  // eslint-disable-next-line no-unused-expressions
  import('@/styles/basics_tiantong.less')
} else {
  // eslint-disable-next-line no-unused-expressions
  import('@/styles/basics.less')
}
Vue.prototype.$map = map
/* hy-common 公共组件 */
hyCommon.setAxios(apiHttp)

Vue.use(sdk)
Vue.use(business)
Vue.config.productionTip = false
Vue.use(Vuex)
Vue.use(api)

// Vue.use(ElementUI)
Vue.use(ElementUI, {
  size: 'small',
  zIndex: 30000
})
Vue.prototype.$message = function (msg) {
  return messageFn(msg)
}
Vue.prototype.$message.success = function (msg) {
  return messageFn(msg, 'success')
}
Vue.prototype.$message.warning = function (msg) {
  return messageFn(msg, 'warning')
}
Vue.prototype.$message.error = function (msg) {
  return messageFn(msg, 'error')
}
function messageFn(msg, messageName) {
  let options = {}
  if (typeof msg === 'string') {
    options = {
      message: msg,
      showClose: true
    }
    if (messageName) {
      return Message[`${messageName}`](options)
    } else {
      return Message(options)
    }
  } else {
    options = {
      ...msg,
      showClose: true
    }
    if (messageName) {
      return Message[`${messageName}`](options)
    } else {
      return Message(options)
    }
  }
}

Vue.use(inputCheck)
// Vue.use(webgis)
Vue.use(rsa)
Vue.use(VueI18n)
Vue.component('virtual-list', VirtualList)
const userGuidance = new Driver({
  onHighlightStarted: () => {
    /* 禁止鼠标点击 */
    userGuidance.steps.forEach((el) => {
      el.node.style.pointerEvents = 'none'
    })
  },
  onReset: () => {
    /* 关闭用户指南 */
    store.dispatch('userGuidanceFn')
    /* 允许鼠标点击 */
    userGuidance.steps.forEach((el) => {
      el.node.style.pointerEvents = 'auto'
    })
  },
  padding: 10,
  stageBackground: 'var(--backgroundv2)',
  allowClose: false, // 是否允许点击遮罩时关闭
  overlayClickNext: false, // 是否允许点击遮罩时移到到下一步
  doneBtnText: '知道了', // 最后一个按钮的文本
  closeBtnText: '跳过', // 关闭按钮文本
  nextBtnText: '下一步', // 下一步
  prevBtnText: '上一步' // 上一步
})
window.userGuidance = userGuidance

const cloneDeep = window.customPublicMethod.cloneDeep
for (const componentsName in window.commonComponents) {
  Vue.component(
    componentsName,
    cloneDeep(window.commonComponents[componentsName])
  )
}
const i18n = new VueI18n({
  // locale: JSON.parse(window.sessionStorage.getItem('language')) === 'Chinese' ? 'zh' : 'en',
  locale: 'zh',
  messages,
  silentTranslationWarn: true
})

/* 小弹窗 */
let deleteConfirmationBox = null
const init = () => {
  // 用Vue.extend 创建组件的模板（构造函数）
  const DeleteConfirmationBox = Vue.extend(
    window.commonComponents.deleteConfirmationBox
  )
  // 实例化组件,构造函数可以传参 data, method
  deleteConfirmationBox = new DeleteConfirmationBox({})
  // 挂载组件到页面上
  deleteConfirmationBox.$mount()
  document.body.appendChild(deleteConfirmationBox.$el)
}
const caller = (options, determineFunc, closeFunc) => {
  init()
  // getParams是在组件里定义的方法，获取传过来的参数和方法
  deleteConfirmationBox.show(options, determineFunc, closeFunc).then(() => {
    deleteConfirmationBox.$destroy()
  })
}
Vue.prototype.$deleteConfirmationBox = caller

Vue.directive('permission', {
  inserted: (el, binding) => {
    const operation = binding.value
    if (operation) {
      let permissionIdList = window.sessionStorage.getItem('permissionIdList')
      if (permissionIdList && permissionIdList.length) {
        permissionIdList = JSON.parse(permissionIdList)
        if (permissionIdList.findIndex((v) => v === operation) === -1) {
          el.parentNode.removeChild(el)
        }
      }
    }
  }
})

Vue.directive('delTabIndex', {
  bind(el, binding) {
    // el为绑定的元素，binding为绑定给指令的对象
    el.__vueSetTimeoutIndex__ = setTimeout(() => {
      // 清除当前tabIndex
      el.removeAttribute('tabindex')
      clearTimeout(el.__vueSetTimeoutIndex__)
    }, 0)
  },
  unbind(el) {
    clearTimeout(el.__vueSetTimeoutIndex__)
  }
})

// 文件路径 是否有子级 校验
const req = svgUrl || require.context('@/assets/basics/svg', true, /\.svg$/)
const requireAll = (requireContent) => requireContent.keys().map(requireContent)
requireAll(req)

// 在主应用中注册子应用
addGlobalUncaughtErrorHandler((eventn, s) => {
  store.commit('globalLoadingFn', false)
  // ElementUI.Message.error('当前应用访问失败')
})

window.__POWERED_BY_QIANKUN__ = true
// 启动
// setDefaultMountApp('/')
function customPrefetch(apps) {
  return {
    criticalAppNames: [],
    minorAppsName: apps
  }
}

const run = async () => {
  registerMicroApps(
    microApps.map((v) => {
      v.props.store = Vue.observable(store)
      v.props.map = Vue.observable(map)
      v.props.deleteConfirmationBox = caller
      return v
    })
  )
  start({
    prefetch: customPrefetch,
    sandbox: true
  })
  new Vue({
    router: router || newVueRouter(),
    i18n,
    store,
    render: (h) => h(App)
  }).$mount('#app')
}
const resetApp = async () => {
  try {
    // 用于动态获取子应用相关配置
    const microConfiguration = (
      await axios.get(`${process.env.VUE_APP_BUILD_DIRECTORY}/entryList.json`)
    ).data
    // const microConfiguration = {
    //   version: '0.2.0',
    //   mcflag: 'xxxxx/mcflag',
    //   routerBase: '/web-base',
    //   axiosBase: {
    //     // admin: '/api/admin',
    //   },
    //   entry: {
    //     operator: 'operator.com'
    //   },
    //   activeRule: {
    //     operator: '1111/operator'
    //   },
    //   mapServer: {
    //     poiServer: {
    //       city: '广东省-深圳市', // 指定城市
    //       citylimit: 0, // 是否启用指定城市（启用:1, 关闭:0）
    //       mapCoordinate: 1, // poi坐标 （WGS84:0, GCJ02:1）
    //       mapPoiType: 'gaode', // poi类型
    //       mapKey: 'abce726a2fd9f546e0b4652822c33638',
    //       serverUrl: 'https://restapi.amap.com'
    //     },
    //     mapPoiType: 'gaode',
    //     mapKey: 'f68ec1e89dd79c485d62b2e56974ff65',
    //     mapSk: '024a144126a2a762fee60e0ca1d0aeeb',
    //     defaultMapStyle: 'amap://styles/b5d280643c6525a8f6b2a07521f639ef', // 地图样式
    //     satelliteMap: 1, // 默认地图 （1平台。2原版，3卫星）
    //     mapCoordinate: 1 // poi坐标 （WGS84:0, GCJ02:1）
    //   }
    // }

    // {
    //   mcflag: 'xxxxx/mcflag'
    // }
    // {
    //   version: '0.2.0',
    //   mcflag: 'xxxxx/mcflag',
    //   routerBase: '/web-base',
    //   axiosBase: {
    //     // admin: '/api/admin',
    //   },
    //   entry: {
    //     operator: 'operator.com'
    //   },
    //   activeRule: {
    //     operator: '1111/operator'
    //   }
    // }
    // const entry = (await axios.get('./entryList.json')).data
    let entry = null
    const activeRule = microConfiguration.activeRule || null
    const axiosBase = microConfiguration.axiosBase || null
    if (microConfiguration.version) {
      entry = microConfiguration.entry
      store.commit('setInitMapServer', microConfiguration.mapServer)
    } else {
      // 兼容旧版本
      entry = microConfiguration
    }
    // axiosBase 文件配置
    BaseUrlConfig.setBaseURLObj(axiosBase)
    // microApps 文件配置
    microApps.forEach((item) => {
      // 配置子应用路由和地址
      if (entry) {
        const hostname = window.location.hostname
        for (const key in entry) {
          if (item.name === key) {
            const value = entry[key] || ''
            // if (value.includes('${SERVER_URL}')) {
            item.entry = value.replace('${SERVER_URL}', hostname)
            // } else {
            //   item.entry = value
            // }
            if (activeRule && Object.hasOwnProperty.call(activeRule, key)) {
              item.activeRule = activeRule[key]
            }
          }
        }
      } else {
        if (activeRule) {
          for (const key in activeRule) {
            if (item.name === key) {
              item.activeRule = activeRule[key]
            }
          }
        }
      }
      // 配置 axios 中 config.baseURL 请求前缀
      if (axiosBase) {
        item.props.httpConfig.baseURLObj = {
          ...item.props.httpConfig.baseURLObj,
          ...axiosBase
        }
      }
    })
    // 用于登录
    if (microConfiguration.mcflag) {
      window.sessionStorage.setItem('mcflag', microConfiguration.mcflag)
    } else {
      sessionStorage.removeItem('mcflag')
    }
    if (microConfiguration.routerBase) {
      router = newVueRouter(microConfiguration.routerBase)
    }
  } catch (e) {
    sessionStorage.removeItem('mcflag')
    console.log('e', e)
  }
}
resetApp().then(async () => {
  await run()
})
