<!-- 指令进度 -->
<template>
  <div id="instructionProgress" class="dialog_clsss">
    <div
      @click="setDragLevel"
      :style="{ 'z-index': dragLevel }"
      class="el-dialog w-[300px]"
      v-el-drag-dialog
      v-if="dialogVisible"
    >
      <!--标题-->
      <div class="pos_r dialog_header el-dialog__header r-f">
        <span class="fs_16 leading-[30px]">
          <svg-icon svg-name="instructionProgress_select" class-name="svgBox" />
          <span class="ml_10">指令进度</span>
        </span>
        <div class="imgPictureChange clickBox" @click="onClose">
          <svg-icon svg-name="delete_hover" class-name="!w-[26px] !h-[26px]" />
          <svg-icon svg-name="close" class-name="!w-[26px] !h-[26px]" />
        </div>
      </div>
      <!--内容-->
      <div class="dialog_body el-dialog__body !p-[0_0_10px_0]">
        <ul
          class="progressBox el-progress-bar__outer_backgroundv3 min-h-[300px]"
        >
          <li
            v-for="(item, index) in directiveList"
            @click="seeFn(item, index)"
            :key="index"
            :class="{ 'success clickBox': item.state === 4 }"
          >
            <el-tooltip
              :manual="item.state !== 3"
              class="item"
              effect="dark"
              :content="item.errorReason || '暂无报错信息'"
              placement="bottom"
            >
              <div>
                <div class="plr_10">
                  <div class="r-f mb-[2px]">
                    <div class="over_one_line flex-grow">
                      {{ item.devName }}
                      <span v-if="item.method === 'takePicture'">(拍照)</span>
                      <span v-else-if="item.method === 'videoRecord'"
                        >(录像)</span
                      >
                      <span v-else-if="item.method === 'startVideoRecord'"
                        >(录像)</span
                      >
                      <span v-else-if="item.method === 'stopVideoRecord'"
                        >(录像)</span
                      >
                      <span v-else-if="item.method === 'startAudioRecord'"
                        >(录音)</span
                      >
                      <span v-else-if="item.method === 'stopAudioRecord'"
                        >(录音)</span
                      >
                    </div>
                    <div
                      class="flex-shrink ml_10 unread_box"
                      v-if="!item.read && item.state === 4 && !item.notViewable"
                    >
                      未 读
                    </div>
                  </div>
                  <div class="r-f justify-between">
                    <span class="c_9DA7B2">{{ item.creationTime }}</span>
                    <span class="c_theme" v-if="item.state !== 3"
                      >{{ progressFn(item.state) }}%</span
                    >
                  </div>
                  <el-progress
                    :show-text="false"
                    v-if="item.state === 3 || item.state === -1"
                    class="progress-bar_outer_errors"
                    color="var(--dangerv2)"
                    :text-inside="true"
                    :stroke-width="9"
                    :percentage="100"
                  ></el-progress>
                  <el-progress
                    v-else
                    :show-text="false"
                    :class="{ 'progress-bar_outer': item.state !== 4 }"
                    color="var(--success)"
                    :text-inside="true"
                    :stroke-width="9"
                    :percentage="progressFn(item.state)"
                  ></el-progress>
                </div>
                <div
                  v-if="separatePageName === 'videoMeeting'"
                  class="operation_box"
                >
                  <div></div>
                  <div>
                    <iconBox
                      @click="itemChatSend(item)"
                      :width="24"
                      :height="24"
                      margin="4px"
                      imgName="icon_fileUpload"
                      altText="发 送"
                    ></iconBox>
                    <iconBox
                      @click="seeFn(item, index, false)"
                      :width="24"
                      :height="24"
                      margin="4px"
                      imgName="see"
                      altText="查 看"
                    ></iconBox>
                  </div>
                </div>
              </div>
            </el-tooltip>
          </li>
          <li
            class="c_9DA7B2 centerBox fs_12 h-[300px]"
            v-if="directiveList.length === 0"
          >
            <div class="tac">
              <svg-icon
                svg-name="noData"
                className="svgBox !w-[50px] !h-[50px]"
              />
              <div class="mt_10">暂 无 数 据</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import elDragDialog from '@components/el-drag-dialog'
export default {
  directives: { elDragDialog },
  data() {
    return {
      count: null,
      timer: null,
      dragLevel: 0,
      caseId: null,
      dialogVisible: false
    }
  },
  computed: {
    ...mapGetters(['directiveList', 'separatePageName'])
  },
  mounted() {},
  methods: {
    /* 重置定时器 */
    resetTimerFn(count) {
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
        window.sessionStorage.setItem('count', count)
      }
    },
    /* 倒计时 */
    countDownFn() {
      const TIME_COUNT = 60
      if (!this.timer) {
        this.count = window.sessionStorage.getItem('count') || TIME_COUNT
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--
          } else {
            this.resetTimerFn(TIME_COUNT)
            /*
             * 状态：0准备中,1录像中,2录像成功,3录像失败,4已上传
             * */
            const getTime = new Date().getTime()
            const dataList = this.directiveList
              ? this.directiveList.filter((v) => {
                  /* 过滤掉 3录像失败,4已上传 */
                  if (v.state === 3) {
                    return false
                  } else {
                    if (v.state !== 4) {
                      /* 判断超过五分钟的数据在进行判断 */
                      if (Math.abs(getTime - v.timestamp) >= 5 * 60 * 1000) {
                        return true
                      }
                    }
                  }
                })
              : []
            dataList.forEach((v) => {
              const item = { ...v }
              /* 查看文件 */
              this.$api.mediaFile.findByProgressKey(item.key).then((res) => {
                if (res.code === 200 && res.data) {
                  this.$set(item, 'state', 4)
                } else {
                  this.$set(item, 'state', 3)
                  this.$set(item, 'errorReason', '录像失败')
                }
                this.$store.commit('addDirectiveListFn', { ...item })
              })
            })
            /* 获取遍历指令 */
            this.$nextTick(() => {
              this.countDownFn()
            })
          }
        }, 1000)
      }
    },
    itemChatSend(item) {
      this.$deleteConfirmationBox(
        {
          deleteTitleImg: 'tips',
          deleteTitle: '提示',
          closeFn: (nextStepFn) => {
            if (nextStepFn) {
              nextStepFn()
            }
          },
          deleteContent:
            '<div><p>是否确认发送？</p><p>发送后，无法撤回</p></div>',
          closeText: '取 消',
          submitClass: 'button_function',
          submitText: '发 送'
        },
        () => {
          this.$api.mediaFile.findByProgressKey(item.key).then((res) => {
            if (res.code === 200) {
              const row = res.data
              let format = null
              if (item.method === 'takePicture') {
                format = 1
              } else if (
                item.method === 'videoRecord' ||
                item.method === 'startVideoRecord' ||
                item.method === 'stopVideoRecord'
              ) {
                format = 3
              } else if (
                item.method === 'startAudioRecord' ||
                item.method === 'stopAudioRecord'
              ) {
                format = 2
              }
              this.chatSend(format, {
                fileKey: row.fileCloudKey
              })
            }
          })
        }
      )
    },
    /* 发送群聊消息 */
    chatSend(format = 0, obj = {}) {
      // format: {
      //   0: '0文字',
      //   1: '1图片',
      //   2: '2音频',
      //   3: '3视频',
      // }
      // obj: {
      //   content: '文案内容',
      //   fileKey: '直传的key',
      // }
      return new Promise((resolve, reject) => {
        const roomId =
          obj.roomId ||
          window.sessionStorage.getItem('videoMeetingRoomId') ||
          null
        if (!roomId) {
          return reject(new Error('请上传房间id'))
        }
        const params = {
          format,
          roomId: roomId
        }
        if (obj.content) {
          params.content = obj.content
        }
        if (obj.fileKey) {
          params.fileKey = obj.fileKey
        }
        this.$api.chat.chatSend(params).then((res) => {
          if (res.code === 200) {
            resolve()
          }
        })
      })
    },
    /* 查看 */
    seeFn(item, index, type = true) {
      if (item.notViewable) return
      if (this.separatePageName === 'videoMeeting' && type) return
      if (item.state === 4) {
        this.$store.commit('updateDirectiveList', {
          ...item,
          read: true
        })
        /* 查看文件 */
        this.$api.mediaFile.findByProgressKey(item.key).then((res) => {
          if (res.code === 200) {
            const row = res.data
            let fileType = null
            // [文件类型]{1:图片;2:视频;3:对讲记录;4:音频;}
            if (row.fileType === 4) {
              fileType = 2
            } else if (row.fileType === 2) {
              fileType = 3
            } else if (row.fileType === 1) {
              fileType = 4
            }
            this.$store.commit('callComponent', {
              name: 'filePlayback',
              data: {
                methodName: 'show',
                params: {
                  playList: [row],
                  curItem: row,
                  curMediaType: fileType
                }
              }
            })
          }
        })
      }
    },
    /* 进度条 */
    progressFn(state) {
      /*
       * 状态：0准备中,1录像中,2录像成功,3录像失败,4已上传
       * -1 报错（这个-1是前端自己弄的）
       * */
      if (state === 3 || state === 4 || state === -1) {
        return 100
      } else {
        return parseInt((100 / 3) * state)
      }
    },
    onClose() {
      this.resetTimerFn(this.count)
      this.dialogVisible = false
    },
    show() {
      this.$store.commit('onunloadOperation', {
        name: 'instructionProgress',
        type: 1,
        callback: () => {
          this.resetTimerFn(this.count)
        }
      })
      this.countDownFn()
      this.setDialogVisible(true)
    },
    // 设置弹窗展示
    setDialogVisible(type) {
      // if (this.dialogVisible === type) return
      if (type) {
        this.setDragLevel()
      }
      this.dialogVisible = type
    },
    // 设置弹窗层级
    setDragLevel() {
      if (this.mainStore) {
        this.dragLevel = this.mainStore.getters.dragLevel
        this.mainStore.commit('setDragLevel')
      } else {
        this.dragLevel = this.$store.getters.dragLevel
        this.$store.commit('setDragLevel')
      }
    }
  },
  destroyed() {
    this.$store.commit('onunloadOperation', {
      name: 'instructionProgress',
      type: 2
    })
  }
}
</script>
<style scoped lang="less" rel="stylesheet/less">
#instructionProgress {
  .el-dialog {
    position: fixed !important;
    top: 110px;
    left: calc(100% - 400px);
  }
  .dialog_header {
    justify-content: space-between;
    border-bottom: 1px solid var(--border);
    padding: 16px 20px 10px;
  }
}
ul.progressBox {
  color: var(--main);
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 20px;
  padding: 4px 4px 10px 4px;
  max-height: 400px;
  overflow: auto;
  li {
    &.success:hover {
      background: var(--dialog-backgroundv2);
      .operation_box {
        background: var(--backgroundv3);
      }
    }
    overflow: hidden;
    border-radius: var(--dialog-border-radius);
    border: 1px solid var(--border);
    margin-top: 8px;
    padding: 10px 0;
    transition: 0.4s;
    &.success {
      .operation_box {
        height: 36px;
      }
    }
    .unread_box {
      font-size: 12px;
      height: 20px;
      line-height: 20px;
      color: var(--theme);
      border-radius: 2px;
      background: var(--backgroundv2);
      padding: 0 4px;
      border: 1px solid var(--theme);
      box-sizing: border-box;
    }
    .operation_box {
      margin-top: 10px;
      padding-left: 10px;
      padding-right: 6px;
      height: 0px;
      display: flex;
      align-items: center;
      background: var(--backgroundv2);
      justify-content: space-between;
      transition: 0.5s;
      overflow: hidden;
    }
  }
}
</style>
