/* 车辆检测 */
import { mapGetters } from 'vuex'
import store from '../../../../store'

const vehicleDetection = () =>
  import('@components/vehicleDetection/vehicleDetection')
export default {
  data() {
    return {
      vehicleRecognitionId: null,
      vehicleRecognitionWarningId: null
    }
  },
  computed: {
    ...mapGetters(['newVehicleAlertReminder'])
  },
  created() {
    this.componentMethodList.push('vehicleDetection')
  },
  components: {
    vehicleDetection
  },
  destroyed() {
    this.$store.commit(
      'deleteUserOperationCallback',
      this.vehicleRecognitionWarningId
    )
  },
  mounted() {
    this.vehicleRecognitionWarningId = this.createUniqueId(1)
    this.vehicleRecognitionWarning()
  },
  methods: {
    /* 监听车脸报警 */
    vehicleRecognitionWarning() {
      this.$store.commit('addUserOperationCallback', {
        id: this.vehicleRecognitionWarningId,
        operationName: 'motorRecognitionWarning',
        callback: (res) => {
          const { motorRecognitionId } = res || {}
          console.log('motorRecognitionWarning', res)
          const vehicleRecognitionId = motorRecognitionId
          this.$store.commit('vehicleUnreadContentListOperate', {
            type: 1,
            vehicleRecognitionId
          })
          // 需要判断报警是否打开，打开需要刷新
          // this.$refs.vehicleDetection.vehicleAlertListGetData()
          if (this.newVehicleAlertReminder === 1) {
            this.vehicleRecognitionId = vehicleRecognitionId
            this.vehicleAlertFn()
          }
        }
      })
    },
    /* 车辆报警 */
    vehicleAlertFn() {
      const close = () => {
        this.$refs.newMessageReminder.onClose('vehicle')
      }
      const submit = () => {
        this.$refs.vehicleDetection.vehicleAlertListShow()
        this.$refs.vehicleDetection.vehicleAlertDetailsShow(
          this.vehicleRecognitionId
        )
        close()
      }
      this.$refs.newMessageReminder
        .show(
          {
            type: 'vehicle',
            imgUrl: 'newCase.png',
            title: '车牌报警',
            contentSvgName: 'vehicleAler-ring',
            content: '发现新的可疑车牌',
            headerHeight: this.headerHeight
          },
          {
            vehicleRecognitionId: this.vehicleRecognitionId
          }
        )
        .then(submit)
        .catch(close)
    }
  }
}
