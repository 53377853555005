<!-- 的页面 -->
<template>
  <div class="headerBox">
    <!--    <div @click="onOfflineDeviceShow" style="color: #f00">-->
    <!--      手动设备上下线-->
    <!--    </div>-->
    <onOfflineDevice ref="onOfflineDevice"></onOfflineDevice>
    <div class="zoomBox" v-if="hideLayerList.length">
      <div
        @mouseenter="openTypeFn(false)"
        class="openBox clickBox"
        v-if="openType"
      >
        <i class="el-icon-caret-top" style="color: var(--main)"></i>
      </div>
      <ul
        v-else
        @mouseleave="openTypeFn(true)"
        :style="{ top: navigationTop ? '44px' : '0px' }"
        class="navigation"
      >
        <li
          v-for="(item, index) in hideLayerList"
          @click="hideLayerFn(item.componentsName, index)"
          :key="index"
        >
          <div class="item clickBox centerBox">
            <svg-icon
              v-if="item.componentsName === 'singleTalkback'"
              svg-name="abbreviation_member"
              className="svgBox"
            />
            <svg-icon
              v-if="item.componentsName === 'multiPersonIntercom'"
              svg-name="abbreviation_group"
              className="svgBox"
            />
            <svg-icon
              v-if="item.componentsName === 'multipleChecks'"
              svg-name="abbreviation_videoSurveillance"
              className="svgBox"
            />
            <svg-icon
              v-if="item.componentsName === 'departmentMultipleChecks'"
              svg-name="abbreviation_videoSurveillance"
              className="svgBox"
            />
            <svg-icon
              v-if="item.componentsName === 'singleInspection'"
              svg-name="abbreviation_video"
              className="svgBox"
            />
            <!-- 录像 -->
            <svg-icon
              v-if="item.componentsName === 'recording'"
              svg-name="abbreviation_recording"
              className="svgBox"
            />
            <!-- 录音 -->
            <svg-icon
              v-if="item.componentsName === 'soundRecording'"
              svg-name="abbreviation_soundRecording"
              className="svgBox"
            />
            <svg-icon
              v-if="item.componentsName === 'broadcast'"
              svg-name="abbreviation_broadcast"
              className="svgBox"
            />
          </div>
          <span class="c_fff fs_12 title">{{ item.title }}</span>
        </li>
      </ul>
    </div>
    <div
      id="intercomAudioBox"
      style="
        opacity: 0;
        position: fixed;
        top: -10px;
        left: -10px;
        overflow: hidden;
        width: 1px;
        height: 1px;
      "
    ></div>
    <personnelCheckAdd ref="personnelCheckAdd"></personnelCheckAdd>
    <!-- 多人监控 -->
    <multipleChecks
      @on-componentShowLayer="onComponentShowLayer"
      @on-hideLayerListFn="hideLayerListFn"
      @on-issueMessage="issueMessageFn"
      @on-broadcast_addNewRoomFn="broadcast_addNewRoomFn"
      @on-broadcast_closeTheRoom="broadcast_closeTheRoom"
      @on-broadcast_closeAllRoomsFn="broadcast_closeAllRoomsFn"
      contentType="multipleChecks"
      ref="multipleChecks"
    >
    </multipleChecks>
    <!-- 多人监控 (部门检查)-->
    <multipleChecks
      @on-hideLayerListFn="hideLayerListFn"
      contentType="departmentMultipleChecks"
      @on-close="personnelCheckAddShow"
      ref="departmentMultipleChecks"
    ></multipleChecks>
    <!--单呼-->
    <single-talkback
      @on-hideLayerListFn="hideLayerListFn"
      @on-close="personnelCheckAddShow"
      ref="singleTalkback"
    ></single-talkback>
    <!-- 多人对讲 -->
    <multiPersonIntercom
      @on-hideLayerListFn="hideLayerListFn"
      @on-close="multiPersonIntercomClose"
      ref="multiPersonIntercom"
    ></multiPersonIntercom>
    <!-- 单人视频 -->
    <singleInspection
      @on-hideLayerListFn="hideLayerListFn"
      @on-close="personnelCheckAddShow"
      ref="singleInspection"
    ></singleInspection>
    <!-- 录像 -->
    <recording
      @on-hideLayerListFn="hideLayerListFn"
      ref="recording"
    ></recording>
    <!-- 录音 -->
    <soundRecording
      @on-hideLayerListFn="hideLayerListFn"
      ref="soundRecording"
    ></soundRecording>
    <!-- 广播 -->
    <broadcast
      @on-hideLayerListFn="hideLayerListFn"
      @on-broadcasRoomDeviceChange="broadcasRoomDeviceChange"
      ref="broadcast"
    ></broadcast>
    <!-- 文件播放 -->
    <filePlayback ref="filePlayback"></filePlayback>
    <!-- 分配任务 -->
    <assignTasks ref="assignTasks"></assignTasks>
    <!-- 任务详情  -->
    <independentMode ref="independentMode"></independentMode>
    <headerNavigation
      :headerState="separatePageName !== 'hideNavigation'"
      :menuList="menuList"
      @on-methodLogout="methodLogout"
      @on-routerPush="routerPush"
      ref="headerNavigation"
    ></headerNavigation>
    <!-- 文件上传  -->
    <fileUpload ref="fileUpload"></fileUpload>
    <!-- 新消息提醒 -->
    <newMessageReminder ref="newMessageReminder"></newMessageReminder>
    <!-- 下发消息 -->
    <issueMessage ref="issueMessage"></issueMessage>
    <!--  人脸报警  -->
    <faceDetection ref="faceDetection"></faceDetection>
    <!--  车牌报警  -->
    <vehicleDetection ref="vehicleDetection"></vehicleDetection>
    <!-- 报警监控 -->
    <alarmRecordMonitor
      @on-alarmRecordOperatorInfo="alarmRecordOperatorInfo"
      ref="alarmRecordMonitor"
    ></alarmRecordMonitor>
    <!-- 警单长时间未处理通知 -->
    <alarmRecordLongTimeNotDeal
      ref="alarmRecordLongTimeNotDeal"
      @on-viewAlarmRecord="newAlarmSubmit"
    ></alarmRecordLongTimeNotDeal>
    <!-- 新增警单 -->
    <newAlarm @on-submit="newAlarmSubmit" ref="newAlarm"></newAlarm>
    <!-- 抽查编辑 -->
    <editSpotCheck ref="editSpotCheck"></editSpotCheck>
    <spotCheckDetails ref="spotCheckDetails"></spotCheckDetails>
  </div>
</template>

<script type="text/ecmascript-6">
import headerBox from './js/index.js'
import faceDetection from './js/faceDetection.js'
import vehicleDetection from './js/vehicleDetection.js'
const onOfflineDevice = () => import('@components/layout/onOfflineDevice')

export default {
  mixins: [headerBox, faceDetection, vehicleDetection],
  data () {
    return {}
  },
  components: {
    onOfflineDevice
  },
  methods: {
    onOfflineDeviceShow () {
      this.$refs.onOfflineDevice.show()
    }
  }
}
</script>

<style src="./css/index.less" scoped lang="less" rel="stylesheet/less"></style>
