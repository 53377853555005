<!-- icon图标展示 -->
<template>
  <div class="inline-flex" @click.stop="clickFn">
    <el-tooltip
      v-delTabIndex
      :open-delay="500"
      v-if="altText"
      class="item"
      effect="dark"
      :content="altText"
      placement="top-start"
    >
      <div
        :style="{
          width: classNmae === 'iconBoxV2' ? '28px' : `${parseRem(width)}rem`,
          height: classNmae === 'iconBoxV2' ? '28px' : `${parseRem(height)}rem`,
          margin: margin
        }"
        class="iconBox css-text-no-select"
        :class="classNmae"
      >
        <svg-icon :svg-name="imgName" className="svgBox" />
      </div>
    </el-tooltip>
    <div
      :style="{
        width: classNmae === 'iconBoxV2' ? '28px' : `${parseRem(width)}rem`,
        height: classNmae === 'iconBoxV2' ? '28px' : `${parseRem(height)}rem`
      }"
      class="iconBox css-text-no-select"
      v-else
      :class="classNmae"
    >
      <svg-icon :svg-name="imgName" className="svgBox" />
    </div>
  </div>
</template>

<script>
import { pxTorem } from '@utils/adaptive'
export default {
  props: {
    classNmae: {
      type: String,
      default: () => {
        return ''
      }
    },
    altText: {
      type: String,
      default: () => {
        return ''
      }
    },
    suffix: {
      type: String,
      default: () => {
        return 'png'
      }
    },
    margin: {
      type: String,
      default: () => {
        return '6px'
      }
    },
    width: {
      type: Number,
      default: () => {
        return 32
      }
    },
    height: {
      type: Number,
      default: () => {
        return 32
      }
    },
    imgName: {
      type: String,
      default: () => {
        return 'password'
      }
    }
  },
  data() {
    return {}
  },
  // components: {},
  // computed: {},
  mounted() {},
  methods: {
    pxTorem,
    parseRem(res) {
      let px = res
      if (px) {
        px = parseInt(px, 10)
        if (isNaN(px)) {
          return ''
        }
        return this.pxTorem(px)
      }
      return ''
    },
    clickFn() {
      this.$emit('click')
    }
  }
}
</script>
<style scoped lang="less" rel="stylesheet/less">
.iconBox:active {
  opacity: 0.8;
}
.iconBox {
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: var(--background);
  box-shadow: 0 0 8px 0 var(--background);
  border: 1px solid var(--border);
  .svgBox {
    width: auto;
    height: 58%;
    max-width: 20px;
    max-height: 20px;
  }
}
.iconBox:hover {
  box-shadow: 0 0 10px 0 var(--theme);
}
.iconBoxV2 {
  background: var(--background);
  box-shadow: 0 0 8px 0 var(--background);
  border: 1px solid var(--border);
  transition: 0.3s;
  margin: 0;
  .svgBox {
    width: 58%;
    height: 58%;
    max-width: 20px;
    max-height: 20px;
  }
}
.iconBoxV2:hover {
  border: 1px solid var(border);
  box-shadow: 0 0 10px 0 var(--theme);
}
.shadow_delete:hover {
  box-shadow: 0 0 10px 0 var(--danger);
}
</style>
