// vw 转 px
const rootValue = 16
const unitPrecision = 5
export function vwToPx(vw) {
  return (vw * window.innerWidth) / 100
}

// rem 转 px
export function remToPx(rem) {
  // 获取根字体大小（通常为16px）
  const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);

  // 将rem单位转换为px单位
  return rem * rootFontSize;
}

export function pxTorem(px) {
  const number = px / rootValue
  const multiplier = Math.pow(10, unitPrecision + 1),
    wholeNumber = Math.floor(number * multiplier);
  return (Math.round(wholeNumber / 10) * 10) / multiplier;
}
