import HyRtcSdk from 'hy-rtc-sdk'
import store from '../store/index.js'
import { Message } from 'element-ui'
import componentsApi from '../api/index.js'
function install(Vue) {
  let sdk = null
  let form = {}
  let userInfo = null
  let clientId = null
  let timeout = 0
  let activeDisconnection = false
  let roomInformation = {
    deviceId: null,
    roomId: null
  }
  let videoRoomInformation = {
    deviceId: null,
    roomId: null
  }
  let mqObj = {
    sdkType: false, // sdk 是否连接成功
    businessType: false // business 是否连接成功
  }

  const hyRtcSdk = {
    /* 关闭所有房间 */
    onunloadOperation() {
      store.commit('componentsSdk/onunloadOperation')
    },
    /* 获取用户信息，连接MQ */
    loginByPhoneOnlyFn(obj, $sdk, $business) {
      return new Promise((resolve, reject) => {
        if (obj.requestHeader && $sdk && $business) {
          if ($sdk.connectionStatus() && $business.connectionStatus()) {
            resolve({
              code: 200,
              data: userInfo,
              msg: 'ok',
              status: 200,
              success: true
            })
          } else {
            mqObj = {
              sdkType: false,
              businessType: false
            }
            store.commit('componentsSdk/requestHeader', obj.requestHeader)
            componentsApi.modules.login
              .loginByPhoneOnly({
                account: obj.account
              })
              .then((res) => {
                if (res.code === 200) {
                  userInfo = res.data
                  store.commit('componentsSdk/sdksetUserInfo', userInfo)
                  $sdk.initSocket(
                    {
                      ...userInfo.mediumMqConnectInfo
                    },
                    () => {
                      mqObj.sdkType = true
                      if (mqObj.sdkType && mqObj.businessType) {
                        resolve(res)
                      }
                      store.commit('componentsSdk/mainThisFn', $sdk)
                      // $sdk.callCallbackObject('audioProducerBroken', (res) => {
                      //   Message.error('话语权被抢断')
                      //   store.commit('componentsSdk/audioContextTypeFn', res)
                      // })
                    }
                  )
                  $business.initSocket(
                    {
                      ...userInfo.businessMqConnectInfo,
                      userId: userInfo.id
                    },
                    () => {
                      mqObj.businessType = true
                      if (mqObj.sdkType && mqObj.businessType) {
                        resolve(res)
                      }
                      store.commit(
                        'componentsBusiness/businessSdkFn',
                        $business
                      )
                      $business.callCallbackObject(
                        'equipmentStatus',
                        (device) => {
                          store.commit(
                            'componentsBusiness/businessCallCallbackObject',
                            {
                              name: 'equipmentStatus',
                              res: device
                            }
                          )
                        }
                      )
                    }
                  )
                }
              })
          }
        } else {
          reject({
            msg: '未传入必传参数'
          })
        }
      })
    },
    /**
     * 链接sdk
     */
    initSocket(res, callback) {
      let useSSL = true
      if (res.useSSL === true || res.useSSL === false) {
        useSSL = res.useSSL
      }
      activeDisconnection = false
      const newSdk = new HyRtcSdk({
        ...res,
        useSSL: useSSL,
        port: res.port - 0,
        sdkParams: res.sdkParams || {}
      })
      form = res
      clientId = res.clientId
      newSdk.eventEmit('open', () => {
        sdk = newSdk
        if (callback) {
          callback()
        }
      })
      newSdk.eventEmit('close', (e) => {
        console.warn('sdk_连接失败回调事件', e)
        this.disconnectedFn()
      })
      newSdk.eventEmit('consumerResumed', (res) => {
        store.commit('userOperationCallback', {
          operationName: 'consumerResumed',
          data: res
        })
      })
      newSdk.eventEmit('consumerPaused', (res) => {
        store.commit('userOperationCallback', {
          operationName: 'consumerPaused',
          data: res
        })
      })

      newSdk.eventEmit('disconnected', (e) => {
        console.warn('sdk_disconnected', e)
        this.disconnectedFn()
      })
    },

    // 主动断开
    disconnectCompletely() {
      activeDisconnection = true
      sdk.disconnectCompletely()
      sdk = null
    },

    /* 连接状态 */
    connectionStatus() {
      return sdk !== null
    },

    // 获取重连次数
    getTimeout () {
      return timeout
    },

    // sdk 调用回调对象
    callCallbackObject(name, callback) {
      sdk.eventEmit(name, (res) => {
        callback(res)
      })
    },

    disconnectedFn() {
      if (activeDisconnection) return
      timeout++
      sdk.disconnectCompletely()
      if (timeout < 3) {
        this.initSocket(form)
      } else {
        sdk = null
        timeout = 0
        Message.error('连接失败')
        /* 退出登录 */
        store.getters.methodLogoutFn()
      }
    },

    clientIdFn() {
      return clientId
    },
    obtainAllClientIdList(obj) {
      return sdk.obtainAllClientIdList(obj)
    },
    consumerPaused() {
      return sdk.consumerPaused()
    },
    consumerResume() {
      return sdk.consumerResume()
    },
    // 发送房间消息
    sendRoomMessage(method, roomId, clientId) {
      return sdk.sendRoomMessage(method, roomId, clientId)
    },
    // 房间信息
    roomInformationFn() {
      return roomInformation
    },
    // 视频房间信息
    videoRoomInformationFn() {
      return videoRoomInformation
    },
    // 加入失败
    joinFailedFn(type) {
      if (type === 'video') {
        videoRoomInformation = {
          roomId: null,
          deviceId: null,
          roomTips: null
        }
      } else {
        roomInformation = {
          roomId: null,
          deviceId: null,
          roomTips: null
        }
      }
    },
    // 退出房间
    exitRoomFn(roomId) {
      roomInformation = {
        roomId: null,
        deviceId: null,
        roomTips: null
      }
      if (roomId) {
        sdk.exitRoom(roomId)
        componentsApi.modules.media.exitRoom(roomId)
      }
    },
    /* 获取本地音频列表 */
    audioInputListFn() {
      if (sdk) {
        return sdk.audioInputListFn()
      }
    },
    /* 获取本地视频列表 */
    videoInputListFn() {
      if (sdk) {
        return sdk.videoInputListFn()
      }
    },

    getRoom (roomId) {
      if (sdk) {
        return sdk.getRoom(roomId)
      }
    },

    setVideoInput(videoStream) {
      sdk.setVideoInput(videoStream)
    },

    setAudioInput(audioStream) {
      sdk.setAudioInput(audioStream)
    },

    // 获取当前本地视频流
    getLocalVideoStream (obj = {}) {
      return sdk.getLocalVideoStream(obj)
    },
    // 获取当前本地音频流
    getLocalAudioStream (obj = {}) {
      return sdk.getLocalAudioStream(obj)
    },

    // 在生产时更换视频输入源
    replaceVideoDevice(videoInput, obj) {
      // const obj = {
      //   facingMode 前摄像 user 还是 后摄像 environment
      //   roomId：有房间id，就是针对房间id设置，没有就是全局
      //   successCallback 成功回调
      //   failCallback 失败回调
      // }
      sdk.replaceVideoDevice(videoInput, obj)
    },

    // 在生产时更换视频输入轨道
    replaceVideoTrack(track, obj) {
      // const obj = {
      //   roomId：有房间id，就是针对房间id设置，没有就是全局
      //   successCallback 成功回调
      //   failCallback 失败回调
      // }
      sdk.replaceVideoTrack(track, obj)
    },

    // 在生产时更换音频输入源
    replaceAudioDevice(audioInput, obj) {
      // const obj = {
      //   roomId：有房间id，就是针对房间id设置，没有就是全局
      //   successCallback 成功回调
      //   failCallback 失败回调
      // }
      sdk.replaceAudioDevice(audioInput, obj)
    },

    // 在生产时更换音频输入轨道
    replaceAudioTrack(track, obj) {
      // const obj = {
      //   roomId：有房间id，就是针对房间id设置，没有就是全局
      //   successCallback 成功回调
      //   failCallback 失败回调
      // }
      sdk.replaceAudioTrack(track, obj)
    },

    // 云台控制
    notificationDeviceControl(json) {
      sdk.notificationDeviceControl(json)
    },
    /**
     * 获取屏幕流
     */
    getDisplayMedia(obj) {
      // const obj = {
      //   param：{ video: true, audio: true }
      //   successCallback 成功回调
      //   failCallback 失败回调
      // }
      return new Promise((resolve, reject) => {
        sdk
          .getDisplayMedia(obj.param)
          .then((stream) => {
            resolve(stream)
            if (obj.successCallback) {
              obj.successCallback(stream)
            }
          })
          .catch((e) => {
            reject(e)
            if (obj.failCallback) {
              obj.failCallback(e)
            }
          })
      })
    },
    /**
     * 传入一个流，拆分成音频流和视频流
     */
    handlerDisplayMediaStream(obj) {
      return new Promise((resolve, reject) => {
        sdk
          .handlerDisplayMediaStream(obj.stream)
          .then((res) => {
            resolve(res)
            if (obj.successCallback) {
              obj.successCallback(res)
            }
          })
          .catch((e) => {
            reject(e)
            if (obj.failCallback) {
              obj.failCallback(e)
            }
          })
      })
    },
    /**
     * 在生产时更换视频输入源
     */
    replaceVideoStream(videoStream, roomId) {
      sdk.replaceVideoStream(videoStream, roomId)
    },
    replaceAudioStream(audioStream, roomId) {
      sdk.replaceAudioStream(audioStream, roomId)
    },

    disableMic(roomId) {
      sdk.disableMic(roomId)
    },
    disableWebcam(roomId) {
      sdk.disableWebcam(roomId)
    },
    /**
     * 获取话语权
     */
    audioConsumerResume(obj) {
      sdk.audioConsumerResume(obj, obj.successCallback, obj.failCallback)
    },
    videoConsumerResume(obj) {
      console.log('obj', obj)
      sdk.videoConsumerResume(obj, obj.successCallback, obj.failCallback)
    },
    /**
     * 释放话语权
     */
    audioConsumerPaused(obj) {
      sdk.audioConsumerPaused(obj.roomId, obj.successCallback, obj.failCallback)
    },
    videoConsumerPaused(obj) {
      sdk.videoConsumerPaused(obj.roomId, obj.successCallback, obj.failCallback)
    },

    /* 发起广播 */
    broadcast(obj, failCallback, successCallback) {
      if (!sdk) {
        return
      }
      store.getters.commandLogSave({
        command: 'broadcast',
        deviceIdList: obj.clientIds.split(',')
      })
      roomInformation.roomId = obj.roomId
      roomInformation.roomTips = obj.roomTips || '请退出当前广播'
      sdk.broadcast({ ...obj }, failCallback, successCallback)
    },
    /* 发起国标广播 （弃用）*/
    gbBroadcast(obj, failCallback, successCallback) {
      if (!sdk) {
        return
      }
      roomInformation.roomId = obj.roomId
      roomInformation.roomTips = obj.roomTips || '请退出当前广播'
      sdk.gbBroadcast({ ...obj }, failCallback, successCallback)
    },
    /* 发起对讲 */
    intercom(obj, failCallback, successCallback) {
      if (!sdk) {
        return
      }
      roomInformation.roomId = obj.roomId
      roomInformation.roomTips = obj.roomTips || '请退出当前对讲'
      sdk.intercom({ ...obj }, failCallback, successCallback)
    },
    /* 语音通话 (可以进行其他操作) */
    intercomcall(obj, failCallback, successCallback) {
      if (!sdk) {
        return
      }
      store.getters.commandLogSave({
        command: 'intercomCall',
        deviceIdList: [ obj.clientId ]
      })
      roomInformation.roomId = obj.roomId
      roomInformation.roomTips = obj.roomTips || '请退出当前对讲'
      sdk.intercomcall({ ...obj }, failCallback, successCallback)
    },
    /* 发起监控 */
    monitor(obj, failCallback, successCallback) {
      if (!sdk) {
        return
      }
      store.getters.commandLogSave({
        command: 'monitor',
        deviceIdList: [ obj.clientId ]
      })
      videoRoomInformation.roomId = obj.roomId
      videoRoomInformation.roomTips = obj.roomTips || '请退出当前监控'
      sdk.monitor({ ...obj }, failCallback, successCallback)
    },
    /* 发起双人视频通话 */
    videocall(obj, failCallback, successCallback) {
      if (!sdk) {
        return
      }
      store.getters.commandLogSave({
        command: 'videoCall',
        deviceIdList: [ obj.clientId ]
      })
      videoRoomInformation.roomId = obj.roomId
      videoRoomInformation.roomTips = obj.roomTips || '请退出当前监控'
      sdk.videocall({ ...obj }, failCallback, successCallback)
    },
    /* 发起多人视频会议 */
    videoMeetingIn(obj, failCallback, successCallback) {
      if (!sdk) {
        return
      }
      videoRoomInformation.roomId = obj.roomId
      videoRoomInformation.roomTips = obj.roomTips || '请退出当前监控'
      sdk.videoMeetingIn({ ...obj }, failCallback, successCallback)
    },
    /* 视频录制 */
    recordVideo(obj, failCallback, successCallback) {
      if (!sdk) {
        return
      }
      sdk.recordVideo({ ...obj }, failCallback, successCallback)
    },
    /* 加入频道 */
    channelInFn(obj, failCallback, successCallback) {
      if (!sdk) {
        return
      }
      roomInformation.roomId = obj.roomId
      roomInformation.roomTips = obj.roomTips || '请退出当前群组'
      sdk.channelInFn({ ...obj }, failCallback, successCallback)
    },

    // 获取媒体房间内，接收情况状态。
    getRoomRecvStatus(roomId, successCallback, failCallback) {
      sdk.getRoomRecvStatus(roomId, successCallback, failCallback)
    }
  }
  Vue.prototype.$sdk = Object.assign({}, hyRtcSdk)
}
export default install
