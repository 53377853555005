/* 子应用通过 当前 vuex存储文件去调用父级方法 */
const state = {
  sdk: {},
  mqObj: {
    businessType: false,
    sdkType: false
  },
  // 回调对象存储
  callbackObjectStore: {},
  // 广播设备列表
  broadcastDeviceList: [],
  // 直播设备列表
  multipleChecksDataList: [],
  // 调试设备监控列表
  debugMultipleChecksList: []
  // debugMultipleChecksList: [
  //   {
  //     roomId: 'www',
  //     deviceId: 'XXX',
  //     videoWidth: 'XXX',
  //     videoHeight: 'XXX',
  //     roomRecvStatusList: []
  //   }
  // ]
}

/* =================================== 获取消息（getters） =================================== */
const getters = {
  mqObj(state) {
    return state.mqObj
  },
  /* 连接状态 */
  connectionStatus(state) {
    return state.sdk.connectionStatus
  },
  roomInformationFn(state) {
    return state.sdk.roomInformationFn
  },
  clientIdFn(state) {
    return state.sdk.clientIdFn
  },
  obtainAllClientIdList(state) {
    return state.sdk.obtainAllClientIdList
  },
  consumerPaused(state) {
    return state.sdk.consumerPaused
  },
  consumerResume(state) {
    return state.sdk.consumerResume
  },
  videoInputListFn(state) {
    return state.sdk.videoInputListFn
  },

  getRoom(state) {
    return state.sdk.getRoom
  },
  // 获取麦克风列表
  audioInputListFn(state) {
    return state.sdk.audioInputListFn
  },
  // 获取当前本地视频流
  getLocalVideoStream(state) {
    return state.sdk.getLocalVideoStream
  },
  // 获取当前本地音频流
  getLocalAudioStream(state) {
    return state.sdk.getLocalAudioStream
  },
  // 视频房间信息
  videoRoomInformationFn(state) {
    return state.sdk.videoRoomInformationFn
  },
  // 发送房间消息
  sendRoomMessage(state) {
    return state.sdk.sendRoomMessage
  },
  broadcastDeviceList(state) {
    return state.broadcastDeviceList
  },
  // 直播设备列表
  multipleChecksDataList(state) {
    return JSON.parse(JSON.stringify(state.multipleChecksDataList))
  },
  // 调试设备监控列表
  debugMultipleChecksList(state) {
    return state.debugMultipleChecksList
  }
}
const mutations = {
  mqObjFn(state, obj) {
    state.mqObj[`${obj.name}`] = obj.type
  },
  mainThisFn(state, sdk) {
    state.sdk = sdk
  },
  // sdk 赋值回调对象
  sdkAssignmentCallbackObject(state, obj) {
    /*
     * name 相关内容
     * audioContextType 话语权状态
     * */
    state.callbackObjectStore[`${obj.name}`] = obj.callback
  },
  // sdk 调用回调对象
  sdkCallCallbackObject(state, obj) {
    if (state.callbackObjectStore[`${obj.name}`]) {
      state.callbackObjectStore[`${obj.name}`](obj.res)
    }
  },
  /* =================================== 房间操作 =================================== */
  /* 加入失败 */
  joinFailedFn(state, type) {
    state.sdk.joinFailedFn(type)
  },
  /* 退出房间 */
  exitRoomFn(state, roomId) {
    state.sdk.exitRoomFn(roomId)
  },
  /* 发送房间消息 */
  sendRoomMessage(state, parameter) {
    state.sdk.sendRoomMessage(
      parameter.method,
      parameter.roomId,
      parameter.clientId
    )
  },
  // 获取媒体房间内，接收情况状态。
  getRoomRecvStatus(state, parameter) {
    state.sdk.getRoomRecvStatus(
      parameter.roomId,
      parameter.successCallback,
      parameter.failCallback
    )
  },

  /* ==================================== 加入房间操作 =================================== */
  /* 发起对讲 */
  intercom(state, parameter) {
    state.sdk.intercom(
      parameter.obj,
      parameter.failCallback,
      parameter.successCallback
    )
  },
  /* 语音通话 (可以进行其他操作) */
  intercomcall(state, parameter) {
    state.sdk.intercomcall(
      parameter.obj,
      parameter.failCallback,
      parameter.successCallback
    )
  },
  /* 发起监控 */
  monitor(state, parameter) {
    state.sdk.monitor(
      parameter.obj,
      parameter.failCallback,
      parameter.successCallback
    )
  },
  /* 发起双人视频通话 */
  videocall(state, parameter) {
    state.sdk.videocall(
      parameter.obj,
      parameter.failCallback,
      parameter.successCallback
    )
  },
  /* 发起多人视频会议 */
  videoMeetingIn(state, parameter) {
    state.sdk.videoMeetingIn(
      parameter.obj,
      parameter.failCallback,
      parameter.successCallback
    )
  },
  /* 视频录制 */
  recordVideo(state, parameter) {
    state.sdk.recordVideo(
      parameter.obj,
      parameter.failCallback,
      parameter.successCallback
    )
  },
  /* 加入频道 */
  channelInFn(state, parameter) {
    state.sdk.channelInFn(
      parameter.obj,
      parameter.failCallback,
      parameter.successCallback
    )
  },

  setVideoInput(state, videoStream) {
    state.sdk.setVideoInput(videoStream)
  },

  setAudioInput(state, audioStream) {
    state.sdk.setAudioInput(audioStream)
  },

  /* =================================== 加入房间后的操作 =================================== */
  // 在生产时更换视频输入源
  replaceVideoDevice(state, obj) {
    state.sdk.replaceVideoDevice(obj.videoInput, obj)
  },

  // 在生产时更换视频输入轨道
  replaceVideoTrack(state, obj) {
    state.sdk.replaceVideoTrack(obj.track, obj)
  },

  // 在生产时更换音频输入源
  replaceAudioDevice(state, obj) {
    state.sdk.replaceAudioDevice(obj.audioInput, obj)
  },

  // 在生产时更换音频输入轨道
  replaceAudioTrack(state, obj) {
    state.sdk.replaceAudioTrack(obj.track, obj)
  },

  /* 中控操作 */
  notificationDeviceControl(state, parameter) {
    state.sdk.notificationDeviceControl(parameter)
  },
  /**
   * 获取话语权
   */
  audioConsumerResume(state, parameter) {
    state.sdk.audioConsumerResume(parameter)
  },
  videoConsumerResume(state, parameter) {
    state.sdk.videoConsumerResume(parameter)
  },
  /**
   * 释放话语权
   */
  audioConsumerPaused(state, parameter) {
    state.sdk.audioConsumerPaused(parameter)
  },
  videoConsumerPaused(state, parameter) {
    state.sdk.videoConsumerPaused(parameter)
  },
  /**
   * 获取屏幕流
   * @param { video: true, audio: true }
   */
  getDisplayMedia(state, obj) {
    state.sdk.getDisplayMedia(obj)
  },
  /**
   * 传入一个流，拆分成音频流和视频流
   * @param stream
   */
  handlerDisplayMediaStream(state, obj) {
    state.sdk.handlerDisplayMediaStream(obj)
  },
  /**
   * 在生产时更换视频输入源
   */
  replaceVideoStream(state, obj) {
    state.sdk.replaceVideoStream(obj.stream, obj.roomId)
  },
  replaceAudioStream(state, obj) {
    state.sdk.replaceAudioStream(obj.stream, obj.roomId)
  },

  disableMic(state, roomId) {
    state.sdk.disableMic(roomId)
  },
  disableWebcam(state, roomId) {
    state.sdk.disableWebcam(roomId)
  },
  /* 广播设备列表 */
  broadcastDeviceIdListFn(state, broadcastDeviceList) {
    state.broadcastDeviceList = broadcastDeviceList || []
  },
  /* 直播设备列表 */
  multipleChecksDataListFn(state, multipleChecksDataList) {
    state.multipleChecksDataList = multipleChecksDataList || []
  },
  debugMultipleChecksListFn(state, params) {
    //   type: 1 新增 2删除 3全部替换
    //   roomId: 房间id
    //   deviceId: 设备id
    //   debugList: 调试数据 （当type 3的时候才生效）
    const { type, roomId, deviceId, debugList } = params || {}
    console.log('debugMultipleChecksListFn', params)
    if (type === 3) {
      state.debugMultipleChecksList = [...debugList]
      return
    }
    const index = state.debugMultipleChecksList.findIndex(
      (v) => v.roomId === roomId && v.deviceId === deviceId
    )
    const debugMultipleChecksList = [...state.debugMultipleChecksList]
    if (type === 1 && index === -1) {
      debugMultipleChecksList.push({
        roomId,
        deviceId,
        roomRecvStatusList: []
      })
      state.debugMultipleChecksList = debugMultipleChecksList
    } else if (type === 2 && index !== -1) {
      debugMultipleChecksList.splice(index, 1)
      state.debugMultipleChecksList = [...debugMultipleChecksList]
    }
  }
}

const actions = {}

export default {
  state,
  getters,
  mutations,
  actions
}
