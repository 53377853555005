import Axios from '@/http'

export default {
  countDayNum(params) {
    return new Promise((resolve, reject) => {
      Axios.post('deviceTrail/countDayNum', params).then((res) => {
        resolve(res)
      })
    })
  },
  page(controller, params) {
    return new Promise((resolve, reject) => {
      Axios.post('deviceTrail/' + controller + '/page', params, {
        isJSON: true
      }).then((res) => {
        resolve(res)
      })
    })
  }
}
