import Axios from '@/http'

export default {
  insertTemp(params) {
    return new Promise((resolve, reject) => {
      Axios.post('orgGroup/insertTemp', params, { isJSON: true })
        .then((res) => {
          resolve(res)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  temp2Nature(id) {
    return new Promise((resolve, reject) => {
      Axios.post('orgGroup/temp2Nature', { id }).then((res) => {
        resolve(res)
      })
    })
  }
}
