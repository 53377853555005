import { createWorker, stopWorker } from '@/utils/webWorker.js'

export default {
  data() {
    return {
      recordingTimer: null,
      progressKey: null,
      recordDeviceList: []
    }
  },
  methods: {
    // 录制房间（创建）
    async recordRoomCreate(device) {
      const { code, data } = await this.$api.alarmRecord.recordRoomCreate({
        progressKey: this.progressKey,
        deviceId: device.deviceId
      })
      if (code === 200) {
        const index = this.monitorDeviceList.findIndex(
          (v) => v.deviceId === device.deviceId
        )
        if (index !== -1) {
          this.$set(this.monitorDeviceList[index], 'recordRoomId', data)
        }
      }
    },
    // 开启录制
    recordRoomOperate_recordStart1(device) {
      const index = this.recordDeviceList.findIndex(
        (v) => v.deviceId === device.deviceId
      )
      if (index === -1 && device.recordRoomId) {
        const item = this.transcribeClientIdList.find(
          (v) => v.clientId === device.deviceId
        )
        let audioPushing = false
        let videoPushing = false
        if (item) {
          if (item.consumers) {
            const audioContext = new AudioContext()
            const dest = audioContext.createMediaStreamDestination()
            const videoStream = new MediaStream()
            item.consumers.forEach((consumer) => {
              if (consumer.track.kind === 'audio') {
                audioPushing = true
                const audioStream = new MediaStream()
                audioStream.addTrack(consumer.track)
                audioContext.createMediaStreamSource(audioStream).connect(dest)
              } else if (consumer.track.kind === 'video') {
                videoStream.addTrack(consumer.track)
                videoPushing = true
              }
            })
            if (videoPushing && audioPushing) {
              this.$nextTick(async () => {
                const roomId = device.recordRoomId
                const { code } = await this.$api.alarmRecord.recordRoomOperate({
                  roomId,
                  action: 'recordStart'
                })
                if (code === 200) {
                  this.recordDeviceList.push({
                    deviceId: device.deviceId,
                    roomId
                  })
                  this.recordVideo({
                    audioStream: dest.stream,
                    videoStream,
                    roomId,
                    deviceId: device.deviceId
                  })
                }
              })
            } else {
              // this.$message.warning('当前设备无法录制画面')
            }
          }
        }
      }
    },
    recordRoomOperate_recordStart(device) {
      const index = this.recordDeviceList.findIndex(
        (v) => v.deviceId === device.deviceId
      )
      if (index === -1 && device.recordRoomId) {
        const item = this.transcribeClientIdList.find(
          (v) => v.clientId === device.deviceId
        )
        let audioPushing = false
        let videoPushing = false
        const video = document.getElementById(
          `${device.deviceId}${device.roomId}`
        )
        if (item && video) {
          if (item.consumers) {
            const audioContext = new AudioContext()
            const dest = audioContext.createMediaStreamDestination()
            item.consumers.forEach((consumer) => {
              if (consumer.track.kind === 'audio') {
                audioPushing = true
                const audioStream = new MediaStream()
                audioStream.addTrack(consumer.track)
                audioContext.createMediaStreamSource(audioStream).connect(dest)
              } else if (consumer.track.kind === 'video') {
                videoPushing = true
              }
            })
            if (videoPushing && audioPushing) {
              const canvas = document.createElement('canvas')
              const ctx = canvas.getContext('2d')
              // video.addEventListener('play', function () {
              const drawFrame = () => {
                if (this.recordingTimer) {
                  stopWorker(this.recordingTimer)
                  this.recordingTimer = null
                }
                this.recordingTimer = createWorker(() => {
                  if (
                    document.getElementById(
                      `${device.deviceId}${device.roomId}`
                    )
                  ) {
                    canvas.width = video.videoWidth
                    canvas.height = video.videoHeight
                    // 每帧清除canvas并绘制视频帧
                    ctx.clearRect(0, 0, video.videoWidth, video.videoHeight)
                    ctx.imageSmoothingEnabled = false
                    ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
                    // 请求下一帧
                    requestAnimationFrame(drawFrame)
                  } else {
                    stopWorker(this.recordingTimer)
                  }
                }, 50)
              }
              drawFrame()
              // })
              const videoStream = canvas.captureStream()
              this.$nextTick(async () => {
                const roomId = device.recordRoomId
                const { code } = await this.$api.alarmRecord.recordRoomOperate({
                  roomId,
                  action: 'recordStart'
                })
                if (code === 200) {
                  this.recordDeviceList.push({
                    deviceId: device.deviceId,
                    roomId
                  })
                  this.recordVideo({
                    audioStream: dest.stream,
                    videoStream,
                    roomId,
                    deviceId: device.deviceId
                  })
                }
              })
            } else {
              // this.$message.warning('当前设备无法录制画面')
            }
          }
        }
      }
    },
    /* sdk 录制视频 */
    recordVideo({ videoStream, audioStream, roomId, deviceId }) {
      this.$sdk.recordVideo(
        {
          roomId,
          videoStream,
          audioStream
        },
        (res) => {
          console.log('res', res)
        },
        (res) => {
          const index = this.monitorDeviceList.findIndex(
            (v) => v.deviceId === deviceId
          )
          if (index !== -1) {
            this.$set(this.monitorDeviceList[index], 'recordingTime', 0)
            this.broadcastSupport(this.monitorDeviceList[index])
          }
        }
      )
    },
    // 停止录制
    async recordRoomOperate_recordStop(device) {
      const index = this.recordDeviceList.findIndex(
        (v) => v.deviceId === device.deviceId
      )
      if (index !== -1) {
        const roomId = device.recordRoomId
        const { code } = await this.$api.alarmRecord.recordRoomOperate({
          roomId,
          action: 'recordStop'
        })
        if (code === 200) {
          this.$sdk.exitRoomFn(roomId)
          this.recordDeviceList.splice(index, 1)
        }
      }
    },
    // 房间中途修改音频流
    replaceAudioStream(device, broadcastType = false) {
      // 录像房间 id
      const roomId = device.recordRoomId
      const item = this.transcribeClientIdList.find(
        (v) => v.clientId === device.deviceId
      )
      // 获取设备 audio stream
      const audioContext = new AudioContext()
      const dest = audioContext.createMediaStreamDestination()
      if (!item) {
        return
      }
      item.consumers.forEach((consumer) => {
        if (consumer.track.kind === 'audio') {
          const audioStream = new MediaStream()
          audioStream.addTrack(consumer.track)
          audioContext.createMediaStreamSource(audioStream).connect(dest)
        }
      })
      this.$nextTick(() => {
        if (broadcastType) {
          if (!this.broadcastRoomId || !device.deviceId) return
          // 获取广播的声音 audio stream
          const broadcastAudioStream = this.$sdk.getLocalAudioStream({
            roomId: this.broadcastRoomId
          })
          if (broadcastAudioStream) {
            // 合并 audio stream
            audioContext
              .createMediaStreamSource(broadcastAudioStream)
              .connect(dest)
            this.$sdk.replaceAudioStream(dest.stream, roomId)
          } else {
            console.error(
              'broadcastAudioStream',
              this.broadcastRoomId,
              broadcastAudioStream
            )
            // 获取不到广播房间的 AudioStream 自动获取麦克风的
            navigator.getUserMedia(
              { audio: true },
              (stream) => {
                const tracksList = stream.getTracks()
                tracksList.forEach((track) => {
                  if (track.kind === 'audio') {
                    const audioStream = new MediaStream()
                    audioStream.addTrack(track)
                    audioContext
                      .createMediaStreamSource(audioStream)
                      .connect(dest)
                  }
                })
                this.$nextTick(() => {
                  this.$sdk.replaceAudioStream(dest.stream, roomId)
                })
              },
              (e) => {
                console.error(e)
              }
            )
          }
        }
      })
    }
  }
}
