import Axios from '@/http'

export default {
  /* 内部加入会议 */
  videoMeetingIn(uniqueId) {
    return new Promise((resolve, reject) => {
      Axios.post('videoMeeting/videoMeetingIn', { uniqueId }).then((res) => {
        return resolve(res)
      })
    })
  },
  /* 获取会议详情 */
  selectByUniqueId(uniqueId) {
    return new Promise((resolve, reject) => {
      Axios.post('videoMeeting/selectByUniqueId', { uniqueId }).then((res) => {
        return resolve(res)
      })
    })
  },
  /* 创建视频会议新标签页获取mq连接信息校验码 */
  validCodeCreate(uniqueId) {
    return new Promise((resolve, reject) => {
      Axios.post('videoMeeting/validCode/create', { uniqueId }).then((res) => {
        return resolve(res)
      })
    })
  }
}
