<!-- 编辑抽查 -->
<template>
  <div>
    <div
      v-if="wrapperClosable && dialogVisible"
      class="fixed top-0 left-0 bg-[rgba(0,0,0,0.5)] w-full h-full z-[100]"
    ></div>
    <div
      v-if="animationVisible"
      :class="{
        hide: !dialogVisible,
        display: dialogVisible
      }"
      style="animation-iteration-count: 1"
      class="duration-500 bg-background rounded-[12px_0_0_12px] border-r-0 border-theme fixed right-0 top-[54%] h-[87%] -translate-y-1/2 z-[101] overflow-hidden"
    >
      <div class="w-[1080px] h-full">
        <div
          class="w-full h-full px-[20px] box_border flex flex-col text-main text-xs"
        >
          <!-- 标题 -->
          <div class="flex justify-between items-center h-[60px]">
            <div class="font-bold text-lg">{{ title }}</div>
            <svg-icon
              @click.native="onClose"
              svg-name="signOut"
              className="clickBox !w-[28px] !h-[28px]"
            />
          </div>
          <!-- 内容 -->
          <div
            v-loading="loading"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
            class="flex-grow h-0"
          >
            <el-form
              :model="form"
              :rules="rules"
              ref="form"
              label-width="0px"
              class="w-full h-full flex flex-col"
            >
              <!-- 定时抽查 -->
              <div
                v-if="contentState === 3"
                class="flex mb-2 flex-shrink-0 rounded bg-backgroundv2 px-3 pt-2 h-[90px] box-border items-center"
              >
                <el-form-item prop="templateName" class="w-full">
                  <div class="text-sm h-[30px] flex items-center">
                    模板名称<i class="text-[#f00]">*</i>
                  </div>
                  <el-input
                    v-model="form.templateName"
                    placeholder="请输入模板名称"
                    clearable
                  ></el-input>
                </el-form-item>
              </div>
              <!-- 定时抽查 -->
              <div
                v-else-if="contentState === 2"
                class="flex mb-2 flex-shrink-0 rounded bg-backgroundv2 px-3 pt-2 h-[90px] box-border items-center"
              >
                <div class="flex-1 mr-4">
                  <el-form-item prop="taskDateType">
                    <div class="text-sm h-[30px] flex items-center">
                      执行日期<i class="text-[#f00]">*</i>
                    </div>
                    <el-select
                      class="w_100vh"
                      @change="taskDateTypeFn"
                      v-model="form.taskDateType"
                      placeholder="请选择执行日期"
                    >
                      <el-option label="每日" :value="1"></el-option>
                      <el-option label="指定星期" :value="2"></el-option>
                      <el-option label="指定日期" :value="3"></el-option>
                      <el-option label="指定时间段" :value="4"></el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div>
                  <el-form-item
                    v-if="form.taskDateType === 2"
                    prop="everydayJson"
                    class="max-w-[360px]"
                  >
                    <div class="text-sm h-[30px] flex items-center">
                      指定星期<i class="text-[#f00]">*</i>
                    </div>
                    <div class="everydayBox leading-[20px]">
                      <el-checkbox v-model="everydayJson.everyday1"
                        ><span class="text-xs">星期一</span>
                      </el-checkbox>
                      <el-checkbox v-model="everydayJson.everyday2"
                        ><span class="text-xs">星期二</span>
                      </el-checkbox>
                      <el-checkbox v-model="everydayJson.everyday3"
                        ><span class="text-xs">星期三</span>
                      </el-checkbox>
                      <el-checkbox v-model="everydayJson.everyday4"
                        ><span class="text-xs">星期四</span>
                      </el-checkbox>
                      <el-checkbox v-model="everydayJson.everyday5"
                        ><span class="text-xs">星期五</span>
                      </el-checkbox>
                      <el-checkbox v-model="everydayJson.everyday6"
                        ><span class="text-xs">星期六</span>
                      </el-checkbox>
                      <el-checkbox v-model="everydayJson.everyday7"
                        ><span class="text-xs">星期日</span>
                      </el-checkbox>
                    </div>
                  </el-form-item>
                  <el-form-item
                    v-else-if="form.taskDateType === 3"
                    prop="apDateListV2"
                    class="mr-4"
                  >
                    <div class="text-sm h-[30px] flex items-center">
                      指定日期<i class="text-[#f00]">*</i>
                    </div>
                    <div>
                      <el-date-picker
                        type="dates"
                        v-model="form.apDateListV2"
                        format="yyyy 年 MM 月 dd 日"
                        style="width: 100%"
                        value-format="yyyy-MM-dd"
                        placeholder="选择一个或多个日期"
                      >
                      </el-date-picker>
                    </div>
                  </el-form-item>
                  <el-form-item
                    v-else-if="form.taskDateType === 4"
                    prop="apDtmV2"
                    class="mr-4 w-[340px]"
                  >
                    <div class="text-sm h-[30px] flex items-center">
                      指定时间段<i class="text-[#f00]">*</i>
                    </div>
                    <div>
                      <el-date-picker
                        v-model="form.apDtmV2"
                        type="daterange"
                        class="!w-full"
                        format="yyyy 年 MM 月 dd 日"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                      >
                      </el-date-picker>
                    </div>
                  </el-form-item>
                </div>
                <div class="flex-1 min-w-[220px]">
                  <el-form-item prop="taskDateTime">
                    <div class="text-sm h-[30px] flex items-center">
                      执行时间<i class="text-[#f00]">*</i>
                    </div>
                    <el-time-picker
                      is-range
                      class="!w-full"
                      v-model="form.taskDateTime"
                      range-separator="至"
                      value-format="HH:mm:ss"
                      start-placeholder="开始时间"
                      end-placeholder="结束时间"
                      placeholder="选择时间范围"
                    >
                    </el-time-picker>
                  </el-form-item>
                </div>
              </div>
              <!-- 立即抽查 -->
              <div class="flex items-center flex-grow h-0">
                <div class="flex-grow w-0 h-full flex flex-col">
                  <div class="flex">
                    <el-form-item class="flex-1">
                      <div class="text-sm h-[30px] flex items-center">
                        抽查类型<i class="text-[#f00]">*</i>
                      </div>
                      <el-select
                        class="w-full"
                        v-model="form.checkType"
                        placeholder="请选择抽查类型"
                      >
                        <el-option label="范围抽查" :value="1"> </el-option>
                        <el-option label="随机抽查" :value="2"> </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item class="flex-1 ml-1 pl-1">
                      <div class="text-sm h-[30px] flex items-center">
                        抽查设备<i class="text-[#f00]">*</i>
                      </div>
                      <el-select
                        class="w-full"
                        @change="changeOnlineStateFlag"
                        :value="form.onlineStateFlag"
                        placeholder="请选择抽查设备"
                      >
                        <el-option label="仅抽查在线设备" :value="1">
                        </el-option>
                        <el-option label="抽查所有设备" :value="2"> </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item
                      v-if="form.checkType === 2"
                      class="flex-1 ml-1 pl-1"
                      prop="limitNum"
                    >
                      <div class="text-sm h-[30px] flex items-center">
                        随机数量<i class="text-[#f00]">*</i>
                      </div>
                      <div
                        class="test h-[32px] flex border border-border rounded box-border overflow-hidden bg-backgroundv2"
                      >
                        <div
                          class="flex-grow w-0 box-border px-1 flex items-center justify-center"
                        >
                          <input
                            type="text"
                            style="outline: none"
                            class="bg-backgroundv2 w-full h-full"
                            v-model="form.limitNum"
                            @blur="blurLimitNum"
                            placeholder="请输入随机数量"
                          />
                        </div>
                        <div
                          class="flex flex-col w-[32px] text-auxiliary border-l border-border"
                        >
                          <div
                            @click="setRandomNumber(1)"
                            class="flex-1 flex items-center justify-center clickBox"
                          >
                            <i class="el-icon-arrow-up"></i>
                          </div>
                          <div
                            @click="setRandomNumber(2)"
                            class="flex-1 border-t border-border flex items-center justify-center clickBox"
                          >
                            <i class="el-icon-arrow-down"></i>
                          </div>
                        </div>
                      </div>
                    </el-form-item>
                  </div>
                  <div class="text-sm h-[30px] flex items-center">
                    抽查范围<i class="text-[#f00]">*</i>
                  </div>
                  <!--随机抽查-->
                  <div
                    v-show="form.checkType === 2"
                    class="flex-grow h-0 bg-backgroundv4 shadow-[0_0_10px_0_inset] shadow-theme05 rounded-[6px] flex items-center justify-center"
                  >
                    <div class="tac text-auxiliary">
                      <svg-icon
                        svg-name="noData"
                        className="svgBox !w-[40px] !h-[40px]"
                      />
                      <div class="mt_10">
                        <!--// text-sm-->
                        <p class="">随机抽查下您不需要选择范围与设备</p>
                        <p class="mt-1">系统会帮助您随机选择</p>
                      </div>
                    </div>
                  </div>
                  <!--范围抽查-->
                  <div
                    v-show="form.checkType === 1"
                    class="flex-grow h-0 bg-backgroundv3 rounded-[6px] flex flex-col p-2"
                  >
                    <!--范围抽查-->
                    <div class="flex justify-between h-[40px] items-center">
                      <div>
                        <el-select
                          class="w-[160px]"
                          :value="form.checkScope"
                          @change="changeCheckScope"
                          placeholder="请选择"
                        >
                          <el-option label="选择区域" :value="1"> </el-option>
                          <el-option label="选择部门" :value="2"> </el-option>
                          <el-option label="选择设备" :value="3"> </el-option>
                        </el-select>
                      </div>
                      <div>
                        <el-popover
                          :open-delay="500"
                          placement="bottom-start"
                          popper-class="popover_echarts w-[200px]"
                          trigger="hover"
                        >
                          <ul class="fs_12 p-[10px_20px]">
                            <li class="r-f mb_10">
                              <span>1、</span>
                              <span
                                >区域(电子围栏)：选择此项以在指定的电子围栏区域内对支持抽查配置的设备进行抽查。</span
                              >
                            </li>
                            <li class="r-f mb_10">
                              <span>2、</span>
                              <span
                                >部门：选择此项以在指定的部门中对支持抽查配置的设备进行抽查。</span
                              >
                            </li>
                            <li class="r-f mb_10">
                              <span>3、</span>
                              <span
                                >设备：选择此项以手动选择特定支持抽查配置的设备进行抽查。</span
                              >
                            </li>
                          </ul>
                          <span
                            class="fs_12 cursor_pointer !text-theme"
                            slot="reference"
                          >
                            <svg-icon
                              svg-name="help"
                              class-name="svgBox ml-1 !w-[12px] !h-[12px]"
                            />
                            帮助
                          </span>
                        </el-popover>
                      </div>
                    </div>
                    <!-- 抽选择组件 （设备组件，电子围栏组件，部门组件） -->
                    <div class="flex-grow h-0 flex">
                      <div class="flex-1">
                        <selectElectronicFence
                          v-if="form.checkScope === 1"
                          @on-selectedDataList="setSelectedDataList"
                          :initSelectedDataList="selectedDataList"
                          ref="selectElectronicFence"
                        ></selectElectronicFence>
                        <selectDepartment
                          v-else-if="form.checkScope === 2"
                          @on-selectedDataList="setSelectedDataList"
                          :initSelectedDataList="selectedDataList"
                          ref="selectDepartment"
                        ></selectDepartment>
                        <selectDevice
                          v-else-if="form.checkScope === 3"
                          @on-selectedDataList="setSelectedDataList"
                          :initSelectedDataList="selectedDataList"
                          :onlineStateFlag="form.onlineStateFlag"
                          ref="selectDevice"
                        ></selectDevice>
                      </div>
                    </div>
                  </div>
                </div>
                <!--分割线-->
                <div
                  class="mx-[20px] w-[2px] h-[96%] rounded bg-border flex-shrink-0"
                ></div>
                <div class="w-[33%] h-full flex flex-col">
                  <el-form-item>
                    <div class="text-sm h-[30px] flex items-center">
                      抽查方式<i class="text-[#f00]">*</i>
                    </div>
                    <el-select
                      class="w-full"
                      v-model="form.checkStyle"
                      :disabled="contentState === 2"
                      placeholder="请选择抽查方式"
                    >
                      <el-option label="消息" :value="1"> </el-option>
                      <el-option label="呼叫" :value="2"> </el-option>
                    </el-select>
                  </el-form-item>
                  <div class="text-sm h-[30px] flex items-center">
                    消息内容<i class="text-[#f00]">*</i>
                  </div>
                  <!-- 呼叫内容-->
                  <div
                    v-show="form.checkStyle === 2"
                    class="flex-grow h-0 bg-backgroundv4 shadow-[0_0_10px_0_inset] shadow-theme05 rounded-[6px] flex items-center justify-center"
                  >
                    <div class="tac text-auxiliary">
                      <svg-icon
                        svg-name="noData"
                        className="svgBox !w-[40px] !h-[40px]"
                      />
                      <div class="mt_10">
                        <p>呼叫不需要您输入消息内容</p>
                        <p class="mt-1">将会采用对讲应答的方式抽查</p>
                      </div>
                    </div>
                  </div>
                  <!-- 消息内容-->
                  <div
                    v-if="form.checkStyle === 1"
                    class="flex-grow h-0 bg-backgroundv3 rounded-[6px] flex flex-col p-2"
                  >
                    <div class="flex-grow h-0">
                      <el-form-item prop="msgContent">
                        <el-input
                          type="textarea"
                          placeholder="请填写要发送的消息"
                          v-model="form.msgContent"
                          maxlength="100"
                          resize="none"
                          :autosize="{ minRows: contentState === 1 ? 14 : 10 }"
                          show-word-limit
                        ></el-input>
                      </el-form-item>
                      <el-checkbox v-model="form.playMsg"
                        ><span class="text-xs"
                          >需在设备进行TTS语音播报</span
                        ></el-checkbox
                      >
                    </div>
                    <div class="mb-2">
                      <div class="text-sm h-[30px] flex items-center">
                        应答方式<i class="text-[#f00]">*</i>
                      </div>
                      <el-select
                        class="w-full"
                        v-model="form.actionType"
                        placeholder="请选择"
                      >
                        <el-option label="打卡" :value="1"> </el-option>
                        <el-option label="答复" :value="2"> </el-option>
                      </el-select>
                    </div>
                    <div class="mb-2">
                      <div class="text-sm h-[30px] flex items-center">
                        应答时间<i class="text-[#f00]">*</i>
                      </div>
                      <el-select
                        class="w-full"
                        v-model="form.actionTime"
                        placeholder="请选择应答时间"
                      >
                        <el-option label="5s" :value="5"> </el-option>
                        <el-option label="10s" :value="10"> </el-option>
                        <el-option label="15s" :value="15"> </el-option>
                        <el-option label="30s" :value="30"> </el-option>
                        <el-option label="60s" :value="60"> </el-option>
                      </el-select>
                    </div>
                  </div>
                </div>
              </div>
            </el-form>
          </div>
          <!-- 操作区 -->
          <div class="flex items-center justify-between h-[80px]">
            <div class="text-auxiliary flex items-center">
              <i class="el-icon-warning-outline text-sm mr-1"></i>
              您可以套用抽查模板快速填写内容
            </div>
            <div>
              <el-button
                v-if="contentState !== 3"
                @click="selectTemplateShow"
                size="small"
                class="button_function buttonBox"
                >抽查模板</el-button
              >
              <el-button
                @click="onClose"
                size="small"
                class="button_default buttonBox"
                >取 消</el-button
              >
              <el-button
                size="small"
                class="button_function buttonBox"
                @click="onSubmit"
                >确 认</el-button
              >
            </div>
          </div>
        </div>
      </div>
      <selectTemplate ref="selectTemplate"></selectTemplate>
    </div>
    <!-- 点名抽查 -->
    <viewSpotCheck ref="viewSpotCheck"></viewSpotCheck>
  </div>
</template>

<script>
import selectDevice from './selectDevice'
import selectDepartment from './selectDepartment'
import selectElectronicFence from './selectElectronicFence'
import selectTemplate from './selectTemplate'
import viewSpotCheck from '@components/spotCheck/viewSpotCheck/viewSpotCheck'
export default {
  data() {
    const everydayJsonFn = (rule, value, callback) => {
      let weekDate = ''
      for (const key in this.everydayJson) {
        if (this.everydayJson[key]) {
          weekDate += key.replace(/(^everyday)|(everyday$)/g, '') + ','
        }
      }
      if (weekDate.length) {
        callback()
      } else {
        callback(new Error('请选择指定星期'))
      }
    }
    return {
      successfulCallback: null,
      loading: false,
      wrapperClosable: false, // 弹窗是否需要遮罩
      selectedDataList: [],
      contentState: null, // 1 立即抽查 2定时抽查 2抽查模板
      title: '立刻发起点名抽查',
      dialogVisible: false,
      animationTime: null,
      animationVisible: false,
      initForm: {
        limitNum: 10, // 最大数量
        onlineStateFlag: 1, // [在线状态过滤]{1:在线;2:全部
        checkType: 1, // 抽查类型（1范围抽查、2随机抽查）
        checkScope: 1, // 抽查范围（1电子围栏、2部门、3设备）
        checkStyle: 1, // 抽查方式（1消息、2呼叫）
        actionType: 1, // 应答方式（1打卡、2答复）
        actionTime: 5, // 应答时间 (单位秒)
        deviceIdList: [], // 设备ID
        mapRailIdList: [], // 电子围栏id
        orgIdList: [], // 部门id
        msgContent: '', // 消息内容
        playMsg: false, // 设备是否需要TTS播报消息（1需要）
        // 抽查模版
        templateName: '', // 模版名称
        // 定时抽查
        taskDateType: 1,
        apDateListV2: null,
        apDtmV2: null
      },
      form: {
        limitNum: 10, // 最大数量
        onlineStateFlag: 1, // [在线状态过滤]{1:在线;2:全部
        actionTime: 5, // 应答时间
        actionType: 1, // 应答方式（1打卡、2答复）
        checkScope: 1, // 抽查范围（1电子围栏、2部门、3设备）
        checkStyle: 1, // 抽查方式（1消息、2呼叫）
        checkType: 1, // 抽查类型（1范围抽查、2随机抽查）
        deviceIdList: [], // 设备ID
        mapRailIdList: [], // 电子围栏id
        orgIdList: [], // 部门id
        msgContent: '', // 消息内容
        playMsg: false, // 设备是否需要TTS播报消息（1需要）
        // 抽查模版
        templateName: '', // 模版名称
        // 定时抽查
        taskDateType: 1,
        apDateListV2: null,
        apDtmV2: null
      },
      rules: {
        limitNum: [
          {
            required: true,
            message: '请输入随机数量',
            trigger: 'blur'
          }
        ],
        templateName: [
          {
            required: true,
            message: '请输入模板名称',
            trigger: 'blur'
          }
        ],
        msgContent: [
          {
            required: true,
            message: '请填写要发送的消息',
            trigger: 'blur'
          }
        ],
        everydayJson: [
          {
            required: true,
            validator: everydayJsonFn,
            trigger: 'change'
          }
        ],
        taskDateTime: [
          { required: true, message: '请选择执行时间', trigger: 'change' }
        ],
        taskDateType: [
          { required: true, message: '请选择执行日期', trigger: 'change' }
        ],
        apDateListV2: [
          { required: true, message: '选择一个或多个日期', trigger: 'change' }
        ],
        apDtmV2: [
          { required: true, message: '请选择指定时间段', trigger: 'change' }
        ]
      },
      /* 星期 */
      everydayJson: {
        everyday1: false,
        everyday2: false,
        everyday3: false,
        everyday4: false,
        everyday5: false,
        everyday6: false,
        everyday7: false
      }
    }
  },
  watch: {
    'form.limitNum': function (val) {
      if (typeof val === 'string') {
        val = val.replace(/[^.\d]/g, '')
        val = val.replace('.', '')
        // if (val - 0 <= 1) {
        //   this.$set(this.form, 'limitNum', 1)
        //   return
        // }
        this.$set(this.form, 'limitNum', val)
      }
    },
    dialogVisible: {
      immediate: true,
      handler: function () {
        this.setAnimationVisible()
      }
    }
  },
  components: {
    selectDevice,
    selectDepartment,
    selectElectronicFence,
    selectTemplate,
    viewSpotCheck
  },
  mounted() {
    // this.show(null, {
    //   // contentState: 2,
    //   checkScope: 1
    // })
  },
  methods: {
    blurLimitNum() {
      this.$refs.form.validateField('limitNum')
    },
    // 切换在线状态
    changeOnlineStateFlag(onlineStateFlag) {
      if (onlineStateFlag === this.form.onlineStateFlag) return
      return new Promise((resolve, reject) => {
        if (
          this.selectedDataList.length !== 0 &&
          this.form.checkScope === 3 &&
          onlineStateFlag === 1
        ) {
          this.$deleteConfirmationBox(
            {
              deleteTitleImg: 'tips',
              deleteTitle: '提示',
              submitClass: 'button_function',
              deleteContent: `
                <div><p>切换抽查设备将会清空已选择数据</p><p>是否确认切换</p></div>
              `,
              closeText: '取消',
              submitText: '确认'
            },
            () => {
              const components = this.getCheckScopeComponents()
              if (components) {
                components.clearSelectedData()
                components.getData()
              }
              this.$set(this.form, 'onlineStateFlag', onlineStateFlag)
              resolve()
            }
          )
        } else {
          this.$set(this.form, 'onlineStateFlag', onlineStateFlag)
          if (this.form.checkScope === 3) {
            const components = this.getCheckScopeComponents()
            if (components) {
              components.getData()
            }
          }
          resolve()
        }
      })
    },
    // 设置随机数量
    setRandomNumber(type) {
      // type 1 添加 2 删除
      let randomNumber = this.form.limitNum - 0
      if (type === 1) {
        randomNumber += 1
      } else {
        if (randomNumber <= 1) {
          this.$message.error('最少随机 1 台设备')
          return
        }
        randomNumber -= 1
      }
      this.$set(this.form, 'limitNum', randomNumber)
    },
    show(id, params) {
      const {
        checkScope = 1, // 抽查范围（1电子围栏、2部门、3设备）
        contentState = 1, // 1 立即抽查 2定时抽查 2抽查模板
        onlineStateFlag = 1, // [在线状态过滤]{1:在线;2:全部
        copyType = false,
        wrapperClosable = false,
        title = '立刻发起点名抽查',
        selectedDataList = [],
        successfulCallback = null
      } = params || {}
      if (this.dialogVisible) {
        if (contentState === 1) {
          // const selectedDataList = this.arrangeSelectedDataList(params)
          if (checkScope === this.form.checkScope) {
            const components = this.getCheckScopeComponents()
            if (components) {
              components.addSelectedDataList(selectedDataList)
            }
          } else {
            this.setCheckScope(checkScope).then(() => {
              this.setSelectedDataList([], true)
              const components = this.getCheckScopeComponents()
              if (components) {
                components.addSelectedDataList(selectedDataList)
              }
            })
          }
        } else {
          return this.$message.warning('重复开启')
        }
        return
      }
      this.successfulCallback = successfulCallback
      this.wrapperClosable = wrapperClosable
      this.contentState = contentState
      this.title = title
      if (this.$refs.form) {
        this.$refs.form.resetFields()
      }
      this.form = { ...this.initForm }
      this.$set(this.form, 'limitNum', 10)
      this.$set(this.form, 'checkScope', checkScope)
      this.$set(this.form, 'onlineStateFlag', onlineStateFlag)
      if (contentState === 1) {
        if (selectedDataList.length) {
          this.setSelectedDataList(selectedDataList, true)
        }
      } else {
        this.setSelectedDataList([], true)
      }
      this.everydayJson = {
        everyday1: false,
        everyday2: false,
        everyday3: false,
        everyday4: false,
        everyday5: false,
        everyday6: false,
        everyday7: false
      }
      if (id && typeof id === 'number') {
        this.detail(id, copyType)
      } else {
        this.setDialogVisible(true)
      }
    },
    // 设置弹窗显示隐藏
    setDialogVisible(type) {
      this.dialogVisible =
        typeof type === 'boolean' ? type : !this.dialogVisible
    },

    taskDateTypeFn() {
      this.$refs.form.clearValidate('everydayJson')
      this.$refs.form.clearValidate('apDateListV2')
      this.$refs.form.clearValidate('apDtmV2')
    },
    // change 抽查范围
    changeCheckScope(checkScope) {
      this.setCheckScope(checkScope).then(() => {
        this.setSelectedDataList([], true)
      })
    },
    // 设置抽查范围
    setCheckScope(checkScope) {
      if (checkScope === this.form.checkScope) return
      return new Promise((resolve, reject) => {
        if (
          this.selectedDataList.length !== 0 &&
          checkScope !== this.form.checkScope
        ) {
          this.$deleteConfirmationBox(
            {
              deleteTitleImg: 'tips',
              deleteTitle: '提示',
              submitClass: 'button_function',
              deleteContent: `
                <div><p>切换抽查范围将会清空已选择数据</p><p>是否确认切换</p></div>
              `,
              closeText: '取消',
              submitText: '确认'
            },
            () => {
              this.$set(this.form, 'checkScope', checkScope)
              resolve()
            }
          )
        } else {
          this.$set(this.form, 'checkScope', checkScope)
          resolve()
        }
      })
    },
    // 设置动画显示隐藏
    setAnimationVisible() {
      if (this.dialogVisible) {
        this.animationVisible = true
      } else {
        if (this.animationTime) {
          clearTimeout(this.animationTime)
          this.animationTime = null
        }
        this.animationTime = setTimeout(() => {
          this.animationVisible = this.dialogVisible
          clearTimeout(this.animationTime)
          this.animationTime = null
        }, 500)
      }
    },
    // 获取抽查范围组件
    getCheckScopeComponents() {
      const checkScope = this.form.checkScope
      if (checkScope === 1) {
        return this.$refs.selectElectronicFence
      } else if (checkScope === 2) {
        return this.$refs.selectDepartment
      } else if (checkScope === 3) {
        return this.$refs.selectDevice
      }
      return null
    },
    // 整理选中设备
    arrangeSelectedDataList(details) {
      const {
        checkScope = 1,
        mapRailIdList = [],
        orgIdList = [],
        deviceIdList = []
      } = details || {}

      let dataList = []
      if (checkScope === 1 && mapRailIdList.length) {
        dataList = mapRailIdList.map((v) => {
          return { id: v }
        })
      } else if (checkScope === 2 && orgIdList.length) {
        dataList = orgIdList.map((v) => {
          return { id: v }
        })
      } else if (checkScope === 3 && deviceIdList.length) {
        dataList = deviceIdList.map((v) => {
          return { deviceId: v }
        })
      }
      return dataList || []
    },
    // 设置选中设备
    setSelectedDataList(selectedDataList, notificationType = false) {
      // notificationType 通知状态 （是否通知）
      this.selectedDataList = selectedDataList
      if (notificationType) {
        const components = this.getCheckScopeComponents()
        if (components) {
          components.setSelectedDataList(selectedDataList)
        }
      }
    },
    // 获取选中数据
    getSelectedDataList() {
      const checkScope = this.form.checkScope
      let name = ''
      let dataList = []
      if (checkScope === 1) {
        name = 'mapRailIdList'
        dataList = this.selectedDataList.map((v) => v.id)
      } else if (checkScope === 2) {
        name = 'orgIdList'
        dataList = this.selectedDataList.map((v) => v.id)
      } else if (checkScope === 3) {
        name = 'deviceIdList'
        dataList = this.selectedDataList.map((v) => v.deviceId)
      }
      return {
        dataList,
        name
      }
    },
    // 提交
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const json = { ...this.form }
          json.playMsg = json.playMsg ? 1 : 0
          if (json.checkType === 1) {
            const res = this.getSelectedDataList()
            json[`${res.name}`] = res.dataList
            if (res.dataList.length === 0) {
              return this.$message.error('请选择抽查范围')
            }
          } else {
            json.checkScope = null
          }
          this.$nextTick(() => {
            if (this.contentState === 2) {
              this.timingInsert(json)
            } else if (this.contentState === 3) {
              this.templateInsert(json)
            } else {
              this.taskExecute(json)
            }
          })
        }
      })
    },
    viewSpotCheckShow(id) {
      if (!id) return
      this.$refs.viewSpotCheck?.show(id)
    },
    close() {
      this.setSelectedDataList([], true)
      this.setDialogVisible(false)
      this.successfulCallback = null
    },
    onClose() {
      this.close()
    },
    // 获取详情
    detail(id, copyType) {
      if (this.contentState === 2) {
        this.timingDetail(id, copyType)
      } else if (this.contentState === 3) {
        this.templateDetail(id, copyType)
      }
    },

    // ===========================
    // 定时抽查详情
    async timingDetail(id, copyType = false) {
      this.loading = true
      const { code, data } = await this.$api.detail('spotCheck/timing', id)
      if (code === 200) {
        const details = data
        const checkScope = data.checkScope
        if (details.taskDateType === 2) {
          const weekDate = details.weekDate.split(',')
          weekDate.forEach((v) => {
            v -= 0
            if (v === 1) {
              this.everydayJson.everyday1 = true
            } else if (v === 2) {
              this.everydayJson.everyday2 = true
            } else if (v === 3) {
              this.everydayJson.everyday3 = true
            } else if (v === 4) {
              this.everydayJson.everyday4 = true
            } else if (v === 5) {
              this.everydayJson.everyday5 = true
            } else if (v === 6) {
              this.everydayJson.everyday6 = true
            } else if (v === 7) {
              this.everydayJson.everyday7 = true
            }
          })
        } else if (details.taskDateType === 3) {
          const apDateListV2 = []
          details.apDateList.forEach((v) => {
            apDateListV2.push(v.apDtm.split(' 00:00:00')[0])
          })
          details.apDateListV2 = apDateListV2
        } else if (details.taskDateType === 4) {
          details.apDtmV2 = [details.apStartDtm, details.apEndDtm]
        }
        /* 执行时间 */
        details.taskDateTime = [
          details.taskDateStartTime,
          details.taskDateEndTime
        ]
        this.form = {
          ...details,
          playMsg: details.playMsg === 1
        }
        if (copyType) {
          this.$set(this.form, 'id', null)
        }
        const selectedDataList = this.arrangeSelectedDataList(details)
        this.setSelectedDataList(selectedDataList)
        this.$nextTick(() => {
          this.loading = false
          if (!this.dialogVisible) {
            this.setDialogVisible(true)
          }
        })
      }
    },
    // 定时抽查提交
    async timingInsert(json) {
      json.taskDateStartTime = this.form.taskDateTime[0]
      json.taskDateEndTime = this.form.taskDateTime[1]
      if (this.form.taskDateType === 2) {
        let weekDate = ''
        for (const key in this.everydayJson) {
          if (this.everydayJson[key]) {
            weekDate += key.replace(/(^everyday)|(everyday$)/g, '') + ','
          }
        }
        if (weekDate.length === 0) return this.$message.error('请选择指定星期')
        json.weekDate = weekDate.substring(weekDate.length - 1, 0)
      } else if (this.form.taskDateType === 3) {
        if (!this.form.apDateListV2 || this.form.apDateListV2.length === 0) {
          return this.$message.error('请选择日期')
        }
        json.apDateList = this.form.apDateListV2.map((v) => {
          return {
            apDtm: v + ' 00:00:00'
          }
        })
      } else if (this.form.taskDateType === 4) {
        if (!this.form.apDtmV2 || this.form.apDtmV2.length === 0) {
          return this.$message.error('请选择时间段')
        }
        json.apStartDtm = this.form.apDtmV2[0] + ' 00:00:00'
        json.apEndDtm = this.form.apDtmV2[1] + ' 00:00:00'
      }
      const { code, data = {} } = await this.$api.save('spotCheck/timing', json)
      if (code === 200) {
        this.$message.success('操作成功')
        this.$emit('on-submit')
        if (this.successfulCallback) {
          this.successfulCallback()
        }
        this.close()
      }
    },
    // ===========================
    // 选择模板
    async selectTemplateShow() {
      const { id } = await this.$refs.selectTemplate.show({
        checkStyle: this.contentState === 2 ? 1 : null
      })
      if (id) {
        if (this.$refs.form) {
          this.$refs.form.resetFields()
        }
        this.form = { ...this.initForm }
        this.everydayJson = {
          everyday1: false,
          everyday2: false,
          everyday3: false,
          everyday4: false,
          everyday5: false,
          everyday6: false,
          everyday7: false
        }
        this.selectedDataList = []
        await this.templateDetail(id, true)
        this.$message.success('模板已套用')
      }
    },
    // 抽查模版详情
    async templateDetail(id, copyType = false) {
      this.loading = true
      const { code, data } = await this.$api.detail('spotCheck/template', id)
      if (code === 200) {
        this.form = {
          ...data,
          playMsg: data.playMsg === 1
        }
        if (copyType) {
          this.$set(this.form, 'id', null)
        }
        const selectedDataList = this.arrangeSelectedDataList(this.form)
        this.setSelectedDataList(selectedDataList)
        this.$nextTick(() => {
          this.loading = false
          if (!this.dialogVisible) {
            this.setDialogVisible(true)
          }
        })
      }
    },
    // 抽查模版提交（新增）
    async templateInsert(json) {
      const { code } = await this.$api.save('spotCheck/template', json)
      if (code === 200) {
        this.$message.success('操作成功')
        this.$emit('on-submit')
        if (this.successfulCallback) {
          this.successfulCallback()
        }
        this.close()
      }
    },
    // ===========================
    // 立即抽查提交
    async taskExecute(json) {
      const { code, data = {} } = await this.$api.spotCheck.taskExecute(json)
      if (code === 200) {
        this.$message.success('操作成功')
        if (this.successfulCallback) {
          this.successfulCallback()
        }
        this.$emit('on-submit')
        this.viewSpotCheckShow(data.id)
        this.close()
      }
    }
  }
}
</script>
<style scoped lang="less" rel="stylesheet/less">
.display {
  animation: display 0.5s infinite forwards;
  animation-iteration-count: 1;
}
@keyframes display {
  0% {
    width: 0;
    border-width: 0;
  }
  100% {
    width: 1080px;
    border-width: 4px 0 4px 4px;
  }
}
.hide {
  animation: hide 0.5s infinite forwards;
  animation-iteration-count: 1;
}
@keyframes hide {
  0% {
    width: 1080px;
    border-width: 4px 0 4px 4px;
  }
  100% {
    width: 0;
    border-width: 0;
  }
}
.is-error .test {
  border-color: var(--dangerv2) !important;
}
</style>
