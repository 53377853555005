<!-- 自定义列 -->
<template>
  <el-table-column
    class-name="table-column-class"
    label-class-name="table-label-class-name"
    :prop="item.prop"
    :label="item.label"
    :align="item.align"
    :show-overflow-tooltip="item.overflowTooltip"
    :width="parseRem(item.width)"
    :type="item.type || ''"
  >
    <template #header="{ column }" v-if="item.headerSlot">
      <slot name="headerSlot" v-bind:column="column"></slot>
    </template>
    <template v-if="item.customSlot" slot-scope="{ row, $index }">
      <slot name="customSlot" v-bind:row="row" v-bind:index="$index"></slot>
    </template>
  </el-table-column>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
import { remToPx } from '@utils/adaptive'
export default {
  data () {
    return {}
  },
  props: {
    item: {}
  },
  methods: {
    remToPx,
    parseRem(res) {
      let px = res
      if (px) {
        px = parseInt(px, 10)
        if (isNaN(px)) {
          return ''
        }
        return this.remToPx(px / 16)
      }
      return ''
    },
  },
  mounted () {
  }
}
</script>
