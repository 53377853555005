/* 下发方法给到子应用使用 */
const state = {
  orgGroupId: null,
  /* 组件存储位置 */
  componentMethod: {},
  /* 检查记录参数 */
  checkParameters: null,
  /* 麦克风状态 */
  audioContextType: false,
  multipleChecks: [],
  departmentMultipleChecks: []
}

const getters = {
  multipleChecks(state) {
    return state.multipleChecks
  },
  departmentMultipleChecks(state) {
    return [...state.departmentMultipleChecks]
  },
  orgGroupId(state) {
    return state.orgGroupId
  },
  checkParameters(state) {
    return state.checkParameters
  },
  audioContextType(state) {
    return state.audioContextType
  }
}
const mutations = {
  multipleChecksObjFn(state, obj) {
    if (obj) {
      state[`${obj.name}`] = obj.dataList
    }
  },
  /* 组件回调 */
  callComponent(state, obj) {
    const data = state.componentMethod[`${obj.name}`]
    if (data) {
      data.callback(obj.data)
    }
  },
  /* 组件存储 */
  componentMethodFn(state, obj) {
    state.componentMethod[`${obj.name}`] = obj
  },
  /* 检查记录参数 */
  checkParametersFn(state, checkParameters) {
    state.checkParameters = checkParameters
  },
  /* 麦克风状态 */
  audioContextTypeFn(state, type) {
    if (state.state !== type) {
      state.audioContextType = type
    }
  },
  /* 群组id（用于指挥中心群组在发起对讲的时候高亮） */
  orgGroupIdFn(state, orgGroupId) {
    state.orgGroupId = orgGroupId
  }
}

const actions = {}

export default {
  state,
  getters,
  mutations,
  actions
}
