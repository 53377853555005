var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"w-full h-full p-1 box-border relative z-10",attrs:{"element-loading-text":"拼命加载中","element-loading-spinner":"el-icon-loading","element-loading-background":"rgba(0, 0, 0, 0.8)"}},[_c('audio',{ref:"audioEl",staticClass:"absolute",attrs:{"id":"audio-el"}}),_c('div',{ref:"chatMessageList",staticClass:"w-full h-full overflow-auto bg-backgroundv2 text-main text-xs",attrs:{"id":"chatMessageList"}},[(_vm.chatMessageList.length === 0)?_c('div',{staticClass:"w-full h-full flex box-border items-center justify-center text-center text-auxiliary"},[_c('div',{},[(
            _vm.orgGroupInsertTemp.groupNature === 1 &&
            _vm.orgGroupInsertTemp.openRecording !== 1
          )?_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"若想开启群组对讲记录，可去群组管理开启保存对讲记录","placement":"top"}},[_c('span',{staticClass:"!text-theme fs_12"},[_c('svg-icon',{attrs:{"svg-name":"help","class-name":"svgBox !w-[12px] !h-[12px] !ml-1"}}),_vm._v(" 帮助 ")],1)]):_vm._e(),_c('div',[_c('svg-icon',{attrs:{"svg-name":"no_intercom_record","className":"svgBox !w-[160px] !h-[160px]"}})],1),_c('div',{staticClass:"mt-5"},[(_vm.orgGroupInsertTemp.openRecording === 1)?_c('div',[_vm._v(" 暂无对讲记录 ")]):_c('div',[_c('p',[_vm._v(" "+_vm._s(_vm.orgGroupInsertTemp.groupNature === 1 ? '该群组未开启对讲记录' : '临时群组未开启对讲记录')+" ")]),_c('p',[_vm._v("通过下方按钮进行对讲操作")])])])],1)]):_vm._e(),_vm._l((_vm.chatMessageList),function(item,index){return _c('div',{key:item.id,staticClass:"p-2 box-border flex flex-col items-center",attrs:{"id":`item-${item.id}`}},[(
          item.fileDtmEnd.split(' ')[0] !==
          (index > 0 && _vm.chatMessageList[index - 1].fileDtmEnd.split(' ')[0])
        )?_c('div',{staticClass:"min-w-[72px] p-1 bg-backgroundv4 text-center rounded mb-2"},[_vm._v(" "+_vm._s(item.fileDtmEnd.split(' ')[0])+" ")]):_vm._e(),(item.devUserId === _vm.userInfo.id)?_c('div',{staticClass:"h-[50px] flex items-center w-full"},[_c('div',{staticClass:"mr-2 flex-grow w-0 flex flex-col items-end"},[_c('div',[_vm._v(" "+_vm._s(item.devUserName)+" ("+_vm._s(item.serialNum || item.devUserId)+") ")]),_c('div',{staticClass:"w-full flex items-center justify-end"},[(_vm.mediaFileId === item.id && _vm.audioType)?_c('div',{staticClass:"mr-1 mt-1 rounded-[4px] p-[4px_8px] bg-backgroundv305 text-auxiliary text-xs scale-90 clickBox",on:{"click":function($event){return _vm.getAudioContext(item.id)}}},[_vm._v(" 继续播放 ")]):_vm._e(),_c('div',{staticClass:"max-w-[70%] h-[28px] flex items-center p-[6px] box-border mt-1 rounded-[4px_0_4px_4px] justify-between relative z-10 clickBox border border-border shadow-[0_0_4px_0] shadow-background",class:{
                '!border-theme !shadow-theme': _vm.mediaFileId === item.id
              },style:({
                width:
                  item.audioLength >= 60
                    ? '70%'
                    : `calc(${(item.audioLength / 60) * 90}px + 160px)`,
                background:
                  _vm.mediaFileId === item.id
                    ? 'var(--background-gradientV2)'
                    : 'var(--backgroundv3)'
              }),on:{"click":function($event){return _vm.getAudioContext(item.id)}}},[_c('div',{staticClass:"font-bold flex-shrink-0"},[_vm._v(" "+_vm._s(item.audioLength)+"” ")]),_c('div',{staticClass:"flex-shrink-0 ml-1"},[(item.fileDtmEnd)?_c('span',[_vm._v(_vm._s(item.fileDtmEnd.split(' ')[1]))]):_vm._e()])])])]),_c('userHeadPortrait',{staticClass:"point point_dot",attrs:{"width":"32px","height":"32px","audioContextType":_vm.mediaFileId === item.id,"iconType":false,"device":{
            ...item,
            onlineState: 1
          }}})],1):_c('div',{staticClass:"h-[50px] flex items-center w-full"},[_c('userHeadPortrait',{staticClass:"point point_dot",attrs:{"width":"32px","height":"32px","audioContextType":_vm.mediaFileId === item.id,"iconType":false,"device":{
            ...item,
            onlineState: 1
          }}}),_c('div',{staticClass:"ml-2 flex-grow w-0 flex flex-col items-start"},[_c('div',[_vm._v(" "+_vm._s(item.devUserName)+" ("+_vm._s(item.serialNum || item.devUserId)+") ")]),_c('div',{staticClass:"w-full flex items-center"},[_c('div',{staticClass:"max-w-[70%] h-[28px] flex items-center p-[6px] box-border mt-1 rounded-[0_4px_4px] justify-between relative z-10 clickBox border border-border shadow-[0_0_4px_0] shadow-background",class:{
                '!border-theme !shadow-theme': _vm.mediaFileId === item.id
              },style:({
                width:
                  item.audioLength >= 60
                    ? '70%'
                    : `calc(${(item.audioLength / 60) * 90}px + 160px)`,
                background:
                  _vm.mediaFileId === item.id
                    ? 'var(--background-gradientV2)'
                    : 'var(--backgroundv3)'
              }),on:{"click":function($event){return _vm.getAudioContext(item.id)}}},[_c('div',{staticClass:"flex-shrink-0 mr-1"},[(item.fileDtmEnd)?_c('span',[_vm._v(_vm._s(item.fileDtmEnd.split(' ')[1]))]):_vm._e()]),_c('div',{staticClass:"font-bold flex-shrink-0"},[_vm._v(" "+_vm._s(item.audioLength)+"” ")])]),(_vm.mediaFileId === item.id && _vm.audioType)?_c('div',{staticClass:"ml-1 mt-1 rounded-[4px] p-[4px_8px] bg-backgroundv305 text-auxiliary text-xs scale-90 clickBox",on:{"click":function($event){return _vm.getAudioContext(item.id)}}},[_vm._v(" 继续播放 ")]):_vm._e()])])],1)])})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }