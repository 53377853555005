<!-- SOS 文件详情 -->
<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    class="dialog_clsss"
    top="6vh"
    custom-class="w-[1200px]"
  >
    <div class="deleteBox c_fff">
      <div class="pos_r dialog_header">
        <span>SOS文件查看</span>
        <div class="imgPictureChange clickBox closeBox" @click="onClose">
          <svg-icon
            :svg-name="'delete_hover'"
            class-name="!w-[26px] !h-[26px]"
          />
          <svg-icon :svg-name="'close'" class-name="!w-[26px] !h-[26px]" />
        </div>
      </div>
      <div class="dialog_body">
        <div class="h-[70vh] overflow-auto">
          <div v-if="contentType === 1">
            <div class="group-box c-f">
              <div class="group-item c-f-i c-f">
                <div class="c-f-i group-content r-f m-0">
                  <!-- 音频文件 -->
                  <div
                    @click="curMediaTypeFn(2)"
                    class="item-content r-f-i c-f pos_r"
                  >
                    <div class="w_100vh h_100vh centerBox ovh pos_r">
                      <div>
                        <div class="icon-mic c-f-i">
                          <svg-icon
                            svg-name="menu_micphone"
                            className="svgBox"
                          />
                        </div>
                        <div class="h_20"></div>
                      </div>
                      <div
                        class="file-name c-f-i pos_a w_100vh h_40"
                        style="bottom: 8px"
                      >
                        <p class="itemBox">
                          共
                          <span class="selectBox">{{
                            fileNum.audioNum || 0
                          }}</span>
                          个音频文件
                        </p>
                      </div>
                    </div>
                  </div>
                  <!-- 视频文件 -->
                  <div
                    @click="curMediaTypeFn(3)"
                    class="item-content r-f-i c-f pos_r"
                  >
                    <div class="w_100vh h_100vh centerBox ovh pos_r">
                      <div>
                        <div class="icon-mic c-f-i">
                          <svg-icon svg-name="menu_video" className="svgBox" />
                        </div>
                        <div class="h_20"></div>
                      </div>
                      <div
                        class="file-name c-f-i pos_a w_100vh h_40"
                        style="bottom: 8px"
                      >
                        <p class="itemBox">
                          共
                          <span class="selectBox">{{
                            fileNum.videoNum || 0
                          }}</span>
                          个视频文件
                        </p>
                      </div>
                    </div>
                  </div>
                  <!-- 图片文件 -->
                  <div
                    @click="curMediaTypeFn(4)"
                    class="item-content r-f-i c-f pos_r"
                  >
                    <div class="w_100vh h_100vh centerBox ovh pos_r">
                      <div>
                        <div class="icon-mic c-f-i">
                          <svg-icon
                            svg-name="menu_micphone"
                            className="svgBox"
                          />
                        </div>
                        <div class="h_20"></div>
                      </div>
                      <div
                        class="file-name c-f-i pos_a w_100vh h_40"
                        style="bottom: 8px"
                      >
                        <p class="itemBox">
                          共
                          <span class="selectBox">{{
                            fileNum.imgNum || 0
                          }}</span>
                          个图片文件
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="contentType === 2" class="h_100vh">
            <div
              v-for="(item, index) in folderList"
              :key="index"
              class="group-box c-f mb-[20px]"
            >
              <div class="group-item">
                <div class="c-f-i group-header c-f-i">
                  <div class="group-line r-f-i"></div>
                  <div class="group-label">{{ item.dateStr }}</div>
                  <div class="group-line r-f-i"></div>
                </div>
                <div
                  v-for="fileItem in item.fileList"
                  :key="fileItem.id"
                  @click="clickGroupItem(item.fileList, fileItem)"
                >
                  <!-- fileType  1图片 4音频 2视频 (无复选框)-->
                  <div
                    v-if="fileItem.fileType === 1"
                    class="c-f-i group-content r-f"
                  >
                    <div
                      :class="{ checkBox: fileItem.check }"
                      class="group-content-item"
                    >
                      <div
                        v-if="fileItem.filePath && fileItem.del !== 1"
                        class="icon-box ovh"
                        style="flex-grow: 1; height: 0"
                      >
                        <img :src="fileItem.filePath" class="h_100vh" />
                      </div>
                      <div v-else style="flex-grow: 1">
                        <div class="icon-box c-f-i">
                          <svg-icon
                            svg-name="mediaCenter_rightRipple"
                            className="ripple-svg"
                          />
                          <svg-icon
                            svg-name="menu_image"
                            className="svgBox my-0 mx-1"
                          />
                          <svg-icon
                            svg-name="mediaCenter_leftRipple"
                            className="ripple-svg"
                          />
                        </div>
                      </div>
                      <div class="item-name h_40 c-f-i centerBox">
                        <div
                          class="over_two_lines"
                          :class="{ fileDel: fileItem.del === 1 }"
                        >
                          {{ fileItem.fileName }}
                        </div>
                      </div>
                      <div class="item-information fs_12 mt_10">
                        <div
                          v-if="fileItem.type === 1"
                          class="information-type"
                        >
                          日常
                        </div>
                        <div
                          v-else-if="fileItem.type === 2"
                          class="information-type border border-theme"
                        >
                          任务
                        </div>
                        <div
                          v-else-if="fileItem.type === 3"
                          style="background: var(--warning)"
                          class="information-type fw_600"
                        >
                          SOS
                        </div>
                        <ul>
                          <li v-if="fileItem.hadPoint" class="c_9DA7B2">点</li>
                          <li v-if="fileItem.hadRemark">注</li>
                          <svg-icon
                            v-if="fileItem.mark"
                            svg-name="mediaCenter_markFile"
                            className="svgBox w-[22px] h-[22]px ml-1"
                          />
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div
                    v-else-if="fileItem.fileType === 4"
                    class="c-f-i group-content r-f"
                  >
                    <div
                      :class="{ checkBox: fileItem.check }"
                      class="group-content-item"
                    >
                      <div style="flex-grow: 1">
                        <div class="icon-box c-f-i">
                          <svg-icon
                            svg-name="mediaCenter_rightRipple"
                            className="ripple-svg"
                          />
                          <svg-icon
                            svg-name="menu_micphone_v2"
                            className="svgBox"
                          />
                          <svg-icon
                            svg-name="mediaCenter_leftRipple"
                            className="ripple-svg ml-[4px]"
                          />
                        </div>
                      </div>
                      <div class="item-name h_40 c-f-i centerBox">
                        <div
                          class="over_two_lines"
                          :class="{ fileDel: fileItem.del === 1 }"
                        >
                          {{ fileItem.fileName }}
                        </div>
                      </div>
                      <div class="item-information fs_12 mt_10">
                        <div
                          v-if="fileItem.type === 1"
                          class="information-type"
                        >
                          日常
                        </div>
                        <div
                          v-else-if="fileItem.type === 2"
                          class="information-type border border-theme"
                        >
                          任务
                        </div>
                        <div
                          v-else-if="fileItem.type === 3"
                          style="background: var(--warning)"
                          class="information-type fw_600"
                        >
                          SOS
                        </div>
                        <ul>
                          <li v-if="fileItem.hadPoint" class="c_9DA7B2">点</li>
                          <li v-if="fileItem.hadRemark">注</li>
                          <svg-icon
                            v-if="fileItem.mark"
                            svg-name="mediaCenter_markFile"
                            className="svgBox w-[22px] h-[22]px ml-1"
                          />
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div
                    v-else-if="fileItem.fileType === 2"
                    class="c-f-i group-content r-f"
                  >
                    <div
                      :class="{ checkBox: fileItem.check }"
                      class="group-content-item"
                    >
                      <div
                        v-if="fileItem.screenshot1"
                        class="icon-box ovh"
                        style="flex-grow: 1; height: 0"
                      >
                        <img :src="fileItem.screenshot1" class="h_100vh" />
                      </div>
                      <div v-else style="flex-grow: 1">
                        <div class="icon-box c-f-i">
                          <svg-icon
                            svg-name="mediaCenter_rightRipple"
                            className="ripple-svg"
                          />
                          <svg-icon
                            svg-name="menu_video"
                            className="svgBox my-0 mx-1"
                          />
                          <svg-icon
                            svg-name="mediaCenter_leftRipple"
                            className="ripple-svg"
                          />
                        </div>
                      </div>
                      <div class="item-name h_40 c-f-i centerBox">
                        <div
                          class="over_two_lines"
                          :class="{ fileDel: fileItem.del === 1 }"
                        >
                          {{ fileItem.fileName }}
                        </div>
                      </div>
                      <div class="item-information fs_12 mt_10">
                        <div
                          v-if="fileItem.type === 1"
                          class="information-type"
                        >
                          日常
                        </div>
                        <div
                          v-else-if="fileItem.type === 2"
                          class="information-type border border-theme"
                        >
                          任务
                        </div>
                        <div
                          v-else-if="fileItem.type === 3"
                          style="background: var(--warning)"
                          class="information-type fw_600"
                        >
                          SOS
                        </div>
                        <ul>
                          <li v-if="fileItem.hadPoint" class="c_9DA7B2">点</li>
                          <li v-if="fileItem.hadRemark">注</li>
                          <svg-icon
                            v-if="fileItem.mark"
                            svg-name="mediaCenter_markFile"
                            className="svgBox w-[22px] h-[22]px ml-1"
                          />
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="centerBox h_100vh" v-if="folderList.length === 0">
              <div class="tac">
                <svg-icon
                  svg-name="noData"
                  className="svgBox !w-[50px] !h-[50px]"
                />
                <div class="c_9DA7B2 fs_14 centerBox mt_10">暂无数据</div>
              </div>
            </div>
          </div>
        </div>
        <div class="centerBox mt_20">
          <el-button
            size="small"
            class="button_default buttonBox"
            @click="onClose"
            >关闭弹窗</el-button
          >
          <!--          <el-button v-if="contentType === 2"-->
          <!--            size="small" class="button_function buttonBox" @click="contentType = 1">返回上級</el-button>-->
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      folderList: [],
      warningRecordId: null,
      curMediaType: null,
      contentType: 1,
      policeCaseDetails: {},
      fileNum: {},
      dialogVisible: false
    }
  },
  components: {},
  mounted() {},
  methods: {
    clickGroupItem(playList = [], curItem) {
      if (curItem.del === 1)
        return this.$message.error('文件已经删除，无法查看')
      playList = playList.filter((v) => v.del !== 1)
      if (this.mainStore) {
        this.mainStore.commit('callComponent', {
          name: 'filePlayback',
          data: {
            methodName: 'show',
            params: {
              lowestLevel: true,
              curItem,
              playList: playList,
              curMediaType: this.curMediaType
            }
          }
        })
      } else {
        this.$store.commit('callComponent', {
          name: 'filePlayback',
          data: {
            methodName: 'show',
            params: {
              lowestLevel: true,
              curItem,
              playList: playList,
              curMediaType: this.curMediaType
            }
          }
        })
      }
    },
    curMediaTypeFn(type) {
      this.curMediaType = type
      let fileType = null
      // [文件类型]{1:图片;2:视频;3:对讲记录;4:音频;}
      if (type === 2) {
        fileType = 4
      } else if (type === 3) {
        fileType = 2
      } else if (type === 4) {
        fileType = 1
      }
      this.$api.warningRecord
        .fileGroupByDate({
          warningRecordId: this.warningRecordId,
          fileType: fileType
        })
        .then((res) => {
          if (res.code === 200) {
            this.folderList = res.data
            this.$nextTick(() => {
              this.contentType = 2
            })
          }
        })
    },
    fileNumCal() {
      this.$api.warningRecord.fileNumCal(this.warningRecordId).then((res) => {
        if (res.code === 200) {
          this.fileNum = res.data
        }
      })
    },
    show(warningRecordId) {
      this.warningRecordId = warningRecordId
      this.curMediaType = null
      // this.contentType = 1
      this.folderList = []
      this.$nextTick(() => {
        this.contentType = 2
        // this.fileNumCal()
        this.curMediaTypeFn(3)
        this.dialogVisible = true
      })
    },
    onClose() {
      this.policeCaseDetails = null
      this.dialogVisible = false
    }
  }
}
</script>
<style scoped lang="less" rel="stylesheet/less">
.group-box {
  margin: 0 auto;
  width: 100%;
  .group-item {
    .selectAll {
      font-size: 14px;
      color: var(--main);
      cursor: pointer;
      display: flex;
      align-items: center;
    }
    .selectAll_select {
      color: var(--theme);
    }
    .checkboxBox {
      position: absolute;
      top: 6px;
      right: 10px;
      color: var(--main);
      z-index: 1;
    }
    .statistics {
      font-size: 14px;
      color: var(--auxiliary);
      font-weight: bold;
      line-height: 10px;
      margin-bottom: 10px;
      .num {
        color: var(--theme);
      }
    }
    .group-header {
      justify-content: center;
      align-items: center;
      .group-label {
        font-weight: bold;
        color: var(--main);
        font-size: 16px;
        padding: 0 12px;
      }
      .group-line {
        background: var(--border-auxiliary);
        height: 2px;
        flex: 1;
      }
    }
    .group-content {
      flex-wrap: wrap;
      color: var(--auxiliary);
      .group-content-item {
        position: relative;
        margin: 0 10px 12px 0;
        width: 176px;
        height: 192px;
        background: var(--backgroundv3);
        border: 2px solid var(--backgroundv3);
        border-radius: 12px;
        padding: 8px;
        box-sizing: border-box;
        font-size: 14px;
        cursor: pointer;
        transition: 0.5s;
        display: flex;
        flex-direction: column;
        .icon-box {
          width: 100%;
          height: 100%;
          border-radius: 6px;
          background: var(--backgroundv2);
          justify-content: center;
          align-items: center;
          display: flex;
          .svgBox {
            width: 40px;
            height: 40px;
          }
        }
        .item-name {
          margin-top: 8px;
          font-weight: bold;
          color: var(--main);
        }
        .item-information {
          display: flex;
          justify-content: space-between;
          color: var(--main);
          .information-type {
            min-width: 44px;
            height: 22px;
            background: var(--backgroundv2);
            border-radius: 4px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          ul {
            overflow: hidden;
            li {
              width: 22px;
              height: 22px;
              display: flex;
              align-items: center;
              justify-content: center;
              float: left;
              color: var(--auxiliary);
              background: var(--backgroundv2);
              border-radius: 11px;
              margin-left: 4px;
            }
          }
        }
        .item-id {
          margin-top: 2px;
          text-align: center;
          color: var(--auxiliary);
        }
        &:hover {
          box-shadow: 0 0 6px 0 var(--main-select-color);
          border: 1px solid var(--main-select-color);
        }
      }
      .checkBox {
        box-shadow: 0 0 6px 0 var(--main-select-color);
        border: 1px solid var(--main-select-color);
        color: var(--main);
      }
    }
  }
  .fileDel {
    color: var(--dangerv2) !important;
    text-decoration: line-through;
  }
}
.itemBox {
  display: flex;
  align-items: center;
  font-weight: bold;
  color: var(--main);
  .selectBox {
    color: var(--main-select-color);
    padding: 0 4px;
  }
}
.navigationBox {
  padding: 10px 0;
  font-size: 14px;
  color: var(--auxiliary);
  font-weight: bold;
  display: flex;
  align-items: center;
  span {
    max-width: 300px;
    transition: 0.4s;
    margin: 0 1px;
  }
  span:hover {
    color: #fff;
  }
}
</style>
