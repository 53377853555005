var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"headPortraitBox",style:({
    width: `${_vm.parseRem(_vm.width)}rem`,
    height: `${_vm.parseRem(_vm.height)}rem`,
    'border-color':
      _vm.device.onlineState === 1
        ? _vm.audioContextType
          ? 'var(--theme)'
          : _vm.device.gbDevice === 1
          ? '#FDE777'
          : 'var(--auxiliary)'
        : 'var(--info)'
  })},[(_vm.device.gbDevice === 1)?_c('div',{staticClass:"headPortrait centerBox ovh fw_600 fs_16",style:(_vm.device.onlineState === 1
        ? 'background:linear-gradient(180deg, #5A2120 0%, #C81F12 100%); color:#FDE777;'
        : 'background:var(--backgroundv2); color:var(--auxiliary);')},[_vm._v(" GB ")]):_c('div',{staticClass:"headPortrait centerBox ovh"},[(_vm.device.headImg)?_c('img',{attrs:{"src":_vm.device.headImg,"alt":"用户头像"}}):(_vm.device.onlineState === 1)?_c('svg-icon',{attrs:{"svg-name":_vm.initSvgName,"className":"svgBox"}}):_c('svg-icon',{attrs:{"svg-name":_vm.initSvgName,"className":"svgBox"}})],1),(_vm.iconType)?_c('span',{class:_vm.device.onlineState === 1
        ? 'bg-success shadow-[0_0_4px_0] shadow-success'
        : 'bg-info'}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }