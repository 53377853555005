/* 抽查检查 */
import editSpotCheck from '@components/spotCheck/editSpotCheck/editSpotCheck'
import spotCheckDetails from '@components/spotCheck/spotCheckDetails'

export default {
  data() {
    return {}
  },
  components: {
    editSpotCheck,
    spotCheckDetails
  },
  methods: {}
}
