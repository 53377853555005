import Axios from '@/http'

export default {
  /* 标记已读 */
  read(params) {
    return new Promise((resolve, reject) => {
      Axios.post('msgRead/read', params, { isJSON: true }).then((res) => {
        resolve(res)
      })
    })
  },
  /* 获取未读数量 */
  notReadCount() {
    return new Promise((resolve, reject) => {
      Axios.post('msgRead/notReadCount').then((res) => {
        resolve(res)
      })
    })
  }
}
