import { mapGetters } from 'vuex'

const faceDetection = () => import('@components/faceDetection/faceDetection')
export default {
  data() {
    return {
      newFaceEvents: false,
      faceRecognitionId: null,
      faceRecognitionWarningId: null
    }
  },
  computed: {
    ...mapGetters(['newFaceAlertReminder'])
  },
  created() {
    this.componentMethodList.push('faceDetection')
  },
  components: {
    faceDetection
  },
  destroyed() {
    this.$store.commit(
      'deleteUserOperationCallback',
      this.faceRecognitionWarningId
    )
  },
  mounted() {
    this.faceRecognitionWarningId = this.createUniqueId(1)
    this.faceRecognitionWarning()
  },
  methods: {
    /* 监听人脸报警 */
    faceRecognitionWarning() {
      this.$store.commit('addUserOperationCallback', {
        id: this.faceRecognitionWarningId,
        operationName: 'faceRecognitionWarning',
        callback: ({ faceRecognitionId }) => {
          this.$store.commit('faceUnreadContentListOperate', {
            type: 1,
            faceRecognitionId
          })
          // 需要判断报警是否打开，打开需要刷新
          this.$refs.faceDetection.faceAlertListGetData()
          if (this.newFaceAlertReminder === 1) {
            this.faceRecognitionId = faceRecognitionId
            this.faceAlertFn()
          }
        }
      })
    },
    /* 人脸报警 */
    faceAlertFn() {
      const close = () => {
        this.$refs.newMessageReminder.onClose('face')
      }
      const submit = () => {
        this.$refs.faceDetection.faceAlertListShow()
        this.$refs.faceDetection.faceAlertDetailsShow(this.faceRecognitionId)
        close()
      }
      this.$refs.newMessageReminder
        .show(
          {
            type: 'face',
            imgUrl: 'newCase.png',
            title: '人脸报警',
            contentSvgName: 'faceAler-ring',
            content: '有新的人脸报警',
            headerHeight: this.headerHeight
          },
          {
            faceRecognitionId: this.faceRecognitionId
          }
        )
        .then(submit)
        .catch(close)
    }
  }
}
