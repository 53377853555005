<!-- 的页面 -->
<template>
  <!--bg-background-->
  <div class="w-full h-full box-border text-main rounded-[6px] p-2">
    <div v-if="dataList.length !== 0" class="w-full h-full">
      <div class="mb-2 flex items-center px-2 box-border">
        {{ title }}
      </div>
      <el-select
        size="small"
        @change="channelSelect"
        v-model="selectedId"
        placeholder="请选择通道"
      >
        <el-option
          v-for="channeTtem in dataList"
          :key="channeTtem.id"
          :label="channeTtem.channelName"
          :value="channeTtem.channelId"
          :disabled="channeTtem.onlineState !== 1"
        >
          <el-tooltip
            v-delTabIndex
            :open-delay="500"
            class="channeTtem"
            effect="dark"
            :content="
              channeTtem.onlineState === 1
                ? '点击切换通道'
                : '该通道已离线，不可切换'
            "
            placement="right"
          >
            <div
              :class="{
                '!text-theme': channeTtem.channelId === selectedId,
                'text-success': channeTtem.onlineState === 1,
                'text-info': channeTtem.onlineState !== 1
              }"
              class="w-full flex css-text-no-select items-center"
            >
              <span
                :class="{
                  'bg-dangerv2': channeTtem.onlineState !== 1,
                  'bg-success': channeTtem.onlineState === 1
                }"
                class="w-[10px] h-[10px] rounded-full inline-block mr-2 flex-shrink-0"
              ></span>
              <!--class="flex-grow w-0 over_one_line"-->
              <div>
                {{ channeTtem.channelName }}
              </div>
            </div>
          </el-tooltip>
        </el-option>
      </el-select>
    </div>
    <div
      class="w-full h-full flex items-center justify-center mt-2"
      v-if="dataList.length === 0"
    >
      <div class="flex flex-col items-center text-auxiliary">
        <svg-icon svg-name="noData" className="svgBox !w-[40px] !h-[40px]" />
        <span class="mt-4">暂 无 数 据</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    deviceId: {
      type: String,
      default: () => {
        return null
      }
    },
    title: {
      type: String,
      default: () => {
        return '视频通道'
      }
    },
    channelType: {
      type: String,
      default: () => {
        return '1' // 通道类型（1视频通道、2报警通道、3语音通道）
      }
    },
    channelId: {
      type: String,
      default: () => {
        return null
      }
    },
    channelList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      selectedId: null,
      dataList: []
    }
  },
  created() {
    this.dataList = this.channelList || []
    this.selectedId = this.channelId
    if (this.deviceId) {
      this.filterByDeviceId([{ deviceId: this.deviceId }])
    }
  },
  methods: {
    // 根据设备ID 查找通道
    async filterByDeviceId(deviceList) {
      const { code, data = [] } = await this.$api.gbChannel.filterByDeviceId({
        channelType: this.channelType,
        deviceIdList: deviceList.map((v) => v.deviceId)
      })
      if (code === 200) {
        data.forEach((item) => {
          if (item.deviceId === this.deviceId) {
            this.dataList = item.channelList || []
            this.$emit('on-filterByDevice', item)
          }
        })
        this.$nextTick(() => {
          this.$emit('on-filterByDeviceList', data)
        })
      }
    },
    channelSelect() {
      const channeTtem = this.dataList.find(
        (v) => v.channelId === this.selectedId
      )
      if (!channeTtem) return
      if (channeTtem.onlineState !== 1) return
      this.$emit('on-select', { ...channeTtem, deviceId: this.deviceId })
    }
  }
}
</script>
<style scoped lang="less" rel="stylesheet/less"></style>
