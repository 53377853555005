/* 通道相关内容 */
import channelSelect from '@/components/device/channelSelect'
export default {
  data() {
    return {
      channelPpover: null // popover 开关
    }
  },
  components: {
    channelSelect
  },
  computed: {},
  created() {},
  methods: {
    /* 发起设备监控 */
    monitorFn(device) {
      if (device.deviceId) {
        if (
          device.gbDevice === 1 &&
          (!device.channelList || device.channelList === 0)
        ) {
          this.filterByDeviceId([device])
        } else {
          this.sendingMonitor(device)
        }
      }
    },
    // 根据设备ID 查找通道
    async filterByDeviceId(deviceList) {
      const { code, data = [] } = await this.$api.gbChannel.filterByDeviceId({
        channelType: 1,
        deviceIdList: deviceList.map((v) => v.deviceId)
      })
      if (code === 200) {
        data.forEach((item) => {
          const index = this.monitorDeviceList.findIndex(
            (v) => v.deviceId === item.deviceId
          )
          if (index !== -1) {
            this.$set(
              this.monitorDeviceList[index],
              'channelId',
              item.channelId
            )
            this.$set(
              this.monitorDeviceList[index],
              'channelName',
              item.channelName
            )
            this.$set(
              this.monitorDeviceList[index],
              'channelList',
              item.channelList
            )
            this.sendingMonitor(this.monitorDeviceList[index])
          }
        })
      }
    },
    // 设置选中的数据
    setSelectChanne(channeItem) {
      const clientIdIndex = this.monitorDeviceList.findIndex(
        (v) => v.deviceId === channeItem.deviceId
      )
      if (
        this.currentZoomIn &&
        this.currentZoomIn.deviceId === channeItem.deviceId
      ) {
        this.$set(this.currentZoomIn, 'channelId', channeItem.channelId)
        this.$set(this.currentZoomIn, 'channelName', channeItem.channelName)
      }
      if (clientIdIndex !== -1) {
        this.$set(
          this.monitorDeviceList[clientIdIndex],
          'channelId',
          channeItem.channelId
        )
        this.$set(
          this.monitorDeviceList[clientIdIndex],
          'channelName',
          channeItem.channelName
        )
        this.$nextTick(() => {
          this.reconnectFn(this.monitorDeviceList[clientIdIndex])
        })
      }
    },
    // 设置单个数据的通道信息
    setItemChanne(data) {
      const clientIdIndex = this.monitorDeviceList.findIndex(
        (v) => v.deviceId === data.deviceId
      )
      if (this.currentZoomIn && this.currentZoomIn.deviceId === data.deviceId) {
        this.$set(this.currentZoomIn, 'channelList', data.channelList)
      }
      if (clientIdIndex !== -1) {
        this.$set(
          this.monitorDeviceList[clientIdIndex],
          'channelList',
          data.channelList
        )
      }
    },
    // 通道弹窗开关
    setChannelPpover(channelPpover, type) {
      if (!type) {
        if (this.channelPpover === channelPpover) {
          this.channelPpover = null
        }
      } else {
        this.channelPpover = channelPpover
      }
    }
  }
}
