<!--  静默设置 -->
<template>
  <div class="percent-100">
    <el-dialog
      :visible.sync="aadDialogVisible"
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      class="dialog_clsss"
      custom-class="w-[580px]"
    >
      <div class="c_fff">
        <div class="pos_r dialog_header">
          <span>
            {{ title }}
          </span>
          <div class="imgPictureChange clickBox closeBox" @click="close">
            <svg-icon
              :svg-name="'delete_hover'"
              class-name="!w-[26px] !h-[26px]"
            />
            <svg-icon :svg-name="'close'" class-name="!w-[26px] !h-[26px]" />
          </div>
        </div>
        <div class="dialog_body">
          <div>
            <el-form
              label-position="top"
              :model="form"
              :rules="rules"
              ref="form"
              label-width="0px"
            >
              <el-row :gutter="30">
                <el-col :span="24">
                  <el-form-item prop="ruleName">
                    <div class="requiredTitle">
                      {{ $t('rules.ruleName') }}：<i>*</i>
                    </div>
                    <div class="d_ib w_70vh">
                      <el-input
                        :placeholder="`${this.$t(
                          'general.pleaseEnter'
                        )}${this.$t('rules.ruleName')}`"
                        @keyup.native="inputForbidden(form, 'ruleName')"
                        v-model="form.ruleName"
                      ></el-input>
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item prop="">
                    <div class="">{{ $t('rules.areaName') }}<i>*</i></div>
                    <div class="w-full flex">
                      <el-select
                        class="w-0 flex-grow"
                        :placeholder="`${this.$t(
                          'general.pleaseChoose'
                        )}${this.$t('rules.areaName')}`"
                        v-model="form.mapRailId"
                      >
                        <el-option
                          v-for="item in railMapList"
                          :key="item.id"
                          :label="item.railName"
                          :value="item.id"
                        >
                        </el-option>
                      </el-select>
                      <div>
                        <el-button
                          class="button_function ml-[20px] flex-shrink-0"
                          @click="addArea"
                          >{{ $t('rules.addMap') }}</el-button
                        >
                      </div>
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item prop="stopHour">
                    <div class="requiredTitle">
                      {{ $t('rules.standingTime') }}：<i>*</i>
                    </div>
                    <el-input
                      @keyup.native="enterTheMumber(form, 'stopHour')"
                      :placeholder="`${this.$t('general.pleaseEnter')}${this.$t(
                        'rules.standingTime'
                      )}`"
                      v-model="form.stopHour"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item required>
                    <div class="requiredTitle">执行时间：<i>*</i></div>
                    <div>
                      <el-checkbox
                        v-model="everydayJson.everyday"
                        @change="meitinaFn"
                        :disabled="everydayJson.everyday8"
                        >{{ $t('common.everyDay') }}
                      </el-checkbox>
                      <el-checkbox
                        v-model="everydayJson.everyday1"
                        :disabled="
                          everydayJson.everyday || everydayJson.everyday8
                        "
                      >
                        {{ $t('common.Monday') }}
                      </el-checkbox>
                      <el-checkbox
                        v-model="everydayJson.everyday2"
                        :disabled="
                          everydayJson.everyday || everydayJson.everyday8
                        "
                      >
                        {{ $t('common.Tuesday') }}
                      </el-checkbox>
                      <el-checkbox
                        v-model="everydayJson.everyday3"
                        :disabled="
                          everydayJson.everyday || everydayJson.everyday8
                        "
                      >
                        {{ $t('common.Wednesday') }}
                      </el-checkbox>
                      <el-checkbox
                        v-model="everydayJson.everyday4"
                        :disabled="
                          everydayJson.everyday || everydayJson.everyday8
                        "
                      >
                        {{ $t('common.Thursday') }}
                      </el-checkbox>
                      <el-checkbox
                        v-model="everydayJson.everyday5"
                        :disabled="
                          everydayJson.everyday || everydayJson.everyday8
                        "
                      >
                        {{ $t('common.Friday') }}
                      </el-checkbox>
                      <el-checkbox
                        v-model="everydayJson.everyday6"
                        :disabled="
                          everydayJson.everyday || everydayJson.everyday8
                        "
                      >
                        {{ $t('common.Saturday') }}
                      </el-checkbox>
                      <el-checkbox
                        v-model="everydayJson.everyday7"
                        :disabled="
                          everydayJson.everyday || everydayJson.everyday8
                        "
                      >
                        {{ $t('common.Sunday') }}
                      </el-checkbox>
                      <div>
                        <el-checkbox
                          v-model="everydayJson.everyday8"
                          :disabled="everydayJson.everyday"
                          @change="meitinaFn"
                          >{{ $t('common.appointedDay') }}
                        </el-checkbox>
                        <el-date-picker
                          v-if="everydayJson.everyday8"
                          v-model="everydayJson.time"
                          value-format="yyyy-MM-dd"
                          type="daterange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                        >
                        </el-date-picker>
                      </div>
                      <div class="mt_10">
                        <el-form-item prop="timeRange">
                          <el-time-picker
                            is-range
                            class="!w-full"
                            v-model="form.timeRange"
                            value-format="HH:mm:ss"
                            :range-separator="$t('general.to')"
                            :start-placeholder="$t('general.startDtm')"
                            :end-placeholder="$t('general.endDtm')"
                            :placeholder="$t('general.selectTimeRange')"
                          >
                          </el-time-picker>
                        </el-form-item>
                      </div>
                    </div>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
          <div class="tac mt_20">
            <el-button
              size="small"
              class="button_default buttonBox"
              @click="close"
              >关 闭</el-button
            >
            <el-button
              size="small"
              class="button_function buttonBox"
              @click="onSubmit"
              >保 存</el-button
            >
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="dialogVisible"
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      class="dialog_clsss_lightColor"
      custom-class="w-[800px]"
    >
      <div class="c_fff activatedImei">
        <div class="pos_r dialog_header">
          <span>电子围栏</span>
          <div class="imgPictureChange clickBox closeBox" @click="onClose">
            <svg-icon
              :svg-name="'delete_hover'"
              class-name="!w-[26px] !h-[26px]"
            />
            <svg-icon :svg-name="'close'" class-name="!w-[26px] !h-[26px]" />
          </div>
        </div>
        <div class="dialog_body h-[500px]">
          <electronicFence
            v-if="dialogVisible"
            ref="electronicFence"
          ></electronicFence>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
export default {
  data () {
    return {
      rules: {
        mapRailId: [
          { required: true, message: `${this.$t('general.pleaseChoose')}${this.$t('rules.areaName')}`, trigger: 'change' }
        ],
        ruleName: [
          { required: true, message: `${this.$t('general.pleaseEnter')}${this.$t('rules.ruleName')}`, trigger: 'blur' }
        ],
        timeRange: [
          { required: true, message: '请选择执行开始结束时间', trigger: 'change' }
        ],
        stopHour: [
          { required: true, message: `${this.$t('general.pleaseEnter')}${this.$t('rules.standingTime')}`, trigger: 'change' }
        ]
      },
      title: '',
      everydayJson: {
        everyday: false,
        everyday1: false,
        everyday2: false,
        everyday3: false,
        everyday4: false,
        everyday5: false,
        everyday6: false,
        everyday7: false,
        everyday8: false,
        time: null
      },
      form: {
        mapName: '',
        stopHour: '',
        mapRailId: null,
        timeRange: null
      },
      aadDialogVisible: false,
      dialogVisible: false,
      railMapList: []
    }
  },
  methods: {
    /* 地图 */
    addArea () {
      this.dialogVisible = true
    },
    onClose () {
      this.dialogVisible = false
      this.getMapRail()
    },
    getMapRail () {
      this.$api.findAll('mapRail').then(res => {
        this.railMapList = res.data
      })
    },
    /* = */
    show (row) {
      if (row && row.id) {
        this.$api.select('rule', row.id).then(res => {
          if (res.code === 200) {
            this.newlyAddedFn(res.data)
          }
        })
      } else {
        this.newlyAddedFn({})
      }
    },
    newlyAddedFn (row) {
      this.form = {
        ruleName: '',
        stopHour: '',
        timeRange: null
      }
      this.everydayJson = {
        everyday: false,
        everyday1: false,
        everyday2: false,
        everyday3: false,
        everyday4: false,
        everyday5: false,
        everyday6: false,
        everyday7: false,
        everyday8: false,
        time: null
      }
      this.getMapRail()
      this.title = row.id ? this.$t('report.editTheSedentarySettings') : this.$t('report.addTheSedentarySettings')
      this.aadDialogVisible = true
      if (row.id) {
        this.form = row
        this.everydayJson.everyday = row.ruleDateType === 1
        if (row.weekDate && row.ruleDateType === 2) {
          for (let i = 0; i < 7; i++) {
            const mun = 'everyday' + (i + 1)
            if (row.weekDate.indexOf(i + 1) > -1) {
              this.everydayJson[mun] = true
            }
          }
        }
        this.everydayJson.everyday8 = row.ruleDateType === 4
        if (row.apStartDtm && row.apEndDtm) {
          this.$set(this.form, 'apStartDtm', row.apStartDtm.split(' 00:00:00')[0])
          this.$set(this.form, 'apEndDtm', row.apEndDtm.split(' 00:00:00')[0])
          this.$set(this.everydayJson, 'time', [row.apStartDtm, row.apEndDtm])
        }
        this.$set(this.form, 'stopHour', this.form.stopHour || null)
        this.$set(this.form, 'timeRange', [this.form.startDtm1, this.form.endDtm1])
      }
      if (this.$refs.form) {
        this.$refs.form.resetFields()
      }
    },
    /* 执行时间全部关闭 */
    meitinaFn () {
      for (let i = 0; i < 7; i++) {
        const mun = 'everyday' + (i + 1)
        this.everydayJson[mun] = false
      }
    },
    onSubmit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.form.ruleDateType = null
          if (this.form.timeRange && this.form.timeRange.length !== 0) {
            this.form.startDtm1 = this.form.timeRange[0]
            this.form.endDtm1 = this.form.timeRange[1]
          }
          if (this.everydayJson.everyday) {
            this.form.ruleDateType = 1
          } else if (this.everydayJson.everyday8) {
            this.form.ruleDateType = 4
            if (!this.everydayJson.time) {
              this.$message.error('请选择指定时间')
              return false
            }
            this.$set(this.form, 'apStartDtm', this.everydayJson.time[0] + ' 00:00:00')
            this.$set(this.form, 'apEndDtm', this.everydayJson.time[1] + ' 00:00:00')
          } else {
            const arr = this.everydayJson
            const arr2 = []
            arr.everyday = false
            arr.everyday8 = false
            this.$set(this.form, 'apStartDtm', null)
            this.$set(this.form, 'apEndDtm', null)
            arr.time = null
            for (const key in this.everydayJson) {
              if (this.everydayJson[key]) {
                arr2.push(key.replace(/(^everyday)|(everyday$)/g, ''))
              }
            }
            if (arr2.length) {
              this.form.ruleDateType = 2
              this.form.weekDate = arr2.toString()
            }
          }
          if (!this.form.ruleDateType) {
            this.$message.error('请选择执行时间')
            return false
          }
          this.$api.save('rule', Object.assign({}, this.form, {
            type: 3
          })).then(() => {
            this.$emit('on-submit')
            this.$message.success('操作成功')
            this.aadDialogVisible = false
          })
        }
      })
    },
    close () {
      this.aadDialogVisible = false
    }
  },
  mounted () {
  }
}
</script>
