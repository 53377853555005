import { render, staticRenderFns } from "./casesQuery.vue?vue&type=template&id=32878c20&scoped=true"
import script from "./casesQuery.vue?vue&type=script&lang=js"
export * from "./casesQuery.vue?vue&type=script&lang=js"
import style0 from "./casesQuery.vue?vue&type=style&index=0&id=32878c20&prod&scoped=true&lang=less&rel=stylesheet%2Fless"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32878c20",
  null
  
)

export default component.exports