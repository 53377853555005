<!-- 短信内容 -->
<template>
  <div class="h-full flex flex-col">
    <div class="flex-grow h-0">
      <el-table
        height="100%"
        :data="tableData"
        v-el-table-infinite-scroll="load"
        class="pos_r w-full z-[1] mt-1"
      >
        <el-table-column label="短信内容">
          <template slot-scope="{ row }">
            <div class="lh_20 p-[4px_0]">{{ row.content }}</div>
            <!-- 图片 -->
            <ul
              v-if="row.fileType === 1"
              class="fileListBox css-text-no-select"
            >
              <li v-for="(item, index) in row.imagesList" :key="index">
                <div class="imgBox">
                  <div class="centerBox deleteBox">
                    <iconBox
                      @click="onShowViewer(item)"
                      imgName="see"
                      altText="查 看"
                    ></iconBox>
                  </div>
                  <img :src="item" alt="" />
                </div>
              </li>
            </ul>
            <!-- 视频 -->
            <ul
              v-if="row.fileType === 2"
              class="fileListBox css-text-no-select"
            >
              <li>
                <div style="background: #0d2035" class="imgBox">
                  <div class="centerBox deleteBox">
                    <iconBox
                      @click="videoPlayerShow(row)"
                      imgName="see"
                      altText="查 看"
                    ></iconBox>
                  </div>
                  <div class="pos_a w_100vh centerBox">
                    <svg-icon
                      svg-name="videoCover"
                      class-name="!w-[40px] !h-[40px]"
                    />
                  </div>
                </div>
              </li>
            </ul>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="160px">
          <template slot-scope="{ row }">
            <iconBox
              @click="choiceFn(row)"
              imgName="icon_choice"
              altText="选 择"
            ></iconBox>
            <iconBox
              @click="deleteShow(row.id)"
              classNmae="shadow_delete"
              imgName="delete"
              altText="删 除"
            ></iconBox>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 预览图片 -->
    <imageviewer
      v-if="imageviewerType"
      style="z-index: 9999"
      ref="imageviewer"
      :urlList="imageviewerUrl"
      :onClose="imageviewerClose"
      :zIndex="0"
    >
    </imageviewer>
    <el-dialog
      :visible.sync="videoDialogVisible"
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      class="dialog_clsss_lightColor"
      custom-class="w-[800px]"
    >
      <div class="c_fff activatedImei">
        <div class="pos_r dialog_header">
          视频查看
          <div
            class="imgPictureChange clickBox closeBox"
            @click="videoDialogClose"
          >
            <svg-icon
              svg-name="delete_hover"
              class-name="!w-[26px] !h-[26px]"
            />
            <svg-icon svg-name="close" class-name="!w-[26px] !h-[26px]" />
          </div>
        </div>
        <div class="dialog_body h-[500px]">
          <div class="pos_r w_100vh h_100vh">
            <videoPlayer
              borderColor="transparent"
              ref="videoPlayer"
            ></videoPlayer>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import videoPlayer from '@components/common/videoPlayer'
import imageviewer from '@/components/common/image/src/image-viewer.vue'
import elTableInfiniteScroll from 'el-table-infinite-scroll'
export default {
  directives: {
    'el-table-infinite-scroll': elTableInfiniteScroll
  },
  data() {
    return {
      screen: {
        page: -1,
        totalPages: null,
        size: 20,
        queryDtm: null,
        query: null
      },
      lockHead: false,
      tableData: [],
      imageviewerType: false, // 查看大图
      imageviewerUrl: [], // 查看大图list
      videoDialogVisible: false
    }
  },
  components: {
    imageviewer,
    videoPlayer
  },
  mounted() {
    this.screenFn()
  },
  methods: {
    deleteShow(id) {
      this.$deleteConfirmationBox(
        {
          deleteTitle: '删除固定消息',
          deleteContent: '<div><p>确认删除当前固定消息？</p></div>',
          submitText: '确 定'
        },
        () => {
          this.$api.deleteBatch('deviceMsg/template', id).then((res) => {
            if (res.code === 200) {
              this.handleClick()
            }
          })
        }
      )
    },
    /* 选中 */
    choiceFn(row) {
      this.$emit('on-choice', row)
    },
    videoPlayerShow(item) {
      this.videoDialogVisible = true
      this.$nextTick(() => {
        this.$refs.videoPlayer.playVideo(item.fileUrls, {
          ...item,
          id: item.id || 1,
          filePath: item.fileUrls
        })
      })
    },
    videoDialogClose() {
      if (this.$refs.videoPlayer) {
        this.$refs.videoPlayer.pauseVideo()
      }
      this.videoDialogVisible = false
    },
    // 打开大图展示
    onShowViewer(image) {
      this.imageviewerType = true
      this.imageviewerUrl = []
      this.imageviewerUrl.push(image)
    },
    // 关闭大图查看
    imageviewerClose() {
      this.imageviewerType = false
    },
    handleClick() {
      this.screen = {
        page: -1,
        totalPages: null,
        size: 20,
        queryDtm: null,
        query: null
      }
      if (this.map) {
        this.map.setZoom(11)
        this.map.panTo([this.userInfo.lng, this.userInfo.lat])
      }
      this.screenFn()
    },
    screenFn() {
      if (this.lockHead) {
        return
      }
      this.$set(this.screen, 'page', 0)
      this.$set(this.screen, 'totalPages', null)
      this.tableData = []
      this.$nextTick(() => {
        this.getData()
      })
    },
    load() {
      if (this.lockHead) {
        return
      }
      if (
        this.screen.totalPages &&
        this.screen.totalPages <= this.screen.page + 1
      ) {
        return
      }
      const num = this.screen.page + 1
      this.$set(this.screen, 'page', num)
      this.getData()
    },
    getData() {
      this.lockHead = true
      const obj = {
        ...this.screen
      }
      if (this.screen.queryDtm && this.screen.queryDtm.length) {
        obj.queryStartDtm = this.screen.queryDtm[0]
        obj.queryEndDtm = this.screen.queryDtm[1]
      } else {
        obj.queryStartDtm = null
        obj.queryEndDtm = null
      }
      this.$api
        .page('deviceMsg/template', {
          ...obj
        })
        .then((res) => {
          if (res.code === 200) {
            const content = res.data.content
            this.tableData.push(
              ...content.map((v) => {
                let imagesList = []
                if (v.fileUrls) {
                  imagesList = v.fileUrls.split(',')
                }
                return {
                  ...v,
                  imagesList
                }
              })
            )
            this.$set(this.screen, 'totalPages', res.data.totalPages)
            this.$nextTick(() => {
              this.lockHead = false
            })
          }
        })
    }
  }
}
</script>
<style scoped lang="less" rel="stylesheet/less">
ul.fileListBox {
  overflow: hidden;
  margin: 0 -10px;
  li:hover {
    box-shadow: 0 0 10px 0 #a7ddfc;
    .deleteBox {
      z-index: 1;
      display: flex;
    }
  }
  li {
    margin: 4px 8px 4px;
    padding: 2px;
    float: left;
    width: 80px;
    height: 80px;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: var(--dialog-box-shadow);
    .uploadFile {
      width: 80px;
      height: 80px;
    }
    .deleteBox {
      width: 100%;
      height: 100%;
      position: relative;
      transition: 1s;
      display: none;
      background: var(--item-background-auxiliary-color-05);
    }
    .imgBox {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 7px;
      overflow: hidden;
      border: 1px solid #a7ddfc;
      img {
        position: absolute;
        height: 100%;
      }
    }
  }
}
</style>
