<!-- 地图搜索地方 -->
<template>
  <div
    style="flex-direction: column"
    class="search_lightColor pos_r w-[340px] inline-block"
  >
    <div v-show="false" class="w-[40px] h-[60px]"></div>
    <div class="r-f">
      <div class="r-f flex-grow w-0">
        <el-select
          size="small"
          @change="searchAddressTypeFn"
          class="!w-[37%] box-border border rounded-[6px] flex-shrink-0"
          style="border-color: var(--input-border)"
          v-model="searchAddressType"
          placeholder="请选择"
        >
          <el-option label="地理地址" :value="1"></el-option>
          <!--          <el-option label="水源点" :value="2"></el-option>-->
          <!--          <el-option label="防火单位" :value="3"></el-option>-->
          <el-option label="部门名称" :value="4"></el-option>
          <el-option label="查经纬度" :value="5"></el-option>
          <el-option label="查巡查点" :value="6"></el-option>
        </el-select>
        <el-input
          v-if="searchAddressType !== 5"
          size="small"
          clearable
          class="box-border rounded-[6px] ml-[4px] border"
          style="border-color: var(--input-border)"
          @input="throttle(resetFn, 1000)()"
          v-model="searchAddress"
          :placeholder="placeholder"
        ></el-input>
        <!-- 经纬度 -->
        <el-input
          size="small"
          clearable
          maxlength="20"
          v-if="searchAddressType === 5"
          @input="lnglatCheckFn('lng')"
          class="box-border rounded-[6px] ml-[4px] border"
          style="border-color: var(--input-border)"
          v-model="lnglat.lng"
          placeholder="经度"
        ></el-input>
        <el-input
          size="small"
          clearable
          v-if="searchAddressType === 5"
          maxlength="20"
          @input="lnglatCheckFn('lat')"
          class="box-border rounded-[6px] ml-[4px] border"
          style="border-color: var(--input-border)"
          v-model="lnglat.lat"
          placeholder="纬度"
        ></el-input>
      </div>
      <div class="searchBox centerBox flex-shrink-0 flex ml-1" @click="resetFn">
        <svg-icon :svg-name="searchSvgName" />
      </div>
    </div>
    <div v-show="searchAddressType === 5">
      <div
        v-for="(lnglatItem, lnglatIndex) in lnglatList"
        :key="lnglatIndex"
        @click="lnglatClickFn(lnglatItem.lng, lnglatItem.lat)"
        class="r-f fs_12 c_9DA7B2 lh_20 lnglat_box clickBox"
      >
        <svg-icon
          class="flex-shrink !w-[26px] !h-[26px]"
          svg-name="maplocation"
        />
        <div class="ml_10 f-g">
          <div class="title fs_14 c_fff over_one_line fw_600">
            {{ lnglatItem.titleName }}
          </div>
          <div class="fs_12 c_9DA7B2">
            <div>
              经度：
              <span v-show="!lnglat.lngType && lnglatItem.lng" class="warning"
                >内容错误</span
              >
              <span v-show="lnglat.lngType || !lnglatItem.lng">{{
                lnglatItem.lng || '请输入经度'
              }}</span>
            </div>
            <div>
              纬度：
              <span v-show="!lnglat.latType && lnglatItem.lat" class="warning"
                >内容错误</span
              >
              <span v-show="lnglat.latType || !lnglatItem.lat">{{
                lnglatItem.lat || '请输入经度'
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <virtual-list
      v-if="searchResultList.length"
      v-infinite-scroll="load"
      infinite-scroll-disabled="disabled"
      class="filterContentList h-[400px] overflow-y-auto"
      :data-key="'id'"
      :data-sources="searchResultList"
      :data-component="mapAearchPlaceItem"
      :keeps="10"
      :extra-props="{
        color: colorObject.success,
        mapJumpLocationFn: mapJumpLocationFn,
        searchAddressType: searchAddressType
      }"
      :estimate-size="88"
    />
    <p v-if="loading" class="c_fff centerBox fs_12">加载中...</p>
    <p v-if="noMore" class="c_fff centerBox fs_12">没有更多了</p>
  </div>
</template>

<script type="text/ecmascript-6">
import mapAearchPlace from './js/index.js'
export default {
  mixins: [mapAearchPlace],
  destroyed () {
    if (this.map && this.map.destroy) {
      this.map.destroy()
    }
  },
  data () {
    return {}
  },
  components: {
  },
  methods: {
  }
}
</script>

<style scoped lang="less" rel="stylesheet/less">
.filterContentList {
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  border-top: 1px solid var(--backgroundv2);
  margin-top: 6px;
  box-sizing: border-box;
}
.lnglat_box {
  &:hover {
    border-radius: 4px;
    background: var(--backgroundv3);
  }
  padding: 10px;
  align-items: center;
  margin-top: 10px;
  .warning {
    color: var(--dangerv2);
  }
}
.title {
  line-height: 28px;
  height: 28px;
}
</style>
