<!-- 用户头像 -->
<template>
  <!--  <div v-show="false" class=""></div>-->
  <!--  :class="`h-[${height}] w-[${width}]`"-->
  <div
    :style="{
      width: `${parseRem(width)}rem`,
      height: `${parseRem(height)}rem`,
      'border-color':
        device.onlineState === 1
          ? audioContextType
            ? 'var(--theme)'
            : device.gbDevice === 1
            ? '#FDE777'
            : 'var(--auxiliary)'
          : 'var(--info)'
    }"
    class="headPortraitBox"
  >
    <div
      v-if="device.gbDevice === 1"
      :style="
        device.onlineState === 1
          ? 'background:linear-gradient(180deg, #5A2120 0%, #C81F12 100%); color:#FDE777;'
          : 'background:var(--backgroundv2); color:var(--auxiliary);'
      "
      class="headPortrait centerBox ovh fw_600 fs_16"
    >
      GB
    </div>
    <div v-else class="headPortrait centerBox ovh">
      <img v-if="device.headImg" :src="device.headImg" alt="用户头像" />
      <svg-icon
        v-else-if="device.onlineState === 1"
        :svg-name="initSvgName"
        className="svgBox"
      />
      <svg-icon v-else :svg-name="initSvgName" className="svgBox" />
    </div>
    <span
      v-if="iconType"
      :class="
        device.onlineState === 1
          ? 'bg-success shadow-[0_0_4px_0] shadow-success'
          : 'bg-info'
      "
    ></span>
  </div>
</template>

<script>
import { pxTorem } from '@utils/adaptive'
export default {
  props: {
    initSvgName: {
      type: String,
      default: () => {
        return 'user_offline'
      }
    },
    device: Object, // 设备信息
    audioContextType: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    iconType: {
      type: Boolean,
      default: () => {
        return true
      }
    },
    width: {
      type: String,
      default: () => {
        return '54px'
      }
    },
    height: {
      type: String,
      default: () => {
        return '54px'
      }
    }
  },
  data() {
    return {}
  },
  methods: {
    pxTorem,
    parseRem(res) {
      let px = res
      if (px) {
        px = parseInt(px, 10)
        if (isNaN(px)) {
          return ''
        }
        return this.pxTorem(px)
      }
      return ''
    }
  }
}
</script>
<style scoped lang="less" rel="stylesheet/less">
.headPortraitBox {
  background: var(--backgroundv2);
  position: relative;
  border: 1px solid var(--main);
  box-sizing: border-box;
  flex-shrink: 0;
  border-radius: 50%;
  .headPortrait {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
    img {
      height: 100%;
    }
    .svgBox {
      width: 60%;
      height: 60%;
    }
  }
  span {
    //max-width: 14px;
    //max-height: 14px;
    width: 32%;
    height: 32%;
    border-radius: 50%;
    position: absolute;
    bottom: -4%;
    right: -2%;
  }
}
</style>
