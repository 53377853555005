import Axios from '@/http'

export default {
  /* 一键退出所有媒体房间 */
  closeByClientId(params) {
    return new Promise((resolve, reject) => {
      Axios.post('media/closeByClientId', params, { isJSON: true }).then(
        (res) => {
          resolve(res)
        }
      )
    })
  },
  /* 退出房间 */
  exitRoom(roomId) {
    return new Promise((resolve, reject) => {
      Axios.post('media/exitRoom', { roomId }, { isJSON: true }).then((res) => {
        resolve(res)
      })
    })
  },
  /* 群组-踢出房间 */
  groupCloseDevice(params) {
    return new Promise((resolve, reject) => {
      Axios.post('media/groupCloseDevice', params, { isJSON: true }).then(
        (res) => {
          resolve(res)
        }
      )
    })
  },
  /* 获取媒体房间成员集合 */
  mediaRoomMemberList(params) {
    return new Promise((resolve, reject) => {
      Axios.post('media/mediaRoomMemberList', params).then((res) => {
        resolve(res)
      })
    })
  },
  /* 广播-踢出房间 */
  broadcastMemberClose(params) {
    return new Promise((resolve, reject) => {
      Axios.post('media/broadcastMemberClose', params, { isJSON: true }).then(
        (res) => {
          resolve(res)
        }
      )
    })
  },
  /* 广播-邀请加入 */
  broadcastInvite(params) {
    return new Promise((resolve, reject) => {
      Axios.post('media/broadcastInvite', params, { isJSON: true }).then(
        (res) => {
          resolve(res)
        }
      )
    })
  },
  /* 客户端信息 */
  clientInfo(params) {
    return new Promise((resolve, reject) => {
      Axios.post('media/clientInfo', params).then((res) => {
        resolve(res)
      })
    })
  },
  /* 拉设备进入对讲群组 */
  intercomInviteDevice(params) {
    return new Promise((resolve, reject) => {
      Axios.post('media/intercomInviteDevice', params, { isJSON: true }).then(
        (res) => {
          resolve(res)
        }
      )
    })
  },
  /* 对设备单独对讲 */
  intercomSingle(params) {
    return new Promise((resolve, reject) => {
      Axios.post('media/intercomSingle', params).then((res) => {
        resolve(res)
      })
    })
  },
  /* 正式群组对讲呼叫 */
  intercomNatureGroupIn(params) {
    return new Promise((resolve, reject) => {
      Axios.post('media/intercomNatureGroupIn', params).then((res) => {
        resolve(res)
      })
    })
  }
}
