import mapAearchPlaceItem from '@components/electronicFence/mapAearchPlaceItem.vue'
import {
  GCJ2transformWGS,
  exportCoordinate,
  WGStransformGCJ2
} from '../../../../utils/webgis'
export default {
  props: {
    searchSvgName: {
      type: String,
      default: () => {
        return 'search'
      }
    },
    map: {
      // 筛选框数组
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      nextPageParams: null,
      lnglat: {
        lng: null,
        lngType: null,
        lat: null,
        latType: null
      },
      lnglatList: [
        {
          titleName: '填写经纬度',
          lng: null,
          lat: null
        }
      ],
      searchResultList: [], // 搜索结果
      searchAddressType: 1,
      placeholder: '请输入地址',
      mapAearchPlaceItem: mapAearchPlaceItem,
      searchAddress: null, // 搜索内容
      valid: true,
      size: 10,
      page: 0,
      noMore: false,
      loading: false,
      colorObject: {},
      timeOut: null,
      queryMarkerPoint: null
    }
  },
  computed: {
    disabled() {
      return this.loading || this.noMore
    },
    commonOptions() {
      if (this.mainStore) {
        return this.mainStore.getters.commonOptions
      } else {
        return this.$store.getters.commonOptions
      }
    },
    mapCoordinate() {
      return parseInt(this.commonOptions.bmCorrect)
    }
  },
  mounted() {
    // this.getData()
    let colorObject = {}
    if (this.mainStore) {
      colorObject = this.mainStore.getters.colorObject
    } else {
      colorObject = this.$store.getters.colorObject
    }
    this.colorObject = colorObject
  },
  methods: {
    /* 经纬度校验 */
    lnglatCheckFn(content) {
      this.onlyNumber(this.lnglat, content)
      this.$nextTick(() => {
        if (this.lnglat[`${content}`]) {
          let reg = null
          if (content === 'lng') {
            reg =
              /^(-|\+)?(((\d|[1-9]\d|1[0-7]\d|0{1,3})\.\d{0,15})|(\d|[1-9]\d|1[0-7]\d|0{1,3})|180\.0{0,15}|180)$/
          } else {
            reg = /^(-|\+)?([0-8]?\d{1}\.\d{0,15}|90\.0{0,15}|[0-8]?\d{1}|90)$/
          }
          if (reg.test(this.lnglat[`${content}`])) {
            this.$set(this.lnglat, `${content}Type`, true)
          } else {
            this.$set(this.lnglat, `${content}Type`, false)
          }
        } else {
          this.$set(this.lnglat, `${content}Type`, false)
        }
        const lng = this.lnglat.lng
        const lat = this.lnglat.lat
        let lnglatList = []
        if (this.lnglat.latType && this.lnglat.lngType) {
          const Coordinate = {
            WGS84: 0,
            GCJ02: 1
          }
          let wgs84Res = {}
          let gcj20 = {}
          let marsPoint = {}
          console.log(this.mapCoordinate, Coordinate.WGS84, Coordinate.GCJ02)

          if (this.mapCoordinate === Coordinate.WGS84) {
            // 坐标系在当前地图经纬度所在位置
            marsPoint = GCJ2transformWGS(lat, lng)
            wgs84Res = {
              titleName: 'WGS84',
              lng,
              lat
            }
            gcj20 = {
              titleName: 'GCJ02',
              lng: marsPoint.lng,
              lat: marsPoint.lat
            }
          } else if (this.mapCoordinate === Coordinate.GCJ02) {
            marsPoint = WGStransformGCJ2(lat, lng)
            gcj20 = {
              titleName: 'GCJ02',
              lng,
              lat
            }
            wgs84Res = {
              titleName: 'WGS84',
              lng: marsPoint.lng,
              lat: marsPoint.lat
            }
          }
          lnglatList = [wgs84Res, gcj20]
        } else {
          lnglatList = [
            {
              titleName: '填写经纬度',
              lng,
              lat
            }
          ]
        }
        this.$nextTick(() => {
          this.lnglatList = [...lnglatList]
        })
      })
    },
    /* 经纬度点击 */
    lnglatClickFn(lng, lat) {
      if (this.lnglat.lngType && this.lnglat.latType) {
        this.mapJumpLocationFn({
          lng,
          lat
        })
      }
    },

    queryMarkerPointDelete() {
      if (this.timeOut) {
        clearTimeout(this.timeOut)
        this.timeOut = null
      }
      if (this.queryMarkerPoint) {
        this.map.remove(this.queryMarkerPoint)
        this.queryMarkerPoint = null
      }
    },
    // 设置查询的点 （闪烁三秒后移除）
    setQueryMarkerPoint(row) {
      if (!this.map) return
      const { lat, lng } = row
      this.queryMarkerPointDelete()
      const content = `
         <div class="w-[40px] h-[60px] twinkle_marker">
                  <svg class= "svg-icon" style="width: inherit;height: inherit;">
                    <use xlink:href = "#icon-trajectory_personnel" > </use>
                  </svg>
        </div>
      `
      this.queryMarkerPoint = new window.AMap.Marker({
        visible: true,
        content,
        zIndex: 1,
        size: [40, 60],
        offset: new window.AMap.Pixel(-20, -60),
        position: [lng, lat]
      })
      this.queryMarkerPoint.setMap(this.map)
      this.timeOut = setTimeout(() => {
        this.queryMarkerPointDelete()
      }, 4000)
    },

    /* 地图跳转位置 */
    mapJumpLocationFn(row) {
      this.map.panTo([row.lng, row.lat])
      this.map.setZoom(18)
      this.setQueryMarkerPoint(row)
      this.resetFn(1)
    },

    /* 节流 */
    throttle(fn, delay) {
      return (params) => {
        if (!this.valid) {
          return false
        }
        this.valid = false
        setTimeout(() => {
          fn(params)
          this.valid = true
        }, delay)
      }
    },
    /* 滚动加载 */
    load() {
      this.loading = true
      this.page++
      this.$nextTick(() => {
        this.getData()
      })
    },
    /* 重置 */
    searchAddressTypeFn() {
      this.searchAddress = null
      this.lnglat = {
        lng: null,
        lngType: null,
        lat: null,
        latType: null
      }
      this.lnglatList = [
        {
          titleName: '填写经纬度',
          lng: null,
          lat: null
        }
      ]
      this.placeholderFn()
      this.resetFn()
    },
    /* 重置数据 */
    resetFn(type) {
      this.searchResultList = []
      this.page = 1
      this.noMore = false
      this.loading = false
      this.$nextTick(() => {
        if (type !== 1) {
          this.getData()
        }
      })
    },
    getData() {
      if (this.searchAddress && this.searchAddress.length) {
        if (this.searchAddressType === 1) {
          this.addressRequestFn()
        } else if (this.searchAddressType === 4) {
          this.organizationPageFn()
        } else if (this.searchAddressType === 6) {
          this.patrolPointFn()
        }
      } else {
        this.resetFn(1)
      }
    },
    /* 根据地址搜索 */
    addressRequestFn() {
      if (this.page === 1) {
        this.nextPageParams = null
      }
      window.hyMap
        .placeSearch({
          pageSize: this.size, // 单页显示结果条数
          pageIndex: this.page,
          query: this.searchAddress,
          // 谷歌地图下一页传入
          nextPageParams: this.nextPageParams
        })
        .then(({ results, nextPageParams }) => {
          this.nextPageParams = nextPageParams
          if (results) {
            const dataList = results
            const list = []
            for (let i = 0; i < dataList.length; i++) {
              const item = dataList[i]
              list.push({
                lng: item.lng,
                lat: item.lat,
                id: this.createUniqueId(1),
                address: item.address || item.name,
                title: this.titleFn(item.name)
              })
            }
            this.$nextTick(() => {
              this.loading = false
              this.searchResultList.push(...list)
            })
          }
        })
        .catch(() => {
          this.loading = false
          this.noMore = true
          this.$message.error('未搜索到内容')
        })
    },
    /* 部门名称搜索 */
    organizationPageFn() {
      this.$api
        .page('organization', {
          page: this.page - 1,
          size: 10,
          query: this.searchAddress
        })
        .then((res) => {
          if (res.code === 200) {
            const dataList = res.data.content || []
            const list = []
            for (let i = 0; i < dataList.length; i++) {
              const item = dataList[i]
              const lngAntlat = exportCoordinate({
                lat: item.lat,
                lng: item.lng
              })
              list.push({
                id: item.id,
                address: item.address || item.companyName,
                ...item,
                lng: lngAntlat.lng,
                lat: lngAntlat.lat,
                title: this.titleFn(item.companyName)
              })
            }
            this.$nextTick(() => {
              this.loading = false
              this.searchResultList.push(...list)
              if (dataList.length === 0 && this.searchResultList.length === 0) {
                this.$message.error('未搜索到内容')
              }
              if (this.page === res.data.totalPages) {
                this.noMore = true
              }
            })
          }
        })
    },
    /* 巡查点搜索 */
    patrolPointFn() {
      console.log('patrolPointFn')
      this.$api
        .page('scrollCheck/scp', {
          page: this.page - 1,
          size: 10,
          query: this.searchAddress
        })
        .then((res) => {
          if (res.code === 200) {
            const dataList = res.data.content || []
            const list = []
            for (let i = 0; i < dataList.length; i++) {
              const item = dataList[i]
              if (item.lng && item.lat) {
                list.push({
                  id: item.id,
                  ...item,
                  address: item.address || item.pointName,
                  title: this.titleFn(item.pointName)
                })
              }
            }
            this.$nextTick(() => {
              this.loading = false
              this.searchResultList.push(...list)
              if (dataList.length === 0 && this.searchResultList.length === 0) {
                this.$message.error('未搜索到内容')
              }
              if (this.page === res.data.totalPages) {
                this.noMore = true
              }
            })
          }
        })
    },
    /* 文字转换 */
    titleFn(title = '') {
      const arr = []
      for (let i = 0; i < title.length; i++) {
        const item = title[i]
        const index = this.searchAddress.indexOf(item)
        const json = {
          content: title[i]
        }
        if (index === -1) {
          json.type = 1
        } else {
          json.type = 2
        }
        arr.push(json)
      }
      return arr
    },
    placeholderFn() {
      if (this.searchAddressType === 1) {
        this.placeholder = '请输入地址'
      } else if (this.searchAddressType === 4) {
        this.placeholder = '请输入部门名称'
      } else if (this.searchAddressType === 6) {
        this.placeholder = '请输入巡查点'
      } else {
        this.placeholder = null
      }
    }
  }
}
