<!-- 视频播放器 -->
<template>
  <div
    class="video-player-content"
    :class="{ 'full-video-player-content': isFullScreen }"
  >
    <video id="videoPlayer">
      <source type="video/mp4" />
    </video>
    <div class="operator-box r-f">
      <div class="progress-box">
        <input
          max="100"
          :style="{
            background: `linear-gradient(to right, var(--theme) ${getPercent(
              value,
              100
            )}%, var(--theme-05) ${getPercent(value, 100)}% `
          }"
          type="range"
          @mouseup="getNowWh($event)"
          @mousedown="isDrop = true"
          ref="progress"
          id="progress"
          class="pos_r w-full"
          v-model="value"
        />
      </div>
      <div class="left-operator r-f-i fs_12 lh_20 c_9DA7B2">
        <span class="is-play-seconds">{{ formatSeconds(curTime) }}"</span> /
        <span class="total-seconds">{{ formatSeconds(totalTime) }}"</span>
      </div>
      <div class="center-operator r-f-i" v-if="canPlay">
        <span @click="playPrevious">
          <svg-icon svg-name="audio_previous-btn" className="svgBox" />
        </span>
        <span
          v-if="videoState !== 'PLAYING' && canPlay"
          @click="playVideo(videoUrl)"
        >
          <svg-icon svg-name="audio_play-btn" className="svgBox" />
        </span>
        <span @click="pauseVideo" v-if="videoState === 'PLAYING'">
          <svg-icon svg-name="audio_pause-btn" className="svgBox" />
        </span>
        <span @click="playNext">
          <svg-icon svg-name="audio_next-btn" className="svgBox" />
        </span>
      </div>
      <div class="right-operator r-f-i pos_r">
        <!-- 速度 -->
        <el-popover
          placement="top"
          popper-class="minWidth0_popover popover_darkColor liveBroadcast w-[62px]"
          trigger="click"
        >
          <div class="centerBox p-[10px_0]">
            <ul class="c_9DA7B2 fs_12 speedList">
              <li
                class="clickBox"
                @click="speedFn(item.value)"
                :class="{ selectBox: item.value === speed }"
                v-for="(item, index) in speedList"
                :key="index"
              >
                {{ item.label }}
              </li>
            </ul>
          </div>
          <div
            slot="reference"
            class="fs_12 c_9DA7B2 speed_box centerBox clickBox css-text-no-select"
          >
            <span
              v-for="(item, index) in speedList"
              :key="index"
              v-show="item.value === speed"
            >
              {{ item.value === 1 ? '倍 数' : item.label }}
            </span>
          </div>
        </el-popover>
        <!-- 声音 -->
        <el-popover
          placement="top"
          popper-class="popover_volumeSlider w-[40px]"
          trigger="click"
        >
          <div class="centerBox">
            <el-slider
              @input="changeVolume($event)"
              class="volume-slider"
              v-model="volumeValue"
              vertical
              height="105px"
            >
            </el-slider>
          </div>
          <div slot="reference">
            <el-tooltip
              class="item"
              effect="dark"
              content="调节播放音量"
              placement="top"
            >
              <svg-icon svg-name="audio_volumn" className="svgBox" />
            </el-tooltip>
          </div>
        </el-popover>
        <!-- 放大 -->
        <el-tooltip
          class="item clickBox"
          effect="dark"
          content="全屏切换"
          placement="top"
        >
          audio_reduce
          <svg-icon
            @click.native="clickFullScreen"
            :svg-name="isFullScreen ? 'audio_reduce' : 'audio_enlarge'"
            className="svgBox"
          />
        </el-tooltip>
      </div>
    </div>
    <div
      v-if="downloadFilesType || playRulesType"
      class="float-operator-item right-top-operator"
    >
      <!-- 下载 -->
      <div v-if="downloadFilesType">
        <svg-icon
          @click.native="downloadFn"
          svg-name="audio_down-btn"
          className="svgBox"
        />
      </div>
      <!-- 播放规则 -->
      <div v-if="playRulesType">
        <div
          @click="playRulesFn(item.value)"
          v-for="(item, index) in playRuleslist"
          :key="index + 'playRuleslist'"
          v-show="playRules === item.value"
        >
          <el-tooltip
            class="item clickBox"
            effect="dark"
            :content="item.label"
            placement="top"
          >
            <svg-icon :svg-name="item.svgName" className="svgBox" />
          </el-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less">
ul.speedList {
  width: 100%;
  li {
    &:after {
      transition: 0s;
    }
    &:hover,
    &.selectBox {
      color: var(--theme);
      background: var(--backgroundv3);
    }
    transition: 0.5s;
    height: 26px;
    line-height: 26px;
    text-align: center;
  }
}
.speed_box {
  width: 44px;
  height: 24px;
  flex-shrink: 0;
  margin-right: 10px;
  border-radius: 4px;
  border: 1px solid var(--border);
  box-sizing: border-box;
}
.volume-box {
  width: 31px;
  height: 115px;
  background: #9da7b2;
  border-radius: 6px;
  position: absolute;
  right: 6px;
  top: -130px;
  padding-top: 12px;
  text-align: center;
  .volume-slider {
    width: 30px;
  }
}
.svgBox {
  width: 26px;
  height: 26px;
}
.video-player-content {
  width: 100%;
  height: 100%;
  border: 1px solid var(--border);
  background: #040814;
  position: absolute;
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  video {
    width: 100%;
    height: 0;
    flex-grow: 1;
  }

  .operator-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    height: 42px;
    background: #040814;
    color: var(--main);
    padding: 16px 0 0 0;

    .progress-box {
      width: 100%;
      text-align: center;
      position: absolute;
      top: 0;

      input[type='range'] {
        width: 556px;
        height: 17px;
        outline: none;
        border-radius: 20px;
        -webkit-appearance: none; /*清除系统默认样式*/
        background-color: #eaf5f9;
      }

      input[type='range']::-webkit-slider-thumb {
        -webkit-appearance: none; /*清除系统默认样式*/
        width: 14px;
        height: 14px;
        border-radius: 20px;
        background: var(--main);
        box-shadow: 0px 0px 6px 3px var(--theme);
        cursor: pointer;
      }

      input[type='range']::-ms-fill-lower {
        -webkit-appearance: none;
      }

      #progress {
        width: 99%;
        height: 6px;
      }
    }

    .left-operator {
      font-size: 12px;
      font-weight: bold;
      width: 120px;

      .is-play-seconds {
        margin: 0 8px;
      }

      .total-seconds {
        margin: 0 8px;
      }
    }

    .center-operator {
      width: 88px;
      justify-content: space-between;
      align-items: center;
      .svgBox {
        width: 26px;
        height: 26px;
        margin-right: 10px;
      }
    }

    .right-operator {
      align-items: center;
      justify-content: flex-end;
      .svgBox {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
  }

  .float-operator-item {
    position: absolute;
    color: var(--main);
    z-index: 100;
    cursor: pointer;
    right: 10px;
    img {
      height: 22px;
    }
  }

  .right-top-operator {
    top: 20px;
    div + div {
      margin-top: 10px;
    }
  }

  .right-center-operator {
    top: 40%;
  }
}

// 全屏播放器样式
.full-video-player-content {
  .volume-box {
    right: 16px;
  }
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  .operator-box {
    height: 65px;
    .center-operator {
      height: 34px;
      justify-content: space-between;
      align-items: center;
      .svgBox {
        width: 34px;
        height: 34px;
        margin-right: 10px;
      }
    }

    .right-operator {
      height: 22px;
      align-items: center;
      .svgBox {
        width: 28px;
        height: 28px;
        margin-right: 20px;
      }
    }
  }
  .float-operator-item {
    position: absolute;
    color: var(--main);
    z-index: 100;
    cursor: pointer;
    right: 16px;

    img {
      height: 34px !important;
    }
  }
}
</style>
<script type="text/ecmascript-6">
import timeUtils from '@utils/timeUtils'

export default {
  data () {
    return {
      /* 速度 */
      speedList: [
        { value: 0.5, label: '0.5 X' },
        { value: 1, label: '1.0 X' },
        { value: 1.5, label: '1.5 X' },
        { value: 2, label: '2.0 X' },
        { value: 3, label: '3.0 X' }
      ],
      speed: 1,
      /* 播放规则 */
      playRuleslist: [
        { value: 1, label: '播完即停', svgName: 'mediaCenter_stopPlaying' },
        { value: 2, label: '连续播放', svgName: 'mediaCenter_autoplay' },
        { value: 3, label: '循环播放', svgName: 'mediaCenter_loopPlayback' }
      ],
      playRules: 1,
      videoItem: {},
      videoUrl: '',
      value: 0,
      videoEl: null,
      videoState: 'NO_LOAD', // NO_LOAD.未加载 IS_LOAD.已加载 PLAYING.播放中 PAUSE.暂停 PLAY_END.播完结束 CAN_NOT_PLAY.无法播放
      curTime: 0,
      totalTime: 0,
      isDrop: false,
      isFullScreen: false,
      volumeValue: 100,
      isChangeVolume: false
    }
  },
  props: {
    /* 下载 */
    downloadFilesType: {
      type: Boolean,
      default: () => {
        return true
      }
    },
    /* 播放规则 */
    playRulesType: {
      type: Boolean,
      default: () => {
        return true
      }
    },
    // 播放列表
    playList: {
      default: () => {
        return []
      }
    }
  },
  computed: {
    playItem () {
      return this.playList.find(item => item.id === this.videoItem.id)
    },
    canPlay () {
      return ((this.videoState !== 'NO_LOAD') || (this.videoState !== 'CAN_NOT_PLAY'))
    }
  },
  destroyed () {
    this.videoEl = null
  },
  methods: {
    playRulesFn (playRules) {
      if (playRules === 3) {
        this.playRules = 1
      } else {
        this.playRules = playRules + 1
      }
    },
    /* 速度 */
    speedFn (speed) {
      this.speed = speed
      if (document.getElementById('videoPlayer')) {
        document.getElementById('videoPlayer').playbackRate = speed
      }
    },
    findIndexFn () {
      // return this.playList.findIndex(item => item.filePath === this.audioUrl)
      return this.playList.findIndex(item => item.id === this.videoItem.id)
    },
    playNext () {
      const findIndex = this.findIndexFn()
      if (findIndex !== this.playList.length - 1) {
        const item = this.playList[findIndex + 1]
        this.playVideo(item.filePath, item)
      } else {
        this.$message.warning('当前是最后一条')
      }
    },
    playPrevious () {
      const findIndex = this.findIndexFn()
      if (findIndex > 0 && findIndex !== this.playList.length) {
        const item = this.playList[findIndex - 1]
        this.playVideo(item.filePath, item)
      } else {
        this.$message.warning('当前是第一条')
      }
    },
    playVideo (filePath, item) {
      if (item) {
        if (item.id !== this.videoItem.id) {
          this.videoItem = item
          this.videoUrl = filePath
          this.videoEl.src = filePath
        }
      } else {
        if (this.videoUrl === '') {
          return false
        }
      }
      this.videoEl.play()
      this.videoState = 'PLAYING'
    },
    /* 视频截屏 */
    audioCutting () {
      const canvas = document.createElement('canvas')
      const img = document.getElementById('img')
      const video = document.getElementById('videoPlayer')
      video.setAttribute('crossOrigin', 'anonymous')
      canvas.width = video.clientWidth
      canvas.height = video.clientHeight
      canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height)
      const dataURL = canvas.toDataURL('image/png')
      img.setAttribute('src', dataURL)
    },
    /* 下载文件 */
    exportExcelFn (name, url) {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()
        xhr.open('GET', url)
        xhr.responseType = 'blob'
        xhr.headers = {
          'Content-Type': '*'
        }
        xhr.onload = function () {
          const blob = xhr.response
          resolve({
            name: name,
            blob
          })
        }
        xhr.onerror = function () {
          console.error('could not download file')
        }
        xhr.send()
      })
    },
    downloadFn () {
      if (this.videoItem && this.videoItem.id) {
        this.$message.warning('开始下载')
        // const today = this.formatTimeFn(new Date(), 'Y-M-h D:m:s')
        // this.$api.exportExcel.exportDailyCheckReport(`/mediaFile/mediaFileZip?mediaFileIds=${this.videoItem.id}`).then(res => {
        //   const blob = res
        //   const reader = new FileReader()
        //   reader.readAsDataURL(blob)
        //   reader.onload = function (ev) {
        //     if (window.navigator.msSaveOrOpenBlob) {
        //     // 兼容ie11
        //       const blobObject = new Blob([blob])
        //       window.navigator.msSaveOrOpenBlob(blobObject, `文件压缩包_${today}.zip`)
        //     } else {
        //       const url = URL.createObjectURL(new Blob([blob]))
        //       const a = document.createElement('a')
        //       document.body.appendChild(a) // 此处增加了将创建的添加到body当中
        //       a.href = url
        //       a.download = `文件压缩包_${today}.zip`
        //       a.target = '_blank'
        //       a.click()
        //       a.remove() // 将a标签移除
        //     }
        //   }
        // })
        this.exportExcelFn(this.videoItem.fileName, this.videoItem.filePath).then((res) => {
          const blob = res.blob
          const reader = new FileReader()
          reader.readAsDataURL(blob)
          reader.onload = function (ev) {
            if (window.navigator.msSaveOrOpenBlob) {
              // 兼容ie11
              const blobObject = new Blob([blob])
              window.navigator.msSaveOrOpenBlob(blobObject, res.name)
            } else {
              const url = URL.createObjectURL(new Blob([blob]))
              const a = document.createElement('a')
              document.body.appendChild(a) // 此处增加了将创建的添加到body当中
              a.href = url
              a.download = res.name
              a.target = '_blank'
              a.click()
              a.remove() // 将a标签移除
            }
          }
        })
      }
    },
    changeVolume () {
      this.videoEl.volume = this.volumeValue / 100
    },
    getNowWh () {
      this.curTime = this.totalTime * (this.value / 100)
      this.videoEl.currentTime = this.curTime
      this.isDrop = false
    },
    getPercent (num, total) {
      num = parseFloat(num)
      total = parseFloat(total)
      if (isNaN(num) || isNaN(total)) {
        return 0
      }
      return total <= 0 ? 0 : (Math.round(num / total * 10000) / 100.00)
    },
    resumePlay () {
      this.videoEl.play()
    },
    pauseVideo () {
      this.videoEl && this.videoEl.pause()
      this.videoState = 'PAUSE'
    },
    initVideo () {
      this.videoEl = document.querySelector('#videoPlayer')
      this.videoEl.addEventListener('canplay', () => {
        this.videoState = 'IS_LOAD'
        this.totalTime = Math.floor(this.videoEl.duration)
        this.$emit('can-play', this.totalTime)
      })
      this.videoEl.addEventListener('play', () => {
        this.videoEl.playbackRate = this.speed
        this.videoState = 'PLAYING'
        if (this.videoUrl && this.playItem) {
          this.$emit('play-start', this.videoUrl, this.playItem)
        }
      })
      this.videoEl.addEventListener('pause', () => {
        this.videoState = 'PAUSE'
      })
      this.videoEl.addEventListener('ended', () => {
        this.videoState = 'PLAY_END'
        if (this.playRules === 2) {
          /* 连续播放 */
          this.playNext()
        } else if (this.playRules === 3) {
          /* 循环播放 */
          this.videoEl.load()
          this.videoEl.play()
        }
        this.$emit('play-end')
      })
      this.videoEl.addEventListener('timeupdate', () => {
        this.videoState = 'PLAYING'
        if (!this.isDrop) {
          if (this.videoEl) {
            this.curTime = Math.floor(this.videoEl.currentTime)
          } else {
            setTimeout(() => {
              if (this.videoEl) {
                this.curTime = Math.floor(this.videoEl.currentTime)
              }
            }, 1000)
          }
          this.value = (this.curTime / this.totalTime) * 100
          this.$emit('on-time-update', this.curTime)
        }
      }, false)
    },
    // 注册全屏事件
    initFullScreenEvent () {
      document.addEventListener('fullscreenchange', (e) => {
        if (document.fullscreenElement) {
          this.isFullScreen = true
        } else {
          this.isFullScreen = false
        }
      })
    },
    formatSeconds (value) {
      return timeUtils.formatSeconds(value)
    },
    jumpDuration (s) {
      this.videoEl.currentTime = s
    },
    clickFullScreen () {
      if (this.isFullScreen) {
        this.exitFullscreen()
      } else {
        this.fullScreen()
      }
    },
    fullScreen () {
      const el = document.documentElement
      if (el.requestFullscreen) {
        el.requestFullscreen()
      }
    },
    exitFullscreen () {
      const el = document
      if (el.exitFullscreen) {
        el.exitFullscreen()
      }
    }
  },
  mounted () {
    this.playRulesFn(3)
    this.speedFn(1)
    this.initVideo()
    this.initFullScreenEvent()
  }
}
</script>
