import Axios from '@/http'

export default {
  /* 对比（详情） */
  detail(params) {
    return new Promise((resolve, reject) => {
      Axios.post('motorRecognition/detail', params).then((res) => {
        resolve(res)
      })
    })
  }
}
