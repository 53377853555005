<!-- 选择设备 -->
<template>
  <div class="dialog_clsss_lightColor selectRangeDevice">
    <div
      @click="setDragLevel"
      class="el-dialog pos_r select_device w-[300px]"
      v-el-drag-dialog
      v-if="dialogVisible"
      :style="{ 'z-index': dragLevel }"
    >
      <div class="el-dialog__header !rounded-[12px]">
        <div class="el-dialog__body ovh pb-1" style="position: revert">
          <div class="h-[400px]">
            <selectRangeDeviceItem
              v-if="dialogVisible"
              :requestParams="form"
              @on-selectedDeviceList="setDeviceList"
              ref="selectRangeDeviceItem"
            ></selectRangeDeviceItem>
          </div>
          <div class="h_50 centerBox py-2">
            <el-button
              @click="onClose"
              class="buttonBox clickBox button_default"
              size="mini"
              type="info"
              >取 消</el-button
            >
            <el-button
              @click="onSubmit"
              class="buttonBox clickBox button_function ml-[30px]"
              size="mini"
              type="primary"
              >确 认</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import elDragDialog from '@components/el-drag-dialog'
import selectRangeDeviceItem from '@components/device/selectRangeDeviceItem'

export default {
  data() {
    return {
      dragLevel: 0,
      form: {
        lng: null,
        lat: null,
        distance: null
      },
      deviceList: [],
      successCallback: null,
      errorCallback: null,
      dialogVisible: false
    }
  },
  components: {
    selectRangeDeviceItem
  },
  directives: { elDragDialog },
  mounted() {},
  methods: {
    setDeviceList(deviceList) {
      this.deviceList = deviceList || []
    },
    show(res) {
      return new Promise((resolve, reject) => {
        this.successCallback = resolve
        this.errorCallback = reject
        this.form = res
        this.setDialogVisible(true)
      })
    },
    // 设置弹窗展示
    setDialogVisible(type) {
      //  if (this.dialogVisible === type) return
      if (type) {
        this.setDragLevel()
      }
      this.dialogVisible = type
    },
    // 设置弹窗层级
    setDragLevel() {
      if (this.mainStore) {
        this.dragLevel = this.mainStore.getters.dragLevel
        this.mainStore.commit('setDragLevel')
      } else {
        this.dragLevel = this.$store.getters.dragLevel
        this.$store.commit('setDragLevel')
      }
    },
    close() {
      this.dialogVisible = false
    },
    onClose() {
      this.errorCallback()
      this.close()
    },
    onSubmit() {
      if (this.deviceList.length === 0) {
        return this.$message.error('请选择设备')
      }
      this.successCallback(this.deviceList)
      this.close()
    }
  }
}
</script>
<style scoped lang="less" rel="stylesheet/less">
.selectRangeDevice {
  .el-dialog {
    position: fixed;
    top: 10%;
    left: 26%;
  }
}
</style>
