<!-- 消息版块 -->
<template>
  <div>
    <div class="dialog_clsss">
      <div
        @click="setDragLevel"
        class="el-dialog w-[300px]"
        v-el-drag-dialog
        v-if="dialogVisible"
        :style="{ 'z-index': dragLevel }"
      >
        <!--标题-->
        <div class="pos_r dialog_header el-dialog__header r-f">
          <span class="fs_16" style="line-height: 30px">
            <svg-icon svg-name="news_select" class-name="svgBox" />
            <span class="ml_10">消息</span>
          </span>
          <div class="imgPictureChange clickBox" @click="onClose">
            <svg-icon
              svg-name="delete_hover"
              class-name="!w-[26px] !h-[26px]"
            />
            <svg-icon svg-name="close" class-name="!w-[26px] !h-[26px]" />
          </div>
        </div>
        <!--内容-->
        <div class="dialog_body el-dialog__body !p-[0_0_10px_0]">
          <div class="max-h-[60vh] overflow-y-auto px-[0px]">
            <ul class="sectionList fs_14 c_fff" v-if="sectionList.length">
              <li
                @click="sectionSelectFn(item)"
                v-for="(item, index) in sectionList"
                :key="index"
                class="clickBox"
              >
                <div class="flex_vertically_center css-text-no-select">
                  <svg-icon :svg-name="item.svgName" className="svgBox" />
                  <div class="ml_10 w-[1px] h-[28px] bg-theme05"></div>
                  <span class="ml_10">
                    <span class="pos_r">
                      {{ item.name }}
                      <span
                        v-if="form[`${item.params}`]"
                        class="unreadBox centerBox"
                        >{{ form[`${item.params}`] }}</span
                      >
                    </span>
                  </span>
                </div>
                <svg-icon svg-name="news_arrow" className="svgBox arrow" />
              </li>
            </ul>
            <div v-else class="centerBox c_9DA7B2 fs_12 r-f noData">
              <svg-icon svg-name="noData" className="svgBox" />
              <div class="mt_10">暂无权限</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-drawer
      ref="drawer"
      :append-to-body="true"
      :wrapperClosable="true"
      :destroy-on-close="true"
      :with-header="false"
      custom-class="drawer-class !w-[1000px]"
      :visible.sync="drawer"
      direction="rtl"
    >
      <div class="drawer_header">
        <div class="flex items-center">
          <svg-icon
            :svg-name="sectionSelect.svgName"
            className="svgBox c_theme !w-[18px] !h-[18px]"
          />
          <span class="ml_10">{{ sectionSelect.name }}</span>
          <span v-if="sectionSelect.remarks" class="c_9DA7B2 fs_12 ml_10">{{
            sectionSelect.remarks
          }}</span>
        </div>
        <div @click="drawer = false" class="clickBox">
          <svg-icon className="!w-[34px] !h-[34px]" svg-name="signOut" />
        </div>
      </div>
      <div class="drawer_body">
        <div class="flex-grow h-0">
          <!-- 系统消息 -->
          <operationLog
            v-if="sectionSelect.permission === 'report_operationLog'"
            ref="operationLog"
          ></operationLog>
          <!-- SOS -->
          <sosQuery
            :unreadType="true"
            v-else-if="sectionSelect.permission === 'report_sosQuery'"
            ref="sosQuery"
          >
            <template #unreadType>
              <el-button
                @click="readFn(sectionSelect.type)"
                size="small"
                class="button_function pos_r top-[6px]"
              >
                <div class="centerBox">一键已读</div>
              </el-button>
            </template>
            <template #operator="{ row }">
              <iconBox
                @click="sosInformationFn(row)"
                imgName="ps_alarmLocation"
                altText="查看位置"
              ></iconBox>
            </template>
          </sosQuery>
          <!-- 违反规则 -->
          <breakQuery
            :unreadType="true"
            v-else-if="sectionSelect.permission === 'report_breakQuery'"
            ref="breakQuery"
          >
            <template #unreadType>
              <el-button
                @click="readFn(sectionSelect.type)"
                size="small"
                class="button_function pos_r top-[6px]"
              >
                <div class="centerBox">一键已读</div>
              </el-button>
            </template>
            <template #operator="{ row }">
              <iconBox
                @click="breakQueryInformationFn(row)"
                imgName="ps_alarmLocation"
                altText="查看位置"
              ></iconBox>
            </template>
          </breakQuery>
          <!-- 储存预警 -->
          <storageQuery
            :unreadType="true"
            v-else-if="sectionSelect.permission === 'report_storageQuery'"
            ref="storageQuery"
          >
            <template #unreadType>
              <el-button
                @click="readFn(sectionSelect.type)"
                size="small"
                class="button_function pos_r top-[6px]"
              >
                <div class="centerBox">一键已读</div>
              </el-button>
            </template>
            <template #operator="{ row }">
              <iconBox
                @click="storageQueryInformationFn(row)"
                imgName="ps_alarmLocation"
                altText="查看位置"
              ></iconBox>
            </template>
          </storageQuery>
          <!-- 考勤 -->
          <dailyCheckDay
            :unreadType="true"
            @on-readFn="dailyCheckDayReadFn"
            v-else-if="sectionSelect.permission === 'dailyCheck_checkReport'"
            ref="dailyCheckDay"
          >
            <template #unreadType>
              <el-button
                @click="readFn(sectionSelect.type)"
                size="small"
                class="button_function pos_r top-[6px]"
              >
                <div class="centerBox">一键已读</div>
              </el-button>
            </template>
          </dailyCheckDay>
          <!-- 险情调度 -->
          <gasCaseQuery
            :unreadType="true"
            @on-readFn="dailyCheckDayReadFn"
            v-else-if="sectionSelect.permission === 'dangerCenter'"
            ref="gasCaseQuery"
          >
            <template #unreadType>
              <el-button
                @click="readFn(sectionSelect.type)"
                size="small"
                class="button_function pos_r top-[6px]"
              >
                <div class="centerBox">一键已读</div>
              </el-button>
            </template>
          </gasCaseQuery>
          <!-- 案件通知 -->
          <casesQuery
            v-else-if="sectionSelect.permission === 'caseCenter'"
            :unreadType="true"
            @on-readFn="dailyCheckDayReadFn"
            @on-close="drawerClose"
            ref="casesQuery"
          >
            <template #unreadType>
              <el-button
                @click="readFn(sectionSelect.type)"
                size="small"
                class="button_function pos_r top-[6px]"
              >
                <div class="centerBox">一键已读</div>
              </el-button>
            </template>
          </casesQuery>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import reportPopup from './js/index.js'
export default {
  name: 'Login',
  mixins: [reportPopup],
  components: {},
  data() {
    return {}
  },
  created() {},
  methods: {},
  mounted() {}
}
</script>

<style scoped lang="less" rel="stylesheet/less">
.el-dialog {
  position: fixed !important;
  top: 110px;
  left: calc(100% - 710px);
}
.dialog_header {
  justify-content: space-between;
  border-bottom: 1px solid var(--border);
  padding: 16px 20px 10px;
}
ul.sectionList {
  padding: 0 10px 20px;
  li {
    &:active {
      transition: 0s;
    }
    &:hover {
      box-shadow: inset 0px 0px 6px 0px var(--theme);
      border: 1px solid var(--theme);
      .svgBox {
        color: var(--theme);
      }
    }
    .unreadBox {
      position: absolute;
      top: -4px;
      right: -30px;
      display: inline-block;
      font-size: 12px;
      min-width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      box-sizing: border-box;
      border-radius: 50%;
      background: linear-gradient(180deg, #ff8181 0%, #fd5151 100%);
    }
    transition: 0.5s;
    height: 64px;
    padding: 0 14px;
    box-shadow: var(--box-shadow);
    background: var(--backgroundv2);
    border: 1px solid var(--backgroundv2);
    margin-top: 8px;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .svgBox {
      width: 14px;
      height: 14px;
      color: var(--theme);
      transition: 0.5s;
    }
    .arrow {
      color: var(--auxiliary);
    }
  }
}
.noData {
  height: 300px;
  letter-spacing: 4px;
  flex-direction: column;
  text-align: center;
  .svgBox {
    width: 50px;
    height: 50px;
  }
}
</style>
