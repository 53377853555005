import Axios from '@/http'

export default {
  // 电子围栏（不分页）（包含设备）
  findAllWithDevice(params) {
    return new Promise((resolve, reject) => {
      Axios.post('mapRail/findAllWithDevice', params, { isJSON: false }).then(
        (res) => {
          resolve(res)
        }
      )
    })
  }
}
