/* 子应用通过 当前 vuex存储文件去调用父级方法 */
const state = {
  onunloadList: [],
  business: null,
  // 回调对象存储
  callbackObjectStore: {},
  // 指令列表 （设备发送的指令都是存储在这里）
  directiveList: window.sessionStorage.getItem('directiveList')
    ? JSON.parse(sessionStorage.getItem('directiveList'))
    : []
}

/* =================================== 获取消息（getters） =================================== */
const getters = {
  /* 连接状态 */
  businessConnectionStatus(state) {
    return state.business.connectionStatus
  },
  /* 指令列表 */
  directiveList(state) {
    return state.directiveList
  }
}
const mutations = {
  /* 刷新指令监听 */
  onunloadOperation(state, obj) {
    // obj = {
    //   name: '监听名称',
    //   type: '1 新增或者更新 2删除',
    //   callback: '执行回调'
    // }
    const onunloadList = state.onunloadList || []
    if (obj && obj.name) {
      const index = onunloadList.findIndex((v) => v.name === obj.name)
      if (obj.type === 1) {
        /* 添加 */
        if (index === -1) {
          onunloadList.push(obj)
        } else {
          onunloadList[index].callback = obj.callback
        }
      } else if (obj.type === 2) {
        /* 删除 */
        if (index !== -1) {
          onunloadList.splice(index, index)
        }
      }
      state.onunloadList = onunloadList
    } else {
      if (onunloadList.length) {
        onunloadList.forEach((v) => {
          if (v.callback) {
            v.callback()
          }
        })
      }
    }
  },
  businessSdkFn(state, sdk) {
    state.business = sdk
  },
  // business 赋值回调对象
  businessAssignmentCallbackObject(state, obj) {
    /*
     * name 相关内容
     * audioContextType 话语权状态
     * */
    state.callbackObjectStore[`${obj.name}`] = obj.callback
  },
  // business 调用回调对象
  businessCallCallbackObject(state, obj) {
    if (state.callbackObjectStore[`${obj.name}`]) {
      state.callbackObjectStore[`${obj.name}`](obj.res)
    }
  },

  subscribeListFn(state, list) {
    state.business.subscribeListFn(list)
  },
  /* 批量取消订阅设备 */
  resetDeviceIdList(state, deviceList) {
    state.business.resetDeviceIdList(deviceList)
  },
  /* 批量订阅设备状态 */
  bulkSubscriptionDevice(state, deviceList) {
    state.business.bulkSubscriptionDevice(deviceList)
  },
  /* 批量订阅设备状态 */
  addSubscriptionDevice(state, device) {
    state.business.addSubscriptionDevice(device)
  },
  /* 移除场景中订阅的设备 */
  deleteSceneDevice(state, sceneName) {
    state.business.deleteSceneDevice(sceneName)
  },
  /* 设备指令 */
  equipmentDirective(state, res) {
    state.business.equipmentDirective(
      res.device,
      res.successCallback,
      res.failCallback
    )
  },
  /* 查询⽇期列表 */
  deviceQueryFileDates(state, res) {
    state.business.deviceQueryFileDates(
      res.obj,
      res.successCallback,
      res.failCallback
    )
  },
  /* 查询⽂件列表 */
  deviceQueryFiles(state, res) {
    state.business.deviceQueryFiles(
      res.obj,
      res.successCallback,
      res.failCallback
    )
  },
  /* 设备文件上传 */
  deviceUploadFile(state, res) {
    state.business.deviceUploadFile(
      res.obj,
      res.successCallback,
      res.failCallback
    )
  },
  /* 设备开关功能组合 */
  deviceFunctionSwitch(state, res) {
    state.business.deviceFunctionSwitch(
      res.obj,
      res.successCallback,
      res.failCallback
    )
  },
  /* 聊天新增订阅 */
  chatSubscribe(state, res) {
    state.business.chatSubscribe(res.roomId, res.chatMessageCallback)
  },
  /* 聊天取消订阅 */
  chatUnsubscribe(state, roomId) {
    state.business.chatUnsubscribe(roomId)
  },

  /* 视频会议新增订阅 */
  videoMeetingSubscribe(state, res) {
    state.business.videoMeetingSubscribe(res.roomId, res.chatMessageCallback)
  },
  /* 视频会议取消订阅 */
  videoMeetingUnsubscribe(state, roomId) {
    state.business.videoMeetingUnsubscribe(roomId)
  },

  /* 画板新增订阅 */
  canvasSubscribe(state, res) {
    state.business.canvasSubscribe(res.roomId, res.chatMessageCallback)
  },
  /* 画板取消订阅 */
  canvasUnsubscribe(state, roomId) {
    state.business.canvasUnsubscribe(roomId)
  },

  /* 警情新增订阅 */
  gasCaseSubscribe(state, res) {
    state.business.gasCaseSubscribe(res.caseUniqueId, res.chatMessageCallback)
  },
  /* 警情会议取消订阅 */
  gasCaseUnsubscribe(state, caseUniqueId) {
    state.business.gasCaseUnsubscribe(caseUniqueId)
  },

  /* 设备抽查状态订阅 */
  spotCheckSubscribe(state, res) {
    state.business.spotCheckSubscribe(res.uniqueId, res.callback)
  },
  /* 设备抽查状态取消订阅 */
  spotCheckUnsubscribe(state, uniqueId) {
    state.business.spotCheckUnsubscribe(uniqueId)
  },

  /* 根据场景订阅的设备 */
  addSceneDevice(state, res) {
    state.business.addSceneDevice({
      ...res.obj
    })
  },
  updateDirectiveList(state, item) {
    /* 当有数据并且数据中 有未完成的时候 */
    let directiveList = window.sessionStorage.getItem('directiveList')
    if (directiveList) {
      directiveList = JSON.parse(directiveList)
    } else {
      directiveList = []
    }
    const index = directiveList.findIndex((v) => v.key === item.key)
    if (index !== -1) {
      directiveList[index] = { ...item }
    }
    state.directiveList = directiveList
    window.sessionStorage.setItem(
      'directiveList',
      JSON.stringify(directiveList)
    )
  },
  /* 新增 设备指令列表 */
  addDirectiveListFn(state, obj) {
    /* 当有数据并且数据中 有未完成的时候 */
    let directiveList = window.sessionStorage.getItem('directiveList')
    if (directiveList) {
      directiveList = JSON.parse(directiveList)
    } else {
      directiveList = []
    }
    const index = directiveList.findIndex((v) => v.key === obj.key)
    if (index !== -1) {
      directiveList[index].state = obj.state
      if (obj.errorReason) {
        directiveList[index].errorReason = obj.errorReason
      }
    } else {
      directiveList.unshift(obj)
    }
    state.directiveList = directiveList
    window.sessionStorage.setItem(
      'directiveList',
      JSON.stringify(directiveList)
    )
  },
  /* 删除 设备指令列表 */
  deleteDirectiveListFn(state, index) {
    let directiveList = window.sessionStorage.getItem('directiveList')
    if (directiveList) {
      directiveList = JSON.parse(directiveList)
    }
    directiveList.split(index, 1)
    state.directiveList = directiveList
    window.sessionStorage.setItem(
      'directiveList',
      JSON.stringify(directiveList)
    )
  }
}

const actions = {}

export default {
  state,
  getters,
  mutations,
  actions
}
