<!-- 分配规则弹窗 -->
<template>
  <el-dialog
    :visible.sync="visible"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    class="dialog_clsss_lightColor"
    custom-class="w-[720px]"
  >
    <div class="c_fff">
      <div class="pos_r dialog_header">
        <span> {{ $t('rules.allotRule') }} </span>
        <div class="imgPictureChange clickBox closeBox" @click="onClose">
          <svg-icon
            :svg-name="'delete_hover'"
            class-name="!w-[26px] !h-[26px]"
          />
          <svg-icon :svg-name="'close'" class-name="!w-[26px] !h-[26px]" />
        </div>
      </div>
      <div class="dialog_body h-[600px]">
        <AppTable
          @on-select-checkbox="selectCheckBox"
          ref="AppTable"
          :tableColumns="tableColumns"
          controller="rule/assign"
          :requestParams="requestParams"
        >
          <template #operator-left-box>
            <div class="r-f">
              <div>
                <el-button
                  size="small"
                  class="button_addTo"
                  @click="assignedBatch(1)"
                  v-if="requestParams.assigned === 1"
                >
                  <div class="centerBox">
                    {{ $t('general.assignedBatch') }}
                  </div>
                </el-button>
                <el-button
                  size="small"
                  class="button_delete"
                  @click="assignedBatch(2)"
                  v-if="requestParams.assigned === 2"
                >
                  <div class="centerBox">批量解除分配</div>
                </el-button>
              </div>
              <div class="select_darkColor ml_20">
                <el-select
                  size="medium"
                  class="!w-[140px]"
                  @change="getData"
                  v-model="requestParams.assigned"
                  placeholder="请选择活动区域"
                >
                  <el-option label="未分配" :value="1"></el-option>
                  <el-option label="已分配" :value="2"></el-option>
                </el-select>
              </div>
            </div>
          </template>
          <template #orgHeader="{ column }">
            <orgCascader
              @change="orgCascaderOnChange"
              ref="orgCascader"
              :contentType="true"
            >
              {{ column.label }}
            </orgCascader>
          </template>
          <template #deviceId="{ row }">
            {{ sensitiveFontsFn(row.deviceId) }}
          </template>

          <template #imeiList="{ row }">
            <span v-if="isShowImeiList(row)">
              <div class="flex items-center gap-x-1">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.deviceList[0].imei || '--'"
                  placement="top"
                >
                  <span class="whitespace-nowrap">
                    {{ row.deviceList[0].imei || '--' }}
                  </span>
                </el-tooltip>
                <el-popover
                  placement="bottom"
                  style="flex-shrink: 0"
                  popper-class="popover_table_clsss"
                  trigger="hover"
                >
                  <div class="popoverBox flex flex-col gap-y-2">
                    <div
                      class="flex flex-col gap-y-2 text-xs"
                      v-for="item in row.deviceList"
                      :key="item.id"
                    >
                      <div>
                        <span class="text-theme whitespace-nowrap">IMEI：</span>
                        <span class="text-white whitespace-nowrap">
                          {{ item.imei }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div slot="reference">
                    <span class="fs_12 cursor_pointer c_theme ml-1">
                      ({{ row.deviceList.length }}台)
                      <span>（查看全部）</span>
                    </span>
                  </div>
                </el-popover>
              </div>
            </span>
            <span v-else class="c_9DA7B2">--</span>
          </template>

          <template #operator="{ row }">
            <el-button
              size="mini"
              class="button_function"
              @click="assignDevice(row, 1)"
              v-if="requestParams.assigned === 1"
            >
              <div class="centerBox">
                {{ $t('rules.allotRule') }}
              </div>
            </el-button>
            <el-button
              size="mini"
              class="button_delete"
              @click="assignDevice(row, 2)"
              v-if="requestParams.assigned === 2"
            >
              <div class="centerBox">
                {{ $t('rules.deallocate') }}
              </div>
            </el-button>
          </template>
        </AppTable>
      </div>
    </div>
  </el-dialog>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
export default {
  data () {
    return {
      selectList: [],
      curRow: {},
      visible: false,
      tableColumns: [
        {
          type: 'selection'
        },
        {
          label: '所属部门',
          prop: 'orgName',
          headerSlot: 'orgHeader'
        },
        {
          label: '用户',
          isSearch: true,
          prop: 'name'
        },
        {
          label: 'IMEI',
          prop: 'status',
          width: '250px',
          customSlot: 'imeiList',
          type: 'customSlot',
        },
        {
          label: '操作',
          customSlot: 'operator',
          type: 'customSlot',
          width: '130px'
        }
      ],
      requestParams: {
        assigned: 1, // 1.已分配 2.未分配
        ruleId: '',
        type: ''
      },
      screenRow: {} // 筛选需要展示内容
    }
  },
  components: {
  },

  computed: {
    isShowImeiList() {
      return (item) => item.deviceList?.length > 0
    }
  },

  mounted() {
  },

  methods: {
    visibleChange(e, row, name) {
      this.$set(row, name, e)
    },

    getData () {
      this.selectList = []
      this.$refs.AppTable.clearSelectedList([])
      this.$refs.AppTable.getData()
    },
    onClose () {
      this.visible = false
    },
    getOrgData () {
      const orgList = []
      this.$api.organization.findAllOrg().then(res => {
        res.data.forEach(item => {
          orgList.push({
            name: item.companyName,
            id: item.id
          })
        })
        this.tableFilter[1].options = orgList
      })
    },
    show (row) {
      this.selectList = []
      this.visible = true
      this.$set(this.requestParams, 'ruleId', row.id)
      this.$set(this.requestParams, 'assigned', 1)
      this.curRow = row
      this.$nextTick(_ => {
        this.getData()
      })
    },
    assignDevice (row, type) {
      console.log(this.$api)
      this.$api.rule.assigned({
        deviceUserIdList: [
          row.deviceUserId
        ],
        type,
        ruleId: this.curRow.id
      }).then(_ => {
        this.getData()
        this.$message.success('操作成功')
      })
    },
    orgCascaderOnChange (value) {
      this.requestParams.orgId = value[value.length - 1]
      this.getData()
    },
    assignedBatch (type) {
      const deviceUserIdList = []
      if (!this.selectList.length) {
        this.$message.error('请选择用户')
        return false
      }
      this.selectList.forEach(item => {
        deviceUserIdList.push(item.deviceUserId)
      })
      this.$api.rule.assigned({
        deviceUserIdList: deviceUserIdList,
        type,
        ruleId: this.curRow.id
      }).then(_ => {
        this.getData()
        this.$message.success('操作成功')
      })
    },
    selectCheckBox (selectList) {
      this.selectList = selectList
    }
  },
}
</script>
