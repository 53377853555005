import noPermissionsMap from '@/views/noPermissionsMap/no-permissions-map.vue'

export default {
  data() {
    return {}
  },
  components: {
    noPermissionsMap
  },
  computed: {
    // 无地图权限判断
    noPermissionsMapType() {
      if (this.mainStore) {
        return this.mainStore.getters.noPermissionsMapType
      }
      return this.$store.getters.noPermissionsMapType
    }
  }
}
