import Axios from '@/http'
const mcflag = window.sessionStorage.getItem('mcflag') || null

export default {
  /* token 登录 */
  loginByToken(params = {}) {
    return new Promise((resolve, reject) => {
      Axios.post('login/loginByToken', { ...params, mcflag })
        .then((res) => {
          resolve(res)
        })
        .catch((res) => {
          reject(res)
        })
    })
  },
  /* 获取新的mq连接信息 */
  getNewMqInfo(params = {}) {
    return new Promise((resolve, reject) => {
      Axios.post('login/getNewMqInfo', { ...params, mcflag })
        .then((res) => {
          resolve(res)
        })
        .catch((res) => {
          reject(res)
        })
    })
  },
  /* 视频会议校验码登录 */
  byVideoMeetingValidCode(validCode) {
    return new Promise((resolve, reject) => {
      Axios.post('login/byVideoMeetingValidCode', { validCode, mcflag })
        .then((res) => {
          resolve(res)
        })
        .catch((res) => {
          reject(res)
        })
    })
  },
  /*  web端：只用手机号登录 */
  loginByPhoneOnly(params = {}) {
    return new Promise((resolve, reject) => {
      Axios.post('login/loginByPhoneOnly', { ...params, mcflag })
        .then((res) => {
          resolve(res)
        })
        .catch((res) => {
          reject(res)
        })
    })
  },
  /* 修改密码 */
  updatePsw(params) {
    return new Promise((resolve, reject) => {
      Axios.post('login/updatePsw', params, { isJSON: true }).then((res) => {
        return resolve(res)
      })
    })
  },
  /* 获取验证码 */
  codeMsgCode(params) {
    return new Promise((resolve, reject) => {
      Axios.post('code/msgCode', params).then((res) => {
        resolve(res)
      })
    })
  },
  /* 手机验证码登陆 */
  loginByPhoneMsg(params = {}) {
    return new Promise((resolve, reject) => {
      Axios.post('login/loginByPhoneMsg', { ...params, mcflag })
        .then((res) => {
          resolve(res)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  /* 手机密码登陆 */
  loginByPhone(params = {}) {
    return new Promise((resolve, reject) => {
      Axios.post('login/loginByPhone', { ...params, mcflag })
        .then((res) => {
          resolve(res)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  /* 退出登录 */
  logout(params) {
    return new Promise((resolve, reject) => {
      Axios.post('login/logout', params).then((res) => {
        resolve(res)
      })
    })
  }
}
