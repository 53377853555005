<!-- sos结案 -->
<template>
  <el-dialog
    :destroy-on-close="true"
    :visible.sync="dialogVisible"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    class="dialog_clsss filter-dialog"
    custom-class="w-[640px]"
  >
    <div class="deleteBox c_fff">
      <div class="pos_r dialog_header">
        <span>SOS详情</span>
        <div class="imgPictureChange clickBox closeBox" @click="onClose">
          <svg-icon
            :svg-name="'delete_hover'"
            class-name="!w-[26px] !h-[26px]"
          />
          <svg-icon :svg-name="'close'" class-name="!w-[26px] !h-[26px]" />
        </div>
      </div>
      <div class="dialog_body fs_14 c_fff lh_20">
        <div>
          <div class="r-f mb_10">
            <div class="r-f itemBox">
              <div class="titleBox">设备名称：</div>
              <div>
                {{ form.devName }}
              </div>
            </div>
            <div class="r-f itemBox">
              <div class="titleBox">设备编号：</div>
              <div>
                {{ form.deviceId }}
              </div>
            </div>
          </div>
          <div class="r-f mb_10">
            <div class="r-f itemBox">
              <div class="titleBox">使用者：</div>
              <div>
                {{ form.name }}
              </div>
            </div>
            <div class="r-f itemBox">
              <div class="titleBox">所属部门：</div>
              <div>
                {{ form.orgName }}
              </div>
            </div>
          </div>
          <div class="r-f mb_10">
            <div class="titleBox">报警时间：</div>
            <div>
              {{ form.warningDtm }}
            </div>
          </div>
          <div class="r-f mb_10">
            <div class="titleBox">SOS位置：</div>
            <div>
              {{ form.address }}
            </div>
          </div>
        </div>
        <div class="transitionBar mb_10"></div>
        <!--结案说明-->
        <div v-if="sosState">
          <div class="r-f mb_10">
            <div class="titleBox">SOS状态：</div>
            <div>已结案</div>
          </div>
          <div class="r-f mb_10">
            <div class="titleBox">结案说明：</div>
            <div>
              <div>{{ form.dealContent || '/' }}</div>
              <el-button
                size="mini"
                class="button_function mt_10"
                @click="sosFileDetailsShow"
                >查看SOS文件</el-button
              >
            </div>
          </div>
          <div class="operator-button centerBox mt_20">
            <el-button
              size="small"
              class="button_default buttonBox"
              @click="onClose"
              >关 闭</el-button
            >
          </div>
        </div>
        <div v-else>
          <div class="r-f mb_10">
            <div class="titleBox">SOS状态：</div>
            <div>未结案</div>
          </div>
          <div class="r-f mb_10">
            <div class="titleBox">结案说明：</div>
            <div style="flex-grow: 1">
              <el-form
                label-position="top"
                :model="form"
                :rules="rules"
                ref="form"
                label-width="0px"
              >
                <el-form-item prop="dealContent">
                  <el-input
                    type="textarea"
                    placeholder="请填写结案说明"
                    v-model="form.dealContent"
                    resize="none"
                    :autosize="{ minRows: 6, maxRows: 8 }"
                  >
                  </el-input>
                </el-form-item>
              </el-form>
              <el-button
                size="mini"
                class="button_function"
                @click="sosFileDetailsShow"
                >查看SOS文件</el-button
              >
            </div>
          </div>
          <div class="operator-button centerBox mt_20">
            <el-button
              size="small"
              class="button_default buttonBox"
              @click="onClose"
              >关 闭</el-button
            >
            <el-button
              size="small"
              class="button_function buttonBox"
              @click="onSubmit"
              >确 认</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <sosFileDetails ref="sosFileDetails"></sosFileDetails>
  </el-dialog>
</template>

<script>
import sosFileDetails from '@components/sosQuery/sosFileDetails.vue'
export default {
  data() {
    return {
      submitCallbackFn: null, // 成功回调
      ruleViolateId: null,
      sosState: false, // true 已结案
      form: {
        dealContent: ''
      },
      rules: {
        dealContent: [
          { required: true, message: '请填写结案说明', trigger: 'blur' }
        ]
      },
      dialogVisible: false
    }
  },
  components: {
    sosFileDetails
  },
  methods: {
    sosFileDetailsShow() {
      this.$refs.sosFileDetails.show(this.form.id)
    },
    /* 数据详情 */
    selectFn(id) {
      this.$api.select('report/sos', id).then((res) => {
        if (res.code === 200) {
          const details = res.data
          this.ruleViolateId = details.id
          if (this.$refs.form) {
            this.$refs.form.resetFields()
          }
          this.sosState = details.sosState === 1
          this.form = { ...details }
          this.$nextTick(() => {
            this.dialogVisible = true
          })
        }
      })
    },
    show(id, submitCallbackFn) {
      this.submitCallbackFn = submitCallbackFn || null
      this.selectFn(id)
    },
    onClose() {
      this.dialogVisible = false
      this.$emit('on-close')
      this.form = {
        dealContent: ''
      }
    },
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$api.report
            .sosDeal({
              id: this.form.id,
              dealContent: this.form.dealContent
            })
            .then((res) => {
              if (res.code === 200) {
                if (this.submitCallbackFn) {
                  this.submitCallbackFn(this.form)
                }
                this.$emit('on-submit')
                this.$message.success('操作成功')
                this.dialogVisible = false
              }
            })
        }
      })
    }
  }
}
</script>
<style scoped lang="less" rel="stylesheet/less">
.itemBox {
  max-width: 50%;
  flex-grow: 1;
  box-sizing: border-box;
}
.titleBox {
  color: var(--auxiliary);
  width: 80px;
  flex-shrink: 0;
}
.transitionBar {
  display: flex;
  width: 100%;
  height: 1px;
  background: linear-gradient(
    270deg,
    var(--backgroundv2) 0%,
    var(--theme-05) 51%,
    var(--backgroundv2) 100%
  );
  border-radius: 1px;
}
</style>
