<!-- 选择模板 -->
<template>
  <el-dialog
    :destroy-on-close="true"
    :visible.sync="dialogVisible"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    class="dialog_clsss filter-dialog"
    width="640px"
  >
    <div class="deleteBox c_fff">
      <div class="pos_r dialog_header">
        <span>抽查模板</span>
        <div class="iconCloseBox clickBox closeBox">
          <svg-icon
            @click.native="close()"
            svg-name="icon_close"
            className="svgBox !w-[26px] !h-[26px]"
          />
        </div>
      </div>
      <div class="dialog_body h-[60vh]">
        <AppTable
          ref="AppTable"
          :pagerCount="5"
          :tableColumns="tableColumns"
          :requestParams="requestParams"
          controller="spotCheck/template"
          :defaultGetData="false"
        >
          <template #operator-left-box="{}">
            <!--<el-button size="mini" class="button_addTo">-->
            <!--  <div class="centerBox">-->
            <!--    <svg-icon svg-name="addV2" />-->
            <!--    <span class="ml-1">添加模板</span>-->
            <!--  </div>-->
            <!--</el-button>-->
          </template>
          <template #operator="{ row }">
            <iconBox
              :width="24"
              :height="24"
              @click="selected(row)"
              imgName="icon_choice"
              altText="编 辑"
            ></iconBox>
          </template>
        </AppTable>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      tableColumns: [
        {
          label: '模板名称',
          prop: 'templateName',
          isSearch: true
        },
        {
          label: '操作',
          width: 80,
          align: 'center',
          customSlot: 'operator',
          type: 'customSlot'
        }
      ],
      requestParams: {
        checkStyle: null
      },
      dialogVisible: false,
      successfulCallback: null
    }
  },
  mounted() {
    // this.show()
  },
  methods: {
    show(params) {
      return new Promise((resolve, reject) => {
        const { checkStyle = null } = params || {}
        this.$set(this.requestParams, 'checkStyle', checkStyle)
        this.dialogVisible = true
        this.successfulCallback = resolve
        this.$nextTick(() => {
          this.getData()
        })
      })
    },
    getData() {
      this.$refs.AppTable.getData()
    },
    selected(row) {
      this.successfulCallback(row)
      this.$emit('on-selected', row)
      this.close()
    },
    close() {
      this.dialogVisible = false
    }
  }
}
</script>
<style scoped lang="less" rel="stylesheet/less"></style>
