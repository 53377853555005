import Axios from '@/http'

export default {
  infoSave(params) {
    return new Promise((resolve, reject) => {
      Axios.post('deviceUser/infoSave', params, {
        isJSON: true
      }).then((res) => {
        resolve(res)
      })
    })
  }
}
