import Axios from '@/http'

export default {
  // 发起抽查 (立即抽查)
  taskExecute(params) {
    return new Promise((resolve, reject) => {
      Axios.post('spotCheck/task/taskExecute', params, { isJSON: true }).then(
        (res) => {
          resolve(res)
        }
      )
    })
  },
  // 抽查任务（结束or取消）
  taskEnd(params) {
    return new Promise((resolve, reject) => {
      Axios.post('spotCheck/task/end', params).then((res) => {
        resolve(res)
      })
    })
  },
  // 抽查任务绑定群组id
  taskGroupBind(params) {
    return new Promise((resolve, reject) => {
      Axios.post('spotCheck/task/groupBind', params, { isJSON: true }).then(
        (res) => {
          resolve(res)
        }
      )
    })
  },
  // 任务（详情）
  taskDetail(params) {
    return new Promise((resolve, reject) => {
      Axios.post('spotCheck/task/detail', params).then((res) => {
        resolve(res)
      })
    })
  }
}
