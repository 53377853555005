<!-- 删除确认框 -->
<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    class="dialog_clsss"
    :custom-class="`w-[${form.width}px]`"
  >
    <div class="deleteBox c_fff">
      <div class="dialog_header fw_600">
        <svg-icon
          v-if="form.deleteTitleImg"
          :svg-name="form.deleteTitleImg"
          class-name="!w-[20px] !h-[20px]"
        />
        <p>{{ form.deleteTitle }}</p>
        <div
          v-if="form.closeFn"
          class="iconCloseBox clickBox closeBox"
          @click="closeFn"
        >
          <svg-icon
            svg-name="icon_close"
            className="svgBox !w-[26px] !h-[26px]"
          />
        </div>
      </div>
      <div class="dialog_body">
        <div class="tac fs_14 leading-[26px]" v-html="form.deleteContent"></div>
        <div
          v-if="form.submitShow || form.closeShow"
          class="mt-[40px]"
          :style="{ 'text-align': form.textAlign }"
        >
          <el-button
            v-if="form.closeShow"
            size="small"
            :class="form.closeClass"
            class="buttonBox"
            @click="onClose"
            >{{ form.closeText }}</el-button
          >
          <el-button
            v-if="form.submitShow"
            size="small"
            :class="form.submitClass"
            class="buttonBox"
            @click="submitFn"
            >{{ form.submitText }}</el-button
          >
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'deleteConfirmationBox',
  data() {
    return {
      successfulCallback: null,
      dialogVisible: false,
      determineFunc: null,
      closeFunc: null,
      form: {
        width: 500,
        deleteTitleImg: 'delete',
        deleteTitle: '删除用户',
        deleteContent: '删除用户',
        closeShow: true,
        closeClass: 'button_default',
        closeText: '关 闭',
        submitShow: true,
        submitClass: 'button_delete',
        submitText: '删 除',
        textAlign: 'center'
      }
    }
  },
  methods: {
    show(json, determineFunc, closeFunc, destroyFn = null) {
      return new Promise((resolve, reject) => {
        const data = {
          closeFn: null,
          width: 500,
          deleteTitleImg: 'delete',
          deleteTitle: '删除用户',
          deleteContent: '删除用户',
          closeShow: true,
          closeClass: 'button_default',
          closeText: '关 闭',
          submitShow: true,
          submitClass: 'button_delete',
          submitText: '删 除',
          textAlign: 'center'
        }
        this.determineFunc = null
        this.closeFunc = null
        this.successfulCallback = resolve
        this.form = {
          ...data,
          ...json
        }
        if (this.form.submitShow && this.form.closeShow) {
          if (determineFunc) {
            this.determineFunc = determineFunc
          }
          if (closeFunc) {
            this.closeFunc = closeFunc
          }
        } else if (this.form.submitShow) {
          if (determineFunc) {
            this.determineFunc = determineFunc
          }
        } else if (this.form.closeShow) {
          if (determineFunc) {
            this.closeFunc = determineFunc
          }
        }
        this.dialogVisible = true
      })
    },
    submitFn() {
      this.dialogVisible = false
      this.$nextTick(() => {
        if (this.determineFunc) {
          this.determineFunc()
        }
        this.$emit('on-submit')
      })
    },
    closeFn() {
      if (this.form.closeFn) {
        this.form.closeFn(() => {
          if (this.successfulCallback) {
            this.successfulCallback()
          }
          this.dialogVisible = false
        })
      } else {
        this.onClose()
      }
    },
    onClose() {
      this.dialogVisible = false
      this.$nextTick(() => {
        if (this.successfulCallback) {
          this.successfulCallback()
        }
        if (this.closeFunc) {
          this.closeFunc()
        }
        this.$emit('on-close')
      })
    }
  }
}
</script>
<style scoped lang="less" rel="stylesheet/less"></style>
