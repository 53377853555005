/* webgis各种地图坐标系之间转换（javascript版本） */
var PI = 3.14159265358979324
var a = 6378245.0
var ee = 0.00669342162296594323
const mapConfig = window.sessionStorage.getItem('mapConfig')
  ? JSON.parse(sessionStorage.getItem('mapConfig'))
  : {}

// 坐标系
const Coordinate = {
  'WGS84': 0,
  'GCJ02': 1,
}
// mapCoordinate 地图坐标系, serverCoordinate 服务端坐标系
const { mapCoordinate = 0, serverCoordinate = 0 } = mapConfig

function delta(lat, lon) {
  const a = 6378245.0 //  a: 卫星椭球坐标投影到平面地图坐标系的投影因子。
  const ee = 0.00669342162296594323 //  ee: 椭球的偏心率。
  let dLat = transformLat(lon - 105.0, lat - 35.0)
  let dLon = transformLon(lon - 105.0, lat - 35.0)
  const radLat = (lat / 180.0) * PI
  let magic = Math.sin(radLat)
  magic = 1 - ee * magic * magic
  const sqrtMagic = Math.sqrt(magic)
  dLat = (dLat * 180.0) / (((a * (1 - ee)) / (magic * sqrtMagic)) * PI)
  dLon = (dLon * 180.0) / ((a / sqrtMagic) * Math.cos(radLat) * PI)
  return {
    lat: dLat,
    lon: dLon
  }
}
function transformLat(x, y) {
  let ret =
    -100.0 +
    2.0 * x +
    3.0 * y +
    0.2 * y * y +
    0.1 * x * y +
    0.2 * Math.sqrt(Math.abs(x))
  ret +=
    ((20.0 * Math.sin(6.0 * x * PI) + 20.0 * Math.sin(2.0 * x * PI)) * 2.0) /
    3.0
  ret +=
    ((20.0 * Math.sin(y * PI) + 40.0 * Math.sin((y / 3.0) * PI)) * 2.0) / 3.0
  ret +=
    ((160.0 * Math.sin((y / 12.0) * PI) + 320 * Math.sin((y * PI) / 30.0)) *
      2.0) /
    3.0
  return ret
}
function transformLon(x, y) {
  let ret =
    300.0 +
    x +
    2.0 * y +
    0.1 * x * x +
    0.1 * x * y +
    0.1 * Math.sqrt(Math.abs(x))
  ret +=
    ((20.0 * Math.sin(6.0 * x * PI) + 20.0 * Math.sin(2.0 * x * PI)) * 2.0) /
    3.0
  ret +=
    ((20.0 * Math.sin(x * PI) + 40.0 * Math.sin((x / 3.0) * PI)) * 2.0) / 3.0
  ret +=
    ((150.0 * Math.sin((x / 12.0) * PI) + 300.0 * Math.sin((x / 30.0) * PI)) *
      2.0) /
    3.0
  return ret
}


/**
 *  exportCoordinate （导出坐标）
 *  服务端给前端
 */
export function exportCoordinate(latLng = {}) {
  const { lat, lng } = latLng
  if (!lat || !lng) {
    return latLng
  }
  if (mapCoordinate !== serverCoordinate) {
    if (mapCoordinate === Coordinate.GCJ02 && serverCoordinate === Coordinate.WGS84) {
      return WGStransformGCJ2(lat, lng)
    } else if (mapCoordinate === Coordinate.WGS84 && serverCoordinate === Coordinate.GCJ02) {
      return GCJ2transformWGS(lat, lng)
    }
  }
  return latLng
}

/**
 *  afferentCoordinate （传入坐标）
 *  前端给服务端，经纬度判断
 */
export function afferentCoordinate(latLng) {
  const { lat, lng } = latLng
  if (!lat || !lng) {
    return latLng
  }
  if (mapCoordinate !== serverCoordinate) {
    if (serverCoordinate === Coordinate.WGS84 && mapCoordinate === Coordinate.GCJ02) {
      return GCJ2transformWGS(lat, lng)
    } else if (serverCoordinate === Coordinate.GCJ02 && mapCoordinate === Coordinate.WGS84) {
      return WGStransformGCJ2(lat, lng)
    }
  }
  return latLng
}


// GCJ02 转 WGS84
export function GCJ2transformWGS  (gcjLat, gcjLon) {
  const d = delta(gcjLat, gcjLon)
  return {
    lat: gcjLat - d.lat,
    lng: gcjLon - d.lon
  }
}

// WGS84 转 GCJ02
export function WGStransformGCJ2  (wgLat, wgLon) {
  var marsPoint = { lng: 0, lat: 0 }
  var dLat = transformLat(wgLon - 105.0, wgLat - 35.0)
  var dLon = transformLon(wgLon - 105.0, wgLat - 35.0)
  var radLat = (wgLat / 180.0) * PI
  var magic = Math.sin(radLat)
  magic = 1 - ee * magic * magic
  var sqrtMagic = Math.sqrt(magic)
  dLat = (dLat * 180.0) / (((a * (1 - ee)) / (magic * sqrtMagic)) * PI)
  dLon = (dLon * 180.0) / ((a / sqrtMagic) * Math.cos(radLat) * PI)
  marsPoint.lat = wgLat + dLat
  marsPoint.lng = wgLon + dLon
  return marsPoint
}
