<!-- 险情查询 -->
<template>
  <AppTable
    ref="AppTable"
    :tableColumns="tableColumns"
    controller="gasCase"
    :requestParams="requestParams"
  >
    <template #operator-left-box>
      <div class="">
        <slot name="unreadType"></slot>
      </div>
    </template>
    <template #unread="{ row }">
      <div v-if="!row.hadRead" class="unread"></div>
    </template>
    <template #caseAddress="{ row }">
      <div class="over_one_line">
        <span v-if="row.caseAddress">{{ row.caseAddress }}</span>
        <span v-else class="text-auxiliary">- -</span>
      </div>
    </template>
    <template #caseStatus="{ row }">
      <div class="text-xs text-dangerv2">
        <span v-if="row.caseStatus === 1">接警</span>
        <span v-else-if="row.caseStatus === 2">出警</span>
        <span v-else-if="row.caseStatus === 3">排险</span>
        <span v-else-if="row.caseStatus === 4">抢修</span>
        <span v-else-if="row.caseStatus === 5" class="text-success">复气</span>
        <span v-else class="text-auxiliary">- -</span>
      </div>
    </template>
    <template #operator="{ row }">
      <iconBox
        imgName="icon_getInto"
        classNmae="iconBoxV2"
        altText="处理警情"
        class="text-function"
        @click.native="jumpToAlert(row)"
      ></iconBox>
    </template>
  </AppTable>
</template>

<script>
import { DangerTypes } from '@/types/dangerType.js'

export default {
  props: {
    unreadType: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  name: 'gasCaseQuery',
  components: {},
  data() {
    return {
      today: null,
      queryDtm: null,
      requestParams: {},
      tableColumns: [
        {
          label: '时间',
          prop: 'sendDtm',
          width: '160px'
        },
        {
          label: '地址',
          customSlot: 'caseAddress',
          overflowTooltip: true,
          type: 'customSlot'
        },
        {
          label: '状态',
          customSlot: 'caseStatus',
          type: 'customSlot',
          width: '80px'
        },
        {
          label: '操作',
          customSlot: 'operator',
          type: 'customSlot',
          width: '80px'
        }
      ],
      dailyCheckList: []
    }
  },
  methods: {
    appTableGetData() {
      this.$refs.AppTable.getData()
    },
    copy(random) {
      var input = document.createElement('input') // 创建input对象
      input.value = random // 设置复制内容
      document.body.appendChild(input) // 添加临时实例
      input.select() // 选择实例内容
      document.execCommand('Copy') // 执行复制
      document.body.removeChild(input) // 删除临时实例
      this.$message.success('复制成功')
    },

    async jumpToAlert(item) {
      const { code, data } = await this.$api.dangerList.openGasCaseModeStatus()

      console.log('jumpDanger', data)

      // 已打开险情，跳转指定标签页
      if (code !== 200) return
      if (data === 1) {
        const url = `/dispatching#/dangerMode?gasCaseId=${item.id}&dangerType=${
          DangerTypes.DANGER_MODE
        }&uuId=${this.createUniqueId(item.id)}`
        return window.open(url, 'dangerMode')
      }

      // 未打开险情，跳转新的标签页
      const token = this.$store.getters.userInfo.token
      const newOpenWindow = window.open('about:blank')
      const url = `/login/?token=${token}&gasCaseId=${item.id}&patterns=gasCase&dangerType=${DangerTypes.DANGER_MODE}`

      if (newOpenWindow) {
        newOpenWindow.name = 'dangerMode'
        newOpenWindow.location = url
      }

      if (newOpenWindow) {
        this.$message.success('操作成功')
      } else {
        this.$deleteConfirmationBox(
          {
            deleteTitleImg: 'tips',
            deleteTitle: '险情模式提示',
            deleteContent:
              '<div><p>当前浏览器拦截新窗口打开</p><p>请前往设置后在险情模式 或 复制链接到浏览器打开</p></div>',
            submitClass: 'button_function',
            submitText: '复制链接'
          },
          () => {
            this.copy(`${window.location.origin}${url}`)
          }
        )
      }

      // const token = this.$store.getters.userToken
      // console.log(token)
      // const newOpenWindow = window.open('about:blank')
      // const url = `/login/?patterns=gasCase&token=${token}&gasCaseId=${item.id}&dangerType=${DangerTypes.DANGER_MODE}`
      // if (newOpenWindow) {
      //   newOpenWindow.location = url
      //   return
      // }
      // this.$deleteConfirmationBox(
      //   {
      //     deleteTitleImg: 'tips',
      //     deleteTitle: '新窗口提示',
      //     deleteContent:
      //       '<div><p>当前浏览器拦截新窗口打开</p><p>请前往浏览器设置后 或 复制链接到浏览器打开</p></div>',
      //     submitClass: 'button_function',
      //     submitText: '复制链接'
      //   },
      //   () => {
      //     this.copy(`${window.location.origin}${url}`)
      //   }
      // )
    }
  },
  mounted() {
    if (this.unreadType) {
      this.tableColumns.push({
        label: null,
        width: '30px',
        customSlot: 'unread',
        type: 'customSlot'
      })
    }
  }
}
</script>

<style scoped lang="less" rel="stylesheet/less">
.el-row::after,
.el-row::before {
  display: none;
}
.unread {
  content: '';
  width: 10px;
  height: 10px;
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 50%;
  background: var(--dangerv2);
  box-shadow: 0px 0px 6px 1px var(--dangerv2);
}
</style>
