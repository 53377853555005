var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.selectValue || (_vm.selectValue && _vm.selectedList.length))?_c('el-select',{class:_vm.clearable
      ? _vm.selectValue
        ? 'remoteSelect_have'
        : 'remoteSelect_no'
      : 'remoteSelect_no',attrs:{"placeholder":_vm.placeholder,"multiple":_vm.multiple,"size":_vm.size,"filterable":"","remote":"","loading":(_vm.loading && _vm.page === 0) || _vm.options.length === 0,"loading-text":_vm.loading ? '加载中' : '无数据',"clearable":_vm.clearable,"remote-method":_vm.remoteMethod},on:{"visible-change":_vm.visibleChange,"change":_vm.handleChange},model:{value:(_vm.selectValue),callback:function ($$v) {_vm.selectValue=$$v},expression:"selectValue"}},[(_vm.selectedList.length)?_c('el-option-group',{style:({ display: _vm.multiple ? 'block' : 'none' }),attrs:{"label":"已选择"}},[_c('div',{staticClass:"max-h-[70px] overflow-y-auto"},_vm._l((_vm.selectedList),function(item,index){return _c('el-option',{key:index + '_selectedList',attrs:{"label":item[`${_vm.optionLabel}`],"value":item[`${_vm.optionValue}`]}})}),1)]):_vm._e(),_c('el-option-group',{attrs:{"label":`${_vm.multiple ? '请选择' : ''}`}},[(_vm.visibleChangeType)?_c('virtual-list',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadmore),expression:"loadmore"}],staticClass:"max-h-[200px] overflow-y-auto",attrs:{"data-key":'id',"data-sources":_vm.options,"data-component":_vm.selectComponent,"extra-props":{
        optionLabel: _vm.optionLabel,
        optionValue: _vm.optionValue
      },"keeps":10,"estimate-size":34}}):_vm._e()],1),(_vm.loading)?_c('p',{staticClass:"centerBox fs_12 c_9DA7B2 mt_10"},[_vm._v("加载中...")]):_vm._e()],1):_c('el-select',{class:_vm.clearable
      ? _vm.selectValue
        ? 'remoteSelect_have'
        : 'remoteSelect_no'
      : 'remoteSelect_no',attrs:{"placeholder":_vm.placeholder,"multiple":_vm.multiple,"size":_vm.size,"filterable":"","remote":"","clearable":_vm.clearable,"remote-method":_vm.remoteMethod},on:{"visible-change":_vm.visibleChange,"change":_vm.handleChange},model:{value:(_vm.selectValue),callback:function ($$v) {_vm.selectValue=$$v},expression:"selectValue"}},[_c('el-option-group',{attrs:{"label":"请选择"}},[(_vm.visibleChangeType)?_c('virtual-list',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadmore),expression:"loadmore"}],staticClass:"max-h-[200px] overflow-y-auto",attrs:{"data-key":'id',"data-sources":_vm.options,"data-component":_vm.selectComponent,"extra-props":{
        optionLabel: _vm.optionLabel,
        optionValue: _vm.optionValue
      },"keeps":10,"estimate-size":34}}):_vm._e()],1),(_vm.loading)?_c('p',{staticClass:"centerBox fs_12 c_9DA7B2 mt_10"},[_vm._v("加载中...")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }