<!-- 多群组 item群组 -->
<template>
  <div class="w-full h-full box-border flex">
    <div class="flex-grow w-full h-full flex flex-col">
      <!-- 正在讲话标识 -->
      <div
        :class="
          currentSpeech || audioContextType
            ? 'text-main border-theme'
            : 'text-auxiliary border-backgroundv2'
        "
        :style="{
          background:
            currentSpeech || audioContextType
              ? 'var(--background-gradientV2)'
              : 'var(--backgroundv2)'
        }"
        class="h-[30px] box-border duration-500 border rounded flex items-center justify-center mb-1 w-full relative"
      >
        <div v-if="audioContextType" class="flex items-center">
          <svg-icon
            svg-name="talking"
            className="svgBox !w-[14px] !h-[14px] rotate-180"
          />
          <span class="mx-1">正在讲话中…</span>
          <svg-icon svg-name="talking" className="svgBox !w-[14px] !h-[14px]" />
        </div>
        <div v-else-if="currentSpeech" class="flex items-center">
          <svg-icon
            svg-name="talking"
            className="svgBox !w-[14px] !h-[14px] rotate-180"
          />
          <div class="mx-1">
            <span> {{ currentSpeech.name || '使用者' }}</span>
            <span
              >_{{ currentSpeech.userClientId || currentSpeech.deviceId }}</span
            >
          </div>
          <svg-icon svg-name="talking" className="svgBox !w-[14px] !h-[14px]" />
        </div>
        <div
          v-else
          class="w-[22px] h-[22px] border border-border rounded-full flex items-center justify-center"
        >
          <svg-icon
            svg-name="noOneSpeaks"
            className="svgBox !w-[14px] !h-[14px]"
          />
        </div>
      </div>
      <div
        class="flex-grow h-0 bg-backgroundv2 rounded overflow-hidden flex flex-col"
      >
        <!-- 对讲记录 -->
        <div class="flex-grow h-0 w-full">
          <itemTalkback
            v-if="orgGroupInsertTemp.id"
            :orgGroupInsertTemp="orgGroupInsertTemp"
            ref="itemTalkback"
          ></itemTalkback>
        </div>
        <div
          v-show="microphoneType"
          @click="setMicrophoneType()"
          class="text-dangerv2 border border-dangerv2 flex items-center justify-center h-[28px] relative clickBox"
        >
          <span class="font-bold">当前群组已闭麦</span>
          <el-tooltip
            class="item"
            :open-delay="500"
            effect="dark"
            content="“闭麦状态下语音消息将不对此群组发送，点击开启或左侧群组列表中开启麦克风权限”"
            placement="top"
          >
            <span class="fs_12 !text-theme absolute right-[10px]">
              <svg-icon
                svg-name="help"
                class-name="svgBox ml-1 !w-[12px] !h-[12px]"
              />
              帮助
            </span>
          </el-tooltip>
        </div>
        <!-- 麦克风开关 -->
        <div
          @mousedown="audioConsumerResume"
          @mouseup="audioConsumerPaused"
          @touchstart="audioConsumerResume"
          @touchend="audioConsumerPaused"
          class="h-[30%] border-t border-border max-h-[96px] text-xs text-auxiliary point_area overflow-hidden"
        >
          <div
            class="w-full h-full flex flex-col items-center justify-center relative z-10"
          >
            <div class="game_time css-text-no-select pos_r cursor_pointer">
              <div class="hold">
                <div
                  class="pie pie1"
                  style="transition: 0.5s"
                  :style="{
                    transform: `rotate(${
                      (countDown / 60) * 100 < 50
                        ? '180'
                        : (100 - (countDown / 60) * 100) * 3.6
                    }deg)`
                  }"
                ></div>
              </div>
              <div class="hold">
                <div
                  v-if="(countDown / 60) * 100 <= 50"
                  :style="{
                    transition: (countDown / 60) * 100 < 49 ? '0.5s' : '0s',
                    background: (countDown / 60) * 100 <= 50 ? '#292F3A' : '',
                    transform: `rotate(${
                      (100 - (countDown / 60) * 100 - 50) * 3.6 + 180
                    }deg)`
                  }"
                  class="pie pie2"
                ></div>
                <div v-else class="pie pie2"></div>
              </div>
              <div class="bg"></div>
              <div class="c_fff countDownBox juzhong">
                <div class="countDownBox_item centerBox">
                  {{ countDown }}<span>"</span>
                </div>
              </div>
            </div>
            <div class="mt-1">
              <span v-show="!audioContextType"
                >长按空格 或 鼠标长按此按钮讲话</span
              >
              <span v-show="audioContextType">您正在讲话</span>
            </div>
          </div>
          <div
            v-show="countDown !== 60"
            class="w-[390px] h-[390px] absolute top-[38%] left-1/2 -translate-y-1/2 -translate-x-1/2"
          >
            <div class="point point_1"></div>
            <div class="point point_2"></div>
            <div class="point point_3"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import multiPersonIntercom from '@components/device/multiPersonIntercom/multiPersonIntercom/js/index.js'
import itemTalkback from './itemTalkback.vue'
export default {
  mixins: [multiPersonIntercom],
  data() {
    return {}
  },
  components: {
    itemTalkback
  },
  mounted() {
    // this.intercomNatureGroupIn(92)
  },
  methods: {
    // 对讲日志
    orgGroupIntercomFn() {},
    getSkdRoomInformation() {
      return {}
    }
  }
}
</script>
<style scoped lang="less" rel="stylesheet/less">
.game_time {
  width: 60px;
  height: 60px;
  .countDownBox {
    width: 50px;
    height: 50px;
    padding: 2px;
    box-sizing: border-box;
    background: var(--theme);
    border-radius: 50%;
    position: relative;
    z-index: 1;
    .countDownBox_item {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-image: linear-gradient(
        180deg,
        var(--backgroundv2) 0%,
        var(--theme-05) 100%
      );
      font-size: 20px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: var(--main);
    }
  }
  .pie {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    position: absolute;
    background: var(--theme-02);
  }
  .pie1 {
    clip: rect(0px, 60px, 60px, 30px);
    -o-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  .pie2 {
    clip: rect(0px, 30px, 60px, 0px);
    -o-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  .hold {
    width: 60px;
    height: 60px;
    position: absolute;
    z-index: 1;
  }
  .bg {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: absolute;
    background: var(--backgroundv2);
  }
}
.point_area {
  text-align: center;
  position: relative;
  transition: opacity 0.5s ease-out;
}
.point_area .point {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  -webkit-border-radius: 50%;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 50%;
  -moz-background-clip: padding;
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    border: 1px solid var(--theme);
    box-shadow: 0 0 40px 10px var(--theme) inset;
    opacity: 0;
    //opacity: 1;
  }
}
.point_area .point_1:after {
  -webkit-animation: ripple 4.5s ease-out 225ms infinite;
  animation: ripple 2.5s ease-out 225ms infinite;
}
.point_area .point_2:after {
  -webkit-animation: ripple 4.5s ease-out 0.9s infinite;
  animation: ripple 2.5s ease-out 0.9s infinite;
}
.point_area .point_3:after {
  -webkit-animation: ripple 4.5s ease-out 1.8s infinite;
  animation: ripple 2.5s ease-out 1.8s infinite;
}
@-webkit-keyframes ripple {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.1);
  }
  5% {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: scale(1);
  }
}
@keyframes ripple {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
  }
  5% {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
</style>
