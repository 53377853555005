import Axios from '@/http'
export default {
  orgTreeWithWaterPoint() {
    return new Promise((resolve, reject) => {
      Axios.post('waterPoint/orgTreeWithWaterPoint').then((res) => {
        resolve(res)
      })
    })
  }
}
