/* 警单长时间未处理通知 */
import alarmRecordLongTimeNotDeal from '@components/alarmRecord/alarmRecordLongTimeNotDeal'
import newAlarm from '@components/alarmRecord/newAlarm'
import alarmRecordMonitor from '@/components/alarmRecord/monitor/monitor.vue'
// import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      operatorInfo: {}
    }
  },
  mounted() {},
  computed: {
    // ...mapGetters(['alarmRecordOperatorInfo'])
  },
  components: {
    alarmRecordLongTimeNotDeal,
    newAlarm,
    alarmRecordMonitor
  },
  methods: {
    // 取消订阅监听
    setAlarmRecordSubscribe() {
      this.$store.commit(
        'deleteUserOperationCallback',
        'headerBox_alarmLongTimeNotDeal'
      )
      this.$store.commit(
        'deleteUserOperationCallback',
        'headerBox_alarmDeviceOffline'
      )
      this.$store.commit(
        'deleteUserOperationCallback',
        'headerBox_alarmNumChange'
      )
      this.$store.commit(
        'deleteUserOperationCallback',
        'headerBox_alarmReception'
      )
    },
    // 订阅监听
    setAlarmRecordUnsubscribe() {
      this.alarmLongTimeNotDeal()
      this.alarmDeviceOffline()
      this.alarmNumChange()
      this.alarmReception()
    },

    async alarmRecord() {
      const data = await this.alarmRecordOperatorInfo()
      this.alarmOperatorAuth()
      if (data.alarmOperator === 1) {
        // 监听
        this.setAlarmRecordUnsubscribe()
        // 获取用户状态数据 和 正在处理警单数据
        if (data.operatorState === 2) {
          this.alarmRecorDealing()
        }
      }
    },
    // 接警员信息（是否是接警人员、当前接警状态）
    async alarmRecordOperatorInfo() {
      const { code, data } = await this.$api.alarmRecord.operatorInfo()
      if (code === 200) {
        // data.alarmOperator [是否是接警人员]{1:是;}
        // data.operatorState 接警员当前状态（1空闲、2接警中）
        this.operatorInfo = { ...data }
        this.$store.commit('setAlarmRecordOperatorInfo', { ...data })
        return data
      }
    },
    // 处理中警单（不分页）
    async alarmRecorDealing() {
      const { code, data = [] } = await this.$api.findAll('alarmRecord/dealing')
      if (code === 200) {
        if (data.length !== 0) {
          this.$deleteConfirmationBox(
            {
              deleteTitleImg: 'tips',
              deleteTitle: '警情未结束',
              deleteContent: `<div><p>当前仍有警单未处理完结，请继续处理</p></div>`,
              closeShow: false,
              submitClass: 'button_function',
              submitText: '前往处理'
            },
            () => {
              this.alarmRecordMonitorShow(data[0])
            }
          )
        }
      }
    },
    alarmRecordMonitorShow(device) {
      this.$store.commit('callComponent', {
        name: 'multipleChecks',
        data: {
          methodName: 'closeTheRoom',
          params: [device],
          paramsV2: () => {
            this.$refs.alarmRecordMonitor?.show(device)
          }
        }
      })
    },
    // 接警人员身份变动通知
    alarmOperatorAuth() {
      // method：
      const callback = (res) => {
        console.log('res', res)
        // data：userId、alarmOperator（是否是接警员 0不是 1是）
        if (this.alarmRecordOperatorType) return
        this.alarmRecordOperatorInfo()
        const { userId, alarmOperator } = res
        if (this.userInfo.id === userId) {
          if (alarmOperator === 1) {
            // 订阅
            this.setAlarmRecordUnsubscribe()
          } else {
            // 取消订阅
            this.setAlarmRecordSubscribe()
          }
        }
      }
      this.$store.commit('addUserOperationCallback', {
        id: 'headerBox_alarmOperatorAuth',
        operationName: 'alarmOperatorAuth',
        callback
      })
    },
    // 警单长时间未处理通知
    alarmLongTimeNotDeal() {
      const callback = (res) => {
        //  data:id（警单id）、deviceId（设备ID）、devName（设备名称）、incidentAddress（事发地址）、orgName（部门名称）、alarmDtm（报警时间）
        const { id } = res
        if (this.alarmRecordOperatorType) return
        this.$refs.alarmRecordLongTimeNotDeal?.show(id)
      }
      this.$store.commit('addUserOperationCallback', {
        id: 'headerBox_alarmLongTimeNotDeal',
        operationName: 'alarmLongTimeNotDeal',
        callback
      })
    },
    // 报警设备下线通知
    alarmDeviceOffline() {
      const h = this.$createElement
      const callback = async (res) => {
        if (this.alarmRecordOperatorType) return
        // data：deviceId（设备ID）、devName（设备名称）
        const { deviceId, devName } = res || {}
        const onView = async () => {
          this.$store.commit('setMapSetPosition', {
            deviceId,
            moveType: true
          })
        }
        this.$message({
          dangerouslyUseHTMLString: true,
          type: 'warning',
          message: h(
            'div',
            {
              style: 'width: 300px;color: #F8A62D;',
              class: 'flex text-xs justify-between'
            },
            [
              h(
                'div',
                {
                  class: 'flex-grow w-0'
                },
                `${devName || '设备名称'}（${deviceId || 'IMEI'}） 下线提醒`
              ),
              h(
                'div',
                {
                  class: 'flex-shrink-0 ml-1 clickBox',
                  on: {
                    click: onView
                  }
                },
                [h('u', null, '前往查看 ')]
              )
            ]
          )
        })
      }
      this.$store.commit('addUserOperationCallback', {
        id: 'headerBox_alarmDeviceOffline',
        operationName: 'alarmDeviceOffline',
        callback
      })
    },
    // 待处理警单数量变动通知（收到此通知，web端刷新待处理警单列表）：
    alarmNumChange() {
      const callback = (res) => {
        // data:id（警单id）、changeType（类型：dealStart（被接警）、notDeal（未被接警））
        const { id } = res
        this.$refs.alarmRecordLongTimeNotDeal?.alarmNumChange(id)
      }
      this.$store.commit('addUserOperationCallback', {
        id: 'headerBox_alarmNumChange',
        operationName: 'alarmNumChange',
        callback
      })
    },
    // 接警通知
    alarmReception() {
      console.log('alarmReception')
      const callback = (res) => {
        const draggedType = res.draggedType
        // data:id（警单id）、deviceId（设备ID）、devName（设备名称）、incidentAddress（事发地址）、orgName（部门名称）、alarmDtm（报警时间）
        this.$refs.newAlarm?.show(
          {
            type: 'alarmRecord',
            imgUrl: 'newCase.png',
            title: '报警器报警',
            content: null,
            draggedType: typeof draggedType === 'boolean' ? draggedType : true,
            contentSvgName: 'sos-ring',
            headerHeight: this.headerHeight
          },
          res
        )
      }
      this.$store.commit('addUserOperationCallback', {
        id: 'headerBox_alarmReception',
        operationName: 'alarmReception',
        callback
      })
    },
    newAlarmSubmit(res) {
      this.$refs.newAlarm?.onClose()
      if (this.alarmRecordOperatorType) {
        return this.$message.error('当前处于接警中，无法进行此操作')
      }
      if (this.operatorInfo.alarmOperator === 0) {
        return this.$message.error('当前非接警员，无法处理警情')
      }
      this.$store.commit('setMapSetPosition', {
        ...res,
        moveType: true
      })
      this.$refs.alarmRecordMonitor?.dealStart(res.id)
      this.alarmRecordMonitorShow(res)
    }
  }
}
