<!--  添加定时回传 -->
<template>
  <div class="percent-100">
    <AppTable
      ref="AppTable"
      :tableColumns="tableColumns"
      controller="rule"
      :requestParams="requestParams"
    >
      <template #operator-left-box>
        <div class="r-f" style="align-items: center">
          <el-button
            size="small"
            class="button_addTo"
            @click="addEditTimerReturnShow()"
          >
            <div class="centerBox">
              <svg-icon svg-name="addV2" />
              <span class="ml-[6px]">添加定时回传</span>
            </div>
          </el-button>
          <span
            style="color: var(--fontSize-auxiliary-color)"
            class="fs_14 ml_20"
            >规则使用说明：1.添加规则 2.设置自定义的规则条件
            3.并把需要执行此规则的人员授权给它</span
          >
        </div>
      </template>
      <template #attendanceTime="{ row }">
        <div v-if="row.ruleDateType === 1">
          {{ $t('tasksOfTheDay.Everyday') }}
          <span class="c_9DA7B2 fs_12"
            >({{ row.startDtm1 }}-{{ row.endDtm1 }}})</span
          >
        </div>
        <div v-else-if="row.ruleDateType === 2">
          {{ weekDateFn(row.weekDate) }}
          <span class="c_9DA7B2 fs_12"
            >({{ row.startDtm1 }}-{{ row.endDtm1 }}})</span
          >
        </div>
        <div v-else-if="row.ruleDateType === 4">
          {{ row.apStartDtm.split(' 00:00:00')[0] }} ~
          {{ row.apEndDtm.split(' 00:00:00')[0] }}
          <span class="c_9DA7B2 fs_12"
            >({{ row.startDtm1 }}-{{ row.endDtm1 }}})</span
          >
        </div>
      </template>
      <template #operator="{ row }">
        <iconBox
          imgName="fenPei"
          :altText="$t('rules.allotRule')"
          @click="allocationRule(row)"
        ></iconBox>
        <iconBox
          imgName="edit"
          :altText="$t('general.edit')"
          @click="addEditTimerReturnShow(row)"
        ></iconBox>
        <iconBox
          classNmae="shadow_delete"
          imgName="delete"
          altText="删 除"
          @click="deleteRow(row)"
        ></iconBox>
      </template>
    </AppTable>

    <addEditTimerReturn
      @on-submit="getData"
      ref="addEditTimerReturn"
    ></addEditTimerReturn>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
import addEditTimerReturn from '@components/ruleSetting/addEditTimerReturn'
export default {
  components: {
    addEditTimerReturn
  },
  data () {
    return {
      // [规则类型]{1:禁入;2:禁出;3:静默;4:定时拍照;5:定时录像;6:定时回传;}
      requestParams: {
        type: 6
      },
      tableColumns: [
        {
          label: this.$t('rules.ruleName'),
          prop: 'ruleName',
          isSearch: true
        },
        {
          label: '工作时间',
          customSlot: 'attendanceTime',
          type: 'customSlot'
        },
        {
          label: '操作',
          customSlot: 'operator',
          type: 'customSlot',
          width: '250px'
        }
      ]
    }
  },
  methods: {
    getData () {
      this.$refs.AppTable.getData()
    },
    addEditTimerReturnShow (row) {
      this.$refs.addEditTimerReturn.show(row)
    },
    weekDateFn (weekDate) {
      weekDate = weekDate.split(',')
      let data = ''
      weekDate.forEach(v => {
        v -= 0
        if (v === 1) {
          data += '星期一，'
        } else if (v === 2) {
          data += '星期二，'
        } else if (v === 3) {
          data += '星期三，'
        } else if (v === 4) {
          data += '星期四，'
        } else if (v === 5) {
          data += '星期五，'
        } else if (v === 6) {
          data += '星期六，'
        } else if (v === 7) {
          data += '星期日,'
        }
      })
      return data.substring(data.length - 1, 0)
    },
    allocationRule (row) {
      this.$emit('on-ruleDeviceDialogShow', row)
    },
    deleteRow (row) {
      this.$deleteConfirmationBox({
        deleteTitle: '删除规则',
        deleteContent: '<div><p>确认删除当前规则？</p></div>',
        submitText: '确 定'
      }, () => {
        this.$api.deleteBatch('rule', row.id).then(() => {
          this.$message.success('删除成功')
          this.$refs.AppTable.getData()
        })
      })
    }
  },
  mounted () {
  }
}
</script>
