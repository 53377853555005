import Axios from '@/http'

export default {
  /* 工单-撤销 */
  cancel(id) {
    return new Promise((resolve, reject) => {
      Axios.post('workOrder/cancel', { id }, { isJSON: true }).then((res) => {
        resolve(res)
      })
    })
  },
  /* 工单-处理 */
  deal(params) {
    return new Promise((resolve, reject) => {
      Axios.post('workOrder/deal', params, { isJSON: true }).then((res) => {
        resolve(res)
      })
    })
  }
}
