import oss from '@/utils/oss'
const department = () => import('@components/device/treeItem/department')
export default {
  props: {
    deviceIdList: {
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      limit: ' @#,|? \\<\\>"\':*\\/$%^&',
      form: {
        content: null,
        addToTem: false,
        playMsg: false
      },
      loading: false,
      imagesList: [],
      videoList: [],
      uploadObj: {},
      selectList: []
    }
  },
  components: {
    department
  },
  // computed: {},
  mounted() {
    if (this.deviceIdList.length) {
      this.onClickTree(this.deviceIdList)
      this.$nextTick(() => {
        this.setCheckedKeysFn()
      })
    } else {
      this.onClickTree([])
    }
    this.resetFn()
  },
  // created(){ // document.title = this.type === 2 ? '设置手机号' : '绑定手机号' },
  methods: {
    deleteSelectList(index) {
      this.selectList.splice(index, 1)
      this.$refs.department?.setCheckedKeysFn(this.selectList)
    },
    setCheckedKeysFn() {
      this.$refs.department?.setCheckedKeysFn(this.deviceIdList)
    },
    onClickTree(selectList) {
      this.selectList = selectList
    },
    show(obj) {
      this.loading = false
      this.form = {
        ...obj,
        addToTem: false
      }
      if (obj.playMsg === 1) {
        this.$set(this.form, 'playMsg', true)
      }
      if (obj.fileType === 1) {
        this.imagesList = obj.imagesList
      } else {
        this.videoList = obj.imagesList
      }
    },
    resetFn() {
      this.form = {
        content: null,
        addToTem: false,
        playMsg: false
      }
      this.loading = false
      this.imagesList = []
      this.videoList = []
    },
    onClose() {
      this.$emit('on-close')
    },
    /* type 1固定 2发送 */
    submitFn(type) {
      if (!this.form.content) {
        this.$message.error('请填写内容')
        return
      }
      let fileType = null
      let fileUrls = null
      if (this.imagesList.length) {
        fileType = 1
        fileUrls = this.imagesList.toString()
      }
      if (this.videoList.length) {
        fileType = 2
        fileUrls = this.videoList.toString()
      }
      const json = {
        content: this.form.content,
        fileType,
        fileUrls
      }
      if (this.form.addToTem) {
        json.addToTem = 1
      } else {
        json.addToTem = 0
      }
      if (this.form.playMsg) {
        json.playMsg = 1
      } else {
        json.playMsg = 0
      }
      let urlName = null
      if (type === 1) {
        urlName = 'deviceMsg/template'
      } else {
        if (this.selectList.length === 0) {
          this.$message.error('请选择设备')
          return
        }
        urlName = 'deviceMsg'
        json.deviceIdList = this.selectList.map((v) => v.deviceId)
      }
      this.$api.save(urlName, json).then((res) => {
        if (res.code === 200) {
          this.resetFn()
          this.$message.success('操作成功 !')
        }
      })
    },
    deleteRow(type, index) {
      if (type === 1) {
        this.imagesList.splice(index, 1)
      } else {
        this.videoList.splice(index, 1)
      }
    },
    beforeAvatarUpload(file) {
      if (this.loading) {
        this.$message.error('文件正在上传中，请稍后在试')
        return false
      }
      const isLt2M = file.size / 1024 / 1024 < 10
      if (!isLt2M) {
        this.$message.error('文件上传失败，文件大小10M以内。')
        return false
      }
      this.uploadObj = {
        fileUrl: null,
        type: null
      }
      const type = file.type
      if (type.indexOf('image') !== -1) {
        this.$set(this.uploadObj, 'type', 1)
        if (this.imagesList.length >= 9) {
          this.$message.error('图片上传数量上限为九张')
          return false
        } else {
          return true
        }
      } else {
        this.$message.error('文件类似不是图片，上传失败')
        return false
      }
    },
    async handleHttpRequestImg(object) {
      this.loading = true
      await oss.upload(object.file).then((fileUrl) => {
        this.loading = false
        // 文件类型（1图片、2视频）
        if (this.uploadObj.type === 1) {
          this.imagesList.push(fileUrl)
        } else {
          this.videoList.push(fileUrl)
        }
      })
    },
    contentFn(row, name, content) {
      const replace = /[`@#$%^&<>?:"{}|,./;'\\[\]·~@#￥%……&+={}|《》]/g
      if (row[name] && row[name].length && row[name] !== null) {
        row[name] += ''
        row[name] = row[name].replace(replace, '')
        row[name] = row[name].replace(/\s/g, '')
      }
    }
  }
}
