// // 引入
// import jsrsasign from 'jsrsasign'
// const RSA_PUBLIC_KEY = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCJbM+f1/Wjti4Z7QP29hhw9cqNsiAerQl7D+vSccyZUBYQFNXQwLXnov9/aFWYHCkQglCgfXWVq7Ka2DMOlgYDw5cQwXYiOm1+3QFQw0woDsYUlg4wr/nWHCkhWudUtbxfEL3w0GTTl0+VpiSfEkXkfBO53fBHhtI/RT/kb59/8wIDAQAB'
// function install (Vue) {
//   // 公共方法挂载
//   Vue.prototype.$rsa = (text) => {
//     let pk = '-----BEGIN PUBLIC KEY-----' + RSA_PUBLIC_KEY + '-----END PUBLIC KEY-----'
//     let pub = jsrsasign.KEYUTIL.getKey(pk)
//     let enc = jsrsasign.KJUR.crypto.Cipher.encrypt(text, pub)
//     enc = jsrsasign.hextob64(enc)
//     return enc
//   }
// }
//
// export default install
import JSEncrypt from './jsencrypt.min'
const {
  RSA_PUBLIC_KEY
} = require(`@/version/${process.env.VUE_APP_BUILD_VERION}.js`)
const RSA = new JSEncrypt()
RSA.setPublicKey(RSA_PUBLIC_KEY)
function install(Vue) {
  Vue.prototype.$rsa = (text) => {
    return RSA.encrypt(text)
  }
}
export default install
