<!-- 违反查询 -->
<template>
  <div class="w_100vh h_100vh">
    <AppTable
      :searchArr="searchArr"
      ref="AppTable"
      :tableColumns="tableColumns"
      controller="report/ruleViolate"
      :requestParams="requestParams"
    >
      <template #operator-left-box>
        <div class="">
          <slot name="unreadType"></slot>
        </div>
      </template>
      <template #unread="{ row }">
        <div v-if="row.hadRead === 0" class="unread"></div>
      </template>
      <!-- 所属部门 -->
      <template #orgHeader="{ column }">
        <orgCascader
          @change="orgCascaderOnChange"
          ref="orgCascader"
          :contentType="true"
        >
          {{ column.label }}
        </orgCascader>
      </template>
      <!-- 设备类型 -->
      <template #deviceTypeHeader="{ column }">
        <div class="pos_r">
          <div
            :style="{
              opacity: column.focusType ? 1 : 0,
              position: column.focusType ? 'relative' : 'absolute'
            }"
            class="top-0 left-0"
          >
            <el-select
              v-model="requestParams.deviceType"
              class="w-full"
              clearable
              size="small"
              @change="screenRowFn('deviceType')"
              @visible-change="visibleChange($event, column, 'focusType')"
              :placeholder="column.label"
            >
              <el-option
                v-for="item in deviceTypeList"
                :key="item.type"
                :label="item.typeName"
                :value="item.type"
              ></el-option>
            </el-select>
          </div>
          <span v-if="!column.focusType" style="cursor: pointer">
            <i class="el-icon-caret-bottom"></i>
            {{ column.label }}
            <span class="fs_12 c_9DA7B2">
              {{
                screenRow.deviceType ? `(${screenRow.deviceType.typeName})` : ''
              }}
            </span>
          </span>
        </div>
      </template>
      <!-- 违反类型 -->
      <template #warningTypeHeader="{ column }">
        <div class="pos_r">
          <div
            :style="{
              opacity: column.focusType ? 1 : 0,
              position: column.focusType ? 'relative' : 'absolute'
            }"
            class="top-0 left-0"
          >
            <el-select
              v-model="requestParams.warningTypeName"
              class="w-full"
              clearable
              size="small"
              @change="screenRowFn('warningTypeName')"
              @visible-change="visibleChange($event, column, 'focusType')"
              :placeholder="column.label"
            >
              <el-option
                v-for="item in commonWarningTypeList"
                :key="item.warningType"
                :label="item.warningTypeName"
                :value="item.warningTypeName"
              ></el-option>
            </el-select>
          </div>
          <span v-if="!column.focusType" style="cursor: pointer">
            <i class="el-icon-caret-bottom"></i>
            {{ column.label }}
            <span class="fs_12 c_9DA7B2">
              {{
                screenRow.warningType
                  ? `(${screenRow.warningType.warningTypeName})`
                  : ''
              }}
            </span>
          </span>
        </div>
      </template>
      <!-- 处理情况 -->
      <template #dealStateHeader="{ column }">
        <div class="pos_r">
          <div
            :style="{
              opacity: column.focusType ? 1 : 0,
              position: column.focusType ? 'relative' : 'absolute'
            }"
            class="top-0 left-0"
          >
            <el-select
              v-model="requestParams.dealState"
              class="w-full"
              clearable
              size="small"
              @change="screenRowFn('dealState')"
              @visible-change="visibleChange($event, column, 'focusType')"
              :placeholder="column.label"
            >
              <el-option
                v-for="item in dealStateList"
                :key="item.type"
                :label="item.name"
                :value="item.type"
              ></el-option>
            </el-select>
          </div>
          <span v-if="!column.focusType" style="cursor: pointer">
            <i class="el-icon-caret-bottom"></i>
            {{ column.label }}
            <span class="fs_12 c_9DA7B2">
              {{ screenRow.dealState ? `(${screenRow.dealState.name})` : '' }}
            </span>
          </span>
        </div>
      </template>
      <template #userInformation="{ row }">
        <el-row class="fs_12 lh_20">
          <el-col :span="24" v-if="row.name"
            ><div class="flex_vertically_center">
              <span class="c_8E9396 flex-shrink">使用者：</span>{{ row.name }}
            </div></el-col
          >
          <el-col :span="24"
            ><div class="flex_vertically_center">
              <span class="c_8E9396 flex-shrink">设备编号：</span
              >{{ row.deviceId }}
            </div></el-col
          >
          <el-col :span="24"
            ><div class="flex_vertically_center">
              <span class="c_8E9396 flex-shrink">设备名称：</span
              >{{ row.devName }}
            </div></el-col
          >
        </el-row>
      </template>
      <template #dealState="{ row }">
        <span v-if="row.dealState === 1" class="c_9DA7B2"> 已处理 </span>
        <span v-else class="c_fff"> 未处理 </span>
      </template>
      <template #operator="{ row }">
        <div>
          <iconBox
            @click="violationHandleShow(row)"
            v-if="row.dealState === 1"
            imgName="see"
            altText="查看"
          ></iconBox>
          <iconBox
            @click="violationHandleShow(row)"
            v-else
            imgName="edit"
            altText="处理"
          ></iconBox>
          <slot name="operator" v-bind:row="row"></slot>
        </div>
      </template>
    </AppTable>
    <violationHandle ref="violationHandle"></violationHandle>
  </div>
</template>

<script>
import exportFile from '@mixins/exportFile'
import violationHandle from '@components/breakQuery/violationHandle.vue'
export default {
  mixins: [exportFile],
  props: {
    unreadType: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  data() {
    return {
      queryDtm: null,
      searchArr: [
        {
          label: '设备名称',
          prop: 'devName'
        },
        {
          label: '设备编号',
          prop: 'deviceId'
        },
        {
          label: '使用者',
          prop: 'name'
        }
      ],
      tableColumns: [
        {
          label: '设备信息',
          width: '300px',
          customSlot: 'userInformation',
          type: 'customSlot'
        },
        // {
        //   label: '设备类型',
        //   prop: 'deviceTypeName',
        //   headerSlot: 'deviceTypeHeader'
        // },
        // {
        //   label: '所属部门',
        //   prop: 'orgName',
        //   headerSlot: 'orgHeader'
        // },
        {
          label: '违反类型',
          prop: 'warningTypeName',
          headerSlot: 'warningTypeHeader'
        },
        {
          label: '违反时间',
          prop: 'warningDtm'
        },
        {
          label: '违反地址',
          prop: 'address'
        },
        // {
        //   label: '处理情况',
        //   customSlot: 'dealState',
        //   type: 'customSlot',
        //   headerSlot: 'dealStateHeader'
        // },
        {
          label: '操作',
          customSlot: 'operator',
          type: 'customSlot',
          width: '150px'
        }
      ],
      requestParams: {
        queryStartDtm: null,
        queryEndDtm: null,
        orgId: null,
        dealState: null,
        warningTypeName: null,
        deviceType: null
      },
      selectList: [],
      screenRow: {}, // 筛选需要展示内容.
      deviceTypeList: [],
      props: {},
      cascaderModel: [],
      commonWarningTypeList: [],
      dealStateList: [
        { name: '未处理', type: 0 },
        { name: '已处理', type: 1 }
      ]
    }
  },
  components: {
    violationHandle
  },
  mounted() {
    if (this.unreadType) {
      this.tableColumns.push({
        label: null,
        width: '30px',
        customSlot: 'unread',
        type: 'customSlot'
      })
    }
    this.commonWarningType()
    this.deviceTypeFindAll()
  },
  methods: {
    /* 处理 */
    violationHandleShow(row) {
      this.$refs.violationHandle.show(row.id, (item) => {
        if (item.id === row.id) {
          this.$set(row, 'dealState', 1)
        }
      })
    },
    commonWarningType() {
      this.$api.common
        .commonWarningType({
          sourceType: 1
        })
        .then((res) => {
          if (res.code === 200) {
            this.commonWarningTypeList = res.data
          }
        })
    },
    /* 获取设备类型 */
    deviceTypeFindAll() {
      this.$api.findAll('device/deviceType').then((res) => {
        if (res.code === 200) {
          this.deviceTypeList = res.data
        }
      })
    },
    screenRowFn(typeName) {
      let item = null
      if (typeName === 'deviceType') {
        if (
          this.requestParams.deviceType ||
          this.requestParams.deviceType === 0
        ) {
          item = this.deviceTypeList.find(
            (v) => v.type === this.requestParams.deviceType
          )
          if (item) {
            this.$set(this.screenRow, 'deviceType', { ...item })
          }
        } else {
          this.screenRow.deviceType = null
        }
      } else if (typeName === 'warningTypeName') {
        if (
          this.requestParams.warningTypeName ||
          this.requestParams.warningTypeName === 0
        ) {
          item = this.commonWarningTypeList.find(
            (v) => v.warningTypeName === this.requestParams.warningTypeName
          )
          if (item) {
            this.$set(this.screenRow, 'warningType', { ...item })
          }
        } else {
          this.screenRow.warningType = null
        }
      } else if (typeName === 'dealState') {
        if (
          this.requestParams.dealState ||
          this.requestParams.dealState === 0
        ) {
          item = this.dealStateList.find(
            (v) => v.type === this.requestParams.dealState
          )
          if (item) {
            this.$set(this.screenRow, 'dealState', { ...item })
          }
        } else {
          this.screenRow.dealState = null
        }
      }

      this.$nextTick(() => {
        this.appTableGetData()
      })
    },
    visibleChange(e, row, name) {
      this.$set(row, name, e)
    },
    queryDtmFn() {
      if (this.queryDtm) {
        this.$set(this.requestParams, 'queryStartDtm', this.queryDtm[0])
        this.$set(this.requestParams, 'queryEndDtm', this.queryDtm[1])
      } else {
        this.$set(this.requestParams, 'queryStartDtm', null)
        this.$set(this.requestParams, 'queryEndDtm', null)
      }
      this.$nextTick(() => {
        this.appTableGetData()
      })
    },
    appTableGetData() {
      this.$refs.AppTable.clearSelectedList([])
      this.selectList = []
      this.$nextTick(() => {
        this.$refs.AppTable.getData()
      })
    },
    orgCascaderOnChange(value) {
      this.requestParams.orgId = value[value.length - 1]
      this.$nextTick(() => {
        this.appTableGetData()
      })
    }
  }
}
</script>
<style scoped lang="less" rel="stylesheet/less">
.el-row::after,
.el-row::before {
  display: none;
}
.unread {
  content: '';
  width: 10px;
  height: 10px;
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 50%;
  background: var(--dangerv2);
  box-shadow: 0px 0px 6px 1px var(--dangerv2);
}
</style>
