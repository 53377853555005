<!-- 抽查设备 -->
<template>
  <el-row :gutter="8" class="h-full">
    <el-col
      v-for="taskDeviceItem in taskDeviceList"
      :key="taskDeviceItem.id"
      :span="paramsSpan"
      class="mb-2"
    >
      <div
        class="h-[58px] bg-backgroundv2 border border-border rounded px-2 box-border flex items-center"
      >
        <div class="relative">
          <userHeadPortrait
            width="36px"
            height="36px"
            :audioContextType="false"
            :iconType="false"
            class="point point_dot"
            :device="{
              ...taskDeviceItem,
              onlineState: 1
            }"
          ></userHeadPortrait>
          <el-tooltip
            class="item"
            effect="dark"
            content="该设备在发起抽查时处于临时群组"
            placement="top"
            v-if="taskDeviceItem.intercomGroupTem === 1"
          >
            <div
              class="w-[16px] text-main h-[16px] bg-dangerv2 flex items-center justify-center absolute -top-[2px] -right-[4px] rounded-full text-xs font-bold"
            >
              <span class="scale-75">临</span>
            </div>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="该设备在发起抽查时处于离线状态"
            placement="top"
            v-else-if="taskDeviceItem.onlineState === 2"
          >
            <div
              class="w-[16px] text-main h-[16px] bg-dangerv2 flex items-center justify-center absolute -top-[2px] -right-[4px] rounded-full text-xs font-bold"
            >
              <svg-icon
                svg-name="icon-off-line"
                class-name="!w-[10px] !h-[14px]"
              />
            </div>
          </el-tooltip>
        </div>
        <div class="flex-grow w-0 flex">
          <div class="mx-2 flex-grow w-0">
            <p class="over_one_line">
              {{ taskDeviceItem.devName }}
            </p>
            <p class="text-auxiliary over_one_line">
              {{ taskDeviceItem.deviceId }}
            </p>
          </div>
          <div>
            <!--checkStatus 抽查状态（1待接收、2接收中、3完成、4未完成）-->
            <div
              v-show="taskDeviceItem.checkStatus === 1"
              class="border-info bg-info05 border rounded px-2 flex items-center justify-center h-[24px] box-border"
            >
              待接收
            </div>
            <div
              v-show="taskDeviceItem.checkStatus === 2"
              class="border-warning bg-warning05 border rounded px-2 flex items-center justify-center h-[24px] box-border"
            >
              接收中
            </div>
            <div
              v-show="taskDeviceItem.checkStatus === 3"
              class="w-[54px] border-success bg-success05 border rounded px-2 flex items-center justify-center h-[24px] box-border"
            >
              完成
            </div>
            <div
              v-show="taskDeviceItem.checkStatus === 4"
              class="border-dangerv2 bg-dangerv205 border rounded px-2 flex items-center justify-center h-[24px] box-border"
            >
              未完成
            </div>
          </div>
        </div>
      </div>
    </el-col>
    <el-col v-if="taskDeviceList.length === 0" :span="24" class="h-full">
      <div
        class="h-full bg-backgroundv2 border border-border rounded px-2 box-border flex justify-center text-auxiliary items-center"
      >
        <div class="r-f tac block">
          <svg-icon svg-name="noData" className="svgBox !w-[50px] !h-[50px]" />
          <div class="mt-3">暂无数据</div>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
export default {
  props: {
    subscribeStatus: {
      // 是否需要订阅
      type: Boolean,
      default: () => {
        return true
      }
    },
    form: {
      type: Object,
      default: () => {
        return {}
      }
    },
    paramsSpan: {
      type: Number,
      default: () => {
        return 24
      }
    }
  },
  data() {
    return {
      uniqueId: null,
      beReceiveNum: 0, // 待接收
      receivingNum: 0, // 接收中
      finishNum: 0, // 已完成
      notFinishNum: 0, // 未完成
      offLineNum: 0, // 离线
      intercomGroupTemNum: 0, // 对讲中
      taskDeviceList: []
    }
  },
  destroyed() {
    this.spotCheckUnsubscribe()
  },
  mounted() {
    this.uniqueId = this.form.uniqueId
    if (this.subscribeStatus) {
      this.spotCheckSubscribe()
    }
    this.getData()
  },
  methods: {
    // 设置设备状态
    setDeviceStatus(params) {
      // deviceId（设备ID）、checkStatus（2接收中、3完成、4未完成）
      const { deviceId, checkStatus } = params || {}
      if (!deviceId) return
      const index = this.taskDeviceList.findIndex(
        (taskDeviceItem) => taskDeviceItem.deviceId === deviceId
      )

      if (index !== -1) {
        const item = this.taskDeviceList[index]
        switch (item.checkStatus) {
          case 1:
            this.beReceiveNum--
            break
          case 2:
            this.receivingNum--
            break
        }

        switch (checkStatus) {
          case 2:
            this.receivingNum++
            break
          case 3:
            this.finishNum++
            break
          case 4:
            this.notFinishNum++
            break
        }
        this.$nextTick(() => {
          this.onCountStatus()
          this.$set(this.taskDeviceList[index], 'checkStatus', checkStatus)
        })
      }
    },
    // 初始化设备状态列表
    initDeviceStatusList() {
      let beReceiveNum = 0
      let receivingNum = 0
      let finishNum = 0
      let notFinishNum = 0
      let offLineNum = 0
      let intercomGroupTemNum = 0
      this.taskDeviceList.forEach((item) => {
        const checkStatus = item.checkStatus
        const intercomGroupTem = item.intercomGroupTem
        const onlineState = item.onlineState
        if (intercomGroupTem === 1) {
          intercomGroupTemNum++
        }
        if (onlineState === 2) {
          offLineNum++
        }
        // checkStatus 抽查状态（1待接收、2接收中、3完成、4未完成）
        switch (checkStatus) {
          case 1:
            beReceiveNum++
            break
          case 2:
            receivingNum++
            break
          case 3:
            finishNum++
            break
          case 4:
            notFinishNum++
            break
        }
      })
      this.$nextTick(() => {
        this.beReceiveNum = beReceiveNum
        this.receivingNum = receivingNum
        this.finishNum = finishNum
        this.notFinishNum = notFinishNum
        this.offLineNum = offLineNum
        this.intercomGroupTemNum = intercomGroupTemNum
        this.onCountStatus()
      })
    },
    // 返回状态数量统计
    onCountStatus() {
      this.$emit('on-countStatus', {
        beReceiveNum: this.beReceiveNum,
        receivingNum: this.receivingNum,
        finishNum: this.finishNum,
        notFinishNum: this.notFinishNum,
        offLineNum: this.offLineNum,
        intercomGroupTemNum: this.intercomGroupTemNum
      })
    },
    // 设备抽查状态订阅
    spotCheckSubscribe() {
      if (!this.uniqueId) return
      const callback = (res) => {
        const { method, data = {} } = res || {}
        const { uniqueId } = data
        if (uniqueId !== this.uniqueId) return
        if (method === 'deviceCheckStatus') {
          this.setDeviceStatus(data)
          // data：deviceId（设备ID）、checkStatus（2接收中、3完成、4未完成）
        }
      }
      this.$business.spotCheckSubscribe(this.uniqueId, callback)
    },
    // 设备抽查状态取消订阅
    spotCheckUnsubscribe() {
      if (!this.uniqueId) return
      this.$business.spotCheckUnsubscribe(this.uniqueId)
    },
    // 获取数据
    async getData(checkStatus) {
      const { code, data = [] } = await this.$api.findAll(
        'spotCheck/task/taskDevice',
        {
          taskId: this.form.id,
          checkStatus: checkStatus || null
        }
      )
      if (code === 200) {
        this.taskDeviceList = data
        if (this.subscribeStatus) {
          this.initDeviceStatusList()
        }
        this.$emit('change', this.taskDeviceList)
      }
    }
  }
}
</script>
<style scoped lang="less" rel="stylesheet/less"></style>
