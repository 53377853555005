<!-- 存储预警查询 -->
<template>
  <AppTable
    :searchArr="searchArr"
    ref="AppTable"
    :tableColumns="tableColumns"
    controller="report/storageWarning"
    :requestParams="requestParams"
  >
    <template #operator-left-box>
      <div class="">
        <slot name="unreadType"></slot>
      </div>
    </template>
    <template #unread="{ row }">
      <div v-if="row.hadRead === 0" class="unread"></div>
    </template>
    <template #operator="{ row }">
      <div>
        <slot name="operator" v-bind:row="row"></slot>
      </div>
    </template>
    <!-- 所属部门 -->
    <template #orgHeader="{ column }">
      <orgCascader
        @change="orgCascaderOnChange"
        ref="orgCascader"
        :contentType="true"
      >
        {{ column.label }}
      </orgCascader>
    </template>
    <!-- 设备类型 -->
    <template #deviceTypeHeader="{ column }">
      <div class="pos_r">
        <div
          :style="{
            opacity: column.focusType ? 1 : 0,
            position: column.focusType ? 'relative' : 'absolute'
          }"
          class="top-0 left-0"
        >
          <el-select
            v-model="requestParams.deviceType"
            class="!w-full"
            clearable
            size="small"
            @change="screenRowFn('deviceType')"
            @visible-change="visibleChange($event, column, 'focusType')"
            :placeholder="column.label"
          >
            <el-option
              v-for="item in deviceTypeList"
              :key="item.type"
              :label="item.typeName"
              :value="item.type"
            ></el-option>
          </el-select>
        </div>
        <span v-if="!column.focusType" style="cursor: pointer">
          <i class="el-icon-caret-bottom"></i>
          {{ column.label }}
          <span class="fs_12 c_9DA7B2">
            {{
              screenRow.deviceType ? `(${screenRow.deviceType.typeName})` : ''
            }}
          </span>
        </span>
      </div>
    </template>
    <template #userInformation="{ row }">
      <el-row class="fs_12 lh_20">
        <el-col :span="24" v-if="row.name"
          ><div class="flex_vertically_center">
            <span class="c_8E9396 flex-shrink">使用者：</span>{{ row.name }}
          </div></el-col
        >
        <el-col :span="24"
          ><div class="flex_vertically_center">
            <span class="c_8E9396 flex-shrink">设备编号：</span
            >{{ row.deviceId }}
          </div></el-col
        >
        <el-col :span="24"
          ><div class="flex_vertically_center">
            <span class="c_8E9396 flex-shrink">设备名称：</span
            >{{ row.devName }}
          </div></el-col
        >
      </el-row>
    </template>
    <template #storage="{ row, tdItem }">
      <span>{{ publicFileSizeFn(row[`${tdItem.prop}`]) }}</span>
    </template>
  </AppTable>
</template>

<script>
import exportFile from '@mixins/exportFile'
export default {
  mixins: [exportFile],
  props: {
    unreadType: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  data() {
    return {
      queryDtm: null,
      searchArr: [
        {
          label: '设备名称',
          prop: 'devName'
        },
        {
          label: '设备编号',
          prop: 'deviceId'
        }
      ],
      tableColumns: [
        {
          label: '设备信息',
          width: '300px',
          customSlot: 'userInformation',
          type: 'customSlot'
        },
        // {
        //   label: '设备类型',
        //   prop: 'deviceTypeName',
        //   headerSlot: 'deviceTypeHeader'
        // },
        // {
        //   label: '所属部门',
        //   prop: 'orgName',
        //   headerSlot: 'orgHeader'
        // },
        {
          label: '总存储空间',
          align: 'center',
          customSlot: 'storage',
          type: 'customSlot',
          prop: 'storageTol'
        },
        {
          label: '已用空间',
          align: 'center',
          customSlot: 'storage',
          type: 'customSlot',
          prop: 'storageUsed'
        },
        {
          label: '剩余空间',
          align: 'center',
          customSlot: 'storage',
          type: 'customSlot',
          prop: 'storageSurplus'
        }
      ],
      requestParams: {
        queryStartDtm: null,
        queryEndDtm: null,
        orgId: null,
        deviceType: null
      },
      selectList: [],
      screenRow: {}, // 筛选需要展示内容.
      deviceTypeList: [],
      props: {},
      cascaderModel: []
    }
  },
  components: {},
  mounted() {
    if (this.unreadType) {
      this.tableColumns.push({
        label: '操作',
        customSlot: 'operator',
        type: 'customSlot',
        width: '150px'
      })
      this.tableColumns.push({
        label: null,
        width: '30px',
        customSlot: 'unread',
        type: 'customSlot'
      })
    }
    this.deviceTypeFindAll()
  },
  methods: {
    /* 获取设备类型 */
    deviceTypeFindAll() {
      this.$api.findAll('device/deviceType').then((res) => {
        if (res.code === 200) {
          this.deviceTypeList = res.data
        }
      })
    },
    screenRowFn(typeName) {
      let item = null
      if (typeName === 'deviceType') {
        if (
          this.requestParams.deviceType ||
          this.requestParams.deviceType === 0
        ) {
          item = this.deviceTypeList.find(
            (v) => v.type === this.requestParams.deviceType
          )
          if (item) {
            this.$set(this.screenRow, 'deviceType', { ...item })
          }
        } else {
          this.screenRow.deviceType = null
        }
      }
      this.$nextTick(() => {
        this.appTableGetData()
      })
    },
    visibleChange(e, row, name) {
      this.$set(row, name, e)
    },
    queryDtmFn() {
      if (this.queryDtm) {
        this.$set(this.requestParams, 'queryStartDtm', this.queryDtm[0])
        this.$set(this.requestParams, 'queryEndDtm', this.queryDtm[1])
      } else {
        this.$set(this.requestParams, 'queryStartDtm', null)
        this.$set(this.requestParams, 'queryEndDtm', null)
      }
      this.$nextTick(() => {
        this.appTableGetData()
      })
    },
    appTableGetData() {
      this.$refs.AppTable.clearSelectedList([])
      this.selectList = []
      this.$nextTick(() => {
        this.$refs.AppTable.getData()
      })
    },
    orgCascaderOnChange(value) {
      this.requestParams.orgId = value[value.length - 1]
      this.$nextTick(() => {
        this.appTableGetData()
      })
    }
  }
}
</script>
<style scoped lang="less" rel="stylesheet/less">
.el-row::after,
.el-row::before {
  display: none;
}
.unread {
  content: '';
  width: 10px;
  height: 10px;
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 50%;
  background: var(--dangerv2);
  box-shadow: 0px 0px 6px 1px var(--dangerv2);
}
</style>
