import { render, staticRenderFns } from "./headerBox_quanSheng.vue?vue&type=template&id=0ac7bb55&scoped=true"
import script from "./headerBox_quanSheng.vue?vue&type=script&lang=js"
export * from "./headerBox_quanSheng.vue?vue&type=script&lang=js"
import style0 from "./css/index.less?vue&type=style&index=0&id=0ac7bb55&prod&scoped=true&lang=less&rel=stylesheet%2Fless&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ac7bb55",
  null
  
)

export default component.exports