<!-- 警单的报警监控 -->
<template>
  <div>
    <div
      @click="setDragLevel"
      :style="{ 'z-index': dragLevel }"
      v-el-drag-dialog
      v-if="dialogTableVisible"
      class="el-dialog w-[900px] videoSurveillanceBox"
    >
      <div class="h-[60vh] el-dialog__header w-full !p-0 absolute z-[1]"></div>
      <!--<div-->
      <!--  @click="-->
      <!--    show({-->
      <!--      id: 223-->
      <!--    })-->
      <!--  "-->
      <!--&gt;-->
      <!--  show-->
      <!--</div>-->
      <div
        class="h-[60vh] !bg-backgroundv2 w-full flex flex-col overflow-hidden !rounded-[8px]"
      >
        <div
          class="h-[50px] bg-backgroundv4 flex items-center px-[10px] relative"
        >
          <div class="flex">
            <div
              v-for="item in numberOfrowsList"
              :key="item.label + 'numberOfrowsList'"
              :class="{
                '!text-main !border-theme !shadow-theme':
                  item.number === numberOfrows.number &&
                  item.type === numberOfrows.type
              }"
              @click="setNumberOfrows(item)"
              class="clickBox w-[26px] h-[26px] shadow-[0_0_4px_0] hover:text-main hover:border-theme hover:shadow-theme shadow-background bg-background rounded border border-border text-xs font-bold text-auxiliary flex justify-center items-center ml-[8px]"
            >
              {{ item.label }}
            </div>
          </div>
          <div class="absolute z-[2] left-1/2 -translate-x-1/2">
            <el-button size="small" class="button_delete" @click="dealEnd"
              >结束警情</el-button
            >
          </div>
        </div>
        <div class="flex-grow h-0 p-[8px_10px]">
          <el-row :gutter="6" class="h-full">
            <draggable
              handle=".mover"
              v-model="monitorDeviceListV2"
              :disabled="false"
              :move="onMove"
              @end="onEnd"
              class="h-full"
            >
              <transition-group class="h-full block">
                <el-col
                  v-for="(item, index) in monitorDeviceList"
                  :key="index"
                  :style="{ height: getHeight(1, index) }"
                  :span="
                    numberOfrows.type === 1
                      ? 24 / numberOfrows.number
                      : index === 0
                      ? 24 - 24 / numberOfrows.number
                      : 24 / numberOfrows.number
                  "
                  :class="{
                    'relative z-[2]': item.deviceId
                  }"
                  class="h-full py-[2px] group"
                >
                  <div
                    @click="setCurrentDevice(item)"
                    v-if="item.deviceId"
                    :ref="`${item.deviceId}`"
                    :id="`videoBox_${item.deviceId}`"
                    :class="{
                      '!border-theme':
                        item.methodType === 'successfulCall' &&
                        item.deviceId === currentDevice.deviceId
                    }"
                    class="w-full h-full box_border border border-border rounded overflow-hidden text-xs text-auxiliary relative"
                  >
                    <!-- 头部 -->
                    <div
                      class="mover h-[28px] flex justify-between items-center px-2 mb-1 relative z-10 bg-backgroundv2"
                    >
                      <div
                        class="w-[18px] h-[18px] bg-background rounded-full flex items-center justify-center text-main"
                      >
                        {{ index + 1 }}
                      </div>
                      <div
                        class="flex-grow w-0 text-center text-sm mx-2 flex justify-center"
                      >
                        <div class="over_one_line text-center">
                          <span v-if="item.parentOrgName"
                            >{{ item.parentOrgName }}-</span
                          >
                          <span v-if="item.orgName">{{ item.orgName }}-</span>
                          <span>{{ item.devName }}</span>
                        </div>
                        <div class="flex-shrink-0 ml-1">
                          <span v-if="item.sos === 1 || item.channelName">
                            (<span v-show="item.channelName">{{
                              item.channelName
                            }}</span>
                            <span v-show="item.sos === 1" class="fw_600"
                              >sos</span
                            >)
                          </span>
                        </div>
                      </div>
                      <div class="clickBox hover:text-dangerv2">
                        <div @click.stop="closeTheRoom([item])">
                          <svg-icon
                            svg-name="icon_close"
                            className="svgBox !w-[18px] !h-[18px]"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- 录像 -->
                    <div
                      v-if="item.recordingTime"
                      class="min-w-[64px] h-[24px] font-bold rounded-[10px] bg-backgroundv305 flex items-center justify-center text-xs box-border absolute right-[10px] z-10"
                    >
                      <span
                        class="w-2 h-2 rounded-full bg-success inline-block mr-[4px]"
                      ></span>
                      <span>{{ getTime(item.recordingTime) }}</span>
                    </div>
                    <!-- 未接通提示 -->
                    <div
                      v-if="
                        item.errorCode && item.methodType !== 'successfulCall'
                      "
                      class="juzhong text-sm text-main text-center"
                    >
                      <span v-if="item.errorCode === 1001">
                        {{ item.errorReason }}
                      </span>
                      <span v-else>
                        <span
                          v-if="item.errorCode === 500 || item.errorCode === -1"
                        >
                          {{
                            item.onlineState === 1
                              ? '对方网络不佳'
                              : '对方不在线'
                          }}
                        </span>
                        <span v-else>
                          对方网络信号不佳
                          <span class="c_9DA7B2 fs_12"
                            >({{ item.errorCode }})</span
                          >
                        </span>
                      </span>
                      <div class="mt-3">
                        <el-button
                          size="mini"
                          :loading="item.intercomInviteState"
                          class="button_function"
                          @click="reconnectFn(item)"
                        >
                          {{
                            item.intercomInviteState ? '努力加载中' : '重新连接'
                          }}
                        </el-button>
                      </div>
                    </div>
                    <div
                      v-else-if="item.methodType === 'clientClosed'"
                      class="juzhong text-xs text-main text-center"
                    >
                      <span> 设备退出房间，检查设备网路或是否在线 </span>
                      <div class="mt-3">
                        <el-button
                          size="mini"
                          :loading="item.intercomInviteState"
                          class="button_function"
                          @click="reconnectFn(item)"
                        >
                          {{
                            item.intercomInviteState ? '努力加载中' : '重新连接'
                          }}
                        </el-button>
                      </div>
                    </div>
                    <div
                      v-else-if="item.methodType !== 'successfulCall'"
                      class="juzhong text-xs text-main text-center"
                    >
                      <el-button
                        v-if="item.intercomInviteState"
                        size="mini"
                        :loading="true"
                        class="button_function"
                      >
                        加载画面中, 请耐心等待
                      </el-button>
                      <span v-else> 正在连接摄像头，请耐心等待… </span>
                    </div>
                    <!-- 已经接通操作区 -->
                    <div
                      v-if="
                        item.methodType === 'successfulCall' &&
                        operationList.length
                      "
                    >
                      <div
                        class="absolute bottom-0 left-1/2 -translate-x-1/2 w-[96%] z-10"
                      >
                        <div
                          v-if="!item.operationAreaHide"
                          @click.stop="setOperationAreaHide(item, true)"
                          class="w-full bg-backgroundv3 rounded-[12px_12px_0_0] h-[20px] flex items-center justify-center clickBox opacity-0 group-hover:opacity-100"
                        >
                          <svg-icon
                            svg-name="operationAreaShow"
                            class-name="svgBox !w-[12px] !h-[12px] clickBox"
                          />
                        </div>
                        <!--展开-->
                        <div
                          v-else
                          class="w-full bg-backgroundv3 rounded-[12px_12px_0_0] h-[40px] z-10"
                        >
                          <div
                            class="absolute -top-[26px] left-0 bg-background border border-border p-1 rounded flex items-center justify-center"
                          >
                            <svg-icon
                              @click.native="setOperationAreaHide(item, false)"
                              svg-name="operationAreaHide"
                              class-name="svgBox !w-[12px] !h-[12px] clickBox"
                            />
                          </div>
                          <!--操作区-->
                          <div class="h-full flex justify-center items-center">
                            <el-tooltip
                              v-delTabIndex
                              v-for="operationItem in operationList"
                              :key="operationItem.type"
                              class="mx-1"
                              effect="dark"
                              :open-delay="500"
                              :content="operationItem.name"
                              placement="top"
                            >
                              <!-- 视频通道-->
                              <el-popover
                                placement="top"
                                trigger="click"
                                @show="
                                  setChannelPpover(
                                    `channelSelect_${item.deviceId}`,
                                    true
                                  )
                                "
                                @hide="
                                  setChannelPpover(
                                    `channelSelect_${item.deviceId}`,
                                    false
                                  )
                                "
                                v-if="
                                  operationItem.type === 16 &&
                                  item.channelList &&
                                  item.channelList.length !== 0
                                "
                                popper-class="popover_darkColor liveBroadcast"
                              >
                                <div class="w-[180px]">
                                  <channelSelect
                                    v-if="
                                      channelPpover ===
                                      `channelSelect_${item.deviceId}`
                                    "
                                    :channelId="item.channelId"
                                    :channelList="item.channelList"
                                    :deviceId="item.deviceId"
                                    @on-filterByDevice="setItemChanne"
                                    @on-select="setSelectChanne"
                                    :key="`channelSelect_${item.deviceId}`"
                                  ></channelSelect>
                                </div>
                                <div class="clickBox" slot="reference">
                                  <svg-icon
                                    svg-name="liveBroadcast_channel"
                                    className="svgBox !w-[26px] !h-[26px]"
                                  />
                                </div>
                              </el-popover>
                              <el-popover
                                placement="top"
                                trigger="click"
                                v-else-if="operationItem.type === 4"
                                class="leading-[0]"
                                popper-class="popover_darkColor liveBroadcast"
                              >
                                <el-tooltip
                                  class="item"
                                  effect="dark"
                                  :content="'音量:' + item.soundControl * 100"
                                  placement="top"
                                  :open-delay="500"
                                >
                                  <div
                                    class="soundControl centerBox border border-backgroundv2 bg-backgroundv2 flex"
                                  >
                                    <div
                                      @click="forbiddenSound(item.deviceId)"
                                      class="pos_r top-[1px] mr-2 flex-shrink-0"
                                    >
                                      <svg-icon
                                        v-if="!item.forbiddenSound"
                                        svg-name="sound-select"
                                      />
                                      <svg-icon v-else svg-name="sound" />
                                    </div>
                                    <input
                                      class="flex-grow w-0"
                                      v-model="item.soundControl"
                                      max="1"
                                      min="0"
                                      step="0.1"
                                      @input="
                                        setSoundControl(
                                          item.deviceId,
                                          item.soundControl
                                        )
                                      "
                                      :style="{
                                        background: `linear-gradient(to right, ${
                                          !item.forbiddenSound
                                            ? 'var(--theme)'
                                            : 'var(--dangerv2)'
                                        } ${getPercent(
                                          item.soundControl,
                                          1
                                        )}%, var(--background) ${getPercent(
                                          item.soundControl,
                                          1
                                        )}% `
                                      }"
                                      type="range"
                                    />
                                  </div>
                                </el-tooltip>
                                <div slot="reference" class="clickBox">
                                  <svg-icon
                                    v-if="item.forbiddenSound"
                                    svg-name="liveBroadcast-forbiddenSound-select"
                                    className="svgBox !w-[26px] !h-[26px]"
                                  />
                                  <svg-icon
                                    v-else
                                    svg-name="liveBroadcast-forbiddenSound"
                                    className="svgBox !w-[26px] !h-[26px]"
                                  />
                                </div>
                              </el-popover>
                              <!-- 消息 -->
                              <div
                                v-else-if="
                                  operationItem.type === 12 &&
                                  item.sendMsgSupport === 1
                                "
                                class="clickBox flex flex-col items-center"
                                @click="issueMessage(item)"
                              >
                                <svg-icon
                                  svg-name="liveBroadcast_messagev2"
                                  className="svgBox !w-[26px] !h-[26px]"
                                />
                              </div>
                              <!-- 广播 -->
                              <div
                                v-else-if="
                                  operationItem.type === 10 &&
                                  item.broadcastSupport === 1
                                "
                                class="flex flex-col items-center"
                              >
                                <div
                                  v-if="!item.broadcastType"
                                  class="clickBox flex flex-col items-center"
                                  slot="reference"
                                >
                                  <!--加载动画-->
                                  <div
                                    v-if="item.broadcastLoading"
                                    class="w-[26px] h-[26px] rounded-full text-theme bg-[rgba(0,0,0,0.4)] cursor-not-allowed absolute overflow-hidden centerBox"
                                  >
                                    <i class="el-icon-loading"></i>
                                  </div>
                                  <div
                                    @click="broadcastSupport(item)"
                                    class="flex items-center justify-center"
                                  >
                                    <svg-icon
                                      :svg-name="
                                        item.broadcastType
                                          ? 'liveBroadcast_broadcast_select'
                                          : 'liveBroadcast_broadcast'
                                      "
                                      className="svgBox !w-[26px] !h-[26px]"
                                    />
                                  </div>
                                </div>
                                <el-popover
                                  v-else
                                  placement="top"
                                  trigger="click"
                                  @show="
                                    setChannelPpover(
                                      `channelSelect_broadcast_${item.deviceId}`,
                                      true
                                    )
                                  "
                                  @hide="
                                    setChannelPpover(
                                      `channelSelect_broadcast_${item.deviceId}`,
                                      false
                                    )
                                  "
                                  popper-class="popover_darkColor liveBroadcast"
                                >
                                  <div class="w-[180px]">
                                    <channelSelect
                                      channelType="3"
                                      title="广播通道"
                                      v-if="
                                        channelPpover ===
                                        `channelSelect_broadcast_${item.deviceId}`
                                      "
                                      :channelId="item.channelId"
                                      :channelList="item.channelList || []"
                                      :deviceId="item.deviceId"
                                      @on-filterByDevice="
                                        broadcast_setItemChanne
                                      "
                                      @on-select="broadcast_setSelectChanne"
                                      :key="`channelSelect_broadcast_${item.deviceId}`"
                                    ></channelSelect>
                                    <div class="border-border border-t p-2">
                                      <el-button
                                        @click="broadcastSupport(item)"
                                        size="mini"
                                        class="button_delete w-full"
                                        >关闭广播</el-button
                                      >
                                    </div>
                                  </div>
                                  <div
                                    class="clickBox flex flex-col items-center"
                                    slot="reference"
                                  >
                                    <!--加载动画-->
                                    <div
                                      v-if="item.broadcastLoading"
                                      class="w-[26px] h-[26px] rounded-full text-theme bg-[rgba(0,0,0,0.4)] cursor-not-allowed absolute overflow-hidden centerBox"
                                    >
                                      <i class="el-icon-loading"></i>
                                    </div>
                                    <div>
                                      <svg-icon
                                        svg-name="liveBroadcast_broadcast_select"
                                        className="svgBox !w-[26px] !h-[26px]"
                                      />
                                    </div>
                                  </div>
                                </el-popover>
                              </div>
                            </el-tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-else
                    class="bg-backgroundv4 box-border border border-backgroundv2 !rounded w-full h-full flex justify-center items-center p-[20px]"
                  >
                    <div
                      @click="setPositionSelection(index)"
                      :class="{ '!border-theme': emptySelect === index }"
                      class="bg-backgroundv2 w-full h-full box-border rounded hover:border-theme clickBox border border-border flex items-center justify-center"
                    >
                      <svg-icon
                        class-name="!w-[60%] !h-[60%]"
                        svg-name="emptyVideo"
                      />
                    </div>
                  </div>
                </el-col>
              </transition-group>
            </draggable>
          </el-row>
        </div>
      </div>
    </div>
    <selectRangeDevice ref="selectRangeDevice"></selectRangeDevice>
    <issueMessage ref="issueMessage"></issueMessage>
  </div>
</template>

<script>
import monitor from './js/index.js'
import broadcast from './js/broadcast.js'
import recordRoom from './js/recordRoom.js'
import channe from '@components/device/videoCheck/multipleChecks/js/channe.js'
export default {
  mixins: [monitor, broadcast, recordRoom, channe],
  data() {
    return {}
  },
  mounted() {},
  methods: {}
}
</script>
<style scoped lang="less" rel="stylesheet/less">
.el-dialog {
  position: fixed;
  top: 20%;
  left: 10%;
}
.soundControl {
  transition: 0.5s;
  width: 150px;
  height: 40px;
  padding: 0px 8px;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: var(--box-shadow);
  color: var(--main);
  /*滑动条背景*/
  input[type='range'] {
    -webkit-appearance: none;
    height: 8px;
    background: var(--item);
    border-radius: 4px;
  }
  input[type='range']::-ms-fill-lower {
    -webkit-appearance: none;
  }
  /*滑动条操作按钮样式*/
  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 10px;
    height: 20px;
    background: linear-gradient(270deg, #ffffff 0%, #d5d9dc 100%);
    box-shadow: 0px 2px 4px 0px rgba(27, 47, 86, 0.3),
      inset -1px 0px 0px 0px #ffffff;
    border-radius: 3px;
  }
  img {
    height: 20px;
    position: relative;
    left: -10px;
    transform: rotate(89deg);
  }
}
.mover {
  &:active {
    cursor: grabbing;
  }
  cursor: grab;
}
</style>
<style lang="less" rel="stylesheet/less">
.videoSurveillanceBox {
  video {
    position: absolute;
    top: 52%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 80%;
  }
}
</style>
