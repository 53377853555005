// 已废弃待删除
const state = {
  isCreateDangerDrawer: false
}

const getters = {
  setCreateDangerDrawerState(state) {
    return state.setCreateDangerDrawerState
  }
}

const mutations = {
  setCreateDangerDrawerState(state, data) {
    state.isCreateDangerDrawer = data
  }
}

export default {
  state,
  getters,
  mutations
}
