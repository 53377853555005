// 致业版本
let contentObj = {}

/* window 执行 */
if (typeof window === 'object') {
  const lodash = require('lodash')
  const zhLocale = require('@/lang/zh')
  /* 公共组件 */
  const userHeadPortrait = require('@components/common/userHeadPortrait')
  const iconBox = require('@components/common/iconBox')
  const AppTable = require('@components/common/AppTableV2')
  const SvgIcon = require('@components/common/svgIcon.vue')
  const orgCascader = require('@/components/common/orgCascader')
  const deleteConfirmationBox = require('@components/common/deleteConfirmationBox')
  /* 电子围栏 */
  const electronicFence = require('@components/electronicFence/electronicFence.vue')
  const mapAearchPlace = require('@components/electronicFence/mapAearchPlace/index.vue')
  /* 规则设置 */
  const ruleSetting = require('@components/ruleSetting/ruleSetting.vue')
  /* hy-下拉选择框 */
  const hySelect = require('@components/common/hySelect/select.vue')

  /* 公共组件，子应用全部也注册成公共应用 */
  window.commonComponents = {
    SvgIcon: SvgIcon.default,
    iconBox: iconBox.default,
    AppTable: AppTable.default,
    deleteConfirmationBox: deleteConfirmationBox.default,
    electronicFence: electronicFence.default,
    mapAearchPlace: mapAearchPlace.default,
    ruleSetting: ruleSetting.default,
    userHeadPortrait: userHeadPortrait.default,
    orgCascader: orgCascader.default,
    hySelect: hySelect.default
  }

  /* 自定义公共方法 */
  window.customPublicMethod = {
    cloneDeep: lodash.cloneDeep
  }
  const entryList = {
    // 林乾开发环境
    linqianDevEntry: {
      operator: '//192.168.0.88:20001',
      dispatching: '//192.168.0.88:20002',
      mediaCenter: '//192.168.0.88:20003',
      mission: '//192.168.0.88:20004',
      monitor: '//192.168.0.88:20005',
      examine: '//192.168.0.88:20006',
      setting: '//192.168.0.88:20007',
      alert: '//192.168.0.88:20008',
      dailyCheck: '//192.168.0.88:20009',
      risk: '//192.168.0.88:20010',
      dataCenter: '//192.168.0.88:20012'
    },
    // 嘉威开发环境
    jiaweiDevEntry: {
      operator: '//192.168.0.222:20001',
      dispatching: '//192.168.0.222:20002',
      mediaCenter: '//192.168.0.222:20003',
      mission: '//192.168.0.222:20004',
      monitor: '//192.168.0.222:20005',
      examine: '//192.168.0.222:20006',
      setting: '//192.168.0.222:20007',
      alert: '//192.168.0.222:20008',
      dailyCheck: '//192.168.0.222:20009',
      risk: '//192.168.0.222:20010',
      report: '//192.168.0.222:20013',
      dataCenter: '//192.168.0.222:20012'
    },
    // 百安达服务器
    baiandaServer: {
      operator: '//operator.baianda-fire-control.huayu-et.com/', // 运营中心
      dispatching: '//dispatching.baianda-fire-control.huayu-et.com/', // 指挥中心
      mediaCenter: '//media-center.baianda-fire-control.huayu-et.com/', // 回放中心
      mission: '//mission.baianda-fire-control.byanda.net/', // 任务中心
      monitor: '//monitor.baianda-fire-control.byanda.net/', // 监控中心
      setting: '//setting.baianda-fire-control.byanda.net/', // 设置中心
      examine: '//examine.baianda-fire-control.byanda.net/', // 检查中心
      meeting: '//meeting.baianda-fire-control.byanda.net/', // 视频会议
      dailyCheck: '//attendance.baianda-fire-control.byanda.net/', // 考勤中心
      alert: '//alert.baianda-fire-control.byanda.net/', // 警情中心
      risk: '//risk.baianda-fire-control.byanda.net/' // 风险管控
    },
    // 贵州服务器
    guizhouServer: {
      operator: '//operator.gz119.huayu-et.com/', // 运营中心
      dispatching: '//dispatching.gz119.huayu-et.com/', // 指挥中心
      mediaCenter: '//media-center.gz119.huayu-et.com/', // 回放中心
      mission: '//mission.gz119.byanda.net/', // 任务中心
      monitor: '//monitor.gz119.byanda.net/', // 监控中心
      setting: '//setting.gz119.byanda.net/', // 设置中心
      examine: '//examine.gz119.byanda.net/', // 检查中心
      meeting: '//meeting.gz119.huayu-et.com/', // 视频会议
      dailyCheck: '//attendance.gz119.huayu-et.com/', // 考勤中心
      alert: '//alert.gz119.byanda.net/', // 警情中心
      risk: '//risk.gz119.byanda.net/' // 风险管控
    },
    // 执法仪服务器
    dispatchServer: {
      dataCenter: '//data-center.hy-ser.huayu-et.com/', // 数据首页
      dispatching: '//dispatching.hy-ser.huayu-et.com/', // 指挥中心
      monitor: '//monitor.hy-ser.huayu-et.com/', // 监控中心
      mission: '//mission.hy-ser.huayu-et.com/', // 任务中心
      dailyCheck: '//daily-check.hy-ser.huayu-et.com/', // 考勤中心
      // video: '//video.hy-ser.huayu-et.com/', // 会议.
      mediaCenter: '//media-center.hy-ser.huayu-et.com/', // 回放中心
      report: '//report.hy-ser.huayu-et.com/', // 报表中心
      operator: '//operator.hy-ser.huayu-et.com/', // 运营中心
      setting: '//setting.hy-ser.huayu-et.com/' // 设置中心
    }
  }
  // 二级导航
  const menuList = [
    {
      /* 数据管理 */
      label: '首页',
      permission: 'dataCenter',
      iconName: 'dataCenter',
      path: '/dataCenter'
    },
    {
      label: '指挥',
      permission: 'commands',
      iconName: 'dispatching',
      path: '/dispatching'
    },
    {
      label: '监控',
      permission: 'monitor',
      iconName: 'monitor',
      path: '/monitor'
    },
    {
      label: '任务',
      permission: 'mission',
      iconName: 'mission',
      path: '/mission'
    },
    {
      label: '考勤',
      iconName: 'dailyCheck',
      permission: 'dailyCheck',
      path: '/dailyCheck'
    },
    {
      label: '布控',
      permission: 'viidControl',
      iconName: 'viidControl',
      path: '/viidControl'
    },
    {
      label: '会议',
      permission: 'videoConference',
      iconName: 'videoConference',
      path: '/videoConference'
    },
    {
      label: '回放',
      permission: 'mediaCenter',
      iconName: 'mediaCenter',
      path: '/mediaCenter'
    },
    {
      label: '报表',
      permission: 'report',
      iconName: 'report',
      path: '/report'
    },
    {
      label: '运营',
      permission: 'operator',
      iconName: 'operator',
      path: '/operator'
    },
    {
      label: '设置',
      permission: 'setting',
      iconName: 'setting',
      path: '/setting'
    }
  ]

  // 用户引导数据列表
  const userGuidanceList = [
    // left, left-center, left-bottom, top, top-center, top-right, right, right-center,right-bottom或 bottom, bottom-center, bottom-right, mid-center。
    {
      element: '#dataCenter',
      permission: 'dataCenter',
      popover: {
        title: '首页',
        description: '统计平台各项关键数据概览。',
        position: 'bottom'
      }
    },
    {
      element: '#commands',
      permission: 'commands',
      popover: {
        title: '指挥',
        description: '查看设备分布并对设备下发功能指令指挥调度。',
        position: 'bottom'
      }
    },
    {
      element: '#monitor',
      permission: 'monitor',
      popover: {
        title: '监控',
        description: '分屏查看监控画面，最少1屏最多16屏。',
        position: 'bottom'
      }
    },
    {
      element: '#mission',
      permission: 'mission',
      popover: {
        title: '任务',
        description: '向设备下发任务，设备执行操作向平台汇报任务情况。',
        position: 'bottom'
      }
    },
    {
      element: '#dailyCheck',
      permission: 'dailyCheck',
      popover: {
        title: '考勤',
        description: '设置班次与考勤组并可查询考勤人员的考勤记录。',
        position: 'bottom'
      }
    },
    {
      element: '#mediaCenter',
      permission: 'mediaCenter',
      popover: {
        title: '回放',
        description: '查询系统的视频、音频、图片文件及设备轨迹。',
        position: 'bottom'
      }
    },
    {
      element: '#report',
      permission: 'report',
      popover: {
        title: '报表',
        description: '查询系统各项数据统计的报表。',
        position: 'bottom'
      }
    },
    {
      element: '#operator',
      permission: 'operator',
      popover: {
        title: '运营',
        description:
          '建设部门、角色、用户、设备、群组、人脸识别、车辆识别、APK升级、规则设置等运营数据。',
        position: 'bottom'
      }
    },
    {
      element: '#setting',
      permission: 'setting',
      popover: {
        title: '设置',
        description: '设置系统基础信息与各项参数。',
        position: 'bottom'
      }
    },
    {
      element: '#guide_news',
      popover: {
        title: '消息',
        description: '查看系统消息、SOS、违反规则、故障日志、存储预警信息。',
        position: 'bottom-right'
      }
    },
    {
      element: '#guide_instructions',
      popover: {
        title: '指令进度',
        description: '可查看设备指令执行进度。',
        position: 'bottom-right'
      }
    },
    {
      element: '#guide_fullScreen',
      popover: {
        title: '全屏',
        description: '将系统全屏化或取消全屏化。',
        position: 'bottom-right'
      }
    },
    {
      element: '#guide_userInformation',
      popover: {
        title: '用户信息',
        description: '展示登录用户的信息，可修改用户密码与退出登录。',
        position: 'bottom-right'
      }
    }
  ]
  const entry = entryList[process.env.VUE_APP_SERVER_INFO] || {}
  /* svg地址 */
  // directory: 要查找的文件路径
  // useSubdirectories: 是否查找子目录
  // regExp: 要匹配文件的正则
  const svgUrl = require.context('@/assets/dispatch/svg', true, /\.svg$/)
  const messages = {
    en: {
      ...zhLocale.default
    },
    zh: {
      ...zhLocale.default
    }
  }
  /* 地图默认配置参数 */
  const mapConfiguration = {
    /* 默认也就是设计图颜色 */
    defaultMapStyle: 'amap://styles/eeefd028855fff4361276ff7210b0961',
    // defaultMapStyle: 'amap://styles/darkblue',
    /* 通用也就是白色 */
    currencyMapStyle: 'amap://styles/normal'
    // 标准 normal
    // 幻影黑 dark
    // 月光银  light
    // 远山黛  whitesmoke
    // 草色青  fresh
    // 雅士灰  grey
    // 涂鸦  graffiti
    // 马卡龙  macaron
    // 靛青蓝  blue
    // 极夜蓝  darkblue
    // 酱籽  wine
  }
  /* 密码加密 */
  const RSA_PUBLIC_KEY =
    'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA6pbuym6zxwQw7alnsZ2n1rOb2R88rOzfP/eRniLkceaf/J62yhvPKc+yexk4ySWQLOA/Eo3TLKYBolUmjWLQZRkAEKfQ7IKDul8l9Nq9slf5duAI7VxoHR4OwSYEe29M9y4kECSJ2PZrSaNGFojCIXVVLOxYfbBV3nsmvb29XewiWX6dQUeJM+MT70/Y+/jcM5EGConD9Nvokp7z3+IA6Y8So1axdoGSPUZmRx1pAtLJ94uR8RFJB085N53gBnihVKp6sw19m7SwVZah+JFF2zfP0u/xT/u1WDcAqaCwHuLIrNOvDrF+5y1mHS42yA1CkrYILPm1aBXU2aTt09j0xQIDAQAB'
  const headConfigure = {
    href: 'dispatch',
    title: '可视化调度平台'
  }
  const stylesName = 'basics_dispatch'
  contentObj = {
    entry,
    menuList,
    svgUrl,
    mapConfiguration,
    messages,
    RSA_PUBLIC_KEY,
    userGuidanceList,
    headConfigure,
    stylesName
  }
}

const assetsUrl = 'src/assets/dispatch'

/* 组件内容 */
const componentsContent = {
  headerBox: 'headerBox_quanSheng',
  addNewTask: 'addNewTask_tiantong' /* 新增任务 */,
  login: 'login_pipingGas', // 登录页
  headerNavigation: 'header_dispatch', // 头部导航
  reportPopup: 'reportPopup_dispatch' // 消息中心
}

/* 组件样式 */
const componentsStyle = {}

module.exports = {
  ...contentObj,
  assetsUrl,
  componentsContent,
  componentsStyle
}
