<!-- 新报警 -->
<template>
  <div class="newAlarm">
    <div
      v-if="form.draggedType && dialogVisible"
      class="newCaseBox"
      :style="{
        height: 'calc(100% - 70px)'
      }"
    ></div>
    <div>
      <div
        v-el-drag-dialog
        v-if="dialogVisible"
        class="el-dialog w-[300px] z-[301]"
      >
        <div class="goToInquireBox centerBox el-dialog__header p-0">
          <div class="goToInquire w-full el-dialog__body p-0">
            <svg-icon
              :svg-name="form.contentSvgName"
              class-name="!w-[120px] !h-[120px]"
            />
            <div class="text-auxiliary mb-2 px-3">
              <div class="text-main font-bold text-sm over_one_line">
                {{ params.devName || '设备名称' }}
              </div>
              <div class="flex my-2 items-center justify-center">
                <div class="flex-shrink-0 mr-1 flex items-center">
                  <svg-icon
                    svg-name="location"
                    class-name="svgBox !w-[12px] !h-[12px]"
                  />
                </div>
                <div class="over_one_line">
                  {{ params.incidentAddress || '位置获取失败' }}
                </div>
              </div>
              <div
                class="bg-backgroundv2 border border-border rounded box-border px-2 py-1 text-xs mt-2 mb-5"
              >
                <div class="my-2 flex items-center justify-between">
                  <div>IMEI</div>
                  <div
                    class="flex-grow w-0 ml-1 text-main over_one_line text-right"
                  >
                    {{ params.deviceId || '/' }}
                  </div>
                </div>
                <div class="my-2 flex items-center justify-between">
                  <div>所属部门</div>
                  <div
                    class="flex-grow w-0 ml-1 text-main over_one_line text-right"
                  >
                    {{ params.orgName || '/' }}
                  </div>
                </div>
                <div class="my-2 flex items-center justify-between">
                  <div>报警时间</div>
                  <div
                    class="flex-grow w-0 ml-1 text-main over_one_line text-right"
                  >
                    {{ params.alarmDtm || '/' }}
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="form.submitShow || form.closeShow"
              class="m-[20_0_10px]"
              :style="{ 'text-align': form.textAlign }"
            >
              <el-button
                v-if="form.closeShow"
                size="small"
                :class="form.closeClass"
                class="buttonBox"
                @click="onClose"
                >{{ form.closeText }}</el-button
              >
              <el-button
                v-if="form.submitShow"
                size="small"
                :class="form.submitClass"
                class="buttonBox"
                @click="onSubmit"
                >{{ form.submitText }}</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import elDragDialog from '@components/el-drag-dialog'
export default {
  directives: { elDragDialog },
  data() {
    return {
      params: {},
      form: {
        textAlign: 'center',
        contentSvgName: 'sos-ring',
        closeShow: true,
        draggedType: true,
        closeClass: 'button_default',
        closeText: '稍后查看',
        submitShow: true,
        submitClass: 'button_function',
        submitText: '立即处理'
      },
      dialogVisible: false
    }
  },
  mounted() {},
  //created(){ // document.title = this.type === 2 ? '设置手机号' : '绑定手机号' },
  methods: {
    show(obj, params = {}) {
      this.form = {
        textAlign: 'center',
        contentSvgName: 'sos-ring',
        draggedType: true,
        closeShow: true,
        closeClass: 'button_default',
        closeText: '稍后查看',
        submitShow: true,
        submitClass: 'button_delete',
        submitText: '立即查看',
        ...obj
      }
      if (this.form.draggedType) {
        document.querySelector('#SOSalarm').play()
      }
      this.params = params
      this.dialogVisible = true
    },
    close() {
      this.params = null
      document.querySelector('#SOSalarm').pause()
      this.dialogVisible = false
    },
    onClose() {
      this.$emit('on-close', this.params)
      this.$nextTick(() => {
        this.close()
      })
    },
    onSubmit() {
      this.$emit('on-submit', this.params)
      this.$nextTick(() => {
        this.close()
      })
    }
  }
}
</script>
<style scoped lang="less" rel="stylesheet/less">
.newAlarm {
  .el-dialog {
    position: fixed;
    top: calc(50% - 190px);
    left: calc(50% - 150px);
  }
}
.newCaseBox {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  animation: mymove 2s infinite;
  z-index: 300;
}
.goToInquireBox {
  width: 100%;
  box-sizing: border-box;
  padding: 2px;
  border-radius: 12px;
  box-shadow: 0px 0px 10px 2px rgba(255, 35, 40, 0.5);
  background-image: linear-gradient(
    180deg,
    var(--danger) 0%,
    var(--theme) 100%
  );
}
.goToInquire {
  line-height: 20px;
  color: var(--main);
  font-size: 14px;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  text-align: center;
  background: var(--background);
  padding-top: 24px;
  padding-bottom: 10px;
  /*background: linear-gradient(180deg, #240B0B 0%, #0D1A2A 100%);*/
}
@keyframes mymove {
  0% {
    box-shadow: 0px 0 300px 20px rgba(255, 0, 0, 1) inset;
  }
  80% {
    box-shadow: 0px 0 300px 20px rgba(255, 0, 0, 0) inset;
  }
  100% {
    box-shadow: 0px 0 300px 20px rgba(255, 0, 0, 1) inset;
  }
}
</style>
