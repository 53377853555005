import Axios from '@/http'
export default {
  orgTreeWithFireControlOffice() {
    return new Promise((resolve, reject) => {
      Axios.post('fireControlOffice/orgTreeWithFireControlOffice').then(
        (res) => {
          resolve(res)
        }
      )
    })
  }
}
