export default {
  filterNodeMethod(type, value, data) {
    if (value === '') {
      return true
    } else {
      /*
       * 1 部门
       * 3 设备
       * 5 人员
       * 6 考勤 (人员)
       * 7 考勤名称 || 班次名称
       * */
      if (type === 7) {
        if (data.typeName === 'workOrderList') {
          /* 班次 */
          if (data.workTimeName && data.workTimeName.toString().includes(value)) {
            return true
          }
        } else {
          /* 考勤组 */
          if (data.dailyCheckName && data.dailyCheckName.toString().includes(value)) {
            return true
          }
        }
      } else if (type === 6) {
        const item = data.item || {}
        if (data.deviceUserName && data.deviceUserName.toString().includes(value)) {
          return true
        } else if (item.deviceId && item.deviceId.toString().includes(value)) {
          return true
        }
      } else if (type === 5) {
        if (data.jobNum && data.jobNum.toString().includes(value)) {
          return true
        } else if (data.name && data.name.toString().includes(value)) {
          return true
        } else if (data.phone && data.phone.toString().includes(value)) {
          return true
        }
      } else if (type === 3 || data.type === 3) {
        if (data.deviceId && data.deviceId.toString().includes(value)) {
          return true
        } else if (data.devName && data.devName.toString().includes(value)) {
          return true
        }
      } else {
        if (data.companyName && data.companyName.toString().includes(value)) {
          return true
        } else if (
          data.groupName &&
          data.groupName.toString().includes(value)
        ) {
          return true
        } else if (data.orgName && data.orgName.toString().includes(value)) {
          return true
        }
      }
    }
    return false
  },
  /* 过滤部门 */
  filteringDepartment(filterValue, dataList = []) {
    const children = []
    dataList.forEach((val) => {
      let type = null
      if (val.children && val.children.length) {
        val.children = this.filteringDepartment(filterValue, val.children)
        if (val.children.length) {
          children.push(val)
        } else {
          type = this.filterNodeMethod(1, filterValue, val)
          if (type) {
            children.push(val)
          } else if (val.deviceList) {
            const index = val.deviceList.findIndex((device) => {
              return this.filterNodeMethod(3, filterValue, device)
            })
            if (index !== -1) {
              children.push(val)
            }
          }
        }
      } else {
        type = this.filterNodeMethod(1, filterValue, val)
        if (type) {
          children.push(val)
        } else if (val.deviceList) {
          const index = val.deviceList.findIndex((device) => {
            return this.filterNodeMethod(3, filterValue, device)
          })
          if (index !== -1) {
            children.push(val)
          }
        }
      }
    })
    // console.log(children)
    return children
  },
  /* 树形_部门设备 */
  handlerDevTree(filterValue, dataList, params, parent) {
    const {  } = params || {}
    const deviceList = []
    const children = []
    dataList.forEach((val, index) => {
      if (val.type === 3) {
        val.onlyId = `${parent ? `superior${parent.id}` : '' }device${val.id}`
      } else {
        val.onlyId = val.id
      }
      val.childrenDeviceList = []
      val.onlineCountList = []

      let parentOrgName = null
      // 上级部门
      if (parent && parent.companyName && val.type !== 3) {
        parentOrgName = parent.companyName
      }

      if (parent && parent.parentIdsList) {
        val.parentIdsList = [
          ...parent.parentIdsList,
          {
            id: val.id,
            currentSubscript: index
          }
        ]
      } else {
        val.parentIdsList = [
          {
            id: val.id,
            currentSubscript: index
          }
        ]
      }
      let type = false
      if ((parent && parent.parentType) || val.type === 3) {
        type = true
      } else {
        type = this.filterNodeMethod(1, filterValue, val)
      }
      const item = type ? val : {}

      /* 循环子级设备 */
      // deviceList
      if (val.deviceList && val.deviceList.length) {
        const childrenDeviceList = []
        val.deviceList.forEach((val2, index2) => {
          if (
            (parent && parent.parentType) ||
            this.filterNodeMethod(3, filterValue, val2) ||
            type
          ) {
            const deviceObj = {
              ...val2,
              parentOrgName: parentOrgName,
              parentIdsList: val.parentIdsList
            }
            val.childrenDeviceList.push(deviceObj)
            deviceList.push(deviceObj)
            // 处理设备状态
            const obj = {
              parentType: true,
              ...val2,
              isLeaf: true,
              label: val2.name || val2.deviceId,
              deviceId: val2.deviceId,
              deviceType: val2.type,
              prodModel: val2.prodModel ? val2.prodModel : '',
              simNum: val2.simNum ? val2.simNum : '',
              companyName: dataList[index].companyName,
              select: false,
              expand: false,
              type: 3,
              item: val2,
              parentId: val2.parentId ? val2.parentId : '',
              parentName: val2.parentName ? val2.parentName : '',
              onlineState: val2.onlineState,
              onlineStateStr: val2.onlineState === 1 ? '在线' : '离线',
              personnel: val2.personnel ? '1' : '2',
              lng: val2.lng,
              lat: val2.lat,
              address: val2.address,
              gpsDtm: val2.gpsDtm,
              parentOrgName: parentOrgName,
              id: 'device-' + val2.id
            }
            if (val2.onlineState === 1) {
              childrenDeviceList.unshift(obj)
            } else {
              childrenDeviceList.push(obj)
            }
          }
        })
          val.children.push(...childrenDeviceList)
      } else {
        val.deviceList = []
      }
      if (val.children && val.children.length > 0) {
        const json = this.handlerDevTree(filterValue, val.children, params,{
          ...val,
          parentType: type
        })
        const childrenDeviceList = json.deviceList
        val.childrenDeviceList.push(...childrenDeviceList)
        deviceList.push(...childrenDeviceList)
        val.childrenDeviceList = val.childrenDeviceList.filter(
          (childrenDeviceItem, index, self) => {
            return self.indexOf(childrenDeviceItem) === index
          }
        )
        val.onlineCountList = val.childrenDeviceList.filter(
          (v) => v.onlineState === 1
        )
        item.children = json.children
      }

      if (Object.keys(item).length) {
        if (
          val.type === 3 ||
          item.companyName ||
          item.orgName ||
          item.children.length
        ) {
          children.push({ ...val, ...item })
        }
      }
    })
    return {
      children: children,
      deviceList: deviceList.filter((item, index, self) => {
        return self.indexOf(item) === index
      })
    }
  },
  createUniqueId(n) {
    var random = function () {
      // 生成10-12位不等的字符串
      return Number(Math.random().toString().substr(2)).toString(36) // 转换成十六进制
    }
    var arr = []
    function createId() {
      var num = random()
      var _bool = false
      arr.forEach((v) => {
        if (v === num) _bool = true
      })
      if (_bool) {
        createId()
      } else {
        arr.push(num)
      }
    }
    var i = 0
    while (i < n) {
      createId()
      i++
    }
    return arr[0]
  },
  /* 树形_群组 */
  orgGroup_handlerDevTree(filterValue, dataList, parent) {
    const deviceList = []
    const children = []
    dataList.forEach((val, index) => {
      if (val.type === 3) {
        val.onlyId = `${parent ? `superior${parent.id}` : ''}device${val.id}`
      } else if (val.type === 4) {
        val.onlyId = `${parent ? `superior${parent.id}` : ''}group${val.id}`
      } else {
        val.onlyId = val.id
      }
      val.childrenDeviceList = []
      val.onlineCountList = []
      let type = false
      if (val.type === 3) {
        type = true
      } else {
        type = this.filterNodeMethod(1, filterValue, val)
      }
      const item = type ? val : {}
      if (val.groupList && val.groupList.length) {
        val.groupList.forEach((val2, index2) => {
          // 处理设备状态
          // 搜索设备号的时候，过滤当前群组里的其他设备
          if (val2.deviceList && val2.deviceList.length > 0) {
            let childrenDeviceList = []
            if (
              (parent && parent.parentType) ||
              this.filterNodeMethod(1, filterValue, val2) ||
              type
            ) {
              val2.deviceList.forEach((deviceItem) => {
                const obj = {
                  isLeaf: true,
                  ...deviceItem,
                  children: [],
                  orgGroupId: val2.id,
                  deviceType: deviceItem.type,
                  type: 3
                }
                if (deviceItem.onlineState === 1) {
                  childrenDeviceList.unshift(obj)
                } else {
                  childrenDeviceList.push(obj)
                }
              })
              val.children.push({
                ...val2,
                children: childrenDeviceList,
                type: 4
              })
            } else {
              val2.deviceList.forEach((deviceItem) => {
                if (this.filterNodeMethod(3, filterValue, deviceItem)) {
                  const obj = {
                    isLeaf: true,
                    ...deviceItem,
                    children: [],
                    orgGroupId: val2.id,
                    deviceType: deviceItem.type,
                    type: 3
                  }
                  if (deviceItem.onlineState === 1) {
                    childrenDeviceList.unshift(obj)
                  } else {
                    childrenDeviceList.push(obj)
                  }
                }
              })
              if (childrenDeviceList.length) {
                val.children.push({
                  ...val2,
                  children: childrenDeviceList,
                  type: 4
                })
              }
            }
          } else {
            val.children.push({
              ...val2,
              children: [],
              type: 4
            })
          }
        })
      } else {
        val.groupList = []
      }

      if (val.deviceList && val.deviceList.length) {
        val.childrenDeviceList = val.deviceList
      }
      if (val.childrenDeviceList) {
        val.childrenDeviceList.forEach((v) => {
          const index = deviceList.findIndex((o) => o.deviceId === v.deviceId)
          if (index === -1) {
            deviceList.push({ ...v })
          }
        })
      }

      if (val.children && val.children.length > 0) {
        const json = this.orgGroup_handlerDevTree(filterValue, val.children, {
          ...val,
          parentType: type
        })
        const childrenDeviceList = json.deviceList
        val.childrenDeviceList.push(...childrenDeviceList)
        val.onlineCountList = val.childrenDeviceList.filter(
          (v) => v.onlineState === 1
        )
        deviceList.push(...childrenDeviceList)

        item.children = json.children
      }
      if (Object.keys(item).length) {
        if (
          val.type === 3 ||
          item.companyName ||
          item.orgName ||
          item.children.length
        ) {
          children.push({ ...val, ...item })
        }
      }
    })
    return {
      children: children,
      deviceList: deviceList.filter((item, index, self) => {
        return self.indexOf(item) === index
      })
    }
  },
  /* 树形_人员 */
  deviceUserTree(filterValue, dataList, parent) {
    const deviceList = []
    const children = []
    dataList.forEach((val, index) => {
      if (val.type === 3) {
        val.onlyId = `${parent ? `superior${parent.id}` : ''}user${val.id}`
      } else {
        val.onlyId = val.id
      }
      val.childrenDeviceList = []
      val.onlineCountList = []
      if (parent && parent.parentIdsList) {
        val.parentIdsList = [
          ...parent.parentIdsList,
          {
            id: val.id,
            currentSubscript: index
          }
        ]
      } else {
        val.parentIdsList = [
          {
            id: val.id,
            currentSubscript: index
          }
        ]
      }
      let type = false
      if ((parent && parent.parentType) || val.type === 3) {
        type = true
      } else {
        type = this.filterNodeMethod(1, filterValue, val)
      }
      const item = type ? val : {}
      /* 循环子级设备 */
      if (val.deviceUserList && val.deviceUserList.length) {
        const childrenDeviceList = []
        val.deviceUserList.forEach((val2, index2) => {
          if (
            (parent && parent.parentType) ||
            this.filterNodeMethod(5, filterValue, val2) ||
            type
          ) {
            const deviceObj = {
              ...val2,
              parentType: true,
              isLeaf: true,
              label: val2.name || val2.id,
              deviceId: val2.id,
              deviceType: val2.type,
              type: 3,
              item: val2,
              parentIdsList: val.parentIdsList
            }
            val.childrenDeviceList.push(deviceObj)
            deviceList.push(deviceObj)
            // 处理设备状态
            const obj = {
              ...val2,
              parentType: true,
              isLeaf: true,
              label: val2.name || val2.id,
              deviceId: val2.id,
              deviceType: val2.type,
              type: 3,
              item: val2
            }
            if (val2.onlineState === 1) {
              childrenDeviceList.unshift(obj)
            } else {
              childrenDeviceList.push(obj)
            }
          }
        })
        val.children.push(...childrenDeviceList)
      } else {
        val.deviceList = []
      }

      if (val.children && val.children.length > 0) {
        const json = this.deviceUserTree(filterValue, val.children, {
          ...val,
          parentType: type
        })
        const childrenDeviceList = json.deviceList
        val.childrenDeviceList.push(...childrenDeviceList)
        deviceList.push(...childrenDeviceList)
        val.childrenDeviceList = val.childrenDeviceList.filter(
          (childrenDeviceItem, index, self) => {
            return self.indexOf(childrenDeviceItem) === index
          }
        )
        val.onlineCountList = val.childrenDeviceList.filter(
          (v) => v.onlineState === 1
        )
        item.children = json.children
      }
      if (Object.keys(item).length) {
        if (
          val.type === 3 ||
          item.companyName ||
          item.orgName ||
          item.children.length
        ) {
          children.push({ ...val, ...item })
        }
      }
    })
    return {
      children: children,
      deviceList: deviceList.filter((item, index, self) => {
        return self.indexOf(item) === index
      })
    }
  },
  /* 树形_考勤 */
  dailyCheckTree (filterValue, dataList, parent) {
    const deviceList = []
    const children = []
    dataList.forEach((val, index) => {
      if (val.type === 6) {
        val.onlyId = `${parent ? `superior${parent.id}` : ''}user${val.id}`
      } else {
        val.onlyId = val.id
      }
      val.childrenDeviceList = []
      val.onlineCountList = []
      if (parent && parent.parentIdsList) {
        val.parentIdsList = [...parent.parentIdsList, {
          id: val.id,
          currentSubscript: index
        }]
      } else {
        val.parentIdsList = [{
          id: val.id,
          currentSubscript: index
        }]
      }
      let type = false
      if (parent && parent.parentType || val.type === 6) {
        type = true
      } else {
        type = this.filterNodeMethod(7, filterValue, val)
      }
      const item = type ? val : {}
      /* 循环子级设备 */
      if (val.userList && val.userList.length) {
        const childrenDeviceList = []
        val.userList.forEach((val2, index2) => {
          if (parent && parent.parentType || this.filterNodeMethod(6, filterValue, val2) || type) {
            const deviceObj = {
              ...val2,
              parentType: true,
              isLeaf: true,
              label: val2.name || val2.id,
              originalDeviceId: val2.deviceId,
              deviceId: val2.temporaryId,
              deviceType: val2.type,
              type: 6,
              item: val2,
              parentIdsList: val.parentIdsList
            }
            val.childrenDeviceList.push(deviceObj)
            deviceList.push(deviceObj)
            // 处理设备状态
            const obj = {
              ...val2,
              parentType: true,
              isLeaf: true,
              label: val2.name || val2.id,
              originalDeviceId: val2.deviceId,
              deviceId: val2.temporaryId,
              deviceType: val2.type,
              type: 6,
              item: val2
            }
            if (val2.onlineState === 1) {
              childrenDeviceList.unshift(obj)
            } else {
              childrenDeviceList.push(obj)
            }
          }
        })
        val.children.push(...childrenDeviceList)
      } else {
        val.deviceList = []
      }
      if (val.children && val.children.length > 0) {
        const json =  this.dailyCheckTree(filterValue, val.children, {
          ...val,
          parentType: type
        })
        const childrenDeviceList = json.deviceList
        val.childrenDeviceList.push(...childrenDeviceList)
        deviceList.push(...childrenDeviceList)
        val.childrenDeviceList = val.childrenDeviceList.filter((childrenDeviceItem, index, self) => {
          return self.indexOf(childrenDeviceItem) === index
        })
        val.onlineCountList = val.childrenDeviceList.filter(v => v.onlineState === 1)
        item.children = json.children
      }
      if (Object.keys(item).length) {
        if (val.type === 6 || item.dailyCheckName || item.orgName || (item.children && item.children.length)) {
          children.push({ ...val, ...item })
        }
      }
    })
    return {
      children: children,
      deviceList: deviceList.filter((item, index, self) => {
        return self.indexOf(item) === index
      })
    }
  }
}
