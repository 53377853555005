<template>
  <div
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    class="c-f w-full h-full"
  >
    <!--    v-if="($slots.operator-left-box) || ($slots.operator-right-box)"-->
    <div class="app-table-operator-box r-f c-f-i">
      <div class="app-table-operator-left-box r-f-i flex-grow w-0 mr-5">
        <div class="flex_vertically_center w-full">
          <slot name="operator-left-box"></slot>
        </div>
      </div>
      <div class="app-table-operator-right-box r-f-i flex-shrink-0">
        <div class="flex_vertically_center">
          <slot name="operator-right-box"></slot>
        </div>
        <!--    搜索区域-->
        <div
          class="search_darkColor c-f-i"
          v-if="searchPlaceholder"
          style="width: auto"
        >
          <el-input
            size="small"
            clearable
            :style="{ width: parseRem(matchInputWidth) + 'rem' }"
            @clear="getData()"
            @keyup.enter.native="getData()"
            :placeholder="`输入 ${searchPlaceholder} 搜索`"
            v-model="searchText"
          ></el-input>
          <div class="searchBox" @click="getData()">
            <svg-icon svg-name="search" className="svgBox" />
          </div>
        </div>
      </div>
    </div>
    <!--    表格区域-->
    <div v-if="internalContentType" class="table-content c-f-i">
      <!--      <div v-if="loading"><i class="el-icon-loading"></i> {{loadingEmptyText}}</div>-->
      <el-table
        ref="elTable"
        :row-key="getRowKeys"
        :data="tableData"
        @select="selectEvent"
        @select-all="selectEvent"
        height="98%"
        :style="{
          'font-size': `${parseRem(fontSize)}rem`
        }"
        :class="{ hideSelect: isShowAllSelect }"
        @row-click="handleRowClick"
        :row-class-name="tableRowClassName"
        :row-style="{ border: '1px solid #ddd' }"
      >
        <template #empty>
          {{ emptyText }}
          <slot name="empty"></slot>
        </template>
        <el-table-column type="expand" v-if="expandType">
          <template slot-scope="{ row }">
            <slot name="expand" v-bind:row="row"></slot>
          </template>
        </el-table-column>
        <template v-for="(item, index) in myColumns">
          <!--selection多选框类型-->
          <el-table-column
            :key="index"
            class-name="table-column-class"
            label-class-name="table-label-class-name"
            :prop="item.prop"
            :align="item.align"
            :show-overflow-tooltip="item.overflowTooltip"
            :label="item.label"
            :width="item.width || ''"
            :reserve-selection="true"
            :selectable="() => selectable"
            type="selection"
            v-if="item.type === 'selection'"
          >
          </el-table-column>
          <defaultColumn
            :item="item"
            v-if="item.type === 'default'"
            :key="index"
          >
            <template #headerSlot="{ column }">
              <slot
                :name="item.headerSlot"
                v-bind:tdItem="item"
                v-bind:column="column"
              ></slot>
            </template>
          </defaultColumn>
          <customColumn
            :item="item"
            v-if="item.type === 'customSlot'"
            :key="index"
          >
            <template #headerSlot="{ column }">
              <slot
                :name="item.headerSlot"
                v-bind:tdItem="item"
                v-bind:column="column"
              ></slot>
            </template>
            <template #customSlot="{ row, index }">
              <slot
                :name="item.customSlot"
                v-bind:tdItem="item"
                v-bind:row="row"
                v-bind:index="index"
              ></slot>
            </template>
          </customColumn>
        </template>
      </el-table>
    </div>
    <div v-else class="mb_20 h-0 flex-grow">
      <slot name="custom-content-box" v-bind:tableData="tableData"></slot>
    </div>
    <!--    多选后操作区-->
    <div class="multiple-box r-f">
      <div class="multiple-box-tips r-f-i">
        本页共有 <span>{{ tableData.length }}</span> 条数据
        <template v-if="selectedList.length > 0"
          >已选中 <span>{{ selectedList.length }}</span> 条</template
        >
      </div>
      <div class="multiple-box-slot r-f-i" v-if="selectedList.length > 0">
        <slot name="multiple-operator-slot"></slot>
      </div>
    </div>
    <!--分页器Start-->
    <div v-if="!url" class="footer-page c-f-i justify-center">
      <div class="r-f-i">
        <el-pagination
          class="pagination"
          :hide-on-single-page="true"
          :background="true"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 15, 30, 50, 100]"
          :page-size="pageSize"
          :layout="layout"
          :pager-count="pagerCount"
          :total="totalElements"
        >
        </el-pagination>
      </div>
    </div>
    <!--分页器End-->
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less">
.hideSelect {
  /* 禁用全选 */
  /deep/.el-table__header-wrapper .el-checkbox {
    display: none;
  }
}
.app-table-operator-box {
  justify-content: space-between;
  border-bottom: 0px solid #666b7c;
  padding-bottom: 8px;
  margin-bottom: 8px;

  .app-table-operator-left-box {
  }

  .app-table-operator-right-box {
  }
}
.table-content {
  height: 90%;
}
.footer-page {
  padding-top: 12px;
}
.search-box {
  width: 240px;
  background: var(--item-background-auxiliary-color);
  padding: 4px 4px;
  justify-content: space-between;
  border-radius: 8px;
  margin-left: 8px;
  .input-box {
    height: 28px;
    width: 80%;
    input {
      padding-left: 8px;
      line-height: 28px;
      width: 100%;
      background: var(--item-background-auxiliary-color);
      border: none;
      color: #fff;
      font-size: 12px;
    }
  }
  .search-btn {
    border-radius: 5px;
    cursor: pointer;
    height: 28px;
    width: 28px;
    background: var(--item-background-main-color);
    justify-content: center;
    align-items: center;
    .iconfont {
      font-size: 18px;
      color: #fff;
    }
    &:hover {
      background: #2f3644;
    }
  }
}
.multiple-box {
  font-size: 12px;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  min-height: 34px;
  .multiple-box-tips {
    letter-spacing: 3px;
    align-items: flex-end;
    span {
      color: var(--main-select-color);
      font-size: 14px;
    }
  }
  .multiple-box-slot {
  }
}
</style>
<script type="text/ecmascript-6">
import defaultColumn from '@/components/common/AppTable/column/defaultColumn'
import customColumn from '@/components/common/AppTable/column/customColumn'
import AppTableV2 from './js/index.js'
export default {
  mixins: [AppTableV2],
  data () {
    return {}
  },
  components: {
    defaultColumn,
    customColumn
  },
  watch: {
    tableColumns: {
      immediate: true,
      handler: 'handlerColumns'
    }
  },
  methods: {
  },
  mounted () {
    this.contentTypeFn(this.contentType)
    if (this.defaultGetData) {
      this.getData()
    }
  }
}
</script>
