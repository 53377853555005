var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.dialogTableVisible)?_c('div',{directives:[{name:"el-drag-dialog",rawName:"v-el-drag-dialog"}],staticClass:"el-dialog w-[900px] videoSurveillanceBox",style:({ 'z-index': _vm.dragLevel }),on:{"click":_vm.setDragLevel}},[_c('div',{staticClass:"h-[60vh] el-dialog__header w-full !p-0 absolute z-[1]"}),_c('div',{staticClass:"h-[60vh] !bg-backgroundv2 w-full flex flex-col overflow-hidden !rounded-[8px]"},[_c('div',{staticClass:"h-[50px] bg-backgroundv4 flex items-center px-[10px] relative"},[_c('div',{staticClass:"flex"},_vm._l((_vm.numberOfrowsList),function(item){return _c('div',{key:item.label + 'numberOfrowsList',staticClass:"clickBox w-[26px] h-[26px] shadow-[0_0_4px_0] hover:text-main hover:border-theme hover:shadow-theme shadow-background bg-background rounded border border-border text-xs font-bold text-auxiliary flex justify-center items-center ml-[8px]",class:{
              '!text-main !border-theme !shadow-theme':
                item.number === _vm.numberOfrows.number &&
                item.type === _vm.numberOfrows.type
            },on:{"click":function($event){return _vm.setNumberOfrows(item)}}},[_vm._v(" "+_vm._s(item.label)+" ")])}),0),_c('div',{staticClass:"absolute z-[2] left-1/2 -translate-x-1/2"},[_c('el-button',{staticClass:"button_delete",attrs:{"size":"small"},on:{"click":_vm.dealEnd}},[_vm._v("结束警情")])],1)]),_c('div',{staticClass:"flex-grow h-0 p-[8px_10px]"},[_c('el-row',{staticClass:"h-full",attrs:{"gutter":6}},[_c('draggable',{staticClass:"h-full",attrs:{"handle":".mover","disabled":false,"move":_vm.onMove},on:{"end":_vm.onEnd},model:{value:(_vm.monitorDeviceListV2),callback:function ($$v) {_vm.monitorDeviceListV2=$$v},expression:"monitorDeviceListV2"}},[_c('transition-group',{staticClass:"h-full block"},_vm._l((_vm.monitorDeviceList),function(item,index){return _c('el-col',{key:index,staticClass:"h-full py-[2px] group",class:{
                  'relative z-[2]': item.deviceId
                },style:({ height: _vm.getHeight(1, index) }),attrs:{"span":_vm.numberOfrows.type === 1
                    ? 24 / _vm.numberOfrows.number
                    : index === 0
                    ? 24 - 24 / _vm.numberOfrows.number
                    : 24 / _vm.numberOfrows.number}},[(item.deviceId)?_c('div',{ref:`${item.deviceId}`,refInFor:true,staticClass:"w-full h-full box_border border border-border rounded overflow-hidden text-xs text-auxiliary relative",class:{
                    '!border-theme':
                      item.methodType === 'successfulCall' &&
                      item.deviceId === _vm.currentDevice.deviceId
                  },attrs:{"id":`videoBox_${item.deviceId}`},on:{"click":function($event){return _vm.setCurrentDevice(item)}}},[_c('div',{staticClass:"mover h-[28px] flex justify-between items-center px-2 mb-1 relative z-10 bg-backgroundv2"},[_c('div',{staticClass:"w-[18px] h-[18px] bg-background rounded-full flex items-center justify-center text-main"},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('div',{staticClass:"flex-grow w-0 text-center text-sm mx-2 flex justify-center"},[_c('div',{staticClass:"over_one_line text-center"},[(item.parentOrgName)?_c('span',[_vm._v(_vm._s(item.parentOrgName)+"-")]):_vm._e(),(item.orgName)?_c('span',[_vm._v(_vm._s(item.orgName)+"-")]):_vm._e(),_c('span',[_vm._v(_vm._s(item.devName))])]),_c('div',{staticClass:"flex-shrink-0 ml-1"},[(item.sos === 1 || item.channelName)?_c('span',[_vm._v(" ("),_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.channelName),expression:"item.channelName"}]},[_vm._v(_vm._s(item.channelName))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.sos === 1),expression:"item.sos === 1"}],staticClass:"fw_600"},[_vm._v("sos")]),_vm._v(") ")]):_vm._e()])]),_c('div',{staticClass:"clickBox hover:text-dangerv2"},[_c('div',{on:{"click":function($event){$event.stopPropagation();return _vm.closeTheRoom([item])}}},[_c('svg-icon',{attrs:{"svg-name":"icon_close","className":"svgBox !w-[18px] !h-[18px]"}})],1)])]),(item.recordingTime)?_c('div',{staticClass:"min-w-[64px] h-[24px] font-bold rounded-[10px] bg-backgroundv305 flex items-center justify-center text-xs box-border absolute right-[10px] z-10"},[_c('span',{staticClass:"w-2 h-2 rounded-full bg-success inline-block mr-[4px]"}),_c('span',[_vm._v(_vm._s(_vm.getTime(item.recordingTime)))])]):_vm._e(),(
                      item.errorCode && item.methodType !== 'successfulCall'
                    )?_c('div',{staticClass:"juzhong text-sm text-main text-center"},[(item.errorCode === 1001)?_c('span',[_vm._v(" "+_vm._s(item.errorReason)+" ")]):_c('span',[(item.errorCode === 500 || item.errorCode === -1)?_c('span',[_vm._v(" "+_vm._s(item.onlineState === 1 ? '对方网络不佳' : '对方不在线')+" ")]):_c('span',[_vm._v(" 对方网络信号不佳 "),_c('span',{staticClass:"c_9DA7B2 fs_12"},[_vm._v("("+_vm._s(item.errorCode)+")")])])]),_c('div',{staticClass:"mt-3"},[_c('el-button',{staticClass:"button_function",attrs:{"size":"mini","loading":item.intercomInviteState},on:{"click":function($event){return _vm.reconnectFn(item)}}},[_vm._v(" "+_vm._s(item.intercomInviteState ? '努力加载中' : '重新连接')+" ")])],1)]):(item.methodType === 'clientClosed')?_c('div',{staticClass:"juzhong text-xs text-main text-center"},[_c('span',[_vm._v(" 设备退出房间，检查设备网路或是否在线 ")]),_c('div',{staticClass:"mt-3"},[_c('el-button',{staticClass:"button_function",attrs:{"size":"mini","loading":item.intercomInviteState},on:{"click":function($event){return _vm.reconnectFn(item)}}},[_vm._v(" "+_vm._s(item.intercomInviteState ? '努力加载中' : '重新连接')+" ")])],1)]):(item.methodType !== 'successfulCall')?_c('div',{staticClass:"juzhong text-xs text-main text-center"},[(item.intercomInviteState)?_c('el-button',{staticClass:"button_function",attrs:{"size":"mini","loading":true}},[_vm._v(" 加载画面中, 请耐心等待 ")]):_c('span',[_vm._v(" 正在连接摄像头，请耐心等待… ")])],1):_vm._e(),(
                      item.methodType === 'successfulCall' &&
                      _vm.operationList.length
                    )?_c('div',[_c('div',{staticClass:"absolute bottom-0 left-1/2 -translate-x-1/2 w-[96%] z-10"},[(!item.operationAreaHide)?_c('div',{staticClass:"w-full bg-backgroundv3 rounded-[12px_12px_0_0] h-[20px] flex items-center justify-center clickBox opacity-0 group-hover:opacity-100",on:{"click":function($event){$event.stopPropagation();return _vm.setOperationAreaHide(item, true)}}},[_c('svg-icon',{attrs:{"svg-name":"operationAreaShow","class-name":"svgBox !w-[12px] !h-[12px] clickBox"}})],1):_c('div',{staticClass:"w-full bg-backgroundv3 rounded-[12px_12px_0_0] h-[40px] z-10"},[_c('div',{staticClass:"absolute -top-[26px] left-0 bg-background border border-border p-1 rounded flex items-center justify-center"},[_c('svg-icon',{attrs:{"svg-name":"operationAreaHide","class-name":"svgBox !w-[12px] !h-[12px] clickBox"},nativeOn:{"click":function($event){return _vm.setOperationAreaHide(item, false)}}})],1),_c('div',{staticClass:"h-full flex justify-center items-center"},_vm._l((_vm.operationList),function(operationItem){return _c('el-tooltip',{directives:[{name:"delTabIndex",rawName:"v-delTabIndex"}],key:operationItem.type,staticClass:"mx-1",attrs:{"effect":"dark","open-delay":500,"content":operationItem.name,"placement":"top"}},[(
                                operationItem.type === 16 &&
                                item.channelList &&
                                item.channelList.length !== 0
                              )?_c('el-popover',{attrs:{"placement":"top","trigger":"click","popper-class":"popover_darkColor liveBroadcast"},on:{"show":function($event){return _vm.setChannelPpover(
                                  `channelSelect_${item.deviceId}`,
                                  true
                                )},"hide":function($event){return _vm.setChannelPpover(
                                  `channelSelect_${item.deviceId}`,
                                  false
                                )}}},[_c('div',{staticClass:"w-[180px]"},[(
                                    _vm.channelPpover ===
                                    `channelSelect_${item.deviceId}`
                                  )?_c('channelSelect',{key:`channelSelect_${item.deviceId}`,attrs:{"channelId":item.channelId,"channelList":item.channelList,"deviceId":item.deviceId},on:{"on-filterByDevice":_vm.setItemChanne,"on-select":_vm.setSelectChanne}}):_vm._e()],1),_c('div',{staticClass:"clickBox",attrs:{"slot":"reference"},slot:"reference"},[_c('svg-icon',{attrs:{"svg-name":"liveBroadcast_channel","className":"svgBox !w-[26px] !h-[26px]"}})],1)]):(operationItem.type === 4)?_c('el-popover',{staticClass:"leading-[0]",attrs:{"placement":"top","trigger":"click","popper-class":"popover_darkColor liveBroadcast"}},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":'音量:' + item.soundControl * 100,"placement":"top","open-delay":500}},[_c('div',{staticClass:"soundControl centerBox border border-backgroundv2 bg-backgroundv2 flex"},[_c('div',{staticClass:"pos_r top-[1px] mr-2 flex-shrink-0",on:{"click":function($event){return _vm.forbiddenSound(item.deviceId)}}},[(!item.forbiddenSound)?_c('svg-icon',{attrs:{"svg-name":"sound-select"}}):_c('svg-icon',{attrs:{"svg-name":"sound"}})],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.soundControl),expression:"item.soundControl"}],staticClass:"flex-grow w-0",style:({
                                      background: `linear-gradient(to right, ${
                                        !item.forbiddenSound
                                          ? 'var(--theme)'
                                          : 'var(--dangerv2)'
                                      } ${_vm.getPercent(
                                        item.soundControl,
                                        1
                                      )}%, var(--background) ${_vm.getPercent(
                                        item.soundControl,
                                        1
                                      )}% `
                                    }),attrs:{"max":"1","min":"0","step":"0.1","type":"range"},domProps:{"value":(item.soundControl)},on:{"input":function($event){return _vm.setSoundControl(
                                        item.deviceId,
                                        item.soundControl
                                      )},"__r":function($event){return _vm.$set(item, "soundControl", $event.target.value)}}})])]),_c('div',{staticClass:"clickBox",attrs:{"slot":"reference"},slot:"reference"},[(item.forbiddenSound)?_c('svg-icon',{attrs:{"svg-name":"liveBroadcast-forbiddenSound-select","className":"svgBox !w-[26px] !h-[26px]"}}):_c('svg-icon',{attrs:{"svg-name":"liveBroadcast-forbiddenSound","className":"svgBox !w-[26px] !h-[26px]"}})],1)],1):(
                                operationItem.type === 12 &&
                                item.sendMsgSupport === 1
                              )?_c('div',{staticClass:"clickBox flex flex-col items-center",on:{"click":function($event){return _vm.issueMessage(item)}}},[_c('svg-icon',{attrs:{"svg-name":"liveBroadcast_messagev2","className":"svgBox !w-[26px] !h-[26px]"}})],1):(
                                operationItem.type === 10 &&
                                item.broadcastSupport === 1
                              )?_c('div',{staticClass:"flex flex-col items-center"},[(!item.broadcastType)?_c('div',{staticClass:"clickBox flex flex-col items-center",attrs:{"slot":"reference"},slot:"reference"},[(item.broadcastLoading)?_c('div',{staticClass:"w-[26px] h-[26px] rounded-full text-theme bg-[rgba(0,0,0,0.4)] cursor-not-allowed absolute overflow-hidden centerBox"},[_c('i',{staticClass:"el-icon-loading"})]):_vm._e(),_c('div',{staticClass:"flex items-center justify-center",on:{"click":function($event){return _vm.broadcastSupport(item)}}},[_c('svg-icon',{attrs:{"svg-name":item.broadcastType
                                        ? 'liveBroadcast_broadcast_select'
                                        : 'liveBroadcast_broadcast',"className":"svgBox !w-[26px] !h-[26px]"}})],1)]):_c('el-popover',{attrs:{"placement":"top","trigger":"click","popper-class":"popover_darkColor liveBroadcast"},on:{"show":function($event){return _vm.setChannelPpover(
                                    `channelSelect_broadcast_${item.deviceId}`,
                                    true
                                  )},"hide":function($event){return _vm.setChannelPpover(
                                    `channelSelect_broadcast_${item.deviceId}`,
                                    false
                                  )}}},[_c('div',{staticClass:"w-[180px]"},[(
                                      _vm.channelPpover ===
                                      `channelSelect_broadcast_${item.deviceId}`
                                    )?_c('channelSelect',{key:`channelSelect_broadcast_${item.deviceId}`,attrs:{"channelType":"3","title":"广播通道","channelId":item.channelId,"channelList":item.channelList || [],"deviceId":item.deviceId},on:{"on-filterByDevice":_vm.broadcast_setItemChanne,"on-select":_vm.broadcast_setSelectChanne}}):_vm._e(),_c('div',{staticClass:"border-border border-t p-2"},[_c('el-button',{staticClass:"button_delete w-full",attrs:{"size":"mini"},on:{"click":function($event){return _vm.broadcastSupport(item)}}},[_vm._v("关闭广播")])],1)],1),_c('div',{staticClass:"clickBox flex flex-col items-center",attrs:{"slot":"reference"},slot:"reference"},[(item.broadcastLoading)?_c('div',{staticClass:"w-[26px] h-[26px] rounded-full text-theme bg-[rgba(0,0,0,0.4)] cursor-not-allowed absolute overflow-hidden centerBox"},[_c('i',{staticClass:"el-icon-loading"})]):_vm._e(),_c('div',[_c('svg-icon',{attrs:{"svg-name":"liveBroadcast_broadcast_select","className":"svgBox !w-[26px] !h-[26px]"}})],1)])])],1):_vm._e()],1)}),1)])])]):_vm._e()]):_c('div',{staticClass:"bg-backgroundv4 box-border border border-backgroundv2 !rounded w-full h-full flex justify-center items-center p-[20px]"},[_c('div',{staticClass:"bg-backgroundv2 w-full h-full box-border rounded hover:border-theme clickBox border border-border flex items-center justify-center",class:{ '!border-theme': _vm.emptySelect === index },on:{"click":function($event){return _vm.setPositionSelection(index)}}},[_c('svg-icon',{attrs:{"class-name":"!w-[60%] !h-[60%]","svg-name":"emptyVideo"}})],1)])])}),1)],1)],1)],1)])]):_vm._e(),_c('selectRangeDevice',{ref:"selectRangeDevice"}),_c('issueMessage',{ref:"issueMessage"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }