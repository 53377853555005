<!-- 每日考勤统计 -->
<template>
  <div class="h_100vh">
    <AppTable
      ref="AppTable"
      :defaultGetData="false"
      :tableColumns="tableColumns"
      controller="attendance/report/dailyCheckPush"
      :requestParams="requestParams"
    >
      <template #operator-left-box>
        <div class="">
          <slot name="unreadType"></slot>
        </div>
        <div v-if="false" class="flex_vertically_center">
          <el-date-picker
            v-model="queryDtm"
            @change="queryDtmFn"
            size="small"
            type="datetimerange"
            range-separator="-"
            value-format="yyyy-MM-dd HH:mm:ss"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
      </template>
      <template #unread="{ row }">
        <div v-if="row.hadRead === 0" class="unread"></div>
      </template>
      <!-- 自定义内容 -->
      <template #name="{ row }">
        {{ row.name }} <span class="fs_12 c_9DA7B2">(123456)</span>
      </template>
      <template #abnormal="{ row }">
        <span
          v-if="row.missNum"
          class="fs_12 fw_600"
          style="color: var(--dangerv2)"
        >
          <span>异常</span>
          <span class="prop-box missNum">{{ row.missNum }}</span>
        </span>
        <span v-else class="fs_12 fw_600 c_9DA7B2">
          <span>异常</span>
          <span class="prop-box noData">0</span>
        </span>
      </template>
      <template #userNum="{ row }">
        <span v-if="row.userNum">{{ row.userNum || 0 }}人</span>
        <span v-else class="c_9DA7B2">--</span>
      </template>
      <template #operator="{ row }">
        <iconBox
          imgName="see"
          altText="明细"
          @click="dailyCheckDayDetailsShow(row)"
        ></iconBox>
      </template>
    </AppTable>
    <dailyCheckDayDetails ref="dailyCheckDayDetails"></dailyCheckDayDetails>
  </div>
</template>
<script>
const dailyCheckDayDetails = () =>
  import('@components/checkReport/dailyCheckDayDetails')
export default {
  props: {
    unreadType: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  data() {
    return {
      today: null,
      queryDtm: null,
      requestParams: {
        orgId: null,
        queryStartDtm: null,
        queryEndDtm: null
      },
      tableColumns: [
        {
          label: '考勤日期',
          prop: 'checkDay'
        },
        {
          label: '考勤组',
          isSearch: true,
          prop: 'dailyCheckName'
        },
        {
          label: '考勤组人数',
          customSlot: 'userNum',
          type: 'customSlot'
        },
        {
          label: '考勤异常',
          customSlot: 'abnormal',
          type: 'customSlot',
          align: 'center'
        },
        {
          label: '操作',
          customSlot: 'operator',
          type: 'customSlot',
          width: '140px'
        }
      ],
      dailyCheckList: []
    }
  },
  components: {
    dailyCheckDayDetails
  },
  mounted() {
    if (this.unreadType) {
      this.tableColumns.push({
        label: null,
        width: '30px',
        customSlot: 'unread',
        type: 'customSlot'
      })
    }
    this.currentMonthFn()
    this.today = this.formatTimeFn(new Date(), 'Y-M-h D:m:s')
    this.dailyCheckFindAll()
  },
  methods: {
    /* 导出报表 */
    exportReport() {
      if (!this.queryDtm) {
        this.$message.error('请选择时间')
        return
      }
      if (!this.requestParams.orgId) {
        this.$message.error('请选择部门')
        return
      }
      const today = this.today
      let parameter = ''
      for (const k in this.requestParams) {
        if (this.requestParams[k]) {
          parameter += `${k}=${this.requestParams[k]}&`
        }
      }
      if (parameter && parameter.length) {
        parameter = parameter.substring(parameter.length - 1, 0)
      }
      this.$api.exportExcel
        .exportDailyCheckReport(
          `/attendance/exportExcel/exportDailyCheckReport?${parameter}`
        )
        .then((res) => {
          const blob = res
          const reader = new FileReader()
          reader.readAsDataURL(blob)
          reader.onload = function (ev) {
            if (window.navigator.msSaveOrOpenBlob) {
              // 兼容ie11
              const blobObject = new Blob([blob])
              window.navigator.msSaveOrOpenBlob(
                blobObject,
                `报表明细_${today}.xlsx`
              )
            } else {
              const url = URL.createObjectURL(new Blob([blob]))
              const a = document.createElement('a')
              document.body.appendChild(a) // 此处增加了将创建的添加到body当中
              a.href = url
              a.download = `报表明细_${today}.xlsx`
              a.target = '_blank'
              a.click()
              a.remove() // 将a标签移除
            }
          }
        })
    },
    /* 获取考情组数据 */
    dailyCheckFindAll() {
      this.$api.findAll('dailyCheck').then((res) => {
        if (res.code === 200) {
          this.dailyCheckList = res.data
        }
      })
    },
    dailyCheckDayDetailsShow(row) {
      this.$set(row, 'hadRead', 1)
      this.$emit('on-readFn', row)
      this.$refs.dailyCheckDayDetails.show(row.dailyCheckId, row.checkDay)
    },
    weekFn(index) {
      switch (index) {
        case 1:
          return '星期一'
        case 2:
          return '星期二'
        case 3:
          return '星期三'
        case 4:
          return '星期四'
        case 5:
          return '星期五'
        case 6:
          return '星期六'
        case 7:
          return '星期日'
      }
    },
    /* 今天 */
    currentMonthFn() {
      this.queryDtm = this.formatTimeFn(new Date(), 'Y-M-h')
      this.$nextTick(() => {
        this.appTableGetData()
      })
    },
    queryDtmFn() {
      if (this.queryDtm) {
        this.$set(this.requestParams, 'queryStartDtm', this.queryDtm[0])
        this.$set(this.requestParams, 'queryEndDtm', this.queryDtm[1])
      } else {
        this.$set(this.requestParams, 'queryStartDtm', null)
        this.$set(this.requestParams, 'queryEndDtm', null)
      }
      this.$nextTick(() => {
        this.appTableGetData()
      })
    },
    appTableGetData() {
      this.$refs.AppTable.getData()
    },
    visibleChange(e, row, name) {
      this.$set(row, name, e)
    },
    getData() {
      this.$refs.AppTable.getData()
    },
    orgCascaderOnChange(value) {
      this.requestParams.orgId = value[value.length - 1]
      this.getData()
    }
  }
}
</script>
<style scoped lang="less" rel="stylesheet/less">
.prop-box {
  min-width: 24px;
  padding: 4px;
  line-height: initial;
  box-sizing: border-box;
  display: inline-block;
  background: rgba(246, 109, 106, 0.2);
  text-align: center;
  border-radius: 4px;
  border: 1px solid var(--dangerv2);
  font-size: 12px;
  font-weight: bold;
  color: var(--main);
  margin-left: 6px;
}
/* checkNum 考情 正常 */
/* restNum 休息  */
/* lateNum 迟到  */
/* leaveEarlyNum 早退  */
/* missNum 缺勤 错误 */
.checkNum {
  background: rgba(45, 255, 165, 0.2);
  border-color: var(--success);
}
.restNum {
  background: var(--info);
  border-color: var(--info);
}
.lateNum {
  background: var(--warning);
  border-color: var(--warning);
}
.leaveEarlyNum {
  background: var(--warning);
  border-color: var(--warning);
}
.missNum {
  background: var(--dangerv2);
  border-color: avr(--dangerv2);
}
.noData {
  color: var(--auxiliary);
  background: var(--backgroundv3);
  border-color: var(--backgroundv3);
}
.unread {
  content: '';
  width: 10px;
  height: 10px;
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 50%;
  background: var(--dangerv2);
  box-shadow: 0px 0px 6px 1px var(--dangerv2);
}
</style>
