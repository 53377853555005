import Axios from '@/http'
export default {
  /* 考勤报表-考勤明细 */
  checkDetail(params) {
    return new Promise((resolve, reject) => {
      Axios.post('attendance/dailyCheck/deviceUser/checkDetail', params, {
        isJSON: true
      }).then((res) => {
        resolve(res)
      })
    })
  },
  /* 用户考勤明细-考勤日期情况 */
  dailyCheckUserCheckDayStatus(params) {
    return new Promise((resolve, reject) => {
      Axios.post('attendance/dailyCheck/dailyCheckUserCheckDayStatus', params, {
        isJSON: true
      }).then((res) => {
        resolve(res)
      })
    })
  },
  // 用户考勤明细-x天的考勤明细
  dailyCheckUserCheckDayDetail(params) {
    return new Promise((resolve, reject) => {
      Axios.post(
        'attendance/dailyCheck/dailyCheckUserCheckDayDetail',
        params
      ).then((res) => {
        resolve(res)
      })
    })
  },
  /* 考勤组执行状态-修改 */
  updateState(params) {
    return new Promise((resolve, reject) => {
      Axios.post('attendance/dailyCheck/updateState', params, {
        isJSON: true
      }).then((res) => {
        resolve(res)
      })
    })
  },
  /* 考勤组执行状态-修改 */
  dailyCheckPushFindAll(dailyCheckId) {
    return new Promise((resolve, reject) => {
      Axios.post('attendance/dailyCheck/dailyCheckPush/findAll', {
        dailyCheckId
      }).then((res) => {
        resolve(res)
      })
    })
  },
  // 获取人员工号、班次名称、围栏名称
  getVariable(params) {
    return new Promise((resolve, reject) => {
      Axios.post('attendance/dailyCheck/getVariable', params, {
        isJSON: true
      }).then((res) => {
        resolve(res)
      })
    })
  },
  // 考勤组-批量添加(v2)
  insertBatch(list) {
    return new Promise((resolve, reject) => {
      Axios.post(
        'attendance/dailyCheck/v2/insertBatch',
        { list },
        { isJSON: true }
      ).then((res) => {
        resolve(res)
      })
    })
  },
  // 每日考勤统计-x天的明细
  dailyCheckDayDetail(params) {
    return new Promise((resolve, reject) => {
      Axios.post('attendance/dailyCheck/dailyCheckDayDetail', params).then(
        (res) => {
          resolve(res)
        }
      )
    })
  }
}
