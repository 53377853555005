/* 案件通知 */
import caseDeadline from '@components/cases/caseDeadline.vue'

export default {
  data() {
    return {}
  },
  components: {
    caseDeadline
  },
  methods: {
    /* =========== 案件通知 =========== */
    // 案件通知
    casesNotice() {
      const h = this.$createElement
      const callback = async (res) => {
        // data：id（通知id）、casesNo（案件编号）、content（通知内容）
        const { id, casesNo, content, type } = res || {}
        const onView = async () => {
          const { code, data } = await this.getCaseCasesUserNoticeSelect(id)
          if (code === 200) {
            this.viewCaseCasesUserNotice(data)
          }
        }
        this.$message({
          dangerouslyUseHTMLString: true,
          type: 'success',
          message: h(
            'div',
            {
              style: 'width: 300px;color: #67C23A;',
              // !w-[300px] !text-[#67C23A]
              class: 'flex text-xs justify-between'
            },
            [
              h(
                'div',
                {
                  class: 'flex-grow w-0'
                },
                `案件：${casesNo}; ${content}`
              ),
              h(
                'div',
                {
                  class: 'flex-shrink-0 ml-1 clickBox',
                  on: {
                    click: onView
                  }
                },
                [h('u', null, '前往查看 ')]
              )
            ]
          )
        })
        if (type === 3 && this.$refs.caseDeadline) {
          this.$refs.caseDeadline.show(id)
        }
      }
      this.$store.commit('addUserOperationCallback', {
        id: 'headerBox_casesNotice',
        operationName: 'casesNotice',
        callback
      })
    },
    // 获取案件详情
    getCaseCasesUserNoticeSelect(id) {
      return new Promise((resolve, reject) => {
        this.$api.select('case/casesUserNotice', id).then(resolve)
      })
    },
    // 查看案件详情
    viewCaseCasesUserNotice(row) {
      const { casesId, casesAuditId, casesType, type } = row
      let path = null
      if (type === 1) {
        if (casesType === 3) {
          path = '/caseCenter#/compul-measures'
        } else {
          path = '/caseCenter#/case-manage'
        }
      } else {
        path = '/caseCenter#/review-approval'
      }
      this.$nextTick(() => {
        const time = this.createUniqueId(1)
        this.$router.push(
          `${path}?casesId=${casesId}&casesAuditId=${casesAuditId}&time=${time}`
        )
      })
    }
    /* =========== 案件期限通知 =========== */
  }
}
