import operationLog from '@components/report/operationLog/operationLog.vue'
import sosQuery from '@components/report/sosQuery/sosQuery.vue'
import breakQuery from '@components/report/breakQuery/breakQuery.vue'
import storageQuery from '@components/report/storageQuery/storageQuery.vue'
import dailyCheckDay from '@components/report/dailyCheckDay/dailyCheckDay.vue'
import gasCaseQuery from '@components/report/gasCase/gasCaseQuery.vue'
import casesQuery from '@components/report/casesQuery/casesQuery.vue'
import elDragDialog from '@components/el-drag-dialog'
export default {
  directives: { elDragDialog },
  data() {
    return {
      dragLevel: 0,
      sectionSelect: {},
      form: {},
      sectionList: [
        {
          type: 1,
          params: '',
          name: '系统消息',
          componentName: 'operationLog', // 组件名称
          permission: 'report_operationLog', // 权限
          svgName: 'news_systemMessages'
        },
        {
          type: 4,
          params: 'inviteMsg',
          permission: 'null',
          name: '邀请消息',
          componentName: '', // 组件名称
          svgName: 'news_invitationMessage'
        },
        {
          type: 5,
          params: 'dailyCheck',
          permission: 'dailyCheck_checkReport',
          name: '每日考勤',
          componentName: 'dailyCheckDay', // 组件名称
          svgName: 'news_dailyAttendance'
        },
        {
          type: 6,
          params: 'sos',
          name: 'SOS',
          remarks: '点击详细信息，地图可移动到对应位置',
          componentName: 'sosQuery', // 组件名称
          permission: 'report_sosQuery',
          svgName: 'news_sos'
        },
        {
          type: 7,
          params: 'rule',
          name: '违反规则',
          remarks: '点击详细信息，地图可移动到对应位置',
          componentName: 'breakQuery', // 组件名称
          permission: 'report_breakQuery',
          svgName: 'news_ruleViolation'
        },
        {
          type: 8,
          params: 'storage',
          name: '储存预警',
          remarks: '点击详细信息，地图可移动到对应位置',
          componentName: 'storageQuery', // 组件名称
          permission: 'report_storageQuery',
          svgName: 'news_storageAlert'
        },
        {
          type: 10,
          params: 'gasCase',
          name: '险情消息',
          componentName: 'gasCaseQuery', // 组件名称
          permission: 'dangerCenter',
          svgName: 'news_gasCase'
        },
        {
          type: 11,
          params: 'cases',
          name: '案件通知',
          componentName: 'casesQuery', // 组件名称
          permission: 'caseCenter',
          svgName: 'news_cases'
        }
      ],
      commandsType: false, // 有没有指挥中心权限
      drawer: false,
      dialogVisible: false
    }
  },
  components: {
    operationLog,
    sosQuery,
    breakQuery,
    storageQuery,
    dailyCheckDay,
    gasCaseQuery,
    casesQuery
  },
  computed: {},
  mounted() {
    /*  整理权限可访问的内容  */
    const list = []
    let permissionIdList = window.sessionStorage.getItem('permissionIdList')
    if (permissionIdList && permissionIdList.length) {
      permissionIdList = JSON.parse(permissionIdList)
      this.$nextTick(() => {
        this.commandsType =
          permissionIdList.findIndex((v) => v === 'commands') !== -1
        for (let i = 0; i < this.sectionList.length; i++) {
          const item = this.sectionList[i]
          if (item.permission) {
            const index = permissionIdList.findIndex(
              (v) => v === item.permission
            )
            if (index !== -1) {
              list.push(item)
            }
          } else {
            list.push(item)
          }
        }
        this.$nextTick(() => {
          this.setSectionList(list)
        })
      })
    }
    // this.show()
  },
  methods: {
    setSectionList(list) {
      this.sectionList = list
    },
    /* 未读消息数量统计 */
    notReadCount() {
      this.$api.msgRead.notReadCount().then((res) => {
        if (res.code === 200) {
          this.form = res.data || {}
          this.$nextTick(() => {
            let total = 0
            this.sectionList.forEach((item) => {
              const num = this.form[`${item.params}`] - 0
              if (num) {
                total += num
              }
            })
            this.$emit('on-msgNotReadTotalFn', total)
          })
        }
      })
    },
    /* 标记已读 */
    readFn(type, sourceId = null) {
      this.$api.msgRead.read({ type, sourceId }).then((res) => {
        /* 当是一键已读的时候，就需要主动刷新一下分页页面了 */
        if (res.code === 200) {
          this.notReadCount()
          if (!sourceId) {
            this.$message.success('操作成功')
            this.$refs[`${this.sectionSelect.componentName}`].appTableGetData()
          }
        }
      })
    },
    routerPush(name) {
      if (name) {
        this.$router.push(name)
      } else {
        this.$message.error('功能未开发')
      }
    },
    dailyCheckDayReadFn(row) {
      this.readFn(this.sectionSelect.type, row.id)
    },
    /* 存储查询 查看详细信息 */
    storageQueryInformationFn(row) {
      if (this.commandsType) {
        this.routerPush('/dispatching')
      } else {
        this.$message.error('当前账号无指挥管理权限，无法立即查看')
      }
      this.$nextTick(() => {
        this.$set(row, 'hadRead', 1)
        this.readFn(this.sectionSelect.type, row.id)
        this.$store.commit('mapPunctuationFn', {
          ...row,
          key: this.createUniqueId(1),
          punctuationType: 'storageQuery'
        })
        this.drawer = false
      })
    },
    /* 违反规则 查看详细信息 */
    breakQueryInformationFn(row) {
      if (this.commandsType) {
        this.routerPush('/dispatching')
      } else {
        this.$message.error('当前账号无指挥管理权限，无法立即查看')
      }
      this.$nextTick(() => {
        this.$set(row, 'hadRead', 1)
        this.readFn(this.sectionSelect.type, row.id)
        this.$store.commit('mapPunctuationFn', {
          ...row,
          key: this.createUniqueId(1),
          punctuationType: 'breakQuery'
        })
        this.drawer = false
      })
    },
    /* sos 查看详细信息 */
    sosInformationFn(row) {
      const json = {
        triggerSOS: true, // 是否触发sos校验
        viewSOS: true, // 是否需要查看sos
        emergencyOpening: false,
        progressKey: row.progressKey
      }
      if (this.commandsType) {
        this.routerPush('/dispatching')
      } else {
        this.$message.error('当前账号无指挥管理权限，无法立即查看')
      }
      this.$nextTick(() => {
        this.$set(row, 'hadRead', 1)
        this.readFn(this.sectionSelect.type, row.id)
        this.$store.commit('sosObjFn', json)
        this.drawer = false
      })
    },
    sectionSelectFn(item) {
      this.sectionSelect = item || {}
      this.drawer = true
    },
    drawerClose() {
      this.drawer = false
      this.onClose()
    },
    onClose() {
      this.dialogVisible = false
    },
    show() {
      this.notReadCount()
      this.setDialogVisible(true)
    },
    // 设置弹窗展示
    setDialogVisible(type) {
      // if (this.dialogVisible === type) return
      if (type) {
        this.setDragLevel()
      }
      this.dialogVisible = type
    },
    // 设置弹窗层级
    setDragLevel() {
      if (this.mainStore) {
        this.dragLevel = this.mainStore.getters.dragLevel
        this.mainStore.commit('setDragLevel')
      } else {
        this.dragLevel = this.$store.getters.dragLevel
        this.$store.commit('setDragLevel')
      }
    }
  },
  destroyed() {}
}
