var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full h-full box-border text-main rounded-[6px] p-2"},[(_vm.dataList.length !== 0)?_c('div',{staticClass:"w-full h-full"},[_c('div',{staticClass:"mb-2 flex items-center px-2 box-border"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('el-select',{attrs:{"size":"small","placeholder":"请选择通道"},on:{"change":_vm.channelSelect},model:{value:(_vm.selectedId),callback:function ($$v) {_vm.selectedId=$$v},expression:"selectedId"}},_vm._l((_vm.dataList),function(channeTtem){return _c('el-option',{key:channeTtem.id,attrs:{"label":channeTtem.channelName,"value":channeTtem.channelId,"disabled":channeTtem.onlineState !== 1}},[_c('el-tooltip',{directives:[{name:"delTabIndex",rawName:"v-delTabIndex"}],staticClass:"channeTtem",attrs:{"open-delay":500,"effect":"dark","content":channeTtem.onlineState === 1
              ? '点击切换通道'
              : '该通道已离线，不可切换',"placement":"right"}},[_c('div',{staticClass:"w-full flex css-text-no-select items-center",class:{
              '!text-theme': channeTtem.channelId === _vm.selectedId,
              'text-success': channeTtem.onlineState === 1,
              'text-info': channeTtem.onlineState !== 1
            }},[_c('span',{staticClass:"w-[10px] h-[10px] rounded-full inline-block mr-2 flex-shrink-0",class:{
                'bg-dangerv2': channeTtem.onlineState !== 1,
                'bg-success': channeTtem.onlineState === 1
              }}),_c('div',[_vm._v(" "+_vm._s(channeTtem.channelName)+" ")])])])],1)}),1)],1):_vm._e(),(_vm.dataList.length === 0)?_c('div',{staticClass:"w-full h-full flex items-center justify-center mt-2"},[_c('div',{staticClass:"flex flex-col items-center text-auxiliary"},[_c('svg-icon',{attrs:{"svg-name":"noData","className":"svgBox !w-[40px] !h-[40px]"}}),_c('span',{staticClass:"mt-4"},[_vm._v("暂 无 数 据")])],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }