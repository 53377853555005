import { mapGetters } from 'vuex'
import noPermissionsMap from './noPermissionsMap.js'
export default {
  mixins: [noPermissionsMap],
  data() {
    return {
      locList: [],
      polyEditor: null,
      mouseTool: null,
      map: null,
      id: null,
      rules: {
        railName: [
          { required: true, message: '请输入电子围栏名称', trigger: 'blur' }
        ]
      },
      form: {},
      valid: true,
      mapConfiguration: null,
      dialogVisible: false,
      startState: false,
      radius: 100,
      mouseToolName: 'fixedPoint',
      tips: null
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  components: {
    // mapAearchPlace
  },
  watch: {
    radius: function (val) {
      this.radius = val.replace(/[^\d]/g, '')
    }
  },
  mounted() {},
  methods: {
    throttle(fn, delay) {
      return (params) => {
        if (!this.valid) {
          return false
        }
        this.valid = false
        setTimeout(() => {
          fn(params)
          this.valid = true
        }, delay)
      }
    },
    /* 编辑 */
    editPoly() {
      this.polyEditor = new window.AMap.PolyEditor(
        this.map,
        this.map.getAllOverlays('polygon')[
          this.map.getAllOverlays('polygon').length - 1
        ]
      )
      this.polyEditor.open()
    },
    /* 清空 */
    clear() {
      if (this.map) {
        this.map.clearMap()
      }
    },
    tipsFn() {
      let tips = ''
      if (this.mouseToolName === 'fixedPoint') {
        tips = '鼠标在地图点击位置，松手结束'
      } else if (this.mouseToolName === 'circle') {
        tips = '鼠标在地图点击按住拖拽绘制，松手结束'
      } else {
        tips = '鼠标点击地图绘制，右键结束'
      }
      this.tips = tips
    },
    /* 开始绘画 */
    drawPolygon() {
      if (this.mouseToolName === 'fixedPoint') {
        if (this.radius) {
          this.clear()
          this.$message.success(this.tips)
          this.startState = true
        } else {
          this.$message.error('请输入定点范围')
        }
      } else {
        this.clear()
        this.$message.success(this.tips)
        this.startState = true
        this.mouseTool[`${this.mouseToolName}`]({})
      }
    },
    /* 停止绘画 */
    closeDraw() {
      this.startState = false
      if (this.mouseToolName !== 'fixedPoint') {
        this.mouseTool.close(false)
      }
    },
    initMap() {
      if (this.noPermissionsMapType) return
      if (this.mainStore && this.mainStore.getters) {
        this.mapConfiguration = this.mainStore.getters.mapConfiguration
      } else {
        this.mapConfiguration = this.$store.getters.mapConfiguration
      }
      if (!this.mapConfiguration) {
        return
      }
      let center = [] // 中心点坐标
      let curMapZoom = 13
      if (this.userInfo.lng && this.userInfo.lat) {
        center = [this.userInfo.lng, this.userInfo.lat]
      }
      if (center.length === 0) {
        center = null
        curMapZoom = 10
      }
      this.map = new window.AMap.Map(this.$refs.addElectronicFenceContainer, {
        mapStyle: this.mapConfiguration.defaultMapStyle,
        layers: [],
        pitch: 0,
        zooms: [8, 30], // 设置缩放级别范围 3-20 级
        zoom: curMapZoom,
        center: center, // 中心点坐标
        resizeEnable: true
      })
      this.map.on('complete', () => {
        this.mouseTool = new window.AMap.MouseTool(this.map)
        if (this.form.pointList && this.form.pointList.length) {
          const path = []
          this.form.pointList.forEach((val) => {
            path.push([val.lng, val.lat])
          })
          const poly = new window.AMap.Polygon({
            path,
            strokeColor: '#2DF2FF',
            strokeWeight: 3,
            strokeOpacity: 1,
            fillOpacity: 0.5,
            fillColor: '#2DF2FF',
            zIndex: 50
          })
          this.map.add(poly)
          this.editPoly()
          this.map.setFitView()
        }
        this.map.on('click', (res) => {
          if (this.mouseToolName === 'fixedPoint' && this.startState) {
            const { lng, lat } = res.lnglat
            const circle = new window.AMap.Circle({
              center: new window.AMap.LngLat(lng, lat), // 圆心位置
              radius: this.radius / 2, // 半径
              strokeColor: '#FF33FF', // 线颜色
              strokeOpacity: 0.7, // 线透明度
              strokeWeight: 3, // 线宽
              fillColor: '#1791fc', // 填充色
              fillOpacity: 0.5 // 填充透明度
            })
            circle.setMap(this.map)
            this.getAllOverlays('circle')
          }
        })
        window.AMap.event.addListener(this.mouseTool, 'draw', (e) => {
          this.getAllOverlays()
        })
      })
    },
    /* 获取数据 || 在页面上重绘 */
    getAllOverlays(mouseToolName, type) {
      mouseToolName = mouseToolName || this.mouseToolName
      const list = this.map.getAllOverlays(mouseToolName) || []
      if (!list.length) return []
      const path = list.length ? list[list.length - 1].getPath() : []
      if (type === 2) {
        return path
      } else {
        this.clear()
        const poly = new window.AMap.Polygon({
          path,
          strokeColor: '#2DF2FF',
          strokeWeight: 3,
          strokeOpacity: 1,
          fillOpacity: 0.5,
          fillColor: '#2DF2FF',
          zIndex: 50
        })
        this.map.add(poly)
        this.map.setFitView()
        this.closeDraw()
        this.editPoly()
      }
    },
    mapRailSelectFn(id) {
      this.$api.select('mapRail', id).then((res) => {
        if (res.code === 200) {
          this.form = { ...res.data }
          this.dialogVisible = true
          this.$nextTick(() => {
            this.initMap()
          })
        }
      })
    },
    show(id) {
      this.form = {}
      this.startState = false
      this.radius = 100
      this.mouseToolName = 'fixedPoint'
      this.tipsFn()
      if (this.$refs.form) {
        this.$refs.form.resetFields()
      }
      if (id) {
        this.mapRailSelectFn(id)
      } else {
        this.dialogVisible = true
        this.$nextTick(() => {
          this.initMap()
        })
      }
    },
    onSubmit() {
      const path = this.getAllOverlays('polygon', 2)
      if (path.length === 0) {
        this.$message.error('请绘制电子围栏')
        return false
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          const json = {
            railName: this.form.railName
          }
          json.pointList = path.map((val) => {
            return { lng: val.lng, lat: val.lat, mapRailId: this.form.id || '' }
          })
          json.id = this.form.id || null
          this.$api.save('mapRail', json).then((res) => {
            if (res.code === 200) {
              this.$message.success('操作成功 ！')
              this.$emit('on-submit')
              this.clear()
              this.dialogVisible = false
            }
          })
        }
      })
    },
    onClose() {
      this.$emit('on-close')
      this.clear()
      this.dialogVisible = false
    }
  }
}
