import Axios from '@/http'
export default {
  /* 整个租户的部门树形列表（下属部门、直属群组、直属成员、直属设备） */
  topOrgTree(params) {
    return new Promise((resolve, reject) => {
      Axios.post('organization/topOrgTree', params).then((res) => {
        return resolve(res.data)
      })
    })
  },
  orgTreeWithGroupAndDevice(params) {
    return new Promise((resolve, reject) => {
      Axios.post('organization/orgTreeWithGroupAndDevice', params).then(
        (res) => {
          return resolve(res.data)
        }
      )
    })
  },
  orgTreeWithSimpleDevice(params) {
    return new Promise((resolve, reject) => {
      Axios.post('organization/orgTreeWithSimpleDevice', params).then((res) => {
        return resolve(res.data)
      })
    })
  },
  orgListWithDev3(params) {
    return new Promise((resolve, reject) => {
      Axios.post('organization/orgTree', params).then((res) => {
        return resolve(res.data)
      })
    })
  },
  orgTree(params) {
    return new Promise((resolve, reject) => {
      Axios.post('/organization/orgTree', params).then((res) => {
        resolve(res)
      })
    })
  },
  orgTreeSimple(params) {
    return new Promise((resolve, reject) => {
      Axios.post('/organization/orgTreeSimple', params).then((res) => {
        resolve(res)
      })
    })
  },
  /* 上级部门 */
  parentOrg(params) {
    return new Promise((resolve, reject) => {
      Axios.post('/organization/parentOrg', params, { isJSON: true }).then(
        (res) => {
          resolve(res)
        }
      )
    })
  },
  insertOrg(params) {
    return new Promise((resolve, reject) => {
      Axios.post('/organization/insertOrg', params, { isJSON: true }).then(
        (res) => {
          resolve(res)
        }
      )
    })
  },
  updateOrg(params) {
    return new Promise((resolve, reject) => {
      Axios.post('/organization/updateOrg', params, { isJSON: true }).then(
        (res) => {
          resolve(res)
        }
      )
    })
  },
  deleteOrg(params) {
    return new Promise((resolve, reject) => {
      Axios.post('/organization/deleteOrg', params).then((res) => {
        resolve(res)
      })
    })
  }
}
