<!-- 工单管理 -->
<template>
  <el-drawer
    ref="drawer"
    :append-to-body="true"
    :wrapperClosable="true"
    :destroy-on-close="true"
    :with-header="false"
    custom-class="drawer-class"
    :visible.sync="drawer"
    size="90%"
    direction="rtl"
  >
    <div class="drawer_header">
      <div class="r-f" style="line-height: 24px">工单管理</div>
      <div @click="onClose()" class="clickBox">
        <svg-icon className="!w-[34px] !h-[34px]" svg-name="signOut" />
      </div>
    </div>
    <div class="drawer_body">
      <div class="drawer_body_content c-f">
        <div class="mb_20">
          <el-tabs v-model="activeName" @tab-click="handleClick(activeName)">
            <el-tab-pane label="我提交的工单" name="send">
              <div class="childrenTabs">
                <el-tabs
                  v-model="dealState"
                  @tab-click="dealStateFn(dealState)"
                >
                  <el-tab-pane label="全部" name="0"></el-tab-pane>
                  <el-tab-pane label="待处理" name="1"></el-tab-pane>
                  <el-tab-pane label="已处理" name="2"></el-tab-pane>
                  <el-tab-pane label="已撤销" name="3"></el-tab-pane>
                </el-tabs>
              </div>
            </el-tab-pane>
            <el-tab-pane label="接收的工单" name="receive">
              <div class="childrenTabs">
                <el-tabs
                  v-model="dealState"
                  @tab-click="dealStateFn(dealState)"
                >
                  <el-tab-pane label="全部" name="0"></el-tab-pane>
                  <el-tab-pane label="待处理" name="1"></el-tab-pane>
                  <el-tab-pane label="已处理" name="2"></el-tab-pane>
                </el-tabs>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div
          v-show="activeName === 'send'"
          class="flex_vertically_center"
          style="justify-content: space-between"
        >
          <el-button
            @click="submitWorkOrderShow"
            size="mini"
            class="button_addTo"
          >
            <div class="centerBox">
              <svg-icon className="!w-[12px] !h-[12px]" svg-name="addV2" />
              <span class="ml-[6px]">提交工单</span>
            </div>
          </el-button>
          <el-button @click="getData()" size="mini" class="button_function">
            <svg-icon className="!w-[12px] !h-[12px]" svg-name="icon_refres" />
          </el-button>
        </div>
        <div class="h-0 flex-grow">
          <AppTable
            ref="AppTable"
            :defaultGetData="false"
            :controller="controller"
            :tableColumns="tableColumns"
            :requestParams="requestParams"
          >
            <template #content="{ row }">
              <span>{{ row.content }}</span>
            </template>
            <template #dealState="{ row }">
              <div class="lh_20">
                <div
                  v-if="row.dealState === 1"
                  class="r-f c_dangerv2 flex_vertically_center"
                >
                  <span
                    style="background: var(--dangerv2)"
                    class="r-f-i success-state"
                  ></span>
                  <span class="ml-[6px]">待处理</span>
                </div>
                <div
                  v-else-if="row.dealState === 2"
                  class="r-f c_success flex_vertically_center"
                >
                  <span
                    style="background: var(--success)"
                    class="r-f-i success-state"
                  ></span>
                  <span class="ml-[6px]">已处理</span>
                </div>
                <div
                  v-else-if="row.dealState === 3"
                  class="r-f c_auxiliary flex_vertically_center"
                >
                  <span
                    style="background: var(--auxiliary)"
                    class="r-f-i success-state"
                  ></span>
                  <span class="ml-[6px]">已撤销</span>
                </div>
              </div>
            </template>
            <template #operator="{ row }">
              <iconBox
                @click="operationWorkOrderShow(row.id)"
                imgName="see"
                altText="查 看"
              ></iconBox>
              <iconBox
                @click="cancelFn(row.id)"
                v-if="row.dealState === 1 && activeName === 'send'"
                imgName="icon_cancel"
                altText="撤 销"
              ></iconBox>
              <iconBox
                @click="operationWorkOrderShow(row.id, 2)"
                v-if="row.dealState === 1 && activeName === 'receive'"
                imgName="icon_handle"
                altText="处 理"
              ></iconBox>
            </template>
          </AppTable>
        </div>
      </div>
    </div>
    <!-- 提交工单 -->
    <submitWorkOrder
      @on-submit="getData"
      ref="submitWorkOrder"
    ></submitWorkOrder>
    <!-- 处理 || 查看工单 -->
    <operationWorkOrder
      @on-submit="getData"
      ref="operationWorkOrder"
    ></operationWorkOrder>
  </el-drawer>
</template>

<script>
const submitWorkOrder = () => import('@components/workOrder/submitWorkOrder')
const operationWorkOrder = () =>
  import('@components/workOrder/operationWorkOrder')
export default {
  data() {
    return {
      drawer: false,
      requestParams: {
        dealState: null
      },
      tableColumns: [],
      dealState: '0',
      activeName: null,
      controller: null
    }
  },
  components: {
    submitWorkOrder,
    operationWorkOrder
  },
  mounted() {},
  methods: {
    /* 处理 || 查看工单 */
    operationWorkOrderShow(id, type) {
      this.$refs.operationWorkOrder.show(id, type)
    },
    /* 撤销 */
    cancelFn(id) {
      this.$deleteConfirmationBox(
        {
          deleteTitle: '撤销工单',
          deleteContent:
            '<div><p>撤销工单后，无法还原并且工单<span class="c_dangerv2">处理人不可见</span></p><p>是否确认撤销？</p></div>',
          submitText: '确 定'
        },
        () => {
          this.$api.workOrder.cancel(id).then((res) => {
            if (res.code === 200) {
              this.$nextTick(() => {
                this.getData()
              })
            }
          })
        }
      )
    },
    /* 提交工单 */
    submitWorkOrderShow() {
      this.$refs.submitWorkOrder.show()
    },
    /* 状态 */
    dealStateFn(dealState) {
      this.dealState = dealState
      this.$set(
        this.requestParams,
        'dealState',
        dealState - 0 ? dealState : null
      )
      this.$nextTick(() => {
        this.getData()
      })
    },
    /* 导航 */
    handleClick(activeName) {
      return new Promise((resolve, reject) => {
        this.activeName = activeName
        if (activeName === 'send') {
          this.controller = 'workOrder/send'
          this.tableColumns = [
            {
              label: '内容',
              overflowTooltip: true,
              type: 'customSlot',
              customSlot: 'content'
            },
            {
              label: '时间',
              prop: 'sendDtm'
            },
            {
              label: '状态',
              type: 'customSlot',
              customSlot: 'dealState'
            },
            {
              label: '操作',
              type: 'customSlot',
              customSlot: 'operator',
              width: '150px'
            }
          ]
        } else if (activeName === 'receive') {
          this.controller = 'workOrder/receive'
          this.tableColumns = [
            {
              label: '内容',
              overflowTooltip: true,
              type: 'customSlot',
              customSlot: 'content'
            },
            {
              label: '时间',
              prop: 'sendDtm'
            },
            {
              label: '部门',
              prop: 'orgName'
            },
            {
              label: '提单人',
              prop: 'name'
            },
            {
              label: '状态',
              type: 'customSlot',
              customSlot: 'dealState'
            },
            {
              label: '操作',
              type: 'customSlot',
              customSlot: 'operator',
              width: '150px'
            }
          ]
        }
        this.dealStateFn('0')
        this.$nextTick(() => {
          resolve()
        })
      })
    },
    show() {
      this.handleClick('send').then(() => {
        this.drawer = true
        this.$nextTick(() => {
          this.getData()
        })
      })
    },
    onClose() {
      this.drawer = false
    },
    getData() {
      if (this.$refs.AppTable) {
        this.$refs.AppTable.getData()
      }
    }
  }
}
</script>
<style scoped lang="less" rel="stylesheet/less">
.success-state {
  width: 12px;
  height: 12px;
  border-radius: 450px;
  background: var(--success);
}
.device-state {
  margin-left: 8px;
  line-height: 24px;
  font-size: 12px;
  text-align: center;
}
</style>
