<!-- 的页面 -->
<template>
  <div>
    <div v-show="headerState" class="headBox">
      <div class="left box">
        <ul class="navigation">
          <li
            v-for="(item, index) in menuListLeft"
            :key="index + 'menuListLeft'"
            :id="item.permission"
            :class="{ navigationItemSelect: pathFn(item.path) }"
            @click="routerPush(item.path)"
            class="centerBox clickBox text-auxiliary"
          >
            <div class="imgPictureChange">
              <svg-icon svg-name="headItem_select" className="svgBox" />
              <svg-icon svg-name="headItem" className="svgBox" />
            </div>
            <span>{{ item.label }}</span>
          </li>
        </ul>
      </div>
      <div class="centerBox c_fff headTitle_box">
        <svg-icon
          svg-name="headTitle_left"
          className="headTitle_svg -left-[12rem]"
        />
        <!--        :class="businessSystemSetting.systemName.length > 10 ? 'title_fontsize_20' : 'title_fontsize_10'"-->
        <div
          class="headTitle_title centerBox"
          :style="{
            'font-size': businessSystemSetting.systemNameLabelSize || ''
          }"
          v-if="businessSystemSetting && businessSystemSetting.systemName"
        >
          <span>{{ businessSystemSetting.systemName }}</span>
        </div>
        <!--        :class="headConfigure.title.length > 10 ? 'title_fontsize_20' : 'title_fontsize_10'"-->
        <div
          v-else
          :style="{
            'font-size': businessSystemSetting.systemNameLabelSize || ''
          }"
          class="headTitle_title centerBox"
        >
          <span>{{ headConfigure.title }}</span>
        </div>
        <svg-icon
          svg-name="headTitle_right"
          className="headTitle_svg -right-[12rem]"
        />
      </div>
      <div class="right box">
        <ul class="navigation relative mr-2">
          <li
            v-for="(item, index) in menuListRight"
            :key="index + 'menuListRight'"
            :id="item.permission"
            :class="{ navigationItemSelect: pathFn(item.path) }"
            @click="routerPush(item.path)"
            class="centerBox clickBox text-auxiliary"
          >
            <div class="imgPictureChange">
              <svg-icon svg-name="headItem_select" className="svgBox" />
              <svg-icon svg-name="headItem" className="svgBox" />
            </div>
            <span>{{ item.label }}</span>
          </li>
          <li
            v-if="moreMenuList.length"
            :class="{ navigationItemSelect: pathFn(moreMenuSelectItem.path) }"
            @click="routerPush(moreMenuSelectItem.path)"
            class="centerBox clickBox text-auxiliary"
          >
            <div class="imgPictureChange">
              <svg-icon svg-name="headItem_select" className="svgBox" />
              <svg-icon svg-name="headItem" className="svgBox" />
            </div>
            <span>{{ moreMenuSelectItem.label }}</span>
          </li>
          <!-- 更多下拉筛选 -->
          <div
            v-if="moreMenuList.length"
            contenteditable="true"
            @click="moreMenuVisibleShow"
            @blur="moreMenuVisibleClose"
            style="outline: none"
            class="w-[24px] h-[26px] !absolute -right-1 bottom-[6px] z-10 clickBox moreMenuItem"
          >
            <svg-icon
              svg-name="headMore"
              className="svgBox !w-[100%] !h-[100%]"
            />
          </div>
          <!-- 更多导航选择 -->
          <ul
            :class="{ '!border-0 !h-[0]': !moreMenuVisible }"
            class="absolute right-0 top-[46px] border duration-500 overflow-hidden z-[100] flex border-border rounded overflow-hidden h-[34px] text-sm text-auxiliary"
          >
            <li
              v-for="(item, index) in moreMenuList"
              :key="index + '_moreMenuList'"
              @click="setMoreMenuSelectItem(item, true)"
              :class="{
                '!bg-backgroundv2 shadow-theme text-main font-bold':
                  moreMenuSelectItem.label === item.label,
                '!border-r-0': index === moreMenuList.length - 1
              }"
              class="hover:bg-backgroundv2 hover:shadow-theme clickBox w-[80px] h-full box-border border-r border-border centerBox bg-background shadow-backgroundv205 shadow-[0_0_6px_0_inset] h-[34px]"
            >
              <!--bg-backgroundv205-->
              {{ item.label }}
            </li>
          </ul>
        </ul>
        <div class="personalCenter r-f" style="align-items: center">
          <!-- 消 息 -->
          <el-tooltip effect="dark" content="消 息" placement="bottom">
            <div id="guide_news" class="newNewsBox" @click="reportPopupShow">
              <div class="imgPictureChange clickBox hoverBox">
                <svg-icon svg-name="news_select" class-name="svgBox" />
                <svg-icon svg-name="news" class-name="svgBox" />
              </div>
              <span class="newNews css-text-no-select" v-if="msgNotReadTotal">{{
                msgNotReadTotal > 99 ? '99+' : msgNotReadTotal
              }}</span>
            </div>
          </el-tooltip>
          <!-- 指令进度 -->
          <el-tooltip effect="dark" content="指令进度" placement="bottom">
            <div
              id="guide_instructions"
              class="newNewsBox"
              @click="instructionProgressShow"
            >
              <div class="imgPictureChange clickBox hoverBox">
                <svg-icon
                  svg-name="instructionProgress_select"
                  class-name="svgBox"
                />
                <svg-icon svg-name="instructionProgress" class-name="svgBox" />
              </div>
              <span class="newNews css-text-no-select" v-if="haveInHandNum">{{
                haveInHandNum > 99 ? '99+' : haveInHandNum
              }}</span>
            </div>
          </el-tooltip>
          <!-- 文件进度 -->
          <el-tooltip effect="dark" content="文件进度" placement="bottom">
            <div
              id="guide_fileProgress"
              class="newNewsBox"
              @click="fileUploadShow"
            >
              <svg-icon
                svg-name="fileUpload"
                class-name="svgBox fileProgress"
              />
              <span class="newNews css-text-no-select" v-if="fileUploadNum">{{
                fileUploadNum > 99 ? '99+' : fileUploadNum
              }}</span>
            </div>
          </el-tooltip>
          <el-tooltip
            v-if="fullscreen"
            effect="dark"
            content="取消全屏"
            placement="bottom"
          >
            <div
              id="guide_fullScreen"
              class="mr_10 personnel_headPortrait imgPictureChange"
              @click="handleFullScreen"
            >
              <svg-icon
                svg-name="header_smallScreen-select"
                :class-name="'svgBox'"
              />
              <svg-icon svg-name="header_smallScreen" :class-name="'svgBox'" />
            </div>
          </el-tooltip>
          <el-tooltip v-else effect="dark" content="全屏" placement="bottom">
            <div
              id="guide_fullScreen"
              class="mr_10 personnel_headPortrait imgPictureChange"
              @click="handleFullScreen"
            >
              <svg-icon
                svg-name="header_fullScreen-select"
                :class-name="'svgBox'"
              />
              <svg-icon svg-name="header_fullScreen" :class-name="'svgBox'" />
            </div>
          </el-tooltip>
          <!-- 用户信息 -->
          <el-popover
            placement="bottom"
            popper-class="popover_clsss w-[200px]"
            trigger="click"
          >
            <div class="personalCenterBox" v-if="userInfo">
              <div class="r-f ptb_10" style="align-items: center">
                <div class="personnel_headPortrait" v-if="userInfo.headImg">
                  <img :src="userInfo.headImg" alt="" />
                </div>
                <svg-icon
                  v-else
                  svg-name="userAvatar"
                  class-name="headPortrait"
                />
                <div class="fs_12 ml_10">
                  <p class="personal_name">{{ userInfo.name }}</p>
                  <p class="personal_department">{{ userInfo.orgName }}</p>
                </div>
              </div>
              <div class="separator"></div>
              <!-- 用户信息 -->
              <ul class="information">
                <li>
                  <svg-icon svg-name="header_telephone" class-name="svgBox" />
                  <p>{{ userInfo.phone }}</p>
                </li>
                <li v-if="userInfo.jobNum">
                  <svg-icon svg-name="header_jobNumber" class-name="svgBox" />
                  <p>{{ userInfo.jobNum }}</p>
                </li>
                <li v-if="userInfo.serialNum">
                  <svg-icon svg-name="header_cornet" class-name="svgBox" />
                  <p>{{ userInfo.serialNum }}</p>
                </li>
              </ul>
              <div class="separator"></div>
              <!-- 用户操作 -->
              <div v-permission="'workOrder'">
                <ul class="information">
                  <li class="clickBox" @click="workOrderShow">
                    <svg-icon
                      svg-name="menu-workOrder"
                      class-name="svgBox c_theme"
                    />
                    <p>工单管理</p>
                  </li>
                </ul>
                <div class="separator"></div>
              </div>
              <div class="operation css-text-no-select">
                <div
                  class="cursor_pointer clickBox password"
                  @click="updatePasswordShow"
                >
                  修改密码
                </div>
                <div
                  class="cursor_pointer clickBox logOut"
                  @click="methodLogout"
                >
                  <svg-icon
                    svg-name="header_logout-select"
                    class-name="svgBox"
                  />
                  退出登录
                </div>
              </div>
            </div>
            <div slot="reference" class="centerBox" id="guide_userInformation">
              <div class="personnel_headPortrait" v-if="userInfo.headImg">
                <img :src="userInfo.headImg" alt="" />
              </div>
              <svg-icon
                v-else
                svg-name="userAvatar"
                class-name="headPortrait"
              />
              <i class="el-icon-arrow-down"></i>
            </div>
          </el-popover>
        </div>
      </div>
    </div>
    <!-- 指令进度 -->
    <instructionProgress ref="instructionProgress"></instructionProgress>
    <!-- 新消息 -->
    <reportPopup
      @on-msgNotReadTotalFn="msgNotReadTotalFn"
      ref="reportPopup"
    ></reportPopup>
    <!-- 修改密码 -->
    <el-dialog
      :visible.sync="visible"
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      class="dialog_clsss"
      custom-class="w-[640px]"
    >
      <div class="c_fff">
        <div class="pos_r dialog_header el-dialog__header">
          <span>{{ $t('general.updatePassword') }}</span>
          <div class="imgPictureChange clickBox closeBox" @click="close">
            <svg-icon
              :svg-name="'delete_hover'"
              class-name="!w-[26px] !h-[26px]"
            />
            <svg-icon :svg-name="'close'" class-name="!w-[26px] !h-[26px]" />
          </div>
        </div>
        <div class="dialog_body el-dialog__body">
          <div>
            <el-form :model="form" ref="form">
              <el-form-item :label="`${$t('general.oldPassword')}: `">
                <el-input
                  class="!w-[86%]"
                  type="password"
                  show-password
                  v-model="form.oldPsw"
                  :placeholder="$t('general.pleaseEnterOldPassword')"
                ></el-input>
              </el-form-item>
              <el-form-item :label="`${$t('general.newPassword')}: `">
                <el-input
                  class="!w-[86%]"
                  type="password"
                  show-password
                  v-model="form.newPsw"
                  :placeholder="$t('general.passwordConfirm')"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div class="pt_20 tac">
            <el-button size="small" class="button_default" @click="close">{{
              $t('general.close')
            }}</el-button>
            <el-button
              size="small"
              class="button_function"
              @click="updatePsw"
              >{{ $t('general.save') }}</el-button
            >
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 工单管理 -->
    <workOrder ref="workOrder"></workOrder>
  </div>
</template>

<script>
import header from './js/index.js'
import headerDispatch from './js/header_dispatch.js'
export default {
  mixins: [header, headerDispatch],
  data() {
    return {}
  },
  mounted() {},
  methods: {}
}
</script>
<style src="./css/index.less" scoped lang="less" rel="stylesheet/less"></style>
<style lang="less" rel="stylesheet/less"></style>
