import Axios from '@/http'

export default {
  /* 对比（详情） */
  detail(params) {
    return new Promise((resolve, reject) => {
      Axios.post('faceRecognition/detail', params).then((res) => {
        resolve(res)
      })
    })
  },
  compareDetailSelect(params) {
    return new Promise((resolve, reject) => {
      Axios.post('faceRecognition/compareDetail/select', params).then((res) => {
        resolve(res)
      })
    })
  }
}
