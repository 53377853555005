/* 人脸报警 */
const state = {
  faceRecognitionId: null,
  faceUnreadContentList: [], // 人脸未读内容
  vehicleRecognitionId: null,
  vehicleUnreadContentList: [] // 车辆未读内容
}
/* =================================== 获取消息（getters） =================================== */
const getters = {
  faceRecognitionId(state) {
    return state.faceRecognitionId
  },
  faceUnreadContentList(state) {
    return state.faceUnreadContentList
  },

  vehicleRecognitionId(state) {
    return state.vehicleRecognitionId
  },
  vehicleUnreadContentList(state) {
    return state.vehicleUnreadContentList
  }
}

const mutations = {
  setFaceRecognitionId(state, faceRecognitionId) {
    state.faceRecognitionId = faceRecognitionId
  },
  faceUnreadContentListOperate(state, params) {
    // const params = {
    //   type: {
    //     新增: 1,
    //     删除: 2,
    //     置空: 3,
    //   }, // 类型
    //   faceRecognitionId, // 人脸警报id
    // }
    const type = params.type
    const faceRecognitionId = params.faceRecognitionId
    let faceUnreadContentList = state.faceUnreadContentList || []
    if (type === 1) {
      faceUnreadContentList.push({ faceRecognitionId })
    } else if (type === 2) {
      const index = faceUnreadContentList.findIndex(
        (v) => v.faceRecognitionId === faceRecognitionId
      )
      if (index !== -1) {
        faceUnreadContentList.splice(index, 1)
      }
    } else if (type === 3) {
      faceUnreadContentList = []
    }
    state.faceUnreadContentList = [...faceUnreadContentList]
  },
  setVehicleRecognitionId(state, vehicleRecognitionId) {
    state.vehicleRecognitionId = vehicleRecognitionId
  },
  vehicleUnreadContentListOperate(state, params) {
    const type = params.type
    const vehicleRecognitionId = params.vehicleRecognitionId
    let vehicleUnreadContentList = state.vehicleUnreadContentList || []
    if (type === 1) {
      vehicleUnreadContentList.push({ vehicleRecognitionId })
    } else if (type === 2) {
      const index = vehicleUnreadContentList.findIndex(
        (v) => v.vehicleRecognitionId === vehicleRecognitionId
      )
      if (index !== -1) {
        vehicleUnreadContentList.splice(index, 1)
      }
    } else if (type === 3) {
      vehicleUnreadContentList = []
    }
    state.vehicleUnreadContentList = [...vehicleUnreadContentList]
  }
}

const actions = {}

export default {
  state,
  getters,
  mutations,
  actions
}
