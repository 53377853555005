<!-- 选择设备 -->
<template>
  <div class="flex flex-col w-full h-full">
    <div class="flex justify-between items-center h-[40px]">
      <div class="flex mr-2">所有设备</div>
      <div class="flex items-center">
        <el-button
          v-show="selectedDataList.length !== 0"
          @click="clearSelectedData"
          size="mini"
          class="button_delete mr-1 !p-[4px_14px]"
        >
          <span class="text-xs">清 空</span>
        </el-button>
        <div class="flex items-center clickBox">
          <span
            >已选中
            <span class="text-theme">{{ selectedDataList.length }}</span>
            个</span
          >
        </div>
      </div>
    </div>
    <div
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      class="flex-grow h-0 flex"
    >
      <div
        class="flex-1 bg-background rounded shadow-[0_0_10px_0_inset] shadow-backgroundv3 flex flex-col"
      >
        <div class="centerBox p-[8px_8px_4px]">
          <div class="search_lightColor flex min-w-full z-[3]">
            <el-input
              size="small"
              clearable
              class="flex-grow w-0"
              @clear="getData()"
              @keyup.enter.native="getData()"
              v-model="filterValue"
              placeholder="设备号、设备名称、部门名称进行筛选"
            ></el-input>
            <div class="searchBox flex-shrink-0" @click="getData()">
              <svg-icon svg-name="search" className="svgBox" />
            </div>
          </div>
        </div>
        <div class="flex-grow h-0 w-full equipment vbtTable">
          <vbt-table
            border
            stripe
            row-key="onlyId"
            isBigData
            @row-click="clickTable"
            ref="refTable"
            :indent="10"
            isTreeTable
            :show-header="false"
            highlight-hover-row
            :max-height="`${treeHeight}px`"
            v-if="treeHeight"
            :scrollYRenderConfig="scrollYRenderConfig"
            :row-class-name="tableRowClassName"
            :data="deviceList"
          >
            <vbt-table-column>
              <template slot-scope="{ row }">
                <div
                  style="flex-grow: 1; display: flex"
                  class="centerBox"
                  v-if="row.type === 3"
                  @click.stop="deviceClick(row)"
                >
                  {{ judgeSelectedFn(row) }}
                  <div>
                    <div class="el-checkbox">
                      <span
                        :class="{
                          'is-checked': row.selectType,
                          'is-disabled': row.disabled
                        }"
                        class="el-checkbox__input"
                      >
                        <span class="el-checkbox__inner"></span>
                      </span>
                    </div>
                  </div>
                  <div v-if="row.onlineState === 1" class="treeDeviceBox">
                    <div class="centerBox">
                      <svg-icon
                        :svg-name="
                          checkDeviceTypeFn(
                            row.deviceType || row.type,
                            row.onlineState
                          )
                        "
                        className="svgBox"
                      />
                      <span class="deviceName text-main">{{
                        row.devName
                      }}</span>
                    </div>
                    <span style="color: var(--success)" class="spanBox"
                      >在线</span
                    >
                  </div>
                  <div v-else class="treeDeviceBox" style="color: #606266">
                    <div class="centerBox">
                      <svg-icon
                        :svg-name="
                          checkDeviceTypeFn(
                            row.deviceType || row.type,
                            row.onlineState
                          )
                        "
                        className="svgBox"
                      />
                      <span class="deviceName" style="color: var(--info)">{{
                        row.devName
                      }}</span>
                    </div>
                    <span style="color: var(--info)" class="spanBox">离线</span>
                  </div>
                </div>
                <div
                  v-else
                  class="flex-grow flex"
                  @dblclick.stop="deviceClick(row)"
                >
                  <!-- 多选 -->
                  <div class="centerBox" @click.stop="deviceClick(row)">
                    <span
                      class="el-checkbox__input pos_r"
                      style="margin-right: 6px; top: 1px"
                      :class="judgeSelectedFn(row)"
                    >
                      <span class="el-checkbox__inner"></span>
                    </span>
                  </div>
                  <div class="treeDepartment cursor_pointer">
                    <div class="r-f w_100vh flex">
                      <span class="flex-grow w-0 over_one_line">{{
                        row.companyName || row.groupName || row.orgName
                      }}</span>
                      <span
                        v-show="row.onlineCountList.length"
                        class="flex-shrink-0 ml-1 text-theme"
                      >
                        ({{ row.onlineCountList.length }})
                      </span>
                    </div>
                  </div>
                </div>
              </template>
            </vbt-table-column>
          </vbt-table>
        </div>
      </div>
      <ul
        class="flex-1 bg-background !p-[10px] overflow-y-auto overflow-x-hidden box-border rounded shadow-[0_0_10px_0_inset] shadow-backgroundv3 flex flex-col ml-2"
      >
        <li
          v-show="selectedDataList.length === 0"
          class="h-full bg-backgroundv2 border border-border rounded px-2 box-border flex justify-center text-auxiliary items-center"
        >
          <div class="r-f tac block">
            <svg-icon
              svg-name="noData"
              className="svgBox !w-[50px] !h-[50px]"
            />
            <div class="mt-3">您还未选择设备</div>
          </div>
        </li>
        <li
          v-for="item in selectedDataList"
          :key="item.id + '_selectedDataList'"
          class="w-full flex hover:shadow-[0_0_10px_0_inset] items-center bg-backgroundv2 rounded mb-2 box-border clickBox border border-backgroundv2 hover:border-theme hover:shadow-theme"
        >
          <div class="flex-grow w-0 pl-2 flex box-border">
            <svg-icon
              :svg-name="
                checkDeviceTypeFn(
                  item.deviceType || item.type,
                  item.onlineState
                )
              "
              className="svgBox"
            />
            <div class="ml-1 over_one_line flex-grow w-0">
              <span class="deviceName text-main">{{ item.devName }}</span>
            </div>
          </div>
          <div class="flex-shrink-0">
            <iconBox
              margin="4px"
              :width="20"
              :height="20"
              @click="deviceClick(item)"
              classNmae="shadow_delete"
              imgName="delete"
              altText="删 除"
            ></iconBox>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import deviceTree from '@utils/device/deviceTree'
import deepmerge from 'deepmerge'
import { vbtTable, vbtTableColumn } from '@static/vbt-table.js'
export default {
  props: {
    onlineStateFlag: {
      type: Number,
      default: () => {
        return 1
      }
    },
    initSelectedDataList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  watch: {
    filterValue: function (val) {
      this.filterValue = val.replace(/\s/g, '')
    }
  },
  data() {
    return {
      loading: false,
      treeHeight: 400,
      scrollYRenderConfig: {
        renderSize: 30,
        offsetSize: 10
      },
      deviceList: [],
      selectedDataList: [],
      disableSelection: [],
      filterValue: '',
      time: 400,
      timeOut: null
    }
  },
  components: {
    vbtTable,
    vbtTableColumn
  },
  //computed: {},
  mounted() {
    this.setSelectedDataList(this.initSelectedDataList)
    this.getData()
  },
  //created(){ // document.title = this.type === 2 ? '设置手机号' : '绑定手机号' },
  methods: {
    clearSelectedData() {
      this.setSelectedDataList([])
      this.$emit('on-selectedDataList', this.selectedDataList)
    },
    // 添加选中设备
    addSelectedDataList(selectedDataList = []) {
      if (selectedDataList.length === 0) return
      selectedDataList.forEach((row) => {
        const index = this.selectedDataList.findIndex(
          (v) => v.deviceId === row.deviceId
        )
        if (index === -1) {
          this.selectedDataList.push(row)
        }
      })
      this.$nextTick(() => {
        this.checkAll = this.selectedDataList.length === this.deviceList.length
        this.$emit('on-selectedDataList', this.selectedDataList)
      })
    },
    // 设置选中数据
    setSelectedDataList(selectedDataList = []) {
      this.selectedDataList = [...selectedDataList]
    },
    // 获取选中数据
    getSelectedDataList() {
      return [...this.selectedDataList]
    },
    /* 全部选择和取消 */
    deviceClick(row) {
      if (row.disabled) return
      /* 多选 */
      if (row.type === 3) {
        const index = this.selectedDataList.findIndex(
          (v) => v.deviceId === row.deviceId
        )
        if (index === -1) {
          this.selectedDataList.push({ ...row })
        } else {
          this.selectedDataList.splice(index, 1)
        }
      } else {
        clearTimeout(this.timeOut)
        const deviceObjList = {
          nonExistent: [], // 不存在
          existent: [] // 存在
        }
        const childrenDeviceList = row.childrenDeviceList
        if (childrenDeviceList.length) {
          childrenDeviceList.forEach((v) => {
            /* 设备是否可选择 */
            const disableSelectionIndex = this.disableSelection.findIndex(
              (o) => o.deviceId === v.deviceId
            )
            if (disableSelectionIndex === -1) {
              const index = this.selectedDataList.findIndex(
                (i) => i.deviceId === v.deviceId
              )
              if (index === -1) {
                deviceObjList.nonExistent.push({ ...v })
              } else {
                deviceObjList.existent.push({ ...v })
              }
            }
          })
          if (deviceObjList.nonExistent.length) {
            deviceObjList.nonExistent.forEach((v) => {
              this.selectedDataList.push({ ...v })
            })
          } else {
            deviceObjList.existent.forEach((v) => {
              const index = this.selectedDataList.findIndex(
                (o) => o.deviceId === v.deviceId
              )
              this.selectedDataList.splice(index, 1)
            })
          }
        }
      }
      this.$emit('on-selectedDataList', [...this.selectedDataList])
    },
    /* 当个校验复选框状态 */
    judgeSelectedFn(row) {
      if (row.type === 3) {
        const type =
          this.selectedDataList.findIndex(
            (v) => v.deviceId === row.deviceId
          ) !== -1
        this.$set(row, 'selectType', type)
      } else {
        const childrenDeviceList = row.childrenDeviceList
        if (this.selectedDataList.length && childrenDeviceList.length) {
          const arr = this.selectedDataList.filter((v) => {
            const index = childrenDeviceList.findIndex(
              (o) => o.deviceId === v.deviceId
            )
            return index !== -1
          })
          if (arr.length === childrenDeviceList.length) {
            return 'is-checked'
          } else if (arr.length) {
            return 'is-indeterminate'
          } else {
            this.$set(row, 'srow', false)
            return ''
          }
        } else {
          return ''
        }
      }
    },
    clickTable(row) {
      if (row.type === 3) return
      if (this.timeOut) {
        clearTimeout(this.timeOut) // 清除第一个单击事件
      }
      this.timeOut = setTimeout(() => {
        this.$emit('on-department-click', row)
        if (this.$refs.refTable) {
          this.$refs.refTable.toggleRowExpansion(row)
        }
      }, this.time)
    },
    /* 是否禁止 */
    tableRowClassName({ row }) {
      if (row.type === 3) {
        if (row.disabled) {
          return 'disableSelection'
        }
        if (row.selectType) {
          return 'select_box'
        }
      }
    },
    // 获取数据
    getData() {
      this.loading = true
      this.$api.organization
        .orgTreeWithSimpleDevice({ spotCheckSupport: 1 })
        .then((res) => {
          if (this.filterValue) {
            res = deviceTree.filteringDepartment(
              this.filterValue,
              JSON.parse(JSON.stringify(res))
            )
          }
          this.$nextTick(() => {
            const json = deviceTree.handlerDevTree(this.filterValue, res)
            this.$nextTick(() => {
              if (this.onlineStateFlag === 1) {
                this.setDeviceList(this.dataListFilter(json.children))
              } else {
                this.setDeviceList(json.children)
              }
            })
          })
        })
    },
    // 数据列表过滤
    dataListFilter(list) {
      const filter = (dataList, type) => {
        const deviceList = []
        const children = []
        for (let i = 0; i < dataList.length; i++) {
          const item = { ...dataList[i] }
          if (item.type === 3) {
            if (item.onlineState === 1) {
              children.push({ ...item })
              deviceList.push({ ...item })
            }
          } else {
            if (
              (item.children && item.onlineCountList) ||
              item.onlineCountList.length !== 0 ||
              item.children.length !== 0
            ) {
              const res = filter(item.children, type)
              const num = res.children.length
              item.children = res.children
              item.childrenDeviceList = res.deviceList
              deviceList.push(...res.deviceList)
              if (type || num !== 0) {
                children.push({ ...item })
              }
            }
          }
        }
        return {
          children,
          deviceList
        }
      }
      return filter(list, false).children
    },
    // 设置 页面展示数据
    setDeviceList(deviceList) {
      const refTable = this.$refs.refTable
      this.deviceList = deviceList
      if (refTable) {
        refTable.store.commit('setData', deepmerge([], deviceList))
        if (refTable.$ready) {
          this.$nextTick(() => {
            refTable.doLayout()
            this.loading = false
          })
        }
      }
    }
  }
}
</script>
<style scoped lang="less" rel="stylesheet/less"></style>
