/**
 * Created by Deboy on 2016/8/17.
 */
/**
 * 全局HTTP拦截
 * @param request
 * @param next
 */
// 安装完axios之后引入，qs也是axios里面的
import axios from 'axios'
import Qs from 'qs'
import store from '../store/index.js'
// import store from '@store'
import { Notification } from 'element-ui'
import BaseUrlConfig from '@/http/config.js'

const service = axios.create({
  // baseURL, // url = base url + request url
  withCredentials: false, // send cookies when cross-domain requests
  timeout: 60000 // request timeout
})
service.defaults.headers = {
  'Content-Type': 'application/x-www-form-urlencoded'
}
// request interceptor
service.interceptors.request.use(
  (config) => {
    config.baseURL = config.baseURL || BaseUrlConfig.baseURLObj.admin

    const mcflag = window.sessionStorage.getItem('mcflag') || null
    if (mcflag && mcflag.length !== 0) {
      if (typeof config.data === 'object') {
        config.data.clientflag = mcflag
        config.data.mcflag = mcflag
      } else if (typeof config.data === 'undefined') {
        config.data = {
          clientflag: mcflag,
          mcflag: mcflag
        }
      }
    }

    // config.url.toUpperCase().indexOf('FILE') < 0 &&
    if (config.oosUpload) {
      config.headers = {
        'Content-Type': config.ContentType
      }
    } else if (config.isUpload) {
      config.headers = {
        'Content-Type': 'multipart/form-data;'
      }
    } else if (!config.otherUrl) {
      if (config.isJSON) {
        config.headers = {
          'Content-Type': 'application/json'
        }
      } else {
        // 清除无用参数
        for (const params in config.data) {
          if (
            config.data[params] === undefined ||
            config.data[params] === null ||
            config.data[params] === ''
          ) {
            delete config.data[params]
          }
        }
        config.data = Qs.stringify(config.data)
      }
    }
    if (store.getters.userToken) {
      // let each request carry token --['X-Token'] as a custom key.
      // please modify it according to the actual situation.
      config.headers.token = store.getters.userToken
    }
    if (config.xAmzAcl) {
      config.headers = {
        'Content-Type': config.fileSuffix,
        'x-amz-acl': 'public-read'
      }
    }
    if (config.headersObj) {
      config.headers = config.headersObj
    }
    return config
  },
  (error) => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      console.warn('response', response)
      const data = response.data
      const code = data && data.code
      if (code === 200 || code === 10020) {
        // 10020 固定的，文件已合拼
        return Promise.resolve(data)
      } else if (code === 444) {
        // store.commit('logout')
      } else if (response.status === 200 && !code) {
        if (
          response.config.url.indexOf('/exportExcel/') >= 0 ||
          response.config.url.indexOf('/mediaFile/') >= 0
        ) {
          return Promise.resolve(data)
        } else if (response.headers.etag) {
          return Promise.resolve(response.headers)
        } else {
          if (response.config) {
            const configDFata = response.config.data || ''
            if (
              typeof configDFata === 'string' &&
              configDFata.indexOf('notVerifiedCode=1') >= 0
            ) {
              return Promise.resolve(data)
            }
          }
          return Promise.resolve()
        }
      } else {
        Notification.error({
          title: '温馨提示 ~',
          message: data.msg
        })

        if (
          response.config.url.indexOf('common/deviceOrDeviceUserChangeOrg') >= 0
        ) {
          return Promise.resolve(response)
        } else {
          return Promise.reject(data)
        }
      }
    }
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default service

// const install = (Vue) => {
//   Axios.defaults.baseURL = (process.env.NODE_ENV !== 'production' ? '/api' : '') + '/client'
//   // -----------先设置默认接口是form表单提交形式
//   Axios.defaults.headers = {
//     'Content-Type': 'application/x-www-form-urlencoded'
//   }
//   // -----------设置接口最长请求时间，单位ms
//   Axios.defaults.timeout = 60000
//   // -----------有关请求request的过滤器
//   Axios.interceptors.request.use((request) => {
//     // 判断是否第三方接口
//     if (!request.isOther) {
//       request.baseURL = (process.env.NODE_ENV !== 'production' ? '/api' : '') + '/client'
//     }
//     return request
//   }, (error) => {
//     console.log('error', error)
//   })
//   // -----------有关响应response的过滤器
//   Axios.interceptors.response.use((response) => {
//     // -----------根据后台返回的不同code做出对应处理
//     switch (response.data.code) {
//       case 200:
//         // -----------返回正确信息，用resolve
//         return Promise.resolve(response.data)
//       case 444:
//         // 没有登录
//         break
//       default:
//         return Promise.resolve(response.data)
//     }
//   }, (error) => {
//     // -----------请求错误
//     if (JSON.stringify(error).indexOf('404') > 0) {
//     } else {
//     }
//     // -----------返回正确信息，用reject
//     return Promise.reject(error)
//   })
//   Vue.prototype.$http = Axios
// }
// export default install
