<!-- 填写消息 -->
<template>
  <!--input_darkColor-->
  <div class="h-full overflow-y-auto box-border px-[10px]">
    <div class="r-f flex w-full">
      <div class="pr_10 flex-grow w-0">
        <div class="mb_20">
          <div class="titleBox">
            填写文字
            <span> (不能超过100个字符且不能包含特殊字符 {{ limit }}) </span>
          </div>
          <el-input
            type="textarea"
            placeholder="请填写要发送的消息"
            v-model="form.content"
            maxlength="100"
            resize="none"
            :autosize="{ minRows: 4, maxRows: 6 }"
            show-word-limit
            @keyup.native="contentFn(form, 'content')"
          >
          </el-input>
        </div>
        <div class="mb_20">
          <!--          或一个视频-->
          <div class="titleBox">
            添加图片 <span>(最多9张图)</span>
            <el-tooltip
              class="item"
              effect="dark"
              content="此功能为带屏设备使用"
              placement="top"
            >
              <span class="!text-theme fs_12">
                <svg-icon
                  svg-name="help"
                  class-name="svgBox !w-[12px] !h-[12px] !ml-1"
                />
                帮助
              </span>
            </el-tooltip>
          </div>
          <ul class="fileListBox css-text-no-select">
            <li>
              <!--              ,.mp4,.m2v,.mkv,.rmvb,.wmv,.avi,.flv,.mov,.m4v-->
              <el-upload
                class="centerBox w-full h-full"
                accept=".png,.jpg,.gif,.jpeg"
                :before-upload="beforeAvatarUpload"
                :http-request="handleHttpRequestImg"
                action=""
                :show-file-list="false"
              >
                <svg-icon svg-name="uploadFile" class-name="uploadFile" />
              </el-upload>
            </li>
            <li v-for="(item, index) in imagesList" :key="index">
              <div class="imgBox">
                <div class="centerBox deleteBox">
                  <iconBox
                    classNmae="shadow_delete"
                    @click="deleteRow(1, index)"
                    imgName="delete"
                    altText="删 除"
                  ></iconBox>
                </div>
                <img :src="item" alt="" />
              </div>
            </li>
            <li v-for="(item, index) in videoList" :key="index">
              <div class="imgBox">
                <div class="centerBox deleteBox">
                  <iconBox
                    classNmae="shadow_delete"
                    @click="deleteRow(2, index)"
                    imgName="delete"
                    altText="删 除"
                  ></iconBox>
                </div>
                <div class="pos_a w_100vh centerBox">
                  <svg-icon
                    svg-name="videoCover"
                    class-name="!w-[40px] !h-[40px]"
                  />
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="mb_10">
          <el-checkbox v-model="form.addToTem"
            >发送的同时添加到固定列表</el-checkbox
          >
        </div>
        <div class="mb_20">
          <el-checkbox v-model="form.playMsg"
            >需在设备进行TTS语音播报</el-checkbox
          >
        </div>
        <div>
          <el-button
            :loading="loading"
            @click="submitFn(1)"
            size="small"
            class="button_default buttonBox"
            >添加固定</el-button
          >
          <el-button
            :loading="loading"
            @click="submitFn(2)"
            size="small"
            class="button_function buttonBox ml-[20px]"
            >发 送</el-button
          >
        </div>
      </div>
      <div class="w-[33%] flex-shrink-0 flex">
        <div class="flex flex-col w-full">
          <div
            class="h-[40px] leading-[40px] text-xs text-main flex items-center justify-between"
          >
            <div class="text-sm">附近警员</div>
            <div>
              已选中<span class="text-theme">{{ selectList.length }}</span
              >名
            </div>
          </div>
          <selectRangeDeviceItem
            :requestParams="{
              ...requestParams,
              // 是否支持下发消息
              sendMsgSupport: 1
            }"
            ref="selectRangeDeviceItem"
            @on-selectedDeviceList="onClickTree"
          ></selectRangeDeviceItem>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import addNews from '@components/issueMessage/addNews/js/index.js'
import selectRangeDeviceItem from '@components/device/selectRangeDeviceItem'
export default {
  props: {
    requestParams: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  mixins: [addNews],
  components: {
    selectRangeDeviceItem
  },
  data() {
    return {}
  },
  mounted() {},
  // 联动消息已发送
  methods: {}
}
</script>

<style
  src="@components/issueMessage/addNews/css/index.less"
  scoped
  lang="less"
  rel="stylesheet/less"
></style>
<style scoped lang="less" rel="stylesheet/less"></style>
