<!-- 选择电子围栏 -->
<template>
  <div class="flex flex-col w-full h-full">
    <div class="flex justify-between items-center h-[30px]">
      <span>电子围栏</span>
      <div @click="setCheckAll" class="flex items-center clickBox">
        <span
          :class="{ 'is-checked': checkAll }"
          class="el-checkbox__input mr-2"
        >
          <span class="el-checkbox__inner"></span>
        </span>
        <span
          >已选中
          <span class="text-theme">{{ selectedDataList.length }}</span>
          个</span
        >
      </div>
    </div>

    <div
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      class="flex-grow h-0 flex"
    >
      <div
        class="flex-1 bg-background rounded shadow-[0_0_10px_0_inset] shadow-backgroundv3 flex flex-col"
      >
        <div class="centerBox p-[8px_8px_4px]">
          <div class="search_lightColor flex min-w-full z-[3]">
            <el-input
              size="small"
              clearable
              class="flex-grow w-0"
              @clear="getData()"
              @keyup.enter.native="getData()"
              v-model="filterValue"
              placeholder="电子围栏名称进行筛选"
            ></el-input>
            <div class="searchBox flex-shrink-0" @click="getData()">
              <svg-icon svg-name="search" className="svgBox" />
            </div>
          </div>
        </div>
        <div
          class="flex-grow h-0 w-full px-2 box-border overflow-y-scroll overflow-x-hidden"
        >
          <div
            v-for="row in deviceList"
            :key="row.id"
            class="hover:shadow-[0_0_10px_0_inset] bg-backgroundv2 hover:shadow-theme hover:border-theme border-backgroundv2 flex-grow flex h-[32px] items-center border rounded my-2 box-border px-2"
            @click="deviceClick(row)"
          >
            <!-- 多选 -->
            <div class="centerBox">
              <span
                class="el-checkbox__input pos_r"
                style="margin-right: 6px; top: 1px"
                :class="judgeSelectedFn(row)"
              >
                <span class="el-checkbox__inner"></span>
              </span>
            </div>
            <div class="treeDepartment cursor_pointer">
              <div class="r-f w_100vh flex">
                <span class="flex-grow w-0 over_one_line">{{
                  row.railName
                }}</span>
                <span
                  v-show="row.deviceList.length"
                  class="flex-shrink-0 ml-1 text-theme"
                >
                  ({{ row.deviceList.length }})
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ul
        class="flex-1 bg-background !p-[10px] overflow-y-auto overflow-x-hidden box-border rounded shadow-[0_0_10px_0_inset] shadow-backgroundv3 flex flex-col ml-2"
      >
        <li
          v-show="selectedDataList.length === 0"
          class="h-full bg-backgroundv2 border border-border rounded px-2 box-border flex justify-center text-auxiliary items-center"
        >
          <div class="r-f tac block">
            <svg-icon
              svg-name="noData"
              className="svgBox !w-[50px] !h-[50px]"
            />
            <div class="mt-3">您还未选择围栏</div>
          </div>
        </li>
        <li
          v-for="(item, index) in selectedDataList"
          :key="item.id + '_selectedDataList'"
          class="w-full flex hover:shadow-[0_0_10px_0_inset] items-center bg-backgroundv2 rounded mb-2 box-border clickBox border border-backgroundv2 hover:border-theme hover:shadow-theme"
        >
          <div class="flex-grow w-0 pl-2 box-border over_one_line">
            {{ item.railName }}
          </div>
          <div class="flex-shrink-0">
            <iconBox
              margin="4px"
              :width="20"
              :height="20"
              @click="deleteSelectList(index)"
              classNmae="shadow_delete"
              imgName="delete"
              altText="删 除"
            ></iconBox>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    initSelectedDataList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  watch: {
    filterValue: function (val) {
      this.filterValue = val.replace(/\s/g, '')
    }
  },
  data() {
    return {
      checkAll: false,
      loading: false,
      treeHeight: 400,
      scrollYRenderConfig: {
        renderSize: 30,
        offsetSize: 10
      },
      deviceList: [],
      selectedDataList: [],
      filterValue: '',
      time: 400,
      timeOut: null
    }
  },
  components: {},
  mounted() {
    this.setSelectedDataList(this.initSelectedDataList)
    this.getData()
  },
  methods: {
    // 添加选中设备
    addSelectedDataList(selectedDataList = []) {
      if (selectedDataList.length === 0) return
      selectedDataList.forEach((row) => {
        const index = this.selectedDataList.findIndex((v) => v.id === row.id)
        if (index === -1) {
          this.selectedDataList.push(row)
        }
      })
      this.$nextTick(() => {
        this.checkAll = this.selectedDataList.length === this.deviceList.length
        this.$emit('on-selectedDataList', this.selectedDataList)
      })
    },
    // 设置选中数据
    setSelectedDataList(selectedDataList = []) {
      this.selectedDataList = [...selectedDataList]
    },
    // 获取选中数据
    getSelectedDataList() {
      return [...this.selectedDataList]
    },
    // 全选
    setCheckAll() {
      this.checkAll = !this.checkAll
      if (this.checkAll) {
        this.selectedDataList = [...this.deviceList]
      } else {
        this.selectedDataList = []
      }
      this.$emit('on-selectedDataList', this.selectedDataList)
    },
    judgeSelectedFn(row) {
      const index = this.selectedDataList.findIndex((v) => v.id === row.id)
      return index !== -1 ? 'is-checked' : ''
    },
    // 单个选中
    async deviceClick(row) {
      const index = this.selectedDataList.findIndex((v) => v.id === row.id)
      if (index === -1) {
        this.addSelectList(row)
      } else {
        this.deleteSelectList(index)
      }
    },
    // 添加
    addSelectList(row) {
      this.selectedDataList.push(row)
      this.checkAll = this.selectedDataList.length === this.deviceList.length
      this.$emit('on-selectedDataList', this.selectedDataList)
    },
    // 删除
    deleteSelectList(index) {
      this.selectedDataList.splice(index, 1)
      this.checkAll = this.selectedDataList.length === this.deviceList.length
      this.$emit('on-selectedDataList', this.selectedDataList)
    },
    // 获取数据
    async getData() {
      this.loading = true
      const { code, data = [] } = await this.$api.mapRail.findAllWithDevice({
        query: this.filterValue,
        spotCheckSupport: 1,
        onlineState: 1
      })
      if (code === 200) {
        this.deviceList = []
        data.forEach((item) => {
          if (item.deviceList.length !== 0) {
            this.deviceList.unshift(item)
          } else {
            this.deviceList.push(item)
          }
        })
        this.loading = false
      }
    }
  }
}
</script>
<style scoped lang="less" rel="stylesheet/less"></style>
