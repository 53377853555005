<!-- 的页面 -->
<template>
  <li
    class="clickBox css-text-no-select flex"
    @click="mapJumpLocationFn(source)"
  >
    <div class="flex-shrink-0">
      <svg-icon v-if="searchAddressType === 1" svg-name="maplocation" />
      <div
        class="el-markerImgBox centerBox !w-[26px] !h-[26px]"
        v-else-if="searchAddressType === 4"
      >
        <img :src="source.orgTypeIcon" />
      </div>
      <svg-icon
        :style="{ color: source.color || color }"
        v-if="searchAddressType === 6"
        svg-name="marker_patrolPoint"
      />
    </div>
    <div class="ml_10 flex-grow w-0">
      <div class="title fs_14 c_fff over_one_line fw_600">
        <span
          :style="{
            color: row.type === 2 ? 'var(--theme)' : 'var(--auxiliary)'
          }"
          v-for="(row, rowIndex) in source.title"
          :key="rowIndex"
          >{{ row.content }}</span
        >
      </div>
      <div class="address over_one_line fs_12 c_fff">{{ source.address }}</div>
      <div class="fs_12 c_9DA7B2">
        <span>经度：{{ source.lng }}</span>
        <span class="ml_20">纬度：{{ source.lat }}</span>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  props: {
    mapJumpLocationFn: {
      type: Function
    },
    index: {
      type: Number,
      default() {
        return null
      }
    },
    searchAddressType: {
      type: Number,
      default() {
        return 1
      }
    },
    color: {
      type: String,
      default() {
        return ''
      }
    },
    source: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  // components: {},
  // computed: {},
  // mounted(){},
  methods: {}
}
</script>
<style scoped lang="less" rel="stylesheet/less">
.filterContentList {
  li:hover {
    border-radius: 4px;
    background: var(--backgroundv3);
  }
  li {
    padding: 8px 0 10px;
    line-height: 20px;
    font-size: 12px;
    color: #fff;
    display: flex;
    align-items: center;
    padding-left: 4px;
    border-bottom: 1px solid var(--backgroundv3);
    .title {
      line-height: 28px;
      height: 28px;
    }
    svg {
      width: 26px;
      height: 26px;
      flex-shrink: 0;
    }
  }
}
</style>
