<!-- 操作日志 -->
<template>
  <AppTable
    ref="AppTable"
    :tableColumns="tableColumns"
    controller="report/userLog"
    :requestParams="requestParams"
  >
    <template #orgHeader="{ column }">
      <orgCascader
        @change="orgCascaderOnChange"
        ref="orgCascader"
        :contentType="true"
      >
        {{ column.label }}
      </orgCascader>
    </template>
    <template #weidu="">
      <div class="weidu"></div>
    </template>
    <template #information="{ row }">
      <div class="sss"></div>
      <el-row class="fs_12" style="text-align: left" :gutter="6">
        <el-col :span="24"
          ><div>
            <span class="c_888B8F">操作人：</span>{{ row.name }}
          </div></el-col
        >
        <el-col :span="24"
          ><div>
            <span class="c_888B8F">手机：</span>{{ row.phone }}
          </div></el-col
        >
      </el-row>
    </template>
    <template #typeHeader="{ column }">
      <div class="pos_r">
        <div
          :style="{
            opacity: column.focusType ? 1 : 0,
            position: column.focusType ? 'relative' : 'absolute'
          }"
          class="top-0 left-0"
        >
          <el-select
            v-model="requestParams.type"
            class="!w-full"
            clearable
            size="small"
            @change="getData()"
            @visible-change="visibleChange($event, column, 'focusType')"
            :placeholder="column.label"
          >
            <el-option
              v-for="index in 5"
              :key="index"
              :label="typeFn(index + 1)"
              :value="index + 1"
            ></el-option>
          </el-select>
        </div>
        <span v-if="!column.focusType" style="cursor: pointer">
          <i class="el-icon-caret-bottom"></i>{{ column.label }}
          <span class="fs_12">
            <span v-if="requestParams.type"
              >({{ typeFn(requestParams.type) }})</span
            >
          </span>
        </span>
      </div>
    </template>
    <template #typeState="{ row }">
      <div>{{ typeFn(row.type) }}</div>
    </template>
  </AppTable>
</template>

<script>
import exportFile from '@mixins/exportFile'
export default {
  mixins: [exportFile],
  data() {
    return {
      queryDtm: null,
      tableColumns: [
        {
          label: '日志生成时间',
          prop: 'logDtm'
        },
        {
          label: '操作人 - 手机',
          isSearch: true,
          customSlot: 'information',
          type: 'customSlot'
        },
        {
          label: '日志类型',
          prop: 'type',
          customSlot: 'typeState',
          type: 'customSlot',
          headerSlot: 'typeHeader'
        },
        {
          label: '日志内容',
          prop: 'content'
        }
        // {
        //   label: null,
        //   width: '30px',
        //   customSlot: 'weidu',
        //   type: 'customSlot'
        // }
      ],
      requestParams: {
        queryStartDtm: null,
        queryEndDtm: null,
        orgId: null,
        type: null
      }
    }
  },
  components: {},
  methods: {
    typeFn(type) {
      switch (type) {
        case 1:
          return '登录'
        case 2:
          return '退出'
        case 3:
          return '添加'
        case 4:
          return '编辑'
        case 5:
          return '删除'
        case 6:
          return '指令'
      }
    },
    visibleChange(e, row, name) {
      this.$set(row, name, e)
    },
    queryDtmFn() {
      if (this.queryDtm) {
        this.$set(this.requestParams, 'queryStartDtm', this.queryDtm[0])
        this.$set(this.requestParams, 'queryEndDtm', this.queryDtm[1])
      } else {
        this.$set(this.requestParams, 'queryStartDtm', null)
        this.$set(this.requestParams, 'queryEndDtm', null)
      }
      this.$nextTick(() => {
        this.getData()
      })
    },
    getData() {
      this.$refs.AppTable.getData()
    },
    orgCascaderOnChange(value) {
      this.requestParams.orgId = value[value.length - 1]
      this.$refs.AppTable.getData()
    }
  }
}
</script>
<style scoped lang="less" rel="stylesheet/less">
.el-row::after,
.el-row::before {
  display: none;
}
</style>
<style lang="less" rel="stylesheet/less">
.weidu {
  content: '';
  width: 10px;
  height: 10px;
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 50%;
  background: var(--dangerv2);
  box-shadow: 0px 0px 6px 1px var(--dangerv2);
}
</style>
