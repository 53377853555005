import Axios from '@/http'
import store from '@store'
export default {
  userAttenDev() {
    return new Promise((resolve, reject) => {
      Axios.post('userAttenDev/userAttenDev').then((res) => {
        return resolve(res)
      })
    })
  },
  removeAttenDev(deviceId) {
    return new Promise((resolve) => {
      const attenDevList = store.getters.attenDevList
      const result = attenDevList.find((val) => val.deviceId === deviceId)
      Axios.post('userAttenDev/delete', { id: result.id }).then((res) => {
        return resolve(res)
      })
    })
  }
}
