import Axios from '@/http'
export default {
  /* 取消分片上传 */
  abortMultipartUpload(params) {
    return new Promise((resolve, reject) => {
      Axios.post('file/abortMultipartUpload', params, { isJSON: true }).then(
        (res) => {
          resolve(res)
        }
      )
    })
  },
  /* 获取分片上传地址 */
  multipartUploadAuth(params) {
    return new Promise((resolve, reject) => {
      Axios.post('file/multipartUploadAuth', params).then((res) => {
        resolve(res)
      })
    })
  },
  /* 完成分片上传 */
  completeMultipartUpload(params) {
    return new Promise((resolve, reject) => {
      Axios.post('file/completeMultipartUpload', params, { isJSON: true }).then(
        (res) => {
          resolve(res)
        }
      )
    })
  },
  /* 新增文件 */
  mediaFileSave(params) {
    return new Promise((resolve, reject) => {
      Axios.post('mediaFile/mediaFile/save', params, { isJSON: true }).then(
        (res) => {
          resolve(res)
        }
      )
    })
  },
  /* 查找文件是否存在 */
  findByFileCloudKey(fileCloudKey) {
    return new Promise((resolve, reject) => {
      Axios.post('mediaFile/findByFileCloudKey', { fileCloudKey }).then(
        (res) => {
          resolve(res)
        }
      )
    })
  }
}
