import Axios from '@/http'

export default {
  // 燃气险情详情
  getGasCaseDetail(params) {
    return new Promise((resolve, reject) => {
      Axios.post('gasCase/detail', params).then((res) => {
        resolve(res)
      })
    })
  },

  // 查询是否打开了险情模式（1已经打开）
  openGasCaseModeStatus() {
    return new Promise((resolve, reject) => {
      Axios.post('gasCase/openGasCaseModeStatus').then((res) => {
        resolve(res)
      })
    })
  }
}
