<template>
  <div
    class="w-full h-full bg-backgroundv2 text-auxiliary text-base flex flex-col items-center justify-center"
  >
    <svg-icon svg-name="noData" class-name="svgBox !w-[46px] !h-[46px]" />
    <div class="text-center mt-[12px]">
      <p>您没有该模块的地图配置</p>
      <p>请联系管理员前往设置中心进行配置</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'noPermissionsMap',
  components: {},
  data() {
    return {}
  },
  methods: {},
  mounted() {}
}
</script>

<style scoped lang="less"></style>
