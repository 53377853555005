import Axios from '@/http'
export default {
  assignTaskDetail(params) {
    return new Promise((resolve, reject) => {
      Axios.post('taskAssign/assignTaskDetail', params).then((res) => {
        resolve(res)
      })
    })
  },
  todayTaskDetail(params) {
    return new Promise((resolve, reject) => {
      Axios.post('taskAssign/todayTaskDetail', params).then((res) => {
        resolve(res)
      })
    })
  },
  updateStatus(params) {
    return new Promise((resolve, reject) => {
      Axios.post('taskAssign/updateStatus', params, { isJSON: true }).then(
        (res) => {
          resolve(res)
        }
      )
    })
  }
}
