var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inline-flex",on:{"click":function($event){$event.stopPropagation();return _vm.clickFn.apply(null, arguments)}}},[(_vm.altText)?_c('el-tooltip',{directives:[{name:"delTabIndex",rawName:"v-delTabIndex"}],staticClass:"item",attrs:{"open-delay":500,"effect":"dark","content":_vm.altText,"placement":"top-start"}},[_c('div',{staticClass:"iconBox css-text-no-select",class:_vm.classNmae,style:({
        width: _vm.classNmae === 'iconBoxV2' ? '28px' : `${_vm.parseRem(_vm.width)}rem`,
        height: _vm.classNmae === 'iconBoxV2' ? '28px' : `${_vm.parseRem(_vm.height)}rem`,
        margin: _vm.margin
      })},[_c('svg-icon',{attrs:{"svg-name":_vm.imgName,"className":"svgBox"}})],1)]):_c('div',{staticClass:"iconBox css-text-no-select",class:_vm.classNmae,style:({
      width: _vm.classNmae === 'iconBoxV2' ? '28px' : `${_vm.parseRem(_vm.width)}rem`,
      height: _vm.classNmae === 'iconBoxV2' ? '28px' : `${_vm.parseRem(_vm.height)}rem`
    })},[_c('svg-icon',{attrs:{"svg-name":_vm.imgName,"className":"svgBox"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }