import reportPopup from '@components/report/reportPopup'
import instructionProgress from '@components/instructionProgress/instructionProgress.vue'
import workOrder from '@components/workOrder/workOrder'
import { mapGetters } from 'vuex'
export default {
  props: {
    /* 头部显示状态 */
    headerState: {
      type: Boolean,
      default: () => {
        return true
      }
    },
    menuList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  components: {
    reportPopup,
    instructionProgress,
    workOrder
  },
  computed: {
    ...mapGetters([
      'userInfo',
      'directiveList',
      'businessSystemSetting',
      'fileUploadNum'
    ])
  },
  watch: {
    directiveList: {
      handler() {
        this.directiveListFn()
      },
      deep: true
    }
  },
  data() {
    return {
      moreMenuVisible: false,
      moreMenuSelectItem: {}, // 更多导航选中
      moreMenuList: [], // 更多导航
      permissionIdList: []
    }
  },
  mounted() {
    this.notReadCount()
    this.$store.commit('addUserOperationCallback', {
      operationName: 'msgNotRead',
      callback: async ({ noticeType }) => {
        if (noticeType) {
          if (noticeType === 'dailyCheckAbnormal') {
            await this.$message.warning(
              '您有一条考勤异常记录，请进入考勤模式查看'
            )
          }
        }
        if (this.$refs.reportPopup) {
          this.$refs.reportPopup.notReadCount()
        }
      }
    })
    this.directiveListFn()
    this.businessSystemSettingFn()
    this.organizeNavigation()
  },
  methods: {
    /* 更多导航展示 */
    moreMenuVisibleShow() {
      this.moreMenuVisible = true
    },
    /* 更多导航关闭 */
    moreMenuVisibleClose() {
      this.moreMenuVisible = false
    },
    /* 设置更多选中对象 */
    setMoreMenuSelectItem(item, pushState = false) {
      this.moreMenuSelectItem = item
      if (pushState) {
        this.routerPush(item.path)
      }
    },
    organizeNavigation() {
      this.$nextTick(() => {
        const permissionIdList =
          window.sessionStorage.getItem('permissionIdList')
        this.permissionIdList =
          permissionIdList && permissionIdList.length
            ? JSON.parse(permissionIdList)
            : []
        let menuList = []
        this.menuList.forEach((item) => {
          if (item.permission) {
            const index = this.permissionIdList.findIndex(
              (v) => v === item.permission
            )
            if (index !== -1) {
              menuList.push(item)
            }
          } else {
            menuList.push(item)
          }
        })
        this.$nextTick(() => {
          let length = menuList.length
          if (length > 8) {
            const moreMenuList = menuList.splice(7, length - 1)
            this.moreMenuList = moreMenuList
            length = 7
            this.setMoreMenuSelectItem(this.moreMenuList[0])
          } else {
            this.moreMenuList = []
          }
          const num = Math.ceil(length / 2)
          this.menuListLeft = menuList.splice(0, num)
          this.menuListRight = menuList
          this.$nextTick(() => {
            this.componentMethodList.forEach((v) => {
              this.$store.commit('componentMethodFn', {
                name: v,
                callback: (res) => {
                  if (res.methodName) {
                    this.$refs[`${v}`][`${res.methodName}`](
                      res.params,
                      res.paramsV2,
                      res.otherParams
                    )
                  }
                }
              })
            })
          })
        })
      })
    },
    notReadCount() {
      this.$api.msgRead.notReadCount().then((res) => {
        if (res.code === 200) {
          const form = res.data || {}
          this.$nextTick(() => {
            let total = 0
            const list = []
            const sectionList = [
              {
                type: 1,
                params: '',
                name: '系统消息',
                componentName: 'operationLog', // 组件名称
                permission: 'report_operationLog', // 权限
                svgName: 'news_systemMessages'
              },
              {
                type: 4,
                params: 'inviteMsg',
                permission: 'null',
                name: '邀请消息',
                componentName: '', // 组件名称
                svgName: 'news_invitationMessage'
              },
              {
                type: 5,
                params: 'dailyCheck',
                permission: 'dailyCheck_checkReport',
                name: '每日考勤',
                componentName: 'dailyCheckDay', // 组件名称
                svgName: 'news_dailyAttendance'
              },
              {
                type: 6,
                params: 'sos',
                name: 'SOS',
                remarks: '点击详细信息，地图可移动到对应位置',
                componentName: 'sosQuery', // 组件名称
                permission: 'report_sosQuery',
                svgName: 'news_sos'
              },
              {
                type: 7,
                params: 'rule',
                name: '违反规则',
                remarks: '点击详细信息，地图可移动到对应位置',
                componentName: 'breakQuery', // 组件名称
                permission: 'report_breakQuery',
                svgName: 'news_ruleViolation'
              },
              {
                type: 8,
                params: 'storage',
                name: '储存预警',
                remarks: '点击详细信息，地图可移动到对应位置',
                componentName: 'storageQuery', // 组件名称
                permission: 'report_storageQuery',
                svgName: 'news_storageAlert'
              },
              {
                type: 10,
                params: 'gasCase',
                name: '险情消息',
                componentName: 'gasCaseQuery', // 组件名称
                permission: 'alert',
                svgName: 'news_gasCase'
              },
              {
                type: 11,
                params: 'cases',
                name: '案件通知',
                componentName: 'casesQuery', // 组件名称
                permission: 'caseCenter',
                svgName: 'news_cases'
              }
            ]
            let permissionIdList =
              window.sessionStorage.getItem('permissionIdList')
            if (permissionIdList && permissionIdList.length) {
              permissionIdList = JSON.parse(permissionIdList)
              this.$nextTick(() => {
                this.commandsType =
                  permissionIdList.findIndex((v) => v === 'commands') !== -1
                for (let i = 0; i < sectionList.length; i++) {
                  const item = sectionList[i]
                  if (item.permission) {
                    const index = permissionIdList.findIndex(
                      (v) => v === item.permission
                    )
                    if (index !== -1) {
                      list.push(item)
                    }
                  } else {
                    list.push(item)
                  }
                }
                this.$nextTick(() => {
                  list.forEach((item) => {
                    const num = form[`${item.params}`] - 0
                    if (num) {
                      total += num
                    }
                  })
                  this.msgNotReadTotalFn(total)
                })
              })
            }
          })
        }
      })
    }
  }
}
