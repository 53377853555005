import http from '@/http'
export default {
  // 根据progressKey查询
  findByProgressKey(progressKey) {
    return new Promise((resolve, reject) => {
      http
        .post('warningRecord/findByProgressKey', { progressKey })
        .then((res) => {
          resolve(res)
        })
    })
  },
  // 停止报警
  warningClose(closeop) {
    return new Promise((resolve, reject) => {
      http.post('warningRecord/warningClose', { closeop }).then((res) => {
        resolve(res)
      })
    })
  },
  /* 报警类型 */
  fileNumCal(warningRecordId) {
    return new Promise((resolve, reject) => {
      http
        .post('warningRecord/sos/fileNumCal', { warningRecordId })
        .then((res) => {
          resolve(res)
        })
    })
  },
  /* SOS文件按日期分组查找 */
  fileGroupByDate(params) {
    return new Promise((resolve, reject) => {
      http.post('warningRecord/sos/fileGroupByDate', params).then((res) => {
        resolve(res)
      })
    })
  }
}
