<!--  新标签页打开的标题 -->
<template>
  <div class="headerBox">
    <div class="zoomBox" v-if="hideLayerList.length">
      <div
        @mouseenter="openTypeFn(false)"
        class="openBox clickBox"
        v-if="openType"
      >
        <i class="el-icon-caret-top" style="color: var(--main)"></i>
      </div>
      <ul
        v-else
        @mouseleave="openTypeFn(true)"
        :style="{ top: navigationTop ? '44px' : '0px' }"
        class="navigation"
      >
        <li
          v-for="(item, index) in hideLayerList"
          @click="hideLayerFn(item.componentsName, index)"
          :key="index"
        >
          <div class="item clickBox centerBox">
            <svg-icon
              v-if="item.componentsName === 'singleTalkback'"
              svg-name="abbreviation_member"
              className="svgBox"
            />
            <svg-icon
              v-if="item.componentsName === 'multiPersonIntercom'"
              svg-name="abbreviation_group"
              className="svgBox"
            />
            <svg-icon
              v-if="item.componentsName === 'multipleChecks'"
              svg-name="abbreviation_videoSurveillance"
              className="svgBox"
            />
            <svg-icon
              v-if="item.componentsName === 'departmentMultipleChecks'"
              svg-name="abbreviation_videoSurveillance"
              className="svgBox"
            />
            <svg-icon
              v-if="item.componentsName === 'singleInspection'"
              svg-name="abbreviation_video"
              className="svgBox"
            />
            <!-- 录像 -->
            <svg-icon
              v-if="item.componentsName === 'recording'"
              svg-name="abbreviation_recording"
              className="svgBox"
            />
            <!-- 录音 -->
            <svg-icon
              v-if="item.componentsName === 'soundRecording'"
              svg-name="abbreviation_soundRecording"
              className="svgBox"
            />
            <svg-icon
              v-if="item.componentsName === 'broadcast'"
              svg-name="abbreviation_broadcast"
              className="svgBox"
            />
          </div>
          <span class="c_fff fs_12 title">{{ item.title }}</span>
        </li>
      </ul>
    </div>
    <div
      id="intercomAudioBox"
      style="
        opacity: 0;
        position: fixed;
        top: -10px;
        left: -10px;
        overflow: hidden;
        width: 1px;
        height: 1px;
      "
    ></div>
    <personnelCheckAdd ref="personnelCheckAdd"></personnelCheckAdd>
    <!-- 多人监控 -->
    <multipleChecks
      @on-componentShowLayer="onComponentShowLayer"
      @on-hideLayerListFn="hideLayerListFn"
      @on-issueMessage="issueMessageFn"
      @on-broadcast_addNewRoomFn="broadcast_addNewRoomFn"
      @on-broadcast_closeTheRoom="broadcast_closeTheRoom"
      @on-broadcast_closeAllRoomsFn="broadcast_closeAllRoomsFn"
      contentType="multipleChecks"
      ref="multipleChecks"
    >
    </multipleChecks>
    <!-- 多人监控 (部门检查)-->
    <multipleChecks
      @on-hideLayerListFn="hideLayerListFn"
      contentType="departmentMultipleChecks"
      @on-close="personnelCheckAddShow"
      ref="departmentMultipleChecks"
    ></multipleChecks>
    <!--单呼-->
    <single-talkback
      @on-hideLayerListFn="hideLayerListFn"
      @on-close="personnelCheckAddShow"
      ref="singleTalkback"
    ></single-talkback>
    <!-- 多人对讲 -->
    <multiPersonIntercom
      @on-hideLayerListFn="hideLayerListFn"
      @on-close="multiPersonIntercomClose"
      ref="multiPersonIntercom"
    ></multiPersonIntercom>
    <!-- 单人视频 -->
    <singleInspection
      @on-hideLayerListFn="hideLayerListFn"
      @on-close="personnelCheckAddShow"
      ref="singleInspection"
    ></singleInspection>
    <!-- 录像 -->
    <recording
      @on-hideLayerListFn="hideLayerListFn"
      ref="recording"
    ></recording>
    <!-- 录音 -->
    <soundRecording
      @on-hideLayerListFn="hideLayerListFn"
      ref="soundRecording"
    ></soundRecording>
    <!-- 广播 -->
    <broadcast
      @on-hideLayerListFn="hideLayerListFn"
      @on-broadcasRoomDeviceChange="broadcasRoomDeviceChange"
      ref="broadcast"
    ></broadcast>
    <!-- 文件播放 -->
    <filePlayback ref="filePlayback"></filePlayback>
    <!-- 分配任务 -->
    <assignTasks ref="assignTasks"></assignTasks>
    <!-- 任务详情  -->
    <independentMode ref="independentMode"></independentMode>
    <headerNavigation
      :headerState="false"
      :menuList="menuList"
      @on-methodLogout="methodLogout"
      @on-routerPush="routerPush"
      ref="headerNavigation"
    ></headerNavigation>
    <!-- 文件上传  -->
    <fileUpload ref="fileUpload"></fileUpload>
    <!-- 新消息提醒 -->
    <newMessageReminder ref="newMessageReminder"></newMessageReminder>
    <!-- 下发消息 -->
    <issueMessage ref="issueMessage"></issueMessage>
  </div>
</template>

<script>
import { DangerTypes } from '@/types/dangerType.js'
import headerBox from '@components/layout/headerBox/js/index.js'
export default {
  name: 'newTabOpenHeader',
  mixins: [headerBox],
  components: {},
  data() {
    return {}
  },
  methods: {
    /* 新的sos弹窗提示 */
    newSosEventsFn() {
      const close = () => {
        this.warningClose('sos')
        this.$refs.newMessageReminder.onClose('sos')
      }
      this.$refs.newMessageReminder
        .show({
          type: 'sos',
          imgUrl: 'newCase.png',
          title: 'S O S',
          content: '有新的SOS发生',
          auxiliaryContent: '请前往指挥中心处理',
          contentSvgName: 'sos-ring',
          submitShow: false,
          headerHeight: 0
        })
        .catch(close)
    },
    /* 有新险情通知 */
    newCaseNotice() {
      // 强提醒
      const strongReminder = (gasCaseId) => {
        const onClose = () => {
          this.$refs.newMessageReminder.onClose('gasCase')
        }
        const onSubmit = () => {
          this.$router.push(
            `/dispatching#/dangerMode?gasCaseId=${gasCaseId}&dangerType=${DangerTypes.DANGER_MODE}`
          )
          onClose()
        }
        this.$refs.newMessageReminder
          .show({
            type: 'gasCase',
            imgUrl: 'newCase.png',
            title: '险情报警',
            content: '有新的险情',
            submitText: '立即加入',
            contentSvgName: 'device-ring',
            headerHeight: 0
          })
          .then(onSubmit)
          .catch(onClose)
      }
      /**
       * caseSourceType
       * 1设备上报、2话机热线、3客服转单
       * */
      const callback = async ({ gasCaseId, caseSourceType }) => {
        if (this.separatePageName !== 'gasCase') return
        if (caseSourceType !== 1) return
        strongReminder(gasCaseId)
      }
      this.$store.commit('addUserOperationCallback', {
        id: 'headerBox_newCaseNotice',
        operationName: 'newCaseNotice',
        callback
      })
    },
    /* 险情电话报警 */
    gasCasePhoneAlarm() {
      // 关闭报警弹窗
      const newMessageReminderOnClose = () => {
        this.$refs.newMessageReminder.onClose('gasCasePhoneAlarm')
      }
      // 未接起
      const onSLCNotAnswer = () => {
        this.$message.warning('电话已挂断')
        newMessageReminderOnClose()
      }
      /* 电话响铃 */
      const onCallIn = async (phone, callFlowNo) => {
        this.$message.warning('有险情来电，请接听')
        this.$refs.newMessageReminder
          .show({
            type: 'gasCasePhoneAlarm',
            imgUrl: 'newCase.png',
            title: '险情来电',
            content: '有险情来电',
            auxiliaryContent: '拿起话筒接听或挂断',
            contentSvgName: 'phoneAlarm-ring',
            headerHeight: 0,
            closeShow: true,
            closeText: '稍后处理',
            submitShow: false
          })
          .catch(newMessageReminderOnClose)
        // if (this.$store.state.dangerMode.isCreateDangerDrawer) return
        // this.$router.push(
        //   `/dispatching#/dangerMode?policePersonPhone=${phone}&callFlowNo=${callFlowNo}&createDtm=${this.createUniqueId(
        //     1
        //   )}&caseSourceType=2`
        // )
      }
      /* 电话接起 */
      const onSLCAnswer = async ({ phone, callFlowNo }) => {
        if (!phone) return
        newMessageReminderOnClose()

        // // if (this.$store.state.dangerMode.isCreateDangerDrawer) return
        // this.$router.push(
        //   `/dispatching#/dangerMode?policePersonPhone=${phone}&callFlowNo=${callFlowNo}&createDtm=${this.createUniqueId(
        //     1
        //   )}&caseSourceType=2`
        // )

        this.$router.push(
          `/dispatching#/dangerMode?policePersonPhone=${phone}&callFlowNo=${callFlowNo}&createDtm=${this.createUniqueId(
            1
          )}&caseSourceType=2`
        )
      }
      const callback = async (params) => {
        if (this.separatePageName !== 'gasCase') return
        console.log('params', params)
        const { opType, exten, phone, callFlowNo } = params || {}
        const { data = null } = await this.$api.gasCase.userExten()
        if (!data || data !== exten) return
        // data：phone（打进来的手机号）, opType（“CallIn”打进来，“SLCAnswer”接起）
        switch (opType) {
          case 'CallIn': // 打进来
            await onCallIn(phone, callFlowNo)
            break
          case 'SLCAnswer': // 接起
            onSLCAnswer({ phone, callFlowNo })
            break
          case 'SLCNotAnswer': // 接起
            // onSLCNotAnswer({ phone })
            break
          default:
            break
        }
      }
      this.$store.commit('addUserOperationCallback', {
        id: 'headerBox_gasCasePhoneAlarm',
        operationName: 'gasCasePhoneAlarm',
        callback
      })
    }
  },
  mounted() {}
}
</script>

<style
  src="@/components/layout/headerBox/css/index.less"
  scoped
  lang="less"
  rel="stylesheet/less"
></style>
<style scoped lang="less" rel="stylesheet/less"></style>
