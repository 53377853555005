/* eslint-disable */
import { Notification } from 'element-ui'

var weakPwd = [
  'P@SSWORD',
  'TRUSTNO1',
  'PASSW0RD',
  '1QAZ2WSX',
  'PASSWORD',
  'ABC123',
  'ILOVEYOU',
  'ADOBE123',
  '123123',
  'SUNSHINE',
  '1314520',
  'A1B2C3',
  '123QWE',
  'AAA111',
  'QWEASD',
  'ADMIN',
  'PASSWD',
  'ROOT',
  '111111',
  '123456',
  '11111111',
  '11112222',
  '11223344',
  '12121212',
  '12301230',
  '12341234',
  '12344321',
  '12345678',
  '19780822',
  '19920624',
  '58858264',
  '77777777',
  '87654321',
  '88888888',
  '1.89E+25',
  '!!QQ@@WW',
  '!@#$%^&*',
  '!@#$1234',
  '!@#$5678',
  '!@#$QWER',
  '!@QWASZX',
  '!Q@W#E$R',
  '!Q@W3E4R',
  '!Q2W3E4R',
  '!QAZ@WSX',
  '!QAZ1QAZ',
  '!QAZ2WSX',
  '!QAZ3EDC',
  '!QAZXSW@',
  '!QAZXSW2',
  '!Z@X3C4V',
  '#EDC$RFV',
  '*IK<.LO9',
  '@#QWAS68',
  '@WSX3EDC',
  '^YHN^YHN',
  '0.123ABC',
  '0NBLDPNB',
  '0PLMNKO9',
  '100DEDI@',
  '123.789+',
  '123@123A',
  '123@ABC?',
  '123@ROOT',
  '1234!@#$',
  '1234$#@!',
  '1234%^&*',
  '1234.ASD',
  '12345@GD',
  '123456.A',
  '123456@A',
  '1234567A',
  '123456A!',
  '123456A?',
  '123456AA',
  '123456AA',
  '123456AA',
  '123456AB',
  '123456GG',
  '123456QQ',
  '123456QW',
  '1234AAAA',
  '1234ABCD',
  '1234QQQQ',
  '1234QWER',
  '1234REWQ',
  '1234ZXCV',
  '123QWE,.',
  '12QW34ER',
  '12QWASZX',
  '1985MAI2',
  '1A2B3C4D',
  '1A2S3D4F',
  '1NT3RN3T',
  '1Q2W!Q@W',
  '1Q2W#E$R',
  '1Q2W3E4R',
  '1QAZ!QAZ',
  '1QAZ#EDC',
  '1QAZ@WSX',
  '1QAZ0OKM',
  '1QAZ1QAZ',
  '1QAZ2WSX',
  '1QAZ2XSW',
  '1QAZ3EDC',
  '1QAZXDR5',
  '1QAZXSW@',
  '1QAZXSW2',
  '1Z2X3C4V',
  '21VIANET',
  '21WQSAXZ',
  '2WSX!QAZ',
  '2WSX@WSX',
  '2WSX1QAZ',
  '2WSX3EDC',
  '3EDC#EDC',
  '3EDC4RFV',
  '4RFV5TGB',
  '5TGB6YHN',
  '855674WW',
  'A123456!',
  'A1234567',
  'A123B456',
  'A1B2C3D4',
  'A1S2D3F4',
  'AA123123',
  'AA123456',
  'AA654321',
  'AAAA1111',
  'AB123456',
  'AB12CD34',
  'ABC@@123',
  'ABC0.123',
  'ABC1234%',
  'ABC12345',
  'ABCD@123',
  'ABCD1234',
  'ABCDABCD',
  'AD!@#$%^',
  'ADMIN!@#',
  'ADMIN000',
  'ADMIN001',
  'ADMIN007',
  'ADMIN123',
  'ADMIN321',
  'ADMIN520',
  'ADMIN789',
  'ADMINWEI',
  'AHDXIPTV',
  'AJAY@123',
  'ASD123.0',
  'ASDF1234',
  'ASDFASDF',
  'ASDFQWER',
  'ASTERISK',
  'AUTOHOME',
  'AVONLINE',
  'BACKDOOR',
  'BASEBALL',
  'BUGAOSNI',
  'BUZHIDAO',
  'CACTI123',
  'CALM$123',
  'CATM!@#$',
  'CDE34RFV',
  'CDNADMIN',
  'CESHI123',
  'CHAFFING',
  'CHAMPION',
  'CHANGEME',
  'CHINA_ME',
  'CHINA125',
  'CISCO123',
  'CMCC2011',
  'CMCC2012',
  'CORECESS',
  'CTYUN**1',
  'CVSADMIN',
  'DATABASE',
  'DATABEST',
  'DAVID123',
  'DB2ADMIN',
  'DB2FENC1',
  'DB2FENC2',
  'DB2INST1',
  'DB2INST2',
  'DB2INST3',
  'DBPROXY1',
  'DELL@123',
  'DIRECTOR',
  'DNFLSKFK',
  'DNS99.CN',
  'DOWNLOAD',
  'DREAMBOX',
  'EBASERDB',
  'EINSTEIN',
  'ERIC@123',
  'ESHOP123',
  'EXCLOUD8',
  'EXPLORER',
  'FANRY@61',
  'FIREWALL',
  'FLOO4ALL',
  'FLZXSQC!',
  'FSFS..!@',
  'FTPUSER1',
  'FUCK2012',
  'FW3MDNME',
  'GARFIELD',
  'GG123456',
  'GIT12345',
  'GITADMIN',
  'GL123456',
  'GUEST111',
  'GUEST123',
  'GUEST666',
  'GUEST888',
  'GUEST999',
  'HALLMARK',
  'HELEN123',
  'HELLO123',
  'HOSTNAME',
  'HUANG123',
  'HUAWEI#1',
  'I2KPWDD4',
  'I2KPWDI5',
  'ICDADMIN',
  'IDC12345',
  'IDCEZ123',
  'IDCIDCOK',
  'IDRAC!@#',
  'IDRAC123',
  'IFC20145',
  'ILOVEYOU',
  'IMAPUSER',
  'INFO@123',
  'INFORMIX',
  'INTERNAL',
  'INTERNET',
  'IPTV!@#$',
  'IPTV@123',
  'IPTV1234',
  'IPTVIPTV',
  'JBOSS123',
  'JIANG123',
  'JK889900',
  'JSSQ9061',
  'JUSTDOIT',
  'JXIDC123',
  'LINUX123',
  'LINUX520',
  'LOLER11Q',
  'M&G_2010',
  'MANAGER1',
  'MANDRAKE',
  'MAVERICK',
  'MERCEDES',
  'MM123456',
  'MONGODB2',
  'MYSQL123',
  'NATIONAL',
  'NCC1701D',
  'NE7JA%5M',
  'NETADMIN',
  'NETGEAR1',
  'NETNUMEN',
  'NFYWS6LJ',
  'NGINX123',
  'NIHAO123',
  'NIMASILE',
  'NOKIA123',
  'NOSOUP4U',
  'NSR@2015',
  'OANA1982',
  'OPENDOOR',
  'OPENELEC',
  'OPERATOR',
  'ORACLE12',
  'ORACLE88',
  'OS10+ZTE',
  'OTTUM123',
  'P@$$W0RD',
  'P@$$WORD',
  'P@55W0RD',
  'P@SS1234',
  'P@SSW0RD',
  'P@SSWORD',
  'P0O9I8U7',
  'P4SSW0RD',
  'PA$$S0RD',
  'PA$$W0RD',
  'PA55W0RD',
  'PA55WORD',
  'PA5SW0RD',
  'PAKISTAN',
  'PARAMIKO',
  'PASS1234',
  'PASSPASS',
  'PASSW0RD',
  'PASSWORD',
  'PLCMSPIP',
  'POSTGRES',
  'POWER123',
  'PROXY160',
  'PW$$W0RD',
  'PWRCHUTE',
  'Q!W@E#R$',
  'Q1W2E3R4',
  'Q1W2Q!W@',
  'Q2W3E4R5',
  'QASWEDFR',
  'QAWSEDRF',
  'QAZ1WSX2',
  'QAZWE123',
  'QQ123!@#',
  'QQ123456',
  'QW123456',
  'QWASZX12',
  'QWE123.0',
  'QWE1231A',
  'QWE12345',
  'QWEAZ123',
  'QWER!@#$',
  'QWER123$',
  'QWER1234',
  'QWER3EDC',
  'QWERASDF',
  'QWERT789',
  'QWERTYUI',
  'R00TR00T',
  'R4E3W2Q1',
  'RABBITMQ',
  'RAINSOFT',
  'RECOVERY',
  'REDIS123',
  'RELATION',
  'ROOT!@#$',
  'ROOT!123',
  'ROOT#123',
  'ROOT$123',
  'ROOT%123',
  'ROOT.123',
  'ROOT@123',
  'ROOT_123',
  'ROOT1234',
  'ROOT2013',
  'ROOT2014',
  'ROOT2015',
  'ROOT2016',
  'ROOTPASS',
  'ROOTROOT',
  'ROOTTOOR',
  'SECURITY',
  'SERVERXP',
  'SH123456',
  'SISTEMAS',
  'SNAPSHOT',
  'SPEEDTOP',
  'SUNJOYCN',
  'SUNSHINE',
  'SUPERMAN',
  'SYNOPASS',
  'SYSADMIN',
  'SZUNICOM',
  'TEST@123',
  'TEST1234',
  'TEST2014',
  'TESTPASS',
  'TESTTEST',
  'TESTUSER',
  'TMRGQCB7',
  'TOOR1234',
  'TOV8P2FQ',
  'TPKK9CFA',
  'TRUSTNO1',
  'TVM1NING',
  'TVMINING',
  'TZ123456',
  'ULTRANMS',
  'UPNETBGP',
  'USERNAME',
  'UWAY2011',
  'W1E2R3T4',
  'WASADMIN',
  'WEBADMIN',
  'WEBLOGIC',
  'WELCOME.',
  'WELCOME1',
  'WELCOME2',
  'WINN2000',
  'WMDJ~123',
  'WOAINIMA',
  'WUKAI0.0',
  'WWW-DATA',
  'XMHKMYGS',
  'XSW23EDC',
  'YANG@123',
  'YBCNC888',
  'YMIDC.CN',
  'Z1X2C3V4',
  'Z7895123',
  'ZAQ!@WSX',
  'ZAQ!2WSX',
  'ZAQ1@WSX',
  'ZAQ12WSX',
  'ZAQ1XSW2',
  'ZJSX2121',
  'ZTE_IPTV',
  'ZXC123.0',
  'ZXCV!@12',
  'ZXCV1234',
  'ZXDBP_10',
  'ZXSMGWDB',
  'ZZ123456',
  '100200300',
  '110120119',
  '111111111',
  '111222333',
  '123123123',
  '123456123',
  '123456789',
  '147258369',
  '147852369',
  '159357456',
  '222222222',
  '333333333',
  '333333333',
  '444444444',
  '456123789',
  '555555555',
  '610713725',
  '666666666',
  '727527122',
  '777777777',
  '789654123',
  '888888888',
  '963852741',
  '987654321',
  '987654321',
  '999999999',
  '---------',
  '!@#123QWE',
  '!@#QWE123',
  '!@#QWEASD',
  '#>!$!%(@^',
  '111QQQ!!!',
  '111QQQ...',
  '123!@#123',
  '123!@#QWE',
  '123..0!@#',
  '123@ADMIN',
  '123@QWE~~',
  '1234!@#$%',
  '1234.ABCD',
  '123456!@#',
  '1234560..',
  '123456ABC',
  '123456ASD',
  '123456QWE',
  '123456WSX',
  '123ABC!@#',
  '123ABC@HO',
  '123ABC456',
  '123ASD!@#',
  '123ASDASD',
  '123QAZ!@#',
  '123QAZQAZ',
  '123QWE!@#',
  '123QWE,./',
  '123QWE123',
  '123QWEASD',
  '123QWERTY',
  '123ZXC!@#',
  '1A2S3D!@#',
  '1Q2W3E!@#',
  '1Q2W3E4R5',
  '1QA2WS3ED',
  '1QAZ@1QAZ',
  '1QAZ@2WSX',
  '1QAZ@3EDC',
  '1QAZ2WSX3',
  '1QZ2WX3EC',
  '2342W3E4R',
  '2894721..',
  '3H8IDC!!#',
  '855674W!@',
  '855674WW!',
  '8978436..',
  '990SF.NET',
  '995430AAA',
  'A33565378',
  'AAA123!@#',
  'ABC!@#123',
  'ABC123!@#',
  'ABC123$%^',
  'ABC123456',
  'ABCABC123',
  'ABCD.1234',
  'ABCD@1234',
  'ABCD=1234',
  'ABCD-1234',
  'ABCD1234!',
  'ABCD12345',
  'ADMIN!@#$',
  'ADMIN#123',
  'ADMIN@123',
  'ADMIN@321',
  'ADMIN@WEI',
  'ADMIN_!@#',
  'ADMIN_123',
  'ADMIN_MMS',
  'ADMIN-123',
  'ADMIN123!',
  'ADMIN123#',
  'ADMIN123$',
  'ADMIN1234',
  'ADMIN2014',
  'ADMIN2015',
  'ADMIN2016',
  'ADMINROOT',
  'ADMINUSER',
  'AHDX_IPTV',
  'ALCATELDC',
  'ANTIVIRUS',
  'AQ1SW2DE3',
  'ASD!@#123',
  'ASD123!@#',
  'ASD123456',
  'ASD123ASD',
  'ASDASDASD',
  'ASDF_1234',
  'ASDFGHJKL',
  'ASDQWE123',
  'BUGAOSUNI',
  'CACTI@123',
  'CDN@DNION',
  'CENTOS123',
  'CGSL#1234',
  'CHANGEME2',
  'CINTERNET',
  'CNP200@HW',
  'COMMON123',
  'CSCKF24@$',
  'CTTHB.IDC',
  'CTYUN@123',
  'DEPLOY123',
  'DEVELOPER',
  'DNION@123',
  'ESESERVER',
  'ESHORE!!0',
  'ESHORE!!1',
  'ESHORE!!2',
  'ESHORE!!3',
  'ESHORE!!4',
  'ESHORE!!5',
  'ESHORE!!6',
  'ESHORE!!7',
  'ESHORE!!8',
  'ESHORE!!9',
  'ESHORE!@#',
  'ESHORE!@3',
  'ESHORE!89',
  'ESHORE.CN',
  'ESHORE114',
  'ESHROE!@#',
  'FASTCACHE',
  'FOUNDERBN',
  'FS20!0!23',
  'FTPSECURE',
  'GDSZADMIN',
  'GIT123456',
  'GITGIT123',
  'GUANGZHOU',
  'HACLUSTER',
  'HADOOP123',
  'HAOHAO123',
  'HAWKCO123',
  'HELLO@123',
  'HMSPASSWD',
  'HMSSERVER',
  'HUAWEI!@#',
  'HUAWEI123',
  'HUAWEIIDC',
  'IDC!@#123',
  'IDC0123.0',
  'IDC121212',
  'IDC123!@#',
  'IDC123123',
  'IDC123456',
  'IDCCUN110',
  'IDCIDCIDC',
  'IDCJI2010',
  'IDCOFFICE',
  'IDRAC@123',
  'IMAGES123',
  'INDONESIA',
  'INTERFACE',
  'IVR_CARDS',
  'JSICT1502',
  'KGPGADMIN',
  'LINUX@123',
  'LINUX2013',
  'LINUX2014',
  'LISY@TEST',
  'LOCALHOST',
  'MANAGER19',
  'MDNFTP_R1',
  'MDNFTP_R2',
  'MICROSOFT',
  'MINECRAFT',
  'MOBILE123',
  'MUIEMULTA',
  'N2000USER',
  'N2003USER',
  'NAGIOS123',
  'NETCENTER',
  'NIHAO,123',
  'NIHAO.123',
  'NJDX@2014',
  'OAOIDC123',
  'OMMSERVER',
  'OPROFILRE',
  'ORACLE!@#',
  'ORACLE123',
  'P@55W0RD!',
  'P@55W0RD1',
  'P@SSW0RD!',
  'P@SSW0RD1',
  'PA$$W0RD1',
  'PASS@WORD',
  'PASS12345',
  'PASSW0RD!',
  'PASSW0RD1',
  'PASSWD123',
  'PASSWORD1',
  'PASSWORDS',
  'PATROL123',
  'PORTAL123',
  'PPACCOUNT',
  'PR@JECT94',
  'PUP87NOG$',
  'Q1W2E3!@#',
  'QAZ#@!321',
  'QAZ123!@#',
  'QAZ123$%^',
  'QAZ123456',
  'QAZWER)(*',
  'QAZWSX!@#',
  'QAZWSX123',
  'QAZWSX321',
  'QAZWSXEDC',
  'QAZXCVBNM',
  'QAZXSW123',
  'QAZXSWEDC',
  'QQ@123456',
  'QWE!@#123',
  'QWE123!@#',
  'QWE123123',
  'QWE123456',
  'QWE123ASD',
  'QWE123QWE',
  'QWEASD!@#',
  'QWEASD123',
  'QWEASDZXC',
  'QWEDCXZAS',
  'QWEDSAZXC',
  'QWEQWE123',
  'QWEQWEQWE',
  'QWER@1234',
  'QWERTY123',
  'R00T0NEIO',
  'RASPBERRY',
  'REDHAT!@#',
  'REDHAT123',
  'RKQLDK!@#',
  'ROOT!2015',
  'ROOT@2013',
  'ROOT@2014',
  'ROOT@2015',
  'ROOT@2016',
  'ROOT@ROOT',
  'ROOT12345',
  'ROOTADMIN',
  'RST_LOGIN',
  'RUIJIESAM',
  'SERVER123',
  'SHISP.NET',
  'SKYWALKER',
  'SLVIEW!@#',
  'SLVIEW123',
  'SMSPASSWD',
  'SNIPER!@#',
  'STEVEN123',
  'SUNNIWELL',
  'SUPERUSER',
  'SWORDFISH',
  'TEAMSPEAK',
  'TOMCAT123',
  'UBUNTU123',
  'UNIMALLJK',
  'URP@81008',
  'USERN2000',
  'UTCFS2202',
  'VTPIOTTUM',
  'W2X0T1V3M',
  'WANGSU123',
  'WANMEI#@!',
  'WANMEI123',
  'WANWEI_YW',
  'WD#SD7258',
  'WEBMASTER',
  'WINCENTER',
  'WOAILAOPO',
  'WWWSERVER',
  'XIANZI123',
  'YINHE@123',
  'YUN708090',
  'Z1A2Q3!@#',
  'Z3A2Q1!@#',
  'ZABBIX123',
  'ZAQWSXCDE',
  'ZTE@OS+10',
  'ZTEOPENWG',
  'ZXC123!@#',
  'ZXC123456',
  'ZXCASD123',
  'ZXIPTV_13',
  'ZXIPTVSDH',
  'ZXR10!^**',
  'ZXY04@$16',
  '1020304050',
  '1122334455',
  '1123581321',
  '1234567890',
  '1357924680',
  '9876543210',
  '!@#$%^&*()',
  '!@#$%12345',
  '!@#$%54321',
  '!@#$%67890',
  '!Q@W#E$R%T',
  '#XJACE!!$@',
  '%USERNAME%',
  '***WMSK###',
  '@>((&(@$%*',
  '[PASSWORD]',
  '0O9I8U7Y6T',
  '0WLONEWAVE',
  '1!QAZ2@WSX',
  '12345!@#$%',
  '12345%$#@!',
  '12345^&*()',
  '123456@ABC',
  '123456789Q',
  '12345QWERT',
  '12345TREWQ',
  '123MUIE123',
  '1A2B3C4D5E',
  '1Q2W3E4R5T',
  '1Z2X3C4V5B',
  '2000SERVER',
  '2003SERVER',
  '21YSTEN123',
  '27E5CBBF1C',
  '2894721..0',
  '51U5M1GT..',
  '8379526!@#',
  '855674W!@#',
  'A123456789',
  'A1B2C3D4E5',
  'A1S2D3F4G5',
  'ABC@123456',
  'ABC1234567',
  'ABCD123456',
  'ABCDE12345',
  'ADMIN@1234',
  'ADMIN123.0',
  'ADMIN12345',
  'ADMINADMIN',
  'ASDF13579#',
  'ASDFGZXCVB',
  'BELL345#$%',
  'CACTIEZ!@#',
  'CACTIEZ123',
  'CAONIMA123',
  'CENTOS@123',
  'CERNET1234',
  'CGSLZZY123',
  'CHANDRU123',
  'CHANGE_123',
  'CHINACACHE',
  'COMMON@123',
  'CTYUN@321!',
  'DB2FENC123',
  'DBA$FOUNDC',
  'DBPASSWORD',
  'DIANA4EVER',
  'DIFUQING.0',
  'DUZON@1234',
  'ENTERPRISE',
  'ESHORE!!10',
  'ESHORE@123',
  'ESHORE_123',
  'F0.514/*+R',
  'FDFSDFSDFS',
  'FOUNDER123',
  'FTPTEST123',
  'FUCKOFF123',
  'FUCKYOU123',
  'GOOD168DNS',
  'HELLOWORLD',
  'HUANGH15#*',
  'HUAWEI@123',
  'HUAWEI12#$',
  'IDC123&123',
  'IDCSEO2009',
  'ILOVECHINA',
  'INFO!@#123',
  'ISOIDC8800',
  'J2MV9JYYQ6',
  'KWWW123456',
  'LENOVO@123',
  'LINUXLINUX',
  'LOCALADMIN',
  'LOCALHOST~',
  'LONGMEMIMI',
  'MAILSERVER',
  'MANAGEMENT',
  'MANAGER123',
  'MEIYOUMIMA',
  'MENGYU8505',
  'MIMA123&*(',
  'MIMA123456',
  'MINSHAOHUA',
  'MOBILE@123',
  'MOBILE1234',
  'MONGODB123',
  'N2000N2000',
  'N2003N2003',
  'NEWMONITOR',
  'NGINX@2014',
  'NGINX@2015',
  'NGINX@2016',
  'NOPASSWORD',
  'NOWVPS@123',
  'ORACLE@123',
  'ORACLE1234',
  'PA$$W0RD12',
  'PASS@WORD1',
  'PASS123!@#',
  'PASSWD@123',
  'PASSWORD@1',
  'PASSWORD01',
  'PASSWORD1!',
  'PATROL,123',
  'PATROL@123',
  'PGADMIN123',
  'POIUYTREWQ',
  'PORTAL@123',
  'POWER0.123',
  'POWER123.0',
  'PWD@123456',
  'Q1W2E3R4T5',
  'QAZWSXEDC1',
  'QINAIDE520',
  'QINGDAO123',
  'QWER!@#123',
  'QWER123456',
  'QWERT!@#$%',
  'QWERT12345',
  'QWERTYUIOP',
  'QWQWQ@1232',
  'REDHAT@123',
  'ROOT!@#123',
  'ROOT112233',
  'ROOT123!@#',
  'ROOT123456',
  'ROOT654321',
  'ROOTPASSWD',
  'SERVER2000',
  'SERVER2003',
  'SERVER2006',
  'SERVER2007',
  'SERVER2008',
  'SHAJINMENG',
  'SLVIEW@123',
  'SMBUSER123',
  'SSHUSER123',
  'SUPERVISOR',
  'SXDXTEST10',
  'SYNCMASTER',
  'SYSTEM2003',
  'SZNN38E@C2',
  'TENCENT123',
  'TEST123!@#',
  'TEST123456',
  'UNVS.-.57F',
  'VANDIDEI12',
  'VNCUSER123',
  'VTPIORACLE',
  'WANG123456',
  'WANGDANQLL',
  'WANGSU@)!%',
  'WDLINUX.CN',
  'WEBUSER123',
  'WEIWEI12..',
  'WELCOME!@#',
  'WELCOME123',
  'WHATHEFUCK',
  'WOBUZHIDAO',
  'WPBBS189@#',
  'WPTEST189@',
  'XIRANG@123',
  'XK(025)UMA',
  'XK(026)UMA',
  'XYIDC_2006',
  'Z@43H8SVN3',
  'ZABBIXUSER',
  'ZHANQWE123',
  'ZXCVBNM,./',
  'ZXCVBNM123',
  'ZXDBM_ISMP',
  'ZXIPTV_123',
  'ZXIPTV_321',
  '12345678910',
  '!@#ADMIN123',
  '123!@#123OK',
  '12345@QWERT',
  '1234ASDF!@#',
  '123YSTEN!@#',
  '14759179..A',
  '1QAZ2WSX!@#',
  '855674WW!#$',
  '8OWMPIYDDYO',
  'AA123456789',
  'ADMIN@ADMIN',
  'ADMIN123!@#',
  'ADMIN123456',
  'ADMINWEI12#',
  'APAAJABOLEH',
  "ASDFGHJKL;'",
  'ASTERISKFTP',
  'BEIJING2008',
  'CACTIEZ@123',
  'CCCCHINA021',
  'CEC.BGBVRB1',
  'CHANGEME!@#',
  'CHANGEME123',
  'CHINA35.IDC',
  'CHINABT.123',
  'CHINAFUB709',
  'CHINAMOBILE',
  'CHINAUNICOM',
  'DABESTMOUSE',
  'DELOCHI_CDN',
  'DNION!@#$%^',
  'DOION123$%^',
  'EDONGIDC123',
  'ESHORE!@189',
  'FASTWEB@123',
  'FSHG1433..1',
  'FSUNICOM123',
  'FTPTEST@123',
  'HC123456789',
  'HC123456987',
  'HUAWEI@2014',
  'ICTMAPADMIN',
  'IDC1234%^&*',
  'IDC1611!^!!',
  'IDC1Q2W3E4R',
  'IDC2013$#@!',
  'IDRAC!@#123',
  'IDRAC123!@#',
  'IPCONFIG123',
  'IPTVTEST203',
  'JX2015IJANK',
  'MAMC!@#2013',
  'MDN2KHUAWEI',
  'MUIEFRAIERE',
  'N0TH1N9@123',
  'N2000SERVER',
  'N2003SERVER',
  'ORACLE@2014',
  'ORACLE@2015',
  'ORACLE@2016',
  'P@$$W0RD123',
  'P@SSW0RD!@#',
  'P@SSW0RD123',
  'P@SSWORD123',
  'PA$$W0RD123',
  'PASSWORD!@#',
  'PASSWORD01!',
  'PASSWORD123',
  'PDIDC123!@#',
  'POSTGRES123',
  'QAZZXC66@$%',
  'QI!@#!!@..1',
  'QINGDAO@123',
  'QSC!#%ZZIDC',
  'RABBITMQ123',
  'ROOT@123456',
  'ROOT_112233',
  'SECURITY421',
  'SF100769978',
  'SUOWEIDEXD1',
  'SUOWEIDEZD1',
  'TVM1NING!@#',
  'TVMINING!@#',
  'TVMINING123',
  'U_TYWG_2008',
  'VICIDIALNOW',
  'VTPISCMUSER',
  'VTPISQMUSER',
  'VTPISRCUSER',
  'WANGHAO@123',
  'WANGSU@2012',
  'WDS@)!%1984',
  'WEBLOGIC123',
  'WEBUSER@123',
  'WELCOME123!',
  'WMDJWEB~123',
  'WWW.7X24.CN',
  'XIAOCHEN520',
  'YOHUU129!@(',
  'YUNSHAN3302',
  'ZJJINHUAIP1',
  'ZL0PASSW0RD',
  'ZTEOPENWG01',
  'ZTEUSS_IPTV',
  'ZZIDC!@#EWQ',
  '1.23E+11',
  '!@!#$%^&*()1',
  '!@#$%^&*()_+',
  '!@#$QWERASDF',
  '!@#123QWEASD',
  '!@#123QWERTY',
  '!@#QWEASDZXC',
  '!2123#21QASW',
  '!Q@W#E4R5T6Y',
  '!QAZ@WSX#EDC',
  '!QAZ2WSX0OKM',
  '!QAZ2WSX3EDC',
  '%&*)PWRD%)*#',
  '@#$RFVXSWEDC',
  '@DITZU140489',
  '0OKM9IJN8UHB',
  '123!@#ABCABC',
  '123..0!@#WEI',
  '123456!@#$%^',
  '123456%$#@!^',
  '123456QWERTY',
  '123456YTREWQ',
  '1234QWER!@#$',
  '1234QWERASDF',
  '123QWE!@#QWE',
  '123QWEASDZXC',
  '1Q2W3E$R%T^Y',
  '1Q2W3E4R!@#$',
  '1Q2W3E4R5T6Y',
  '1QA2WS3ED4RF',
  '1QAZ#EDC5TGB',
  '1QAZ@WSX#EDC',
  '1QAZ@WSX3EDC',
  '1QAZ@WSX3IDC',
  '1QAZ2WSX#EDC',
  '1QAZ2WSX3EDC',
  '1QAZ3EDC5TGB',
  '1QAZXSW2#EDC',
  '1QAZXSW23EDC',
  '1QWE2ASD3ZXC',
  '34563E4R5T6Y',
  '3EDC4RFV5TGB',
  '4RFV%TGB6YHN',
  '567TYUGHJBNM',
  '8IK,9OL.0P;/',
  'A855206..123',
  'ABCD1234!@#$',
  'ABCD1234%^&*',
  'ADMIN159753+',
  'ADMINSSH SSH',
  'AHDXIPTV_123',
  'AIYA@2015!@#',
  'ALLORNOTHING',
  'BABADELACOLT',
  'BBAARRTT11!!',
  'BF(1234)!@#$',
  'BJCTV2177!@#',
  'BUZHIDAOMIMA',
  'CHANGEME_123',
  'CHENPENG123@',
  'CHINABT.3389',
  'D/5*1-4+,E18',
  'DIGITALCHINA',
  'DNION@123!@#',
  'EDONGIDC1234',
  'ESMEMBERTEST',
  'FASTWEB@1605',
  'FS2010123..1',
  'GFWL.IDC@123',
  'GM4WSEIGEG5Y',
  'HACKQIANGZAI',
  'HUAWEI123456',
  'IPTV1234!@#$',
  'IPTV1234%^&*',
  'IT..2ZN~!FW*',
  'JASSON_MAS08',
  'JIANKONG2013',
  'JINGGANGSHAN',
  'LXT1019325#$',
  'M3*L^V2-P37%',
  'MEDIA@CDNBJ3',
  'MI$UN_S12124',
  'MJU76YHNBGT5',
  'MOBILE123456',
  'NCS-CYBER_TJ',
  'NSC&57711135',
  'OPENDOOR2013',
  'P@$$W0RD@123',
  'P@SSW0RD@123',
  'P@SSW0RD1234',
  'P@SSW0RD2012',
  'P@SSWORD!@#$',
  'PASSW0RD@123',
  'PASSWORD@123',
  'PASSWORD1234',
  'Q!W@E#R4T5Y6',
  'Q1W2E3R4T5Y6',
  'QAZWSX123!@#',
  'QAZWSXEDC!@#',
  'QAZWSXEDCRFV',
  'QISHANG@2015',
  'QQ313994716.',
  'QWE123QWE!@#',
  'QWEASDZXC123',
  'QWEPOIZXC?><',
  'QWEPOIZXC098',
  'QWER!@#$1234',
  'QWER1234!@#$',
  'QWERTY123456',
  'QWERTYUIOP[]',
  'R00TPASSW0RD',
  'RANGLM123456',
  'REDHAT!@#123',
  'REDHAT123!@#',
  'ROOT@LINUXPC',
  'ROOT@STORAGE',
  'ROOTPASSWORD',
  'ROOTROOTROOT',
  'SCYDSWRZ-123',
  'SHANGHAI!@#$',
  'SKY123321456',
  'SQLSAZTESMCC',
  'SX!@#WLK2013',
  'TEMPUS!@#^%$',
  'TEST!Q@W#E$R',
  'TVM1NING$#@!',
  'TVMINING@123',
  'UWAY123@ZBJS',
  'UWAYSOFT2009',
  'VPSSQLMASTER',
  'WWW.10086.CN',
  'XN8866A!#2!#',
  'YUNSHANWENSU',
  'YY^*)+_(#%!!',
  'ZAQ!2WSXCDE#',
  'ZAQ12WSXCDE3',
  'ZAQ1XSW2CDE3',
  'ZTE@IPTV2009',
  'ZXCASDQWE123',
  'ZXCVASDF!@#1',
  '!@#QAZWSX1717',
  '#TE!(*(ST1US#',
  '@@!^@*$OKMNJI',
  '@DM1N1STR@T0R',
  '20058()*()IOP',
  '365OBSSERVER!',
  '88860113++-OK',
  'ADMIN_DEFAULT',
  'ADMINISTRATOR',
  'CHINAIDCW168*',
  'CMNET2010@ZTE',
  'CX_STORE@TEST',
  'DEVER!1201501',
  'DNIONBJCS@)!@',
  'ESHORE!!4TYJZ',
  'ESHORETY!@!@#',
  'ESHORETY!@123',
  'ESHORETY!@126',
  'ESHORETY!@188',
  'ESHORETY!@189',
  'GLTCNMGO10*()',
  'GYG0SD@GYGNBD',
  'HPJHCNC1!2@3#',
  'ICTMAPDEV1502',
  'IDC@#JB123654',
  'JLIAE@EDU2013',
  'LD9%)83HUJWVU',
  'LIQI464866447',
  'LIUTAOCAONIMA',
  'LOVE187329YES',
  'MARCONI123!@#',
  'MYMZT@JSB2015',
  'NATSHELL@2014',
  'NETNUMEN123,.',
  'NIHAOWANMEI,!',
  'NISHIZHU..123',
  'POWEREDGER720',
  'QDWKIDC!@#456',
  'QWASZX12ADMIN',
  'SMGWDATA_USER',
  'TONGLIANXUNDA',
  'UNICOMIPTV~!@',
  'WANGSU2015!@#',
  'WEIWEIDDOS123',
  'WELCOME123!@#',
  'WUHUSIHAIRY10',
  'Y!W)Z@X(8L2)6',
  'YANDIENIBA123',
  'ZHANGYAN7TIAN',
  'ZHUTONGSMSSQS',
  'ZX@USS_135246',
  '$RFVVGY7822542',
  '1Q2W3E4R5T6Y7U',
  '1QAZ2WSX,./!@#',
  '4AADMIN@ZJYD*#',
  'ADMIN_UWAY123.',
  'ASD!@#123!@#$%',
  'BJTTTVM1@*#^%&',
  'CACHE@%*YD0805',
  'DN&@([XNOP))^%',
  'DNIONFLIULIANG',
  'GOFUCKYOURSELF',
  'IDC@ELINK.TEST',
  'ISBOX_YSTEN#@!',
  'JASSONSOFTIM05',
  'JIANK0724JIANK',
  'JSHX-RYDW-1502',
  'JXLIN8()IOPKL;',
  'LIUC201408@@!&',
  'LIUTAOSHINIDIE',
  'P@SSWORD123456',
  'PICO2011SERVER',
  'PICO2013SERVER',
  'PICO2014SERVER',
  'QISHANGZAIXIAN',
  'RZX!@!*BAIZHAO',
  'SB4544DHJSB!@#',
  'SXIDC@12321456',
  'T0TALC0NTR0L4!',
  'TELE@HB3*8=2.1',
  'TJWSDSD!@#AW12',
  'WOW1@SJAQJDNJS',
  'XIAOCHEN445362',
  'YLSK&BA@130TPL',
  'YSTEN@JX!_1230',
  'YUPEI@19871229',
  'ZABBIXPASSWORD',
  'ZJSX2121.01351',
  '!QAZ2WSX#EDCRFV',
  '12345QWERT!@#$%',
  'ADMINISTRATOR12',
  'CZZTE#OS+!^()#(',
  'D!@33Y%(#PIFENG',
  'DN!)13XNOP^$#@1',
  'FEISHUVPS000IDC',
  'IDEPLOY@STORAGE',
  'INFO12321VIANET',
  'MAC.2015***CCDC',
  'OKW500@*/SH2014',
  'OKW500@*/SH2015',
  'ORACLE123ORACLE',
  'QAZ3EDC%TGB7UJM',
  'QWERTASDFGZXCVB',
  'TZJ0904()*()LIN',
  'XIAOCHENG621898',
  'YZZTE#OS+!^()#$',
  'ZTE@USS100ZXM10',
  'ZTSMS@)!%998MTQ',
  '!QAZ2WSX#EDC4RFV',
  '%&*)%)*#^$*^PWRD',
  '1QAZ@WSX3EDC$RFV',
  '4RFV3EDC2WSX1QAZ',
  'ADMINISTRATOR!@#',
  'ADMINISTRATOR123',
  'ADMINISTRATOR389',
  'CHINACACHE123!@#',
  'FSHSJ855206..123',
  'GTIG_NEWTECH**##',
  'I!@33Y#&)PTAOMIE',
  'JASSONSOFT_MAS08',
  'JIAXUENET_201507',
  'O!@33Y#$@PFODDER',
  'QQ549584JINGWA*.',
  'QQ549584ZHOUQIN.',
  'ROOTROOTROOTROOT',
  'TEST_130329_1101',
  'WEIWEI@123!@#..1',
  'ZAQ1XSW2CDE3VFR4'
]
export default {
  /*
   * pwd 密码
   * account 账号
   * 密码里面不能包含账号
   * */
  checkPassword(pwd, account) {
    function checkWeakPwd(pwd) {
      if (pwd == null || trim(pwd).length == 0) {
        return false
      }
      for (var i = 0; i < weakPwd.length; i++) {
        if (
          weakPwd[i] == pwd.toUpperCase() ||
          pwd.toUpperCase().indexOf(weakPwd[i]) >= 0
        ) {
          Notification.error({
            title: '温馨提示 ~',
            message: '不支持网络常见弱密码',
            duration: 3000
          })
          return false
        }
      }
      return true
    }

    // 基本判断函数
    function trim(v) {
      return v.replace(/^\s+|\s+$/g, '')
    }

    function isEmail(pwd) {
      var myreg = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/
      let result = myreg.test(pwd)
      if (result) {
        Notification.error({
          title: '温馨提示 ~',
          message: `密码中不能包含邮箱账号`,
          duration: 3000
        })
      }
      return !result
    }

    function isChinese(pwd) {
      var reg = new RegExp('[\\u4E00-\\u9FFF]+', 'g')
      if (reg.test(pwd)) {
        Notification.error({
          title: '温馨提示 ~',
          message: `密码中不能包含汉字`,
          duration: 3000
        })
        return false
      }
      return true
    }

    function isEqual(v1, v2) {
      return v1 == v2
    }

    function isEmpty(v) {
      return trim(v).length == 0
    }

    function isSame(v) {
      var len = v.length - 1
      return new RegExp('^(.)\\1{' + len + '}$', 'ig').test(v)
    }

    function getLength(v) {
      // 获取字符长度，1个全角字符长度为2
      var len = v.length
      var arr = v.match(/[^\x00-\x80]/gi) // 匹配全角字符
      if (arr != null) {
        len += arr.length
      }
      return len
    }

    function checkLength(v, min, max) {
      if (isEmpty(v)) {
        return false
      }
      var len = getLength(v)
      if (!(len >= min && len <= max)) {
        Notification.error({
          title: '温馨提示 ~',
          message: `密码长度为${min}到${max}位`,
          duration: 3000
        })
      }
      return len >= min && len <= max
    }

    function checkCombination(pwd) {
      if (pwd == null || isEmpty(pwd)) {
        return false
      }
      var isNum = false
      var isUpper = false
      var isLower = false
      var isSpecialchar = false
      for (var i = 0; i < pwd.length; i++) {
        var c = pwd.charCodeAt(i)
        if (isNum && isUpper && isLower && isSpecialchar) {
          break
        }
        if (c >= 65 && c <= 90) {
          isUpper = true
          continue
        }
        if (c >= 48 && c <= 57) {
          isNum = true
          continue
        }
        if (c >= 97 && c < 122) {
          isLower = true
          continue
        }
        if (
          (c >= 33 && c <= 47) ||
          (c >= 58 && c <= 64) ||
          (c >= 91 && c <= 96) ||
          (c >= 123 && c <= 127)
        ) {
          isSpecialchar = true
          continue
        }
      }
      // 密码不符合包括数字、小写字母、大写字母、特殊符号4类中至少3类
      if (!(isNum && isUpper && isLower && isSpecialchar)) {
        Notification.error({
          title: '温馨提示 ~',
          message: `密码不符合包括数字、小写字母、大写字母、特殊符号4类中至少3类`,
          duration: 3000
        })
        return false
      }
      return true
    }

    function checkContainAccount(pwd, account) {
      if (account != null && !isEmpty(account)) {
        if (pwd == null || isEmpty(pwd)) {
          return false
        }
        var pwdUppers = trim(pwd).toUpperCase()
        var accountTmp = trim(account).toUpperCase()
        var accountTmpReverse = accountTmp.split('').reverse().join('')
        if (pwdUppers.indexOf(accountTmp) >= 0) {
          Notification.error({
            title: '温馨提示 ~',
            message: `密码中不能包含账号`,
            duration: 3000
          })
          return false
        }
        if (pwdUppers.indexOf(accountTmpReverse) >= 0) {
          Notification.error({
            title: '温馨提示 ~',
            message: `密码中不能包含账号`,
            duration: 3000
          })
          return false
        }
      }
      return true
    }

    function checkRepeat(pwd) {
      if (pwd == null || isEmpty(pwd)) {
        return false
      }
      for (var n = 0; n < pwd.length - 2; n++) {
        // 3个以上连续重复字符
        if (
          pwd.charAt(n) == pwd.charAt(n + 1) &&
          pwd.charAt(n + 1) == pwd.charAt(n + 2)
        ) {
          return false
        }
      }
      return true
    }

    function checkSequence(pwd) {
      if (pwd == null || isEmpty(pwd)) {
        return false
      }
      var pwdUppers = pwd.toUpperCase()
      var kbs_2_1 = '`1234567890-='
      var kbs_2_1_r = kbs_2_1.split('').reverse().join('')
      var kbs_2_2 = 'QWERTYUIOP[]\\'
      var kbs_2_2_r = kbs_2_2.split('').reverse().join('')
      var kbs_2_3 = "ASDFGHJKL;'"
      var kbs_2_3_r = kbs_2_3.split('').reverse().join('')
      var kbs_2_4 = 'ZXCVBNM,./'
      var kbs_2_4_r = kbs_2_4.split('').reverse().join('')
      var kbs_3_1 = '~!@#$%^&*()_+'
      var kbs_3_1_r = kbs_3_1.split('').reverse().join('')
      var kbs_3_2 = 'QWERTYUIOP{}|'
      var kbs_3_2_r = kbs_3_2.split('').reverse().join('')
      var kbs_3_3 = 'ASDFGHJKL:"'
      var kbs_3_3_r = kbs_3_3.split('').reverse().join('')
      var kbs_3_4 = 'ZXCVBNM<>?'
      var kbs_3_4_r = kbs_3_4.split('').reverse().join('')
      var alpha_order_1 = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
      var alpha_order_1_r = alpha_order_1.split('').reverse().join('')

      for (var n = 0; n < pwdUppers.length - 2; n++) {
        var s =
          pwdUppers.charAt(n) +
          pwdUppers.charAt(n + 1) +
          pwdUppers.charAt(n + 2)
        // 3个以上英文字母顺序字符
        if (alpha_order_1.indexOf(s) >= 0 || alpha_order_1_r.indexOf(s) >= 0) {
          return false
        }
        // 键盘三个连续字符的匹配(横向、大小写、shift)
        if (
          kbs_2_1.indexOf(s) >= 0 ||
          kbs_2_2.indexOf(s) >= 0 ||
          kbs_2_3.indexOf(s) >= 0 ||
          kbs_2_4.indexOf(s) >= 0 ||
          kbs_3_1.indexOf(s) >= 0 ||
          kbs_3_2.indexOf(s) >= 0 ||
          kbs_3_3.indexOf(s) >= 0 ||
          kbs_3_4.indexOf(s) >= 0 ||
          kbs_2_1_r.indexOf(s) >= 0 ||
          kbs_2_2_r.indexOf(s) >= 0 ||
          kbs_2_3_r.indexOf(s) >= 0 ||
          kbs_2_4_r.indexOf(s) >= 0 ||
          kbs_3_1_r.indexOf(s) >= 0 ||
          kbs_3_2_r.indexOf(s) >= 0 ||
          kbs_3_3_r.indexOf(s) >= 0 ||
          kbs_3_4_r.indexOf(s) >= 0
        ) {
          Notification.error({
            title: '温馨提示 ~',
            message: `不能为键盘横向连续排序（连续3个或以上）`,
            duration: 3000
          })
          return false
        }
      }
      return true
    }

    return (
      checkWeakPwd(pwd) &&
      isEmail(pwd) &&
      checkSequence(pwd) &&
      checkContainAccount(pwd, account) &&
      checkLength(pwd, 8, 16) &&
      checkCombination(pwd) &&
      isChinese(pwd)
    )
  }
}
