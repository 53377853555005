<!-- 新增电子围栏 -->
<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    class="dialog_clsss"
    top="6vh"
    custom-class="w-[1200px]"
  >
    <div class="c_fff">
      <div class="pos_r dialog_header">
        <span>电子围栏</span>
        <div class="imgPictureChange clickBox closeBox" @click="onClose">
          <svg-icon
            :svg-name="'delete_hover'"
            class-name="!w-[26px] !h-[26px]"
          />
          <svg-icon :svg-name="'close'" class-name="!w-[26px] !h-[26px]" />
        </div>
      </div>
      <div class="dialog_body">
        <el-form
          :model="form"
          :rules="rules"
          ref="form"
          label-width="0px"
          class="demo-ruleForm"
        >
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item prop="railName">
                <div class="requiredTitle">电子围栏名称<i>*</i></div>
                <el-input
                  v-model="form.railName"
                  placeholder="请输入电子围栏名称"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item class="m-0">
                <div class="requiredTitle">电子围栏范围<i>*</i></div>
                <div class="pos_r r-f h_50">
                  <div style="z-index: 1">
                    <mapAearchPlace
                      v-if="map && (map.id || map.keyboard)"
                      :map="map"
                      class="w-[490px]"
                      ref="mapAearchPlace"
                    ></mapAearchPlace>
                  </div>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item class="m-0 pl-[20px]">
                <div class="requiredTitle">绘制方式<i>*</i></div>
                <div class="flex_vertically_center form-item">
                  <el-select
                    @change="tipsFn"
                    v-model="mouseToolName"
                    placeholder="请选择绘制方式"
                  >
                    <el-option label="定点范围" value="fixedPoint"></el-option>
                    <el-option label="自定圆形" value="circle"></el-option>
                    <el-option label="自定多边形" value="polygon"></el-option>
                  </el-select>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="3" v-if="mouseToolName === 'fixedPoint'">
              <el-form-item class="m-0">
                <div class="requiredTitle">范围距离<i>*</i></div>
                <div class="flex_vertically_center form-item">
                  <el-input
                    v-model="radius"
                    maxlength="6"
                    placeholder="单位米"
                    clearable
                  ></el-input>
                  <span class="fs_14 ml_10 c_9DA7B2 flex-shrink">M</span>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item class="m-0">
                <div class="requiredTitle">操作按钮</div>
                <div class="flex_vertically_center form-item">
                  <el-button
                    size="small"
                    class="button_function"
                    @click="drawPolygon"
                    >开始绘制</el-button
                  >
                  <el-button size="small" class="button_delete" @click="clear"
                    >清空绘制</el-button
                  >
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item>
                <noPermissionsMap
                  class="!h-[400px] rounded"
                  v-if="noPermissionsMapType"
                ></noPermissionsMap>
                <div
                  v-else
                  ref="addElectronicFenceContainer"
                  style="z-index: 0"
                  id="addElectronicFence_container"
                ></div>
                <div class="fs_12 c_9DA7B2 flex_vertically_center">
                  <i class="el-icon-warning-outline fs_14"></i>
                  点击开始绘制后：
                  <span class="c_theme">{{ tips }}</span>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="tar mt_10">
          <el-button
            size="small"
            class="button_default buttonBox"
            @click="onClose"
            >关 闭</el-button
          >
          <el-button
            size="small"
            class="button_function buttonBox"
            @click="throttle(onSubmit, 500)()"
            >保 存</el-button
          >
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script type="text/ecmascript-6">
import addElectronicFence from './js/index.js'
export default {
  mixins: [addElectronicFence],
  destroyed () {
    if (this.map && this.map.destroy) {
      this.map.destroy()
    }
  },
  data () {
    return {}
  },
  components: {
  },
  methods: {
  }
}
</script>
<style scoped lang="less" rel="stylesheet/less">
#addElectronicFence_container {
  width: 100%;
  height: 400px;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid var(--border);
  box-shadow: var(--box-shadow);
  margin-top: 10px;
}
.form-item {
  height: 44px;
}
</style>
