/* 全局地图配置 */
const state = {
  mapSettingInfo: {
    newFaceAlertReminder: 1, // 1 新消息弹窗警报 2新消息不警报
    newVehicleAlertReminder: 1, // 1 新消息弹窗警报 2新消息不警报
    satelliteMap: 1 // 图层展示
  }
}

/* =================================== 获取消息（getters） =================================== */
const getters = {
  mapSettingInfo(state) {
    return state.mapSettingInfo || {}
  },
  /* 新的人脸报警 */
  newFaceAlertReminder(state) {
    return state.mapSettingInfo.newFaceAlertReminder || 1
  },
  /* 新的车牌报警 */
  newVehicleAlertReminder(state) {
    return state.mapSettingInfo.newVehicleAlertReminder || 1
  },
  satelliteMap(state) {
    return state.mapSettingInfo.satelliteMap
  }
}

const mutations = {
  mapSettingInfoFn(state, form) {
    state.mapSettingInfo = form
  },
  mapSettingsFn(state, obj) {
    state.mapSettingInfo[obj.name] = obj.content
  }
}
const actions = {}

export default {
  state,
  getters,
  mutations,
  actions
}
