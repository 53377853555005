/* 导出下载文件 */
export default {
  methods: {
    blobDownloadFn(res, name) {
      return new Promise((resolve, reject) => {
        const today = this.formatTimeFn(new Date(), 'Y-M-h D:m:s')
        const blob = res
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = function (ev) {
          resolve()
          if (window.navigator.msSaveOrOpenBlob) {
            // 兼容ie11
            const blobObject = new Blob([blob])
            window.navigator.msSaveOrOpenBlob(
              blobObject,
              `${name}_${today}.xls`
            )
          } else {
            const url = URL.createObjectURL(new Blob([blob]))
            const a = document.createElement('a')
            document.body.appendChild(a) // 此处增加了将创建的添加到body当中
            a.href = url
            a.download = `${name}_${today}.xls`
            a.target = '_blank'
            a.click()
            a.remove() // 将a标签移除
          }
        }
      })
    },
    //  自定义参数请求
    customExportFileFn(name, route, getParameters = {}) {
      return new Promise((resolve, reject) => {
        let url = ''
        for (const k in getParameters) {
          const content = getParameters[`${k}`]
          if (content || content === 0) {
            url += `${k}=${content}&`
          }
        }
        url = url.substring(url.length - 1, 0)
        this.$api.exportExcel
          .exportDailyCheckReport(`/exportExcel/${route}?${url}`)
          .then((res) => {
            this.blobDownloadFn(res, name).then(() => {
              resolve()
            })
          })
      })
    },
    customExportFilePostFn(name, route, getParameters = {}) {
      return new Promise((resolve, reject) => {
        this.$api.exportExcel
          .exportDailyCheckReportPost(`/exportExcel/${route}`, getParameters)
          .then((res) => {
            this.blobDownloadFn(res, name).then(() => {
              resolve()
            })
          })
      })
    },
    //  需要带上表格参数请求
    exportFileFn(name, route) {
      return new Promise((resolve, reject) => {
        let getParameters = this.$refs.AppTable.getParametersFn()
        if (!(getParameters.queryStartDtm || getParameters.queryEndDtm)) {
          return this.$message.error('请选择时间')
        }
        let url = ''
        getParameters = {
          ...getParameters,
          ...this.requestParams
        }
        for (const k in getParameters) {
          const content = getParameters[`${k}`]
          if (content || content === 0) {
            url += `${k}=${content}&`
          }
        }
        url = url.substring(url.length - 1, 0)
        this.$api.exportExcel
          .exportDailyCheckReport(`/exportExcel/${route}?${url}`)
          .then((res) => {
            this.blobDownloadFn(res, name).then(() => {
              resolve()
            })
          })
      })
    },
    //  不需要其他参数 （直接下载）
    directDownloadFn(url, fileName) {
      return new Promise((resolve, reject) => {
        this.$message.warning('开始下载')
        this.$api.exportExcel.exportDailyCheckReport(url).then((res) => {
          this.blobDownloadFn(res, fileName).then(() => {
            resolve()
          })
        })
      })
    },
    //  不需要其他参数 (获取文件地址后，在下载)
    obtainUrlDownloadingFn(url, fileName) {
      this.$message.warning('开始下载')
      const today = this.formatTimeFn(new Date(), 'Y-M-h D:m:s')
      this.$api.exportExcel.exportExcelFn(url).then((res) => {
        if (res.code === 200) {
          const url = res.data
          const xhr = new XMLHttpRequest()
          xhr.open('GET', url)
          xhr.responseType = 'blob'
          xhr.onload = function () {
            const blob = xhr.response
            const reader = new FileReader()
            reader.readAsDataURL(blob)
            reader.onload = () => {
              if (window.navigator.msSaveOrOpenBlob) {
                // 兼容ie11
                const blobObject = new Blob([blob])
                window.navigator.msSaveOrOpenBlob(
                  blobObject,
                  `${fileName}_${today}.xls`
                )
              } else {
                const url = URL.createObjectURL(new Blob([blob]))
                const a = document.createElement('a')
                document.body.appendChild(a) // 此处增加了将创建的添加到body当中
                a.href = url
                a.download = `${fileName}_${today}.xls`
                a.target = '_blank'
                a.click()
                a.remove() // 将a标签移除
              }
            }
          }
          xhr.onerror = function () {
            console.error('could not download file')
          }
          xhr.send()
        }
      })
    }
  }
}
