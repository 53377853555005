import { mapGetters } from 'vuex'
import elDragDialog from '@components/el-drag-dialog'
import { flatMap } from 'lodash'
const selectDevice = () => import('@components/device/selectDevice')
const talkbackDetail = () => import('@components/mediaCenter/talkbackDetail')
const {
  sdkParams
} = require(`@/version/${process.env.VUE_APP_BUILD_VERION}.js`)
export default {
  props: {
    // 是否默认展示页面
    defaultDisplayPage: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    /* 空数据时显示的文本内容 */
    emptyText: {
      type: String,
      default: () => {
        return '等 待 创 建 房 间'
      }
    },
    /* 大屏设备一行数量
     *  布局是 24栅格布局，只要是 24 可以除的整数就可以 ``1,2,3,4,6,8``
     *  */
    largeScreenNumberOfLines: {
      type: Number,
      default: () => {
        return 3
      }
    },
    /* 小屏设备一行数量
     *  布局是 24栅格布局，只要是 24 可以除的整数就可以 ``1,2,3,4,6,8``
     * */
    smallScreenNumberOfLines: {
      type: Number,
      default: () => {
        return 3
      }
    },
    /* 是否需要关闭设备操作
     *  小屏幕布局无法使用
     *
     *  大屏幕布局有单独按钮显示
     * */
    closeDeviceType: {
      type: Boolean,
      default: () => {
        return true
      }
    },
    /* 是否展示邀请设备板块
     * 展示后，点击邀请会触发 @on-intercomInviteDevice 回调
     *  */
    showInvitationDevice: {
      type: Boolean,
      default: () => {
        return true
      }
    }
  },
  data() {
    return {
      mediaRoomMemberList: [],
      audioProducerBrokenType: false, // 抢呼状态
      componentsName: null,
      tabsNavigationType: 1,
      roomStatusList: [], // 房间状态列表
      drawer: false,
      opacity: 1,
      hideType: false,
      opacityTimer: null,
      delayedSpeech: null, // 延迟讲话
      /* 倒计时 */
      countDownTime: null,
      countDown: 60,
      dragLevel: 0,
      allForbiddenSounds: false,
      dialogTableVisible: false,
      clientId: null,
      currentEquipment: {}, // 当前设备
      clientIdList: [], // 群组设备列表
      inPlaceClientIdList: [], // 群组设备列表
      roomId: null,
      /* 群组消息 */
      orgGroupInsertTemp: {},
      /* 视图显示模式 */
      viewDisplayMode: 1,
      keyType: null,
      valid: true,
      currentSpeech: null,
      /* 对讲日志列表 */
      orgGroupIntercomList: [],
      intercomloTiming: null,
      microphoneType: false,
      audioContextType: false
    }
  },
  directives: { elDragDialog },
  watch: {
    audioContextType: {
      handler() {
        if (!this.audioContextType) {
          this.keyType = null
        }
      },
      deep: true
    },
    getCurrentSpeech: {
      handler(item) {
        this.setCurrentSpeech(item)
      },
      deep: true
    }
  },
  components: {
    selectDevice,
    talkbackDetail
  },
  computed: {
    getCurrentSpeech() {
      return this.clientIdList.find((v) => v.audioContextType)
    },
    ...mapGetters(['userInfo', 'alarmRecordOperatorType'])
  },
  mounted() {
    this.componentsName = this.createUniqueId(1)
  },
  methods: {
    // 设置正在讲话
    setCurrentSpeech(item) {
      this.currentSpeech = item
      this.$emit('on-setCurrentSpeech', {
        id: this.orgGroupInsertTemp.id,
        groupName: this.orgGroupInsertTemp.groupName,
        serialNum: this.orgGroupInsertTemp.serialNum,
        groupNature: this.orgGroupInsertTemp.groupNature,
        item: item || null
      })
    },
    // 麦克风限制
    setMicrophoneType(type) {
      const microphoneType =
        typeof type === 'boolean' ? type : !this.microphoneType
      if (microphoneType) {
        this.audioConsumerPaused()
      }
      this.microphoneType = microphoneType
      this.$nextTick(() => {
        this.$emit('on-methodChange', {
          name: 'setMicrophoneType',
          res: this.microphoneType
        })
      })
    },
    closeTheRoom(deviceList = [], judgmentPageStatus = false) {
      /*
       * judgmentPageStatus 页面状态判断
       * */
      if (!this.roomId) {
        return
      }
      if (judgmentPageStatus && this.dialogTableVisible) {
        return
      }
      return new Promise((resolve, reject) => {
        if (deviceList.length && this.roomId) {
          const deviceIdList = deviceList.map((v) => v.deviceId)
          this.$api.media
            .groupCloseDevice({
              groupId: this.orgGroupInsertTemp.id,
              deviceIdList
            })
            .then((res) => {
              if (res.code === 200) {
                resolve()
                this.$message.success('设备退出群组')
              }
            })
        } else {
          reject(new Error('deviceList 参数有误'))
        }
      })
    },
    roomStatusListFn(obj) {
      obj.time = this.formatTimeFn(new Date(), 'D:m:s')
      this.roomStatusList.unshift(obj)
    },
    tabsNavigationTypeFn(type) {
      this.tabsNavigationType = type
    },
    /* 显示隐藏 */
    showLayerFn() {
      this.hideType = false
      this.opacity = 1
      this.setDragLevel()
      this.$emit('on-showLayerFn')
    },
    /* 缩小到下方 */
    hideLayersFn() {
      this.opacity = 0
      this.hideType = true
      this.$emit('on-hideLayerListFn', {
        title: '多人对讲',
        componentsName: 'multiPersonIntercom'
      })
    },
    /* 打开音频文件 */
    clickGroupItem(curItem) {
      if (this.mainStore) {
        this.mainStore.commit('callComponent', {
          name: 'filePlayback',
          data: {
            methodName: 'show',
            params: {
              curItem,
              lowestLevel: true,
              playList: this.orgGroupIntercomList,
              curMediaType: 2
            }
          }
        })
      } else {
        this.$store.commit('callComponent', {
          name: 'filePlayback',
          data: {
            methodName: 'show',
            params: {
              curItem,
              lowestLevel: true,
              playList: this.orgGroupIntercomList,
              curMediaType: 2
            }
          }
        })
      }
    },
    // 对讲日志
    orgGroupIntercomFn() {
      if (this.intercomloTiming) {
        clearInterval(this.intercomloTiming)
        this.intercomloTiming = null
      }
      const getData = async () => {
        const { code, data = {} } = await this.$api.page(
          'mediaFile/orgGroup/intercom',
          {
            page: 0,
            groupId: this.orgGroupInsertTemp.id
          }
        )
        if (code === 200) {
          this.orgGroupIntercomList = data.content || []
        }
      }
      this.intercomloTiming = setInterval(() => {
        getData()
      }, 5000)
    },
    /* 临时群组转正式群组 */
    temp2Nature() {
      this.$api.orgGroup.temp2Nature(this.orgGroupInsertTemp.id).then((res) => {
        if (res.code === 200) {
          this.$message.success('操作成功')
          this.orgGroupInsertTemp = res.data
        }
      })
    },
    setCountDown(countDown) {
      this.countDown = countDown
      this.$emit('on-countDown', countDown)
      this.$emit('on-methodChange', {
        name: 'countDown',
        res: countDown
      })
    },
    countDownFn(type) {
      if (this.delayedSpeech) {
        clearTimeout(this.delayedSpeech)
      }
      if (type === 1) {
        if (this.countDownTime) {
          this.audioConsumerPaused()
        }
        const TIME_COUNT = 60
        this.setCountDown(59)
        if (this.countDownTime) {
          clearInterval(this.countDownTime)
        }
        this.countDownTime = setInterval(() => {
          if (this.countDown > 0 && this.countDown <= TIME_COUNT) {
            this.setCountDown(this.countDown - 1)
          } else {
            this.audioConsumerPaused()
          }
        }, 1000)
      } else {
        if (this.countDownTime) {
          clearInterval(this.countDownTime)
          this.setCountDown(60)
          this.countDownTime = null
        }
      }
    },
    closeDrawer() {
      if (this.$refs.drawer) {
        this.$refs.drawer.closeDrawer()
      }
      this.drawer = false
    },
    talkbackDetailShow() {
      if (this.orgGroupInsertTemp.groupNature === 1) {
        if (this.orgGroupInsertTemp.openRecording === 1) {
          this.drawer = true
        } else {
          this.$message.error('当前群组录制功能未开放')
        }
      } else {
        this.$message.error('临时群组录制功能未开放')
      }
    },
    roomDeviceNumFn() {
      const num = this.inPlaceClientIdList.filter(
        (v) =>
          v.methodType === 'successfulCall' || v.methodType === 'joinTheRoom'
      ).length
      return this.clientIdList.length - num
    },
    /* 在线离线统计 */
    onlineOfflineStatistics(type) {
      return this.clientIdList.filter((v) => v.onlineState === type).length
    },
    setAllForbiddenSounds(type) {
      this.allForbiddenSounds =
        typeof type === 'boolean' ? type : !this.allForbiddenSounds
      this.$nextTick(() => {
        this.$emit('on-methodChange', {
          name: 'setAllForbiddenSounds',
          res: this.allForbiddenSounds
        })
        this.clientIdList.forEach((item) => {
          const label = document.getElementById(
            `${item.deviceId}${this.roomId}`
          )
          if (label) {
            this.$set(item, 'forbiddenSound', this.allForbiddenSounds)
            label.muted = this.allForbiddenSounds
          }
        })
      })
    },
    forbiddenSound(item, muted) {
      // console.warn('label', `${item.deviceId}${this.roomId}`)
      const label = document.getElementById(`${item.deviceId}${this.roomId}`)
      console.warn('label', muted)
      if (label) {
        if (typeof muted === 'boolean') {
          this.$set(item, 'forbiddenSound', muted)
          label.muted = muted
        } else {
          if (!item.forbiddenSound) {
            this.$set(item, 'forbiddenSound', true)
            label.muted = true
          } else {
            this.$set(item, 'forbiddenSound', false)
            label.muted = false
          }
        }
      }
    },
    /* 关闭选择设备弹窗 */
    selectDeviceClosePopup() {
      if (this.$refs.selectDevice) {
        this.$refs.selectDevice.closePopup()
      }
    },
    /* 选择设备成功回调 */
    selectDeviceSuccessCallback(clientIdList) {
      const offLineList = [] // 离线设备统计
      const notSupportedList = [] // 不支持设备统计
      const inIntercomList = [] // 在别处对讲中
      const deviceList = [] // 可以对接设备
      clientIdList.forEach((device) => {
        if (device.onlineState !== 1) {
          offLineList.push(device)
        } else if (device.intercomSupport === 0) {
          notSupportedList.push(device)
        } else if (device.intercomGroupTem === 1) {
          inIntercomList.push(device)
        } else {
          deviceList.push(device)
        }
      })
      let message = ''
      if (offLineList.length) {
        message += `<div class="lh_20">有${offLineList.length}台设备不在线无法发起对讲</div>`
      }
      if (notSupportedList.length) {
        message += `<div class="lh_20">有${notSupportedList.length}台设备不支持对讲</div>`
      }
      if (inIntercomList.length) {
        message += `<div class="lh_20">有${inIntercomList.length}台设备已处于临时群组中</div>`
      }
      if (message.length) {
        this.$message.error({
          duration: 8000,
          dangerouslyUseHTMLString: true,
          message: message
        })
      }
      this.$nextTick(() => {
        if (deviceList.length) {
          this.intercomInviteDevice(deviceList.map((v) => v.deviceId))
        }
      })
    },
    /* 打开选择设备弹窗 */
    selectDeviceShow() {
      // 房间内的人不在邀请
      const disableSelection = [...this.clientIdList]
      this.$emit('on-selectDeviceShow', disableSelection)
      if (!this.$refs.selectDevice) return
      this.$refs.selectDevice.show(
        {
          disableSelection
        },
        (clientIdList) => {
          this.selectDeviceSuccessCallback(clientIdList)
        }
      )
    },
    viewDisplayModeFn(type) {
      this.viewDisplayMode = type
      this.$emit('on-methodChange', {
        name: 'viewDisplayModeFn',
        res: type
      })
    },
    setAudioContextType(audioContextType) {
      this.audioContextType = audioContextType
    },
    audioConsumerMouseout() {
      if (this.keyType !== 'onkeydown' && this.audioContextType) {
        this.audioConsumerPaused()
      }
    },
    audioContextTypeFn() {
      if (!this.roomId) return
      if (this.microphoneType) return
      if (this.audioContextType) {
        this.audioConsumerPaused()
      } else {
        this.audioConsumerResume()
      }
    },
    // 暂停producer推送
    audioConsumerPaused() {
      if (!this.roomId) return
      // if (this.microphoneType) return
      if (this.delayedSpeech) {
        clearTimeout(this.delayedSpeech)
        this.delayedSpeech = null
      }
      if (!this.audioContextType) return
      const This = this
      const successCallback = (res) => {
        This.audioConsumerPaused_success(res)
      }
      const failCallback = (res) => {
        This.audioConsumerPaused_fail(res)
      }
      console.warn(`audioConsumerPaused: ${this.roomId}`)
      this.$sdk.audioConsumerPaused({
        roomId: this.roomId,
        successCallback,
        failCallback
      })
    },
    audioConsumerPaused_success(res) {
      this.roomStatusListFn({
        name: '管理者',
        self: true,
        methodType: 'audioConsumerPaused'
      })
      this.countDownFn(2)
      this.setAudioContextType(false)
      console.warn(`audioConsumerPaused 成功`)
      this.$emit('on-methodChange', {
        name: 'audioConsumerPaused_success',
        res
      })
    },
    audioConsumerPaused_fail(res) {
      if (res && res.msg) {
        console.error(`audioConsumerPaused_${res.msg}`)
      }
      this.$message.error(
        `${this.orgGroupInsertTemp.groupName}: 停止麦克风失败`
      )
      this.setAudioContextType(false)
      console.warn(`audioConsumerPaused 失败`, res)
      this.$emit('on-methodChange', {
        name: 'audioConsumerPaused_fail',
        res
      })
    },
    // 恢复producer推送
    audioConsumerResume(delayTime = 500) {
      if (!this.roomId) return
      if (this.microphoneType) return
      if (this.delayedSpeech) {
        clearTimeout(this.delayedSpeech)
        this.delayedSpeech = null
      }
      if (this.audioContextType) return
      const This = this
      const successCallback = (res) => {
        This.audioConsumerResume_success(res)
      }
      const failCallback = (res) => {
        This.audioConsumerResume_fail(res)
      }
      this.delayedSpeech = setTimeout(
        () => {
          console.warn(`audioConsumerResume: ${this.roomId}`)
          this.$sdk.audioConsumerResume({
            roomId: this.roomId,
            successCallback,
            failCallback
          })
        },
        typeof delayTime === 'number' ? delayTime : 500
      )
    },
    audioConsumerResume_success(res) {
      this.roomStatusListFn({
        name: '管理者',
        self: true,
        methodType: 'audioConsumerResume'
      })
      this.countDownFn(1)
      this.setAudioContextType(true)
      this.$emit('on-methodChange', {
        name: 'audioConsumerResume_success',
        res
      })
    },
    audioConsumerResume_fail(res) {
      if (res && res.msg) {
        console.error(`audioConsumerPaused_${res.msg}`)
      }
      this.$message.error(
        `${this.orgGroupInsertTemp.groupName}: 开始麦克风失败`
      )
      this.countDownFn(2)
      this.setAudioContextType(false)
      this.$emit('on-methodChange', {
        name: 'audioConsumerResume_fail',
        res
      })
    },
    onClose() {
      if (this.intercomloTiming) {
        clearInterval(this.intercomloTiming)
        this.intercomloTiming = null
      }
      this.$store.commit('sdkCallCallbackObject', {
        name: 'orgGroupIdFn',
        res: null
      })
      document.onkeyup = () => {}
      document.onkeydown = () => {}
      this.countDownFn(2)
      this.setAudioContextType(false)
      if (this.roomId) {
        this.$sdk.exitRoomFn(this.roomId)
      }
      let methodType = true
      this.clientIdList.forEach((v) => {
        if (!v.methodTypeV2) {
          methodType = false
        }
      })
      this.$nextTick(() => {
        this.$emit('on-close', {
          type: methodType,
          list: [...this.clientIdList]
        })
        this.$emit('on-methodChange', {
          name: 'onClose',
          res: {
            roomId: this.roomId,
            clientId: this.clientId,
            data: { ...this.orgGroupInsertTemp }
          }
        })
        this.$nextTick(() => {
          this.$business.deleteSceneDevice(this.componentsName)
          this.selectDeviceClosePopup()
          this.device = {}
          this.clientIdList = []
          if (this.$refs.drawer) {
            this.$refs.drawer.closeDrawer()
          }
          this.roomId = null
          this.drawer = false
          this.setDialogVisible(false)
        })
      })
    },
    // 设置弹窗展示
    setDialogVisible(type) {
      //  if (this.dialogVisible === type) return
      if (type) {
        this.setDragLevel()
      }
      this.dialogTableVisible = type
    },
    // 设置弹窗层级
    setDragLevel() {
      if (this.mainStore) {
        this.dragLevel = this.mainStore.getters.dragLevel
        this.mainStore.commit('setDragLevel')
      } else {
        this.dragLevel = this.$store.getters.dragLevel
        this.$store.commit('setDragLevel')
      }
    },
    // 被抢断
    audioProducerBroken(clientIdItem) {
      this.$message.error(
        `${this.orgGroupInsertTemp.groupName}: 麦克风被${
          clientIdItem.name || '使用者'
        }抢断`
      )
      this.audioConsumerPaused()
      this.roomStatusListFn({
        name: '管理者',
        self: true,
        content: `抢断者: ${clientIdItem.name || '使用者'}`,
        methodType: 'audioProducerBroken'
      })
      this.$store.commit('sdkCallCallbackObject', {
        name: 'examine_audioContextType',
        res: false
      })
    },
    /* 设备加入房间 */
    deviceJoinRoomFn(res) {
      const status = res.status
      if (status === 'audioProducerBroken') {
        this.audioProducerBrokenType = true
        return
      }
      if (res.clientIdList.length) {
        const resClientIdList = res.clientIdList.filter((v) => {
          return v.clientId
        })
        if (resClientIdList.length) {
          this.inPlaceClientIdList = [...resClientIdList]
          this.inPlaceClientIdList.forEach((v) => {
            const clientIdIndex = this.clientIdList.findIndex(
              (j) => j.deviceId === v.clientId
            )
            if (clientIdIndex !== -1) {
              /* 设备在别处对剑的时候，提示 */
              if (v.errorCode === 701) {
                // v.errorReason = '未进组'
              }
              const clientIdItem = this.clientIdList[clientIdIndex]
              this.$set(clientIdItem, 'intercomInviteState', false)
              if (clientIdItem.methodType !== v.methodType) {
                if (v.methodType !== 'successfulCall') {
                  this.roomStatusListFn({
                    name:
                      clientIdItem.name ||
                      clientIdItem.devName ||
                      clientIdItem.deviceId ||
                      clientIdItem.openClientId ||
                      clientIdItem.userClientId,
                    methodType: v.methodType
                  })
                }
              }
              if (clientIdItem.audioContextType !== v.audioContextType) {
                if (v.audioContextType) {
                  this.roomStatusListFn({
                    name:
                      clientIdItem.name ||
                      clientIdItem.devName ||
                      clientIdItem.deviceId ||
                      clientIdItem.openClientId ||
                      clientIdItem.userClientId,
                    methodType: 'audioConsumerResume'
                  })
                } else {
                  this.roomStatusListFn({
                    name:
                      clientIdItem.name ||
                      clientIdItem.devName ||
                      clientIdItem.deviceId ||
                      clientIdItem.openClientId ||
                      clientIdItem.userClientId,
                    methodType: 'audioConsumerPaused'
                  })
                }
              }
              this.$set(this.clientIdList, clientIdIndex, {
                ...clientIdItem,
                methodTypeV2: true,
                ...v
              })
              // 抢呼判断
              if (v.audioContextType && this.audioProducerBrokenType) {
                this.audioProducerBroken({
                  ...this.clientIdList[clientIdIndex]
                })
                this.audioProducerBrokenType = false
              }
              if (status !== 'audioContextType') {
                if (
                  this.clientIdList[clientIdIndex].methodType ===
                  'successfulCall'
                ) {
                  if (this.allForbiddenSounds) {
                    this.forbiddenSound(this.clientIdList[clientIdIndex], true)
                  } else {
                    const muted =
                      this.clientIdList[clientIdIndex].forbiddenSound
                    this.forbiddenSound(
                      this.clientIdList[clientIdIndex],
                      typeof muted === 'boolean' ? muted : false
                    )
                  }
                }
              }
            } else {
              this.clientIdList.push({
                methodTypeV2: true,
                ...v,
                deviceId: v.clientId,
                intercomInviteState: false
              })
            }

            // this.allForbiddenSounds = false
            this.$nextTick(() => {
              this.throttle(this.mediaClientInfoFn, 500)()
            })
          })
        }
      }
    },
    // 节流
    throttle(fn, delay) {
      return (params) => {
        if (!this.valid) {
          return false
        }
        this.valid = false
        setTimeout(() => {
          fn(params)
          this.valid = true
        }, delay)
      }
    },
    async getClientInfo(clientIdList = []) {
      const { code, data = [] } = await this.$api.media.clientInfo({
        clientIds: clientIdList.join(',')
      })
      if (code === 200) {
        data.forEach((v) => {
          const rowIndex = this.clientIdList.findIndex(
            (o) =>
              o.deviceId === v.openClientId || o.deviceId === v.userClientId
          )
          if (rowIndex !== -1) {
            const clientIdItem = this.clientIdList[rowIndex]
            this.roomStatusListFn({
              name:
                v.name ||
                v.devName ||
                v.deviceId ||
                v.openClientId ||
                v.userClientId,
              methodType: clientIdItem.methodType
            })
            this.$set(this.clientIdList, rowIndex, {
              ...clientIdItem,
              ...v,
              intercomInviteState: false
            })

            if (
              this.clientIdList[rowIndex].audioContextType &&
              this.audioProducerBrokenType
            ) {
              this.audioProducerBroken({
                ...this.clientIdList[rowIndex]
              })
              this.audioProducerBrokenType = false
            }

            if (this.clientIdList[rowIndex].methodType === 'successfulCall') {
              if (this.allForbiddenSounds) {
                this.forbiddenSound(this.clientIdList[rowIndex], true)
              } else {
                const muted = this.clientIdList[rowIndex].forbiddenSound
                this.forbiddenSound(
                  this.clientIdList[rowIndex],
                  typeof muted === 'boolean' ? muted : false
                )
              }
            }
          }
        })
      }
    },
    /* 客户端信息 */
    mediaClientInfoFn() {
      const clientIdList = []
      this.inPlaceClientIdList.forEach((v) => {
        const row = this.clientIdList.find((o) => o.deviceId === v.clientId)
        if (row) {
          if (!row.id && !row.userClientId) {
            clientIdList.push(v.clientId)
          }
        }
      })
      if (clientIdList.length) {
        this.getClientInfo(clientIdList)
      }
    },
    onkeyup() {
      const key = window.event.keyCode
      if (key === 32) {
        if (this.keyType !== 'onkeyup') {
          this.keyType = 'onkeyup'
          this.audioConsumerPaused()
        }
      }
    },
    onkeydown() {
      const key = window.event.keyCode
      if (key === 32) {
        if (this.keyType !== 'onkeydown') {
          this.keyType = 'onkeydown'
          this.audioConsumerResume()
        }
      }
    },
    /* 加入频道 （加入正式群组） */
    channelInFn(params = {}) {
      const { roomId, clientIdList = [], messageContent, type } = params
      if (this.alarmRecordOperatorType) {
        return this.$message.error('当前处于接警中，无法进行此操作')
      }
      this.clientIdList = []
      clientIdList.forEach((deviceItem) => {
        if (deviceItem.onlineState === 1) {
          this.clientIdList.unshift(deviceItem)
        } else {
          this.clientIdList.push(deviceItem)
        }
      })
      this.inPlaceClientIdList = []
      document.onkeyup = this.onkeyup
      document.onkeydown = this.onkeydown
      this.setDialogVisible(true)
      if (messageContent) {
        this.$message({
          message: messageContent,
          type: 'success'
        })
      }
      this.roomId = roomId
      this.sdkChannelIn({ roomId })
      this.$nextTick(() => {
        this.viewDisplayModeFn(type === 2 ? 2 : 1)
      })
    },
    // 调用sdk channelInFn 方法
    sdkChannelIn(params) {
      const { roomId } = params
      this.$emit('on-orgGroupInsertTemp', this.orgGroupInsertTemp)
      this.$sdk.channelInFn(
        {
          roomId,
          mediaRoomMemberList: this.mediaRoomMemberList,
          intercomType: 'intercomMany',
          containerName: 'intercomAudioBox'
        },
        (res) => {
          this.$message.error(res.errorReason)
        },
        this.deviceJoinRoomFn
      )
    },
    /* 获取mq是否连接 */
    connectionStatus(specify) {
      if (specify === 'sdk') {
        return this.$sdk.connectionStatus()
      } else if (specify === 'business') {
        return this.$business.connectionStatus()
      } else {
        return this.$sdk.connectionStatus() && this.$business.connectionStatus()
      }
    },
    getSkdRoomInformation() {
      return this.$sdk.roomInformationFn() || {}
    },
    /* 监控时加入小组 */
    joinTheTeamDuringMonitoring(device) {
      if (this.dialogTableVisible) {
        const index = this.clientIdList.findIndex(
          (v) => v.deviceId === device.deviceId
        )
        if (index === -1) {
          /* 直接加入房间即可 */
          this.intercomInviteDevice([device.deviceId])
        } else {
          this.$message.error('当前设备已经在群组中')
        }
      } else {
        const roomInformation = this.getSkdRoomInformation()
        if (roomInformation.roomTips) {
          /* 在 1对1 对讲，禁止拉起 */
          this.$message.error('请退出当前对讲')
        } else {
          /* 创建临时群组 */
          this.orgGroupInsertTempFn([device])
        }
      }
    },
    /* 燃气险情群组（保存） */
    saveGasCaseGroup(groupId, gasCaseId) {
      this.$api.gasCase.saveGasCaseGroup({
        groupId,
        gasCaseId
      })
    },
    /* 燃气警情，创建或者加入 */
    gasCaseCreateOrJoin(data) {
      return new Promise((resolve, reject) => {
        if (this.alarmRecordOperatorType) {
          reject(new Error('当前处于接警中，无法进行此操作'))
          return this.$message.error('当前处于接警中，无法进行此操作')
        }
        const {
          groupId = null,
          deviceList = [],
          gasCaseId,
          type = null,
          groupName = null
        } = data
        if (!gasCaseId) {
          reject(new Error('请传入燃气警情id'))
          return this.$message.error('请传入燃气警情id')
        }
        if (groupId) {
          this.intercomNatureGroupIn(groupId, type, groupName)
            .then(() => {
              this.saveGasCaseGroup(groupId, gasCaseId)
            })
            .catch((e) => {
              reject(e)
            })
        } else if (deviceList.length) {
          this.orgGroupInsertTempFn(deviceList, type)
            .then((groupId) => {
              this.saveGasCaseGroup(groupId, gasCaseId)
            })
            .catch((e) => {
              reject(e)
            })
        }
      })
    },
    /* 创建临时群组 */
    orgGroupInsertTempFn(clientList, params, groupName, caseId) {
      let type = null
      let openRecording = null
      if (typeof params === 'number') {
        type = params
      } else if (typeof params === 'object' && params) {
        type = params.type
        openRecording = params.openRecording
      }
      // clientList 设备列表, type 大屏小屏, groupName 群组名称, caseId 警情id
      return new Promise((resolve, reject) => {
        if (this.alarmRecordOperatorType) {
          reject(new Error('当前处于接警中，无法进行此操作'))
          return this.$message.error('当前处于接警中，无法进行此操作')
        }
        if (!this.connectionStatus()) {
          reject(new Error('mq未连接'))
          return this.$message.error('mq未连接')
        }
        const offLineList = [] // 离线设备统计
        const notSupportedList = [] // 不支持设备统计
        const inIntercomList = [] // 在别处对讲中
        const deviceList = [] // 可以对讲设备
        clientList.forEach((device) => {
          if (device.onlineState !== 1) {
            offLineList.push(device)
          } else if (device.intercomSupport === 0) {
            notSupportedList.push(device)
          } else if (device.intercomGroupTem === 1) {
            inIntercomList.push(device)
          } else {
            deviceList.push(device)
          }
        })
        let message = ''
        if (offLineList.length) {
          message += `<div class="lh_20">有${offLineList.length}台设备不在线无法发起对讲</div>`
        }
        if (notSupportedList.length) {
          message += `<div class="lh_20">有${notSupportedList.length}台设备不支持对讲</div>`
        }
        if (inIntercomList.length) {
          message += `<div class="lh_20">有${inIntercomList.length}台设备已处于临时群组中</div>`
        }
        if (message.length) {
          this.$message.error({
            duration: 8000,
            dangerouslyUseHTMLString: true,
            message: message
          })
        }
        this.$nextTick(() => {
          if (deviceList.length) {
            const roomInformation = this.getSkdRoomInformation()
            if (roomInformation.roomTips) {
              return this.$message.error(roomInformation.roomTips)
            }
            this.$api.orgGroup
              .insertTemp({
                type: 2,
                remark: null,
                deviceIdList: deviceList.map((v) => v.deviceId),
                groupName: groupName || '临时群组',
                openRecording,
                caseId
              })
              .then((res) => {
                if (res.code === 200) {
                  const groupId = res.data.id
                  resolve(groupId)
                  this.intercomNatureGroupIn(groupId, type)
                }
              })
              .catch((e) => {
                reject(new Error(e.msg || '创建临时群组失败'))
              })
          } else {
            reject(new Error('无设备'))
          }
        })
      })
    },
    /* 警情发起群组 */
    policeSituationFn(obj) {
      if (this.alarmRecordOperatorType) {
        return this.$message.error('当前处于接警中，无法进行此操作')
      }
      this.orgGroupInsertTempFn(
        obj.clientIdList,
        obj.type,
        obj.groupName,
        obj.caseId
      )
    },
    /* 正式群组对讲呼叫 */
    intercomNatureGroupIn(groupId, type, params) {
      return new Promise((resolve, reject) => {
        if (this.alarmRecordOperatorType) {
          reject(new Error('当前处于接警中，无法进行此操作'))
          return this.$message.error('当前处于接警中，无法进行此操作')
        }
        this.tabsNavigationType = 1
        this.roomStatusList = []
        if (!this.connectionStatus()) {
          reject(new Error('mq未连接'))
          return this.$message.error('mq未连接')
        }
        const audioInputList = this.$sdk.audioInputListFn() || []
        if (audioInputList.length !== 0) {
          const roomInformation = this.getSkdRoomInformation()
          if (roomInformation.roomTips) {
            return this.$message.error(roomInformation.roomTips)
          }
          this.getIntercomNatureGroupInData(groupId, type, params).then(() => {
            resolve()
          })
        } else {
          reject(new Error('获取麦克风权限有误'))
          return this.$message.error('获取麦克风权限有误')
        }
      })
    },
    // 获取媒体房间成员集合
    async getMediaRoomMemberList(roomId, deviceList = []) {
      const { code, data = [] } = await this.$api.media.mediaRoomMemberList({
        roomId
      })
      if (code === 200) {
        this.mediaRoomMemberList = [...data]
        data.forEach((item, index) => {
          if (this.clientId === item.userClientId) {
            this.mediaRoomMemberList.splice(index, 1)
          } else {
            const index = deviceList.findIndex(
              (v) => v.deviceId === item.userClientId
            )
            if (index !== -1) {
              this.$set(deviceList[index], 'methodType', 'joinTheRoom')
              this.$set(deviceList[index], 'audioContextType', false)
            }
          }
        })
        return deviceList
      }
      return []
    },
    // 获取群组数据
    async getIntercomNatureGroupInData(groupId, type, params) {
      const { code, data } = await this.$api.media.intercomNatureGroupIn({
        groupId,
        caseId: params ? params.caseId || null : null
      })
      if (code === 200) {
        this.mediaRoomMemberList = []
        console.warn('sdkParams', sdkParams)
        if (sdkParams && sdkParams.version === '0.2.0') {
          const deviceList = await this.getMediaRoomMemberList(
            data.roomId,
            data.deviceList
          )
          this.setOrgGroupInsertTemp(data, deviceList)
        } else {
          this.setOrgGroupInsertTemp(data, data.deviceList, type)
        }
      }
    },
    // 添加设备房间监听
    addSceneDevice() {
      this.$business.addSceneDevice({
        sceneName: this.componentsName,
        onlyOnlineAndOffline: true,
        callback: (res) => {
          const index = this.clientIdList.findIndex(
            (v) => v.deviceId === res.deviceId
          )
          if (index !== -1) {
            this.$set(this.clientIdList[index], 'onlineState', res.onlineState)
            if (res.isofn === 1) {
              const obj = { ...this.clientIdList[index] }
              this.clientIdList.splice(index, 1)
              if (res.onlineState === 1) {
                this.clientIdList.unshift(obj)
              } else {
                this.clientIdList.push(obj)
              }
            }
          }
        }
      })
    },
    // 设置群组内容
    setOrgGroupInsertTemp(data = {}, deviceList = [], type) {
      this.orgGroupInsertTemp = {
        ...data,
        deviceList
      }
      this.clientId = this.$sdk.clientIdFn()
      this.channelInFn({
        roomId: data.roomId,
        clientIdList: deviceList,
        messageContent: `正在对${data.groupName}-群组发起对讲`,
        type
      })
      this.$nextTick(() => {
        this.orgGroupIntercomList = []
        this.intercomloTiming = null
        if (this.orgGroupInsertTemp.openRecording === 1) {
          this.orgGroupIntercomFn()
        }
        this.addSceneDevice()
      })
    },
    /* 邀请设备加入群组对讲 */
    async intercomInviteDevice(deviceIdList = []) {
      if (deviceIdList && deviceIdList.length) {
        const { code, data = [] } = await this.$api.media.intercomInviteDevice({
          groupId: this.orgGroupInsertTemp.id,
          deviceIdList
        })
        if (code === 200) {
          if (data.length) {
            data.forEach((item) => {
              if (item.state === 777) {
                const clientIdItem = this.clientIdList.find(
                  (v) => v.deviceId === item.deviceId
                )
                if (clientIdItem) {
                  this.$set(clientIdItem, 'intercomInviteState', false)
                }
                setTimeout(() => {
                  this.$message.error(item.error)
                })
              }
            })
          } else {
            this.$message.success('发起邀请成功')
          }
        }
      }
    },
    /* 邀请单个设备进组 */
    intercomInviteSingle(deviceItem) {
      if (deviceItem.onlineState === 1) {
        this.$set(deviceItem, 'intercomInviteState', true)
        this.$nextTick(() => {
          this.intercomInviteDevice([deviceItem.deviceId])
        })
      } else {
        this.$message.warning('当前设备离线 无法邀请')
      }
    },
    /* 邀请所有没有进组的设备进组 */
    intercomInviteAll() {
      const deviceList = []
      this.clientIdList.forEach((deviceItem) => {
        const methodType = deviceItem.methodType
        if (
          methodType !== 'successfulCall' &&
          methodType !== 'joinTheRoom' &&
          deviceItem.onlineState === 1
        ) {
          this.$set(deviceItem, 'intercomInviteState', true)
          deviceList.push(deviceItem.deviceId)
        }
      })
      this.$nextTick(() => {
        if (deviceList.length) {
          this.intercomInviteDevice(deviceList)
        } else {
          this.$message.warning('无设备需要拉入')
        }
      })
    }
  }
}
