<!-- 抽查详情 -->
<template>
  <el-dialog
    :destroy-on-close="true"
    :visible.sync="dialogVisible"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    class="dialog_clsss filter-dialog"
    width="820px"
  >
    <div class="deleteBox c_fff">
      <div class="pos_r dialog_header">
        <span>抽查详情</span>
        <div class="iconCloseBox clickBox closeBox">
          <svg-icon
            @click.native="close()"
            svg-name="icon_close"
            className="svgBox !w-[26px] !h-[26px]"
          />
        </div>
      </div>
      <div class="dialog_body h-[60vh] flex flex-col text-main text-xs !px-0">
        <div class="flex items-center justify-center mb-[16px]">
          <div class="relative flex-shrink-0">
            <div
              :style="{
                left: `${navigationStatusProgress}%`
              }"
              class="rounded-[4px_4px_0_0] absolute top-0 left-0 w-[96px] h-full duration-500"
              style="background: var(--background-gradientV2)"
            ></div>
            <ul
              class="relative text-sm h-[36px] !leading-[36px] text-center border border-border rounded text-auxiliary flex overflow-hidden"
            >
              <li
                v-for="(item, index) in navigationList"
                :key="index"
                @click="setNavigationStatus(item.value)"
                :class="{
                  'text-main font-bold': item.value === navigationStatus
                }"
                class="clickBox px-2 w-[96px] box-border"
              >
                {{ item.label }}
              </li>
            </ul>
          </div>
        </div>
        <!-- 抽查信息 -->
        <div
          v-show="navigationStatus === 1"
          class="flex flex-grow h-0 px-4 box-border"
        >
          <el-row :gutter="8" class="w-full h-full">
            <el-col :span="8" class="h-full">
              <div class="w-full h-full box-border px-2">
                <div
                  class="w-full h-full bg-backgroundv2 rounded px-3 py-1 box-border overflow-x-hidden overflow-y-auto"
                >
                  <div class="mb-2">
                    <div class="text-auxiliary h-[30px] leading-[30px]">
                      抽查人员
                    </div>
                    <div>
                      {{ form.name }}
                      <span class="text-auxiliary"> ({{ form.jobNum }})</span>
                    </div>
                  </div>
                  <div class="mb-2">
                    <div class="text-auxiliary h-[30px] leading-[30px]">
                      所属部门
                    </div>
                    <div>{{ form.orgName }}</div>
                  </div>
                  <div class="mb-2">
                    <div class="text-auxiliary h-[30px] leading-[30px]">
                      抽查时间
                    </div>
                    <div>{{ form.checkDtm }}</div>
                  </div>
                  <div class="mb-2">
                    <div class="text-auxiliary h-[30px] leading-[30px]">
                      抽查类型
                    </div>
                    <div>
                      {{ form.checkType === 1 ? '范围抽查' : '随机抽查' }}
                    </div>
                  </div>
                  <div class="mb-2">
                    <div class="text-auxiliary h-[30px] leading-[30px]">
                      抽查方式
                    </div>
                    <div>{{ form.checkStyle === 1 ? '消息' : '呼叫' }}</div>
                  </div>
                  <div v-if="form.checkType === 1" class="mb-2">
                    <div class="text-auxiliary h-[30px] leading-[30px]">
                      抽查范围 (
                      <span v-if="form.checkScope === 1">区域抽查</span>
                      <span v-else-if="form.checkScope === 2">部门抽查</span>
                      <span v-else-if="form.checkScope === 3">设备抽查</span>
                      )
                    </div>
                    <div v-if="form.checkScope === 1">
                      <span
                        v-for="(name, index) in railNameList"
                        :key="index + '_orgNameList'"
                      >
                        {{ name }}
                        <span v-if="index !== railNameList.length - 1">、</span>
                      </span>
                    </div>
                    <div v-else-if="form.checkScope === 2">
                      <span
                        v-for="(name, index) in orgNameList"
                        :key="index + '_orgNameList'"
                      >
                        {{ name }}
                        <span v-if="index !== orgNameList.length - 1">、</span>
                      </span>
                    </div>
                    <div v-else>
                      <span>{{ form.checkNum || 0 }} 台设备</span>
                    </div>
                  </div>
                  <div v-else class="mb-2">
                    <div class="text-auxiliary h-[30px] leading-[30px]">
                      抽查范围 (
                      <span>随机抽查</span>
                      )
                    </div>
                    <div>
                      <span>{{ form.limitNum || 0 }} 台设备</span>
                    </div>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="16" class="h-full">
              <div
                v-if="form.checkStyle === 1"
                class="py-1 box-border h-full bg-backgroundv3 rounded"
              >
                <div
                  class="w-full h-[34px] px-2 box-border flex justify-between items-center"
                >
                  <div>消息内容</div>
                  <div
                    v-if="form.playMsg === 1"
                    class="ml-2 border-[2px] border-border box-border flex items-center justify-center h-[26px] px-2 rounded"
                  >
                    TTS文字转语音
                  </div>
                </div>
                <!-- 消息内容 -->
                <div class="h-[40%] box-border px-2 mt-1">
                  <div
                    class="w-full h-full box-border bg-backgroundv2 rounded p-2 overflow-y-auto overflow-x-hidden"
                  >
                    {{ form.msgContent }}
                  </div>
                </div>
                <div class="flex px-2 mt-1">
                  <div
                    class="flex-1 bg-backgroundv2 rounded px-2 box-border h-[32px] flex justify-between items-center mr-2"
                  >
                    <div class="text-auxiliary">应答方式</div>
                    <span>{{ form.actionType === 1 ? '打卡' : '答复' }}</span>
                  </div>
                  <div
                    class="flex-1 bg-backgroundv2 rounded px-2 box-border h-[32px] flex justify-between items-center"
                  >
                    <div class="text-auxiliary">应答时间</div>
                    <span
                      ><span class="font-bold">{{ form.actionTime }}</span>
                      秒</span
                    >
                  </div>
                </div>
              </div>
              <div v-else class="h-full py-1 bg-backgroundv3 rounded">
                <div
                  class="w-full h-full flex items-center justify-center text-auxiliary box-border"
                >
                  <div class="r-f tac block text-sm">
                    <svg-icon
                      svg-name="noData"
                      className="svgBox !w-[46px] !h-[46px]"
                    />

                    <div class="mt-3">呼叫抽查</div>
                    <div class="mt-1">将会采用对讲应答的方式抽查备份</div>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <!-- 抽查情况 -->
        <div
          v-show="navigationStatus === 2"
          class="flex flex-grow h-0 px-4 box-border"
        >
          <el-row v-if="form.checkNum !== 0" :gutter="8" class="w-full h-full">
            <el-col
              :span="form.actionType === 1 ? 24 : 8"
              class="h-full flex flex-col"
            >
              <div
                :class="{
                  'flex-col': form.actionType !== 1,
                  'justify-between items-center': form.actionType === 1
                }"
                class="flex-shrink-0 flex px-2"
              >
                <!-- 设备状态统计 -->
                <el-popover
                  :open-delay="500"
                  placement="top"
                  popper-class="popover_echarts w-[130px]"
                  trigger="hover"
                >
                  <ul class="text-xs p-2 leading-[22px]">
                    <li class="flex w-full">
                      <span class="flex-shrink-0">抽查人数：</span>
                      <div class="text-right font-bold flex-grow w-0">
                        {{ form.checkNum || '- -' }}
                      </div>
                    </li>
                    <li class="flex w-full">
                      <span class="flex-shrink-0 tracking-[4px]">待接收：</span>
                      <div class="text-right font-bold flex-grow w-0">
                        {{ form.beReceiveNum || '- -' }}
                      </div>
                    </li>
                    <li class="flex w-full">
                      <span class="flex-shrink-0 tracking-[4px]">接收中：</span>
                      <div class="text-right font-bold flex-grow w-0">
                        {{ form.receivingNum || '- -' }}
                      </div>
                    </li>
                    <li class="flex w-full">
                      <span class="flex-shrink-0 tracking-[4px]">已完成：</span>
                      <div
                        class="text-right font-bold flex-grow w-0 text-success"
                      >
                        {{ form.finishNum || '- -' }}
                      </div>
                    </li>
                    <li class="flex w-full">
                      <span class="flex-shrink-0 tracking-[4px]">未完成：</span>
                      <div
                        class="text-right font-bold flex-grow w-0 text-dangerv2"
                      >
                        {{ form.notFinishNum || '- -' }}
                      </div>
                    </li>
                  </ul>
                  <div
                    slot="reference"
                    :class="{
                      'min-w-[248px]': form.actionType === 1
                    }"
                    class="h-[28px] box-border leading-[28px] w-full bg-backgroundv2 border border-border rounded flex font-bold"
                  >
                    <div class="flex-1 flex">
                      <div
                        class="bg-backgroundv3 h-full w-[32px] flex items-center justify-center flex-shrink-0"
                      >
                        <svg-icon
                          svg-name="everyDay_manyPeople"
                          className="svgBox"
                        />
                      </div>
                      <div class="flex-grow w-0 text-center over_one_line">
                        {{ form.checkNum || '- -' }}
                      </div>
                    </div>
                    <div class="flex-1 flex">
                      <div
                        class="bg-backgroundv3 h-full w-[32px] flex items-center justify-center flex-shrink-0"
                      >
                        <svg-icon svg-name="successState" className="svgBox" />
                      </div>
                      <div
                        class="flex-grow w-0 text-center text-success over_one_line"
                      >
                        {{ form.finishNum || '- -' }}
                      </div>
                    </div>
                    <div class="flex-1 flex">
                      <div
                        class="bg-backgroundv3 h-full w-[32px] flex items-center justify-center flex-shrink-0"
                      >
                        <svg-icon svg-name="dangerState" className="svgBox" />
                      </div>
                      <div
                        class="flex-grow w-0 text-center text-dangerv2 over_one_line"
                      >
                        {{ form.notFinishNum || '- -' }}
                      </div>
                    </div>
                  </div>
                </el-popover>
                <!-- 状态切换 -->
                <div class="relative mt-3 mb-2 min-w-[160px]">
                  <div
                    :style="{
                      left: `${checkStatusProgress}%`
                    }"
                    :class="{
                      'w-[78px]': form.actionType !== 1,
                      'w-[54px]': form.actionType === 1
                    }"
                    class="rounded-[4px_4px_0_0] absolute top-0 left-0 h-full duration-500"
                    style="background: var(--background-gradientV2)"
                  ></div>
                  <ul
                    class="relative h-[26px] !leading-[26px] text-center border border-border rounded text-auxiliary text-xs flex overflow-hidden"
                  >
                    <li
                      v-for="(item, index) in checkStatusList"
                      :key="index + '_checkStatusList'"
                      @click="setCheckStatus(item.value)"
                      :class="{ 'text-main': item.value === checkStatus }"
                      class="flex-1 clickBox"
                    >
                      {{ item.label }}
                    </li>
                  </ul>
                </div>
              </div>
              <!-- 设备列表 -->
              <div class="flex-grow h-0 px-2 overflow-x-hidden overflow-y-auto">
                <spotCheckTaskDevice
                  v-if="form.id"
                  :paramsSpan="form.actionType === 1 ? 8 : 24"
                  :form="form"
                  :subscribeStatus="false"
                  @change="spotCheckTaskDeviceChange"
                  @on-countStatus="spotCheckTaskDeviceCountStatus"
                  ref="spotCheckTaskDevice"
                ></spotCheckTaskDevice>
              </div>
            </el-col>
            <el-col v-if="form.actionType !== 1" :span="16" class="h-full">
              <div
                class="w-full h-full rounded border border-border bg-backgroundv2 overflow-hidden"
              >
                <!-- 呼叫 -->
                <itemTalkback
                  v-if="orgGroupInsertTemp.id && form.checkStyle === 2"
                  :orgGroupInsertTemp="orgGroupInsertTemp"
                  :realtimeRequestType="false"
                  ref="itemTalkback"
                ></itemTalkback>
                <!-- 消息答复 -->
                <talkbackReply
                  v-else-if="
                    form.id && form.actionType === 2 && form.checkStyle === 1
                  "
                  :taskId="form.id"
                  :realtimeRequestType="false"
                  ref="talkbackReply"
                ></talkbackReply>
                <div
                  v-else
                  class="flex h-full items-center justify-center text-auxiliary"
                >
                  <div class="r-f tac block">
                    <svg-icon
                      svg-name="noData"
                      className="svgBox !w-[50px] !h-[50px]"
                    />
                    <div class="mt_10">暂无数据</div>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
          <div
            v-else
            class="w-full shadow-[0_0_10px_0_inset] shadow-theme05 h-full flex items-center justify-center text-auxiliary bg-backgroundv2 box-border border border-border rounded"
          >
            <div class="r-f tac block">
              <svg-icon
                svg-name="noData"
                className="svgBox !w-[46px] !h-[46px]"
              />

              <div class="mt-3 text-sm">没有设备支持本次抽查</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import talkbackReply from '@components/spotCheck/viewSpotCheck/talkbackReply'
import itemTalkback from '@components/device/multiPersonIntercom/itemTalkback'
import spotCheckTaskDevice from '@components/spotCheck/viewSpotCheck/spotCheckTaskDevice'
export default {
  data() {
    return {
      form: {},
      groupId: null,
      orgGroupInsertTemp: {},
      navigationStatus: 1,
      navigationList: [
        { label: '抽查信息', value: 1 },
        { label: '抽查情况', value: 2 }
      ],
      checkStatus: 0, // 抽查状态（0全部,1待接收、2接收中、3完成、4未完成）
      checkStatusList: [
        { label: '全部', value: 0 },
        { label: '完成', value: 3 },
        { label: '未完成', value: 4 }
      ],
      orgNameList: [], // 部门名称 集合
      railNameList: [], // 电子围栏名称 集合
      dialogVisible: false
    }
  },
  components: {
    itemTalkback,
    talkbackReply,
    spotCheckTaskDevice
  },
  computed: {
    // 状态进度
    checkStatusProgress() {
      const index = this.checkStatusList.findIndex(
        (v) => v.value === this.checkStatus
      )
      if (index !== -1) {
        return Math.ceil(index * (100 / this.checkStatusList.length))
      }
      return 0
    },
    // 状态进度
    navigationStatusProgress() {
      const index = this.navigationList.findIndex(
        (v) => v.value === this.navigationStatus
      )
      if (index !== -1) {
        return Math.ceil(index * (100 / this.navigationList.length))
      }
      return 0
    }
  },
  mounted() {
    // this.show(157)
  },
  methods: {
    // 获取群组数据
    async getIntercomNatureGroupInData(groupId) {
      if (!groupId) return
      const { code, data } = await this.$api.detail('orgGroup', groupId)
      if (code === 200) {
        this.orgGroupInsertTemp = data
      }
    },
    // 通知 spotCheckTaskDevice 获取数据
    spotCheckTaskDeviceGetData() {
      this.$refs.spotCheckTaskDevice?.getData(this.checkStatus)
    },
    // 切换状态
    setCheckStatus(checkStatus) {
      this.checkStatus = checkStatus
      this.$nextTick(() => {
        this.spotCheckTaskDeviceGetData()
      })
    },
    // 设置导航
    setNavigationStatus(navigationStatus) {
      this.navigationStatus = navigationStatus
    },
    // 设置加载状态
    setLoading(loading) {
      this.loading = loading
    },
    // 抽查设备 返回状态数量统计
    spotCheckTaskDeviceCountStatus(params = {}) {
      const {
        beReceiveNum = 0,
        receivingNum = 0,
        finishNum = 0,
        notFinishNum = 0
      } = params
      this.$set(this.form, 'beReceiveNum', beReceiveNum)
      this.$set(this.form, 'receivingNum', receivingNum)
      this.$set(this.form, 'finishNum', finishNum)
      this.$set(this.form, 'notFinishNum', notFinishNum)
    },
    // 抽查设备获取设备
    spotCheckTaskDeviceChange(taskDeviceList = []) {
      this.taskDeviceList = taskDeviceList
      this.setLoading(false)
    },
    show(id) {
      this.form = {}
      this.setNavigationStatus(1)
      this.setCheckStatus(0)
      this.setLoading(true)
      this.detail(id)
    },
    async detail(id) {
      const { code, data = {} } = await this.$api.spotCheck.taskDetail({
        id,
        getMapRail: 1,
        getOrg: 1
      })
      if (code === 200) {
        this.form = data
        this.orgNameList = data.orgNameList || []
        this.railNameList = data.railNameList || []
        if (this.form.checkNum === 0) {
          this.spotCheckTaskDeviceChange([])
        }
        this.$nextTick(() => {
          if (data.checkStyle === 2) {
            this.groupId = data.groupId
            this.getIntercomNatureGroupInData(this.groupId)
            this.$set(this.form, 'actionType', null)
          }
          this.dialogVisible = true
        })
      }
    },
    close() {
      this.dialogVisible = false
    }
  }
}
</script>
<style scoped lang="less" rel="stylesheet/less"></style>
