<!-- 选择部门 -->
<template>
  <div class="flex flex-col w-full h-full">
    <div class="flex justify-between items-center h-[40px]">
      <div class="flex-1">
        所有部门<span class="text-auxiliary ml-3"
          >(双击选中该部门和部门下全部所属部门)</span
        >
      </div>
      <div class="flex-1 flex items-center justify-end">
        <el-button
          v-show="selectedDataList.length !== 0"
          @click="clearSelectedData"
          size="mini"
          class="button_delete mr-1 !p-[4px_14px]"
        >
          <span class="text-xs">清 空</span>
        </el-button>
        <div class="flex items-center clickBox">
          <span
            >已选中
            <span class="text-theme">{{ selectedDataList.length }}</span>
            个</span
          >
        </div>
      </div>
    </div>
    <div
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      class="flex-grow h-0 flex"
    >
      <div
        class="flex-1 bg-background rounded shadow-[0_0_10px_0_inset] shadow-backgroundv3 flex flex-col"
      >
        <div class="centerBox p-[8px_8px_4px]">
          <div class="search_lightColor flex min-w-full z-[3]">
            <el-input
              size="small"
              clearable
              class="flex-grow w-0"
              @clear="getData()"
              @keyup.enter.native="getData()"
              v-model="filterValue"
              placeholder="设备号、设备名称、部门名称进行筛选"
            ></el-input>
            <div class="searchBox flex-shrink-0" @click="getData()">
              <svg-icon svg-name="search" className="svgBox" />
            </div>
          </div>
        </div>
        <div class="flex-grow h-0 w-full equipment vbtTable">
          <vbt-table
            border
            stripe
            row-key="onlyId"
            isBigData
            @row-click="clickTable"
            ref="departmentTable"
            :indent="10"
            isTreeTable
            :show-header="false"
            highlight-hover-row
            :max-height="`${treeHeight}px`"
            v-if="treeHeight"
            :scrollYRenderConfig="scrollYRenderConfig"
            :data="deviceList"
          >
            <vbt-table-column>
              <template slot-scope="{ row }">
                <div
                  class="flex-grow flex"
                  @dblclick.stop="deviceDblClick(row)"
                >
                  <!-- 多选 -->
                  <div class="centerBox" @click.stop="deviceClick(row)">
                    <span
                      class="el-checkbox__input pos_r"
                      style="margin-right: 6px; top: 1px"
                      :class="judgeSelectedFn(row)"
                    >
                      <span class="el-checkbox__inner"></span>
                    </span>
                  </div>
                  <div class="treeDepartment cursor_pointer">
                    <div class="r-f w_100vh flex">
                      <div class="flex-grow w-0 over_one_line">
                        <el-tooltip
                          class="item"
                          effect="dark"
                          placement="bottom"
                        >
                          <div slot="content">
                            {{
                              row.companyName || row.groupName || row.orgName
                            }}
                          </div>
                          <span>{{
                            row.companyName || row.groupName || row.orgName
                          }}</span>
                        </el-tooltip>
                      </div>
                      <span
                        v-show="row.onlineCountList.length"
                        class="flex-shrink-0 ml-1 text-theme"
                      >
                        ({{ row.onlineCountList.length }})
                      </span>
                    </div>
                  </div>
                </div>
              </template>
            </vbt-table-column>
          </vbt-table>
        </div>
      </div>
      <ul
        class="flex-1 bg-background !p-[10px] overflow-y-auto overflow-x-hidden box-border rounded shadow-[0_0_10px_0_inset] shadow-backgroundv3 flex flex-col ml-2"
      >
        <li
          v-show="selectedDataList.length === 0"
          class="h-full bg-backgroundv2 border border-border rounded px-2 box-border flex justify-center text-auxiliary items-center"
        >
          <div class="r-f tac block">
            <svg-icon
              svg-name="noData"
              className="svgBox !w-[50px] !h-[50px]"
            />
            <div class="mt-3">您还未选择部门</div>
          </div>
        </li>
        <li
          v-for="(item, index) in selectedDataList"
          :key="item.id + '_selectedDataList'"
          class="w-full flex hover:shadow-[0_0_10px_0_inset] items-center bg-backgroundv2 rounded mb-2 box-border clickBox border border-backgroundv2 hover:border-theme hover:shadow-theme"
        >
          <div class="flex-grow w-0 pl-2 box-border over_one_line">
            {{ item.companyName || item.groupName || item.orgName }}
          </div>
          <div class="flex-shrink-0">
            <iconBox
              margin="4px"
              :width="20"
              :height="20"
              @click="deleteSelectList(index)"
              classNmae="shadow_delete"
              imgName="delete"
              altText="删 除"
            ></iconBox>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import deviceTree from '@utils/device/deviceTree'
import deepmerge from 'deepmerge'
import { vbtTable, vbtTableColumn } from '@static/vbt-table.js'
export default {
  props: {
    initSelectedDataList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  components: {
    vbtTable,
    vbtTableColumn
  },
  watch: {
    filterValue: function (val) {
      this.filterValue = val.replace(/\s/g, '')
    }
  },
  data() {
    return {
      checkAll: false,
      loading: false,
      treeHeight: 380,
      scrollYRenderConfig: {
        renderSize: 30,
        offsetSize: 10
      },
      deviceList: [],
      selectedDataList: [],
      filterValue: '',
      time: 400,
      timeOut: null
    }
  },
  mounted() {
    this.setSelectedDataList(this.initSelectedDataList)
    this.getData()
  },
  methods: {
    // 空已选中数据
    clearSelectedData() {
      this.setSelectedDataList([])
      this.setCheckAll(false)
    },
    clickTable(row) {
      if (row.type === 3) return
      if (this.timeOut) {
        clearTimeout(this.timeOut) // 清除第一个单击事件
      }
      this.timeOut = setTimeout(() => {
        this.$emit('on-department-click', row)
        if (this.$refs.departmentTable) {
          this.$refs.departmentTable.toggleRowExpansion(row)
        }
      }, this.time)
    },
    // 双击选中
    async deviceDblClick(row) {
      clearTimeout(this.timeOut)
      const type = true
      // 已存在数据
      const existingData = []
      // 不存在数据
      const nonexistentData = []
      // 初始化数据
      const initData = [...this.selectedDataList]
      const departmentListList = [row, ...row.allDepartmentList]
      departmentListList.forEach((item) => {
        const index = initData.findIndex((v) => v.id === item.id)
        if (index !== -1) {
          existingData.push(item)
          initData.splice(index, 1)
        } else {
          nonexistentData.push(item)
        }
      })
      if (existingData.length === departmentListList.length) {
        // 删除
        this.selectedDataList = [...initData]
      } else {
        // 加入
        this.selectedDataList.push(...nonexistentData)
      }
      this.setCheckAll(this.selectedDataList.length === this.deviceList.length)
    },
    // 单个选中
    async deviceClick(row) {
      clearTimeout(this.timeOut)
      const index = this.selectedDataList.findIndex((v) => v.id === row.id)
      if (index === -1) {
        this.addSelectList(row)
      } else {
        this.deleteSelectList(index)
      }
    },
    // 添加
    addSelectList(row) {
      this.selectedDataList.push(row)
      this.$nextTick(() => {
        this.setCheckAll(
          this.selectedDataList.length === this.deviceList.length
        )
      })
    },
    // 删除
    deleteSelectList(index) {
      this.selectedDataList.splice(index, 1)
      this.$nextTick(() => {
        this.setCheckAll(
          this.selectedDataList.length === this.deviceList.length
        )
      })
    },
    // 添加选中设备
    addSelectedDataList(selectedDataList = []) {
      if (selectedDataList.length === 0) return
      selectedDataList.forEach((row) => {
        const index = this.selectedDataList.findIndex((v) => v.id === row.id)
        if (index === -1) {
          this.selectedDataList.push(row)
        }
      })
      this.$nextTick(() => {
        this.setCheckAll(
          this.selectedDataList.length === this.deviceList.length
        )
      })
    },
    // 设置选中数据
    setSelectedDataList(selectedDataList = []) {
      this.selectedDataList = [...selectedDataList]
    },
    // 获取选中数据
    getSelectedDataList() {
      return [...this.selectedDataList]
    },
    // 全选
    setCheckAll(checkAll) {
      this.checkAll = typeof checkAll === 'boolean' ? checkAll : !this.checkAll
      this.$emit('on-selectedDataList', this.selectedDataList)
    },
    judgeSelectedFn(row) {
      const index = this.selectedDataList.findIndex((v) => v.id === row.id)
      return index !== -1 ? 'is-checked' : ''
    },
    // 获取数据
    getData() {
      this.loading = true
      this.$api.organization
        .orgTreeWithSimpleDevice({ spotCheckSupport: 1 })
        .then((res) => {
          if (this.filterValue) {
            res = deviceTree.filteringDepartment(
              this.filterValue,
              JSON.parse(JSON.stringify(res))
            )
          }
          this.$nextTick(() => {
            const json = deviceTree.handlerDevTree(this.filterValue, res)
            this.$nextTick(() => {
              this.setDeviceList(this.dataListFilter(json.children))
            })
          })
        })
    },
    // 数据列表过滤
    dataListFilter(list) {
      const filter = (dataList) => {
        const deviceList = []
        const allDepartmentList = []
        const children = []
        for (let i = 0; i < dataList.length; i++) {
          const item = { ...dataList[i] }
          if (item.type === 3) {
            if (item.onlineState === 1) {
              deviceList.push({ ...item })
            }
          } else {
            allDepartmentList.push(item)
            const res = filter(item.children)
            item.children = res.children
            item.childrenDeviceList = res.deviceList
            deviceList.push(...res.deviceList)
            allDepartmentList.push(...res.allDepartmentList)
            item.allDepartmentList = res.allDepartmentList
            children.push({ ...item })
          }
        }
        return {
          children,
          deviceList,
          allDepartmentList
        }
      }
      console.log('filter(list, true).children', filter(list).children)
      return filter(list).children
    },
    // 设置 页面展示数据
    setDeviceList(deviceList = []) {
      const departmentTable = this.$refs.departmentTable
      this.deviceList = deviceList
      if (departmentTable) {
        departmentTable.store.commit('setData', deepmerge([], deviceList))
        if (departmentTable.$ready) {
          this.$nextTick(() => {
            departmentTable.doLayout()
            this.loading = false
          })
        }
      }
      this.loading = false
    }
  }
}
</script>
<style scoped lang="less" rel="stylesheet/less"></style>
