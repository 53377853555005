<!-- 多群组 item对讲群组 -->
<template>
  <div
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    class="w-full h-full p-1 box-border relative z-10"
  >
    <audio id="audio-el" ref="audioEl" class="absolute"></audio>
    <div
      class="w-full h-full overflow-auto bg-backgroundv2 text-main text-xs"
      ref="chatMessageList"
      id="chatMessageList"
    >
      <div
        v-if="chatMessageList.length === 0"
        class="w-full h-full flex box-border items-center justify-center text-center text-auxiliary"
      >
        <div class="">
          <el-tooltip
            class="item"
            effect="dark"
            content="若想开启群组对讲记录，可去群组管理开启保存对讲记录"
            placement="top"
            v-if="
              orgGroupInsertTemp.groupNature === 1 &&
              orgGroupInsertTemp.openRecording !== 1
            "
          >
            <span class="!text-theme fs_12">
              <svg-icon
                svg-name="help"
                class-name="svgBox !w-[12px] !h-[12px] !ml-1"
              />
              帮助
            </span>
          </el-tooltip>
          <div>
            <svg-icon
              svg-name="no_intercom_record"
              className="svgBox !w-[160px] !h-[160px]"
            />
          </div>
          <div class="mt-5">
            <div v-if="orgGroupInsertTemp.openRecording === 1">
              暂无对讲记录
            </div>
            <div v-else>
              <p>
                {{
                  orgGroupInsertTemp.groupNature === 1
                    ? '该群组未开启对讲记录'
                    : '临时群组未开启对讲记录'
                }}
              </p>
              <p>通过下方按钮进行对讲操作</p>
            </div>
          </div>
        </div>
      </div>
      <div
        v-for="(item, index) in chatMessageList"
        :key="item.id"
        :id="`item-${item.id}`"
        class="p-2 box-border flex flex-col items-center"
      >
        <div
          class="min-w-[72px] p-1 bg-backgroundv4 text-center rounded mb-2"
          v-if="
            item.fileDtmEnd.split(' ')[0] !==
            (index > 0 && chatMessageList[index - 1].fileDtmEnd.split(' ')[0])
          "
        >
          {{ item.fileDtmEnd.split(' ')[0] }}
        </div>
        <div
          v-if="item.devUserId === userInfo.id"
          class="h-[50px] flex items-center w-full"
        >
          <div class="mr-2 flex-grow w-0 flex flex-col items-end">
            <div>
              {{ item.devUserName }} ({{ item.serialNum || item.devUserId }})
            </div>
            <div class="w-full flex items-center justify-end">
              <div
                v-if="mediaFileId === item.id && audioType"
                @click="getAudioContext(item.id)"
                class="mr-1 mt-1 rounded-[4px] p-[4px_8px] bg-backgroundv305 text-auxiliary text-xs scale-90 clickBox"
              >
                继续播放
              </div>
              <div
                @click="getAudioContext(item.id)"
                :style="{
                  width:
                    item.audioLength >= 60
                      ? '70%'
                      : `calc(${(item.audioLength / 60) * 90}px + 160px)`,
                  background:
                    mediaFileId === item.id
                      ? 'var(--background-gradientV2)'
                      : 'var(--backgroundv3)'
                }"
                :class="{
                  '!border-theme !shadow-theme': mediaFileId === item.id
                }"
                class="max-w-[70%] h-[28px] flex items-center p-[6px] box-border mt-1 rounded-[4px_0_4px_4px] justify-between relative z-10 clickBox border border-border shadow-[0_0_4px_0] shadow-background"
              >
                <div class="font-bold flex-shrink-0">
                  {{ item.audioLength }}”
                </div>
                <div class="flex-shrink-0 ml-1">
                  <span v-if="item.fileDtmEnd">{{
                    item.fileDtmEnd.split(' ')[1]
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <userHeadPortrait
            width="32px"
            height="32px"
            :audioContextType="mediaFileId === item.id"
            :iconType="false"
            class="point point_dot"
            :device="{
              ...item,
              onlineState: 1
            }"
          ></userHeadPortrait>
        </div>
        <div v-else class="h-[50px] flex items-center w-full">
          <userHeadPortrait
            width="32px"
            height="32px"
            :audioContextType="mediaFileId === item.id"
            :iconType="false"
            class="point point_dot"
            :device="{
              ...item,
              onlineState: 1
            }"
          ></userHeadPortrait>
          <div class="ml-2 flex-grow w-0 flex flex-col items-start">
            <div>
              {{ item.devUserName }} ({{ item.serialNum || item.devUserId }})
            </div>
            <div class="w-full flex items-center">
              <div
                @click="getAudioContext(item.id)"
                :style="{
                  width:
                    item.audioLength >= 60
                      ? '70%'
                      : `calc(${(item.audioLength / 60) * 90}px + 160px)`,
                  background:
                    mediaFileId === item.id
                      ? 'var(--background-gradientV2)'
                      : 'var(--backgroundv3)'
                }"
                :class="{
                  '!border-theme !shadow-theme': mediaFileId === item.id
                }"
                class="max-w-[70%] h-[28px] flex items-center p-[6px] box-border mt-1 rounded-[0_4px_4px] justify-between relative z-10 clickBox border border-border shadow-[0_0_4px_0] shadow-background"
              >
                <div class="flex-shrink-0 mr-1">
                  <span v-if="item.fileDtmEnd">{{
                    item.fileDtmEnd.split(' ')[1]
                  }}</span>
                </div>
                <div class="font-bold flex-shrink-0">
                  {{ item.audioLength }}”
                </div>
              </div>
              <div
                v-if="mediaFileId === item.id && audioType"
                @click="getAudioContext(item.id)"
                class="ml-1 mt-1 rounded-[4px] p-[4px_8px] bg-backgroundv305 text-auxiliary text-xs scale-90 clickBox"
              >
                继续播放
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    realtimeRequestType: {
      // 是否实时请求
      type: Boolean,
      default: () => {
        return true
      }
    },
    orgGroupInsertTemp: {
      type: Object,
      default: () => {
        return {}
        // {
        //   id: 896,
        //       groupNature: 1,
        //     openRecording: 2
        // }
      }
    }
  },
  data() {
    return {
      groupId: null,
      audioUrl: '',
      loading: false,
      newScrollTop: null,
      totalElements: 0,
      page: 0,
      chatMessageList: [],
      chatMessageListEl: null,
      audioEl: null,
      mediaFileId: null,
      audioType: true,
      timer: null
    }
  },
  //components: {},
  computed: {
    ...mapGetters(['userInfo'])
  },
  mounted() {
    this.init()
  },
  destroyed() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
  //created(){ // document.title = this.type === 2 ? '设置手机号' : '绑定手机号' },
  methods: {
    init() {
      this.groupId = this.orgGroupInsertTemp.id
      this.chatMessageList = []
      this.audioType = true
      // this.orgGroupInsertTemp.groupNature === 1 &&
      if (this.orgGroupInsertTemp.openRecording === 1) {
        this.getData()
        this.$nextTick(() => {
          this.initScrollList()
        })
      }
    },
    // 获取新数据
    getNewData() {
      if (!this.realtimeRequestType) return
      const getData = () => {
        const item = this.chatMessageList[this.chatMessageList.length - 1]
        if (!item) {
          this.getData()
          return
        }
        const params = {
          page: 0,
          groupId: this.groupId,
          largeThenTargetFileDtmStart: item.fileDtmStart
        }
        const scrollTop = this.chatMessageListEl.scrollTop
        const height = this.chatMessageListEl.offsetHeight - 46
        const offsetTop = document.querySelector(`#item-${item.id}`).offsetTop
        this.$api.page('mediaFile/orgGroup/intercom', params).then((res) => {
          if (res.code === 200) {
            const { content = [], totalElements = 0 } = res.data
            this.totalElements = totalElements
            this.$nextTick(() => {
              content.forEach((v) => {
                const index = this.chatMessageList.findIndex(
                  (item) => item.id === v.id
                )
                if (index === -1) {
                  this.chatMessageList.push(v)
                }
              })
              if (scrollTop >= offsetTop - height) {
                this.scrollToBottom()
              }
              // else {
              //   this.scrollToBottom()
              // }
              // this.newScrollTop =
              //   this.chatMessageList[this.chatMessageList.length - 1]?.id
            })
          }
        })
      }
      if (this.timer) {
        clearInterval(this.timer)
      }
      this.timer = setInterval(() => {
        getData()
      }, 10000)
    },
    // 获取数据
    getData() {
      let lessThenId = null
      const length = this.chatMessageList.length
      if (length) {
        lessThenId = this.chatMessageList[0].id
      }
      let params = {
        page: 0,
        groupId: this.groupId,
        lessThenTargetFileDtmStart: this.chatMessageList[0]?.fileDtmStart
      }
      this.newScrollTop = null
      this.loading = true
      this.getNewData()
      this.$api.page('mediaFile/orgGroup/intercom', params).then((res) => {
        if (res.code === 200) {
          const { content = [], totalElements = 0 } = res.data
          this.totalElements = totalElements
          this.$nextTick(() => {
            if (lessThenId) {
              content.forEach((v) => {
                const index = this.chatMessageList.findIndex(
                  (item) => item.id === v.id
                )
                if (index === -1) {
                  this.chatMessageList.unshift(v)
                }
              })
            } else {
              this.chatMessageList = content.reverse()
            }
            if (lessThenId) {
              this.scrollToMsg(lessThenId)
              this.newScrollTop =
                this.chatMessageList[this.chatMessageList.length - 1]?.id
            } else {
              this.scrollToBottom()
            }
            this.$nextTick(() => {
              this.loading = false
            })
          })
        }
      })
    },
    // 定位到消息所在位置
    scrollToMsg(itemId) {
      this.$nextTick(() => {
        this.chatMessageListEl.scrollTo(
          0,
          document.querySelector(`#item-${itemId}`).offsetTop - 46
        )
      })
    },
    topEvent() {
      if (this.totalElements !== 0) {
        this.getData()
      }
    },
    scrollToBottom() {
      this.$nextTick(() => {
        this.chatMessageListEl.scrollTo(0, this.chatMessageListEl.scrollHeight)
      })
    },
    initScrollList() {
      this.chatMessageListEl = this.$refs.chatMessageList
      this.audioEl = this.$refs.audioEl
      this.audioEl.addEventListener('ended', () => {
        this.audioPause()
        this.mediaFileId = null
      })
      this.chatMessageListEl.onscroll = () => {
        const scrollTop = this.chatMessageListEl.scrollTop
        if (scrollTop === 0) {
          this.topEvent()
        }
        // else if (this.newScrollTop) {
        //   const height = this.chatMessageListEl.offsetHeight - 46
        //   const offsetTop = document.querySelector(
        //     `#item-${this.newScrollTop}`
        //   ).offsetTop
        //   console.log('newScrollTop', this.newScrollTop)
        //   if (scrollTop >= offsetTop - height) {
        //     console.log('001')
        //   }
        // }
      }
    },
    audioPlay() {
      this.audioType = false
      this.audioEl.play()
    },
    audioPause() {
      this.audioType = true
      this.audioEl.pause()
    },
    /**
     * 获取音频真实地址
     */
    getAudioContext(mediaFileId) {
      if (this.mediaFileId !== mediaFileId) {
        this.audioPause()
        this.$api.mediaFile
          .redirectFn(`/mediaFile/redirect?mediaFileId=${mediaFileId}`)
          .then((res) => {
            this.mediaFileId = mediaFileId
            this.audioEl.src = URL.createObjectURL(res)
            this.audioUrl = URL.createObjectURL(res)
            this.audioPlay()
          })
          .catch(() => {
            this.$message.error('网络不佳，请稍后重试')
          })
      } else {
        if (this.audioType) {
          this.audioPlay()
        } else {
          this.audioPause()
        }
      }
    }
  }
}
</script>
<style scoped lang="less" rel="stylesheet/less"></style>
