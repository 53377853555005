<!-- 部门层级选择器 -->
<template>
  <div class="pos_r">
    <div v-if="contentType">
      <div
        :style="{
          opacity: focusType ? 1 : 0,
          position: focusType ? 'relative' : 'absolute'
        }"
        class="top-0 left-0"
      >
        <el-cascader
          class="w-full"
          v-model="value"
          :size="size"
          :options="options"
          :disabled="disabled"
          @visible-change="visibleChange($event)"
          @change="handleChange"
          :props="{ checkStrictly: true }"
          clearable
          :placeholder="placeholder"
          filterable
          :show-all-levels="false"
          ref="orgCascader"
        ></el-cascader>
      </div>
      <span v-if="!focusType" style="cursor: pointer">
        <i class="el-icon-caret-bottom"></i>
        <slot></slot>
        <span class="fs_12 c_9DA7B2" v-if="value.length"
          >({{ organizationListFn() }})</span
        >
      </span>
    </div>
    <el-cascader
      v-else
      :class="className"
      class="w-full"
      v-model="value"
      :size="size"
      :options="options"
      @change="handleChange"
      :props="{ checkStrictly: true }"
      clearable
      :disabled="disabled"
      :placeholder="placeholder"
      filterable
      :show-all-levels="false"
      ref="orgCascader"
    ></el-cascader>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
export default {
  props: {
    size: {
      type: String,
      default: () => {
        return 'mini'
      }
    },
    className: {
      type: String,
      default: () => {
        return ''
      }
    },
    placeholder: {
      type: String,
      default: () => {
        return '请选择部门'
      }
    },
    /*
      * false 直接显示输入框
      * true 文字和输入框切换
      * */
    contentType: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    disabled: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  data () {
    return {
      focusType: false,
      value: [],
      organizationName: null,
      organizationList: [],
      options: []
    }
  },
  methods: {
    organizationListFn () {
      if (this.value.length) {
        const id = this.value[this.value.length - 1]
        const organizationRow = this.organizationList.find(v => v.value === id)
        return organizationRow.label
      }
    },
    visibleChange (type) {
      this.focusType = type
    },
    handleChange (value) {
      this.$refs.orgCascader.dropDownVisible = false
      this.visibleChange(false)
      this.$emit('change', value)
    },
    getData () {
      this.$api.organization.orgTreeSimple().then(res => {
        this.handlerData(res.data)
      })
      // this.$api.findAll('organization').then(res => {
      //   this.handlerData(res.data)
      // })
    },
    handlerData (data) {
      this.organizationList = []
      const handler = (list) => {
        list.forEach(item => {
          item.label = item.companyName
          item.value = item.id
          this.organizationList.push({
            label: item.companyName,
            value: item.id
          })
          if (item.children && item.children.length > 0) {
            handler(item.children)
          } else {
            delete item.children
          }
        })
      }
      handler(data)
      this.options = data
    },
    /**
       * 外部传入部门ID，并且设置入选择器中
       */
    setDefaultValue (organizationId) {
      this.value = organizationId
    }
  },
  mounted () {
    this.getData()
  }
}
</script>
