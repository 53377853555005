import Axios from '@/http'
const directTransmission = true
export default {
  /* createUniqueId 生成随机字符串 */
  createUniqueId(n) {
    var random = function () {
      // 生成10-12位不等的字符串
      return Number(Math.random().toString().substr(2)).toString(36) // 转换成十六进制
    }
    var arr = []
    function createId() {
      var num = random()
      var _bool = false
      arr.forEach((v) => {
        if (v === num) _bool = true
      })
      if (_bool) {
        createId()
      } else {
        arr.push(num)
      }
    }
    var i = 0
    while (i < n) {
      createId()
      i++
    }
    return arr[0]
  },
  /* 直传 */
  getAwsAuth(file) {
    return new Promise((resolve) => {
      Axios.post('file/presignedUrl', {
        fileName: this.createUniqueId(1) + file.name
      }).then((res) => {
        resolve(res.data)
      })
    })
  },
  /* 调用接口上传 */
  fileUpload(file) {
    return new Promise((resolve) => {
      const formData = new FormData()
      formData.append('file', file)
      Axios.post('/file/upload', formData, {
        otherUrl: true,
        xAmzAcl: false
      }).then((res) => {
        resolve(res)
      })
    })
  },
  /* 上传返回所有内容 */
  uploadReturnAllContent(file) {
    return new Promise((resolve) => {
      if (directTransmission) {
        this.getAwsAuth(file).then((item) => {
          const { url } = item
          Axios.put(url, file, {
            otherUrl: true,
            xAmzAcl: true
          }).then((res) => {
            resolve(item)
          })
        })
      } else {
        this.fileUpload(file).then((url) => {
          resolve(url)
        })
      }
    })
  },
  upload(file) {
    return new Promise((resolve) => {
      if (directTransmission) {
        this.getAwsAuth(file).then((item) => {
          const { url } = item
          Axios.put(url, file, {
            otherUrl: true,
            xAmzAcl: true
          }).then((res) => {
            resolve(url.split('?')[0])
          })
        })
      } else {
        this.fileUpload(file).then((url) => {
          resolve(url)
        })
      }
    })
  }
}
