import draggable from 'vuedraggable'
import elDragDialog from '@components/el-drag-dialog'
import selectRangeDevice from '@components/device/selectRangeDevice'
import issueMessage from '@components/issueMessage/issueMessage'
export default {
  data() {
    return {
      dragLevel: 0,
      transcribeClientIdList: [],
      sceneName: null,
      /* 当前按钮按键 */
      currentKeyCode: null,
      currentDevice: {},
      operationList: [
        { type: 4, name: '声 音' },
        { type: 10, name: '广 播' },
        { type: 12, name: '发送联动消息' },
        { type: 16, name: '切换视频通道' }
      ],
      emptySelect: null,
      dialogTableVisible: false,
      monitorDeviceList: [],
      monitorDeviceListV2: [],
      /* 窗口控制 */
      numberOfrows: { label: 1, number: 1, type: 1 },
      numberOfrowsList: [
        { label: 1, number: 1, type: 1 },
        { label: 4, number: 2, type: 1 },
        { label: 6, number: 3, type: 2 },
        { label: 8, number: 4, type: 2 },
        { label: 9, number: 3, type: 1 },
        { label: 16, number: 4, type: 1 }
      ],
      timer: null,
      alarmRecord: null,
      //  拖拽移动
      draggedContext: null,
      draggedContextSetTimeout: null,
      elementResizeDetectorMaker: null
    }
  },
  watch: {
    monitorDeviceList: function (val) {
      if (this.monitorDeviceListV2.length !== val.length) {
        const arr = []
        for (let i = 0; i < val.length; i++) {
          arr.push(i + 1)
        }
        this.monitorDeviceListV2 = arr
      }
    }
  },
  components: {
    draggable,
    selectRangeDevice,
    issueMessage
  },
  directives: { elDragDialog },
  methods: {
    // ================ 拖拽移动 ====================
    // 正在拖动回调
    onMove(e) {
      if (this.draggedContextSetTimeout) {
        clearTimeout(this.draggedContextSetTimeout)
      }
      this.draggedContextSetTimeout = setTimeout(() => {
        this.draggedContext = {}
      }, 100)
      if (!this.draggedContext) {
        this.draggedContext = e.draggedContext
      } else if (
        this.draggedContext.futureIndex !== e.draggedContext.futureIndex ||
        this.draggedContext.index !== e.draggedContext.index
      ) {
        this.draggedContext = e.draggedContext
      }
      return false
    },
    // 拖拽结束事件
    onEnd() {
      if (
        this.draggedContext &&
        (this.draggedContext.futureIndex ||
          this.draggedContext.futureIndex === 0)
      ) {
        this.moveInput = this.draggedContext.futureIndex + 1
        this.movePosition('custom', this.draggedContext.index)
      }
      this.$nextTick(() => {
        clearTimeout(this.draggedContextSetTimeout)
        this.draggedContextSetTimeout = {}
      })
    },
    movePosition(direction, index1) {
      let index2 = null
      if (direction === 'left') {
        index2 = index1 - 1
      } else if (direction === 'right') {
        index2 = index1 + 1
      } else if (direction === 'top') {
        if (this.numberOfrows.type === 2 && this.numberOfrows.number > index1) {
          index2 = index1 - 1
        } else {
          index2 = index1 - this.numberOfrows.number
        }
      } else if (direction === 'top_left') {
        index2 = index1 - this.numberOfrows.number - 1
      } else if (direction === 'top_right') {
        index2 = index1 - this.numberOfrows.number + 1
      } else if (direction === 'bottom') {
        if (this.numberOfrows.type === 2 && this.numberOfrows.number > index1) {
          if (this.numberOfrows.number - 1 === index1) {
            index2 = index1 + this.numberOfrows.number
          } else {
            index2 = index1 + 1
          }
        } else {
          index2 = index1 + this.numberOfrows.number
        }
      } else if (direction === 'bottom_left') {
        index2 = index1 + this.numberOfrows.number - 1
      } else if (direction === 'bottom_right') {
        index2 = index1 + this.numberOfrows.number + 1
      } else if (direction === 'custom') {
        index2 = this.moveInput - 1
      }
      if ((!this.moveInput || this.moveInput < 0) && direction === 'custom') {
        // 不能自定义移动
        this.$message.error('无法移动')
        return
      } else if (index2 > this.monitorDeviceList.length - 1) {
        // 往前超出了
        this.$message.error('无法移动')
        return
      } else if (index2 < 0) {
        // 往后超出了
        this.$message.error('无法移动')
        return
      }
      if (direction === 'custom') {
        this.moveInput = null
      }
      this.swapArray(index1, index2)
    },
    swapArray(index1, index2) {
      this.monitorDeviceList.forEach((item) => {
        if (item.deviceId) {
          const el = document.getElementById(`${item.deviceId}${item.roomId}`)
          const parent = document.getElementById('app') || document.body
          parent.append(el)
        }
      })
      if (this.monitorDeviceList.length < index2) {
        this.monitorDeviceList[index2] = {}
      }
      this.$nextTick(() => {
        this.monitorDeviceList[index1] = this.monitorDeviceList.splice(
          index2,
          1,
          this.monitorDeviceList[index1]
        )[0]
        this.$nextTick(() => {
          this.monitorDeviceList.forEach((item) => {
            if (item.deviceId) {
              const el = document.getElementById(`videoBox_${item.deviceId}`)
              const childEl = document.getElementById(
                `${item.deviceId}${item.roomId}`
              )
              if (el && childEl) {
                el.appendChild(childEl)
              }
            }
          })
        })
      })
    },
    getTime(time) {
      if (typeof time !== 'number') {
        return '00:00'
      }
      const h = parseInt(time / 3600)
      const minute = parseInt((time / 60) % 60)
      const second = Math.ceil(time % 60)
      const hours = h < 10 ? '0' + h : h
      const formatSecond = second > 59 ? 59 : second
      return `${hours > 0 ? `${hours}:` : ''}${
        minute < 10 ? '0' + minute : minute
      }:${formatSecond < 10 ? '0' + formatSecond : formatSecond}`
    },
    setTimer() {
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }
      this.timer = setInterval(() => {
        this.monitorDeviceList.forEach((item) => {
          if (
            item.deviceId &&
            item.recordRoomId &&
            item.methodType === 'successfulCall'
          ) {
            const recordingTime = item.recordingTime || 0
            this.$set(item, 'recordingTime', recordingTime + 1)
          }
        })
      }, 1000)
    },
    // 警单关闭所有音视频房间
    closeAllRooms() {
      const componentMethodList = [
        'multipleChecks',
        'departmentMultipleChecks',
        'singleTalkback',
        'multiPersonIntercom',
        'singleInspection',
        'broadcast',
        'recording',
        'soundRecording'
      ]
      componentMethodList.forEach((v) => {
        this.$store.commit('callComponent', {
          name: v,
          data: {
            methodName: 'onClose'
          }
        })
      })
    },
    // 设置弹窗展示
    setDialogVisible(type) {
      // if (this.dialogTableVisible === type) return
      if (type) {
        this.addSceneDevice()
        this.setDragLevel()
      }
      this.dialogTableVisible = type
    },
    // 设置弹窗层级
    setDragLevel() {
      if (this.mainStore) {
        this.dragLevel = this.mainStore.getters.dragLevel
        this.mainStore.commit('setDragLevel')
      } else {
        this.dragLevel = this.$store.getters.dragLevel
        this.$store.commit('setDragLevel')
      }
    },
    async show(res = {}) {
      if (!res || !res.id) {
        return
      }
      this.closeAllRooms()
      const alarmRecord = await this.alarmRecordSelect(res.id)
      this.alarmRecord = alarmRecord
      this.progressKey = alarmRecord.progressKey
      this.setTimer()
      this.keyboardPperationFn()
      if (this.monitorDeviceList.length === 0) {
        this.setNumberOfrows(this.numberOfrowsList[0])
      }
      this.setDialogVisible(true)
      if (alarmRecord.deviceId) {
        const { code, data } = await this.$api.device.detail(
          alarmRecord.deviceId
        )
        if (code === 200) {
          this.liveBroadcast([data])
        }
      }
    },
    async alarmRecordSelect(id) {
      const { code, data } = await this.$api.alarmRecord.select({
        alarmRecordId: id
      })
      if (code === 200) {
        return {
          ...data
        }
      }
    },
    // 警单（接警）
    async dealStart(id) {
      const { code } = await this.$api.alarmRecord.dealStart({
        alarmRecordId: id
      })
      if (code === 200) {
        this.$emit('on-alarmRecordOperatorInfo')
        this.$message.success('开始接警')
      }
    },
    // 警单（结束）
    dealEnd() {
      const dealEnd = async () => {
        const { code } = await this.$api.alarmRecord.dealEnd({
          alarmRecordId: this.alarmRecord.id
        })
        if (code === 200) {
          this.dialogTableVisible = false
          this.monitorDeviceList = []
          this.$message.success('警单已结束，可在警单中查看记录')
          this.$nextTick(() => {
            this.$emit('on-alarmRecordOperatorInfo')
          })
        }
      }
      this.$deleteConfirmationBox(
        {
          deleteTitleImg: 'tips',
          deleteTitle: '结束警情',
          deleteContent: `<div><p>确认结束警情</p></div>`,
          closeText: '取 消',
          submitClass: 'button_function',
          submitText: '确 认'
        },
        () => {
          this.closeTheRoom(this.monitorDeviceList).then(() => {
            dealEnd()
          })
        }
      )
    },
    /* 获取当前平台在调用的设备 */
    getListOfRunningDevices(obj) {
      // obj === null 放回全部设备
      // obj.audioType === true 返回对接中的设备
      // obj.videoType === true 返回调用画面的设备
      // obj.appointed 返回指定类型的设备
      return this.$sdk.obtainAllClientIdList(obj) || []
    },
    liveBroadcast(monitorDeviceList = []) {
      const obtainAllDeviceList = this.getListOfRunningDevices({
        videoType: true
      })
      const offLineList = [] // 离线设备统计
      const notSupportedList = [] // 不支持设备统计
      let deviceList = [] // 可以对接设备
      const liveBroadcastingList = [] // 正在直播
      monitorDeviceList.forEach((device) => {
        if (device.onlineState !== 1) {
          offLineList.push(device)
        } else if (device.monitorSupport !== 1) {
          notSupportedList.push(device)
        } else {
          const index = obtainAllDeviceList.findIndex(
            (v) => v.clientId === device.deviceId
          )
          if (index === -1) {
            deviceList.push(device)
          } else {
            liveBroadcastingList.push(device)
          }
        }
      })
      let message = ''
      if (offLineList.length) {
        message += `<div class="lh_20">有${offLineList.length}台设备不在线无法发起视频</div>`
      }
      if (notSupportedList.length) {
        message += `<div class="lh_20">有${notSupportedList.length}台设备不支持视频</div>`
      }
      if (liveBroadcastingList.length) {
        message += `<div class="lh_20">有${liveBroadcastingList.length}台设备正在直播</div>`
      }
      if (message.length !== 0) {
        this.$message.error({
          duration: 8000,
          dangerouslyUseHTMLString: true,
          message: `${message} <div class="lh_20">一共 ${
            offLineList.length +
            notSupportedList.length +
            liveBroadcastingList.length
          } 台设备无法拉起画面</div>`
        })
      }
      this.$nextTick(() => {
        const deviceListQuantity = deviceList.length
        if (deviceListQuantity !== 0) {
          const quantity = 16 // 上限数量
          this.setDialogVisible(true)
          const monitorDeviceListQuantity = this.monitorDeviceList.filter(
            (v) => v.deviceId
          ).length
          const num = quantity - monitorDeviceListQuantity
          for (let i = 0; i < num; i++) {
            if (deviceList[i]) {
              this.singleJoin(deviceList[i])
            }
          }
          if (
            monitorDeviceListQuantity >= quantity ||
            deviceListQuantity + monitorDeviceListQuantity >= quantity
          ) {
            setTimeout(() => {
              this.$message.error(`当前窗口上限${quantity}屏`)
            })
          }
        }
      })
    },
    singleJoin(row) {
      if (row.onlineState !== 1) {
        this.$emit('on-infoClose', row)
        this.$message.error('当前设备不在线无法发起视频')
        return
      } else if (row.monitorSupport !== 1) {
        this.$emit('on-infoClose', row)
        this.$message.error('当前设备不支持视频')
        return
      }
      row = { ...row }
      if (
        this.emptySelect ||
        (this.emptySelect === 0 &&
          !this.monitorDeviceList[this.emptySelect].deviceId)
      ) {
        this.$set(this.monitorDeviceList, this.emptySelect, row)
        this.emptySelect = null
        this.$nextTick(() => {
          if (row.monitorSupport === 1) {
            this.monitorFn(row)
          }
        })
      } else {
        const index = this.monitorDeviceList.findIndex(
          (o) => o.deviceId === row.deviceId
        )
        if (index === -1) {
          /* 是否成功加入 */
          let joinedType = false
          if (row.monitorSupport !== 1) {
            this.$set(row, 'errorCode', 1)
            this.$set(row, 'errorReason', '当前设备不支持监控')
          }
          /* 因为有占位，所以先判断数据有没有占位的数据，有就直接替换 */
          for (let i = 0; i < this.monitorDeviceList.length; i++) {
            const item = this.monitorDeviceList[i]
            if (!item.deviceId) {
              joinedType = true
              this.$set(this.monitorDeviceList, i, row)
              break
            }
          }
          if (!joinedType) {
            this.monitorDeviceList.push(row)
          }
          const num = this.monitorDeviceList.length
          this.$nextTick(() => {
            if (row.monitorSupport === 1) {
              this.monitorFn(row)
            }
            for (let i = 0; i < this.numberOfrowsList.length; i++) {
              const numberOfrowsRow = this.numberOfrowsList[i]
              if (
                num === numberOfrowsRow.label ||
                num < numberOfrowsRow.label
              ) {
                this.setNumberOfrows(numberOfrowsRow)
                break
              }
            }
          })
        }
      }
    },
    /* 设备监听 */
    addSceneDevice() {
      if (this.sceneName) {
        return
      }
      this.$business.addSceneDevice({
        sceneName: this.sceneName,
        sceneNameCallback: (sceneName) => {
          this.sceneName = sceneName
        },
        callback: (res) => {
          const index = this.monitorDeviceList.findIndex(
            (v) => v.deviceId === res.deviceId
          )
          if (index !== -1) {
            this.setDeviceItemChange(this.monitorDeviceList[index], res)
          }
        }
      })
    },
    setDeviceItemChange(deviceItem, res) {
      this.$set(deviceItem, 'beMonitor', res.beMonitor)
      this.$set(deviceItem, 'videoCall', res.videoCall)
      this.$set(deviceItem, 'videoRecording', res.videoRecording)
      this.$set(deviceItem, 'audioRecording', res.audioRecording)
      this.$set(deviceItem, 'intercomCall', res.intercomCall)
      this.$set(deviceItem, 'intercomGroupNature', res.intercomGroupNature)
      this.$set(deviceItem, 'intercomGroupTem', res.intercomGroupTem)
      this.$set(deviceItem, 'onlineState', res.onlineState)
      this.$set(deviceItem, 'sos', res.sos)
      this.$set(deviceItem, 'broadcast', res.broadcast)
      if (deviceItem.facere !== res.facere) {
        this.$set(deviceItem, 'facereLoading', false)
      }
      if (deviceItem.pfacere !== res.pfacere) {
        this.$set(deviceItem, 'pfacereLoading', false)
      }
      if (deviceItem.platere !== res.platere) {
        this.$set(deviceItem, 'platereLoading', false)
      }
      this.$set(deviceItem, 'facere', res.facere)
      this.$set(deviceItem, 'platere', res.platere)
      this.$set(deviceItem, 'pfacere', res.pfacere)
    },
    setOperationAreaHide(item, type) {
      this.$set(item, 'operationAreaHide', type)
    },

    // 设置窗口
    setNumberOfrows(row) {
      const monitorDeviceList = [...this.monitorDeviceList]
      if (
        row.label > this.numberOfrows.label ||
        row.label === this.numberOfrows.label
      ) {
        const completionNum = row.label - monitorDeviceList.length
        if (completionNum) {
          for (let i = 0; i < completionNum; i++) {
            monitorDeviceList.push({})
          }
        }
        this.monitorDeviceList = [...monitorDeviceList]
        this.numberOfrows = { ...row }
        this.$emit('on-modifyWindow', this.numberOfrows)
      } else {
        const nextStepFn = () => {
          const dataList = []
          monitorDeviceList.forEach((item) => {
            if (item.deviceId) {
              if (!item.errorCode) {
                const el = document.getElementById(
                  `${item.deviceId}${item.roomId}`
                )
                if (el) {
                  const parent = document.getElementById('app') || document.body
                  parent.append(el)
                }
                dataList.push(item)
              }
            }
          })
          const num = dataList.length - row.label
          let closeTheRoomList = []
          if (num > 0) {
            closeTheRoomList = dataList.splice(row.label, dataList.length - 1)
          } else {
            for (let i = 0; i < -num; i++) {
              dataList.push({})
            }
          }
          this.closeTheRoom(closeTheRoomList)
            .then(() => {
              this.emptySelect = null
              this.numberOfrows = { ...row }
              this.$emit('on-modifyWindow', this.numberOfrows)
              this.$nextTick(() => {
                this.monitorDeviceList = [...dataList]
                this.$nextTick(() => {
                  this.videoRestoreLocation()
                })
              })
            })
            .catch(() => {
              this.$nextTick(() => {
                this.videoRestoreLocation()
              })
            })
        }
        if (this.dialogTableVisible) {
          this.$deleteConfirmationBox(
            {
              deleteTitleImg: 'tips',
              deleteTitle: '是否确认切换',
              submitClass: 'button_function',
              deleteContent:
                '<div><p>切换窗口内容会自动关闭多余窗口，是否确认切换？</p></div>',
              submitText: '确 定'
            },
            () => {
              nextStepFn()
            }
          )
        } else {
          nextStepFn()
        }
      }
    },
    videoRestoreLocation() {
      this.monitorDeviceList.forEach((item) => {
        if (item.deviceId) {
          const el = document.getElementById(`videoBox_${item.deviceId}`)
          const childEl = document.getElementById(
            `${item.deviceId}${item.roomId}`
          )
          if (el && childEl) {
            el.appendChild(childEl)
          }
        }
      })
    },
    // 指定视频监视窗口
    setEmptySelect(emptySelect) {
      this.emptySelect = emptySelect
    },
    // 设置选择位置
    setPositionSelection(index) {
      this.setEmptySelect(index)
      this.addMonitorDevice(index)
    },
    async linkScopeConfig() {
      const { code, data } = await this.$api.alarmRecord.linkScopeConfig()
      if (code === 200) {
        this.$set(this.alarmRecord, 'distance', data.configValue - 0)
      }
    },
    // 添加设备
    addMonitorDevice() {
      this.linkScopeConfig().then(() => {
        this.zIndex = 0
        this.$message.success('请选择监控的设备')
        this.$refs.selectRangeDevice
          .show({
            lng: this.alarmRecord.lng,
            lat: this.alarmRecord.lat,
            distance: this.alarmRecord.distance,
            monitorSupport: 1
          })
          .then((data = []) => {
            this.liveBroadcast(data)
          })
      })
    },
    // 获取高度
    getHeight(type, index) {
      if (this.numberOfrows.type === 1) {
        if (this.numberOfrows.number === 1 && index === 0) {
          return '100%'
        } else {
          return parseInt(100 / this.numberOfrows.number) + '%'
        }
      } else {
        let height = parseInt(100 / this.numberOfrows.number)
        if (index === 0) {
          if (this.numberOfrows.number === 3) {
            height -= 0.1
          }
          return height * (this.numberOfrows.number - 1) + '%'
        } else {
          return height + '%'
        }
      }
    },
    issueMessage(item) {
      this.linkScopeConfig().then(() => {
        const deviceList = []
        if (item) {
          deviceList.push(item)
        }
        this.zIndex = 0
        this.$refs.issueMessage?.show(deviceList, {
          addNewsType: 'alarmRecordAddNews',
          title: '发送报警联动消息',
          requestParams: this.alarmRecord
        })
      })
    },
    // 关闭房间
    closeTheRoom(closeTheRoomList = [], successCallback) {
      return new Promise((resolve, reject) => {
        const dataList = []
        closeTheRoomList.forEach((item) => {
          const index = this.monitorDeviceList.findIndex(
            (v) => v.deviceId === item.deviceId
          )
          if (index !== -1) {
            dataList.push({
              ...this.monitorDeviceList[index],
              monitorDeviceListIndex: index
            })
          }
        })
        if (dataList.length) {
          this.$nextTick(() => {
            if (dataList.length) {
              if (this.cannotCloseData(dataList).length) {
                reject(new Error('正在处理设备连接，请稍后重试操作'))
                this.$message.error('正在处理设备连接，请稍后重试操作')
              } else {
                this.drawer = false
                this.broadcast_closeTheRoom(dataList)
                dataList.forEach((row) => {
                  const index = row.monitorDeviceListIndex
                  const item = this.monitorDeviceList[index]
                  if (item.deviceId) {
                    this.recordRoomOperate_recordStop(item)
                    if (this.currentDevice.deviceId === item.deviceId) {
                      this.setCurrentDevice(item, true)
                    }
                    const el = document.getElementById(
                      `${item.deviceId}${item.roomId}`
                    )
                    if (item) {
                      if (el) {
                        el.parentNode.removeChild(el)
                      }
                      if (item.roomId) {
                        this.$sdk.exitRoomFn(item.roomId)
                      }
                      this.$set(this.monitorDeviceList, index, {
                        ...item,
                        deviceId: null
                      })
                      this.$emit('on-infoClose', item)
                    }
                  }
                })
                this.$nextTick(() => {
                  this.monitorDeviceList.forEach((item) => {
                    if (item.deviceId) {
                      const el = document.getElementById(
                        `videoBox_${item.deviceId}`
                      )
                      const childEl = document.getElementById(
                        `${item.deviceId}${item.roomId}`
                      )
                      if (el && childEl) {
                        el.appendChild(childEl)
                      }
                    }
                  })
                  resolve()
                  if (successCallback) {
                    successCallback()
                  }
                })
              }
            } else {
              reject(new Error('正在处理设备连接，请稍后重试操作'))
              this.$message.error('正在处理设备连接，请稍后重试操作')
            }
          })
        } else {
          resolve()
          if (successCallback) {
            successCallback()
          }
        }
      })
    },
    // 无法关闭的数据
    cannotCloseData(dataList) {
      return dataList.filter((v) => {
        if (v.deviceId) {
          if (v.intercomInviteState) {
            return true
          }
          if (v.errorCode || v.methodType || v.roomId) {
            return false
          } else {
            return true
          }
        } else {
          return false
        }
      })
    },

    // ================ 监控 ====================
    // 发起设备监控
    monitorFn(device) {
      this.sendingMonitor(device)
    },
    // 发送通知开启监控
    sendingMonitor(device) {
      if (device.deviceId) {
        this.recordRoomCreate(device)
        this.$sdk.monitor(
          {
            containerName: `videoBox_${device.deviceId}`,
            muted: false,
            // 有通道用通道，没有通道才使用设备id
            clientId: device.channelId || device.deviceId
          },
          (res) => {
            this.monitorFailCallback(res, device)
          },
          (res) => {
            this.monitorSuccessCallback(res, device)
          }
        )
      }
    },
    // 监控失败
    monitorFailCallback(res, device) {
      const index = this.monitorDeviceList.findIndex(
        (v) => v.deviceId === device.deviceId
      )
      if (index !== -1) {
        this.$set(this.monitorDeviceList[index], 'intercomInviteState', false)
        this.$set(
          this.monitorDeviceList[index],
          'errorCode',
          res.errorCode || 1002
        )
        this.$set(this.monitorDeviceList[index], 'errorReason', res.errorReason)
      }
    },
    // 监控成功
    monitorSuccessCallback(res, device) {
      if (res.clientIdList && res.clientIdList.length) {
        if (res.status !== 'audioContextType') {
          this.transcribeClientIdList = res.clientIdList
        }
        const resMonitorDeviceList = res.clientIdList.filter((v) => {
          return v.clientId
        })
        resMonitorDeviceList.forEach((v) => {
          if (device.deviceId === v.clientId) {
            this.$emit('on-deviceStatus', {
              deviceId: v.clientId,
              methodType: v.methodType
            })
          }
          const clientIdIndex = this.monitorDeviceList.findIndex(
            (j) => j.deviceId === v.clientId
          )
          if (clientIdIndex !== -1) {
            /* 设备在别处对剑的时候，提示 */
            if (v.errorCode === 701) {
              v.errorReason = '未进组'
            }
            const item = this.monitorDeviceList[clientIdIndex]
            const methodType = v.methodType
            if (methodType !== 'exitRoom') {
              this.$set(item, 'intercomInviteState', false)
            }
            // &&
            // v.audioPushing &&
            // v.videoPushing
            if (
              v.methodType === 'successfulCall' &&
              v.consumers &&
              res.status === 'canPlay'
            ) {
              this.recordRoomOperate_recordStart(item)
            }

            if (
              item.methodType !== methodType &&
              methodType === 'successfulCall'
            ) {
              this.$emit('on-deviceMonitorOpen', v.clientId)
            }
            const clientIdRow = {
              roomId: res.roomId,
              ...item,
              ...v
            }
            const el = document.getElementById(
              `${clientIdRow.deviceId}${clientIdRow.roomId}`
            )
            if (el) {
              this.$set(clientIdRow, 'forbiddenSound', el.muted)
              if (!clientIdRow.soundControl) {
                this.$set(clientIdRow, 'soundControl', '1')
              }
              el.setAttribute('playsinline', 'true')
              el.setAttribute('x5-video-player-fullscreen', 'true')
              el.setAttribute('x5-playsinline', 'true')
              el.setAttribute('webkit-playsinline', 'true')
              setTimeout(() => {
                el.play()
              }, 1000)
            }
            this.setCurrentDevice(clientIdRow)
            this.$set(this.monitorDeviceList, clientIdIndex, clientIdRow)
          }
        })
      } else {
        if (res.roomId) {
          const index = this.monitorDeviceList.findIndex(
            (v) => v.deviceId === device.deviceId
          )
          if (index !== -1) {
            this.$set(this.monitorDeviceList[index], 'roomId', res.roomId)
          }
        }
      }
    },

    /* 重新连接 */
    reconnectFn(item) {
      if (item.intercomInviteState) return
      this.$set(item, 'recordingTime', 0)
      this.$set(item, 'intercomInviteState', true)
      this.$set(item, 'broadcastType', false)
      this.$set(item, 'broadcastLoading', false)

      if (item.errorCode || item.methodType || item.roomId) {
        this.$nextTick(() => {
          const el = document.getElementById(`${item.deviceId}${item.roomId}`)
          if (el) {
            el.parentNode.removeChild(el)
          }
          if (item.roomId) {
            this.$sdk.exitRoomFn(item.roomId)
          }
          if (item.recordRoomId) {
            this.recordRoomOperate_recordStop(item)
          }
          this.$message.success('重新发起连接')
          this.broadcast_closeTheRoom([item])
          setTimeout(() => {
            this.monitorFn(item)
          }, 500)
        })
      } else {
        this.$message.error('正在处理设备连接，请稍后重试操作')
      }
    },
    // ================ 声音控制 ====================
    /* 打开或者关闭声音方法 */
    forbiddenSound(deviceId, type) {
      const item = this.monitorDeviceList.find((v) => v.deviceId === deviceId)
      if (item.roomId) {
        const label = document.getElementById(`${item.deviceId}${item.roomId}`)
        if (label) {
          if (typeof type === 'boolean') {
            this.$set(item, 'forbiddenSound', type)
            label.muted = type
          } else {
            if (!item.forbiddenSound) {
              this.$set(item, 'forbiddenSound', true)
              label.muted = true
              this.$message.warning('您已将该设备静音')
            } else {
              this.$set(item, 'forbiddenSound', false)
              label.muted = false
            }
          }
          this.$emit('on-forbiddenSound', {
            deviceId,
            muted: label.muted
          })
        }
      }
      this.monitorForbiddenSound(deviceId, type)
    },
    monitorForbiddenSound(deviceId, type) {
      const judgment = (item) => {
        if (item && item.deviceId === deviceId) {
          if (typeof type === 'boolean') {
            this.$set(item, 'forbiddenSound', type)
          } else {
            if (!item.forbiddenSound) {
              this.$set(item, 'forbiddenSound', true)
            } else {
              this.$set(item, 'forbiddenSound', false)
            }
          }
        }
      }
      // 全屏
      judgment(this.currentZoomIn)
      this.$emit('on-monitorBroadcastDeviceList', {
        deviceId,
        type
      })
    },
    // 音量进度条
    getPercent(num, total) {
      num = parseFloat(num)
      total = parseFloat(total)
      if (isNaN(num) || isNaN(total)) {
        return 0
      }
      return total <= 0 ? 0 : Math.round((num / total) * 10000) / 100.0
    },
    // 音量设置
    setSoundControl(deviceId, soundControl) {
      const item = this.monitorDeviceList.find((v) => v.deviceId === deviceId)
      if (item.roomId) {
        const el = document.getElementById(`${item.deviceId}${item.roomId}`)
        if (el) {
          el.volume = soundControl
        }
        this.$set(item, 'soundControl', soundControl)
      }
      if (this.currentZoomIn && this.currentZoomIn.deviceId) {
        this.$set(this.currentZoomIn, 'soundControl', soundControl)
      }
    },
    // ================ 控制键盘操作中控 ====================
    // 选择当前选中的设备对象
    setCurrentDevice(device, emptyType) {
      if (this.currentDevice.deviceId === device.deviceId && !emptyType) {
        // console.log('选中的设备和现在选中的是同一个设备')
        return
      }
      if (device.cloudControlSupport !== 1 && device.zoomControlSupport !== 1) {
        // console.log('当前设备没有中控操作')
        return
      }
      this.currentKeyCode = null
      if (this.currentDevice.deviceId) {
        this.notificationDeviceControl('PTZ_STOP', this.currentDevice)
      }
      this.$nextTick(() => {
        if (emptyType) {
          this.currentDevice = {}
        } else {
          this.currentDevice = device
        }
      })
    },
    keyboardUnbindFn() {
      document.onkeydown = null
      document.onkeyup = null
    },
    // 键盘操作
    keyboardPperationFn() {
      /* 键盘按下 */
      document.onkeydown = (event) => {
        if (!this.currentDevice.deviceId) {
          return
        } else if (this.currentKeyCode === event.keyCode) {
          return
        }
        if (this.currentKeyCode !== null) {
          this.currentKeyCode = null
          this.notificationDeviceControl('PTZ_STOP', this.currentDevice)
        }
        const keyCodeList = [
          { keyCode: 38, direction: '上', command: 'PTZ_UP' },
          { keyCode: 39, direction: '右', command: 'PTZ_RIGHT' },
          { keyCode: 40, direction: '下', command: 'PTZ_DOWN' },
          { keyCode: 37, direction: '左', command: 'PTZ_LEFT' },
          { keyCode: 109, direction: '减缩放', command: 'PTZ_ZOOM_OUT' },
          { keyCode: 107, direction: '加缩放', command: 'PTZ_ZOOM_IN' }
        ]
        const row = keyCodeList.find((v) => v.keyCode === event.keyCode)
        if (row) {
          this.currentKeyCode = event.keyCode
          this.notificationDeviceControl(row.command, this.currentDevice)
        }
      }
      /* 键盘松开 */
      document.onkeyup = (event) => {
        if (!this.currentDevice.deviceId) {
          return
        }
        if (
          event.keyCode === 37 ||
          event.keyCode === 38 ||
          event.keyCode === 39 ||
          event.keyCode === 40 ||
          event.keyCode === 109 ||
          event.keyCode === 107
        ) {
          this.currentKeyCode = null
          this.notificationDeviceControl('PTZ_STOP', this.currentDevice)
        }
      }
    },
    // sdk 中控指令
    notificationDeviceControl(command, device, speed) {
      if (device.roomId && device.deviceId) {
        this.$sdk.notificationDeviceControl({
          command: command,
          roomId: device.roomId,
          clientId: device.deviceId,
          speed: speed || 4
        })
      }
    }
  }
}
