import Axios from '@/http'
export default {
  /* 燃气险情群组（保存） */
  saveGasCaseGroup(params) {
    return new Promise((resolve, reject) => {
      Axios.post('gasCase/saveGasCaseGroup', params).then((res) => {
        resolve(res)
      })
    })
  },
  /* 查询是否打开了险情模式（1已经打开） */
  openGasCaseModeStatus() {
    return new Promise((resolve, reject) => {
      Axios.post('gasCase/openGasCaseModeStatus').then((res) => {
        resolve(res)
      })
    })
  },
  /* 燃气数据（座机信息（不分页）） */
  baseTelephoneFindAll() {
    return new Promise((resolve, reject) => {
      Axios.post('gasCase/base/telephoneFindAll').then((res) => {
        resolve(res)
      })
    })
  },
  /* 保存用户的分机号（燃气险情分机号） */
  userExtenSave(exten) {
    return new Promise((resolve, reject) => {
      Axios.post('gasCase/base/userExtenSave', { exten }).then((res) => {
        resolve(res)
      })
    })
  },
  /* 获取用户的分机号（燃气险情分机号） */
  userExten() {
    return new Promise((resolve, reject) => {
      Axios.post('gasCase/base/userExten').then((res) => {
        resolve(res)
      })
    })
  }
}
