import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
// leaflet.pm插件可添加一些地图工具（如：画点，画圆，画矩形，画线以及清除编辑功能）
import 'leaflet.pm'
import 'leaflet.pm/dist/leaflet.pm.css'

// leaflet.chinatmsproviders插件可加载各种地图（如：智图地图，谷歌地图，高德地图等包含卫星地图）
import 'leaflet.chinatmsproviders'
import 'proj4leaflet'

// leaflet.motion 轨迹回访
import 'leaflet.motion/dist/leaflet.motion.min'

// leaflet.markercluster 插件用于完成聚合点
import 'leaflet.markercluster/dist/leaflet.markercluster.js'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'

// leaflet.polyline 装饰线
import 'leaflet-polylinedecorator/dist/leaflet.polylineDecorator'

// 多边形中的点
const leafletPip = require('leaflet-pip');

// Arcgis服务
var esri = require('esri-leaflet')
var cluster = require('esri-leaflet-cluster')
var related = require('esri-leaflet-related/dist/esri-leaflet-related')

L.esri = esri
L.esri.Cluster = cluster
L.esri.Related = related
const ExportLeaflet = () => {
  return L
}

// geoJSON
const geoJSON = (geojson, options) => L.geoJSON(geojson, options)

// 装饰线
const polylineDecorator = (paths, options) => {
  return L.polylineDecorator(paths, options)
}

// 装饰线-符号实例
const Symbol = () => {
  return L.Symbol
}

// 创建 LatLngBounds 对象
const latLngBounds = () => L.latLngBounds()

// 定义地图实例
const newMap = (domId, option) => {
  const map = L.map(domId, option)
  return map
}
// 切片图层
const createLayers = (map, url, option = {}) => {
  const newLeayer = L.tileLayer(url, option)
  newLeayer.addTo(map)
  return newLeayer
}
// leaflet.ChineseTmsProviders插件加载地图
const chinaLayers = (map, url, option) => {
  const chinaLeayer = L.tileLayer.chinaProvider(url, option)
  chinaLeayer.addTo(map)
  return chinaLeayer
}
const icon = (option) => {
  return L.icon(option)
}

const divIcon = (option) => {
  return L.divIcon(option)
}

const point = (option) => {
  return L.point(option)
}

/* 聚合点 */
const markerClusterGroup = (option) => {
  return L.markerClusterGroup(option)
}

/* 绘画 marker */
const marker = (markerOptions) => {
  const marker = L.marker(markerOptions.position, markerOptions)
  return marker
}

/* 绘画圆 */
const circle = (latLngList, option) => {
  const circle = L.circle(latLngList, option)
  return circle
}

/* 绘画多边形覆盖物 */
const polygon = (pathList, option) => {
  const polygon = L.polygon(pathList, option)
  return polygon
}

/* 弹窗 */
const popup = (option) => {
  const popup = L.popup(option)
  return popup
}

// 折线
const polyline = (latlngs, options) => {
  return L.polyline(latlngs, options)
}

const latLngFn = (y, x) => {
  return L.latLng(y, x)
}

const TileLayer = () => {
  return L.TileLayer
}

const esriFn = () => {
  return L.esri
}

export default {
  Symbol,
  ExportLeaflet,
  latLngBounds,
  newMap,
  createLayers,
  chinaLayers,
  icon,
  marker,
  divIcon,
  markerClusterGroup,
  point,
  circle,
  polygon,
  popup,
  polyline,
  latLngFn,
  TileLayer,
  esriFn,
  polylineDecorator,
  leafletPip,
  geoJSON
}
