// import HyRtcBusiness from 'hy-rtc-business'
import HyRtcBusiness from './business.js'
import { Message } from 'element-ui'
import store from '../store/index.js'

function install(Vue) {
  let sdk = null
  let form = {}
  let clientId = null
  let timeout = 0
  let activeDisconnection = false
  const business = {
    /**
     * 链接sdk
     */
    initSocket(res, callback) {
      let useSSL = true
      if (typeof res.useSSL === 'boolean') {
        useSSL = res.useSSL
      }
      activeDisconnection = false
      const newSdk = new HyRtcBusiness({
        userId: res.userId,
        useSSL: useSSL,
        host: res.host,
        port: res.port - 0,
        appId: res.appId,
        path: res.path,
        clientId: res.clientId,
        userName: res.userName,
        password: res.password,
        heartbeatData: res.heartbeatData || null
      })
      form = res
      clientId = res.clientId
      newSdk.eventEmit('open', () => {
        sdk = newSdk
        if (callback) {
          callback()
        }
      })
      newSdk.eventEmit('close', (e) => {
        console.warn('连接失败回调事件', e)
      })
      newSdk.eventEmit('disconnected', (e) => {
        console.warn('business_disconnected', e)
        this.disconnectedFn()
      })
      newSdk.eventEmit('methodLogout', () => {
        /* 退出登录 */
        store.getters.methodLogoutFn()
      })
      newSdk.eventEmit('msgNotRead', (data) => {
        /*新消息提醒*/
        store.commit('userOperationCallback', {
          operationName: 'msgNotRead',
          data
        })
      })

      // ============= business/devicestatusRealtime （设备消息）
        // method：faceRecognitionWarning （人脸警告）
        newSdk.eventEmit('faceRecognitionWarning', (data) => {
          store.commit('userOperationCallback', {
            operationName: 'faceRecognitionWarning',
            data
          })
        }),
        // method：motorRecognitionWarning （车脸警告）
        newSdk.eventEmit('motorRecognitionWarning', (data) => {
          console.log('data', data)
          store.commit('userOperationCallback', {
            operationName: 'motorRecognitionWarning',
            data
          })
        }),
        // method：warningAlarm （规则警告）
        newSdk.eventEmit('warningAlarm', (data) => {
          store.commit('userOperationCallback', {
            operationName: 'warningAlarm',
            data
          })
        })

      // ============= business/user （平台用户消息）

      // method：gasCaseStatusReport （设备上报险情状态）
      // data：caseUniqueId、gasCaseStatusReportId
      newSdk.eventEmit('gasCaseStatusReport', (data) => {
        store.commit('userOperationCallback', {
          operationName: 'gasCaseStatusReport',
          data
        })
      })

      // method：gasNotSupplyLongTime（四小时未复气）
      newSdk.eventEmit('gasNotSupplyLongTime', (data) => {
        store.commit('userOperationCallback', {
          operationName: 'gasNotSupplyLongTime',
          data
        })
      })

      // * method：newCase (有新警情)
      newSdk.eventEmit('newCase', (data) => {
        store.commit('userOperationCallback', {
          operationName: 'newCase',
          data
        })
      })
      // * method：childOrgDealCase （通知作战部门上级和主管部门上级人员）
      newSdk.eventEmit('childOrgDealCase', (data) => {
        store.commit('userOperationCallback', {
          operationName: 'childOrgDealCase',
          data
        })
      })
      //  * method：apLocationPush (下级发起拉入上级部门邀请)
      newSdk.eventEmit('apLocationPush', (data) => {
        store.commit('userOperationCallback', {
          operationName: 'apLocationPush',
          data
        })
      })
      //  * method：pcVideoMeetingInvite （报警人上报警情定位）
      newSdk.eventEmit('pcVideoMeetingInvite', (data) => {
        store.commit('userOperationCallback', {
          operationName: 'pcVideoMeetingInvite',
          data
        })
      })
      // method：caseStatusChange （警情状态修改通知出动部门）
      newSdk.eventEmit('caseLocationChange', (data) => {
        store.commit('userOperationCallback', {
          operationName: 'caseLocationChange',
          data
        })
      })
      // method：videoMeetingInvite （服务邀请用户加入视频会议）
      newSdk.eventEmit('videoMeetingInvite', (data) => {
        store.commit('userOperationCallback', {
          operationName: 'videoMeetingInvite',
          data
        })
      })
      // method：kickOut （警情踢人mq）
      newSdk.eventEmit('kickOut', (data) => {
        store.commit('userOperationCallback', {
          operationName: 'kickOut',
          data
        })
      })
        // method：roomMemberInOutNotice （警情通知报警人状态）
        newSdk.eventEmit('roomMemberInOutNotice', (data) => {
          store.commit('userOperationCallback', {
            operationName: 'roomMemberInOutNotice',
            data
          })
        })
        // method：alarmPersonInCase （报警人进入警情的mq通知）
        newSdk.eventEmit('alarmPersonInCase', (data) => {
          store.commit('userOperationCallback', {
            operationName: 'alarmPersonInCase',
            data
          })
        })
        /**
         * 方式：alarmPersonInCase ( 设备打卡巡检任务巡检点通知 )
         * method：nfcRecordNotice
         * data：id、pointName（巡检点名称）、executeDtm（巡检时间）、name（用户名称）、devName（设备名称）、deviceId（设备ID）
         * */
        newSdk.eventEmit('nfcRecordNotice', (data) => {
          store.commit('userOperationCallback', {
            operationName: 'nfcRecordNotice',
            data
          })
        })
        /**
         *  method：newCaseNotice （有新险情通知）
         *  data：gasCaseId（燃气ID）
         * */
        newSdk.eventEmit('newCaseNotice', (data) => {
          store.commit('userOperationCallback', {
            operationName: 'newCaseNotice',
            data
          })
        })
        /**
         * method：gasCasePhoneAlarm （险情电话报警）
         * data：phone（拨打人的手机号）、callFlowNo（流水号）、exten（话机号）、opType（CallIn呼入、SLCAnswer接起、SLCNotAnswer未接起）
         * */
        newSdk.eventEmit('gasCasePhoneAlarm', (data) => {
          store.commit('userOperationCallback', {
            operationName: 'gasCasePhoneAlarm',
            data
          })
        })
        /**
         * method：casesNotice （案件通知）
         * data：id（通知id）、casesNo（案件编号）、content（通知内容）
         * */
        newSdk.eventEmit('casesNotice', (data) => {
          store.commit('userOperationCallback', {
            operationName: 'casesNotice',
            data
          })
        })
        /**
         * method：warningUserClose （取消报警停通知）
         * data：closeop（操作内容）
         * */
        newSdk.eventEmit('warningUserClose', (data) => {
          store.commit('userOperationCallback', {
            operationName: 'warningUserClose',
            data
          })
        })
        /**
         * method:alarmReception (接警通知)
         * data:id（警单id）、deviceId（设备ID）、devName（设备名称）、incidentAddress（事发地址）、orgName（部门名称）、alarmDtm（报警时间）
        */
        newSdk.eventEmit('alarmReception', (data) => {
          store.commit('userOperationCallback', {
            operationName: 'alarmReception',
            data
          })
        })
        /**
         * method:alarmNumChange ( 待处理警单数量变动通知（收到此通知，web端刷新待处理警单列表）)
         * data:id（警单id）、changeType（类型：dealStart（被接警）、notDeal（未被接警））
         */
        newSdk.eventEmit('alarmNumChange', (data) => {
          store.commit('userOperationCallback', {
            operationName: 'alarmNumChange',
            data
          })
        })
        /**
         * method:alarmLongTimeNotDeal (警单长时间未处理通知)
         *  data:id（警单id）、deviceId（设备ID）、devName（设备名称）、incidentAddress（事发地址）、orgName（部门名称）、alarmDtm（报警时间）
         */
        newSdk.eventEmit('alarmLongTimeNotDeal', (data) => {
          store.commit('userOperationCallback', {
            operationName: 'alarmLongTimeNotDeal',
            data
          })
        })
        /**
         * method:alarmDeviceOffline (报警设备下线通知)
         *  data：deviceId（设备ID）、devName（设备名称）
         */
        newSdk.eventEmit('alarmDeviceOffline', (data) => {
          store.commit('userOperationCallback', {
            operationName: 'alarmDeviceOffline',
            data
          })
        })
      /**
       * method:alarmOperatorAuth (接警人员身份变动通知)
       *  data：userId、alarmOperator（是否是接警员 0不是 1是）
       */
      newSdk.eventEmit('alarmOperatorAuth', (data) => {
        store.commit('userOperationCallback', {
          operationName: 'alarmOperatorAuth',
          data
        })
      })
    },

    // 主动断开
    disconnectCompletely() {
      activeDisconnection = true
      sdk.disconnectCompletely()
      sdk = null
    },

    /* 连接状态 */
    connectionStatus() {
      return sdk !== null
    },

    // 获取重连次数
    getTimeout () {
      return timeout
    },

    // sdk 调用回调对象
    callCallbackObject(name, callback) {
      sdk.eventEmit(name, (res) => {
        callback(res)
      })
    },

    disconnectedFn() {
      if (activeDisconnection) return
      timeout++
      if (timeout < 3) {
        this.initSocket(form)
      } else {
        timeout = 0
        sdk = null
        this.resetDeviceIdList([])
        Message.error('连接失败')
        /* 退出登录 */
        store.getters.methodLogoutFn()
      }
    },

    /* 获取订阅的设备列表 */
    getSubscribedDevicesList() {
      return sdk.getSubscribedDevicesList()
    },

    /* 批量取消订阅设备 */
    resetDeviceIdList(deviceList) {
      if (sdk) {
        sdk.resetDeviceIdList(deviceList || [])
      }
    },

    // 批量订阅设备状态()
    addSubscriptionDevice(device) {
      if (device) {
        sdk.addSubscriptionDevice(device)
      }
    },

    // 批量订阅设备状态()
    bulkSubscriptionDevice(deviceList) {
      if (sdk) {
        sdk.bulkSubscriptionDevice(deviceList)
      }
    },

    /* 移除场景中订阅的设备 */
    deleteSceneDevice(sceneName) {
      if (sdk) {
        sdk.deleteSceneDevice(sceneName)
      }
    },

    /* 设备指令 */
    equipmentDirective(obj, successCallback, failCallback) {
      /*  equipment 参数解释
       *   takePicture: 拍照
       *   videoRecord: 录像 （输入录像时长）
       *   startVideoRecord: 开始录像
       *   stopVideoRecord: 停止录像
       *   startAudioRecord: 开始录音
       *   stopAudioRecord: 停止录音
       *
       * */
      const equipment = obj.equipment
      const clientId = obj.deviceId

      if (equipment) {
        if (equipment === 'videoRecord' || equipment === 'startVideoRecord') {
          store.getters.commandLogSave({
            command: 'videoRecord',
            deviceIdList: [clientId]
          })
        } else if (equipment === 'takePicture') {
          store.getters.commandLogSave({
            command: 'takePicture',
            deviceIdList: [clientId]
          })
        } else if (equipment === 'startAudioRecord') {
          store.getters.commandLogSave({
            command: 'audioRecord',
            deviceIdList: [clientId]
          })
        }

        let json = {}
        if (equipment === 'takePicture') {
          /* 拍照 */
          Message.success('指令发送成功')
          sdk
            .takePicture(
              {
                clientId
              },
              (res) => {
                json = {
                  ...res,
                  ...obj
                }
                store.commit('addDirectiveListFn', json)
                if (successCallback) {
                  successCallback(json)
                }
              }
            )
            .catch((res) => {
              json = {
                ...res,
                ...obj
              }
              if (res.errorCode && res.errorCode === 1001) {
                Message.error(res.errorReason)
              } else {
                json.errorReason = '设备正在忙碌中'
                Message.error('设备正在忙碌中，请稍后再试')
              }
              store.commit('addDirectiveListFn', json)
              if (failCallback) {
                failCallback(res)
              }
            })
        } else if (equipment === 'videoRecord') {
          /* 录像 （输入录像时长） */
          Message.success('指令发送成功')
          sdk
            .videoRecord(
              {
                clientId,
                duration: obj.duration
              },
              (res) => {
                json = {
                  ...res,
                  ...obj
                }
                store.commit('addDirectiveListFn', json)
                if (successCallback) {
                  successCallback(json)
                }
              }
            )
            .catch((res) => {
              json = {
                ...res,
                ...obj
              }
              if (res.errorCode && res.errorCode === 1001) {
                Message.error(res.errorReason)
              } else {
                json.errorReason = '设备正在忙碌中'
                Message.error('设备正在忙碌中，请稍后再试')
              }
              store.commit('addDirectiveListFn', json)
              if (failCallback) {
                failCallback(res)
              }
            })
        } else if (equipment === 'startVideoRecord') {
          /* 开始录像 */
          sdk
            .startVideoRecord(
              {
                clientId,
                maxDuration: obj.maxDuration // (录像最大时间，到达后自动结束)
              },
              (res) => {
                json = {
                  ...res,
                  ...obj
                }
                store.commit('addDirectiveListFn', json)
                if (successCallback) {
                  successCallback(json)
                }
              }
            )
            .catch((res) => {
              json = {
                ...res,
                ...obj
              }
              if (res.errorCode && res.errorCode === 1001) {
                Message.error(res.errorReason)
              } else {
                json.errorReason = '设备正在忙碌中'
                Message.error('设备正在忙碌中，请稍后再试')
              }
              store.commit('addDirectiveListFn', json)
              if (failCallback) {
                failCallback(res)
              }
            })
        } else if (equipment === 'stopVideoRecord') {
          /* 停止录像 */
          /*
           * 状态：0准备中,1录像中,2录像成功,3录像失败,4已上传
           * */
          sdk
            .stopVideoRecord(
              {
                clientId,
                key: obj.key
              },
              (res) => {
                json = {
                  ...res,
                  ...obj
                }
                store.commit('addDirectiveListFn', json)
                if (successCallback) {
                  successCallback(json)
                }
              }
            )
            .catch((res) => {
              if (failCallback) {
                failCallback(res)
              }
            })
        } else if (equipment === 'startAudioRecord') {
          /* 开始录音 */
          sdk
            .startAudioRecord(
              {
                clientId,
                maxDuration: obj.maxDuration // (录像最大时间，到达后自动结束)
              },
              (res) => {
                json = {
                  ...res,
                  ...obj
                }
                store.commit('addDirectiveListFn', json)
                if (successCallback) {
                  successCallback(json)
                }
              }
            )
            .catch((res) => {
              json = {
                ...res,
                ...obj
              }
              if (res.errorCode && res.errorCode === 1001) {
                Message.error(res.errorReason)
              } else {
                json.errorReason = '设备正在忙碌中'
                Message.error('设备正在忙碌中，请稍后再试')
              }
              store.commit('addDirectiveListFn', json)
              if (failCallback) {
                failCallback(res)
              }
            })
        } else if (equipment === 'stopAudioRecord') {
          /* 停止录音 */
          /*
           * 状态：0准备中,1录像中,2录像成功,3录像失败,4已上传
           * */
          sdk
            .stopAudioRecord(
              {
                clientId,
                key: obj.key
              },
              (res) => {
                json = {
                  ...res,
                  ...obj
                }
                store.commit('addDirectiveListFn', json)
                if (successCallback) {
                  successCallback(json)
                }
              }
            )
            .catch((res) => {
              if (failCallback) {
                failCallback(res)
              }
            })
        }
      }
    },

    /* 查询⽇期列表 */
    deviceQueryFileDates(obj, successCallback, failCallback) {
      if (sdk) {
        sdk
          .queryFileDates(obj, (res) => {
            if (successCallback) {
              successCallback(res)
            }
          })
          .catch((res) => {
            if (failCallback) {
              failCallback(res)
            }
          })
      }
    },

    // 设备功能开关
    deviceFunctionSwitch (obj, successCallback, failCallback) {
      // const method = {
      //   facereStart: '开启设备人脸识别',
      //   facereStop: '关闭设备人脸识别',
      //   pfacereStart: '开启平台人脸识别',
      //   pfacereStop: '关闭平台人脸识别',
      //   platereStart: '开启设备车牌识别',
      //   platereStop: '关闭设备车牌识别'
      // }
      sdk.deviceFunctionSwitch({
        ...obj,
        clientId: obj.deviceId,
      }).then((res) => {
        if (successCallback) {
          successCallback(res)
        }
      }).catch((error) => {
        if (failCallback) {
          failCallback(error)
        }
      })
    },

    /* 查询⽂件列表 */
    deviceQueryFiles(obj, successCallback, failCallback) {
      if (sdk) {
        sdk
          .queryFiles(obj, (res) => {
            if (successCallback) {
              successCallback(res)
            }
          })
          .catch((res) => {
            if (failCallback) {
              failCallback(res)
            }
          })
      }
    },

    /* 设备文件上传 */
    deviceUploadFile(obj, successCallback, failCallback) {
      if (sdk) {
        sdk.uploadFile(
            {
              clientId: obj.deviceId,
              fileId: obj.fileId
            },
            (res) => {
              if (successCallback) {
                successCallback(res)
              }
            }
          )
          .catch((res) => {
            if (failCallback) {
              failCallback(res)
            }
          })
      }
    },

    /* 聊天新增订阅 */
    chatSubscribe(roomId, callback) {
      if (sdk) {
        sdk.chatSubscribe(roomId, callback)
      }
    },

    /* 聊天取消订阅 */
    chatUnsubscribe(roomId) {
      if (sdk) {
        sdk.chatUnsubscribe(roomId)
      }
    },

    /* 视频会议新增订阅 */
    videoMeetingSubscribe(roomId, callback) {
      if (sdk) {
        sdk.videoMeetingSubscribe(roomId, callback)
      }
    },

    /* 视频会议取消订阅 */
    videoMeetingUnsubscribe(roomId) {
      if (sdk) {
        sdk.videoMeetingUnsubscribe(roomId)
      }
    },

    /* 画板新增订阅 */
    canvasSubscribe(roomId, callback) {
      if (sdk) {
        sdk.canvasSubscribe(roomId, callback)
      }
    },

    /* 画板取消订阅 */
    canvasUnsubscribe(roomId) {
      if (sdk) {
        sdk.canvasUnsubscribe(roomId)
      }
    },

    /* 设备抽查状态订阅 */
    spotCheckSubscribe(uniqueId, callback) {
      if (sdk) {
        sdk.spotCheckSubscribe(uniqueId, callback)
      }
    },
    /* 设备抽查状态取消订阅 */
    spotCheckUnsubscribe(uniqueId) {
      if (sdk) {
        sdk.spotCheckUnsubscribe(uniqueId)
      }
    },

    /* 警情新增订阅 */
    gasCaseSubscribe(caseUniqueId, callback) {
      if (sdk) {
        sdk.gasCaseSubscribe(caseUniqueId, callback)
      }
    },

    /* 警情会议取消订阅 */
    gasCaseUnsubscribe(caseUniqueId) {
      if (sdk) {
        sdk.gasCaseUnsubscribe(caseUniqueId)
      }
    },

    /* 根据场景订阅的设备
     *  sceneName (场景名称)
     *  sceneNameCallback （如果发现场景名称重复或者没有，都会自动生成一个名称回调）
     *  callback （设备改变回调）
     *  */
    addSceneDevice(obj) {
      if (sdk) {
        sdk.addSceneDevice(obj)
      }
    }
  }
  Vue.prototype.$business = Object.assign({}, business)
}
export default install
