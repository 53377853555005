<!-- 消息管理 -->
<template>
  <div class="dialog_clsss_lightColor ruleView_details">
    <div
      @click="setDragLevel"
      class="el-dialog w-[1000px]"
      v-el-drag-dialog
      v-if="dialogVisible"
      :style="{ 'z-index': dragLevel }"
    >
      <!--标题-->
      <div class="el-dialog__header pos_r c_fff dialog_header">
        <span class="">{{ title }}</span>
        <div class="iconCloseBox clickBox closeBox">
          <svg-icon
            @click.native="onClose()"
            svg-name="icon_close"
            className="svgBox !w-[26px] !h-[26px]"
          />
        </div>
      </div>
      <!--内容-->
      <div class="el-dialog__body dialog_body !p-[0_0_20px]">
        <div class="h-[54vh] flex flex-col px-[20px]">
          <el-tabs v-model="deviceType" class="flex-shrink-0">
            <el-tab-pane
              :key="index"
              v-for="(item, index) in deviceTypeList"
              :label="item.name"
              :name="item.type"
            ></el-tab-pane>
          </el-tabs>
          <div v-if="dialogVisible" class="c_fff mt_20 flex-grow h-0">
            <div v-if="deviceType === '1'" class="w-full h-full">
              <addNews
                v-if="addNewsType === 'addNews'"
                @on-close="onClose"
                :deviceIdList="deviceList"
                ref="addNews"
              ></addNews>
              <alarmRecordAddNews
                v-if="addNewsType === 'alarmRecordAddNews'"
                ref="alarmRecordAddNews"
                :requestParams="requestParams"
              ></alarmRecordAddNews>
            </div>
            <fixedList
              v-else-if="deviceType === '2'"
              @on-choice="choiceFn"
              ref="fixedList"
            ></fixedList>
            <messageRecord
              v-else-if="deviceType === '3'"
              ref="messageRecord"
            ></messageRecord>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import elDragDialog from '@components/el-drag-dialog'
import addNews from '@components/issueMessage/addNews'
import alarmRecordAddNews from '@components/issueMessage/alarmRecordAddNews'
import fixedList from '@components/issueMessage/fixedList'
import messageRecord from '@components/issueMessage/messageRecord'
export default {
  data() {
    return {
      requestParams: {},
      addNewsType: 'addNews',
      title: null,
      dragLevel: 0,
      deviceList: [],
      dialogVisible: false,
      deviceType: '1',
      deviceTypeList: [
        { name: '填写消息', type: '1' },
        { name: '固定列表', type: '2' },
        { name: '消息记录', type: '3' }
      ]
    }
  },
  components: {
    addNews,
    alarmRecordAddNews,
    fixedList,
    messageRecord
  },
  directives: { elDragDialog },
  mounted() {
    // this.show([
    //   { deviceId: '58ebc973635086f6' },
    //   { deviceId: '416869e997a35b47' }
    // ])
  },
  methods: {
    /* 选中 */
    choiceFn(row) {
      this.deviceType = '1'
      this.$nextTick(() => {
        if (this.$refs.addNews) {
          this.$refs.addNews.show(row)
        }
      })
    },
    onClose() {
      this.dialogVisible = false
    },
    show(deviceList, params = {}) {
      const {
        title = '消息管理',
        addNewsType = 'addNews',
        requestParams = {}
      } = params || {}
      this.addNewsType = addNewsType
      this.title = title
      this.requestParams = requestParams
      this.deviceType = '1'
      this.deviceList = deviceList
      this.$nextTick(() => {
        this.setDialogVisible(true)
      })
    },
    // 设置弹窗展示
    setDialogVisible(type) {
      // if (this.dialogVisible === type) return
      if (type) {
        this.setDragLevel()
      }
      this.dialogVisible = type
    },
    // 设置弹窗层级
    setDragLevel() {
      if (this.mainStore) {
        this.dragLevel = this.mainStore.getters.dragLevel
        this.mainStore.commit('setDragLevel')
      } else {
        this.dragLevel = this.$store.getters.dragLevel
        this.$store.commit('setDragLevel')
      }
    }
  }
}
</script>
<style scoped lang="less" rel="stylesheet/less">
.ruleView_details {
  .el-dialog {
    position: fixed;
    top: 10%;
    left: 26%;
  }
  .detailed > div {
    margin-bottom: 10px;
    text-align: center;
  }
}
</style>
