import { render, staticRenderFns } from "./videoPlayer.vue?vue&type=template&id=4f6af527&scoped=true"
import script from "./videoPlayer.vue?vue&type=script&lang=js"
export * from "./videoPlayer.vue?vue&type=script&lang=js"
import style0 from "./videoPlayer.vue?vue&type=style&index=0&id=4f6af527&prod&scoped=true&lang=less&rel=stylesheet%2Fless"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f6af527",
  null
  
)

export default component.exports