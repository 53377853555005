import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'

// const a = require(`@/version/${process.env.VUE_APP_BUILD_VERION}.js`)
let router = null
let routes = []
if (process.env.VUE_APP_BUILD_VERION === 'szmetroDemo') {
  routes = [
    {
      path: '/dataCenter',
      name: 'dataCenter',
      component: () => import('@/views/beijingGonganInspection/dataCenter'),
      meta: {
        title: '首页'
      }
    },
    {
      path: '/userCenter',
      name: 'userCenter',
      component: () => import('@/views/beijingGonganInspection/userCenter'),
      meta: {
        title: '用户中心'
      }
    },
    {
      path: '/preplanCenter',
      name: 'preplanCenter',
      component: () => import('@/views/beijingGonganInspection/preplanCenter'),
      meta: {
        title: '预案中心'
      }
    }
  ]
}
Vue.use(VueRouter)

const routesList = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: (resolve) => require(['../views/login'], resolve)
  }
]

if (routes) {
  routesList.push(...routes)
}

// 解决跳转相同路径时的错误警告
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

export function getRouter() {
  return router
}
export function newVueRouter(base) {
  router = new VueRouter({
    mode: 'history',
    // 本地部署测试代码
    // base: '/web-base',
    base: base || process.env.BASE_URL,
    routes: routesList
  })
  router.beforeEach((to, from, next) => {
    const isLogin = store.getters.isLogin
    if (isLogin) {
      next()
    } else {
      if (to.name === 'login') {
        next()
      } else {
        next({ path: '/login/' })
      }
    }
  })
  return router
}

export default routesList
