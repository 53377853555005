import Axios from '@/http'

export default {
  onOfflineDevice({ deviceId, onlineState }) {
    return new Promise((resolve, reject) => {
      Axios.get(
        `test/onOfflineDevice?deviceId=${deviceId}&onlineState=${onlineState}`
      ).then((res) => {
        resolve(res)
      })
    })
  }
}
