<!-- 消息记录 -->
<template>
  <div class="h-full flex flex-col">
    <div
      class="datePicker_datetimerange_darkColor mb_10 flex-shrink-0 flex items-center"
    >
      <span class="fs_14 fw_600 text-auxiliary mr-1">发送时间: </span>
      <el-date-picker
        @change="screenFn"
        v-model="screen.queryDtm"
        type="datetimerange"
        range-separator="至"
        value-format="yyyy-MM-dd HH:mm:ss"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      >
      </el-date-picker>
    </div>
    <div class="h-0 flex-grow">
      <el-table
        height="100%"
        :data="tableData"
        :show-header="false"
        class="pos_r w-full z-[1] mt-1"
        v-el-table-infinite-scroll="load"
      >
        <el-table-column label=" ">
          <template slot-scope="{ row }">
            <div>
              <div class="lh_20 p-[4px_0]" v-if="row.content">
                {{ row.content }}
              </div>
              <!-- 图片 -->
              <ul
                v-if="row.fileType === 1"
                class="fileListBox css-text-no-select"
              >
                <li v-for="(item, index) in row.imagesList" :key="index">
                  <div class="imgBox">
                    <div class="centerBox deleteBox">
                      <iconBox
                        @click="onShowViewer(item)"
                        imgName="see"
                        altText="查 看"
                      ></iconBox>
                    </div>
                    <img :src="item" alt="" />
                  </div>
                </li>
              </ul>
              <!-- 视频 -->
              <ul
                v-if="row.fileType === 2"
                class="fileListBox css-text-no-select"
              >
                <li>
                  <div style="background: #0d2035" class="imgBox">
                    <div class="centerBox deleteBox">
                      <iconBox
                        @click="videoPlayerShow(row)"
                        imgName="see"
                        altText="查 看"
                      ></iconBox>
                    </div>
                    <div class="pos_a w_100vh centerBox">
                      <svg-icon
                        svg-name="videoCover"
                        class-name="!w-[40px] !h-[40px]"
                      />
                    </div>
                  </div>
                </li>
              </ul>
              <div class="lh_20 fs_12 c_theme mtb_10">
                <div v-if="row.deviceUserList.length">
                  <span class="c_9DA7B2">接收人:</span>
                  <span
                    class="clickBox"
                    @click="deviceUserShow(row.deviceUserList)"
                  >
                    {{
                      row.deviceUserList.length || 0
                    }}人（点击查看接收列表）</span
                  >
                </div>
                <div>
                  <span class="c_9DA7B2">发送人:</span> {{ row.sendUserName }}
                  {{ row.sendDtm }}
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 预览图片 -->
    <imageviewer
      v-if="imageviewerType"
      style="z-index: 9999"
      ref="imageviewer"
      :urlList="imageviewerUrl"
      :onClose="imageviewerClose"
      :zIndex="0"
    >
    </imageviewer>
    <el-dialog
      :visible.sync="deviceUserVisible"
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      class="dialog_clsss_lightColor"
      custom-class="w-[340px]"
    >
      <div class="c_fff activatedImei">
        <div class="pos_r dialog_header !pb-2">
          接收人
          <div class="iconCloseBox clickBox closeBox">
            <svg-icon
              @click.native="deviceUserClose()"
              svg-name="icon_close"
              className="svgBox !w-[26px] !h-[26px]"
            />
          </div>
        </div>
        <div class="dialog_body">
          <el-table
            height="300px"
            :data="deviceUserList"
            :show-header="false"
            class="pos_r w-full z-[1] mt-1"
            v-el-table-infinite-scroll="load"
          >
            <el-table-column label=" ">
              <template slot-scope="{ row }">
                <span>{{ row.name }}({{ row.orgName }})</span>
              </template>
            </el-table-column>
          </el-table>
          <div class="tac mt_20">
            <el-button
              size="small"
              class="button_default buttonBox"
              @click="deviceUserClose"
              >关 闭</el-button
            >
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="videoDialogVisible"
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      class="dialog_clsss_lightColor"
      custom-class="w-[800px]"
    >
      <div class="c_fff activatedImei">
        <div class="pos_r dialog_header">
          视频查看
          <div
            class="imgPictureChange clickBox closeBox"
            @click="videoDialogClose"
          >
            <svg-icon
              svg-name="delete_hover"
              class-name="!w-[26px] !h-[26px]"
            />
            <svg-icon svg-name="close" class-name="!w-[26px] !h-[26px]" />
          </div>
        </div>
        <div class="dialog_body h-[500px]">
          <videoPlayer
            borderColor="transparent"
            ref="videoPlayer"
          ></videoPlayer>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import videoPlayer from '@components/common/videoPlayer'
import imageviewer from '@/components/common/image/src/image-viewer.vue'
import elTableInfiniteScroll from 'el-table-infinite-scroll'
export default {
  directives: {
    'el-table-infinite-scroll': elTableInfiniteScroll
  },
  data() {
    return {
      screen: {
        page: -1,
        totalPages: null,
        size: 20,
        queryDtm: null,
        query: null
      },
      lockHead: false,
      tableData: [],
      imageviewerType: false, // 查看大图
      imageviewerUrl: [], // 查看大图list
      videoDialogVisible: false,
      deviceUserList: [],
      deviceUserVisible: false
    }
  },
  components: {
    imageviewer,
    videoPlayer
  },
  mounted() {
    this.screenFn()
  },
  methods: {
    deviceUserShow(deviceUserList) {
      this.deviceUserList = deviceUserList
      this.deviceUserVisible = true
    },
    deviceUserClose() {
      this.deviceUserList = []
      this.deviceUserVisible = false
    },
    videoPlayerShow(item) {
      this.videoDialogVisible = true
      this.$nextTick(() => {
        this.$refs.videoPlayer.playVideo(item.fileUrls, {
          ...item,
          filePath: item.fileUrls
        })
      })
    },
    videoDialogClose() {
      if (this.$refs.videoPlayer) {
        this.$refs.videoPlayer.pauseVideo()
      }
      this.videoDialogVisible = false
    },
    // 打开大图展示
    onShowViewer(image) {
      this.imageviewerType = true
      this.imageviewerUrl = []
      this.imageviewerUrl.push(image)
    },
    // 关闭大图查看
    imageviewerClose() {
      this.imageviewerType = false
    },
    handleClick() {
      this.screen = {
        page: -1,
        totalPages: null,
        size: 20,
        queryDtm: null,
        query: null
      }
      if (this.map) {
        this.map.setZoom(11)
        this.map.panTo([this.userInfo.lng, this.userInfo.lat])
      }
      this.screenFn()
    },
    screenFn() {
      if (this.lockHead) {
        return
      }
      this.$set(this.screen, 'page', 0)
      this.$set(this.screen, 'totalPages', null)
      this.tableData = []
      this.$nextTick(() => {
        this.getData()
      })
    },
    load() {
      if (this.lockHead) {
        return
      }
      if (
        this.screen.totalPages &&
        this.screen.totalPages <= this.screen.page + 1
      ) {
        return
      }
      const num = this.screen.page + 1
      this.$set(this.screen, 'page', num)
      this.getData()
    },
    getData() {
      this.lockHead = true
      const obj = {
        ...this.screen
      }
      if (this.screen.queryDtm && this.screen.queryDtm.length) {
        obj.queryStartDtm = this.screen.queryDtm[0]
        obj.queryEndDtm = this.screen.queryDtm[1]
      } else {
        obj.queryStartDtm = null
        obj.queryEndDtm = null
      }
      this.$api
        .page('deviceMsg', {
          ...obj
        })
        .then((res) => {
          if (res.code === 200) {
            this.tableData.push(
              ...res.data.content.map((v) => {
                let imagesList = []
                if (v.fileUrls) {
                  imagesList = v.fileUrls.split(',')
                }
                return {
                  ...v,
                  imagesList
                }
              })
            )
            this.$set(this.screen, 'totalPages', res.data.totalPages)
            this.$nextTick(() => {
              this.lockHead = false
            })
          }
        })
    }
  }
}
</script>
<style scoped lang="less" rel="stylesheet/less">
ul.fileListBox {
  overflow: hidden;
  margin: 0 -10px;
  li:hover {
    box-shadow: 0 0 10px 0 var(--theme);
    .deleteBox {
      z-index: 1;
      display: flex;
    }
  }
  li {
    margin: 4px 8px 4px;
    padding: 2px;
    float: left;
    width: 80px;
    height: 80px;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: 0 0 10px 0 var(--backgroundv2);
    .uploadFile {
      width: 80px;
      height: 80px;
    }
    .deleteBox {
      width: 100%;
      height: 100%;
      position: relative;
      transition: 1s;
      display: none;
      background: var(--theme-05);
    }
    .imgBox {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 7px;
      overflow: hidden;
      border: 1px solid var(--border);
      img {
        position: absolute;
        height: 100%;
      }
    }
  }
}
</style>
