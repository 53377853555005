<template>
  <div
    id="app"
    ref="app"
    v-loading="globalLoading || disconnectionType"
    :element-loading-text="elementLoadingText"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    class="flex flex-col"
  >
    <div v-if="false" class="w-[40px] h-[60px]"></div>
    <div
      id="mouseTextPrompts"
      class="hidden text-main text-xs fixed top-0 left-0 z-[3001] rounded bg-backgroundv2 border border-border px-2 py-1"
    ></div>
    <div v-if="loginPageFn()">
      <!-- 单个页面的头部 （使用于 单页面访问 新窗口打开）  -->
      <newTabOpenHeader
        ref="newTabOpenHeader"
        v-if="
          (separatePageName &&
            separatePageName !== 'null' &&
            separatePageName !== 'hideNavigation') ||
          isDangerMode
        "
      ></newTabOpenHeader>
      <!-- 多个板块访问头部 -->
      <headerBox
        :headerHeight="headerHeight"
        ref="headerBox"
        v-else
      ></headerBox>
    </div>
    <!--<monitor ref="monitor"></monitor>-->
    <div
      id="appContainer"
      class="h-0 flex-grow"
      :style="{
        'flex-grow': loginPageFn() && isLogin ? 1 : 0
      }"
    ></div>
    <div class="text-[.8333vw]" v-show="false"></div>
    <router-view />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import headerBox from '@/components/layout/headerBox'
import newTabOpenHeader from '@/components/layout/newTabOpenHeader'
const {
  headConfigure,
  stylesName,
  mapConfiguration,
  loadScriptType
} = require(`@/version/${process.env.VUE_APP_BUILD_VERION}.js`)

export default {
  data() {
    return {
      dialogVisible: false,
      stylesName: stylesName,
      mapConfiguration: mapConfiguration,
      systemName: null,
      disconnectionType: false,
      elementLoadingText: '加载中',
      headerHeight: 80,
      headConfigure: headConfigure
    }
  },
  components: {
    headerBox,
    newTabOpenHeader
  },
  computed: {
    ...mapGetters([
      'isLogin',
      'globalLoading',
      'businessSystemSetting',
      'separatePageName'
    ]),

    // 是否为险情页面
    isDangerMode() {
      return this.$route?.fullPath.indexOf('/dispatching#/dangerMode') !== -1
    }
  },
  watch: {
    'businessSystemSetting.labelIcon': {
      immediate: true,
      handler: function (v) {
        window.sessionStorage.setItem('icoNname', this.headConfigure.href)
        const href = v || `/${this.headConfigure.href}.ico`
        const link =
          document.querySelector("link[rel*='icon']") ||
          document.createElement('link')
        link.type = 'image/x-icon'
        link.rel = 'icon'
        link.href = href
        document.head.appendChild(link) // 输出修改
      }
    },
    'businessSystemSetting.systemName': {
      immediate: true,
      handler: function (v) {
        document.title = v || this.headConfigure.title
      }
    }
  },
  created() {
    if (loadScriptType === 'bm') {
      this.loadScript(
        `${process.env.VUE_APP_BUILD_DIRECTORY}/static/bigemap.js`
      ).then(() => {
        this.loadScript(
          `${process.env.VUE_APP_BUILD_DIRECTORY}/static/markercluster.js`
        )
        this.loadScript(
          `${process.env.VUE_APP_BUILD_DIRECTORY}/static/bmDraw.js`
        )
        this.loadScript(
          `${process.env.VUE_APP_BUILD_DIRECTORY}/static/bm.geometryutil.js`
        )
        this.loadScript(
          `${process.env.VUE_APP_BUILD_DIRECTORY}/static/moveMarker.js`
        )
      })
    } else if (loadScriptType === 'CFCASIPInput') {
      this.loadScript(
        `${process.env.VUE_APP_BUILD_DIRECTORY}/static/CFCASIPInput.min.js`
      )
    }
    const link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement('link')
    link.type = 'image/x-icon'
    link.rel = 'icon'
    link.href = `/${this.headConfigure.href}.ico`
    document.head.appendChild(link) // 输出修改
    document.title = this.headConfigure.title
    document.addEventListener('visibilitychange', this.handleVisibilityChange)
  },
  destroyed() {
    document.removeEventListener(
      'visibilitychange',
      this.handleVisibilityChange
    )
  },
  mounted() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      if (document.getElementsByClassName('mapcontainer').length) {
        document.getElementsByClassName('mapcontainer')[0].style =
          'height:' +
          window.screen.width +
          'px;width:' +
          window.screen.height +
          'px;'
      }
    }
    window.addEventListener('online', () => {
      this.elementLoadingText = '加载中'
      this.$message.success('网络连接已恢复,正在连接服务')
      this.disconnectionType = false
      if (this.isLogin) {
        this.$deleteConfirmationBox(
          {
            deleteTitleImg: 'tips',
            deleteTitle: '恢复网络',
            submitClass: 'button_function',
            deleteContent: `
              <div>请退出当前账号 <span class="">重新登录</span> 或者 <span class="">刷新浏览器</span></div>
            `,
            closeText: '退出登录',
            submitText: '刷新浏览器'
          },
          () => {
            location.reload()
          },
          () => {
            this.methodLogout()
          }
        )
      }
    })
    window.addEventListener('offline', () => {
      this.elementLoadingText = '网络断开，正在努力寻找网络中'
      this.disconnectionType = true
    })
    if (this.stylesName === 'basics_tiantong') {
      this.headerHeight = 70
    } else if (this.stylesName === 'basics_dispatch') {
      this.headerHeight = 70
    } else {
      this.headerHeight = 80
    }
    this.platformLoginCoverFn()
  },
  methods: {
    fn() {
      this.$api.login.loginByToken({})
    },
    handleVisibilityChange() {
      this.$store.commit('userOperationCallback', {
        operationName: 'visibilitychange',
        data: {
          visibilityState: document.visibilityState
        }
      })
    },
    loginPageFn() {
      return window.location.pathname.indexOf('login') === -1
    },
    /* 退出登录 */
    methodLogout() {
      this.$api.login.logout().then((res) => {
        if (res.code === 200) {
          this.$store.commit('logout')
          this.$router.push({ path: '/login/' })
          this.$nextTick(() => {
            if (this.$sdk && this.$sdk.connectionStatus()) {
              /* 断开mq */
              this.$sdk.disconnectCompletely()
            }
            if (this.$business && this.$business.connectionStatus()) {
              /* 批量取消订阅设备 */
              this.$business.resetDeviceIdList([])
              /* 断开mq */
              this.$business.disconnectCompletely()
            }
          })
        }
      })
    },
    loadScript(url) {
      return new Promise((resolve) => {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.setAttribute('data-callType', 'callScript')
        if (script.readyState) {
          // IE浏览器
          script.onreadystatechange = function () {
            if (
              script.readyState === 'loaded' ||
              script.readyState === 'complete'
            ) {
              script.onreadystatechange = null // 删除事件，避免重复触发
              resolve()
            }
          }
        } else {
          script.onload = () => {
            resolve()
          }
        }
        script.src = url
        document.querySelector('head').appendChild(script)
      })
    },
    /* 获取平台设置数据 */
    platformLoginCoverFn() {
      let hostName = null
      if (process.env.NODE_ENV === 'development') {
        hostName = '192.168.0.126' // www.zh-ser.huayu-et.com
      } else {
        hostName = window.location.hostname
      }
      this.$api.common
        .platformLoginCover({
          hostName: hostName
        })
        .then((res) => {
          if (res.code === 200) {
            const data = res.data
            if (data) {
              this.systemName = data.name || this.headConfigure.title
              document.title = this.systemName
              if (!this.loginPageFn()) {
                this.$store.commit('businessSystemSetting', {
                  systemName: this.systemName,
                  labelIcon: null,
                  systemLogo: null
                })
              }
              // this.$nextTick(() => {
              this.$store.commit('setMapConfigurationFn', {
                label: 'systemName',
                value: this.systemName
              })
              this.$store.commit('setMapConfigurationFn', {
                label: 'coverUrl',
                value: data.coverUrl
              })
              this.$store.commit('setMapConfigurationFn', {
                label: 'recordNo',
                value: data.recordNo || null
              })
              // })
            }
          }
        })
    }
  }
}
</script>
<style lang="less">
#appContainer {
  position: relative;
  //z-index: 90;
}
#appContainer > div {
  width: 100%;
  height: 100%;
}

.svgBox {
  width: 16px;
  height: 16px;
}
#app {
  display: flex;
  flex-direction: column;
}
* {
  margin: 0;
  padding: 0;
}
html,
body {
  width: 100%;
  height: 100%;
}
li {
  list-style: none;
}
#app {
  height: 100%;
  width: 100%;
}
</style>
