<!-- 选择设备 -->
<template>
  <div
    class="w-full h-full flex flex-col shadow-[0_0_10px_0_inset] shadow-theme05 pb-2 box-border rounded"
  >
    <div
      class="flex-shrink-0 p-[14px_10px_0] box-border flex items-center justify-center"
    >
      <div class="search_lightColor flex !w-full">
        <el-input
          size="small"
          clearable
          class="flex-grow w-0"
          @clear="getData()"
          @keyup.enter.native="getData()"
          v-model="filterValue"
          placeholder="设备号、设备名称、部门名称进行筛选"
        ></el-input>
        <div class="searchBox flex-shrink-0" @click="getData()">
          <svg-icon svg-name="search" className="svgBox" />
        </div>
      </div>
    </div>
    <ul
      class="w-full box-border flex-grow h-0 rounded p-[10px] text-xs text-auxiliary overflow-auto"
    >
      <div
        v-show="deviceList.length === 0"
        class="w-full h-full flex items-center justify-center text-center"
      >
        <div>
          <svg-icon svg-name="noData" className="svgBox !w-[50px] !h-[50px]" />
          <div class="c_9DA7B2 fs_14 centerBox mt_10">暂无数据</div>
        </div>
      </div>
      <li
        v-for="deviceItem in deviceList"
        :key="deviceItem.id"
        :class="{
          'text-main': deviceItem.onlineState === 1
        }"
        @click="deviceClick(deviceItem)"
        class="w-full flex items-center hover:shadow-[0_0_10px_0_inset] bg-backgroundv2 border-backgroundv2 rounded mb-2 box-border clickBox border hover:border-theme hover:shadow-theme h-[32px] px-2"
      >
        <div class="el-checkbox flex-shrink-0 !m-0">
          <span
            :class="{
              'is-checked': deviceItem.selectType
            }"
            class="el-checkbox__input"
          >
            <span class="el-checkbox__inner"></span>
          </span>
        </div>
        <svg-icon
          :svg-name="
            checkDeviceTypeFn(
              deviceItem.deviceType || deviceItem.type,
              deviceItem.onlineState
            )
          "
          class-name="svgBox !w-[20px] !h-[20px] flex-shrink-0 mx-1"
        />
        <div class="flex-grow w-0 over_one_line">{{ deviceItem.devName }}</div>
        <div class="ml-1 text-auxiliary">{{ deviceItem.distance }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    requestParams: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  watch: {
    filterValue: function (val) {
      this.filterValue = val.replace(/\s/g, '')
    }
  },
  data() {
    return {
      form: {},
      // form: {
      //   lng: null,
      //   lat: null,
      //   distance: null
      // },
      filterValue: '',
      selectedDeviceList: [],
      deviceList: []
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.form = this.requestParams
      this.show()
    })
  },
  methods: {
    show() {
      this.deviceList = []
      this.selectedDeviceList = []
      this.getData()
    },
    filterNodeMethod(value, data) {
      if (value === '') {
        return true
      } else {
        if (data.deviceId && data.deviceId.toString().includes(value)) {
          return true
        } else if (data.devName && data.devName.toString().includes(value)) {
          return true
        } else if (data.name && data.name.toString().includes(value)) {
          return true
        } else if (data.orgName && data.orgName.toString().includes(value)) {
          return true
        }
      }
      return false
    },
    deviceClick(deviceItem) {
      const index = this.selectedDeviceList.findIndex(
        (v) => v.deviceId === deviceItem.deviceId
      )
      if (index === -1) {
        this.selectedDeviceList.push(deviceItem)
      } else {
        this.selectedDeviceList.splice(index, 1)
      }
      this.$set(deviceItem, 'selectType', !deviceItem.selectType)
      this.$emit('on-selectedDeviceList', this.selectedDeviceList)
    },
    async getData() {
      const params = {
        ...this.requestParams
        // lng: this.form.lng,
        // lat: this.form.lat,
        // distance: this.form.distance
      }
      const { code, data = [] } = await this.$api.device.nayearByGroupByOrg(
        params
      )
      if (code === 200) {
        const list = []
        data.forEach((deviceItem) => {
          if (this.filterNodeMethod(this.filterValue, deviceItem)) {
            const index = this.selectedDeviceList.findIndex(
              (v) => v.deviceId === deviceItem.deviceId
            )
            if (index !== -1) {
              deviceItem.selectType = true
            }
            let distance = deviceItem.distance || 0
            if (distance > 1) {
              distance = `${distance.toFixed(2)}km`
            } else {
              distance = distance * 1000
              distance = `${distance.toFixed(2)}m`
            }
            const json = {
              ...deviceItem,
              distance
            }
            if (deviceItem.onlineState === 1) {
              list.unshift(json)
            } else {
              list.push(json)
            }
          }
        })
        this.$nextTick(() => {
          this.deviceList = list
        })
      }
    }
  }
}
</script>
<style scoped lang="less" rel="stylesheet/less"></style>
