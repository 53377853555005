export default {
  data() {
    return {
      broadcastRoomId: null,
      broadcastDeviceList: []
    }
  },
  methods: {
    // 广播开关
    broadcastSupport(item) {
      if (item.broadcastLoading) return
      this.$set(item, 'broadcastLoading', true)
      setTimeout(() => {
        if (item.broadcastLoading) {
          const index = this.monitorDeviceList.findIndex(
            (v) => v.deviceId === item.deviceId
          )
          if (index !== -1) {
            this.$set(this.monitorDeviceList[index], 'broadcastLoading', false)
          }
        }
      }, 5000)
      if (item.broadcastType) {
        this.broadcast_closeTheRoom([item])
      } else {
        if (item.broadcastSupport === 1) {
          this.broadcast_addNewRoomFn([item])
        } else {
          this.$message.error('设备不支持此功能')
        }
      }
    },

    /* 获取设备详情并且过滤无用数据 */
    clientInfoFn(deviceIdList) {
      return new Promise((resolve, reject) => {
        this.$api.media
          .clientInfo({
            clientIds: deviceIdList.join(',')
          })
          .then((res) => {
            if (res.code === 200) {
              const clientIdList = res.data || []
              const offLineList = [] // 离线设备统计
              const notSupportedList = [] // 不支持设备
              const inIntercomList = [] // 在别处对讲中
              const deviceList = [] // 可以对讲设备
              clientIdList.forEach((device) => {
                if (device.onlineState !== 1) {
                  offLineList.push(device)
                } else if (device.broadcastSupport !== 1) {
                  notSupportedList.push(device)
                } else if (device.broadcast === 1) {
                  inIntercomList.push(device)
                } else {
                  if (device.gbDevice === 1) {
                    if (!device.deviceId) {
                      device.deviceId = device.openClientId
                    }
                  } else {
                    if (!device.deviceId) {
                      device.deviceId = device.userClientId
                    }
                  }
                  deviceList.push(device)
                }
              })
              let message = ''
              if (offLineList.length) {
                message += `<div class="lh_20">有${offLineList.length}台设备不在线无法发起广播</div>`
              }
              if (notSupportedList.length) {
                message += `<div class="lh_20">有${notSupportedList.length}台设备不支持广播</div>`
              }
              if (inIntercomList.length) {
                message += `<div class="lh_20">有${inIntercomList.length}台设备已处于广播中</div>`
              }
              if (message.length) {
                this.$message.error({
                  duration: 8000,
                  dangerouslyUseHTMLString: true,
                  message: message
                })
              }
              this.$nextTick(() => {
                if (deviceList.length) {
                  resolve(deviceList)
                } else {
                  reject(new Error('无设备'))
                }
              })
            }
          })
      })
    },

    /* 加入广播 */
    broadcast_addNewRoomFn(broadcastDeviceList) {
      const deviceIdList = broadcastDeviceList.map((v) => v.deviceId)
      if (this.broadcastRoomId) {
        this.intercomInviteDevice(deviceIdList)
      } else {
        this.createRoom(deviceIdList)
      }
    },

    /* 创建房间 */
    createRoom(deviceIdList) {
      this.clientInfoFn(deviceIdList).then((deviceList) => {
        this.splitChanneAndDevice(deviceList).then((res) => {
          this.$emit('on-broadcast_addNewRoomFn', res.deviceList)
          this.broadcast(res.deviceList)
        })
      })
    },
    // 拆分 管道id 和 设备id
    async splitChanneAndDevice(dataList) {
      const deviceIdList = []
      const channelIdList = []
      const deviceList = [...dataList]
      const { code, data = [] } = await this.$api.gbChannel.filterByDeviceId({
        channelType: 3,
        deviceIdList: deviceList.map((v) => v.deviceId)
      })
      if (code === 200) {
        data.forEach((item) => {
          if (!item.channelId) {
            deviceIdList.push(item.deviceId)
          } else {
            channelIdList.push(item.channelId)
          }
          if (item.channelList && item.channelList.length !== 0) {
            const index = deviceList.findIndex(
              (v) => v.deviceId === item.deviceId
            )
            if (index !== -1) {
              this.$set(deviceList[index], 'channelId', item.channelId)
              this.$set(deviceList[index], 'channelName', item.channelName)
              this.$set(deviceList[index], 'channelList', item.channelList)
            }
          }
        })
      }
      return {
        deviceIdList,
        channelIdList,
        deviceList
      }
    },

    /* 邀请设备加入群组 */
    intercomInviteDevice(deviceIdList = []) {
      /*
       * deviceIdList 设备id数组
       *  */
      return new Promise((resolve, reject) => {
        if (deviceIdList.length) {
          this.clientInfoFn(deviceIdList).then((deviceList) => {
            if (deviceList) {
              this.splitChanneAndDevice(deviceList).then((res) => {
                this.addBroadcastDeviceList(res.deviceList)
                this.$api.media
                  .broadcastInvite({
                    roomId: this.broadcastRoomId,
                    deviceIdList: res.deviceIdList,
                    channelIdList: res.channelIdList
                  })
                  .then((res) => {
                    if (res.code === 200) {
                      resolve()
                      // 发起邀请成功
                      this.$message.warning('设备已开启广播, 请等待连接')
                    }
                  })
              })
            }
          })
        } else {
          reject(new Error('deviceIdList 参数有误'))
        }
      })
    },

    // 设置选中的数据
    broadcast_setSelectChanne(channeItem) {
      const clientIdIndex = this.broadcastDeviceList.findIndex(
        (v) => v.deviceId === channeItem.deviceId
      )
      if (clientIdIndex !== -1) {
        this.broadcast_closeTheRoom([this.broadcastDeviceList[clientIdIndex]])
        this.$set(
          this.broadcastDeviceList[clientIdIndex],
          'channelId',
          channeItem.channelId
        )
        this.$set(
          this.broadcastDeviceList[clientIdIndex],
          'channelName',
          channeItem.channelName
        )
        this.$nextTick(() => {
          setTimeout(() => {
            this.$api.media
              .broadcastInvite({
                roomId: this.roomId,
                deviceIdList: [],
                channelIdList: [channeItem.channelId]
              })
              .then((res) => {
                if (res.code === 200) {
                  this.$message.warning('重新发起广播')
                }
              })
          }, 1000)
        })
      }
    },
    // 设置单个数据的通道信息
    broadcast_setItemChanne(data) {
      const clientIdIndex = this.broadcastDeviceList.findIndex(
        (v) => v.deviceId === data.deviceId
      )
      if (clientIdIndex !== -1) {
        this.$set(
          this.broadcastDeviceList[clientIdIndex],
          'channelList',
          data.channelList
        )
      }
    },

    // broadcastDeviceList 添加
    addBroadcastDeviceList(deviceList) {
      deviceList.forEach((deviceItem) => {
        const index = this.broadcastDeviceList.findIndex(
          (v) => v.deviceId === deviceItem.deviceId
        )
        if (index === -1) {
          this.broadcastDeviceList.push({ ...deviceItem })
        }
      })
    },

    broadcast(deviceList = []) {
      this.$message.warning('设备已开启广播，请等待连接')
      this.broadcastDeviceList = deviceList
      this.$sdk.broadcast(
        {
          clientIds: deviceList.map((v) => v.channelId || v.deviceId).join(','),
          audioPush: true,
          videoPush: false
        },
        (res) => {
          this.broadcastRoomId = null
          this.$message.error(res.errorReason)
        },
        (res) => {
          this.broadcastRoomId = res.roomId
          /* 房间设备改变 */
          if (res.clientIdList.length) {
            const resClientIdList =
              res.clientIdList.filter((v) => {
                return v.clientId
              }) || []
            if (resClientIdList.length) {
              resClientIdList.forEach((v) => {
                const clientIdIndex = this.broadcastDeviceList.findIndex(
                  (j) => j.deviceId === v.clientId
                )
                if (clientIdIndex !== -1) {
                  const clientIdItem = this.broadcastDeviceList[clientIdIndex]
                  this.$set(this.broadcastDeviceList, clientIdIndex, {
                    ...clientIdItem,
                    methodTypeV2: true,
                    ...v
                  })
                } else {
                  this.broadcastDeviceList.push({
                    methodTypeV2: true,
                    ...v,
                    deviceId: v.clientId
                  })
                }
              })
            }
            if (
              resClientIdList.findIndex(
                (item) => item.methodType !== 'clientClosed'
              ) === -1
            ) {
              this.broadcast_onClose()
            } else {
              this.broadcast_roomDeviceChange()
            }
          }
        }
      )
    },
    broadcast_onClose() {
      if (this.broadcastRoomId) {
        this.$sdk.exitRoomFn(this.broadcastRoomId)
      }
      // this.broadcastDeviceList.forEach((device) => {
      //   if (device.gbDevice === 1 && device.roomId) {
      //     this.$sdk.exitRoomFn(device.roomId)
      //   }
      // })
      this.$nextTick(() => {
        this.broadcast_roomDeviceChange()
        this.broadcastDeviceList = []
        this.broadcastRoomId = null
      })
    },
    // 广播_移出房间
    broadcast_closeTheRoom(deviceList) {
      if (!this.broadcastRoomId) {
        return
      }
      return new Promise((resolve, reject) => {
        if (deviceList.length && this.broadcastRoomId) {
          const deviceIdList = []
          const channelIdList = []
          deviceList.forEach((item) => {
            let row = this.broadcastDeviceList.find(
              (row) => row.deviceId === item.deviceId
            )
            if (!row) {
              row = item
            }
            if (row.channelId) {
              channelIdList.push(row.channelId)
            } else if (row.deviceId) {
              deviceIdList.push(row.deviceId)
            }
          })
          this.$nextTick(() => {
            this.$api.media
              .broadcastMemberClose({
                roomId: this.broadcastRoomId,
                deviceIdList,
                channelIdList
              })
              .then((res) => {
                if (res.code === 200) {
                  resolve()
                  this.$message.success('设备广播已关闭')
                }
              })
          })
        } else {
          reject(new Error('deviceList 参数有误'))
        }
      })
    },
    // 广播_房间设备改变
    broadcast_roomDeviceChange() {
      this.broadcastDeviceIdListFn()
    },
    // 广播_设备列表
    broadcastDeviceIdListFn() {
      const broadcastDeviceIdList = this.getListOfRunningDevices({
        appointed: 'broadcast'
      })
      console.warn('broadcastDeviceIdList', broadcastDeviceIdList)
      this.monitorDeviceList.forEach((device) => {
        if (device.deviceId) {
          const index = broadcastDeviceIdList.findIndex(
            (v) => v.clientId === device.deviceId
          )
          if (index === -1) {
            if (device.broadcastType) {
              this.$set(device, 'broadcastType', false)
              this.replaceAudioStream(device, false)
            }
          } else {
            if (!device.broadcastType) {
              this.$set(device, 'broadcastType', true)
              this.replaceAudioStream(device, true)
            }
          }
          this.$set(device, 'broadcastLoading', false)
        }
      })
    },
    /* 获取当前平台在调用的设备 */
    getListOfRunningDevices(obj) {
      // obj === null 放回全部设备
      // obj.audioType === true 返回对接中的设备
      // obj.videoType === true 返回调用画面的设备
      // obj.appointed 返回指定类型的设备
      return this.$sdk.obtainAllClientIdList(obj) || []
    }
  }
}
