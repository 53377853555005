import Axios from '@/http'
const mcflag = window.sessionStorage.getItem('mcflag') || null

export default {
  /* 加密配置 */
  encryptConfig() {
    return new Promise((resolve, reject) => {
      Axios.post('mssms/encryptConfig').then((res) => {
        resolve(res)
      })
    })
  },

  /* 获取验证码 */
  mssmsSmssend(params) {
    return new Promise((resolve, reject) => {
      Axios.post('mssms/smssend', params).then((res) => {
        resolve(res)
      })
    })
  },
  /* 口令认证 */
  passwordauth(params = {}) {
    return new Promise((resolve, reject) => {
      Axios.post('mssms/passwordauth', { ...params, mcflag }, { isJSON: true })
        .then((res) => {
          resolve(res)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  /* 工号或OA名验证码登陆 */
  loginMssmslogin(params = {}) {
    return new Promise((resolve, reject) => {
      Axios.post('login/mssmslogin', { ...params, mcflag })
        .then((res) => {
          resolve(res)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  smsauth(params = {}) {
    return new Promise((resolve, reject) => {
      Axios.post('mssms/smsauth', { ...params, mcflag })
        .then((res) => {
          resolve(res)
        })
        .catch((e) => {
          reject(e)
        })
    })
  }
}
