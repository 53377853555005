<!-- 禁入禁出规则弹框 -->
<template>
  <div>
    <el-dialog
      :visible.sync="visible"
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      custom-class="w-[400px]"
      class="dialog_clsss"
    >
      <div class="c_fff">
        <div class="pos_r dialog_header">
          <span>
            {{
              `${form.id ? $t('general.edit') : $t('general.add')}${
                type === 1 ? $t('rules.forbidInto') : $t('rules.forbidOut')
              }` + $t('rules.rule')
            }}
          </span>
          <div class="imgPictureChange clickBox closeBox" @click="close">
            <svg-icon
              :svg-name="'delete_hover'"
              class-name="!w-[26px] !h-[26px]"
            />
            <svg-icon :svg-name="'close'" class-name="!w-[26px] !h-[26px]" />
          </div>
        </div>
        <div class="dialog_body">
          <div>
            <el-form
              label-position="top"
              :model="form"
              :rules="rules"
              ref="form"
              label-width="0px"
            >
              <el-row :gutter="30">
                <el-col :span="24">
                  <el-form-item prop="ruleName">
                    <div class="requiredTitle">
                      {{ $t('rules.ruleName') }}：<i>*</i>
                    </div>
                    <el-input
                      v-if="!justShow"
                      :placeholder="`${this.$t('general.pleaseEnter')}${this.$t(
                        'rules.ruleName'
                      )}`"
                      @keyup.native="inputForbidden(form, 'ruleName')"
                      v-model="form.ruleName"
                    ></el-input>
                    <span v-else>{{ this.form.ruleName }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item prop="mapRailId">
                    <div class="requiredTitle flex-shrink-0">
                      {{ $t('rules.areaName') }}<i>*</i>
                    </div>
                    <div class="flex w-full">
                      <el-select
                        v-if="!justShow"
                        class="w-0 flex-grow"
                        :placeholder="`${this.$t(
                          'general.pleaseChoose'
                        )}${this.$t('rules.areaName')}`"
                        v-model="form.mapRailId"
                      >
                        <el-option
                          v-for="item in railMapList"
                          :key="item.id"
                          :label="item.railName"
                          :value="item.id"
                        >
                        </el-option>
                      </el-select>
                      <el-button
                        v-if="!justShow"
                        class="button_function ml-[20px] flex-shrink-0"
                        @click="addArea"
                        >{{ $t('rules.addMap') }}</el-button
                      >
                      <span v-else>{{ this.form.areaName }}</span>
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item prop="timeRange">
                    <div class="requiredTitle">
                      {{ $t('rules.timeRange') }}<i>*</i>
                    </div>
                    <el-time-picker
                      v-if="!justShow"
                      is-range
                      class="!w-full"
                      v-model="form.timeRange"
                      value-format="HH:mm:ss"
                      :range-separator="$t('general.to')"
                      :start-placeholder="$t('general.startDtm')"
                      :end-placeholder="$t('general.endDtm')"
                      :placeholder="$t('general.selectTimeRange')"
                    >
                    </el-time-picker>
                    <span v-else
                      >{{ this.form.startDtm1 }} ---
                      {{ this.form.endDtm1 }}</span
                    >
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
          <div class="tac mt_20">
            <el-button
              size="small"
              class="button_default buttonBox"
              @click="close"
              >关 闭</el-button
            >
            <el-button
              size="small"
              class="button_function buttonBox"
              @click="submit"
              v-if="!justShow"
              >保 存</el-button
            >
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="dialogVisible"
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      class="dialog_clsss_lightColor"
      custom-class="w-[800px]"
    >
      <div class="c_fff activatedImei">
        <div class="pos_r dialog_header">
          <span>电子围栏</span>
          <div class="imgPictureChange clickBox closeBox" @click="onClose">
            <svg-icon
              :svg-name="'delete_hover'"
              class-name="!w-[26px] !h-[26px]"
            />
            <svg-icon :svg-name="'close'" class-name="!w-[26px] !h-[26px]" />
          </div>
        </div>
        <div class="dialog_body h-[500px]">
          <electronicFence
            v-if="dialogVisible"
            ref="electronicFence"
          ></electronicFence>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
export default {
  components: {
  },
  data () {
    return {
      dialogVisible: false,
      justShow: false,
      type: 1, // 1.禁入规则 2.禁出规则
      visible: false,
      takeVideo: 1,
      form: {
        timeRange: null,
        videoOrPhoto: '',
        ruleName: '',
        areaType: 1,
        startDtm: '',
        endDtm: '',
        warningVoice: '',
        takePhoto: 1,
        warningPhone: '',
        warningEmail: '',
        mapRailId: null,
        areaId: null
      },
      rules: {
        ruleName: [
          { required: true, message: `${this.$t('general.pleaseEnter')}${this.$t('rules.ruleName')}`, trigger: 'blur' }
        ],
        mapRailId: [
          { required: true, message: `${this.$t('general.pleaseChoose')}${this.$t('rules.areaName')}`, trigger: 'change' }
        ],
        timeRange: [
          { required: true, message: `${this.$t('general.pleaseChoose')}${this.$t('general.selectTimeRange')}`, trigger: 'change' }
        ],
        videoOrPhoto: [
          { required: true, message: `${this.$t('general.pleaseChoose')}${this.$t('rules.videoOrPhoto')}`, trigger: 'change' }
        ]
      },
      railMapList: []
    }
  },
  watch: {
    'form.areaType' () {
      this.$nextTick(() => {
        if (this.form.areaType === 2 && this.form.area) {
          this.$refs.areaSelect.handlerCurCity(this.form.area)
        }
      })
    }
  },
  methods: {
    onClose () {
      this.dialogVisible = false
      this.getMapRail()
    },
    onSelectArea (cityObj) {
      this.form.areaId = cityObj.id
    },
    show (row, type) {
      this.getMapRail()
      this.justShow = false
      this.form.videoOrPhoto = 'photo'
      this.type = type || 1
      if (row.id) {
        this.$api.select('rule', row.id).then(res => {
          this.$nextTick(() => {
            this.form = res.data
            this.$set(this.form, 'timeRange', [this.form.startDtm1, this.form.endDtm1])
          })
        })
      } else {
        this.form = {
          timeRange: null,
          videoOrPhoto: '',
          ruleName: '',
          areaType: 1,
          startDtm: '',
          endDtm: '',
          warningVoice: '',
          takePhoto: 1,
          warningPhone: '',
          warningEmail: '',
          mapRailId: null,
          areaId: null
        }
      }
      this.visible = true
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },
    close () {
      this.visible = false
    },
    submit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.timeRange && this.form.timeRange.length !== 0) {
            this.form.startDtm1 = this.form.timeRange[0]
            this.form.endDtm1 = this.form.timeRange[1]
          }
          if (!this.form.ruleName) {
            this.$message.error(this.$t('tips.tips24'))
            return false
          }
          if (this.form.areaType === 1 && !this.form.mapRailId) {
            this.$message.error(this.$t('tips.tips28'))
            return false
          } else if (this.form.areaType === 2 && !this.form.areaId) {
            this.$message.error(this.$t('tips.tips29'))
            return false
          }
          if (!this.form.startDtm1 || !this.form.endDtm1) {
            this.$message.error(this.$t('tips.tips25'))
            return false
          }
          if (this.form.videoOrPhoto === 'video') {
            this.form.takePhoto = null
            this.form.takeVideo = 1
            this.form.videotapeLength = 15
          }
          if (this.form.videoOrPhoto === 'photo') {
            this.form.takePhoto = 1
            this.form.takeVideo = null
          }
          this.$api.save('rule', Object.assign({}, this.form, {
            type: this.type || 1
          })).then(() => {
            this.$emit('on-submit')
            this.$message.success(this.$t('tips.tips6'))
            this.close()
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    getMapRail () {
      this.$api.findAll('mapRail').then(res => {
        this.railMapList = res.data
      })
    },
    addArea () {
      this.dialogVisible = true
    }
  },
  mounted () {

  }
}
</script>
