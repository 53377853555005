<!-- 的页面 -->
<template>
  <el-option
    :label="source[`${optionLabel}`]"
    :value="source[`${optionValue}`]"
  />
</template>

<script>
export default {
  props: {
    optionLabel: {
      type: String,
      default: () => {
        return 'name'
      }
    },
    optionValue: {
      type: String,
      default: () => {
        return 'id'
      }
    },
    source: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  methods: {}
}
</script>
<style scoped lang="less" rel="stylesheet/less"></style>
