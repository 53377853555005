<!-- 规则设置 -->
<template>
  <div class="h_100vh w_100vh c_fff pt-[10px]">
    <el-tabs v-if="deviceTypeList.length" v-model="deviceType">
      <el-tab-pane
        :key="index"
        v-for="(item, index) in deviceTypeList"
        :label="item.name"
        :name="item.permission"
      ></el-tab-pane>
    </el-tabs>
    <div
      class="mt_20"
      :style="{ height: deviceTypeList.length ? 'calc(100% - 64px)' : '100%' }"
    >
      <missionInRule
        @on-ruleDeviceDialogShow="ruleDeviceDialogShow"
        v-if="deviceType === 'operator_ruleSetting_missionInRule'"
        ref="missionInRule"
      ></missionInRule>
      <missionOutRule
        @on-ruleDeviceDialogShow="ruleDeviceDialogShow"
        v-else-if="deviceType === 'operator_ruleSetting_missionOutRule'"
        ref="missionOutRule"
      ></missionOutRule>
      <sedentary
        @on-ruleDeviceDialogShow="ruleDeviceDialogShow"
        v-else-if="deviceType === 'operator_ruleSetting_sedentary'"
        ref="sedentary"
      ></sedentary>
      <timerCamera
        @on-ruleDeviceDialogShow="ruleDeviceDialogShow"
        v-else-if="deviceType === 'operator_ruleSetting_timerCamera'"
        ref="timerCamera"
      ></timerCamera>
      <timedVideo
        @on-ruleDeviceDialogShow="ruleDeviceDialogShow"
        v-else-if="deviceType === 'operator_ruleSetting_timedVideo'"
        ref="timedVideo"
      ></timedVideo>
      <timingReturn
        @on-ruleDeviceDialogShow="ruleDeviceDialogShow"
        v-else-if="deviceType === 'operator_ruleSetting_timingReturn'"
        ref="timedVideo"
      ></timingReturn>
      <div v-else class="h_100vh centerBox c_9DA7B2 fs_12 r-f noData">
        <svg-icon svg-name="noData" className="svgBox" />
        <div class="mt_10">暂无权限</div>
      </div>
    </div>
    <!-- 分配规则弹窗 -->
    <ruleDeviceDialog ref="ruleDeviceDialog"></ruleDeviceDialog>
  </div>
</template>
<script>
import ruleDeviceDialog from '@components/ruleSetting/ruleDeviceDialog'
import missionInRule from '@components/ruleSetting/missionInRule.vue'
import missionOutRule from '@components/ruleSetting/missionOutRule.vue'
import sedentary from '@components/ruleSetting/sedentary.vue'
import timerCamera from '@components/ruleSetting/timerCamera.vue'
import timedVideo from '@components/ruleSetting/timedVideo.vue'
import timingReturn from '@components/ruleSetting/timingReturn.vue'
export default {
  data() {
    return {
      deviceType: null,
      deviceTypeList: []
    }
  },
  components: {
    missionInRule,
    missionOutRule,
    ruleDeviceDialog,
    sedentary,
    timerCamera,
    timedVideo,
    timingReturn
  },
  mounted() {
    const list = []
    const deviceTypeList = [
      { name: '禁入', permission: 'operator_ruleSetting_missionInRule' },
      { name: '禁出', permission: 'operator_ruleSetting_missionOutRule' },
      { name: '静默', permission: 'operator_ruleSetting_sedentary' },
      { name: '定时拍照', permission: 'operator_ruleSetting_timerCamera' },
      { name: '定时录像', permission: 'operator_ruleSetting_timedVideo' },
      { name: '定时回传', permission: 'operator_ruleSetting_timingReturn' }
    ]
    this.deviceType = null
    let permissionIdList = window.sessionStorage.getItem('permissionIdList')
    if (permissionIdList && permissionIdList.length) {
      permissionIdList = JSON.parse(permissionIdList)
      this.$nextTick(() => {
        for (let i = 0; i < deviceTypeList.length; i++) {
          const item = deviceTypeList[i]
          if (item.permission) {
            const index = permissionIdList.findIndex(
              (v) => v === item.permission
            )
            if (index !== -1) {
              list.push(item)
            }
          } else {
            list.push(item)
          }
        }
        this.$nextTick(() => {
          this.deviceTypeList = list
          if (list.length) {
            this.deviceType = list[0].permission
          }
        })
      })
    }
  },
  methods: {
    ruleDeviceDialogShow(row) {
      this.$refs.ruleDeviceDialog.show(row)
    }
  }
}
</script>
<style scoped lang="less" rel="stylesheet/less">
.noData {
  letter-spacing: 4px;
  flex-direction: column;
  .svgBox {
    width: 50px;
    height: 50px;
  }
}
</style>
