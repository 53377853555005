// 环境切换
class HttpConfig {
  constructor() {
    this.baseURLObj = {
      wz: '/violation',
      // 本地部署测试代码
      // admin: '/web-base/admin',
      admin: '/admin',
      hyLive: '/hyLive',
      hyAnalyze: '/hyAnalyze',
      customMetric: '/customMetric'
    }
    if (process.env.NODE_ENV === 'development') {
      // 本地测试
      this.baseURLObj.wz = '/wzapi/violation'
      this.baseURLObj.hyLive = '/hyLiveApi/hyLive'
      this.baseURLObj.hyAnalyze = '/hyAnalyze/hyAnalyze'
      this.baseURLObj.customMetric = '/customMetric/customMetric'
      this.baseURLObj.admin = '/api/admin'
      // for (const key in this.baseURLObj) {
      //   this.baseURLObj[`${key}`] = '/api' + this.baseURLObj[`${key}`]
      // }
    }
  }

  initHttpConfig(config) {
    const { baseURLObj } = config
    this.baseURLObj = baseURLObj
  }

  setBaseURLObj(baseURLObj) {
    if (baseURLObj) {
      this.baseURLObj = {
        ...this.baseURLObj,
        ...baseURLObj
      }
    }
  }
}

export default new HttpConfig()
