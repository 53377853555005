import http from '@/http'

export default {
  /* 编辑部门apkOta自动升级到最新版本按钮值 */
  readApkFileByFileCloudKey(params) {
    return new Promise((resolve, reject) => {
      http.post('/deviceOta/readApkFileByFileCloudKey', params).then((res) => {
        resolve(res)
      })
    })
  },
  /* 编辑部门apkOta自动升级到最新版本按钮值 */
  apkOtaAutoUpdate(params) {
    return new Promise((resolve, reject) => {
      http.post('/deviceOta/apkOtaAutoUpdate', params).then((res) => {
        resolve(res)
      })
    })
  },
  /* 查询部门apkOta自动升级到最新版本按钮值 */
  apkOtaAutoUpdateDetail(params) {
    return new Promise((resolve, reject) => {
      http.post('/deviceOta/apkOtaAutoUpdateDetail', params).then((res) => {
        resolve(res)
      })
    })
  },
  /* 升级记录统计 */
  updateCal(params) {
    return new Promise((resolve, reject) => {
      http.post('/deviceOta/updateCal', params).then((res) => {
        resolve(res)
      })
    })
  },
  /* 修改某个apk“是否强制升级” */
  forceUpdateSave(params) {
    return new Promise((resolve, reject) => {
      http
        .post('/deviceOta/apk/forceUpdateSave', params, { isJSON: true })
        .then((res) => {
          resolve(res)
        })
    })
  },
  /* 读取APK信息 */
  readApkFile(params) {
    return new Promise((resolve, reject) => {
      http.post('/deviceOta/readApkFile', params).then((res) => {
        resolve(res)
      })
    })
  },
  /* 保存APK */
  apkSave(params) {
    return new Promise((resolve, reject) => {
      http.post('/deviceOta/apk/save', params, { isJSON: true }).then((res) => {
        resolve(res)
      })
    })
  },
  /* 删除APK */
  apkDeleteBatch(params) {
    return new Promise((resolve, reject) => {
      http.post('/deviceOta/apk/deleteBatch', params).then((res) => {
        resolve(res)
      })
    })
  }
}
