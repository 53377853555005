<!-- 的页面 -->

<!-- 使用
 <hy-select
    v-model="value"
    controller="attendance/dailyCheck/workTime" // 接口
    optionLabel="workTimeName" // label 绑定的名字
    optionValue="id"> // value 绑定的值
  </hy-select>
-->

<template>
  <el-select
    v-if="!selectValue || (selectValue && selectedList.length)"
    v-model="selectValue"
    :placeholder="placeholder"
    :multiple="multiple"
    :size="size"
    :class="
      clearable
        ? selectValue
          ? 'remoteSelect_have'
          : 'remoteSelect_no'
        : 'remoteSelect_no'
    "
    filterable
    remote
    :loading="(loading && page === 0) || options.length === 0"
    :loading-text="loading ? '加载中' : '无数据'"
    :clearable="clearable"
    @visible-change="visibleChange"
    :remote-method="remoteMethod"
    @change="handleChange"
  >
    <el-option-group
      v-if="selectedList.length"
      label="已选择"
      :style="{ display: multiple ? 'block' : 'none' }"
    >
      <div class="max-h-[70px] overflow-y-auto">
        <el-option
          :key="index + '_selectedList'"
          v-for="(item, index) in selectedList"
          :label="item[`${optionLabel}`]"
          :value="item[`${optionValue}`]"
        />
      </div>
    </el-option-group>
    <el-option-group :label="`${multiple ? '请选择' : ''}`">
      <virtual-list
        v-infinite-scroll="loadmore"
        v-if="visibleChangeType"
        :data-key="'id'"
        :data-sources="options"
        :data-component="selectComponent"
        class="max-h-[200px] overflow-y-auto"
        :extra-props="{
          optionLabel: optionLabel,
          optionValue: optionValue
        }"
        :keeps="10"
        :estimate-size="34"
      />
    </el-option-group>
    <p v-if="loading" class="centerBox fs_12 c_9DA7B2 mt_10">加载中...</p>
  </el-select>
  <el-select
    v-else
    v-model="selectValue"
    :placeholder="placeholder"
    :multiple="multiple"
    :size="size"
    :class="
      clearable
        ? selectValue
          ? 'remoteSelect_have'
          : 'remoteSelect_no'
        : 'remoteSelect_no'
    "
    filterable
    remote
    :clearable="clearable"
    @visible-change="visibleChange"
    :remote-method="remoteMethod"
    @change="handleChange"
  >
    <el-option-group label="请选择">
      <virtual-list
        v-infinite-scroll="loadmore"
        v-if="visibleChangeType"
        :data-key="'id'"
        :data-sources="options"
        :data-component="selectComponent"
        class="max-h-[200px] overflow-y-auto"
        :extra-props="{
          optionLabel: optionLabel,
          optionValue: optionValue
        }"
        :keeps="10"
        :estimate-size="34"
      />
    </el-option-group>
    <p v-if="loading" class="centerBox fs_12 c_9DA7B2 mt_10">加载中...</p>
  </el-select>
</template>

<script>
import selectComponent from './selectComponent.vue'
export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  created() {
    this.initSelect()
  },
  watch: {
    value(newValue) {
      if (this.selectValue !== newValue) {
        this.selectValue = newValue
        if (
          this.selectValue instanceof Array
            ? this.selectValue.length
            : this.selectValue
        ) {
          this.getSelectedList()
        } else {
          this.selectedList = []
        }
      }
    }
  },
  props: {
    value: {},
    requestParams: Object, // 请求时的额外参数
    controller: String, // 表格的controller名
    filterDataList: {
      type: Array,
      default: () => {
        return []
      }
    }, // 过滤数据
    customDataList: {
      type: Array,
      default: () => {
        return []
      }
    }, // 自定义数据
    selectedDataList: {
      type: Array,
      default: () => {
        return []
      }
    }, // 自定义数据
    requestimmediately: {
      type: Boolean,
      default: () => {
        return false
      }
    }, // 下拉立即请求
    clearable: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    multiple: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    optionLabel: {
      type: String,
      default: () => {
        return 'name'
      }
    },
    optionValue: {
      type: String,
      default: () => {
        return 'id'
      }
    },
    size: {
      type: String,
      default: () => {
        return null
      }
    },
    placeholder: {
      type: String,
      default: () => {
        return '请选择'
      }
    }
  },
  data() {
    return {
      visibleChangeType: false,
      selectComponent: selectComponent,
      selectValue: null,
      valueType: null,
      // 列表数据
      selectedList: [], // 已经选中的数据
      options: [],
      query: null,
      loading: false,
      noMore: false,
      page: 0
    }
  },
  computed: {
    disabled() {
      return this.loading || this.noMore
    }
  },
  methods: {
    // 初始化选中
    initSelect() {
      this.selectedList = []
      this.selectValue = this.value
      if (
        this.selectValue instanceof Array
          ? this.selectValue.length
          : this.selectValue
      ) {
        if (this.selectedDataList.length) {
          this.selectedList = [...this.selectedDataList]
        } else {
          this.getSelectedList()
        }
      } else {
        this.selectedList = []
      }
    },

    /* 获取已经选中的列表数据 */
    getSelectedList() {
      this.selectedList = []
      if (this.multiple) {
        this.$api
          .page(this.controller, {
            size: 1000,
            idList: this.selectValue,
            page: 0
          })
          .then((res) => {
            if (res.code === 200) {
              const { content } = res.data
              this.selectedList = content || []
              if (this.customDataList && this.customDataList.length) {
                this.selectValue.forEach((row) => {
                  const index = this.selectedList.findIndex(
                    (v) => v[`${this.optionValue}`] === row
                  )
                  if (index === -1) {
                    const customItem = this.customDataList.find(
                      (v) => v[`${this.optionValue}`] === row
                    )
                    if (customItem) {
                      this.selectedList.push(customItem)
                    }
                  }
                })
              }
              this.$emit('on-selectItem', this.selectedList)
            }
          })
      } else {
        this.$api
          .page(this.controller, {
            size: 1000,
            idList: [this.selectValue],
            page: 0
          })
          .then((res) => {
            if (res.code === 200) {
              const { content } = res.data
              this.selectedList = content || []
              if (this.customDataList && this.customDataList.length) {
                const index = this.selectedList.findIndex(
                  (v) => v[`${this.optionValue}`] === this.selectValue
                )
                if (index === -1) {
                  const customItem = this.customDataList.find(
                    (v) => v[`${this.optionValue}`] === this.selectValue
                  )
                  if (customItem) {
                    this.selectedList.push(customItem)
                  }
                }
              }
              if (this.selectedList.length) {
                this.$emit('on-selectItem', this.selectedList[0])
              } else {
                this.$emit('on-selectItem', null)
              }
            }
          })
      }
    },
    visibleChange(type) {
      this.visibleChangeType = type
      this.$emit('visibleChange', type)
      if (this.visibleChangeType) {
        if (this.requestimmediately || this.options.length === 0) {
          this.remoteMethod(null)
        }
      }
    },
    // 选中值发生变化时触发
    handleChange(val) {
      if (!val && this.query) {
        this.remoteMethod(null)
      }
      if (this.multiple) {
        const arr = []
        /* 多选 */
        if (val && val.length) {
          val.forEach((v) => {
            const row = this.options.find(
              (item) => item[`${this.optionValue}`] === v
            )
            if (row) {
              arr.push(row)
            }
          })
          this.selectedList = arr
          this.$emit('on-selectItem', arr)
        } else {
          this.selectedList = []
          this.$emit('on-selectItem', [])
        }
        this.$nextTick(() => {
          this.$emit('on-changeSelectItem', null)
        })
      } else {
        /* 单选 */
        if (val) {
          const row = this.options.find(
            (item) => item[`${this.optionValue}`] === val
          )
          if (row) {
            this.selectedList = [row]
            this.$emit('on-selectItem', row)
            this.$nextTick(() => {
              this.$emit('on-changeSelectItem', row)
            })
          }
        } else {
          this.selectedList = []
          this.$emit('on-selectItem', null)
          this.$nextTick(() => {
            this.$emit('on-changeSelectItem', null)
          })
        }
      }
      this.$emit('input', val)
      this.$emit('change', val)
    },
    // 远程搜索
    remoteMethod(query) {
      this.query = query
      this.page = 0
      this.$nextTick(() => {
        this.getData()
      })
    },
    loadmore() {
      if (!this.loading && !this.disabled && this.options.length >= 15) {
        this.page++
        this.$nextTick(() => {
          this.getData()
        })
      }
    },
    // 查接口 发请求
    getData() {
      this.loading = true
      if (this.page === 0) {
        if (this.customDataList && this.customDataList.length) {
          this.options = [...this.customDataList]
        } else {
          this.options = []
        }
      }
      if (this.controller) {
        this.$api
          .page(this.controller, {
            size: 15,
            ...this.requestParams,
            page: this.page,
            query: this.query
          })
          .then((res) => {
            if (res.code === 200) {
              const { content, totalPages } = res.data
              const dataList = content || []
              if (this.filterDataList.length) {
                dataList.forEach((item) => {
                  const optionValue = item[`${this.optionValue}`]
                  if (optionValue === this.value) {
                    this.options.push(item)
                  } else {
                    const itemIndex = this.filterDataList.findIndex(
                      (filterRow) =>
                        filterRow[`${this.optionValue}`] === optionValue
                    )
                    if (itemIndex === -1) {
                      this.options.push(item)
                    }
                  }
                })
              } else {
                this.options.push(...dataList)
              }
              if (this.query && this.options.length === 0) {
                this.query = null
              }
              this.$nextTick(() => {
                this.loading = false
                this.noMore = this.page === totalPages
              })
            }
          })
      }
    }
  }
}
</script>
<style lang="less" rel="stylesheet/less"></style>
