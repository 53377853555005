import Axios from '@/http'
export default {
  businessSystemSetting() {
    return new Promise((resolve, reject) => {
      Axios.post('setting/businessSystemSetting').then((res) => {
        resolve(res)
      })
    })
  },
  findByParentIdFn(parentId) {
    return new Promise((resolve, reject) => {
      Axios.post('setting/disasterType/findByParentId', { parentId }).then(
        (res) => {
          resolve(res)
        }
      )
    })
  },
  saveRankSort(controller, params) {
    return new Promise((resolve, reject) => {
      Axios.post(`setting/${controller}/saveRankSort`, params, {
        isJSON: true
      }).then((res) => {
        resolve(res)
      })
    })
  }
}
